import React from 'react'
import { Box } from '@mui/material'
import { ReactComponent as SettingsIcon } from '@/assets/icons/settings-icon.svg'

import PageContentLayout from '@/designSystem/PageContentLayout'
import LeftActionButtons from '@/components/Pages/Settings/AdvertisersLogs/LeftActionButtons'
import RightActionButtons from '@/components/Pages/Settings/AdvertisersLogs/RightActionButtons'
import AdvertisersLogsTable from '@/components/Pages/Settings/AdvertisersLogs/AdvertisersLogsTable'

export default function AdvertisersLogsPage () {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  return (
    <PageContentLayout
    title={
    <Box
      display={'flex'}
      alignItems={'center'}
    >
      <SettingsIcon />
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          fontSize: '28px',
          fontWeight: 500,
          color: '#000931',
          marginLeft: '15px'
        }}
      >
        Settings / <Box sx={{ color: '#7A7F8F', marginLeft: '8px', marginTop: '1px' }}>Advertisers Logs:</Box>
      </Box>
    </Box>
    }
      leftActionButtons={<LeftActionButtons />}
      rightActionButtons={<RightActionButtons />}
    >
      <AdvertisersLogsTable />
    </PageContentLayout>
  )
}
