import React from 'react'

export default function UserChanged () {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="12" fill="#4141D5" />
      <path d="M27.9987 27.9987C32.6029 27.9987 36.332 24.2695 36.332 19.6654C36.332 15.0612 32.6029 11.332 27.9987 11.332C23.3945 11.332 19.6654 15.0612 19.6654 19.6654C19.6654 24.2695 23.3945 27.9987 27.9987 27.9987ZM27.9987 32.1654C22.4362 32.1654 11.332 34.957 11.332 40.4987V42.582C11.332 43.7279 12.2695 44.6654 13.4154 44.6654H42.582C43.7279 44.6654 44.6654 43.7279 44.6654 42.582V40.4987C44.6654 34.957 33.5612 32.1654 27.9987 32.1654Z" fill="#F1F3FF" />
    </svg>
  )
}
