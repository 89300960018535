import React, { useEffect } from 'react'
import ActionButtons from '@/designSystem/ActionButtons'
import Popup from '@/designSystem/Popup'
import { Box, Typography, useTheme } from '@mui/material'
import { PeriodType, useEditLeadIntervalSettingsMutation, useGetLeadIntervalSettingsQuery } from '@/store/settings'
import TextField from '@/designSystem/TextField'
import AccountsAutocomplete from '@/pages/Accounts/AccountsAutocomplete'

interface IPopup {
  open: boolean
  handleClose: () => void
}

export const periodTypes: Record<number, string> = {
  [PeriodType.HOUR]: 'Hour',
  [PeriodType.DAY]: 'Day',
  [PeriodType.WEEK]: 'Week',
  [PeriodType.MONTH]: 'Month'
}

export const LateMinimumIntervalPopup = ({ open, handleClose }: IPopup) => {
  const theme = useTheme()
  const [submitInterval] = useEditLeadIntervalSettingsMutation()
  const leadIntervalRefetch = useGetLeadIntervalSettingsQuery({}).refetch
  const leadInterval = useGetLeadIntervalSettingsQuery({})?.data?.interval

  const [period, setPeriod] = React.useState<number | null>(null)
  const [periodType, setPeriodType] = React.useState<number>(PeriodType.HOUR)
  const [error, setError] = React.useState<boolean>(false)

  useEffect(() => {
    leadInterval?.period && setPeriod(leadInterval?.period)
    leadInterval?.periodType && setPeriodType(leadInterval?.periodType)
  }, [leadInterval])

  useEffect(() => {
    void leadIntervalRefetch()
  }, [open])

  const onChangePeriodType = (value: number) => {
    setPeriodType(value as PeriodType)
  }

  const onChangePeriod = (value: number) => {
    value && setPeriod(Number(value))
    if (value <= 0) {
      setError(true)
    } else {
      setError(false)
    }
  }

  const handleCanseled = () => {
    handleClose()
    setPeriod(leadInterval?.period ?? null)
    setError(false)
  }

  const onSubmit = async () => {
    if (!period) {
      setError(true)
      return
    }
    await submitInterval({
      period,
      periodType: Number(periodType)
    })
    handleCanseled()
  }

  return (
    <Popup
      open={open}
      title="Edit Late Minimum Interval"
      handleClose={handleCanseled}
      overflow='auto'
      maxWidth='600px'
      padding='40px'
    >
      <Box
        height={'290px'}
        display='flex'
        flexDirection='column'
        justifyContent={'space-between'}>

        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
        >
          <Typography
            sx={{
              marginBottom: theme.spacing(2)
            }}
          >
            Enter the time period for the late minimum interval
          </Typography>
          <Typography
            sx={{
              marginBottom: theme.spacing(1),
              marginTop: theme.spacing(8)
            }}
          >
            Enter the time period
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(2)
            }}
          >
            <TextField
              type="number"
              value={period}
              onChange={(e: any) => e?.target?.value && onChangePeriod(e.target.value)}
              style={{ width: '255px' }}
            />
            <AccountsAutocomplete
              defaultValue={leadInterval
                ? { value: leadInterval?.periodType?.toString(), title: periodTypes[leadInterval?.periodType] }
                : { value: PeriodType.HOUR.toString(), title: 'Hour' }}
              options={[{ value: PeriodType.HOUR.toString(), title: 'Hour' }, { value: PeriodType.DAY.toString(), title: 'Day' },
                { value: PeriodType.WEEK.toString(), title: 'Week' }, { value: PeriodType.MONTH.toString(), title: 'Month' }
              ]}
              onChange={(e: any) => onChangePeriodType(Number(e?.value))}
              placeholder="Enter Time"
              sx={{ width: '255px' }}
            />
          </Box>
          {error && <Typography sx={{
            color: theme.palette.error.main,
            marginTop: '1px',
            fontSize: '12px'
          }}>Must be more 0</Typography>}

        </Box>
        <ActionButtons
          sx={{ paddingTop: '24px' }}
          onCancel={handleCanseled}
          onSubmit={onSubmit}
          submitText="Submit"
          cancelText='Cancel'
        />
      </Box>
    </Popup>
  )
}
