import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, IconButton } from '@mui/material'

import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow-left.svg'
import PageContentLayout from '@/designSystem/PageContentLayout'
import LeftActionButtons from './LeftActionButtons'
import RightActionButtons from './RightActionButtons'
import DealsTable from './DealsTable'
import {
  type IDeal,
  selectDealsMenu,
  setDealsMenu
} from '@/store/deals'
import MenuDealsTable from './MenuDealsTable/MenuDealsTable'

export default function DealsPage () {
  const dispatch = useDispatch()
  const menu = useSelector(selectDealsMenu)

  const isMenu: boolean = !!menu?.id
  const deal: IDeal | undefined = menu?.deal

  const handleReloadDeals = () => {
    dispatch(setDealsMenu({ name: '', id: '', deal: {} }))
  }

  useEffect(() => {
    handleReloadDeals()
  }, [])

  return (
    <PageContentLayout
      title={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {isMenu && <IconButton onClick={handleReloadDeals} sx={{
            marginRight: '16px',
            marginTop: '2px'
          }}>
            <ArrowIcon width={24} height={24} />
          </IconButton>}
          <Box sx={{
            color: '#000931',
            fontSize: '28px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal'
          }}
          >{!isMenu && 'Deals '}
          </Box>
        </Box>
      }
      activeMenu={<Box sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: '16px',
        marginTop: '2px',
        color: '#000931',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal'
      }}>{menu?.name}
      </Box>}
      leftActionButtons={<LeftActionButtons isMenu={isMenu} />}
      rightActionButtons={!isMenu && <RightActionButtons />}
    >
      {!isMenu && <DealsTable />}
      {isMenu && <MenuDealsTable deals={deal ?? {}} fullMode />}
    </PageContentLayout>
  )
}
