import React from 'react'
import PageContentLayout from '@/designSystem/PageContentLayout'
import HelpInformationModal from '@/designSystem/Pages/Advertisers/HelpInformationModal'

import LeadsTable from '@/components/Pages/TrafficData/Leads/LeadsTable'
import LeftActionButtons from '@/components/Pages/TrafficData/Leads/LeftActionButtons'
import RightActionButtons from '@/components/Pages/TrafficData/Leads/RightActionButtons'

export default function LeadsPage () {
  return (
    <PageContentLayout
      title="Leads"
      support={{ content: <HelpInformationModal />, subtitle: 'Leads' }}
      leftActionButtons={<LeftActionButtons />}
      rightActionButtons={<RightActionButtons />}
    >
      <LeadsTable />
    </PageContentLayout>
  )
}
