import React from 'react'
import { type HoldsFilters } from '@/store/hold'
import { type FiltersAdvertiser } from '@/store/advertisers'
import { activeStateOptions, booleanOptions, saleStatusOptions, textFieldNames } from '../constants'
import TextFilterItem from './TextFilterItem'
import AutocompleteFilterItem from './AutocompleteFilterItem'
import { countryOptions } from '@/constants'
import AdvertiserFilterItem from './AdvertiserFilterItem'
import AdvertiserSaleStatusFilterItem from './AdvertiserSaleStatusFilterItem'
import { type LeadsFilters } from '@/store/leads'
import { type ConversionsFilters } from '@/store/conversions'
import FromToNumberFilterItem from './FromToNumberFilterItem'
import FromToDateFilterItem from './FromToDateFilterItem'
import { goalTypeOptions } from '@/designSystem/Pages/TrafficDistribution/RotationControl/Popups/AddOrEdit'
import CrmSaleStatusFilterItem from './CrmSaleStatusFilterItem'

export interface FilterItemProps {
  onChange: (field: keyof HoldsFilters & keyof LeadsFilters & keyof ConversionsFilters & keyof FiltersAdvertiser, value: string | string[] | boolean | { from?: string, to?: string }, isMultiple: boolean) => void
  onDelete: (field: keyof HoldsFilters & keyof LeadsFilters & keyof ConversionsFilters & keyof FiltersAdvertiser, value: string | string[], isMultiple: boolean) => void
  isMultiple: boolean
  title: string
  name: keyof HoldsFilters | LeadsFilters | ConversionsFilters | FiltersAdvertiser
  selectedValues: string | string[] | undefined | boolean | { from?: string, to?: string }
}

const FilterItem = (props: FilterItemProps) => {
  const { name } = props

  const isTextField = textFieldNames.includes(name as any)
  if (isTextField) {
    return <TextFilterItem {...props} />
  }

  switch (name as any) {
    case 'country': return <AutocompleteFilterItem {...props} options={countryOptions} />
    case 'advertiserId': return <AdvertiserFilterItem {...props} />
    case 'state': return <AutocompleteFilterItem {...props} options={activeStateOptions} />
    case 'goalType': return <AutocompleteFilterItem {...props} options={goalTypeOptions} />
    case 'saleStatus': return <AutocompleteFilterItem {...props} options={saleStatusOptions} />
    case 'advertiserSaleStatus': return <AdvertiserSaleStatusFilterItem {...props} />
    case 'crmSaleStatus': return <CrmSaleStatusFilterItem {...props} />
    case 'withConversion':
    case 'status':
    case 'pullLeadStatuses':
    case 'isAutologinSuccess': return <AutocompleteFilterItem {...props} options={booleanOptions} />
    case 'stateUpdatedAt':
    case 'createdAt': return <FromToDateFilterItem {...props} />
    case 'revenue': return <FromToNumberFilterItem {...props} />
  }
  return null
}

export default FilterItem
