import React, { useState } from 'react'

import { Box, Card, IconButton, Typography, useTheme } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'

import { type Advertiser } from '@/store/advertisers'
import BasicTable from '@/designSystem/BasikTable'
import { TextField } from '@/designSystem/Table/Fields'
import { format } from 'date-fns'

export interface BasicInfoTabContentProps {
  advertiser: Advertiser
}

export default function BasicInfoTabContent ({
  advertiser
}: BasicInfoTabContentProps) {
  const { palette, spacing } = useTheme()

  const firstTableRows = [
    {
      id:
        <Box sx={{ width: '200px' }}>
          <TextField text={advertiser.id} limitation={180} />
        </Box>,
      active:
        <Box sx={{ width: '200px' }}>
          <Typography
            display="flex"
            variant="body1"
            color={palette.text1.main}
            width="max-content">
            {advertiser.status === 'ACTIVE' ? 'Yes' : 'No'}
          </Typography>
        </Box>,
      pullLeadStatuses:
        <Box sx={{ width: '200px' }}>
          <Typography
            display="flex"
            variant="body1"
            color={palette.text1.main}
            width="max-content">
            {advertiser.pullLeadStatuses ? 'Yes' : 'No'}
          </Typography>
        </Box>
    }]

  const secondTableRows = [
    {
      createdAt:
        <Box sx={{ width: '200px' }}>
          <Typography variant='body2' color={palette.text2.main}>
            {format(new Date(advertiser?.createdAt), 'P HH:mm:ss') }
          </Typography>
        </Box>,
      updatedAt:
        <Box sx={{ width: '200px' }}>
          <Typography variant='body2' color={palette.text2.main}>
            {advertiser?.updatedAt ? format(new Date(advertiser?.updatedAt), 'P HH:mm:ss') : '' }
          </Typography>
        </Box>,
      description:
        <Box sx={{ width: '200px' }}>
          <Typography variant='body2' color={palette.text2.main}>
            {advertiser.description}
          </Typography>
        </Box>
    }]

  return (
    <Box>
        <Typography
          width="max-content"
          sx={{
            color: '#000931',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            textTransform: 'capitalize',
            paddingTop: '40px',
            paddingBottom: '12px'
          }}
        >
          Basic Information
        </Typography>
        <BasicTable
          headers={['UUID', 'Active', 'Pull lead statuses']}
          rows={firstTableRows}
        />
        <Box sx={{
          marginTop: '30px'
        }}>
        <BasicTable
          headers={['Created At', 'Updated At', 'Description']}
          rows={secondTableRows}
        />
        </Box>

    </Box>
  )
}

interface RowProps {
  title: string
  text: string
  isLimited?: boolean
}
function Row ({ text, title, isLimited }: RowProps) {
  const { palette } = useTheme()

  return (
    <Typography
      color={palette.text2.main}
      sx={isLimited
        ? {
            maxWidth: '80px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }
        : undefined}
    >
      <Box
        display="inline"
        color={palette.text1.main}
        fontWeight={500}
      >
        {`${title}: `}
      </Box>
      {text}
    </Typography>
  )
}
