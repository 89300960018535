import React from 'react'

import { CountryField, DateTimeField, TextField } from '@/designSystem/Table/Fields'
import { Box, Typography } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { ReactComponent as EyeIcon } from '@/assets/icons/eye.svg'
import { ReactComponent as ThreeDots } from '@/assets/icons/three-dots.svg'
import { dealsHistoryHeaders } from '@/store/deals'

export function FieldMap ({ children }: any) {
  const { palette } = useTheme()
  return (
    <Typography
      sx={{ display: 'flex' }}
      variant='table1'
      width="max-content"
      color={palette.text2.main}
    >
      {children}
    </Typography>
  )
}

const elementMap = (header: string, row: any,
  handleOpenDeal: ({ name, id }: { name: string, id: string, deal: any }) => void,
  setModalLogs: (reason: string) => void) => {
  const {
    id,
    goalType,
    advertiserId,
    reason,
    countries,
    newRevenue,
    updatedAt,
    createdAt
  } = row

  switch (header) {
    case dealsHistoryHeaders.id:
      return <Box sx={{
        marginLeft: '8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '4px'
      }}>
        <Box sx={{ cursor: 'pointer', marginTop: '5px' }} onClick={() => handleOpenDeal({ name: goalType, id, deal: row })} >
          <EyeIcon />
        </Box>
        <TextField text={id} limitation={1} />
      </Box>
    case dealsHistoryHeaders.goalType:
      return <TextField text={goalType} />
    case dealsHistoryHeaders.advertiserId:
      return <TextField text={advertiserId} limitation={1} />
    case dealsHistoryHeaders.reason:
      return <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '180px',
            gap: '4px'
          }}
        >
          <Typography
            display="flex"
            variant="body1"
            color="#393E51"
            width="max-content"
            style={{
              cursor: 'pointer',
              textDecoration: 'none',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: '100px'
            }}
          >{reason || 'No reason'}
          </Typography>
          {reason?.length > 40 && <ThreeDots
            cursor={'pointer'}
            onClick={() => setModalLogs(reason)}
          />}
        </Box >
    case dealsHistoryHeaders.countries:
      return countries?.length > 0
        ? countries?.map((country: string, index: number) => {
          return <CountryField key={index} countryCode={country} />
        })
        : <Box display={'flex'} justifyContent={'start'}>No countries</Box>
    case dealsHistoryHeaders.newRevenue:
      return <TextField text={`$${newRevenue}`} />
    case dealsHistoryHeaders.updatedAt:
      return <DateTimeField date={new Date(updatedAt)} />
    case dealsHistoryHeaders.createdAt:
      return <DateTimeField date={new Date(createdAt)} />
  }
}

export default elementMap
