import React from 'react'
import ReloadButton from '@/designSystem/ReloadButton'
import { useNavigate } from 'react-router'

export default function LeftActionButtons () {
  const navigate = useNavigate()
  const onClickReload = () => navigate(0)

  return <ReloadButton onClick={onClickReload} />
}
