import React from 'react'

import useTheme from '@mui/material/styles/useTheme'
import MuiIconButton, { type IconButtonProps } from '@mui/material/IconButton'

export default function IconButton ({
  children,
  sx,
  ...rest
}: IconButtonProps) {
  const theme = useTheme()
  return (
  <MuiIconButton
    sx={{
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(3),
      '&:hover': {
        backgroundColor: theme.palette.buttonHover.main
      },
      ...sx
    }}
    {...rest}
  >
    {children}
  </MuiIconButton>
  )
}
