import React, { useEffect } from 'react'

import {
  useGetAdvertisersQuery
} from '@/store/advertisers'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import BasicInfoTabContent
  from '@/designSystem/Pages/Advertisers/IntegrationSettings/BasicInfoTabContent'
import { Typography } from '@mui/material'

export default function BasicInfoTab () {
  const { advertiserId } = useParams()

  const { data, isFetching, isError } = useGetAdvertisersQuery({
    limit: 1,
    filters: {
      id: advertiserId ? [advertiserId] : undefined
    }
  })

  const advertiser = data?.advertisers?.[0]

  if (isFetching) {
    return null
  }

  if (isError || !advertiser) {
    return (
      <Typography variant="h1">
        Advertiser not found
      </Typography>
    )
  }

  return <BasicInfoTabContent advertiser={advertiser} />
}
