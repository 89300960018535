import React from 'react'

const PassAllIcon = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="32" height="32" rx="8" fill="#7DD8BC"/>
      <path d="M14.2498 19.1502L11.6248 16.5252C11.5562 16.4557 11.4744 16.4005 11.3843 16.3629C11.2942 16.3252 11.1975 16.3059 11.0998 16.3059C11.0021 16.3059 10.9054 16.3252 10.8153 16.3629C10.7252 16.4005 10.6434 16.4557 10.5748 16.5252C10.5053 16.5938 10.4502 16.6756 10.4125 16.7657C10.3749 16.8558 10.3555 16.9525 10.3555 17.0502C10.3555 17.1479 10.3749 17.2446 10.4125 17.3347C10.4502 17.4248 10.5053 17.5065 10.5748 17.5752L13.7173 20.7177C14.0098 21.0102 14.4823 21.0102 14.7748 20.7177L22.7248 12.7752C22.7943 12.7065 22.8494 12.6248 22.8871 12.5347C22.9247 12.4446 22.9441 12.3479 22.9441 12.2502C22.9441 12.1525 22.9247 12.0558 22.8871 11.9657C22.8494 11.8756 22.7943 11.7938 22.7248 11.7252C22.6562 11.6557 22.5744 11.6005 22.4843 11.5629C22.3942 11.5252 22.2975 11.5059 22.1998 11.5059C22.1021 11.5059 22.0054 11.5252 21.9153 11.5629C21.8252 11.6005 21.7434 11.6557 21.6748 11.7252L14.2498 19.1502Z" fill="#F1F3FF"/>
    </svg>

  )
}

export default PassAllIcon
