import React from 'react'

export default function PushLeads () {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="45" height="45" rx="12" fill="white" />
      <path d="M22.9403 29.9119L27.7612 32.8277C28.6441 33.3621 29.7245 32.5721 29.4921 31.5731L28.2143 26.09L32.4776 22.3959C33.2559 21.7222 32.8377 20.4443 31.8155 20.363L26.2046 19.8867L24.0091 14.7057C23.6141 13.7648 22.2666 13.7648 21.8716 14.7057L19.676 19.8751L14.0652 20.3514C13.0429 20.4327 12.6247 21.7106 13.403 22.3843L17.6664 26.0784L16.3885 31.5615C16.1562 32.5605 17.2365 33.3505 18.1194 32.8161L22.9403 29.9119Z" fill="#4141D5" />
    </svg>
  )
}
