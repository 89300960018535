import { format } from 'date-fns'

export const FORMAT_DATE_TIME = 'yyyy-MM-dd HH:mm:ss'
export const FORMAT_DATE_ONLY = 'yyyy-MM-dd'
export const FORMAT_TIME_ONLY = 'HH:mm:ss'

/**
 * Main Data and Time conversion utility to keep formats the same across entire Application
 * @param {string|object} dateOrString - date to show as UTC string or Date object instance
 * @param {string} [dateFormat] - time conversion template in 'date-fns' format, `FORMAT_DATE_TIME` by default
 * @param {string} [fallbackValue] - optional fallback value if data conversion is not possible
 */
export function dateToString (dateOrString: string | Date, dateFormat = FORMAT_DATE_TIME, fallbackValue = ''): string {
  const date = typeof dateOrString === 'object' ? dateOrString : new Date(dateOrString)
  let result
  try {
    result = format(date, dateFormat)
  } catch (error) {
    result = fallbackValue
  }
  return result
}

export const convertDate = (date: Date) => {
  const inputDate = new Date(date)

  let day: number | string = inputDate.getDate()
  let month: number | string = inputDate.getMonth() + 1
  let year: number | string = inputDate.getFullYear() % 100

  day = day < 10 ? '0' + day : day
  month = month < 10 ? '0' + month : month
  year = year < 10 ? '0' + year : year

  let hours: number | string = inputDate.getHours()
  let minutes: number | string = inputDate.getMinutes()
  let seconds: number | string = inputDate.getSeconds()

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  const result = day + '.' + month + '.' + year + ' ' + hours + ':' + minutes + ':' + seconds

  return result
}
