import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'

export interface PostbackSent {
  id: string
  status: string
  postbackPassingRuleId: string
  requestMethod: string
  url: string
  postbackSentData: {
    data: Record<string, string>
    headers: Record<string, string>
    responseData?: Record<string, string>
    errorData?: Record<string, string>
  }
  createdAt: string
}

export interface IPostbackResponse {
  totalCount: number
  data: PostbackSent[]
}

export interface IPostbackRequest {
  page: number
  limit: number
  sortField?: string
  sortOrder?: string
  filter: {
    leadId: {
      in: string[]
    }
  }
  createdAt?: {
    from: string
    to: string
  }
}

export interface PostbackSentState {
  postBackSent: PostbackSent[]
  queryParams: {
    page: number
    limit: number
    total: number
  }
}

const initialState: PostbackSentState = {
  postBackSent: [],
  queryParams: {
    page: 1,
    limit: 10,
    total: 0
  }
}

export const postBackSentSlice = createSlice({
  name: 'postBackSent',
  initialState,
  reducers: {
    setPostbackSent: (state, action: PayloadAction<IPostbackResponse>) => {
      state.postBackSent = action.payload.data
      state.queryParams.total = action.payload.totalCount
    },
    setPostbackSentQueryParams: (state, action: PayloadAction<{ page: number, limit: number }>) => {
      state.queryParams.limit = action.payload.limit
      state.queryParams.page = action.payload.page
    }
  }
})

export default postBackSentSlice.reducer
export const { setPostbackSent, setPostbackSentQueryParams } = postBackSentSlice.actions
export const selectPostbackSent = (state: RootState) => state.postBackSent.postBackSent
export const selectPostbackSentQueryParams = (state: RootState) => state.postBackSent.queryParams

export const postBackSentApi = createApi({
  reducerPath: 'postBackSentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/postbackSent`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['PostBackSent'],
  endpoints: (builder) => ({
    searchPostBackSent: builder.query<IPostbackResponse, IPostbackRequest>({
      query: ({ limit = 20, page = 1, sortField = 'url', sortOrder = 'asc', filter }) => ({
        url: '/search',
        method: 'POST',
        body: {
          limit,
          page,
          sortField,
          sortOrder,
          filter
        }
      }),
      providesTags: [{ type: 'PostBackSent', id: 'PostBackSentLIST' }]
    })
  })
})

export const {
  useSearchPostBackSentQuery
} = postBackSentApi
