import React, { useEffect } from 'react'
import ActionButtons from '@/designSystem/ActionButtons'
import Popup from '@/designSystem/Popup'
import { Box, Typography, useTheme } from '@mui/material'
import AccountsAutocomplete from '@/pages/Accounts/AccountsAutocomplete'
import { PeriodType, useGetDeduplicationSettingsQuery, useUpdateDeduplicationSettingsMutation } from '@/store/settings'
import TextField from '@/designSystem/TextField'
import IOSSwitch from '@/designSystem/IOSSwitch'
import { placeholderValues } from '@/constants'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import FormAutocomplete from '@/components/Form/FormAutocomplete'
import { type Option } from '@/designSystem/Autocomplete/types'
import { periodTypes } from './LateMinimumIntervalPopup'

interface IPopup {
  open: boolean
  handleClose: () => void
}

export const LeadsDeduplicationPopup = ({ open, handleClose }: IPopup) => {
  const theme = useTheme()
  const [submitLeadsDeduplication] = useUpdateDeduplicationSettingsMutation()
  const leadsDeduplicationRefetch = useGetDeduplicationSettingsQuery({}).refetch
  const getLeadsDeduplication = useGetDeduplicationSettingsQuery({})?.data?.leadDeduplication

  const [intervalPeriod, setIntervalPeriod] = React.useState<number | null>(null)
  const [intervalPeriodType, setIntervalPeriodType] = React.useState<number>(PeriodType.HOUR)
  const [checkAll, setCheckAll] = React.useState<boolean>(false)
  const [fields, setFields] = React.useState<string[]>([])
  const [error, setError] = React.useState({ time: '', groups: '' })

  useEffect(() => {
    getLeadsDeduplication?.intervalPeriod && setIntervalPeriod(getLeadsDeduplication?.intervalPeriod)
    getLeadsDeduplication?.intervalPeriodType && setIntervalPeriodType(getLeadsDeduplication?.intervalPeriodType)
    getLeadsDeduplication?.fields && setFields(getLeadsDeduplication?.fields)
    getLeadsDeduplication?.checkAll && setCheckAll(getLeadsDeduplication?.checkAll)
  }, [getLeadsDeduplication])

  useEffect(() => {
    void leadsDeduplicationRefetch()
  }, [open])

  const plaseholderOptions = placeholderValues?.map((item) => ({ value: item, title: item }))

  const onChangePeriodType = (value: number) => {
    setIntervalPeriodType(value as PeriodType)
  }

  const handleChangeCheckAll = (e: any) => {
    setCheckAll(e?.target?.checked)
  }

  const onChangePeriod = (value: number) => {
    value && setIntervalPeriod(Number(value))
    if (value <= 0) {
      setError({ ...error, time: 'Required field and must be more 0' })
    } else {
      setError({ time: '', groups: '' })
    }
  }

  const handleCanseled = () => {
    handleClose()
    setIntervalPeriod(getLeadsDeduplication?.intervalPeriod ?? null)
    setFields(getLeadsDeduplication?.fields ?? [])
    setCheckAll(getLeadsDeduplication?.checkAll ?? false)
    setError({ time: '', groups: '' })
  }

  const onSubmit = async () => {
    if (!intervalPeriod || !fields?.length) {
      setError({ time: !intervalPeriod ? 'Required field and must be more 0' : '', groups: !fields?.length ? 'Required field' : '' })
      return
    }
    await submitLeadsDeduplication({
      checkAll,
      fields,
      intervalPeriod,
      intervalPeriodType: Number(intervalPeriodType)
    })
    handleCanseled()
  }
  return (
    <Popup
      open={open}
      title="Leads Deduplication"
      handleClose={handleCanseled}
      overflow='auto'
      maxWidth='600px'
      padding='40px'
    >
      <ScrollBarContainer>
        <Box
          height={'400px'}
          display='flex'
          flexDirection='column'
          justifyContent={'space-between'}>

          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'flex-start'}
            gap={'16px'}
          >
            <Typography
              sx={{
                marginBottom: theme.spacing(2)
              }}
            >
              Enter the time period and fields for the leads deduplication
            </Typography>
            <Box>
              <Typography
                sx={{
                  marginBottom: theme.spacing(1),
                  marginTop: theme.spacing(4)
                }}
              >
                Enter the time period
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(2)
                }}
              >
                <TextField
                  type="number"
                  value={intervalPeriod}
                  onChange={(e: any) => e?.target?.value && onChangePeriod(e.target.value)}
                  style={{ width: '255px' }}
                />
                <AccountsAutocomplete
                  defaultValue={getLeadsDeduplication
                    ? { value: getLeadsDeduplication?.periodType?.toString(), title: periodTypes[getLeadsDeduplication?.intervalPeriodType] }
                    : { value: PeriodType.HOUR.toString(), title: 'Hour' }}
                  options={[{ value: PeriodType.HOUR.toString(), title: 'Hour' }, { value: PeriodType.DAY.toString(), title: 'Day' },
                    { value: PeriodType.WEEK.toString(), title: 'Week' }, { value: PeriodType.MONTH.toString(), title: 'Month' }
                  ]}
                  onChange={(e: any) => onChangePeriodType(Number(e?.value))}
                  placeholder="Enter Time"
                  sx={{ width: '255px' }}
                />
              </Box>
               <Typography sx={{
                 color: theme.palette.error.main,
                 marginTop: '1px',
                 fontSize: '12px'
               }}>{error.time}</Typography>
            </Box>

            <Box>
              <Box
                color={theme.palette.text1.main}
                fontSize={18}
                fontWeight={400}
              >Groups</Box>
              <FormAutocomplete
                name="groups"
                rowSelectedOptions
                options={plaseholderOptions ?? [{ title: 'Select groups', value: null }] as unknown as Array<Option<string>>}
                selectedOptions={fields?.length > 0 ? fields : []}
                onChange={(option: Option<string> | null) => {
                  option && setFields([...fields, option.value])
                  option && setError({ time: '', groups: '' })
                }}
                deleteField={(value: string) => () => {
                  setFields(fields?.filter((item: string) => item !== value))
                }}
                sx={{ width: '100%' }}
                textFieldProps={{
                  input: {
                    sx: {
                      input: {
                        padding: '0px 0px 0px 10px !important'
                      }
                    }
                  }
                }}
              />
              <Typography sx={{
                color: theme.palette.error.main,
                marginTop: '1px',
                fontSize: '12px'
              }}>{error.groups}</Typography>
            </Box>

            <Box
              display={'flex'}
              flexDirection={'column'}
              paddingTop={'15px'}
            >
              <IOSSwitch
                checked={checkAll}
                onChange={(e) => handleChangeCheckAll(e)}
                formControlLabel={{
                  label: 'Check All',
                  labelPlacement: 'end',
                  sx: {
                    margin: 0,
                    '& .MuiTypography-root': {
                      marginRight: theme.spacing(3),
                      marginLeft: theme.spacing(1.5)
                    }
                  }
                }} />
            </Box>
          </Box>
          <ActionButtons
            sx={{ paddingTop: '24px', paddingBottom: '24px' }}
            onCancel={handleCanseled}
            onSubmit={onSubmit}
            submitText="Submit"
            cancelText='Cancel'
          />
        </Box>
      </ScrollBarContainer>
    </Popup>
  )
}
