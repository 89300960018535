import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import {
  useGetInjectionsQuery,
  setInjectTotalCount, setInjectMenu, selectInjectMenu,
  setInjection, selectInjection
} from '@/store/injections'
import { selectInjectQueryParams } from '@/store/queryParams'
import { Typography, useTheme } from '@mui/material'
import InjectPagination from './InjectPagination'
import InjectFilterBar from './Filters/InjectFilterBar'
import TableLayout from '@/designSystem/Table'
import elementMap from './ElementMap'
import { EmptyTableInject } from './EmptyTableInject'
import { ModalEventInfo } from '../../designSystem/ModalMessagesLogs'
import { type IInjection } from './InjectionTypes'

const Header = ({ header }: { header: string }) => {
  if (header.startsWith('::')) {
    return ''
  }
  return (
    <Typography
      variant='body1'
      color="text1"
      width="max-content"
      sx={{
        display: 'flex'
      }}
    >
      {header}
    </Typography>
  )
}

export default function InjectionsTable () {
  const dispatch = useDispatch()
  const { limit = 10, headers, filters, page } = useSelector(selectInjectQueryParams)

  const menu = useSelector(selectInjectMenu)
  const { data } = useGetInjectionsQuery({ limit, filters, page })
  const injectionsState: IInjection[] = useSelector(selectInjection)
  const { spacing } = useTheme()
  const [injections, setInjections] = React.useState<any>(injectionsState ?? [])
  const [modalLogs, setModalLogs] = React.useState<any>(null)
  const headersWithExtraFields = ['::indicatorInjections']?.concat(headers)
  const headerNodes: React.ReactNode[] =
    headersWithExtraFields?.map((header) => <Header key={header} header={header} />)

  const handleOpenInject = ({ name, id }: { name: string, id: string }) => {
    dispatch(setInjectMenu({ name, id }))
  }

  const rowNodes: any = injections?.map((row: any) => (
    headersWithExtraFields?.map((header) =>
      elementMap(header, row, setModalLogs, handleOpenInject))
  ))

  useEffect(() => {
    if (data) {
      data && dispatch(setInjection(data?.injection ?? []))
      setInjections(data?.injection ?? [])
      dispatch(setInjectTotalCount(data?.totalCount ?? 0))
    }
  }, [data])

  useEffect(() => {
    if (menu?.name === 'Active') {
      const activeInjections = injectionsState?.filter((inject: any) => inject?.status === 'ACTIVE')
      setInjections(activeInjections)
    } else if (menu?.name === 'Finished') {
      const finishedInjections = injectionsState?.filter((inject: any) => inject?.status === 'FINISHED')
      setInjections(finishedInjections)
    } else {
      setInjections(injectionsState)
    }
  }, [menu])

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={spacing(6)}
    >
      <InjectPagination />
      <InjectFilterBar />
      <TableLayout
        headers={headerNodes}
        rows={rowNodes}
        sx={{
          '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-of-type': {
            width: 0
          }
        }}
      />
      {(rowNodes?.length === 0) && <EmptyTableInject />}
      {modalLogs && <ModalEventInfo
        open={!!modalLogs}
        handleClose={() => setModalLogs(null)}
        logs={modalLogs}
      />}
    </Box>
  )
}
