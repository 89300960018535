import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button, IconButton, Menu, MenuItem } from '@mui/material'

import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow-left.svg'
import { ReactComponent as Arrows } from '@/assets/icons/arrows.svg'
import PageContentLayout from '@/designSystem/PageContentLayout'
import LeftActionButtons from '@/components/AutoInjections/LeftActionButtons'
import RightActionButtons from '@/components/AutoInjections/RightActionButtons'
import { selectAutoInjectMenu, setAutoInjectMenu, useStopAutoInjectionMutation } from '@/store/autoInjections'
import AutoInjectionsTable from '@/components/AutoInjections/AutoInjectionsTable'
import MenuAutoInjectTable from '@/components/AutoInjections/MenuAutoInjectTable/MenuAutoInjectTable'
// import { ReactComponent as EditIcon } from '@/assets/icons/edit.svg'
import { ReactComponent as StopIcon } from '@/assets/icons/round-stop.svg'

export default function AutoInjectionsPage () {
  const dispatch = useDispatch()
  const menu = useSelector(selectAutoInjectMenu)
  const [stopAutoInjectionAction] = useStopAutoInjectionMutation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const isMenu: boolean = !!menu?.id
  const isRunning: boolean = menu?.state === 'RUNNING'

  const handleSetActive = (name: string) => {
    dispatch(setAutoInjectMenu({ name, id: '', state: '' }))
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onMenuItemClicked = (name: string) => {
    handleSetActive(name)
    handleClose()
  }

  const handleReloadInject = () => {
    dispatch(setAutoInjectMenu({ name: '', id: '', state: '' }))
  }

  const stopAutoInjection = async () => {
    await stopAutoInjectionAction({ id: menu?.id ?? '' })
    dispatch(setAutoInjectMenu({ name: '', id: '', state: '' }))
  }

  useEffect(() => {
    handleReloadInject()
  }, [])

  return (
    <PageContentLayout
      title={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {isMenu && <IconButton onClick={handleReloadInject} sx={{
            marginRight: '16px',
            marginTop: '2px'
          }}>
            <ArrowIcon width={24} height={24} />
          </IconButton>}
          <Box sx={{
            color: '#6B6B6B',
            fontSize: '28px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal'
          }}
          >Auto Injections:
          </Box>
        </Box>
      }
      activeMenu={<Box sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: '16px',
        marginTop: '2px',
        color: '#000931',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal'
      }}>{menu?.name}
        {!isMenu &&
          <>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <Arrows />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              <MenuItem onClick={() => onMenuItemClicked('')}>All</MenuItem>
              <MenuItem onClick={() => onMenuItemClicked('Active')}>Active</MenuItem>
              <MenuItem onClick={() => onMenuItemClicked('Finished')}>Finished</MenuItem>
            </Menu>
          </>
        }
      </ Box>}
      leftActionButtons={<LeftActionButtons isMenu={isMenu} />}
      rightActionButtons={(!isMenu && <RightActionButtons />) ||
        (isMenu && <Box
          display={'flex'}
          alignContent={'center'}
          gap='16px'
        >
          {/* <Box
            sx={{
              width: '98px',
              height: '40px',
              padding: '8px 16px',
              borderRadius: '16px',
              border: '1px solid #4141D5',
              background: '#FDFDFD',
              color: '#000000',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
              cursor: 'pointer'
            }}
          >
            <EditIcon />
            Edit
          </Box> */}
          {isRunning && <Box
            sx={{
              width: '98px',
              height: '40px',
              padding: '8px 16px',
              borderRadius: '16px',
              background: '#DF3D3D',
              color: '#F1F3FF',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
              cursor: 'pointer'
            }}
            onClick={stopAutoInjection}
          >
            <StopIcon />
            Stop
          </Box>}
        </Box>)}
    >
      {!isMenu && <AutoInjectionsTable />}
      {isMenu && <MenuAutoInjectTable idAutoInjection={menu?.id ?? ''} />}
    </PageContentLayout>
  )
}
