import React from 'react'

import LeftActionButtonsLayout from '@/designSystem/Pages/Advertisers/LeftActionButtonsLayout'
import { useNavigate } from 'react-router'

interface ILeftButtonsProps {
  openAdd?: () => void
}

export default function LeftActionButtons ({ openAdd }: ILeftButtonsProps) {
  const navigate = useNavigate()
  const onClickReload = () => navigate(0)
  const onClickOpenAdd = () => openAdd && openAdd()

  return <LeftActionButtonsLayout onClickReload={onClickReload} onClickOpenAdd={onClickOpenAdd} />
}
