import React, { useEffect, useState } from 'react'

import { format } from 'date-fns'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { Typography, Box, useTheme, IconButton } from '@mui/material'
import { useNavigate, Link } from 'react-router-dom'
import { routeRefs } from '@/routes/routesList'

import { CircleFlag } from 'react-circle-flags'
import FlexBox from '@/designSystem/FlexBox'
import { countryList } from '@/constants'
import ClipboardIcon from '@/designSystem/Icons/Clipboard'
import ExpandIcon from '@/designSystem/Icons/Expand'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { ReactComponent as EyeIcon } from '@/assets/icons/eye.svg'
import { useSelector } from 'react-redux'
import { selectLogsPage } from '@/store/advertisersLogs'

export function Header ({ header }: { header: string }) {
  if (header.startsWith('::')) {
    return ''
  }
  return (
    <Typography
      variant='body1'
      color="text1"
      width="max-content"
      sx={{
        display: 'flex'
      }}
    >
      {header}
    </Typography>
  )
}

interface NameFieldProps {
  name?: string
  id: number | string
}
export function NameField ({ name, id }: NameFieldProps) {
  const theme = useTheme()
  const openAdvertiser = () => window.open(`/trafic-distribution/advertisers/${id}`, '_blank')
  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="row"
      alignItems="center"
      width="max-content"
    >
      <Box
        onClick={openAdvertiser}
        display="span"
      >
          <Typography
            sx={{
              cursor: 'pointer',
              textDecoration: 'none'
            }}
            color={theme.palette.primary.main}
            variant="table1"
          >
            {name}
          </Typography>
      </Box>
    </Box>
  )
}

export function BooleanField ({ isSomething }: { isSomething: boolean }) {
  const { palette, spacing } = useTheme()
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={spacing(2)}
      justifyContent="flex-start"
      alignItems="center"
    >
      {isSomething
        ? <CheckRoundedIcon sx={{ fill: palette.primary.main }} />
        : <CloseRoundedIcon sx={{ fill: palette.red2.main }} />}
      <Typography variant='table1' color={palette.text2.main}>
        {isSomething ? 'Yes' : 'No'}
      </Typography>
    </Box>
  )
}

export function DateTimeField ({ date, colorText }: { date: Date | undefined, colorText?: string }) {
  const { palette } = useTheme()
  const validDate = date && !isNaN(date.getTime())
  return (
    <Typography
      sx={{ display: 'flex' }}
      variant='table1'
      width="max-content"
      color={colorText ?? palette.text2.main}
    >
      {validDate ? format(date, 'dd/MM/yyyy HH:mm:ss') : 'Empty'}
    </Typography>
  )
}

interface TextFieldProps {
  text?: string
  href?: string
  id?: string
  limitation?: number
  hideValue?: boolean
  isEyeIcon?: boolean
  onClickOpen?: (id: string) => void
  colorText?: string
}
export function TextField ({ text, href, limitation = 0, hideValue, isEyeIcon, onClickOpen, id, colorText }: TextFieldProps) {
  const [isCollapsed, setIsCollapsed] = useState(limitation ? true : undefined)
  const { palette } = useTheme()
  const page = useSelector(selectLogsPage)
  useEffect(() => {
    if (!isCollapsed) setIsCollapsed(true)
  }, [page])
  if (hideValue) {
    return (
      <FlexBox>
        <MoreHorizIcon />
        <MoreHorizIcon />
      </FlexBox>
    )
  }

  if (!text) {
    return (
      <Typography
        display="flex"
        variant="body1"
        color={colorText ?? palette.gray.main}
        // width="max-content"
      ></Typography>
    )
  }

  let useLimitation = !!limitation
  if (limitation && text.length <= limitation) {
    useLimitation = false
  }
  const limitationSx = isCollapsed && useLimitation
    ? {
        maxWidth: limitation === 1 ? 0 : `${limitation * 5}px`,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      }
    : undefined
  const onCopy = () => {
    text && navigator?.clipboard?.writeText(text)
  }

  const onCollapseExpand = () => {
    setIsCollapsed((val) => !val)
  }

  return (
    <Box
      display='flex'
      alignItems='center'
    >
      {isEyeIcon && <Box marginRight='8px' marginTop='5px' sx={{ cursor: 'pointer' }}
        onClick={() => onClickOpen && onClickOpen(typeof id === 'string' ? id : text)}><EyeIcon /></Box>}
      <LimitationWrapper onCopy={onCopy} onExpand={onCollapseExpand} useLimitation={useLimitation}>
        <Typography
          display="flex"
          variant="body1"
          color={colorText ?? palette.text1.main}
          width="max-content"
          sx={limitationSx}
        >
          {href
            ? (
              <Link to={href}>
                <Box sx={{ textTransform: 'none' }}>{text}</Box>
              </Link>
              )
            : <Box sx={{ textTransform: 'none' }}>{text}</Box>
          }
        </Typography>
      </LimitationWrapper>
    </Box>
  )
}

interface LimitationWrapperProps {
  children: React.ReactNode
  useLimitation?: boolean
  onCopy: () => void
  onExpand: () => void
}
const LimitationWrapper = ({ children, useLimitation, onCopy, onExpand }: LimitationWrapperProps) => {
  // if (!useLimitation) {
  //   return children
  // }
  return (
    <FlexBox gap={2} alignCenter>
      {children}
      <FlexBox gap={1} alignCenter>
        {useLimitation &&
         <IconButton onClick={() => onExpand()}>
           <ExpandIcon/>
         </IconButton>
        }
        <IconButton onClick={onCopy}>
          <ClipboardIcon opacity="0.7" />
        </IconButton>
      </FlexBox>
    </FlexBox>
  )
}

interface CTAFieldProps {
  icon: React.ReactNode
  onClick: () => void
}
export function CTAField ({ icon, onClick }: CTAFieldProps) {
  return (
    <IconButton onClick={onClick}>
      {icon}
    </IconButton>
  )
}

export function CountryField ({ countryCode, colorText }: { countryCode: string, colorText?: string }) {
  const countryCodeUppercase = countryCode?.toUpperCase() as keyof typeof countryList
  return (
    <>
      {countryCode && <FlexBox gap={2} alignCenter width="max-content">
        <CircleFlag countryCode={countryCode?.toLowerCase()} height="24px" />
        <Typography variant='table1' sx={{ color: colorText ?? '#393E51' }}>
          <Box display="inline" textTransform="uppercase" sx={{ color: colorText ?? '#393E51' }}>
            {countryCodeUppercase}
          </Box>
          /
          {countryList[countryCodeUppercase]}
        </Typography>
      </FlexBox>}
      {!countryCode && <Typography variant='body1' sx={{ color: colorText ?? '#6B6B6B' }}>Empty</Typography>}
    </>
  )
}

export function MoneyField ({ amount }: { amount?: number }) {
  const { palette } = useTheme()
  return (
    <Typography variant='table1' color={palette.text1.main}>
      ${(amount ?? 0).toFixed(2)}
    </Typography>
  )
}

export const Indicator = ({ active }: { active: boolean }) => {
  const { palette } = useTheme()
  return (
    <Box
      position='relative'
      display='flex'
      alignItems='center'
      width={0}
    >
      <Box
        sx={{
          borderRadius: '0px 6px 6px 0px',
          width: '6px',
          height: '36px',
          backgroundColor: active ? '#15A81B' : palette.red2.main,
          position: 'absolute'
        }}
      />
    </Box>
  )
}
