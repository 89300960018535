import React from 'react'

import useTheme from '@mui/material/styles/useTheme'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const helpInformation = {
  p1: 'Traffic is directed from top to bottom. Each entity has a rule determining what traffic it accepts. Numbers on the left from entities show their priority. Rotation Folder can contain other Folders or Advertisers. Split folder contains Advertisers and splits traffic based on percents. Inside Advertiser you can find the Cap - number of leads this advertiser can get.'
}

export default function HelpInformationModal () {
  const theme = useTheme()
  return (
  <>
    <Box
      display="flex"
      flexDirection="column"
      gap={theme.spacing(3)}
      color={theme.palette.text2.main}
    >
      <Typography variant="body2">
        {helpInformation.p1}
      </Typography>
    </Box>
  </>
  )
}
