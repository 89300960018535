import React, { useMemo } from 'react'
import Chip from '@/components/Advertisers/Filters/Chip'
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { Box, useTheme } from '@mui/material'
import cloneDeep from 'lodash.clonedeep'
import unset from 'lodash.unset'
import { type LogsFilter } from '@/store/advertisersLogs'
import { selectAdvertiserLogsQueryParams, setAdvertiserLogsQueryParams } from '@/store/queryParams'

const Bar = ({ children }: { children: React.ReactNode }) => {
  const { spacing, palette } = useTheme()
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={spacing(3)}
      justifyContent="flex-start"
      padding={spacing(3, 4)}
      width="100%"
      borderRadius="12px"
      sx={{
        backgroundColor: palette.card.main
      }}
    >
      {children}
    </Box>
  )
}

const areFiltersExist = (filters?: LogsFilter) => {
  if (!filters) {
    return false
  }
  return (
    !!filters?.createdAt?.from ||
    !!filters?.createdAt?.to ||
    !!filters?.uuid
  )
}

export default function ApiTokensFilterBar () {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectAdvertiserLogsQueryParams)

  const isParamExist = useMemo(() => areFiltersExist(queryParams?.filter), [queryParams?.filter])

  if (!isParamExist) {
    return null
  }

  const onDeleteParam = (label: string) => () => {
    const params = cloneDeep(queryParams)
    unset(params, `filter.${label}`)
    dispatch(setAdvertiserLogsQueryParams(params))
  }

  const filters = queryParams.filter!

  return (
    <Bar>
      {filters?.uuid && (
        <Chip
          label={`Name: "${filters.uuid}"`}
          onDelete={onDeleteParam('uuid')}
        />
      )}
      {filters?.createdAt?.from && (
        <Chip
          label={`Show from: ${format(new Date(filters.createdAt.from), 'P HH:mm')}`}
          onDelete={onDeleteParam('createdAt.from')}
        />
      )}
      {filters?.createdAt?.to && (
        <Chip
          label={`Show before: ${format(new Date(filters.createdAt.to), 'P HH:mm')}`}
          onDelete={onDeleteParam('createdAt.to')}
        />
      )}
    </Bar>
  )
}
