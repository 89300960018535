import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import { format } from 'date-fns'

import type { Option } from '@/designSystem/Autocomplete/types'
import setProperty from '@/utils/setProperty'
import FilterIcon from '@/designSystem/Pages/Advertisers/Icons/Filter'
import SettingsIcon from '@/assets/icons/Settings'
import { Box, useTheme } from '@mui/material'
import Button from '@/designSystem/Button'
import IconButton from '@/designSystem/IconButton'
import Autocomplete from '@/designSystem/Pages/Advertisers/Autocomplete'
import PresetsFiltersPopup from './Filters/PresetsFiltersPopup'
import PresetsSettingsPopup from './Filters/PresetsSettingsPopup'
import { useGetPresetsQuery } from '@/store/presets'
import isObjectNotEmpty from '@/utils/isObjectNotEmpty'
import { selectPresetsQueryParams, setPresetsQueryParams } from '@/store/queryParams'

const subtractDays = (startingDate: Date, daysCount: number) =>
  new Date(new Date().setDate(startingDate.getDate() - daysCount)).toISOString()
const subtractMonth = (startingDate: Date, monthsCount: number) =>
  new Date(new Date().setMonth(startingDate.getMonth() - monthsCount)).toISOString()
const subtractYears = (startingDate: Date, yearsCount: number) =>
  new Date(new Date().setFullYear(startingDate.getFullYear() - yearsCount)).toISOString()

const getDateValues = (someDate: Date): Array<Option<string>> => [
  { title: 'Day', value: subtractDays(someDate, 1) },
  { title: 'Week', value: subtractDays(someDate, 7) },
  { title: '1 Month', value: subtractMonth(someDate, 1) },
  { title: '6 Months', value: subtractMonth(someDate, 6) },
  { title: 'Year', value: subtractYears(someDate, 1) }
]

export default function RightActionButtons () {
  const theme = useTheme()
  const [isOpenFilterPopup, setIsOpenFilterPopup] = useState(false)
  const [isSettingsOpen, setIsSettingOpen] = useState(false)
  const [createdAt, setCreatedAt] = useState<string | null>(null)

  const [name, setName] = useState<string | null>(null)
  const [debouncedName] = useDebounce(name, 450)

  const dispatch = useDispatch()
  const queryParams = useSelector(selectPresetsQueryParams)

  const isFilter = queryParams?.filters && isObjectNotEmpty(queryParams?.filters)

  const onChangeDate = (value: Option<string> | null) => {
    setCreatedAt(value?.value ?? null)
    return value?.value && dispatch(setPresetsQueryParams({
      ...queryParams,
      page: 1,
      filters: setProperty({ ...queryParams?.filters }, 'createdTo', {
        from: new Date(value?.value).toISOString(),
        to: (new Date()).toISOString()
      })
    }))
  }
  const onInputChangeDate = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value
    if (!value) {
      dispatch(setPresetsQueryParams({
        ...queryParams,
        page: 1,
        filters: setProperty({ ...queryParams?.filters }, 'createdTo')
      }))
    }
  }

  const nameOptions: any = useGetPresetsQuery({ limit: 6, filters: debouncedName ? { label: debouncedName } : undefined })
    .data?.presets?.map((preset) => ({ title: preset?.label, value: preset?.label }))

  const onInputChangeName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value
    setName(value ?? null)
    if (!value) {
      dispatch(setPresetsQueryParams({
        ...queryParams,
        filters: setProperty({ ...queryParams?.filters }, 'label')
      }))
    } else {
      dispatch(setPresetsQueryParams({
        ...queryParams,
        filters: setProperty({ ...queryParams?.filters }, 'label', debouncedName)
      }))
    }
  }
  const onChangeName = (value: Option<string> | null) => {
    setName(value?.value ?? null)
    return value && dispatch(
      setPresetsQueryParams({
        ...queryParams,
        page: 1,
        filters: setProperty({ ...queryParams?.filters }, 'label', value?.value)
      })
    )
  }

  useEffect(() => {
    if (!queryParams?.filters?.label) {
      setName(null)
    }
  }, [queryParams?.filters?.label])

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={theme.spacing(4)}
      >
        {isFilter && <Button
          onClick={() => dispatch(setPresetsQueryParams({ ...queryParams, filters: undefined }))}
          text='Reset filter'
          variant='contained'
          sx={{
            backgroundColor: theme.palette.red2.main,
            padding: `${theme.spacing(2)} ${theme.spacing(6)}`
          }}
        />}
        <IconButton onClick={() => setIsOpenFilterPopup(true)}>
          <FilterIcon />
        </IconButton>
        <Box
          display="flex"
          alignItems="center"
          gap={theme.spacing(3)}
        >
          <Autocomplete
            onInputChange={onInputChangeName}
            onChange={onChangeName}
            value={{ value: name ?? '', title: name ?? '' }}
            clearIcon=''
            options={nameOptions ?? []}
            placeholder='Name'
            leftShift
          />
          <Autocomplete
            onInputChange={onInputChangeDate}
            onChange={onChangeDate}
            options={getDateValues(new Date())}
            value={createdAt
              ? { value: createdAt, title: format(new Date(createdAt), 'P') }
              : null}
            clearIcon=''
            placeholder='Select data'
            leftShift
          />
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => setIsSettingOpen(true)}
          >
            <SettingsIcon />
          </IconButton>
        </Box>
      </Box>
      {isSettingsOpen && (<PresetsSettingsPopup handleClose={() => setIsSettingOpen(false)} />)}
      {isOpenFilterPopup && (
        <PresetsFiltersPopup handleClose={() => setIsOpenFilterPopup(false)} />
      )}
    </>
  )
}
