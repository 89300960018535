import React from 'react'

const AdvertiserIcon = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ flexShrink: 0 }}>
        <rect x="0.5" width="32" height="32" rx="8" fill="#75DBF7"/>
        <path d="M11.864 21L15.476 11.2H17.1L20.684 21H19.116L18.276 18.606H14.272L13.432 21H11.864ZM14.692 17.43H17.856L16.274 12.964L14.692 17.43Z" fill="#F1F3FF"/>
    </svg>
  )
}

export default AdvertiserIcon
