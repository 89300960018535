import React from 'react'
import { useForm, type SubmitHandler, type UseFormReturn } from 'react-hook-form'
import { useUpdateLeadStateMutation } from '@/store/leads'
import Form from '@/components/Form'
import Box from '@mui/material/Box'
import ActionButtons from '@/designSystem/ActionButtons'
import Popup from '@/designSystem/Popup'
import { useTheme } from '@mui/material'
import DateTimePicker from '@/components/Form/DateTimePicker'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getApiError } from '@/utils'

export interface UpdateLeadStateInputs {
  conversionCreatedAt?: Date
}
export interface UpdateLeadStateProps {
  form: UseFormReturn<UpdateLeadStateInputs>
  onSubmit: SubmitHandler<UpdateLeadStateInputs>
  handleClose: () => void
  title: string

}
type UpdateLeadStateModalProps = Pick<UpdateLeadStateProps, 'handleClose'> & {
  leadIds: string[]
  clearIds?: () => void
}

export default function UpdateLeadStateModal ({ handleClose, leadIds, clearIds }: UpdateLeadStateModalProps) {
  const form = useForm<UpdateLeadStateInputs>()
  const [updateLeadToFtd] = useUpdateLeadStateMutation()
  const theme = useTheme()
  const onSubmit: SubmitHandler<UpdateLeadStateInputs> = async ({
    conversionCreatedAt
  }) => {
    for (const id of leadIds) {
      try {
        await updateLeadToFtd({
          id,
          conversionCreatedAt
        }).unwrap()
      } catch (err) {
        console.log('err', err)
        toast.error(getApiError(err))
      }
    }
    clearIds && clearIds()
    handleClose()
  }

  return (
    <Popup
      open
      title={'Update lead to FTD'}
       handleClose={handleClose}
    >
      <Form form={form} onSubmit={onSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          gap={theme.spacing(25)}
        >
          <Box display="flex" flexDirection="column" gap={theme.spacing(3)}>
            <DateTimePicker sx={{
              borderRadius: '16px',

              '& .MuiInputBase-root': {
                borderRadius: '12px',
                height: '50px',
                padding: theme.spacing(1),
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows[2]
              },
              '& .MuiIconButton-root': {
                right: theme.spacing(2)

              },
              '& .MuiCalendarPicker-root': {
                borderRadius: '12px'
              },
              '& .MuiTypography-root': {
                color: theme.palette.text.primary
              }
            }} label="Conversion Create Date" name="conversionCreatedAt" />

          </Box>

          <ActionButtons
            onCancel={handleClose}
          />
        </Box>
      </Form>
    </Popup>
  )
}
