import React from 'react'

import Box from '@mui/material/Box'
import Button from '@/designSystem/Button'
import useTheme from '@mui/material/styles/useTheme'
import { useNavigate } from 'react-router-dom'

export default function LeftActionButtons ({ isMenu }:
{ isMenu: boolean }): JSX.Element {
  const theme = useTheme()
  const navigate = useNavigate()

  const onClickReload = () => navigate(0)

  return (
    <>
      <Box
        display="flex"
        gap={theme.spacing(3)}
        alignItems="center"
      >
        <Button
          sx={{
            borderRadius: '16px',
            height: '30px',
            padding: `${theme.spacing(1.5)} ${theme.spacing(6)}`
          }}
          variant='contained'
          onClick={onClickReload}
          text="Reload"
          textVariant='subtitle1'
        />
      </Box>
    </>
  )
}
