import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
import { type IApiSettings } from '@/components/Advertisers/IntegrationSettingsPage/ApiConnectionSettings/EditPushSettingsForm'
import { type PullLeadSettingsType } from '@/enums/advertiser.enum'

export interface ExtraSettings {
  key: string
  value: string
  group: string
  label: string
}

export interface Advertiser {
  id: string
  name: string
  description?: string
  pullLeadStatuses?: boolean
  pullLeadState?: boolean
  status: 'ACTIVE' | 'INACTIVE'
  createdAt: string
  updatedAt?: string
  advertiserSettings?: IApiSettings[]
  pullLeadStatusesSettings?: IApiSettings[]
  pullLeadStateSettings?: IApiSettings[]
  logs?: any[]
}
export const advertisersHeaders: Record<string, string> = {
  id: 'UUID',
  name: 'Name',
  description: 'Description',
  active: 'Activity',
  pullLeadStatuses: 'Pull leads sale statuses',
  createdAt: 'Created At',
  updatedAt: 'Updated At'
}

const activeToStatusMap = (active?: boolean) => {
  if (active === undefined) {
    return undefined
  }
  if (active) {
    return 'ACTIVE'
  }
  if (!active) {
    return 'INACTIVE'
  }
}
export interface TestLeadPullResponse {
  error: boolean
  matchingLeads: Array<Record<string, unknown>>
  requestData: Record<string, unknown>
  responseData: unknown
}
export interface ManualLeadPullResponse {
  processedLeads: Array<Record<string, unknown>>
  advertiserLeads: Array<Record<string, unknown>>
  pullLeadLogs: Array<Record<string, unknown>>
}
export interface FiltersAdvertiser {
  createdAt?: {
    from?: string
    to?: string
  }
  status?: boolean
  id?: string[] | undefined
  name?: string
  pullLeadStatuses?: boolean
}

export interface AdvertisersState {
  totalCount?: number
  advertiserSettings: ExtraSettings[]
  pullLeadState: boolean
  pullLeadStatusesSettings: ExtraSettings[]
  pullLeadStateSettings: ExtraSettings[]
}

const initialState: AdvertisersState = {
  advertiserSettings: [],
  pullLeadState: false,
  pullLeadStatusesSettings: [],
  pullLeadStateSettings: []
}

export const advertisersSlice = createSlice({
  name: 'advertisers',
  initialState,
  reducers: {
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setPullLeadState: (state, action: PayloadAction<boolean>) => {
      state.pullLeadState = action.payload
    },
    setAdvertiserSettings: (state, action: PayloadAction<any[]>) => {
      state.advertiserSettings = action.payload
    },
    setPullLeadStatusesSettings: (state, action: PayloadAction<any[]>) => {
      state.pullLeadStatusesSettings = action.payload
    },
    setPullLeadStateSettings: (state, action: PayloadAction<any[]>) => {
      state.pullLeadStateSettings = action.payload
    }
  }
})

export const { setTotalCount, setAdvertiserSettings, setPullLeadStatusesSettings, setPullLeadStateSettings, setPullLeadState } = advertisersSlice.actions

export const selectTotalCount = (state: RootState) => state.advertisers.totalCount
export const selectPullLeadState = (state: RootState) => state.advertisers.pullLeadState
export const selectAdvertiserSettings = (state: RootState) => state.advertisers.advertiserSettings
export const selectPullLeadStatusesSettings = (state: RootState) => state.advertisers.pullLeadStatusesSettings
export const selectPullLeadStateSettings = (state: RootState) => state.advertisers.pullLeadStateSettings
export default advertisersSlice.reducer

interface GetAdvertisersArgs {
  limit?: number
  page?: number
  filters?: FiltersAdvertiser
  userId?: string
}

type CreateAdvertiserArgs = Pick<Advertiser, 'status' | 'name' | 'pullLeadStatuses'
| 'description' | 'advertiserSettings' | 'pullLeadStatusesSettings' | 'pullLeadStateSettings'>

type EditAdvertiserArgs = Pick<Advertiser, 'status' | 'name' | 'pullLeadStatuses' | 'pullLeadState'
| 'description' | 'advertiserSettings' | 'pullLeadStatusesSettings' | 'pullLeadStateSettings'>
interface GetAdvertisersReturn {
  advertisers: Advertiser[]
  totalCount: number
}

export const advertisersApi = createApi({
  reducerPath: 'advertisersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/advertisers`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['Advertisers'],
  endpoints: (builder) => ({
    getAdvertisers: builder.query<GetAdvertisersReturn, GetAdvertisersArgs>({
      query: ({ limit = 10, page = 1, filters, userId }) => ({
        url: '/search',
        method: 'POST',
        body: {
          limit,
          page,
          sortField: 'createdAt',
          sortOrder: 'desc',
          createdAt: filters?.createdAt ?? undefined,
          userId,
          filter: {
            status: activeToStatusMap(filters?.status) ?? undefined,
            id: filters?.id
              ? { in: filters.id }
              : undefined,
            name: filters?.name
              ? { contains: filters.name }
              : undefined,
            pullLeadStatuses: filters?.pullLeadStatuses
          }
        }
      }),
      providesTags: [{ type: 'Advertisers', id: 'AdvertisersLIST' }]
    }),
    getAdvertiser: builder.query<any, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'GET'
      }),
      providesTags: [{ type: 'Advertisers', id: 'Advertiser' }]
    }),
    createAdvertiser: builder.mutation<Advertiser, CreateAdvertiserArgs>({
      query: ({ name, description, pullLeadStatuses, status }) => ({
        url: '/',
        method: 'POST',
        body: {
          name,
          description,
          pullLeadStatuses,
          status
        }
      }),
      invalidatesTags: [{ type: 'Advertisers', id: 'AdvertisersLIST' }]
    }),
    editAdvertiser: builder.mutation<Advertiser, Partial<EditAdvertiserArgs> & { id: string }>({
      query: ({
        name, description, id, pullLeadStatuses, pullLeadState, status,
        advertiserSettings, pullLeadStatusesSettings, pullLeadStateSettings
      }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: {
          name,
          description,
          pullLeadStatuses,
          pullLeadState,
          status,
          advertiserSettings,
          pullLeadStatusesSettings,
          pullLeadStateSettings
        }
      }),
      invalidatesTags: [{ type: 'Advertisers', id: 'AdvertisersLIST' }]
    }),
    deleteAdvertiser: builder.mutation<Advertiser, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Advertisers', id: 'AdvertisersLIST' }]
    }),
    testLeadPull: builder.query<TestLeadPullResponse, { advertiserId: string, fromTime: Date | null, toTime: Date | null, settingsType: PullLeadSettingsType }>({
      query: ({ advertiserId, fromTime, toTime, settingsType }) => ({
        url: '/testLeadPull',
        method: 'POST',
        body: {
          settingsType,
          advertiserId,
          fromTime,
          toTime
        }
      })
    }),
    manualLeadPull: builder.query<ManualLeadPullResponse, { advertiserId: string, fromTime: Date | null, toTime: Date | null, settingsType: PullLeadSettingsType }>({
      query: ({ advertiserId, fromTime, toTime, settingsType }) => ({
        url: '/manualLeadPull',
        method: 'POST',
        body: {
          settingsType,
          advertiserId,
          fromTime,
          toTime
        }
      })
    }),
    testPreRequestCode: builder.query({
      query: ({ preRequestCode, advertiserId }: { preRequestCode: string, advertiserId: string }) => ({
        url: '/testPreRequestCode',
        method: 'POST',
        body: {
          preRequestCode,
          advertiserId
        }
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetAdvertisersQuery,
  useGetAdvertiserQuery,
  useCreateAdvertiserMutation,
  useEditAdvertiserMutation,
  useDeleteAdvertiserMutation,
  useTestLeadPullQuery,
  useLazyTestLeadPullQuery,
  useLazyManualLeadPullQuery,
  useTestPreRequestCodeQuery,
  useLazyTestPreRequestCodeQuery
} = advertisersApi
