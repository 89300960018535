import Box from '@mui/material/Box'
import React, { useState } from 'react'

import {
  selectCurrentRange, selectTopAdvertisersCharts,
  useSearchLeadChartQuery
} from '@/store/dashboard'
import { useSelector } from 'react-redux'
import { ReactComponent as Reload } from '@/assets/icons/tabler_reload.svg'

interface TopAdvertisersChartLeftButtonsProps {
  refetchLeadsCurrentPeriod: () => void
  refetchLeadsPreviousPeriod: () => void
}

export default function TopAdvertisersChartLeftButtons ({ refetchLeadsCurrentPeriod, refetchLeadsPreviousPeriod }: TopAdvertisersChartLeftButtonsProps) {
  const { timePeriod } = useSelector(selectTopAdvertisersCharts)
  const [rotating, setRotating] = useState(false)
  const handlerReload = () => {
    setRotating(true)
    refetchLeadsCurrentPeriod()
    if (timePeriod !== 'none') {
      refetchLeadsPreviousPeriod()
    }
    setTimeout(() => setRotating(false), 1000)
  }

  return (
    <Box
      onClick={handlerReload}
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
      }}
    >
      <Reload className={rotating ? 'rotating' : ''}/>
      <style>{`
        .rotating {
          animation: rotate 1s linear;
        }

        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </Box>
  )
}
