/* eslint-disable no-lone-blocks */
import React, { useState } from 'react'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'

import Popup from '@/designSystem/Popup'
import { Box, Typography, useTheme } from '@mui/material'
import Form from '../../../Form'
import ActionButtons from '@/designSystem/ActionButtons'
import TextField from '@/designSystem/TextField'
import IOSSwitch from '@/components/Form/Switch'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import { type IApiTokens, useCreateApiTokensMutation, useUpdateApiTokensMutation } from '@/store/apiTokens'
import { placeholderValues } from '@/constants'

interface AddApiTokens {
  name: string
  ips?: string
  leadProfileKey?: string
  valueLeadProfile?: string
  active?: boolean
}

interface IPropsAddInjModal {
  handleClose: () => void
  open: boolean
  editToken?: IApiTokens
}

export const AddEditApiTokensModal = ({ handleClose, open, editToken }: IPropsAddInjModal) => {
  const [addApiTokens] = useCreateApiTokensMutation()
  const [editApitoken] = useUpdateApiTokensMutation()
  const theme = useTheme()

  const [error, setError] = useState<string>('')

  const form = useForm<AddApiTokens>({
    defaultValues: {
      name: editToken?.name ?? '',
      ips: editToken?.ips?.join(',') ?? '',
      leadProfileKey: editToken?.leadProfile ? Object.keys(editToken?.leadProfile)?.[0] : '',
      valueLeadProfile: editToken?.leadProfile ? Object.values(editToken?.leadProfile)?.[0] : '',
      active: editToken?.active ?? true
    }
  })

  const {
    control: formControl
  } = form

  const onSubmit: SubmitHandler<AddApiTokens> = async (data) => {
    if (!(data?.name)) {
      setError('Required field')
      return
    } else {
      setError('')
    }
    const leadProfileKey = data?.leadProfileKey
    const valueLeadProfile = data?.valueLeadProfile

    let leadProfile = {}
    if (leadProfileKey && valueLeadProfile) {
      leadProfile = { [leadProfileKey]: valueLeadProfile }
    }
    const apisArr = data?.ips?.split(',')
    {
      !editToken && await addApiTokens({
        name: data?.name ?? '',
        ips: data?.ips ? apisArr : undefined,
        leadProfile: leadProfile ?? undefined
      }).unwrap()
    }
    {
      editToken && await editApitoken({
        id: editToken?.id ?? '',
        ips: data?.ips ? apisArr : undefined,
        leadProfile: leadProfile ?? undefined,
        active: data?.active ?? undefined,
        apiToken: editToken?.token ?? undefined
      }).unwrap()
    }
    handleClose()
  }

  return (
    <Popup
      open={open}
      title={editToken ? 'Edit Api Token' : 'Create Api Token'}
      handleClose={handleClose}
      overflow='auto'
      maxWidth='600px'
    >
      <ScrollBarContainer>
        <Form form={form} onSubmit={onSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            gap={'15px'}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={'5px'}
            >
              <Controller
                control={formControl}
                name="name"
                render={({ field }) => (
                  <TextField {...field} title={'Name'} />
                )}
              />
              {error && <Typography sx={{
                color: theme.palette.error.main,
                marginTop: '1px',
                fontSize: '12px'
              }}>{error}</Typography>}
            </Box>

            <Controller
              control={formControl}
              name="ips"
              render={({ field }) => (
                <TextField {...field} title={'Ips'} />
              )}
            />

            <Controller
              control={formControl}
              name="leadProfileKey"
              render={({ field }) => (
                <TextField {...field} placeholders={placeholderValues} placeholder='Change key'
                  title={'Lead Profile'} notInput />
              )}
            />

            <Controller
              control={formControl}
              name="valueLeadProfile"
              render={({ field }) => (
                <TextField {...field} placeholder='Enter value' title={'Value Lead Profile'} />
              )}
            />

            {!!editToken && <Controller
              control={formControl}
              name="active"
              render={({ field }) => (
                <IOSSwitch {...field} formControlLabel={{
                  label: 'Active',
                  labelPlacement: 'end',
                  sx: {
                    margin: 0,
                    '& .MuiTypography-root': {
                      marginRight: theme.spacing(3),
                      marginLeft: theme.spacing(1.5)
                    }
                  }
                }} />
              )}
            />}
          </Box>
          <ActionButtons
            sx={{ paddingTop: '24px' }}
            onCancel={handleClose}
            submitText="Submit"
            cancelText='Cancel'
          />
        </Form>
      </ScrollBarContainer>
    </Popup >
  )
}
