import cloneDeep from 'lodash.clonedeep'

type ObjectToRemoveProperty = Record<string, unknown> | undefined

export default function setPropery (
  obj: ObjectToRemoveProperty,
  key: string,
  value?: unknown
) {
  if (!obj) {
    return undefined
  }
  const copy = cloneDeep(obj)
  if (value) {
    copy[key] = value
  } else {
    delete copy[key]
  }

  return copy
}
