import React from 'react'

const Postback = () => {
  return (
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="8" fill="#F8D8BD"/>
<path d="M12.494 21V11.2H15.924C16.6893 11.2 17.324 11.3307 17.828 11.592C18.332 11.844 18.7053 12.194 18.948 12.642C19.2 13.0807 19.326 13.58 19.326 14.14C19.326 14.672 19.2047 15.162 18.962 15.61C18.7287 16.0487 18.36 16.3987 17.856 16.66C17.352 16.9213 16.708 17.052 15.924 17.052H13.978V21H12.494ZM13.978 15.834H15.854C16.554 15.834 17.0533 15.6847 17.352 15.386C17.66 15.078 17.814 14.6627 17.814 14.14C17.814 13.5987 17.66 13.1787 17.352 12.88C17.0533 12.572 16.554 12.418 15.854 12.418H13.978V15.834Z" fill="#000931"/>
</svg>

  )
}

export default Postback
