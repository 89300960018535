import React, { useEffect } from 'react'
import PageContentLayout from '@/designSystem/PageContentLayout'
import { Box, Typography } from '@mui/material'
import Button from '@/designSystem/Button'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { DealsFormLayout } from './DealsFormLayout'
import { DealsFormLayoutLast } from './DealsFormLayoutLast'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { type Advertiser, useGetAdvertisersQuery } from '@/store/advertisers'
import { DealsCountriesTable } from './DealsCountriesTable'
import { useCreateDealsMutation } from '@/store/deals'
import { selectConversions, setConversions } from '@/store/conversions'
import { selectQueryParamsAdvertisers } from '@/store/queryParams'

export interface IDealsSubmit {
  conversionIds: string[]
  advertiserId: { title: string, value: string | null }
  goalType: { title: string, value: string | null }
  countries: string[]
  newRevenue?: string
  reason: string
}

export default function DealsUpdaterPage () {
  const { filters } = useSelector(selectQueryParamsAdvertisers)
  const { data } = useGetAdvertisersQuery({ limit: 500, filters, page: 1 })
  const [addDeal] = useCreateDealsMutation()
  const conversions = useSelector(selectConversions)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(location.pathname, { replace: true })
    }, 2000)
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    dispatch(setConversions([]))
  }, [location.pathname])

  const [step, setStep] = React.useState<number>(1)

  const optionsAdvertisers = data?.advertisers?.map((advertiser: Advertiser) => {
    return {
      title: advertiser?.name,
      value: advertiser?.id
    }
  })

  const form = useForm<IDealsSubmit>({
    defaultValues: {
      conversionIds: location.state?.ids?.split(',') ?? [],
      advertiserId: { title: 'Select advertiser', value: null },
      goalType: { title: 'Select goal', value: null },
      countries: [],
      newRevenue: '',
      reason: ''
    }
  })

  const advertiser = form.watch('advertiserId')

  const onSubmit: SubmitHandler<IDealsSubmit> = async (data: any) => {
    await addDeal({
      newRevenue: data?.newRevenue,
      reason: data?.reason,
      conversionIds: data?.conversionIds ? data?.conversionIds : undefined
    }).unwrap().then(() => {
      setStep(1)
      form.reset()
      dispatch(setConversions([]))
    }).catch(() => {
    })
  }

  const handleGoToHistory = () => {
    navigate('/finance-and-tools/deals-history')
  }

  return (
    <PageContentLayout title={<Box
      width={'100%'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Box
        fontSize='28px'
        fontStyle='normal'
        fontWeight='500'
        lineHeight='normal'
        textTransform='capitalize'>
        Deals Updater
      </Box>
      <Button
        sx={{
          borderRadius: '16px',
          height: '40px',
          width: '145px',
          padding: '8px 16px',
          position: 'absolute',
          right: '0',
          marginRight: '24px'
        }}
        variant='contained'
        onClick={handleGoToHistory}
        text="Go to History"
        textVariant='subtitle1' />
    </ Box>}>
      <Typography
        paddingBottom={'40px'}
      >
        First you need select Advertiser and Date Range or LeadsUuids
      </Typography>

      <Box
        display={'flex'}
        alignItems={'flex-start'}
      >
        <Box
          width='585px'
          height={step === 2 ? '610px' : '900px'}
          padding='24px 32px'
          justifyContent='center'
          alignItems='flex-start'
          gap='8px'
          borderRadius='16px'
          sx={{
            backgroundColor: '#FFFFFF'
          }}
        >
          <Box>
            {step === 1 && <Box
              display={'flex'}
              alignItems={'center'}
              fontSize={'24px'}
              fontStyle={'normal'}
              fontWeight={700}
              color={'#000931'}
            >
              <Typography
                sx={{
                  color: '#6B6B6B',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: 'normal',
                  marginRight: '8px'
                }}
              >Step 1:</Typography>Configure Payout/Revenue Update</Box>}
            {step === 2 && <Box
              display={'flex'}
              alignItems={'center'}
              fontSize={'24px'}
              fontStyle={'normal'}
              fontWeight={700}
              color={'#000931'}
            >
              <Typography
                sx={{
                  color: '#6B6B6B',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: 'normal',
                  marginRight: '8px'
                }}
              >Step 2: </Typography>Approve Operation</Box>}
          </Box>
          <Box>
            {step === 1 &&
              <DealsFormLayout form={form} onSubmit={onSubmit}
                setStep={setStep} optionsAdvertisers={optionsAdvertisers} />}
            {step === 2 &&
              <DealsFormLayoutLast form={form} onSubmit={onSubmit} setStep={setStep}
                deals={conversions} />}
          </Box>
        </Box>
        {conversions?.length > 0 &&
          <DealsCountriesTable advertiser={advertiser ?? { title: '', value: null }}
            deals={conversions} />}
      </Box>
    </PageContentLayout>
  )
}
