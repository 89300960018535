import React from 'react'
import MuiAutocomplete from '@mui/material/Autocomplete'
import Popper from '@mui/material/Popper'
import TextField, { type TextFiledProps } from '@/designSystem/TextField'

import useTheme from '@mui/material/styles/useTheme'

import type { InputProps } from '@mui/material/Input'
import type { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete'
import type { Option } from '@/designSystem/Autocomplete/types'

type CustomAutocomplete<T> = Omit<MuiAutocompleteProps<
Option<T>,
undefined,
undefined,
undefined
>, 'renderInput' | 'onChange' | 'onInputChange'>

export type AutocompleteProps<T> = CustomAutocomplete<T> & {
  options: Array<Option<T>>
  onChange: (value: Option<T> | null) => void
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  leftShift?: boolean
  required?: boolean
  textFieldProps?: {
    root?: TextFiledProps
    input?: InputProps
  }
  plaseholder?: string
}

export default function Autocomplete<T> ({
  options,
  textFieldProps,
  leftShift,
  onChange,
  onInputChange,
  title,
  required,
  plaseholder,
  ...rest
}: AutocompleteProps<T>) {
  const theme = useTheme()
  const sx = textFieldProps?.root?.sx
  return (
    <MuiAutocomplete
      disablePortal
      options={options}
      getOptionLabel={(option) => option.title}
      onChange={(e, newValue) => {
        onChange(newValue)
      }}
      PopperComponent={(popperProps) => (
        <Popper
          sx={{
            marginLeft: leftShift ? '4px !important' : undefined,
            offset: 0,
            paddingTop: theme.spacing(2),
            '& .MuiPaper-root': {
              boxShadow: 'none',
              transition: 'none',
              border: '1px solid #4141D5',
              borderRadius: '16px',
              marginBottom: '30px'
            }
          }}
          {...popperProps}
        >
          {popperProps.children}
        </Popper>
      )}
      {...rest}
      renderInput={(params) => (
        <TextField
          onChange={onInputChange}
          {...params}
          {...textFieldProps?.root}
          placeholder={plaseholder ?? 'Change value…'}
          required={required}
          sx={{
            textField: {
              padding: `0 ${theme.spacing(10)} 0 ${theme.spacing(4)}`
            },
            ...sx
          }}
          title={title}
          InputProps={{
            ...params.InputProps,
            ...textFieldProps?.input
          }}
        />
      )}
    />
  )
}
