import React from 'react'
import Box from '@mui/material/Box'
import { ReactComponent as EyeIcon } from '@/assets/icons/eye.svg'

export function TableLink ({ link, label = '' }: { link: string, label?: string }) {
  return (
    <Box display='flex'
         alignItems='center'>
      <Box marginRight='8px' marginTop='5px' sx={{ cursor: 'pointer' }}
           onClick={() => window.open(link, '_blank')}><EyeIcon /></Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '4px',
        color: '#4141D5',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        cursor: 'pointer'
      }}
      onClick={() => window.open(link, '_blank')}>
        {label}
    </Box>
    </Box>
  )
}
