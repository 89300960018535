const itemsStyles = {
  margin: '16px 8px',
  padding: '2px 4px',
  borderRadius: '8px',
  background: '#000e4b',
  color: 'white'
}

const longLine = {
  height: '1px',
  flex: '1 1 100%',
  background: '#000e4b'
}
const shortLine = {
  height: '1px',
  flex: '0 0 12px',
  background: '#000e4b'
}

const connectorStyles = {
  margin: '16px 8px',
  padding: '2px 4px',
  borderRadius: '8px',
  background: '#000e4b',
  color: 'white',
  cursor: 'pointer'
}

const disabledConnector = {
  background: '#7F86A5'
}
const autocompleteStyle = { flex: '1 1 100%', margin: 2, '>div>div>div': { padding: '0 0 0 9px !important' } }

export {
  itemsStyles, longLine, shortLine, connectorStyles, disabledConnector, autocompleteStyle
}
