import React, { useEffect } from 'react'
import ActionButtons from '@/designSystem/ActionButtons'
import Popup from '@/designSystem/Popup'
import { Box, Typography, useTheme } from '@mui/material'
import { useEditAutoLoginMutation, useGetAllSettingsQuery } from '@/store/settings'
import TextField from '@/designSystem/TextField'

interface IPopup {
  open: boolean
  handleClose: () => void
}

export const AutoLoginDomainPopup = ({ open, handleClose }: IPopup) => {
  const theme = useTheme()
  const [submitAutoLogin] = useEditAutoLoginMutation()
  const leadIntervalRefetch = useGetAllSettingsQuery({}).refetch
  const autoLoginDomain = useGetAllSettingsQuery({})?.data?.autoLoginDomain

  const [autoLogin, setAutoLogin] = React.useState<string | null>(null)
  const [error, setError] = React.useState<string>('')

  useEffect(() => {
    autoLoginDomain && setAutoLogin(autoLoginDomain)
  }, [autoLoginDomain])

  useEffect(() => {
    void leadIntervalRefetch()
  }, [open])

  const onChangeAutoLogin = (value: string) => {
    setAutoLogin(value)
  }

  const handleCanseled = () => {
    handleClose()
    setAutoLogin(autoLoginDomain ?? null)
    setError('')
  }

  const onSubmit = async () => {
    if (!autoLogin) {
      setError('Required field')
      return
    }
    await submitAutoLogin({
      autoLoginDomain: autoLogin
    }).then((res: any) => {
      if (res?.error?.data?.message) {
        setError(res.error.data.message)
      } else {
        handleCanseled()
      }
    })
  }

  return (
    <Popup
      open={open}
      title="Edit Auto Login"
      handleClose={handleCanseled}
      overflow='auto'
      maxWidth='600px'
      padding='40px'
    >
      <Box
        height={'290px'}
        display='flex'
        flexDirection='column'
        justifyContent={'space-between'}>

        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
        >
          <Typography
            sx={{
              marginBottom: theme.spacing(2),
              marginTop: theme.spacing(8)
            }}
          >
            Enter the auto login domain
          </Typography>

          <TextField
            value={autoLogin}
            onChange={(e: any) => e?.target?.value && onChangeAutoLogin(e.target.value)}
            style={{ width: '100%' }}
          />

          {error && <Typography sx={{
            color: theme.palette.error.main,
            marginTop: '4px',
            fontSize: '12px'
          }}>{error}</Typography>}

        </Box>
        <ActionButtons
          sx={{ paddingTop: '24px' }}
          onCancel={handleCanseled}
          onSubmit={onSubmit}
          submitText="Submit"
          cancelText='Cancel'
        />
      </Box>
    </Popup>
  )
}
