import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'

import { type IAdveriserRevenue } from '@/components/Pages/LeadConvertions/AdvertiserRevenue/AdvertiserRevenueTypes'

export interface ExtraSettings {
  key: string
  value: string
}

export interface TimeReset {
  id: string
  time: string
}

export type RotationControlWithExtraSettings = IAdveriserRevenue & {
  other?: ExtraSettings[]
}

export interface FiltersRevenue {
  status?: IAdveriserRevenue['status']
  country?: string[]
  advertiserId?: string[]
}

interface GetRotationControlArgs {
  limit?: number
  page?: number
  filters?: FiltersRevenue
}

type CreateEditAdvertiserArgs = Partial<IAdveriserRevenue>

export const advertiserRevenueApi = createApi({
  reducerPath: 'advertiserRevenueApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/rotations`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['AdvertiserRevenue', 'TimeReset'],
  endpoints: (builder) => ({
    getRevenues: builder.query<IAdveriserRevenue[], GetRotationControlArgs>({
      query: ({ filters }) => ({
        url: '/get-advertiser-revenues',
        method: 'POST',
        body: {
          filter: filters
        }
      }),
      providesTags: [{ type: 'AdvertiserRevenue', id: 'LIST' }]
    }),
    editRevenue: builder.mutation<
    IAdveriserRevenue,
    Partial<CreateEditAdvertiserArgs> & { id: string }
    >({
      query: ({ id, ...rest }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: rest
      }),
      invalidatesTags: [{ type: 'AdvertiserRevenue', id: 'LIST' }]
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetRevenuesQuery,
  useEditRevenueMutation
} = advertiserRevenueApi
