import { Box, Typography } from '@mui/material'
import { lineElementClasses } from '@mui/x-charts/LineChart'
import React, { useEffect, useMemo, useState } from 'react'
import { axisClasses } from '@mui/x-charts/ChartsAxis'
import ChartLayout from '@/designSystem/ChartLayout/ChartLayout'
import CircularProgress from '@mui/material/CircularProgress'
import TableLayout from '@/designSystem/ChartTable'
import elementMap from '../TopCountryChart/ElementMap'
import { type IChartLead, useSearchLeadChartQuery, selectCurrentRange, selectTopCountryCharts, setTopCountryCharts } from '@/store/dashboard'
import { type TableRowCount, generateBandChartContryData, generateBandChartData } from '../utils'
import { BarChart } from '@mui/x-charts/BarChart'
import TopCountryChartRightButton from './TopCountryChartRightButton'
import { useSelector, useDispatch } from 'react-redux'
import TopCountryChartLeftButton from './TopCountryChartLeftButton'
import TopAdvertisersChartLeftButtons from '@/components/Dashboard/TopAdvertisersCharts/TopAdvertisersChartLeftButtons'
type TimePeriod = 'none' | 'yesterday_today' | 'lastWeek_currentWeek' | 'lastMonth_currentMonth' | 'lastYear_currentYear'

export default function TopCountryChart () {
  const [isCheck, setIsCheck] = useState({ elementIndex: 0 })
  const [leadDataChart, setLeadDataChart] = useState([])
  const [leadDataChartLast, setLeadDataChartLast] = useState([])
  const [headers, setHeaders] = useState<any>()
  const [showFtd, setShowFtd] = useState(true)
  const dispatch = useDispatch()
  const currentRange = useSelector(selectCurrentRange)
  const topCountryDataCharts = useSelector(selectTopCountryCharts)

  const { data: searchLeadChart, isLoading: isLoadingCurrent, refetch: refetchLeadsCurrentPeriod } = useSearchLeadChartQuery(
    topCountryDataCharts.timePeriod === 'none' ? currentRange : topCountryDataCharts.localCurrentRange
  )
  const { data: searchLeadChartLast, isLoading: isLoadingLast, refetch: refetchLeadsPreviousPeriod } = useSearchLeadChartQuery(topCountryDataCharts.localLastRange)

  useEffect(() => {
    const headersMap: Record<TimePeriod, string[]> = {
      none: ['Position', 'Country', 'Total FTD', 'Leads'],
      yesterday_today: ['Position', 'Country', 'Total FTD', 'Yesterday FTD', 'Leads', 'Yesterday Leads'],
      lastWeek_currentWeek: ['Position', 'Country', 'Total FTD', 'Last Week FTD', 'Leads', 'Last Week Leads'],
      lastMonth_currentMonth: ['Position', 'Country', 'Total FTD', 'Last Month FTD', 'Leads', 'Last Month Leads'],
      lastYear_currentYear: ['Position', 'Country', 'Total FTD', 'Last Year FTD', 'Leads', 'Last Year Leads']
    }

    const timePeriod = topCountryDataCharts.timePeriod as TimePeriod
    setHeaders(headersMap[timePeriod])
  }, [topCountryDataCharts.timePeriod])

  useEffect(() => {
    dispatch(
      setTopCountryCharts({
        ...topCountryDataCharts,
        leads: searchLeadChart?.data && searchLeadChart?.data,
        pastTimeLeads: searchLeadChartLast?.data && searchLeadChartLast?.data
      })
    )
  }, [searchLeadChart?.data, searchLeadChartLast?.data, currentRange])

  useEffect(() => {
    if (topCountryDataCharts.leads) {
      setLeadDataChart(topCountryDataCharts.leads.filter((lead: { country: string | any[] }) => lead.country.length > 0))
      if (topCountryDataCharts.timePeriod !== 'none' && topCountryDataCharts.pastTimeLeads) {
        setLeadDataChartLast(topCountryDataCharts.pastTimeLeads.filter((lead: { country: string | any[] }) => lead.country.length > 0))
      }
    }
  }, [topCountryDataCharts.leads, topCountryDataCharts.pastTimeLeads, topCountryDataCharts.timePeriod, showFtd])

  const chartData = useMemo(() => {
    if (topCountryDataCharts.timePeriod === 'none') {
      return generateBandChartData('country', leadDataChart)
    } else {
      return generateBandChartData('country', leadDataChart)
    }
  }, [leadDataChart, leadDataChartLast, topCountryDataCharts.timePeriod])

  const fieldToCheck = showFtd ? 'ftd' : 'leads'
  const headerNodes: React.ReactNode[] = headers?.map((header: string) => <Header key={header} header={header} />)
  const rowNodes: any = chartData?.sort((a: any, b: any) => b[fieldToCheck] - a[fieldToCheck])?.slice(0, 5)?.map((row: TableRowCount, index: number) => (
    headers?.map((header: string) => elementMap(header, row, index, isCheck, topCountryDataCharts))
  ))

  const isLoading = isLoadingCurrent || isLoadingLast

  return (
    <ChartLayout
      title='Top Country'
      rightActionButtons={
        <TopCountryChartRightButton showFtd={showFtd} onShowFtdChange={setShowFtd} />
      }
      leftActionButtons={<TopCountryChartLeftButton
        refetchLeadsCurrentPeriod={refetchLeadsCurrentPeriod} refetchLeadsPreviousPeriod={refetchLeadsPreviousPeriod} />}
      width='100%'>

      <Box width={{ base: '1300px', lg: '100%' }} overflow={'auto'} sx={{ height: '100%', padding: '24px', bgcolor: 'white', borderRadius: '16px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        {isLoading
          ? (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '100%', height: '450px' }}>
            <CircularProgress />
          </Box>)
          : (<BarChart
            grid={{ vertical: true }}
            height={450}
            dataset={chartData as any[]}
            layout="horizontal"
            margin={{ top: 40, right: 40, bottom: topCountryDataCharts.timePeriod !== 'none' ? 100 : 60, left: 140 }}
            slotProps={{
              legend: {
                labelStyle: {
                  fontSize: 14,
                  fill: 'black',
                  fontWeight: 500
                },
                itemMarkWidth: 20,
                itemMarkHeight: 20,
                markGap: 19,
                itemGap: 19,
                position: {
                  vertical: 'bottom',
                  horizontal: 'middle'
                },
                direction: 'row',
                padding: 20
              }
            }}
            bottomAxis={{
              disableLine: true,
              disableTicks: true,
              tickLabelStyle: {
                // angle: 7,
                textAnchor: 'start',
                fontSize: 18,
                lineHeight: 30,
                fill: '##000931'
              }
            }}
            leftAxis={{
              disableLine: true,
              disableTicks: true,
              tickLabelStyle: {
                // angle: 7,
                textAnchor: 'end',
                fontSize: 18,
                lineHeight: 30,
                fill: '##000931'
              }
            }}
            series={topCountryDataCharts.timePeriod === 'none'
              ? [
                  {
                    dataKey: showFtd ? 'ftd' : 'leads',
                    highlightScope: {
                      highlighted: 'item',
                      faded: 'global'
                    }
                  }
                ]
              : [
                  {
                    dataKey: showFtd ? 'ftd' : 'leads',
                    label: getLabel(topCountryDataCharts.timePeriod),
                    highlightScope: {
                      highlighted: 'item',
                      faded: 'global'
                    }
                  },
                  {
                    dataKey: showFtd ? 'ftdLast' : 'leadsLast',
                    label: getLabelLast(topCountryDataCharts.timePeriod),
                    highlightScope: {
                      highlighted: 'item',
                      faded: 'global'
                    }
                  }
                ]}
            xAxis={[{
              tickMinStep: 1,
              valueFormatter: (value: number | null) => (value === null ? '' : value.toString()),
              tickPlacement: 'middle',
              tickLabelPlacement: 'middle'
            }]}
            yAxis={[
              {
                scaleType: 'band',
                dataKey: 'country'
              }
            ]}
            sx={{
              [`& .${lineElementClasses.root}`]: {
                display: 'flex'
              },
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                // Move the y-axis label with CSS
                transform: 'translateX(-75px)'
              }
            }}
          />
            )}
        <TableLayout
          headers={headerNodes}
          rows={rowNodes ?? []}
          setIsCheck={setIsCheck}
          sx={{
            '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-of-type': {
              width: 0
              // marginLeft: '-40%'
            }
          }}
          width='100%'
        />
      </Box>
    </ChartLayout>
  )
}

const Header = ({ header }: { header: string }) => {
  return (
    <Typography
      variant='body1'
      color='text1'
      width='max-content'
      sx={{
        fontWeight: 500,
        fontSize: '20px',
        display: 'flex'
      }}
    >
      {header}
    </Typography>
  )
}

const getLabel = (timePeriod: any) => {
  switch (timePeriod) {
    case 'yesterday_today':
      return 'Today'
    case 'lastWeek_currentWeek':
      return 'Current Week'
    case 'lastMonth_currentMonth':
      return 'Current Month'
    case 'lastYear_currentYear':
      return 'Current Year'
    default:
      return ''
  }
}

const getLabelLast = (timePeriod: any) => {
  switch (timePeriod) {
    case 'yesterday_today':
      return 'Yesterday'
    case 'lastWeek_currentWeek':
      return 'Last Week'
    case 'lastMonth_currentMonth':
      return 'Last Month'
    case 'lastYear_currentYear':
      return 'Last Year'
    default:
      return ''
  }
}
