import React, { useEffect, useState } from 'react'
import { Typography, useTheme } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'
import Table from '@/designSystem/Table'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import Popup from '@/designSystem/Popup'
import { ToastContainer } from 'react-toastify'
import {
  CTAField,
  Header,
  TextField
} from '@/designSystem/Table/Fields'

import EditSettingsForm, { type IApiSettings } from '@/components/Advertisers/IntegrationSettingsPage/ApiConnectionSettings/EditPushSettingsForm'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'

const headers = ['Key', 'Value']
interface RowPushSettings {
  key?: string
  group: string
  value?: string
  label: string
}
interface PushSettingsConnectionTableProps {
  rows: RowPushSettings[]
  advertiserId?: string
}

export default function PushSettingsConnectionTable ({
  rows
}: PushSettingsConnectionTableProps) {
  const { palette } = useTheme()
  const [isOpenAddPopup, setIsOpenAddPopup] = useState(false)
  const [settings, setSettings] = useState<IApiSettings[] | undefined>([])

  useEffect(() => {
    const newSettings: IApiSettings[] = reduceConnectionSettings(rows)
    setSettings(newSettings)
  }, [rows])

  function reduceConnectionSettings (settings: IApiSettings[]) {
    return settings.reduce((acc: IApiSettings[], setting: IApiSettings) => {
      // const isUrlTokenHeader = setting?.key === 'url' ||
      //   setting?.key === 'header_authorization_field' || setting?.key === 'status_field' || setting?.key === 'request_timeout' ||
      //   setting?.key === 'lead_id_field' || setting?.key === 'request_type' || setting?.key === 'response_code_value' ||
      //   setting?.key === 'status_field_value' || setting?.key === 'status_check_type' || setting?.key === 'crm_sale_status_to_use' ||
      //   setting?.key === 'auto_login_url_field'
      const isConnectionGroup = setting?.group === 'connection'
      const isHeadersGroup = setting?.group === 'headers'
      if (isConnectionGroup && !isHeadersGroup) {
        acc.push({
          key: setting?.key ?? '',
          group: setting?.group ?? '',
          value: setting?.value ?? '',
          label: setting?.label ?? ''
        })
      }
      return acc
    }, [])
  }

  const headerNodes = headers.map((header) => <Header header={header} key={header} />)

  const rowNodes = settings?.map((row: any) => (
    headers.map((header) => fieldToElementMap(header, row))
  ))

  return (
    <>
      <FlexBox column gap={3}>
        <FlexBox gap={4} alignCenter>
          <Typography variant='h2'>
            Connection
          </Typography>
          <CTAField
            onClick={() => setIsOpenAddPopup(true)}
            icon={<EditRoundedIcon sx={{ fill: palette.primary.main }} />}
          />
        </FlexBox>
        <Table headers={headerNodes} rows={rowNodes ?? []} />
      </FlexBox>
      <ToastContainer/>
      {isOpenAddPopup && (
        <Popup
          open
          title="Edit Connection Settings"
          subTitle={''}
          handleClose={() => setIsOpenAddPopup(false)}
        >
          <ScrollBarContainer>
            <EditSettingsForm handleClose={() => setIsOpenAddPopup(false)} />
          </ScrollBarContainer>
        </Popup>
      )}
    </>
  )
}

const fieldToElementMap = (header: string, row: RowPushSettings) => {
  const {
    key,
    value
  } = row
  switch (header) {
    case headers[0]:
      return <TextField text={key} />
    case headers[1]:
      return <FlexBox sx={{ alignItems: 'flex-start', width: '250px' }}>
        <TextField text={value} limitation={30} />
      </FlexBox>
  }
}
