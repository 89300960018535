import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'

export interface SaleStatusesMappingState {
  totalCount?: number
}

const initialState: SaleStatusesMappingState = {
  totalCount: 0
}

export const dealsSlice = createSlice({
  name: 'saleStatusesMapping',
  initialState,
  reducers: {
    setSaleStatusesMappingTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    }
  }
})

export const selectSaleStatusesMappingTotalCount = (state: RootState) => state.deals.totalCount

export default dealsSlice.reducer

interface SaleStatusMapping {
  advertiserId: string
  advertiserSaleStatus: string
  createdAt: string
  crmSaleStatusId: string | null
  id: string
  updatedAt: string
}

interface GetSaleStatusesMappingReturn {
  totalCount?: number
  saleStatusMappings?: SaleStatusMapping[]
}

export const saleStatusesMappingApi = createApi({
  reducerPath: 'saleStatusesMappingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/sale-status-mapping`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['SaleStatusesMapping'],
  endpoints: (builder) => ({
    getSaleStatusesMapping: builder.query<GetSaleStatusesMappingReturn, any>({
      query: () => ({
        url: '/search',
        method: 'POST'
      }),
      providesTags: [{ type: 'SaleStatusesMapping', id: 'SaleStatusesMappingLIST' }]
    }),
    getSaleStatusMapping: builder.query<GetSaleStatusesMappingReturn, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'GET'
      }),
      providesTags: [{ type: 'SaleStatusesMapping', id: 'SaleStatusesMappingLIST' }]
    }),
    createSaleStatusMapping: builder.mutation<GetSaleStatusesMappingReturn, { advertiserSaleStatus: string }>({
      query: ({ advertiserSaleStatus }) => ({
        url: '',
        method: 'POST',
        body: {
          advertiserSaleStatus,
          crmSaleStatusId: null
        }
      }),
      invalidatesTags: [{ type: 'SaleStatusesMapping', id: 'SaleStatusesMappingLIST' }]
    }),
    editSaleStatusMapping: builder.mutation<GetSaleStatusesMappingReturn, Partial<Omit<SaleStatusMapping, 'id'>> & { id: string }>({
      query: ({ id, ...rest }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: rest
      }),
      invalidatesTags: [{ type: 'SaleStatusesMapping', id: 'SaleStatusesMappingLIST' }]
    }),
    deleteSaleStatusMapping: builder.mutation<GetSaleStatusesMappingReturn, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'SaleStatusesMapping', id: 'SaleStatusesMappingLIST' }]
    })
  })
})

export const { useGetSaleStatusesMappingQuery, useGetSaleStatusMappingQuery, useCreateSaleStatusMappingMutation, useEditSaleStatusMappingMutation, useDeleteSaleStatusMappingMutation } = saleStatusesMappingApi
