import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
import { type LeadState } from '@/enums/leads.enum'

export const dealsHistoryHeaders: Record<string, string> = {
  id: 'UUID',
  goalType: 'Goal Type',
  advertiserId: 'Advertiser UUID',
  reason: 'Reason',
  countries: 'Countries',
  newRevenue: 'New Revenue',
  updatedAt: 'Updated At',
  createdAt: 'Created At'
}

export interface IDealFilters {
  userId?: string
  userName?: string
  advertiserId?: string
  reason?: string
  countries?: string[]
  newRevenue?: string
  createdTo?: {
    from: string
    to: string
  }
}
export interface DealsState {
  totalCount?: number
  menu?: {
    name: string
    id: string
    deal: IDeal
  }
  dealsHistory?: IDeal[]
}

export interface IOldConversions {
  id?: string
  leadId?: string
  sentLeadId?: string
  country?: string
  advertiserId?: string
  revenue?: number
  goalType?: string
  createdAt?: Date
  updatedAt?: Date
}

const initialState: DealsState = {
  totalCount: 0,
  menu: { name: '', id: '', deal: {} },
  dealsHistory: []
}

export interface IDeal {
  id?: string
  advertiserId?: string
  userId?: string
  goalType?: LeadState.PUSHED_LEAD | LeadState.FTD
  countries?: string[]
  newRevenue?: string
  reason?: string
  createdAt?: string
  updatedAt?: string
  user?: {
    id?: string
    name?: string
    email?: string
  }
}

export const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    setDealsTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setDealsState: (state, action: PayloadAction<any>) => {
      state = action.payload
    },
    setDealsMenu: (state, action: PayloadAction<{ name: string, id: string, deal: IDeal }>) => {
      state.menu = action.payload
    }
  }
})

export const { setDealsTotalCount, setDealsMenu, setDealsState } = dealsSlice.actions

export const selectDealsTotalCount = (state: RootState) => state.deals.totalCount
export const selectDealsState = (state: RootState) => state.deals.dealsHistory
export const selectDealsMenu = (state: RootState) => state.deals.menu

export default dealsSlice.reducer

interface GetDealsArgs {
  limit?: number
  page?: number
  filters?: IDealFilters
}

interface GetDealsReturn {
  totalCount?: number
  data?: any[]
}

export const dealsApi = createApi({
  reducerPath: 'dealsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/dealsHistory`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['Deals'],
  endpoints: (builder) => ({
    getDeals: builder.query<GetDealsReturn, GetDealsArgs>({
      query: ({ limit = 10, page = 1, filters = {} }) => ({
        url: '/search',
        method: 'POST',
        body: {
          limit,
          page,
          sortField: 'createdAt',
          sortOrder: 'desc',
          createdAt: filters?.createdTo !== undefined ? filters?.createdTo : undefined,
          include: {
            conversionsPivot: true
          },
          filter: Object.keys(filters).length
            ? {
                userId: filters?.userId ? { in: [filters?.userId] } : undefined,
                userName: filters?.userName ? filters?.userName : undefined,
                advertiserId: filters?.advertiserId ? { in: [filters?.advertiserId] } : undefined,
                reason: filters?.reason ?? undefined,
                countries: filters?.countries ?? undefined,
                newRevenue: filters?.newRevenue
                  ? {
                      gte: filters?.newRevenue,
                      lte: filters?.newRevenue
                    }
                  : undefined
              }
            : undefined
        }
      }),
      providesTags: [{ type: 'Deals', id: 'DealsLIST' }]
    }),
    createDeals: builder.mutation({
      query: ({
        ...rest
      }) => ({
        url: '',
        method: 'POST',
        body: {
          ...rest
        }
      }),
      invalidatesTags: [{ type: 'Deals', id: 'DealsLIST' }]
    })
  })
})

export const { useGetDealsQuery, useCreateDealsMutation } = dealsApi
