import React from 'react'

import Popup from '@/designSystem/Popup'
import { Box } from '@mui/material'
import ActionButtons from '@/designSystem/ActionButtons'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import { useDispatch } from 'react-redux'
import { setDealsMenu } from '@/store/deals'
import MenuDealsTable from './MenuDealsTable/MenuDealsTable'

interface IPropsAddInjModal {
  handleClose: () => void
  open: boolean
  deal: any
}

export const DealsInfoModal = ({ handleClose, open, deal }: IPropsAddInjModal) => {
  const dispatch = useDispatch()

  const onSubmit = () => {
    handleClose()
    dispatch(setDealsMenu({ name: deal?.name, id: deal?.id, deal: deal?.deal }))
  }

  return (
    <Popup
      open={open}
      title="Basic information"
      subTitle={`ID: ${deal?.id}`}
      handleClose={handleClose}
      overflow='auto'
      maxWidth='1500px'
      rootBoxSx={{
        background: '#F1F3FF'
      }}
    >
      <ScrollBarContainer
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          sx={{
            background: '#F1F3FF'
          }}
        >
          <MenuDealsTable deals={deal?.deal ?? {}} />
          <Box
            display="flex"
            justifyContent={'flex-end'}
          >
            <Box display="flex" width={'530px'}>
              <ActionButtons
                sx={{ paddingTop: '24px', width: '100%' }}
                onCancel={handleClose}
                onSubmit={onSubmit}
                submitText="Full information"
                cancelText='Cancel'
              />
            </Box>
          </Box>
        </Box>
      </ScrollBarContainer>
    </Popup>
  )
}
