import React from 'react'

import Popup from '@/designSystem/Popup'
import ActionButtons from '@/designSystem/ActionButtons'

export interface Inputs {
  name: string
  description: string
  pullLeadStatuses: { value: boolean }
  active: boolean
}

export interface AddPopupProps {
  onSubmit: () => void
  handleClose: () => void
  title: string
  subtitle: string
}

export default function DeleteAdvertiserPopup ({
  onSubmit,
  handleClose,
  title,
  subtitle
}: AddPopupProps) {
  return (
    <Popup
      open
      title={title}
      subTitle={subtitle}
      handleClose={handleClose}
    >
      <ActionButtons
        onCancel={handleClose}
        onSubmit={onSubmit}
      />
    </Popup>
  )
}
