import React, { useEffect } from 'react'

import { useTheme, Typography, Box, IconButton, type SxProps } from '@mui/material'
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import { type IUsers } from '@/store/users'

interface IAccPaginationNavigation {
  setQueryParams: (params: { page: number, limit: number }) => void
  queryParams: { page: number, limit: number }
  totalCount: number
}

const PaginationNavigation = ({ setQueryParams, queryParams, totalCount }: IAccPaginationNavigation) => {
  const { spacing, palette } = useTheme()

  const { page = 1, limit } = queryParams
  const totalPages = Math.ceil(totalCount / limit)

  const iconSx: SxProps = {
    width: '20px',
    height: '20px'
  }

  return (
    <Box
      padding={spacing(1.5, 3)}
      border={`1px solid ${palette.primary.main}`}
      borderRadius="12px"
      display="flex"
      alignItems="center"
      gap={spacing(3)}
      sx={{ backgroundColor: palette.card.main }}
    >
      <IconButton
        sx={iconSx}
        disabled={page === 1}
        onClick={() => setQueryParams({
          ...queryParams,
          page: page - 1
        })}
      >
        <KeyboardArrowLeftRoundedIcon />
      </IconButton>
      <Typography variant='headline'>
        {page}
      </Typography>
      <IconButton
        sx={iconSx}
        disabled={page === totalPages}
        onClick={() => setQueryParams({
          ...queryParams,
          page: page + 1
        })}
      >
        <KeyboardArrowRightRoundedIcon />
      </IconButton>
    </Box>
  )
}

export default function AccountsPagination ({ accounts, setUsers, users }: {
  accounts: IUsers[]
  setUsers: (users: IUsers[]) => void
  users: IUsers[]
}) {
  const { spacing, palette } = useTheme()
  const [queryParams, setQueryParams] = React.useState({ page: 1, limit: 15 })
  const [userPagination, setUserPagination] = React.useState<IUsers[]>([])
  const isUserChanged = users?.length === 1
  const totalUserPage = isUserChanged ? 1 : userPagination?.length || 0

  useEffect(() => {
    if (users?.length === 0) {
      setUserPagination(accounts)
    }
  }, [accounts, users])

  useEffect(() => {
    const start = (queryParams?.page - 1) * queryParams?.limit
    const end = start + queryParams?.limit
    setUsers(userPagination?.slice(start, end))
  }, [queryParams, userPagination])

  if (!totalUserPage) {
    return null
  }

  const handleQueryParams = (params: { page: number, limit: number }) => { setQueryParams(params) }

  const firstElement = (queryParams?.page - 1) * queryParams?.limit + 1
  const lastElement = Math.min(queryParams?.page * queryParams?.limit, totalUserPage)
  const range = `${firstElement} to ${lastElement} Elements / ${totalUserPage} Total`
  const totalPages = Math.ceil(totalUserPage / queryParams?.limit)

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="row"
      gap={spacing(12)}
      paddingBottom={'15px'}
    >
      <Typography
        variant='headline'
        color={palette.text1.main}
      >
        {range}
      </Typography>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="row"
        gap={spacing(3)}
      >
        <Typography variant='headline'>
          Page:
        </Typography>
        <PaginationNavigation queryParams={queryParams}
          setQueryParams={handleQueryParams} totalCount={totalUserPage} />
        <Typography variant='headline'>
          of {totalPages}
        </Typography>
      </Box>
    </Box>
  )
}
