import React, { useEffect, useState } from 'react'

import useTheme from '@mui/material/styles/useTheme'

import { parseFromTimeZone, formatToTimeZone } from 'date-fns-timezone'
import format from 'date-fns/format'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import ClockIcon from '@/designSystem/NavigationPanel/Icons/Clock'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useSelector, useDispatch } from 'react-redux'
import { selectTimezone, setTimezone, useGetTimezoneQuery } from '@/store/settings'
import TimeZonesPopup from './TimeZonesPopup'
import { useRole } from '@/hooks/role'
import { timeZones } from '@/constants'

export default function Time () {
  const theme = useTheme()
  const dispatch = useDispatch()
  const role = useRole()
  const timezoneRemote = useGetTimezoneQuery({})?.data?.timezone
  const timezone = useSelector(selectTimezone)

  const [currentTime, setCurrentTime] = useState(() => new Date())
  const [openPopup, setOpenPopup] = useState<boolean>(false)

  const currentDate = format(currentTime, 'dd/MM/yyyy')
  const cuurrentTimeFormated = format(currentTime, 'HH:mm:ss')

  const timeZoneOffset = formatToTimeZone(currentTime, 'Z, [UTC]', { timeZone: timezone })

  useEffect(() => {
    const localTimazone = localStorage.getItem('timezone')
    timezoneRemote && dispatch(setTimezone(timezoneRemote))
    const timezoneCode = timeZones?.find((item) => item.value === timezoneRemote)?.timezone ?? localTimazone ?? '2'
    localStorage.setItem('timezone', timezoneCode)
  }, [timezoneRemote])

  useEffect(() => {
    const interval = setInterval(() => {
      const date = format(new Date(), 'yyyy-MM-dd HH:mm:ss')
      const formatDate = parseFromTimeZone(date, { timeZone: timezone })
      setCurrentTime(formatDate)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [timezone])

  const openTimeZonePopup = () => {
    role && setOpenPopup(true)
  }

  const closeTimeZonePopup = () => {
    setOpenPopup(false)
  }

  return (
    <>
      <Paper
        variant='outlined'
        elevation={0}
        sx={{
          borderRadius: theme.spacing(4),
          borderColor: theme.palette.primary.main,
          overflow: 'hidden',
          cursor: 'pointer'
        }}
        onClick={openTimeZonePopup}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={theme.spacing(4)}
          padding={theme.spacing(4)}
          borderRadius={theme.spacing(4)}
          sx={{
            backgroundColor: theme.palette.whiteText.main
          }}
        >
          <Box display="flex" flexDirection="row"
            alignItems={'center'} justifyContent="space-between">
            <Box display="flex" gap={theme.spacing(2)}>
              <ClockIcon />
              <Typography variant='body1'>
                {currentDate}
              </Typography>
            </Box>
            <Typography variant='body2'>
              {cuurrentTimeFormated}
            </Typography>
          </Box>
          <Box display="flex" gap={theme.spacing(2)}>
            <LanguageOutlinedIcon />
            <Typography variant='body1'>
              {timeZoneOffset}
            </Typography>
          </Box>
        </Box>
      </Paper>
      <TimeZonesPopup
        open={openPopup}
        handleClose={closeTimeZonePopup}
      />
    </>
  )
}
