import React from 'react'

import IntegrationSettingsPageLayout from '@/designSystem/Pages/Advertisers/IntegrationSettings'
import { useNavigate, useParams } from 'react-router'
import { useGetAdvertisersQuery } from '@/store/advertisers'

export default function IntegrationSettingsPage () {
  const { advertiserId } = useParams()
  const navigate = useNavigate()

  const advertiser =
    useGetAdvertisersQuery({
      limit: 1,
      filters:
        { id: advertiserId ? [advertiserId] : undefined }
    })?.data?.advertisers?.[0]

  if (!advertiserId) {
    navigate(-1)
  }

  const onClickReload = () => navigate(0)

  return (
    <IntegrationSettingsPageLayout
      onClickReload={onClickReload}
      advertiserName={advertiser?.name ?? 'Name is loading...'}
      advertiserId={advertiserId ?? ''}
    />
  )
}
