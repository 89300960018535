export enum fullAccess {
  DASHBOARD = 'DASHBOARD',
  ADVERTISERS = 'ADVERTISERS',
  ROTATIONS = 'ROTATIONS',
  SALE_STATUSES = 'SALE_STATUSES',
  LEADS = 'LEADS',
  HOLDS = 'HOLDS',
  CONVERSIONS = 'CONVERSIONS',
  ADVERTISER_REVENUES = 'ADVERTISER_REVENUES',
  POSTBACKS = 'POSTBACKS',
  DRILLDOWN_REPORTS = 'DRILLDOWN_REPORTS',
  INJECTIONS = 'INJECTIONS',
  AUTO_INJECTIONS = 'AUTO_INJECTIONS',
  DEALS_UPDATERS = 'DEALS_UPDATERS',
  DEALS_HISTORIES = 'DEALS_HISTORIES',
  SETTINGS = 'SETTINGS'
}

export const fullAccessArray = [
  fullAccess.DASHBOARD,
  fullAccess.ADVERTISERS,
  fullAccess.ROTATIONS,
  fullAccess.SALE_STATUSES,
  fullAccess.LEADS,
  fullAccess.HOLDS,
  fullAccess.CONVERSIONS,
  fullAccess.ADVERTISER_REVENUES,
  fullAccess.POSTBACKS,
  fullAccess.DRILLDOWN_REPORTS,
  fullAccess.INJECTIONS,
  fullAccess.AUTO_INJECTIONS,
  fullAccess.DEALS_UPDATERS,
  fullAccess.DEALS_HISTORIES,
  fullAccess.SETTINGS
]
