import React, { memo, useEffect } from 'react'
import Popup from '../Popup'
import ActionButtons from '../ActionButtons'
import Autocomplete from '@/components/Form/Autocomplete'
import { timeZones } from '@/constants'
import composeSx from '../TextField/v2'
import { Box, useTheme } from '@mui/material'
import { useForm } from 'react-hook-form'
import Form from '@/components/Form'
import { selectTimezone, setTimezone, useEditTimezoneMutation } from '@/store/settings'
import { useDispatch, useSelector } from 'react-redux'

interface ITimeZonesPopup {
  open: boolean
  handleClose: () => void
}

const TimeZonesPopup = ({ open, handleClose }: ITimeZonesPopup) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const timezone = useSelector(selectTimezone)
  const [editTimeZone] = useEditTimezoneMutation()
  const textFieldsSx = composeSx(theme)
  const form = useForm({
    defaultValues: {
      timezone: { title: timezone, value: timezone }
    }
  })

  useEffect(() => {
    form.reset({
      timezone: { title: timezone, value: timezone }
    })
  }, [timezone])

  const onSubmit = async (data: { timezone: { value: string, title: string } }) => {
    const localTimazone = localStorage.getItem('timezone')
    data?.timezone?.value && await editTimeZone({ timezone: data?.timezone?.value }).then((res: any) => {
      const responseTimezone = res?.data?.appSettings?.timezone
      dispatch(setTimezone(responseTimezone))
      const timezoneCode = timeZones?.find((item) => item.value === responseTimezone)?.timezone ?? localTimazone ?? '2'
      localStorage.setItem('timezone', timezoneCode)
      form.reset()
      handleClose()
    })
  }

  return (
    <Popup
      open={open}
      title="Choose Timezone"
      handleClose={handleClose}
      maxWidth='600px'
    >
      <Form form={form} onSubmit={onSubmit}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          height={'max-content'}
          minHeight={'450px'}
        >
          <Autocomplete
            name='timezone'
            plaseholder='Choose value ...'
            textFieldProps={{
              root: {
                sx: textFieldsSx
              }
            }}
            options={timeZones}
            isOptionEqualToValue={(option, value) => option.value === value.value}
          />
          <ActionButtons
            onCancel={handleClose}
            submitText="Submit"
            cancelText='Cancel'
          />
        </Box>
      </Form>
    </Popup>
  )
}

export default memo(TimeZonesPopup)
