import React from 'react'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import CloseIcon from '@mui/icons-material/Close'

import useTheme from '@mui/material/styles/useTheme'

import type { SxProps, Theme } from '@mui/material'
import type { DialogProps } from '@mui/material/Dialog'

type PopupProps = Omit<DialogProps, 'maxWidth'> & {
  title: React.ReactNode
  titleSx?: SxProps<Theme>
  subTitle?: React.ReactNode
  children: React.ReactNode
  actionButtons?: React.ReactNode
  maxWidth?: string
  gap?: string
  padding?: string
  handleClose?: () => void
  closeButtonSx?: SxProps<Theme>
  rootBoxSx?: SxProps<Theme>
  closeIcon?: boolean
  overflow?: 'auto' | 'hidden' | 'scroll' | 'visible' | undefined
}

export default function Popup ({
  title,
  titleSx,
  subTitle,
  children,
  actionButtons,
  handleClose,
  closeIcon,
  closeButtonSx,
  rootBoxSx,
  padding,
  sx,
  gap,
  maxWidth = '596px',
  overflow = 'auto',
  ...rest
}: PopupProps) {
  const theme = useTheme()

  return (
    <Dialog
      {...rest}
      sx={{
        '& .MuiDialog-container .MuiPaper-root': {
          width: '100%',
          maxWidth,
          borderRadius: '16px'
        },
        ...sx
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        padding={padding ?? theme.spacing(6)}
        gap={gap ?? theme.spacing(6)}
        sx={rootBoxSx}
        overflow={overflow}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            width='100%'
            gap={theme.spacing(2)}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width='100%'
            >
              <Title title={title} titleSx={titleSx} />
              {!closeIcon && <IconButton
                sx={{
                  height: '28px',
                  width: '28px',
                  ...closeButtonSx
                }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>}
            </Box>
            {subTitle && (
              <Typography
                variant='body1'
                color={theme.palette.text2.main}
              >
                {subTitle}
              </Typography>
            )}
          </Box>
        </Box>
        {children}
        {actionButtons && (
          <Box>
            {actionButtons}
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

type TitleProps = Pick<PopupProps, 'title' | 'titleSx'>
function Title ({ title, titleSx }: TitleProps) {
  const theme = useTheme()
  if (typeof title === 'string') {
    return (
      <Typography
        variant='h1'
        color={theme.palette.text1.main}
        sx={titleSx}
      >
      {title}
    </Typography>
    )
  }
  return title
}
