import React, { useMemo } from 'react'
import Chip from '@/components/Advertisers/Filters/Chip'
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import {
  type AutoInjectionsFilters
} from '@/store/autoInjections'
import { selectAutoInjectQueryParams, setAutoInjectQueryParams } from '@/store/queryParams'
import { Box, useTheme } from '@mui/material'
import cloneDeep from 'lodash.clonedeep'
import unset from 'lodash.unset'

const Bar = ({ children }: { children: React.ReactNode }) => {
  const { spacing, palette } = useTheme()
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={spacing(3)}
      justifyContent="flex-start"
      padding={spacing(3, 4)}
      width="100%"
      borderRadius="12px"
      sx={{
        backgroundColor: palette.card.main
      }}
    >
      {children}
    </Box>
  )
}

const areFiltersExist = (filters?: AutoInjectionsFilters) => {
  if (!filters) {
    return false
  }
  return Object.entries(filters).some(([key, value]) => {
    if (Array.isArray(value)) return !!value.length
    return !!value
  })
}

export default function AutoInjectFilterBar () {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectAutoInjectQueryParams)

  const isParamExist = useMemo(() => areFiltersExist(queryParams?.filters), [queryParams?.filters])

  if (!isParamExist) {
    return null
  }

  const onDeleteParam = (label: string) => () => {
    const params = cloneDeep(queryParams)
    unset(params, `filters.${label}`)
    dispatch(setAutoInjectQueryParams(params))
  }

  const filters = queryParams.filters!

  return (
    <Bar>
      {filters?.id && (
        <Chip
          label={`UIUD: "${filters?.id?.in?.join(', ')}"`}
          onDelete={onDeleteParam('id')}
        />
      )}
      {filters?.name && (
        <Chip
          label={`Name: "${filters?.name}"`}
          onDelete={onDeleteParam('name')}
        />
      )}
      {filters?.status && (
        <Chip
          label={`Status: ${filters?.status?.in?.join(', ')}`}
          onDelete={onDeleteParam('status')}
        />
      )}
      {filters?.state && (
        <Chip
          label={`State: ${filters?.state?.in?.join(', ')}`}
          onDelete={onDeleteParam('state')}
        />
      )}
       {filters?.country && (
        <Chip
          label={`Show country to: ${filters?.country?.in?.[0]}`}
          onDelete={onDeleteParam('country')}
        />
       )}
      {filters?.createdAt?.from && (
        <Chip
          label={`Show from: ${format(new Date(filters.createdAt.from), 'P HH:mm')}`}
          onDelete={onDeleteParam('createdAt.from')}
        />
      )}
      {filters?.createdAt?.to && (
        <Chip
          label={`Show before: ${format(new Date(filters.createdAt.to), 'P HH:mm')}`}
          onDelete={onDeleteParam('createdAt.to')}
        />
      )}
    </Bar>
  )
}
