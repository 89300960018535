import { createSlice } from '@reduxjs/toolkit'
import { type FiltersAdvertiser, advertisersHeaders } from './advertisers'
import type { RootState } from '@/store'
import { injectionsHeaders, type InjectionsFilters } from './injections'
import { autoInjectionsHeaders, type AutoInjectionsFilters } from './autoInjections'
import { leadsHeaders, type LeadsFilters } from './leads'
import { type FiltersRotationControl } from './rotationControl'
import { type FiltersRevenue } from './advertiserRevenue'
import { type HoldsFilters, holdsHeaders } from './hold'
import { dealsHistoryHeaders, type IDealFilters } from './deals'
import { type ConversionsFilters, conversionsHeaders } from './conversions'
import { type LogsFilter, advertiserLogsHeaders } from './advertisersLogs'
import { type ITermFilters } from './terms'
import { apiTokensHeaders, type ApiTokensFilters } from './apiTokens'
import { type IPresetFilters } from './presets'
import { type PostbackFilters } from './postBacks'

interface QueryParamsState {
  advertisers: {
    headers: string[]
    limit: number
    filters?: FiltersAdvertiser
    page?: number
  }
  injections: {
    headers: string[]
    limit: number
    filters?: InjectionsFilters
    page?: number
  }
  autoInjections: {
    headers: string[]
    limit: number
    filters?: AutoInjectionsFilters
    page?: number
  }
  leads: {
    headers: string[]
    limit: number
    filters?: LeadsFilters
    page?: number
  }
  rotationControl: {
    filters?: FiltersRotationControl
  }
  advertiserRevenue: {
    filters?: FiltersRevenue
  }
  hold: {
    headers: string[]
    limit: number
    filters?: HoldsFilters
    page?: number
  }
  deals: {
    headers: string[]
    limit: number
    page?: number
    filters?: IDealFilters
  }
  conversions: {
    headers: string[]
    limit: number
    filters?: ConversionsFilters
    page?: number
  }
  logs: {
    limit: number
    filter?: Record<string, string>
    page?: number
    totalCount: number
  }
  advertisersLogs: {
    headers: string[]
    limit: number
    filter?: LogsFilter
    page?: number
  }
  saleStatusesMapping: {
    limit: number
    page?: number
  }
  terms: {
    limit: number
    page?: number
    filters?: ITermFilters
  }
  apiTokens: {
    headers: string[]
    limit: number
    filters?: ApiTokensFilters
    page?: number
  }
  presets: {
    limit: number
    page?: number
    filters?: IPresetFilters
  }
  postbacks: {
    filter?: PostbackFilters
  }
}

export const initialQueryParamsState: QueryParamsState = {
  advertisers: {
    headers: Object.values(advertisersHeaders),
    limit: 10,
    page: 1
  },
  injections: {
    headers: Object.values(injectionsHeaders),
    limit: 10,
    page: 1
  },
  autoInjections: {
    headers: Object.values(autoInjectionsHeaders),
    limit: 10,
    page: 1,
    filters: {}
  },
  leads: {
    headers: Object.values(leadsHeaders),
    limit: 10,
    page: 1
  },
  rotationControl: {},
  advertiserRevenue: {},
  hold: {
    headers: Object.values(holdsHeaders),
    limit: 10,
    page: 1
  },
  deals: {
    headers: Object.values(dealsHistoryHeaders),
    limit: 10,
    page: 1
  },
  conversions: {
    headers: Object.values(conversionsHeaders),
    limit: 10,
    page: 1
  },
  logs: {
    limit: 10,
    page: 1,
    totalCount: 0
  },
  advertisersLogs: {
    headers: Object.values(advertiserLogsHeaders),
    limit: 10,
    page: 1,
    filter: {}
  },
  saleStatusesMapping: {
    limit: 10,
    page: 1
  },
  terms: {
    limit: 10,
    page: 1
  },
  apiTokens: {
    headers: Object.values(apiTokensHeaders),
    limit: 10,
    page: 1,
    filters: {}
  },
  presets: {
    limit: 10,
    page: 1
  },
  postbacks: {
    filter: {}
  }
}

const queryParamsSlice = createSlice({
  name: 'queryParams',
  initialState: initialQueryParamsState,
  reducers: {
    setQueryParamsAdvertisers: (state, action) => {
      state.advertisers = action.payload
    },
    setInjectQueryParams: (state, action) => {
      state.injections = action.payload
    },
    setAutoInjectQueryParams: (state, action) => {
      state.autoInjections = action.payload
    },
    setLeadsQueryParams: (state, action) => {
      state.leads = action.payload
    },
    setRotationControlQueryParams: (state, action) => {
      state.rotationControl = action.payload
    },
    setAdvertiserRevenueQueryParams: (state, action) => {
      state.advertiserRevenue = action.payload
    },
    setHoldQueryParams: (state, action) => {
      state.hold = action.payload
    },
    setDealsQueryParams: (state, action) => {
      state.deals = action.payload
    },
    setConversionsQueryParams: (state, action) => {
      state.conversions = action.payload
    },
    setLogsQueryParams: (state, action) => {
      state.logs = action.payload
    },
    setAdvertiserLogsQueryParams: (state, action) => {
      state.advertisersLogs = action.payload
    },
    setSaleStatusesMappingQueryParams: (state, action) => {
      state.saleStatusesMapping = action.payload
    },
    setTermsQueryParams: (state, action) => {
      state.terms = action.payload
    },
    setApiTokensQueryParams: (state, action) => {
      state.apiTokens = action.payload
    },
    setPresetsQueryParams: (state, action) => {
      state.presets = action.payload
    },
    setPostbacksQueryParams: (state, action) => {
      state.postbacks = action.payload
    }
  }
})

export const {
  setQueryParamsAdvertisers, setInjectQueryParams, setAutoInjectQueryParams,
  setLeadsQueryParams, setRotationControlQueryParams, setAdvertiserRevenueQueryParams,
  setHoldQueryParams, setDealsQueryParams, setConversionsQueryParams,
  setLogsQueryParams, setAdvertiserLogsQueryParams, setSaleStatusesMappingQueryParams,
  setTermsQueryParams, setApiTokensQueryParams, setPresetsQueryParams, setPostbacksQueryParams
} = queryParamsSlice.actions

export const selectQueryParamsAdvertisers = (state: RootState) => state.queryParams.advertisers
export const selectInjectQueryParams = (state: RootState) => state.queryParams.injections
export const selectAutoInjectQueryParams = (state: RootState) => state.queryParams.autoInjections
export const selectLeadsQueryParams = (state: RootState) => state.queryParams.leads
export const selectRotationControlQueryParams = (state: RootState) => state.queryParams.rotationControl
export const selectAdvertiserRevenueQueryParams = (state: RootState) => state.queryParams.advertiserRevenue
export const selectHoldQueryParams = (state: RootState) => state.queryParams.hold
export const selectDealsQueryParams = (state: RootState) => state.queryParams.deals
export const selectConversionsQueryParams = (state: RootState) => state.queryParams.conversions
export const selectLogsQueryParams = (state: RootState) => state.queryParams.logs
export const selectAdvertiserLogsQueryParams = (state: RootState) => state.queryParams.advertisersLogs
export const selectSaleStatusesMappingQueryParams = (state: RootState) => state.queryParams.saleStatusesMapping
export const selectTermsQueryParams = (state: RootState) => state.queryParams.terms
export const selectApiTokensQueryParams = (state: RootState) => state.queryParams.apiTokens
export const selectPresetsQueryParams = (state: RootState) => state.queryParams.presets
export const selectPostbacksQueryParams = (state: RootState) => state.queryParams.postbacks

export default queryParamsSlice.reducer
