import React, { useEffect, createRef, useState } from 'react'

import {
  Tab,
  Tabs,
  Typography,
  useTheme,
  type TabProps,
  type TabsProps
} from '@mui/material'

interface CustomTabsProps {
  chosenTab: number
  setChosenTab: (index: number) => void
  tabs: React.ReactNode[]
  tabsProps?: TabsProps
  tabProps?: TabProps
}

// We do not expect this list will be changed so we can use index as key
// Alert: shitcode
export default function CustomTabs ({
  tabs,
  tabProps,
  tabsProps,
  chosenTab,
  setChosenTab
}: CustomTabsProps) {
  const [indicatorProperties, setIndicatorProperties] = useState({
    width: 0,
    left: 0
  })
  const [refs, setRefs] = useState<any[]>([])
  const { palette } = useTheme()
  useEffect(() => {
    setRefs((refs) =>
      Array(tabs.length)
        .fill(null)
        .map((_, i) => refs[i] ?? createRef())
    )
  }, [tabs.length])

  useEffect(() => {
    if (!refs?.[chosenTab]) {
      return
    }
    const chosenPosition = refs?.[chosenTab]?.current.getBoundingClientRect()
    let totalLeft = 0
    for (let i = chosenTab - 1; i >= 0; i--) {
      totalLeft += refs?.[i]?.current?.getBoundingClientRect()?.width
    }

    setIndicatorProperties({
      width: chosenPosition.width * 1.15,
      left: totalLeft * 1.33 ?? 0
    })
  }, [refs?.[chosenTab]])

  const { width, left } = indicatorProperties

  return (
    <Tabs
      value={chosenTab}
      onChange={(_, value) => setChosenTab(value)}
      aria-label="tabs"
      TabIndicatorProps={{
        sx: {
          backgroundColor: palette.primary.main,
          height: '6px',
          borderRadius: '12px 12px 0 0',
          left: `${left}px !important`,
          width: `${width + width / 5}px !important`
        }
      }}
      sx={tabProps?.sx}
      {...tabsProps}
    >
      {tabs.map((tab, index) => (
        <Tab
          ref={refs[index]}
          key={index}
          label={getLabel(tab)}
          {...a11yProps(index)}
          {...tabProps}
        />
      ))}
    </Tabs>
  )
}

function getLabel (label: React.ReactNode) {
  if (typeof label === 'string' || typeof label === 'number') {
    return (
      <Typography
        variant="headline"
        color="black"
        textTransform="capitalize"
      >
        {label}
      </Typography>
    )
  }
  return label
}

function a11yProps (index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}
