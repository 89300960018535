import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
import { getCurrentMonth, getLastMonth } from '@/components/Dashboard/utils'
import { LeadState } from '@/enums/leads.enum'

export interface GeneralCharts {
  view: string
  timePeriod: string | undefined
  leads: IChartLead[]
  revenue: IChartLead[]
  pastTimeRevenues: IChartLead[]
  pastTimeLeads: IChartLead[]
  localCurrentRange: DateRange
  localLastRange: DateRange

}
export interface TopAdvertisersChart {
  timePeriod: string | undefined
  leads: IChartLead[]
  pastTimeLeads: IChartLead[]
  localCurrentRange: DateRange
  localLastRange: DateRange
}
export interface TopCountryChart {
  timePeriod: string | undefined
  leads: IChartLead[]
  pastTimeLeads: IChartLead[]
  localCurrentRange: DateRange
  localLastRange: DateRange
}
interface DateRange {
  from: string
  to: string
}
export interface IGlobalTimeRange {
  customeTimeRange: DateRange
  globalTimeSelect: string | undefined
}
export interface DashboardStates {
  generalCharts: GeneralCharts
  topAdvertisersChart: TopAdvertisersChart
  topCountryChart: TopCountryChart
  globalTimeRange: IGlobalTimeRange
  currentRange: DateRange

}
const initialState: DashboardStates = {
  globalTimeRange: {
    customeTimeRange: {
      from: '',
      to: ''
    },
    globalTimeSelect: 'currentMonth'
  },
  currentRange: getCurrentMonth(),
  generalCharts: {
    view: 'lead',
    timePeriod: 'none',
    leads: [],
    pastTimeLeads: [],
    revenue: [],
    pastTimeRevenues: [],
    localCurrentRange: getCurrentMonth(),
    localLastRange: getLastMonth()

  },
  topAdvertisersChart: {
    timePeriod: 'none',
    leads: [],
    pastTimeLeads: [],
    localCurrentRange: getCurrentMonth(),
    localLastRange: getLastMonth()
  },
  topCountryChart: {
    timePeriod: 'none',
    leads: [],
    pastTimeLeads: [],
    localCurrentRange: getCurrentMonth(),
    localLastRange: getLastMonth()
  }
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setGeneralCharts: (state, action: PayloadAction<GeneralCharts>) => {
      state.generalCharts = action.payload
    },
    setTopAdvertisersCharts: (state, action: PayloadAction<TopAdvertisersChart>) => {
      state.topAdvertisersChart = action.payload
    },
    setTopCountryCharts: (state, action: PayloadAction<TopCountryChart>) => {
      state.topCountryChart = action.payload
    },
    setGlobalTimeRange: (state, action: PayloadAction<DashboardStates['globalTimeRange']>) => {
      state.globalTimeRange = action.payload
    },
    setCurrentRange: (state, action: PayloadAction<DashboardStates['currentRange']>) => {
      state.currentRange = action.payload
    }
  }
})

export const { setGeneralCharts, setTopAdvertisersCharts, setTopCountryCharts, setGlobalTimeRange, setCurrentRange } = dashboardSlice.actions
export const selectGeneralCharts = (state: RootState) => state.dashboard.generalCharts
export const selectTopAdvertisersCharts = (state: RootState) => state.dashboard.topAdvertisersChart
export const selectTopCountryCharts = (state: RootState) => state.dashboard.topCountryChart
export const selectGlobalTimeRange = (state: RootState) => state.dashboard.globalTimeRange
export const selectCurrentRange = (state: RootState) => state.dashboard.currentRange

export default dashboardSlice.reducer
export interface IGetWitgetRequest {
  label: string
  createdAt: {
    from: string
    to: string
  }
}

export interface IGetWitgetAnswer {
  label: string
  value: number
}
export interface IGetChartsRequest {
  from: string
  to: string
  state: {
    in: string[]
  }
}

export interface IGetChartsAnswer {
  data: IChartLead[]
  totalLeads: number
}
export interface IChartLead {
  id: string
  country?: string
  advertiserId?: string
  advertiserName?: string
  createdAt: string
  state?: LeadState
  revenue?: number

}

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    }
  }),
  tagTypes: ['Dashboard'],
  endpoints: (builder) => ({
    searchLeadChart: builder.query<IGetChartsAnswer, IGetChartsRequest>({
      query: ({ from, to }) => ({
        url: '/chart/lead',
        method: 'POST',
        body: {
          from,
          to,
          state: { in: [LeadState.PUSHED_LEAD, LeadState.FTD, LeadState.FTD_LATE] }
        }
      }),
      providesTags: [{ type: 'Dashboard', id: 'DashboardLIST' }]
    }),
    searchWidgetLabel: builder.query<IGetWitgetAnswer, IGetWitgetRequest>({
      query: ({ label, createdAt }) => ({
        url: '/widget/getByLabel',
        method: 'POST',
        body: {
          label,
          createdAt
        }
      }),
      providesTags: [{ type: 'Dashboard', id: 'DashboardLIST' }]
    }),
    searchChartRevenue: builder.query<IGetChartsAnswer, IGetChartsRequest>({
      query: ({ from, to }) => ({
        url: '/chart/revenue',
        method: 'POST',
        body: {
          from,
          to
        }
      }),
      providesTags: [{ type: 'Dashboard', id: 'DashboardLIST' }]
    })
  })
})

export const { useSearchWidgetLabelQuery, useSearchLeadChartQuery, useSearchChartRevenueQuery } = dashboardApi
