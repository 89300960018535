import React from 'react'

import useTheme from '@mui/material/styles/useTheme'

import Autocomplete, { type AutocompleteProps as AutocompleteLayoutProps } from '@/designSystem/Autocomplete'
import type { Option } from '@/designSystem/Autocomplete/types'

export type AutocompleteProps<T> = AutocompleteLayoutProps<T> & {
  options: Array<Option<T>>
  onChange: (value: Option<T> | null) => void
  placeholder: string
  sx?: any
  inputSx?: any
}

export default function AdvertisersAutocomplete<T> ({
  options,
  onChange,
  placeholder,
  sx,
  inputSx,
  ...rest
}: AutocompleteProps<T>) {
  const theme = useTheme()
  return (
    <Autocomplete
      sx={{
        width: '218px',
        ...sx
      }}
      textFieldProps={{
        root: {
          placeholder,
          sx: {
            textField: {
              padding: theme.spacing(2)
            }
          }
        },
        input: {
          sx: {
            '& .MuiAutocomplete-input': {
              padding: `0 0 0 ${theme.spacing(4)} !important`
            },
            '& .MuiInputBase-input::placeholder': {
              color: theme.palette.text1.main,
              fontSize: '18px',
              fontWeight: 500,
              opacity: 1
            },
            ...inputSx
          }
        }
      }}
      options={options}
      onChange={onChange}
      {...rest}
    />
  )
}
