import React from 'react'

import { useNavigate } from 'react-router-dom'
import LeftBarLayout from '@/designSystem/LeftBar'

import { routeRefs } from '@/routes/routesList'

export default function LeftBar () {
  const navigate = useNavigate()

  const onClickSettings = () => {
    navigate('/settings')
  }
  const onClickLeave = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('role')
    localStorage.removeItem('accessRights')
    window.location.reload()
  }
  const onClickLogo = () => navigate(routeRefs.home)
  const onClickUser = () => navigate('/accounts')

  return (
    <LeftBarLayout
      onClickLeave={onClickLeave}
      onClickLogo={onClickLogo}
      onClickSettings={onClickSettings}
      onClickUser={onClickUser}
    />
  )
}
