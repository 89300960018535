import React, { useEffect, useState } from 'react'

import { Typography, useTheme } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'
import Table from '@/designSystem/Table'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import Popup from '@/designSystem/Popup'
import {
  CTAField,
  Header,
  TextField
} from '@/designSystem/Table/Fields'

import { type IApiSettings } from '@/components/Advertisers/IntegrationSettingsPage/ApiConnectionSettings/EditPushSettingsForm'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import EditResponseConnectionForm from '@/components/Advertisers/IntegrationSettingsPage/ApiConnectionSettings/EditPullSettingsForm'

const headers = ['Key', 'Value']

interface PullApiSettings {
  key: string
  value: string
  group: string
}
interface RowPullSettings {
  key: string
  group: string
  value: string
  label?: string
}
interface PullSettingsConnectionTableProps {
  rows: RowPullSettings[]
  advertiserId?: string
  settingsType: 'pullLeadStatusesSettings' | 'pullLeadStateSettings'
}

export default function PullSettingsConnectionTable ({
  rows, settingsType
}: PullSettingsConnectionTableProps) {
  const { palette } = useTheme()
  const [isOpenAddPopup, setIsOpenAddPopup] = useState(false)
  const [settings, setSettings] = useState<IApiSettings[] | undefined>([])
  let title = 'Connection'
  if (settingsType === 'pullLeadStateSettings') {
    title = 'Connection'
  }
  useEffect(() => {
    const newSettings: IApiSettings[] = reducePullingSettings(rows)
    setSettings(newSettings)
  }, [rows])

  function reducePullingSettings (settings: IApiSettings[]) {
    return settings.reduce((acc: IApiSettings[], setting: IApiSettings) => {
      const isConnectionGroup = setting?.group === 'connection'
      if (isConnectionGroup) {
        acc.push({
          key: setting?.key ?? '',
          group: setting?.group ?? '',
          value: setting?.value ?? ''
        })
      }
      return acc
    }, [])
  }

  const headerNodes = headers.map((header) => <Header header={header} key={header} />)

  const rowNodes = settings?.map((row: any) => (
    headers.map((header) => fieldToElementMap(header, row))
  ))

  return (
    <>
      <FlexBox column gap={3}>
        <FlexBox gap={4} alignCenter>
          <Typography variant='h2'>
            {title}
          </Typography>
          <CTAField
            onClick={() => setIsOpenAddPopup(true)}
            icon={<EditRoundedIcon sx={{ fill: palette.primary.main }} />}
          />
        </FlexBox>
        <Table headers={headerNodes} rows={rowNodes ?? []} />
      </FlexBox>
      {isOpenAddPopup && (
        <Popup
          open
          title={title}
          subTitle={''}
          handleClose={() => setIsOpenAddPopup(false)}
        >
          <ScrollBarContainer>
            <EditResponseConnectionForm settingsType={settingsType} handleClose={() => setIsOpenAddPopup(false)} />
          </ScrollBarContainer>
        </Popup>
      )}
    </>
  )
}

const fieldToElementMap = (header: string, row: PullApiSettings) => {
  const {
    key,
    group,
    value
  } = row
  switch (header) {
    case headers[0]:
      return <TextField text={key} />
    case headers[1]:
      return <FlexBox sx={{ alignItems: 'flex-start', width: '250px' }}>
      <TextField text={value} limitation={30} />
    </FlexBox>
    case headers[2]:
      return <TextField text={group} />
  }
}
