import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
export interface PostbackFilters {
  name?: string
}
export interface PostbackState {
  total?: number
  postBack: PassingRules[]
  priority: UpdatePriorityPostbacksItem[]
}

const initialState: PostbackState = {
  postBack: [],
  priority: []
}

export const postBacksSlice = createSlice({
  name: 'postbacks',
  initialState,
  reducers: {
    setPostBack: (state, action: PayloadAction<PassingRules[]>) => {
      state.postBack = action.payload
    },
    setPriority: (state, action: PayloadAction<UpdatePriorityPostbacksItem[]>) => {
      state.priority = action.payload
    }
  }
})

export const { setPostBack, setPriority } = postBacksSlice.actions
export default postBacksSlice.reducer
export const selectPostBackData = (state: RootState) => state.postbacks.postBack
export const selectPriority = (state: RootState) => state.postbacks.priority

export enum ESoftFilled {
  ID = 'id',
  NAME = 'name',
  GOALTYPE = 'goalType',
  CREATEDAT = 'createdAt',
  PRIORITY = 'priority',
  ACTIVE = 'active'
}
export enum ERenderer {
  // GOALTYPE = 'goalType',
  COUNTRY = 'country',
  FREE_PQL = 'free_pql',
}
/// ///////////////////////////////////////////
interface PostbackLink {
  to: string
  from: string
}

export interface LinkParameter {
  key: string
  value: string
  label?: string
}

interface LinkParameters {
  to: LinkParameter[]
  from: LinkParameter[]
}

export interface LogData {
  data: {
    postbackLink?: {
      to: string
      from: string
    }
    linkParameters?: LinkParameters
    active?: {
      to: boolean
      from: boolean
    }
    country?: {
      to: string[]
      from: string[]
    }
    priority?: {
      to: number
      from: number
    }
    name?: {
      to: string
      from: string
    }
    description?: {
      to: string
      from: string
    }
    goalType?: {
      to: string
      from: string
    }
    requestMethod?: {
      to: string
      from: string
    }
    pql?: {
      to: string
      from: string
    }
  }
}
export interface PostbackLogs {
  id: string
  userId: string
  leadId: string | null
  rotationId: string | null
  advertiserId: string | null
  postbackId: string
  autoInjectionId: string | null
  injectionId: string | null
  action: string
  actionId: string | null
  logData: LogData
  createdAt: string
  updatedAt: string
}
export interface PostbackLogsArgs {
  logs: PostbackLogs[]
}
export interface PostbackLog {
  sortField: string
  sortOrder: 'desc' | 'asc'
  filter: {
    action: string
    postbackId: string
  }
}

export interface SearchPostBacksArgs {
  page?: number
  limit?: number
  sortField: ESoftFilled | string
  sortOrder?: string
  filter?: PostbackFilters
}
export interface CreateEditPostbackArgs {
  name: string
  description: string
  goalType: string
  country?: string[]
  pql?: string
  requestMethod: string
  postbackLink: string
  linkParameters: LinkParameter[]
  priority: number
  active: boolean
}
export interface UpdatePriorityPostbacksItem {
  id: string
  priority: number
}
export interface UpdatePriorityPostbacks {
  items: UpdatePriorityPostbacksItem[]
}

export interface PassingRules {
  id: string
  name: string
  description: string
  goalType: string
  country?: string[]
  pql?: string
  requestMethod: string
  postbackLink: string
  linkParameters: LinkParameter[]
  priority: number
  active: boolean
  createdAt?: string
}
export interface SearchPostBacksAnswer {
  passingRules: PassingRules[]
  totalCount: number
}

export const postBacksApi = createApi({
  reducerPath: 'postBacksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['PostBacks'],
  endpoints: (builder) => ({
    searchPostBacks: builder.query<SearchPostBacksAnswer, SearchPostBacksArgs>({
      query: ({ limit = 20, page = 1, sortField, sortOrder = 'asc', filter }) => ({
        url: '/postback/search',
        method: 'POST',
        body: {
          limit,
          page,
          sortField,
          sortOrder,
          filter
        }
      }),
      providesTags: [{ type: 'PostBacks', id: 'PostBacksLIST' }]
    }),
    createPostback: builder.mutation<PassingRules, CreateEditPostbackArgs>({
      query: (options) => ({
        url: '/postback/rule',
        method: 'POST',
        body: options
      }),
      invalidatesTags: [{ type: 'PostBacks', id: 'PostBacksLIST' }]
    }),
    getPostbackLogs: builder.query<PostbackLogsArgs, string>({
      query: (postBackId) => ({
        url: '/logs/search',
        method: 'POST',
        body: {
          sortField: 'createdAt',
          sortOrder: 'desc',
          filter: {
            action: 'POSTBACK_CREATE_EDIT',
            postbackId: postBackId
          }
        }
      }),
      providesTags: [{ type: 'PostBacks', id: 'PostBacksLIST' }]
    }),
    updatePostback: builder.mutation<PassingRules, PassingRules>({
      query: (options) => ({
        url: '/postback/rule',
        method: 'PUT',
        body: options
      }),
      invalidatesTags: [{ type: 'PostBacks', id: 'PostBacksLIST' }]
    }),
    updatePriorityPostbacks: builder.mutation<any, UpdatePriorityPostbacks>({
      query: (options) => ({
        url: '/postback/update-rules',
        method: 'POST',
        body: options
      }),
      invalidatesTags: [{ type: 'PostBacks', id: 'PostBacksLIST' }]
    }),
    deletePostback: builder.mutation<any, any>({
      query: (postBackId) => ({
        url: '/postback/rule',
        method: 'DELETE',
        body: {
          id: postBackId
        }
      }),
      invalidatesTags: [{ type: 'PostBacks', id: 'PostBacksLIST' }]
    })
  })
})

export const { useSearchPostBacksQuery, useCreatePostbackMutation, useUpdatePostbackMutation, useDeletePostbackMutation, useUpdatePriorityPostbacksMutation, useGetPostbackLogsQuery } = postBacksApi
