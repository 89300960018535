import * as React from 'react'
import MuiTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { type SxProps, styled } from '@mui/material'

export interface TableProps {
  headers: React.ReactNode[]
  rows: React.ReactNode[][]
  sx?: SxProps
  width?: string
  setIsCheck?: any
}

export default function Table ({ headers, rows, sx, width, setIsCheck }: TableProps) {
  return (
        <TableContainer
            component={Paper}
            sx={{
              // overflowX: 'auto',
              borderRadius: '16px',
              boxShadow: 'none',
              // overflowY: 'hidden',
              overflowX: 'auto',
              width,
              marginTop: '30px',
              '::-webkit-scrollbar': {
                height: '8px'
              },
              '::-webkit-scrollbar-track': {
                background: '#E0E2F0'
              },
              '::-webkit-scrollbar-thumb': {
                background: '#4141D5',
                borderRadius: 16
              }
            }}
        >
            <CustomTable sx={{ backgroundColor: '#F1F3FF', padding: '24px!important', ...sx }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {headers?.map((header, index) => (
                            <TableCell align="center" sx={{ textAlign: '-webkit-center' }} key={index}>{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map((row, index) => (
                        <TableRow
                            sx={{
                              // cursor: 'pointer'
                            }}
                            key={index}
                            onClick={() => setIsCheck({ elementIndex: index })}
                        >
                            {Object?.values(row)?.map((value, index) => (
                                <TableCell align="center" key={index}>{value}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </CustomTable>
        </TableContainer>
  )
}

const CustomTable = styled(MuiTable)(`
.MuiTableCell-root {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    color: #393E51;
    border-bottom: none;
}
`)
