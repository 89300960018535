import React, { useState } from 'react'
import * as AutocompleteDS from '@/designSystem/Autocomplete'
import { useGetAdvertisersQuery } from '@/store/advertisers'
import { useDebounce } from 'use-debounce'
import { type IPQL } from '.'
import { type Option } from '@/designSystem/Autocomplete/types'
import { autocompleteStyle } from './JsxStyles'

interface Props {
  item: IPQL
  onChangeValue: (option: Option<string> | null) => void
}

const AdvertiserAutocomplete = ({ item, onChangeValue }: Props) => {
  const [search, setSearch] = useState<string>('')

  const [debouncedSearch] = useDebounce(search, 500)

  const advertisersData = useGetAdvertisersQuery({ filters: { name: debouncedSearch }, page: 1, limit: 10 }).data

  const advertisersOptions = advertisersData?.advertisers.map((item) => ({
    title: item.name,
    value: item.id
  })) ?? []

  return <AutocompleteDS.default getOptionDisabled={(option) => Array.isArray(item.value) && item.value.findIndex((i) => i.includes(option.value)) > -1} sx={autocompleteStyle} options={advertisersOptions} onChange={(option) => onChangeValue(option)} onInputChange={(e) => setSearch(e.target.value)} />
}

export default AdvertiserAutocomplete
