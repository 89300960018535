import React from 'react'
import { Typography } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { ReactComponent as StarFirst } from '@/assets/icons/firstStar.svg'
import { ReactComponent as StarSecond } from '@/assets/icons/secondStar.svg'
import { ReactComponent as StarThird } from '@/assets/icons/thirdStar.svg'
import { type TableRowCount } from '../utils'

export function FieldMap ({ children }: any) {
  const { palette } = useTheme()
  return (
    <Typography
      sx={{ display: 'flex' }}
      variant='table1'
      width="max-content"
      color={palette.text2.main}
    >
      {children}
    </Typography>
  )
}

const elementMap = (header: string, row: TableRowCount, index: number, isCheck?: { elementIndex: number }, topCountryDataCharts?: any) => {
  const allHeaders = {
    position: 'Position',
    country: 'Country',
    total: 'Total FTD',
    total_last: topCountryDataCharts.timePeriod === 'yesterday_today' ? 'Yesterday FTD' : topCountryDataCharts.timePeriod === 'lastWeek_currentWeek' ? 'Last Week FTD' : topCountryDataCharts.timePeriod === 'lastMonth_currentMonth' ? 'Last Month FTD' : topCountryDataCharts.timePeriod === 'lastYear_currentYear' ? 'Last Year FTD' : '',
    leads: 'Leads',
    leads_last: topCountryDataCharts.timePeriod === 'yesterday_today' ? 'Yesterday Leads' : topCountryDataCharts.timePeriod === 'lastWeek_currentWeek' ? 'Last Week Leads' : topCountryDataCharts.timePeriod === 'lastMonth_currentMonth' ? 'Last Month Leads' : topCountryDataCharts.timePeriod === 'lastYear_currentYear' ? 'Last Year Leads' : ''
  }

  if (topCountryDataCharts.timePeriod === 'none') {
    switch (header) {
      case allHeaders.position:
        if (index === 0) return <StarFirst />
        if (index === 1) return <StarSecond />
        if (index === 2) return <StarThird />
        if (index > 2) return <Typography sx={{ color: isCheck?.elementIndex === index ? '#4141D5' : '#393E51' }} >{`#${index + 1}`}</Typography>
        break
      case allHeaders.country:
        return <Typography sx={{ color: isCheck?.elementIndex === index ? '#4141D5' : '#393E51' }} >{row?.country}</Typography>
      case allHeaders.total:
        return <Typography sx={{ color: isCheck?.elementIndex === index ? '#4141D5' : '#393E51' }} >{row?.ftd ?? 0}</Typography>
      case allHeaders.leads:
        return <Typography sx={{ color: isCheck?.elementIndex === index ? '#4141D5' : '#393E51' }} >{row.leads ?? 0}</Typography>
    }
  } else {
    switch (header) {
      case allHeaders.position:
        if (index === 0) return <StarFirst />
        if (index === 1) return <StarSecond />
        if (index === 2) return <StarThird />
        if (index > 2) return <Typography sx={{ color: isCheck?.elementIndex === index ? '#4141D5' : '#393E51' }} >{`#${index + 1}`}</Typography>
        break
      case allHeaders.country:
        return <Typography sx={{ color: isCheck?.elementIndex === index ? '#4141D5' : '#393E51' }} >{row?.country}</Typography>
      case allHeaders.total:
        return <Typography sx={{ color: isCheck?.elementIndex === index ? '#4141D5' : '#393E51' }} >{row?.ftd ?? 0}</Typography>
      case allHeaders.total_last:
        return <Typography sx={{ color: isCheck?.elementIndex === index ? '#4141D5' : '#393E51' }} >{row?.ftdLast ?? 0}</Typography>
      case allHeaders.leads:
        return <Typography sx={{ color: isCheck?.elementIndex === index ? '#4141D5' : '#393E51' }} >{row?.leads ?? 0}</Typography>
      case allHeaders.leads_last:
        return <Typography sx={{ color: isCheck?.elementIndex === index ? '#4141D5' : '#393E51' }} >{row?.leadsLast ?? 0}</Typography>
    }
  }
}

export default elementMap
