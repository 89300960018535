import React from 'react'

import { useForm, type SubmitHandler, useFieldArray, type UseFormReturn } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'
import Button from '@/designSystem/Button'
import Form from '@/components/Form'
import Popup from '@/designSystem/Popup'
import { Typography } from '@mui/material'
import ItemsPerPageAutocomplete from '@/designSystem/Pages/Advertisers/Popups/Settings/ItemsPerPageAutocomplete'
import type { Option } from '@/designSystem/Autocomplete/types'
import { selectPresetsQueryParams, setPresetsQueryParams } from '@/store/queryParams'

type SettingsPopupProps = Pick<SettingPopupFormProps, 'handleClose'>

interface Column { name?: string, value: boolean, title: string, dbName: string }
export interface Inputs {
  limit: Option<number>
  columns: Column[]
}

export interface SettingPopupFormProps {
  form: UseFormReturn<Inputs>
  onSubmit: SubmitHandler<Inputs>
  handleClose: () => void
}

function Title () {
  const theme = useTheme()
  return (
    <Typography
      variant='leftBar'
      color={theme.palette.whiteText.main}
    >
      List Settings
    </Typography>
  )
}

export default function PresetsSettingsPopup ({ handleClose }: SettingsPopupProps) {
  const queryParams = useSelector(selectPresetsQueryParams)
  const dispatch = useDispatch()
  const theme = useTheme()

  const form = useForm<Inputs>({
    defaultValues: {
      limit: { value: queryParams?.limit, title: `${queryParams?.limit}` }
    }
  })

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    dispatch(setPresetsQueryParams({
      ...queryParams,
      limit: data?.limit?.value
    }))
    handleClose()
  }

  useFieldArray({ control: form.control, name: 'columns' })

  const handleResetForm = () => {
    form.reset({
      limit: { value: queryParams?.limit, title: `${queryParams?.limit}` }
    })
  }

  return (
    <Popup
      open
      title={<Title /> as any}
      handleClose={handleClose}
      titleSx={{ color: theme.palette.card.main }}
      maxWidth='404px'
      rootBoxSx={{
        padding: theme.spacing(4, 6),
        backgroundColor: theme.palette.inputText.main
      }}
      closeButtonSx={{
        '& .MuiSvgIcon-root': { fill: theme.palette.card.main }
      }}
    >
      <Form form={form} onSubmit={onSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          gap={theme.spacing(8)}
        >
          <ItemsPerPageAutocomplete />
          <Box
            display="flex"
            flexDirection="column"
            gap={theme.spacing(2)}
          >
            <Button
              text="Apply Settings"
              variant="contained"
              type="submit"
            />
            <Button
              text="Reset Filters"
              variant="text"
              textColor={theme.palette.red2.main}
              onClick={handleResetForm}
            />
          </Box>
        </Box>
      </Form>
    </Popup>
  )
}
