import React from 'react'

const RevenueIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="8" fill="#F8D8BD"/>
        <path d="M12.494 21V11.2H15.868C16.6427 11.2 17.2773 11.3307 17.772 11.592C18.276 11.8533 18.6493 12.208 18.892 12.656C19.1347 13.0947 19.256 13.5847 19.256 14.126C19.256 14.7327 19.0927 15.2833 18.766 15.778C18.4487 16.2727 17.9493 16.6273 17.268 16.842L19.354 21H17.632L15.728 17.052H13.978V21H12.494ZM13.978 15.932H15.784C16.456 15.932 16.946 15.7687 17.254 15.442C17.5713 15.1153 17.73 14.6907 17.73 14.168C17.73 13.6453 17.576 13.23 17.268 12.922C16.96 12.6047 16.4607 12.446 15.77 12.446H13.978V15.932Z" fill="#000931"/>
    </svg>

  )
}

export default RevenueIcon
