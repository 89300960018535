import React, { useState } from 'react'
import FilterIcon from '@/designSystem/Pages/Advertisers/Icons/Filter'

import Box from '@mui/material/Box'
import IconButton from '@/designSystem/IconButton'

import Button from '@/designSystem/Button'

import useTheme from '@mui/material/styles/useTheme'
import SettingsIcon from '@/assets/icons/Settings'

import ResetTimePopup from '@/components/Pages/TrafficDistribution/RotationControl/ResetTimePopup'
import { useGetTimeResetsQuery } from '@/store/counterTimeReset'
import ResetTime from '@/components/Pages/TrafficDistribution/RotationControl/ResetTime'
import FlexBox from '@/designSystem/FlexBox'
import RotationControlFiltersPopup from '@/components/Pages/TrafficDistribution/RotationControl/Filters/RotationControlFiltersPopup'

interface RightActionButtonsProps<T, K> {
  onClickAdd: () => void
}

export default function RightActionButtons<T, K> ({
  onClickAdd
}: RightActionButtonsProps<T, K>) {
  const [isSettingsOpen, setIsSettingOpen] = useState(false)
  const [isOpenFilterPopup, setIsOpenFilterPopup] = useState(false)

  const timeResetsData = useGetTimeResetsQuery({}).data ?? []

  const theme = useTheme()
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={theme.spacing(4)}
      >
        <ResetTime data={timeResetsData}/>
        <FlexBox
          display="flex"
          alignItems="center"
          gap={3}
        >
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => setIsSettingOpen(true)}
          >
            <SettingsIcon />
          </IconButton>
          <IconButton
            onClick={() => setIsOpenFilterPopup(true)}
          >
            <FilterIcon />
          </IconButton>
        </FlexBox>
        <Button
          sx={{
            borderRadius: '16px',
            height: '40px',
            // padding: `${spacing(1.5)} ${spacing(6)}`,
            width: 'auto'
          }}
          variant='contained'
          onClick={onClickAdd}
          text="Add New"
          textVariant='subtitle1'
        />
      </Box>
      {isSettingsOpen && (<ResetTimePopup handleClose={() => setIsSettingOpen(false)} data={timeResetsData}/>)}
      {isOpenFilterPopup && (
        <RotationControlFiltersPopup handleClose={() => setIsOpenFilterPopup(false)} />
      )}
    </>
  )
}
