import React from 'react'

import DateTimePickerLayout from '@/designSystem/DateTimePicker'
import { Controller, useFormContext } from 'react-hook-form'
import { type DateTimePickerProps as MuiDateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker'
import camelCase from 'lodash.camelcase'

type DateTimePickerProps<T> = Omit<MuiDateTimePickerProps<T>, 'label'> & {
  name?: string
  label?: string
}

export default function DateTimePicker<T> ({ name, label, ...rest }: DateTimePickerProps<T>) {
  const { control } = useFormContext()
  const fieldName = name ?? camelCase(label)
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <DateTimePickerLayout label={label} {...rest} {...field} />
      )}
    />
  )
}
