import type React from 'react'
import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Palette {
    workArea: Palette['primary']
    green: Palette['primary']
    red: Palette['primary']
    red2: Palette['primary']
    orange1: Palette['primary']
    cyen1: Palette['primary']
    stroke: Palette['primary']
    gray: Palette['primary']
    card: Palette['primary']
    leftBar: Palette['primary']
    text1: Palette['primary']
    text2: Palette['primary']
    whiteText: Palette['primary']
    inputText: Palette['primary']
    grayLong: Palette['primary']
    disabledText: Palette['primary']
    buttonHover: Palette['primary']
  }

  interface PaletteOptions {
    workArea?: PaletteOptions['primary']
    green?: PaletteOptions['primary']
    red?: PaletteOptions['primary']
    red2?: PaletteOptions['primary']
    orange1?: PaletteOptions['primary']
    cyen1?: PaletteOptions['primary']
    stroke?: PaletteOptions['primary']
    gray?: PaletteOptions['primary']
    card?: PaletteOptions['primary']
    leftBar?: PaletteOptions['primary']
    text1?: PaletteOptions['primary']
    text2?: PaletteOptions['primary']
    whiteText?: PaletteOptions['primary']
    grayLong?: PaletteOptions['primary']
    inputText?: PaletteOptions['primary']
    disabledText?: PaletteOptions['primary']
    buttonHover?: PaletteOptions['primary']
  }

  interface TypographyVariants {
    h0: React.CSSProperties
    leftBar: React.CSSProperties
    headline: React.CSSProperties
    table1: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    h0?: React.CSSProperties
    leftBar?: React.CSSProperties
    headline?: React.CSSProperties
    table1?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h0: true
    leftBar: true
    headline: true
    table1: true
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#4141D5'
    },
    workArea: {
      main: '#f50057'
    },
    card: {
      main: '#FFFFFF'
    },
    leftBar: {
      main: '#FDFDFD'
    },
    text1: {
      main: '#000931'
    },
    text2: {
      main: '#393E51'
    },
    whiteText: {
      main: '#F1F3FF'
    },
    disabledText: {
      main: '#CBCBCB'
    },
    inputText: {
      main: '#ACB0C4'
    },
    grayLong: {
      main: 'rgba(172, 176, 196, 0.5)'
    },
    green: {
      main: '#1FB526'
    },
    red: {
      main: '#FF070D'
    },
    red2: {
      main: '#DF3D3D'
    },
    orange1: {
      dark: '#c18d5e',
      main: '#EDB077',
      light: '#efcfb3'
    },
    cyen1: {
      dark: '#2990b2',
      main: '#5AC2E5',
      light: '#90d7ed'
    },
    buttonHover: {
      main: '#6D6DE2'
    },
    stroke: {
      main: '#4141D5'
    },
    gray: {
      main: '#6B6B6B'
    }
  },
  typography: {
    fontFamily: 'DM Sans',
    h0: {
      fontSize: '28px',
      fontWeight: 'normal',
      lineHeight: 'normal'
    },
    h1: {
      fontSize: '26px',
      fontWeight: 'bold',
      lineHeight: '34px'
    },
    h2: {
      fontSize: '24px',
      fontWeight: 'bold',
      lineHeight: '31px'
    },
    leftBar: {
      fontSize: '24px',
      fontWeight: 'normal',
      lineHeight: '31px'
    },
    headline: {
      fontSize: '20px',
      fontWeight: 'normal',
      lineHeight: '26px'
    },
    body1: {
      fontSize: '18px',
      fontWeight: 'normal',
      lineHeight: '23px'
    },
    body2: {
      fontSize: '18px',
      fontWeight: 'regular',
      lineHeight: '23px'
    },
    table1: {
      fontSize: '16px',
      fontWeight: 500
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '18px'
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '18px'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          height: '100%',
          width: '100%',
          margin: 0
        }
      }
    }
  },
  spacing: 4
})

export default theme
