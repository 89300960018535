import React from 'react'

import Box from '@mui/material/Box'
import Button from '@/designSystem/Button'
import useTheme from '@mui/material/styles/useTheme'
import { useNavigate } from 'react-router-dom'
import { IconButton } from '@mui/material'
import AddIcon from '@/designSystem/Icons/Add'
import QuestionMarkIcon from '@/designSystem/PageContentLayout/Icons/QuestionMark'
import SettingsIcon from '@/assets/icons/Settings'
import TermsInfoModal from './TermsInfoModal'
import { AddEditTermModal } from './AddEditTermModal'
import { WidgetSettingsModal } from '../../DrillDownReports/WidgetSettingsModal'

export default function LeftActionButtons (): JSX.Element {
  const theme = useTheme()
  const navigate = useNavigate()

  const [openInfo, setOpenInfo] = React.useState(false)
  const [openAdd, setOpenAdd] = React.useState(false)
  const [openTerms, setOpenTerms] = React.useState(false)

  const onClickReload = () => navigate(0)

  const openInfoModal = () => {
    setOpenInfo(true)
  }

  const closeInfoModal = () => {
    setOpenInfo(false)
  }

  const openAddModal = () => {
    setOpenAdd(true)
  }

  const closeAddModal = () => {
    setOpenAdd(false)
  }

  const handleOpenTermsModal = () => {
    setOpenTerms(true)
  }

  const handleCloseTermsModal = () => {
    setOpenTerms(false)
  }

  return (
    <>
      <Box
        display="flex"
        gap={theme.spacing(3)}
        alignItems="center"
      >
        <IconButton onClick={openInfoModal} >
          <QuestionMarkIcon />
        </IconButton>
        <Button
          sx={{
            borderRadius: '16px',
            height: '30px',
            padding: `${theme.spacing(1.5)} ${theme.spacing(6)}`
          }}
          variant='contained'
          onClick={onClickReload}
          text="Reload"
          textVariant='subtitle1'
        />
        <IconButton onClick={openAddModal}>
          <AddIcon />
        </IconButton>
        <IconButton
          onClick={handleOpenTermsModal}
        >
          <SettingsIcon />
        </IconButton>
      </Box>
      {openInfo && <TermsInfoModal handleClose={closeInfoModal} />}
      {openAdd && <AddEditTermModal handleClose={closeAddModal} open={openAdd} />}
      {openTerms && <WidgetSettingsModal open={openTerms} onClose={handleCloseTermsModal} isSettings />}
    </>
  )
}
