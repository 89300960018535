import React from 'react'
import { type FilterItemProps } from '.'
import AutocompleteFilterItem from './AutocompleteFilterItem'
import { useSelector } from 'react-redux'
import { selectSaleStatuses } from '@/store/saleStatuses'

const CrmSaleStatusFilterItem = (props: FilterItemProps) => {
  const saleStatuses = useSelector(selectSaleStatuses)

  const statusesOptions = saleStatuses?.map((item: any) => ({
    title: item?.name,
    value: item?.name
  })) ?? []

  return <AutocompleteFilterItem {...props} options={statusesOptions} />
}

export default CrmSaleStatusFilterItem
