import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PageContentLayout from '@/designSystem/PageContentLayout'
import LeftActionButtons from '@/components/Pages/Postbacks/LeftActionButtons'
import RightActionButtons from '@/components/Pages/Postbacks/RightActionButtons'
import ExtraActions from '@/components/Pages/Postbacks/ExtraActions'
import { useDebounce } from 'use-debounce'
import { Typography, useTheme } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'
import PostBacksList from '@/components/Pages/Postbacks/PostBacksList'
import { ESoftFilled, useSearchPostBacksQuery } from '@/store/postBacks'
import { selectPostbacksQueryParams, setPostbacksQueryParams } from '@/store/queryParams'

export default function PostBackPage () {
  const [search, setSearch] = useState<string>('')
  const dispatch = useDispatch()
  const queryParams = useSelector(selectPostbacksQueryParams)
  const { data: postbacksApi }: any = useSearchPostBacksQuery({ sortField: ESoftFilled.PRIORITY, filter: queryParams.filter })

  const [debouncedSearch] = useDebounce(search, 500)

  useEffect(() => {
    if (debouncedSearch && debouncedSearch.length > 0) {
      dispatch(setPostbacksQueryParams({
        ...queryParams,
        filter: {
          name: debouncedSearch
        }
      }))
    } else {
      dispatch(setPostbacksQueryParams({ ...queryParams, filter: {} }))
    }
  }, [debouncedSearch])

  return (
    <PageContentLayout
      title="Postbacks"
      leftActionButtons={<LeftActionButtons />}
      rightActionButtons={<RightActionButtons />}
    >
      <ExtraActions search={search} onChange={setSearch}/>
      <Headers />
      <PostBacksList />
    </PageContentLayout>
  )
}

const Headers = () => {
  const { palette, spacing } = useTheme()
  const headers = ['Name', 'Passing Rule', 'Settings', '']
  return <FlexBox sx={{ paddingLeft: spacing(4) }}>
    {headers.map((item) => <Typography key={item} variant='h1' color={palette.text2.main} sx={{ flex: item === headers?.[0] ? '0 0 17%' : item === headers?.[1] ? '0 0 30%' : `0 0 ${100 / headers.length}%` }}>
      {item}
    </Typography>)}
  </FlexBox>
}
