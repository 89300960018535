import React, { useState } from 'react'
import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'

import DateTimePicker from '@/components/Form/DateTimePicker'

import Popup from '@/designSystem/Popup'
import Button from '@/designSystem/Button'
import Accordion from '@/designSystem/Accordion'

import { type SxProps, Typography, styled } from '@mui/material'

import { useSelector, useDispatch } from 'react-redux'
import { type LeadsFilters } from '@/store/leads'
import { setLeadsQueryParams, selectLeadsQueryParams } from '@/store/queryParams'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import { type FilterItemType, filterCategories } from './constants'
import FilterItem from '../../Hold/Filters/FilterItem'

interface FiltersPopupProps {
  handleClose: () => void
}

export interface FilterPopupFormProps {
  handleClose: () => void
  resetFilters: () => void
}

export default function LeadsFiltersPopup ({
  handleClose
}: FiltersPopupProps) {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectLeadsQueryParams)
  const theme = useTheme()
  const { palette, spacing } = theme

  const [filters, setFilters] = useState<LeadsFilters>(queryParams.filters ?? {})

  const onSubmitClick = () => {
    dispatch(setLeadsQueryParams({
      ...queryParams,
      filters
    }))
    handleClose()
  }

  const onDelete = (field: keyof LeadsFilters, value: any, isMultiselect: boolean) => {
    if (!isMultiselect) return
    const filtersValue = filters[field]
    if (filtersValue && Array.isArray(filtersValue) && filtersValue[0] !== undefined && typeof filtersValue[0] === 'string' && filtersValue?.includes(value)) { // delete if value is the same
      const newFilters = {
        ...filters,
        [field]: (filters[field] as any[]).filter((i) => i !== value)
      }
      setFilters(newFilters)
    }
  }

  const onChangeFilters = (field: keyof LeadsFilters, value: any, isMultiselect: boolean) => {
    if (value === undefined || value === null || (typeof value === 'string' && value.length === 0)) return
    if (isMultiselect) {
      const newFilters = {
        ...filters,
        [field]: Array.isArray(filters[field]) ? (filters[field] as any[]).concat(value) : [value]
      }
      setFilters(newFilters)
    } else {
      const newFilters = {
        ...filters,
        [field]: value
      }
      setFilters(newFilters)
    }
  }

  const resetFilters = () => {
    dispatch(setLeadsQueryParams({ ...queryParams, filters: undefined }))
    handleClose()
  }

  const bgColor = '#2b2d42'

  const accordionSx: SxProps = {
    backgroundColor: bgColor,
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '& .MuiAccordionSummary-root': {
      color: palette.card.main
    },
    '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
      fill: palette.card.main,
      marginRight: '1.3px'
    }
  }

  return (
    <Popup
      open
      title={<Title /> as any}
      handleClose={handleClose}
      titleSx={{ color: palette.card.main }}
      maxWidth='404px'
      rootBoxSx={{
        padding: spacing(4, 6),
        backgroundColor: bgColor
      }}
      closeButtonSx={{
        '& .MuiSvgIcon-root': { fill: palette.card.main }
      }}
    >
      <ScrollBarContainer>
        <Box
          display="flex"
          flexDirection="column"
          gap={spacing(3)}
        >
          {Object.entries(filterCategories).map(([accordionName, fields]) =>
              <Accordion key={accordionName} title={accordionName} sx={accordionSx}>
                {fields.map((i: FilterItemType) =>
                  <FilterItem
                    onDelete={onDelete}
                    key={i.name}
                    title={i.title}
                    name={i.name as any}
                    onChange={onChangeFilters}
                    isMultiple={i.isMultiple}
                    selectedValues={filters[i.name]}
                  />)}
              </Accordion>
          )}
          <Box
            display="flex"
            flexDirection="column"
            gap={theme.spacing(2)}
          >
            <Button
              text="Apply Filters"
              variant="contained"
              onClick={onSubmitClick}
            />
            <Button
              text="Reset Filters"
              variant="text"
              textColor={theme.palette.red2.main}
              onClick={resetFilters}
            />
          </Box>
        </Box>
      </ScrollBarContainer>
    </Popup>
  )
}

function Title () {
  const theme = useTheme()
  return (
    <Typography
      variant='headline'
      color={theme.palette.whiteText.main}
    >
      Filters
    </Typography>
  )
}

export const CustomizedDatePickers = styled(DateTimePicker)(`  
.MuiInputBase-root{
  background: #FFFFFF;
  border-radius: 16px;
  padding: 8px 16px;
}
input{
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #393E51;
}
label{
  color: #6B6B6B;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: -9px;
  &.Mui-focused{
    margin-top: -4px;
  }
}
`)
