/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'

import { format } from 'date-fns'

import { Box, Typography, useTheme } from '@mui/material'
import { ReactComponent as ThreeDots } from '@/assets/icons/three-dots.svg'
import InjectTableWrapper from './InjectTableWrapper'
import { type ITaskInjection, type IInjection } from '../InjectionTypes'
import { ModalEventInfo } from '../../../designSystem/ModalMessagesLogs'
import { useSelector } from 'react-redux'
import { selectInjection } from '@/store/injections'
import { TextField } from '@/designSystem/Table/Fields'

interface ITableInject {
  idInjection: string
}

enum LeadProcessingStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  REJECTED = 'REJECTED',
  ERROR = 'ERROR'
}

const BoxWrapper = ({ children }: any) => {
  return (
    <Box
      sx={{ height: '67px', display: 'table-cell', verticalAlign: 'middle' }}
    >
      {children}
    </Box>
  )
}

const MenuInjectTable = ({ idInjection }: ITableInject) => {
  const injectionsState: IInjection[] = useSelector(selectInjection)
  const injection: any = injectionsState?.find((injection: IInjection) => injection.id === idInjection) ?? {}
  const { palette } = useTheme()

  const [tasksMode, setTasksMode] = React.useState(injection?.leadsPivot?.length > 0 ? injection?.leadsPivot[0]?.processingStatus : '')
  const [tasks, setTasks] = React.useState<any>([])
  const [tasksStatusColor, setTasksStatusColor] = React.useState('')
  const [modalLogs, setModalLogs] = React.useState<any>(null)

  const taskspending = injection?.leadsPivot?.filter((task: ITaskInjection) => task?.processingStatus === LeadProcessingStatus.PENDING)
  const tasksInProgress = injection?.leadsPivot?.filter((task: ITaskInjection) => task?.processingStatus === LeadProcessingStatus.IN_PROGRESS)
  const tasksSuccess = injection?.leadsPivot?.filter((task: ITaskInjection) => task?.processingStatus === LeadProcessingStatus.SUCCESS)
  const tasksRejected = injection?.leadsPivot?.filter((task: ITaskInjection) => task?.processingStatus === LeadProcessingStatus.REJECTED)
  const tasksError = injection?.leadsPivot?.filter((task: ITaskInjection) => task?.processingStatus === LeadProcessingStatus.ERROR)

  useEffect(() => {
    if (tasksMode === 'PENDING') {
      setTasks(taskspending)
      setTasksStatusColor('#6B6B6B')
    }
    if (tasksMode === 'IN_PROGRESS') {
      setTasks(tasksInProgress)
      setTasksStatusColor('#F8AE6F')
    }
    if (tasksMode === 'SUCCESS') {
      setTasks(tasksSuccess)
      setTasksStatusColor('#1CA220')
    }
    if (tasksMode === 'REJECTED') {
      setTasks(tasksRejected)
      setTasksStatusColor('#F0571B')
    }
    if (tasksMode === 'ERROR') {
      setTasks(tasksError)
      setTasksStatusColor('#F81417')
    }
  }, [tasksMode])

  const validDate = (date: Date) => { return date && !isNaN(date.getTime()) }

  const rowsInjectMain = [
    {
      id:
        <BoxWrapper>
          <TextField text={injection?.id} limitation={1} />
        </BoxWrapper>,
      name:
        <BoxWrapper>
          <Typography
            display="flex"
            variant="body1"
            color={palette.text1.main}
            width="max-content">
            {injection?.name ? injection?.name : 'Empty'}
          </Typography>
        </BoxWrapper>,
      description:
        <BoxWrapper>
          <Typography
            display="flex"
            variant="body1"
            color={palette.text1.main}
            width="max-content">
            {injection?.description ? injection?.description : 'No description'}
          </Typography>
        </BoxWrapper>,
      status:
        <BoxWrapper>
          <Typography
            display="flex"
            variant="body1"
            color={palette.gray.main}
            width="max-content">
            {injection?.status ? injection?.status : 'Empty'}
          </Typography>
        </BoxWrapper>
    }]

  const rowsInjectSettings = [
    {
      interval:
        <BoxWrapper>
          <Typography
            display="flex"
            variant="body1"
            color={palette.text1.main}
            width="170px">
            {`${injection?.interval || 'Empty'} Millisec`}
          </Typography>
        </BoxWrapper>,
      advertiser:
        <BoxWrapper>
          <TextField text={injection?.advertiserId} limitation={1} />
        </BoxWrapper>
    }]

  const tasksInjectSettings = [{
    pending:
      <BoxWrapper>
        <Box
          onClick={() => setTasksMode('PENDING')}
          sx={{
            display: 'table-cell',
            verticalAlign: 'middle',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 500,
            color: '#FFFFFF',
            padding: '6px 8px',
            alignItems: 'flex-end',
            gap: '8px',
            borderRadius: '8px',
            background: '#6B6B6B',
            width: '38px',
            height: '44px',
            cursor: 'pointer'
          }}
        >
          {taskspending?.length || 0}
        </Box>
      </BoxWrapper>,
    inProgress:
      <BoxWrapper>
        <Box
          onClick={() => setTasksMode('IN_PROGRESS')}
          sx={{
            display: 'table-cell',
            verticalAlign: 'middle',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 500,
            color: '#FFFFFF',
            padding: '6px 8px',
            alignItems: 'flex-end',
            gap: '8px',
            borderRadius: '8px',
            background: '#F8AE6F',
            width: '38px',
            height: '44px',
            cursor: 'pointer'
          }}
        >
          {tasksInProgress?.length || 0}
        </Box>
      </BoxWrapper>,
    success:
      <BoxWrapper>
        <Box
          onClick={() => setTasksMode('SUCCESS')}
          sx={{
            display: 'table-cell',
            verticalAlign: 'middle',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 500,
            color: '#FFFFFF',
            padding: '6px 8px',
            alignItems: 'flex-end',
            gap: '8px',
            borderRadius: '8px',
            background: '#1CA220',
            width: '38px',
            height: '44px',
            cursor: 'pointer'
          }}
        >
          {tasksSuccess?.length || 0}
        </Box>
      </BoxWrapper>,
    stopped:
      <BoxWrapper>
        <Box
          onClick={() => setTasksMode('REJECTED')}
          sx={{
            display: 'table-cell',
            verticalAlign: 'middle',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 500,
            color: '#FFFFFF',
            padding: '6px 8px',
            alignItems: 'flex-end',
            gap: '8px',
            borderRadius: '8px',
            background: '#F0571B',
            width: '38px',
            height: '44px',
            cursor: 'pointer'
          }}
        >
          {tasksRejected?.length || 0}
        </Box>
      </BoxWrapper>,
    error:
      <BoxWrapper>
        <Box
          onClick={() => setTasksMode('ERROR')}
          sx={{
            display: 'table-cell',
            verticalAlign: 'middle',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 500,
            color: '#FFFFFF',
            padding: '6px 8px',
            alignItems: 'flex-end',
            gap: '8px',
            borderRadius: '8px',
            background: '#F81417',
            width: '38px',
            height: '44px',
            cursor: 'pointer'
          }} >
          {tasksError?.length || 0}
        </Box>
      </BoxWrapper>
  }]

  const stampsDateAt = validDate(new Date(injection?.createdAt)) ? format(new Date(injection?.createdAt), 'P HH:mm:ss') : 'Empty'
  const stampsDateUp = validDate(new Date(injection?.updatedAt)) ? format(new Date(injection?.updatedAt), 'P HH:mm:ss') : 'Empty'
  const timeStampsInjectSettings = [
    {
      createdArt:
        <BoxWrapper>
          <Typography
            display="flex"
            variant="body1"
            color={palette.text1.main}
            width="190px">
            {stampsDateAt}
          </Typography>
        </BoxWrapper>,
      stateUpdatedAt:
        <BoxWrapper>
          <Typography
            display="flex"
            variant="body1"
            color={palette.text1.main}
            width="190px">
            {stampsDateUp}
          </Typography>
        </BoxWrapper>
    }]

  const tasksMainInjectSettings = tasks?.map((task: ITaskInjection) => {
    return {
      injectionId:
        <BoxWrapper>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px'
            }}>
            <Box
              sx={{
                borderRadius: '0px 6px 6px 0px',
                width: '6px',
                height: '36px',
                backgroundColor: tasksStatusColor
              }}
            />
            <TextField text={task?.injectionId} limitation={1} />
          </Box>
        </BoxWrapper>,
      leadId:
        <BoxWrapper>
          <Typography
            display="flex"
            variant="body1"
            color={palette.gray.main}
            width="max-content">
            <TextField text={task?.leadId} limitation={1} />
          </Typography>
        </BoxWrapper>,
      processingStatus:
        <BoxWrapper>
          <Box
            sx={{
              display: 'table-cell',
              verticalAlign: 'middle',
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 500,
              color: '#FFFFFF',
              padding: '6px 12px',
              alignItems: 'flex-end',
              borderRadius: '8px',
              background: tasksStatusColor,
              width: '82px',
              height: '45px'
            }} >
            {task?.processingStatus}
          </Box>
        </BoxWrapper>,
      extraData:
        <BoxWrapper>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '300px',
              gap: '8px'
            }}
          >
            <Typography
              display="flex"
              variant="body1"
              color={palette.text1.main}
              width="max-content"
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: '240px'
              }}
            > {task?.extraData ? JSON.stringify(task?.extraData) : 'No data logs'}
            </Typography>
            {task?.extraData && <ThreeDots
              onClick={() => setModalLogs(JSON.stringify(task?.extraData))}
              cursor={'pointer'}
            />}
          </Box >
        </BoxWrapper>,
      createdAt:
        <BoxWrapper>
          <Typography
            display="flex"
            variant="body1"
            color={palette.text1.main}
            width="max-content">
            {validDate(new Date(task?.createdAt)) ? format(new Date(task?.createdAt), 'P HH:mm:ss') : 'Empty'}
          </Typography>
        </BoxWrapper>,
      updatedAt:
        <BoxWrapper>
          <Typography
            display="flex"
            variant="body1"
            color={palette.text1.main}
            width="max-content">
            {validDate(new Date(task?.updatedAt)) ? format(new Date(task?.updatedAt), 'P HH:mm:ss') : 'Empty'}
          </Typography>
        </BoxWrapper>
    }
  })

  return (
    <>
      <InjectTableWrapper
        rowsInjectMain={rowsInjectMain}
        rowsInjectSettings={rowsInjectSettings}
        tasksInjectSettings={tasksInjectSettings}
        timeStampsInjectSettings={timeStampsInjectSettings}
        tasksMainInjectSettings={tasksMainInjectSettings}
        tasksLength={injection?.leadsPivot?.length}
      />
      {modalLogs && <ModalEventInfo
        open={!!modalLogs}
        handleClose={() => setModalLogs(null)}
        logs={modalLogs}
      />}
    </>
  )
}

export default MenuInjectTable
