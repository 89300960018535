import React, { useEffect, useState } from 'react'

import PageContentLayout from '@/designSystem/PageContentLayout'
import Tabs from '@/designSystem/Tabs'
import ReloadButton from '@/designSystem/ReloadButton'
import { Box } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import FlexBox from '@/designSystem/FlexBox'
import MenuLeadTable from './MenuLeadTable/MenuLeadTable'
import PullingLeadTable from './MenuLeadTable/PullingInformation'
import LeadsLogs from './LeadsLogs'
import { selectPostbackSent, selectPostbackSentQueryParams, setPostbackSent, useSearchPostBackSentQuery } from '@/store/postBacksSent'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useGetLogsQuery } from '@/store/logs'
import LeadsActions from '../LeadsActions'
import UpdateLeadStateModal from '@/components/Pages/TrafficData/Leads/LeadInformationPage/UpdateLeadStateModal'
import { LeadState } from '@/enums/leads.enum'
import { ToastContainer } from 'react-toastify'
import { toastStyle } from '@/styles'

interface LeadInformationPageProps {
  onClickReload: () => void
  dataLead: any
}

export default function LeadInformationPageLayout ({
  onClickReload, dataLead
}: LeadInformationPageProps) {
  const dispatch = useDispatch()
  const leadId = useLocation().pathname.split('/').pop()
  const { page, limit } = useSelector(selectPostbackSentQueryParams)
  const postBackSentData = useSelector(selectPostbackSent)
  const executedPostbackSent = leadId && useSearchPostBackSentQuery({ page, limit, filter: { leadId: { in: [leadId] } } })?.data
  const saleStatusesLogs = useGetLogsQuery({ limit: 100, sortOrder: 'asc', filter: { leadId, action: 'LEAD_SALE_STATUS_CHANGE' } })?.data?.logs ?? []
  const [openUpdateLeadStateModal, setOpenUpdateLeadStateModal] = useState(false)

  interface Result {
    state: LeadState | null
    stateTo?: LeadState | null
    updatedAt: string | null
    stateFrom?: string | null
  }

  const processLogs = (dataLead: any): Result[] => {
    const logs = dataLead?.logs ?? []

    const filteredAndSortedLogs = logs?.filter(
      (log: any) => log?.action === 'LEAD_CHANGE' && log?.logData?.data?.state
    )
      .map((log: any) => ({
        stateFrom: log?.logData?.data?.state?.from,
        stateTo: log?.logData?.data?.state?.to,
        updatedAt: log?.updatedAt
      }))
      .sort(
        (a: any, b: any) =>
          new Date(a?.updatedAt)?.getTime() - new Date(b?.updatedAt)?.getTime()
      )

    const results = filteredAndSortedLogs?.reduce(
      (acc: Result[], log: any, index: number) => {
        if (index === 0) {
          acc.push({
            state: log?.stateFrom || dataLead?.state,
            updatedAt: dataLead?.createdAt
          })
        }
        acc.push({
          state: log?.stateTo,
          updatedAt: log?.updatedAt
        })
        return acc
      },
      []
    )

    if (results.length === 0) {
      results.push({
        state: dataLead?.state,
        updatedAt: dataLead?.createdAt
      })
    }

    return results?.filter(
      (block: Result) => !!block?.state || !!block?.updatedAt
    )
  }
  const filteredAndSortedLogs = processLogs(dataLead)

  const parsedLogData = saleStatusesLogs?.map((entry: any) => {
    const logData = entry.logData?.data
    const crmSaleStatus = logData?.crmSaleStatus ? `From: ${logData?.crmSaleStatus?.from}, To: ${logData?.crmSaleStatus?.to}` : 'N/A'
    const externalStatus = logData?.externalStatus ? `From: ${logData?.externalStatus?.from}, To: ${logData?.externalStatus?.to}` : 'N/A'

    return {
      id: entry?.id,
      crmSaleStatus,
      externalStatus,
      createdAt: entry?.createdAt
    }
  })

  const [chosenTab, setChosenTab] = useState(0)
  const { spacing } = useTheme()

  useEffect(() => {
    if (executedPostbackSent) {
      dispatch(setPostbackSent(executedPostbackSent))
    }
  }, [executedPostbackSent])
  const logs = dataLead?.logs ?? []
  const tabs = ['Basic Information', 'Conversions', 'Logs']
  return (
    <PageContentLayout
      title={<Box sx={{ color: '#000931', fontSize: '28px', marginRight: '16px' }}>Leads</Box>}
      leftActionButtons={<Box marginLeft={spacing(-5)}><ReloadButton onClick={onClickReload} /></Box>}
      rightActionButtons={<LeadsActions setOpenUpdateLeadStateModal={dataLead?.state === LeadState.PUSHED_LEAD ? setOpenUpdateLeadStateModal : undefined} ids={[dataLead?.id]} />}
    >
      <FlexBox column >
        <Box>
          <Tabs
            chosenTab={chosenTab}
            setChosenTab={setChosenTab}
            tabs={tabs}
          />
        </Box>
        {chosenTab === 0 && <MenuLeadTable dataLead={dataLead} onClickReload={onClickReload} postBackSentData={postBackSentData}
          filteredAndSortedLogs={filteredAndSortedLogs} />}
        {chosenTab === 1 && <PullingLeadTable conversionId={dataLead?.conversionId ?? null} saleStatusData={parsedLogData}
          onClickReload={onClickReload} />}
        {chosenTab === 2 && <LeadsLogs leadLogs={logs} />}
      </FlexBox>
      {openUpdateLeadStateModal && (
        <UpdateLeadStateModal leadIds={[dataLead?.id]} handleClose={() => {
          setOpenUpdateLeadStateModal(false)
        }} />
      )}
      <ToastContainer />
    </PageContentLayout>
  )
}
