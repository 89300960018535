import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
import { type IInjection } from '@/components/Injections/InjectionTypes'

export const injectionsHeaders: Record<string, string> = {
  name: 'Name',
  description: 'Description',
  interval: 'Interval',
  status: 'Status',
  state: 'State',
  createdAt: 'Created At',
  updatedAt: 'Updated At'
}

export interface InjectionsFilters {
  createdAt?: {
    from?: string
    to?: string
  }
  status?: { in: string[] } | undefined
  id?: { in: string[] } | undefined
  name?: string | undefined
  advertiserId?: { in: string[] } | undefined
  state?: { in: string[] } | undefined
}

export interface InjectionsState {
  injection: IInjection[]
  totalCount?: number
  menu?: {
    name: string
    id: string
  }
}

const initialState: InjectionsState = {
  injection: [],
  totalCount: 0,
  menu: { name: '', id: '' }
}

export const injectionsSlice = createSlice({
  name: 'injections',
  initialState,
  reducers: {
    setInjectTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setInjectMenu: (state, action: PayloadAction<{ name: string, id: string }>) => {
      state.menu = action.payload
    },
    setInjection: (state, action: PayloadAction<IInjection[]>) => {
      state.injection = action.payload
    }
  }
})

export const { setInjectTotalCount, setInjectMenu, setInjection } = injectionsSlice.actions

export const selectInjectTotalCount = (state: RootState) => state.injections.totalCount
export const selectInjectMenu = (state: RootState) => state.injections.menu
export const selectInjection = (state: RootState) => state.injections.injection

export default injectionsSlice.reducer

interface GetInjectionsArgs {
  limit?: number
  page?: number
  filters?: InjectionsFilters
}

type CreateEditInjectionsArgs = Pick<IInjection, 'interval' | 'name' | 'advertiserId' | 'description'
| 'overrideFields' > & { leadIds: string[] }

interface GetInjectionsReturn {
  injection: IInjection[]
  totalCount: number
}

export const injectionsApi = createApi({
  reducerPath: 'injectionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/injections`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['Injections'],
  endpoints: (builder) => ({
    getInjections: builder.query<GetInjectionsReturn, GetInjectionsArgs>({
      query: ({ limit = 10, page = 1, filters }) => ({
        url: '/search',
        method: 'POST',
        body: {
          limit,
          page,
          sortField: 'createdAt',
          sortOrder: 'desc',
          createdAt: filters?.createdAt ?? undefined,
          include: {
            leadsPivot: true
          },
          filter: {
            status: filters?.status,
            id: filters?.id,
            name: filters?.name,
            state: filters?.state,
            advertiserId: filters?.advertiserId
          }
        }
      }),
      providesTags: [{ type: 'Injections', id: 'InjectionsLIST' }]
    }),
    createInjections: builder.mutation<IInjection, CreateEditInjectionsArgs>({
      query: ({
        name, description, advertiserId, interval, overrideFields, leadIds
      }) => ({
        url: '',
        method: 'POST',
        body: {
          name,
          description,
          advertiserId,
          interval,
          overrideFields,
          leadIds,
          status: 'ACTIVE'
        }
      }),
      invalidatesTags: [{ type: 'Injections', id: 'InjectionsLIST' }]
    }),
    editInjections: builder.mutation<IInjection, Partial<CreateEditInjectionsArgs> & { id: string }>({
      query: ({ name, description, id, advertiserId }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: {
          name,
          description,
          advertiserId
        }
      }),
      invalidatesTags: [{ type: 'Injections', id: 'InjectionsLIST' }]
    })

  })
})

export const { useGetInjectionsQuery, useCreateInjectionsMutation, useEditInjectionsMutation } = injectionsApi
