import React from 'react'
import {
  FormControlLabel,
  type CheckboxProps as MuiCheckboxProps,
  type FormControlLabelProps
} from '@mui/material'

import CheckboxLayout from '@/designSystem/Checkbox'
import { Controller, useFormContext } from 'react-hook-form'
import camelCase from 'lodash.camelcase'
import { useTheme } from '@mui/material'

// TODO: Rename it mb
type CustomFormControlProps = Omit<FormControlLabelProps, 'control' | 'label' | 'defaultValue'>
export type CheckboxProps = CustomFormControlProps & {
  checkboxProps?: MuiCheckboxProps
  label: string
  defaultValue?: boolean | Record<string, unknown>
}

export default function Checkbox (props: CheckboxProps) {
  const { palette } = useTheme()
  const { checkboxProps, defaultValue, name, label, disabled, ...rest } = props

  const { control } = useFormContext()

  const checkboxName = name ?? camelCase(label)
  return (
    <FormControlLabel
      control={(
        <Controller
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => {
            return (
              <CheckboxLayout
                disabled={disabled}
                {...checkboxProps}
                {...field}
                checked={field?.value?.value ?? field?.value}
                onChange={(e) => field.onChange({
                  ...field.value,
                  value: e.target.checked
                })}
              />
            )
          }}
          name={checkboxName}
        />
      )}
      label={label}
      {...rest}
      sx={{
        color: disabled ? palette.disabledText.main : undefined,
        ...props.sx
      }}
    />
  )
}
