import { Box } from '@mui/material'
import type { Option } from '@/designSystem/Autocomplete/types'
import React from 'react'
import Autocomplete from '@/designSystem/Pages/Advertisers/Autocomplete'
import {
  selectGeneralCharts, setGeneralCharts,
  selectCurrentRange,
  useSearchChartRevenueQuery,
  useSearchLeadChartQuery
} from '@/store/dashboard'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import { getCurrentDay, getCurrentMonth, getCurrentWeek, getCurrentYear, getLastMonth, getLastWeek, getLastYear, getYesterday } from '../utils'
export default function GeneralChartRightButtons () {
  const dispatch = useDispatch()
  const generalCharts = useSelector(selectGeneralCharts)
  const getDateValues = [
    { title: 'No time period selected', value: 'none' },
    { title: 'Last Year vs Current Year', value: 'lastYear_currentYear' },
    { title: 'Last Month vs Current Month', value: 'lastMonth_currentMonth' },
    { title: 'Last Week vs Current Week', value: 'lastWeek_currentWeek' },
    { title: 'Yesterday vs Today', value: 'yesterday_today' }
  ]
  const currentRange = useSelector(selectCurrentRange)

  const searchLeadChartRevenue: any = useSearchChartRevenueQuery(currentRange).data
  const searchLeadChart: any = useSearchLeadChartQuery(currentRange).data
  const handlerCurrentChart = (chart: string) => {
    dispatch(
      setGeneralCharts({
        ...generalCharts,
        view: chart,
        leads: searchLeadChart?.data && searchLeadChart?.data,
        revenue: searchLeadChartRevenue?.data && searchLeadChartRevenue?.data
      })
    )
  }

  const onChangeTimePeriod = (value: Option<string> | null) => {
    let currentRangeX
    let lastRange
    switch (value?.value) {
      case 'yesterday_today':
        currentRangeX = getCurrentDay()
        lastRange = getYesterday()
        break
      case 'lastWeek_currentWeek':
        currentRangeX = getCurrentWeek()
        lastRange = getLastWeek()
        break
      case 'lastMonth_currentMonth':
        currentRangeX = getCurrentMonth()
        lastRange = getLastMonth()
        break
      case 'lastYear_currentYear':
        currentRangeX = getCurrentYear()
        lastRange = getLastYear()
        break
      default:
        currentRangeX = generalCharts.localCurrentRange
        lastRange = generalCharts.localLastRange
    }
    dispatch(
      setGeneralCharts({
        ...generalCharts,
        localCurrentRange: currentRangeX,
        localLastRange: lastRange,
        timePeriod: value?.value
      })
    )
  }
  return (
    <Box
      display="flex"
      gap={6}
      alignItems="center"
    >
      <Box sx={{
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '26px',
        display: 'flex',
        alignItems: 'center'
      }}>
        <Button onClick={() => handlerCurrentChart('lead')} variant="text" sx ={{ textTransform: 'capitalize', fontSize: '20px', fontWeight: 700, color: generalCharts.view === 'lead' ? '#4141D5' : '#6B6B6B' }}>Leads & FTD’s</Button>
        <Button onClick={() => handlerCurrentChart('revenue')} variant="text" sx ={{ textTransform: 'capitalize', fontSize: '20px', fontWeight: 700, color: generalCharts.view === 'revenue' ? '#4141D5' : '#6B6B6B' }}>Revenue & Payout</Button>
        <Button onClick={() => handlerCurrentChart('reject')} variant="text" sx ={{ textTransform: 'capitalize', fontSize: '20px', fontWeight: 700, color: generalCharts.view === 'reject' ? '#4141D5' : '#6B6B6B' }}>Rejected Leads</Button>

      </Box>
      <Autocomplete
        onChange={onChangeTimePeriod}
        options={getDateValues}
        value={getDateValues.find((item) => item.value === generalCharts.timePeriod)}
        clearIcon=''
        placeholder={'No time period selected'}
        leftShift
        defaultValue={getDateValues[0]}
        sx={{ width: 300 }}
      />
    </Box>
  )
}
