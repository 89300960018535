import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
import type { Option } from '@/designSystem/Autocomplete/types'

export interface DrillReportsState {
  drillReportsState: IDrillReport
  headersState: string[]
  dataState: GetDrillReportsArgs | null
}

const initialState: DrillReportsState = {
  drillReportsState: { data: [], total: {} },
  headersState: [],
  dataState: null
}

export interface IDrillReportData {
  title: string | null
  children: IDrillReportData[]
  data: Record<string, number>
  id: string
  level: number
}

export interface IDrillReport {
  data: IDrillReportData[]
  total: Record<string, number>
}

export interface IDrillReportResponce {
  data: IDrillReport
}

export interface GetDrillReportsArgs {
  groups: string[]
  pql: string
  termsIds: string[]
  createdAt: {
    from: string
    to: string
  }
  dateAt?: Option<string>
  isEmptyPql?: boolean
}

export const drillReportsSlice = createSlice({
  name: 'drillReports',
  initialState,
  reducers: {
    setDrillReportsState: (state, action: PayloadAction<any>) => {
      state.drillReportsState = action.payload
    },
    setDrillReportsHeadersState: (state, action: PayloadAction<any>) => {
      state.headersState = action.payload
    },
    setDrillReportsDataState: (state, action: PayloadAction<any>) => {
      state.dataState = action.payload
    }
  }
})

export const { setDrillReportsState, setDrillReportsHeadersState, setDrillReportsDataState } = drillReportsSlice.actions

export const selectDrillReportsState = (state: RootState) => state.drillReports.drillReportsState
export const selectDrillReportsHeadersState = (state: RootState) => state.drillReports.headersState
export const selectDrillReportsDataState = (state: RootState) => state.drillReports.dataState

export default drillReportsSlice.reducer

export const drillReportsApi = createApi({
  reducerPath: 'drillReportsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/drilldown`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['DrillReports'],
  endpoints: (builder) => ({
    createDrillReports: builder.mutation<IDrillReport, GetDrillReportsArgs>({
      query: ({ createdAt, ...rest }) => ({
        url: '/report',
        method: 'POST',
        body: {
          ...rest,
          createdAt: {
            from: createdAt.from,
            to: createdAt.to
          }
        }
      }),
      invalidatesTags: [{ type: 'DrillReports', id: 'DrillReportsLIST' }]
    }),
    createCsvReport: builder.mutation<IDrillReport, GetDrillReportsArgs>({
      query: ({ createdAt, ...rest }) => ({
        url: '/csv',
        method: 'POST',
        body: {
          ...rest,
          createdAt: {
            from: createdAt.from,
            to: createdAt.to
          }
        }
      }),
      invalidatesTags: [{ type: 'DrillReports', id: 'DrillReportsLIST' }]
    })
  })
})

export const {
  useCreateDrillReportsMutation,
  useCreateCsvReportMutation
} = drillReportsApi
