/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Box, Typography } from '@mui/material'
import { ReactComponent as EmptyTableIcon } from '@/assets/icons/empty-table.svg'

export const EmptyTableLeads = () => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding='40px 0'
        width="100%"
        height="300px"
        borderRadius="12px"
        marginTop="24px"
        gap="24px"
        sx={{
          backgroundColor: '#FFFFFF'
        }}
      >
        <EmptyTableIcon />
        <Typography
          variant='body1'
          color="text1"
          textAlign='center'
          sx={{
            fontWeight: '500',
            width: '445px',
            color: '#000931'
          }}
        >
          Empty table.
        </Typography>
      </Box>
    </>
  )
}
