import { type TextFiledProps } from '@/designSystem/TextField'
import { type Theme } from '@mui/material'

export default function composeSx (theme: Theme): TextFiledProps['sx'] {
  return {
    placeholder: {
      color: theme.palette.card.main
    },
    title: {
      color: theme.palette.card.main
    },
    textField: {
      padding: theme.spacing(0, 0, 0, 4),
      color: theme.palette.text1.main,
      backgroundColor: theme.palette.card.main,
      '& .MuiInputBase-input': {
        padding: '0'
      },
      '& .MuiInputBase-input::placeholder': {
        opacity: '1'
      }
    }
  }
}
