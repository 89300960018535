import { type IRotationControl } from '@/components/Pages/TrafficDistribution/RotationControl/RotationControlTypes'

const findElementById = (items: IRotationControl[] | undefined, id: string | undefined | null): IRotationControl | undefined | any => {
  if (!items) return undefined
  for (const node of items) {
    const foundNode = findElementByIdRecursive(node, id)
    if (foundNode) {
      return foundNode
    }
  }
  return undefined
}

function findElementByIdRecursive (node: IRotationControl, id: string | undefined | null): IRotationControl | undefined {
  if (node.id === id) {
    return node
  }

  if (node.children) {
    for (const child of node.children) {
      const foundNode = findElementByIdRecursive(child, id)
      if (foundNode) {
        return foundNode
      }
    }
  }

  return undefined
}

export { findElementById }
