/* eslint-disable react/prop-types */
import React from 'react'

import { Box } from '@mui/material'
import { CountryField, DateTimeField, TextField } from '@/designSystem/Table/Fields'
import DealsTableWrapper from './DealsTableWrapper'
import elementConversionsMap from '@/components/Conversions/ElementConversionsMap'

interface ITableDeals {
  deals: any
  fullMode?: boolean
}

const conversionsHeaders = [
  'Conversion UUID',
  'Conversion Change Log'
]

const BoxWrapper = ({ children }: any) => {
  return (
    <Box
      sx={{ height: '67px', display: 'table-cell', verticalAlign: 'middle' }}
    >
      {children}
    </Box>
  )
}

const MenuDealsTable = ({ deals, fullMode }: ITableDeals) => {
  const conversionsPivot = deals?.conversionsPivot

  const rowsConversions: any = conversionsPivot?.map((row: any) => (
    conversionsHeaders?.map((header) =>
      elementConversionsMap(header, row))
  ))

  const rowsDealsBasikInfo = [
    {
      id:
        <BoxWrapper>
          <TextField text={deals?.id} limitation={1} />
        </BoxWrapper>,
      userId:
        <BoxWrapper>
          <TextField text={deals?.userId} limitation={1} />
        </BoxWrapper>,
      reason:
        <BoxWrapper>
          <TextField text={deals?.reason} limitation={80} />
        </BoxWrapper>
    }]

  const rowsDealsSettings = [
    {
      advertiser:
        <BoxWrapper>
          <TextField text={deals?.advertiserId} limitation={1} />
        </BoxWrapper>,
      countries:
        <BoxWrapper>
          {deals?.countries?.length > 0
            ? deals?.countries?.map((country: string, index: number) => {
              return <CountryField key={index} countryCode={country} />
            })
            : 'No countries'}
        </BoxWrapper>,
      goalType:
        <BoxWrapper>
          <TextField text={deals?.goalType} />
        </BoxWrapper>,
      newRevenue:
        <BoxWrapper>
          <TextField text={`$${deals?.newRevenue}`} />
        </BoxWrapper>
    }]

  const timeStampsDealsSettings = [
    {
      updatedAt:
        <BoxWrapper>
          <DateTimeField date={new Date(deals?.updatedAt)} />
        </BoxWrapper>,
      createdAt:
        <BoxWrapper>
          <DateTimeField date={new Date(deals?.createdAt)} />
        </BoxWrapper>
    }]

  return (
    <>
      <DealsTableWrapper
        rowsDealsSettings={rowsDealsSettings}
        rowsDealsBasikInfo={rowsDealsBasikInfo}
        timeStampsDealsSettings={timeStampsDealsSettings}
        rowsConversions={rowsConversions}
        conversionsHeaders={conversionsHeaders}
        tasksLength={deals?.tasks?.length}
        fullMode={fullMode}
      />
    </>
  )
}

export default MenuDealsTable
