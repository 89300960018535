import React, { useState } from 'react'

import Box from '@mui/material/Box'
import IconButton from '@/designSystem/IconButton'

import FilterIcon from '@/designSystem/Pages/Advertisers/Icons/Filter'
import Button from '@/designSystem/Button'
import Autocomplete, { type AutocompleteProps } from '@/designSystem/Pages/Advertisers/Autocomplete'

import useTheme from '@mui/material/styles/useTheme'
import SettingsIcon from '@/assets/icons/Settings'

import { useSelector } from 'react-redux'
import isObjectNotEmpty from '@/utils/isObjectNotEmpty'
import { selectQueryParamsAdvertisers } from '@/store/queryParams'
import AdvertisersSettingsPopup from '@/components/Advertisers/Filters/AdvertisersSettingsPopup'

type AutocompletePropsFunctionality<T> = Omit<AutocompleteProps<T>, 'placeholder'>

interface RightActionButtonsProps<T, K> {
  nameFilter: AutocompletePropsFunctionality<T>
  dateFilter: AutocompletePropsFunctionality<K>
  onClickFilter: () => void
  onResetFilters: () => void
}

export default function RightActionButtonsLayout<T, K> ({
  nameFilter,
  dateFilter,
  onClickFilter,
  onResetFilters
}: RightActionButtonsProps<T, K>) {
  const [isSettingsOpen, setIsSettingOpen] = useState(false)
  const theme = useTheme()
  const { filters } = useSelector(selectQueryParamsAdvertisers)
  const isFilter = filters && isObjectNotEmpty(filters)
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={theme.spacing(4)}
      >
        {isFilter && <Button
          onClick={onResetFilters}
          text='Reset filter'
          variant='contained'
          sx={{
            backgroundColor: theme.palette.red2.main,
            padding: `${theme.spacing(2)} ${theme.spacing(6)}`
          }}
        />}
        <IconButton onClick={onClickFilter}>
          <FilterIcon />
        </IconButton>
        <Box
          display="flex"
          alignItems="center"
          gap={theme.spacing(3)}
        >
          <Autocomplete
            {...nameFilter}
            placeholder='Name'
            leftShift
          />
          <Autocomplete
            {...dateFilter}
            placeholder='Date'
            leftShift
          />
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => setIsSettingOpen(true)}
          >
            <SettingsIcon />
          </IconButton>
        </Box>
      </Box>
      {isSettingsOpen && (<AdvertisersSettingsPopup handleClose={() => setIsSettingOpen(false)} />)}
    </>
  )
}
