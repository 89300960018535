import React from 'react'
import { Box, Typography } from '@mui/material'
import { ReactComponent as EmptyTableIcon } from '@/assets/icons/empty-table.svg'

export const EmptyTableLogs = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding='40px 0'
      width="100%"
      height="200px"
      borderRadius="12px"
      marginTop="24px"
      sx={{
        backgroundColor: '#FFFFFF'
      }}
    >
      <EmptyTableIcon />
      <Typography
        variant='body1'
        color="text1"
        width="max-content"
        paddingTop='24px'
        sx={{
          display: 'flex',
          fontWeight: '500',
          paddingBottom: '12px'
        }}
      >
        Data wasn’t found for the current filters.
      </Typography>
    </Box>
  )
}
