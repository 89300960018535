import React from 'react'

import MuiAccordion, { type AccordionProps as MuiAccordionProps } from '@mui/material/Accordion'
import { AccordionSummary, Typography, AccordionDetails } from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

type AccordionProps = Omit<MuiAccordionProps, 'title'> & {
  title: React.ReactNode
}

export default function Accordion ({
  children,
  title,
  ...rest
}: AccordionProps) {
  return (
    <MuiAccordion {...rest}>
      <AccordionSummary
        expandIcon={<ExpandMoreRoundedIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ padding: 0 }}
      >
        <Typography variant='headline'>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        {children}
      </AccordionDetails>
    </MuiAccordion>
  )
}
