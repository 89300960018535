import React from 'react'

export default function RejectedLeads () {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="45" height="45" rx="12" fill="white" />
      <path d="M31.6013 14.4123C31.475 14.2858 31.325 14.1854 31.1598 14.1169C30.9946 14.0483 30.8176 14.0131 30.6388 14.0131C30.46 14.0131 30.2829 14.0483 30.1178 14.1169C29.9526 14.1854 29.8026 14.2858 29.6763 14.4123L23 21.0749L16.3237 14.3987C16.1973 14.2723 16.0473 14.172 15.8821 14.1036C15.717 14.0352 15.54 14 15.3612 14C15.1825 14 15.0055 14.0352 14.8403 14.1036C14.6752 14.172 14.5251 14.2723 14.3987 14.3987C14.2723 14.5251 14.172 14.6752 14.1036 14.8403C14.0352 15.0055 14 15.1825 14 15.3612C14 15.54 14.0352 15.717 14.1036 15.8821C14.172 16.0473 14.2723 16.1973 14.3987 16.3237L21.0749 23L14.3987 29.6763C14.2723 29.8027 14.172 29.9527 14.1036 30.1179C14.0352 30.283 14 30.46 14 30.6388C14 30.8175 14.0352 30.9945 14.1036 31.1597C14.172 31.3248 14.2723 31.4749 14.3987 31.6013C14.5251 31.7277 14.6752 31.828 14.8403 31.8964C15.0055 31.9648 15.1825 32 15.3612 32C15.54 32 15.717 31.9648 15.8821 31.8964C16.0473 31.828 16.1973 31.7277 16.3237 31.6013L23 24.9251L29.6763 31.6013C29.8027 31.7277 29.9527 31.828 30.1179 31.8964C30.283 31.9648 30.46 32 30.6388 32C30.8175 32 30.9945 31.9648 31.1597 31.8964C31.3248 31.828 31.4749 31.7277 31.6013 31.6013C31.7277 31.4749 31.828 31.3248 31.8964 31.1597C31.9648 30.9945 32 30.8175 32 30.6388C32 30.46 31.9648 30.283 31.8964 30.1179C31.828 29.9527 31.7277 29.8027 31.6013 29.6763L24.9251 23L31.6013 16.3237C32.1201 15.8049 32.1201 14.9312 31.6013 14.4123Z" fill="#4141D5" />
    </svg>
  )
}
