import React from 'react'
import { Box, useTheme } from '@mui/material'
import Form from '@/components/Form'
import Button from '@/designSystem/Button'
import TextField from '@/components/Form/TextField'
import { type IDealsForm } from './DealsFormLayout'

export const DealsFormLayoutLast = ({ form, onSubmit, setStep, deals }: IDealsForm) => {
  const theme = useTheme()
  const [error, setError] = React.useState('')

  const newRevenue = form.watch('newRevenue')
  const countries = form.watch('countries')?.join(', ')
  const advertiser = form.watch('advertiserId')?.title
  const isReason = !!form.watch('reason')

  const handleBack = () => {
    setStep(1)
  }

  const handleSubmit = (data: any) => {
    if (isReason) {
      setError('')
      onSubmit(data)
    } else {
      setError('This field is required')
    }
  }

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Box
        color={theme.palette.text1.main}
        fontSize={18}
        fontWeight={400}
        paddingTop='32px'
      >Reason</Box>
      <TextField name='reason' placeholder='Enter text...' multiline rows={4} inputHeight='165px' />
      {error && <Box color={'#FF0000'} fontSize={14} fontWeight={400} paddingTop='8px'>{error}</Box>}

      <Box
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
        >
          <Box
            color={theme.palette.text1.main}
            fontSize={20}
            fontWeight={500}
            paddingTop={'24px'}
          >New Revenue: ${newRevenue}</Box>
          <Box
            color={'#393E51'}
            fontSize={18}
            fontWeight={400}
            paddingTop={'16px'}
          >
            {advertiser}
          </Box>
          <Box
            color={'#393E51'}
            fontSize={18}
            fontWeight={400}
            paddingTop={'8px'}
          >
            {countries}
          </Box>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          width={'186px'}
          height={'161px'}
          borderRadius={'16px'}
          marginTop={'24px'}
          sx={{
            backgroundColor: '#F1F3FF'
          }}
        >
          <Box
            fontSize={'20px'}
            fontWeight={500}
            color={'#4141D5'}
          >
            {deals?.length} Conversions
          </Box>
        </Box>
      </Box>

      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        marginTop={'56px'}
      >
        <Box
          display={'flex'}
          width={'182px'}
          height={'43px'}
          padding={'10px 12px'}
          justifyContent={'center'}
          alignItems={'center'}
          borderRadius={'12px'}
          border={'1px solid #4141D5'}
          sx={{
            cursor: 'pointer'
          }}
          onClick={handleBack}
        >
          Previous
        </Box>
        <Button
          type='submit'
          sx={{
            borderRadius: '12px',
            height: '43px',
            width: '315px',
            padding: '10px 12px',
            backgroundColor: '#4141D5',
            color: '#FFFFFF',
            marginLeft: '8px'
          }}
          variant='contained'
          text="Approve"
        />
      </Box>
    </Form>
  )
}
