import React from 'react'

const CountryIcon = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="32" height="32" rx="8" fill="#75DBF7"/>
        <path d="M19.77 17.5C19.83 17.005 19.875 16.51 19.875 16C19.875 15.49 19.83 14.995 19.77 14.5H22.305C22.425 14.98 22.5 15.4825 22.5 16C22.5 16.5175 22.425 17.02 22.305 17.5M18.4425 21.67C18.8925 20.8375 19.2375 19.9375 19.4775 19H21.69C20.9634 20.2512 19.8106 21.199 18.4425 21.67ZM18.255 17.5H14.745C14.67 17.005 14.625 16.51 14.625 16C14.625 15.49 14.67 14.9875 14.745 14.5H18.255C18.3225 14.9875 18.375 15.49 18.375 16C18.375 16.51 18.3225 17.005 18.255 17.5ZM16.5 21.97C15.8775 21.07 15.375 20.0725 15.0675 19H17.9325C17.625 20.0725 17.1225 21.07 16.5 21.97ZM13.5 13H11.31C12.0291 11.7454 13.1811 10.7961 14.55 10.33C14.1 11.1625 13.7625 12.0625 13.5 13ZM11.31 19H13.5C13.7625 19.9375 14.1 20.8375 14.55 21.67C13.184 21.1987 12.0336 20.2508 11.31 19ZM10.695 17.5C10.575 17.02 10.5 16.5175 10.5 16C10.5 15.4825 10.575 14.98 10.695 14.5H13.23C13.17 14.995 13.125 15.49 13.125 16C13.125 16.51 13.17 17.005 13.23 17.5M16.5 10.0225C17.1225 10.9225 17.625 11.9275 17.9325 13H15.0675C15.375 11.9275 15.8775 10.9225 16.5 10.0225ZM21.69 13H19.4775C19.2428 12.0711 18.8952 11.1744 18.4425 10.33C19.8225 10.8025 20.97 11.755 21.69 13ZM16.5 8.5C12.3525 8.5 9 11.875 9 16C9 17.9891 9.79018 19.8968 11.1967 21.3033C11.8931 21.9997 12.7199 22.5522 13.6299 22.9291C14.5398 23.306 15.5151 23.5 16.5 23.5C18.4891 23.5 20.3968 22.7098 21.8033 21.3033C23.2098 19.8968 24 17.9891 24 16C24 15.0151 23.806 14.0398 23.4291 13.1299C23.0522 12.2199 22.4997 11.3931 21.8033 10.6967C21.1069 10.0003 20.2801 9.44781 19.3701 9.0709C18.4602 8.69399 17.4849 8.5 16.5 8.5Z" fill="#F1F3FF"/>
    </svg>

  )
}

export default CountryIcon
