import React, { Suspense } from 'react'
import Routes from '@/routes'
import AppLoading from '@/designSystem/AppLoading'
import ErrorBoundary from '@/pages/ErrorBoundary'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from '@/store'
import './globalCss.css'

import theme from '@/theme'
import { CssBaseline } from '@mui/material'

function App () {
  return (
    <>
      <CssBaseline />
      <ErrorBoundary name="App">
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Suspense fallback={<AppLoading />}>
              <Provider store={store}>
                <PersistGate loading={<AppLoading />} persistor={persistor}>
                  <Routes />
                </PersistGate>
              </Provider>
            </Suspense>
          </LocalizationProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  )
}

export default App
