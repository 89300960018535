import React from 'react'

import useTheme from '@mui/material/styles/useTheme'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const helpInformation = {
  p1: 'Here you can view and edit revenues grouped by advertisers, countries and goal types based on rules in the Rotation Control tab.  If a rule is located outside a folder or a folder is not linked to a country, it is displayed with a No Country label.'
}

export default function HelpInformationModal () {
  const theme = useTheme()
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap={theme.spacing(3)}
        color={theme.palette.text2.main}
      >
        <Typography variant="body2">
          {helpInformation.p1}
        </Typography>
      </Box>
    </>
  )
}
