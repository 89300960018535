import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'

export interface SaleStatusesState {
  totalCount?: number
  saleStatuses: SaleStatus[]
}

const initialState: SaleStatusesState = {
  totalCount: 0,
  saleStatuses: []
}

export const saleStatusesSlice = createSlice({
  name: 'saleStatuses',
  initialState,
  reducers: {
    setSaleStatusesTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setSaleStatuses: (state, action: PayloadAction<SaleStatus[]>) => {
      state.saleStatuses = action.payload
    }
  }
})

export const { setSaleStatusesTotalCount, setSaleStatuses } = saleStatusesSlice.actions

export const selectSaleStatusesTotalCount = (state: RootState) => state.saleStatuses.totalCount
export const selectSaleStatuses = (state: RootState) => state.saleStatuses.saleStatuses

export default saleStatusesSlice.reducer

interface SaleStatus {
  color: string
  description: string
  crmSaleStatus: string
  id: string
  name: string
  updatedAt: string
  createdAt: string // Date
}

interface GetSaleStatusesReturn {
  totalCount?: number
  saleStatuses?: SaleStatus[]
}

interface SaleStatusCRUD {
  id: string
  name: string
  color: string
}

export const saleStatusesApi = createApi({
  reducerPath: 'saleStatusesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/sale-status`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['SaleStatuses'],
  endpoints: (builder) => ({
    getSaleStatuses: builder.query<GetSaleStatusesReturn, any>({
      query: () => ({
        url: '/search',
        method: 'POST'
      }),
      providesTags: [{ type: 'SaleStatuses', id: 'SaleStatusesLIST' }]
    }),
    createSaleStatus: builder.mutation<GetSaleStatusesReturn, Omit<SaleStatusCRUD, 'id'>>({
      query: ({ ...body }) => ({
        url: '/',
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'SaleStatuses', id: 'SaleStatusesLIST' }]
    }),
    editSaleStatus: builder.mutation<GetSaleStatusesReturn, SaleStatusCRUD>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: [{ type: 'SaleStatuses', id: 'SaleStatusesLIST' }]
    }),
    deleteSaleStatus: builder.mutation<GetSaleStatusesReturn, Pick<SaleStatusCRUD, 'id'>>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'SaleStatuses', id: 'SaleStatusesLIST' }]
    })
  })
})

export const { useGetSaleStatusesQuery, useCreateSaleStatusMutation, useEditSaleStatusMutation, useDeleteSaleStatusMutation } = saleStatusesApi
