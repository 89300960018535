import React from 'react'
import useTheme from '@mui/material/styles/useTheme'
import Autocomplete from '@/designSystem/Autocomplete'
import type { Option } from '@/designSystem/Autocomplete/types'

export interface AutocompleteProps {
  options: Array<Option<string>>
  onChange: (value: Option<string> | null) => void
  placeholder: string
  sx?: Record<string, unknown>
  defaultValue?: Option<string>
  onClose?: () => void
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  inputValue?: string
}

export default function AccountsAutocomplete ({
  options,
  onChange,
  placeholder,
  defaultValue,
  sx = {},
  ...rest
}: AutocompleteProps) {
  const theme = useTheme()
  return (
    <Autocomplete
      sx={{
        width: '218px',
        ...sx
      }}
      textFieldProps={{
        root: {
          placeholder,
          sx: {
            textField: {
              // padding: theme.spacing(2),
              root: {
                padding: '6px 0 9px 7px !important'
              }
            }
          }
        },
        input: {
          sx: {
            '& .MuiAutocomplete-input': {
              padding: '0 0 2px 7px !important'
            },
            '& .MuiInputBase-input::placeholder': {
              color: theme.palette.text1.main,
              fontSize: '18px',
              fontWeight: 500,
              opacity: 1
            }
          }
        }
      }}
      defaultValue={defaultValue}
      options={options}
      onChange={onChange}
      {...rest}
    />
  )
}
