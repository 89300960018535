import React, { useState } from 'react'

import IntegrationSettingsTabContent from '@/components/Advertisers/IntegrationSettingsPage/IntegrationSettingsTabContent'

import PageContentLayout from '@/designSystem/PageContentLayout'
import SupportPopupContent from '@/designSystem/Pages/Advertisers/IntegrationSettings/SupportPopupContent'
import Tabs from '@/designSystem/Tabs'
import ReloadButton from '@/designSystem/ReloadButton'

import { Box, Typography } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import FlexBox from '@/designSystem/FlexBox'
import AdvertiserLogs, { type IAdvertiserLogs } from '@/components/Advertisers/IntegrationSettingsPage/AdvertiserLogs'
import AdvertisersLogsPagination from '@/components/Advertisers/IntegrationSettingsPage/AdvertisersLogsPagination'
import PushSettingsTab
  from '@/components/Advertisers/IntegrationSettingsPage/PushSettingsTab'
import PullSettingsTab from '@/components/Advertisers/IntegrationSettingsPage/PullSettingsTab'
import BasicInfoTab from '@/components/Advertisers/IntegrationSettingsPage/BasicInfoTab'

interface IntegrationSettingsPageProps {
  advertiserName: string
  onClickReload: () => void
  advertiserId?: string
}

export default function IntegrationSettingsPage ({
  advertiserName,
  onClickReload,
  advertiserId
}: IntegrationSettingsPageProps) {
  const [chosenTab, setChosenTab] = useState(1)
  const { spacing } = useTheme()

  const [advertiserLogs, setAdvertiserLogs] = useState<IAdvertiserLogs[]>([])

  return (
    <PageContentLayout
      title={<PageTitle name={advertiserName} />}
      support={{
        content: <SupportPopupContent />,
        subtitle: null // <Typography fontWeight={500} variant='body1'>Advertiser Page</Typography>
      }}
      leftActionButtons={<Box marginLeft={spacing(-5)}><ReloadButton onClick={onClickReload} /></Box>}
    >
      <FlexBox column gap={10}>
        <Box>
          <Tabs
            chosenTab={chosenTab}
            setChosenTab={setChosenTab}
            tabs={['Basic Info', 'Push Leads', 'Pull Leads',
            //  'Integration Settings',
              'Logs']}
          />
        </Box>
        {chosenTab === 0 && <BasicInfoTab/>}
         {chosenTab === 1 && <PushSettingsTab />}
         {chosenTab === 2 && <PullSettingsTab/>}

          {/* {chosenTab === 1 && <IntegrationSettingsTabContent />} */}
        {chosenTab === 3 && <AdvertisersLogsPagination advertiserId={advertiserId ?? ''}
          setLogs={setAdvertiserLogs} />}
        {chosenTab === 5 && <AdvertiserLogs advertiserLogs={advertiserLogs ?? []} />}
      </FlexBox>
    </PageContentLayout>
  )
}

function PageTitle ({ name }: { name: string }) {
  const { palette, spacing } = useTheme()
  return (
    <Box
      display="flex"
      gap={spacing(4)}
      alignItems="center"
      marginRight={spacing(2)}
    >
      <Typography
        color={palette.gray.main}
        lineHeight="31px"
        variant='h0'
        textTransform="capitalize"
      >
        Advertiser:
      </Typography>
      <Typography
        color={palette.text1.main}
        variant='h2'
      >
        {name}
      </Typography>
    </Box>
  )
}
