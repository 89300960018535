import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { Box, Typography } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'
import ActionButtons from '@/designSystem/ActionButtons'
import SettingsTextField from './SettingsTextField'
import {
  setPullLeadStatusesSettings,
  setPullLeadStateSettings,
  useEditAdvertiserMutation, selectPullLeadState
} from '@/store/advertisers'
import { EApiSettingsFields, type IApiSettings } from './EditPushSettingsForm'
import { useDispatch, useSelector } from 'react-redux'
import AccountsAutocomplete from '@/pages/Accounts/AccountsAutocomplete'
import IOSSwitch from '@/designSystem/IOSSwitch'
import { checkRequestTimeout } from '@/utils/advertisers'

interface EditPullSettingsModalProps {
  advertiserId?: string
  handleClose: () => void
  settings?: IApiSettings[]
  setSettings?: (settings: IApiSettings[]) => void
  settingsType: 'pullLeadStatusesSettings' | 'pullLeadStateSettings'
}

const regexValidUrl = /^(http|https):\/\/[^ "]+$/

const emptyError = {
  url: '',
  status: '',
  leadId: '',
  state: '',
  stateFtd: '',
  pageNumber: ''
}

export default function EditPullSettingsFormModal ({
  advertiserId,
  handleClose,
  settings,
  setSettings,
  settingsType
}: EditPullSettingsModalProps) {
  const dispatch = useDispatch()
  const [editAdvertiser] = useEditAdvertiserMutation()
  const [error, setError] = React.useState(emptyError)
  const [remoteError, setRemoteError] = React.useState('')
  const pullLeadState = useSelector(selectPullLeadState)
  // const usePullLeadStateSettings = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.USE_PULL_LEAD_STATUS_SETTINGS)?.value ?? 'false'
  const url = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.URL)?.value ?? ''
  const statusField = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.STATUS_FIELD)?.value
  const leadIdField = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.LEAD_ID_FIELD)?.value
  const leadListField = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.LEAD_LIST_FIELD)?.value
  const pageField = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.PAGE_FIELD)?.value
  const startingPageNumber = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.STARTING_PAGE_NUMBER)?.value
  const requestTimeout = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.REQUEST_TIMEOUT)?.value
  const requestType = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.REQUEST_TYPE)?.value
  const stateField = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.STATE_FIELD)?.value
  const stateFieldFtd = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.STATE_FIELD_FTD_VALUE)?.value

  const startingPageNumberNotZero = startingPageNumber && parseInt(startingPageNumber) >= 0

  // const [usePullLeadState, setUsePullLeadState] = React.useState(usePullLeadStateSettings)
  const [settingsUrl, setSettingsUrl] = React.useState(url)

  useEffect(() => {
    if (url && statusField &&
      leadIdField && startingPageNumberNotZero &&
      stateField && stateFieldFtd) {
      setError(emptyError)
      setSettingsUrl(url)
    }
  }, [url, statusField, leadIdField,
    startingPageNumberNotZero, stateFieldFtd, stateFieldFtd])
  // useEffect(() => {
  //   settingsUrlValueState && setSettingsUrl(settingsUrlValueState)
  // }, [settingsUrlValueState])
  const onSubmit = async () => {
    const newSettings = settings && [...settings]
    const validUrl = regexValidUrl.test(settingsUrl)
    const error = checkRequestTimeout(requestTimeout)
    if (error) {
      return toast.error(error)
    }
    let requiredFieldsMissing = (!url || !statusField ||
      !leadIdField || !validUrl || !startingPageNumberNotZero ||
      !stateField || !stateFieldFtd)
    if (settingsType === 'pullLeadStatusesSettings' && pullLeadState) {
      requiredFieldsMissing = (!url || !statusField ||
          !leadIdField || !validUrl || !startingPageNumberNotZero)
    }
    if (settingsType === 'pullLeadStateSettings') {
      requiredFieldsMissing = (!url ||
        !leadIdField || !validUrl || !startingPageNumberNotZero ||
        !stateField || !stateFieldFtd)
    }
    if (requiredFieldsMissing) {
      !remoteError && setError({
        url: !url || !validUrl ? 'Not a valid URL' : '',
        status: !statusField ? 'Status field is required' : '',
        leadId: !leadIdField ? 'Lead Id field is required' : '',
        state: !stateField ? 'State field is required' : '',
        stateFtd: !stateFieldFtd ? 'State FTD field is required' : '',
        pageNumber: !startingPageNumberNotZero ? 'Value must be not less zero' : ''
      })
    } else {
      try {
        const dataAdvertiser = await editAdvertiser({
          id: advertiserId ?? '',
          [settingsType]: newSettings,
          pullLeadState: settingsType === 'pullLeadStatusesSettings' ? pullLeadState : undefined
        }).unwrap()
        if (dataAdvertiser?.[settingsType]) {
          const newSettings = dataAdvertiser?.[settingsType] as IApiSettings[]
          setSettings && setSettings(newSettings)
          if (settingsType === 'pullLeadStatusesSettings') {
            dispatch(setPullLeadStatusesSettings(newSettings))
          } else {
            dispatch(setPullLeadStateSettings(newSettings))
          }
          handleClose()
        }
        setRemoteError('')
        handleClose()
      } catch (e: any) {
        setRemoteError(e?.data?.message)
        return false
      }
    }
  }

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'url') {
        return {
          ...setting,
          value: e.target.value
        }
      }
      return setting
    }))
    setSettingsUrl(e.target.value)
  }

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'status_field') {
        return {
          ...setting,
          value: e.target.value
        }
      }
      return setting
    }))
  }

  const handleLeadIdField = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'lead_id_field') {
        return {
          ...setting,
          value: e.target.value
        }
      }
      return setting
    }))
  }

  const handleLeadListChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'lead_list_field') {
        return {
          ...setting,
          value: e.target.value
        }
      }
      return setting
    }))
  }

  const handlePageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'page_field') {
        return {
          ...setting,
          value: e.target.value
        }
      }
      return setting
    }))
  }

  const handleRequestTypeChange = (e: { value: string, title: string } | null) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'request_type' && e) {
        return {
          ...setting,
          value: e?.value
        }
      }
      return setting
    }))
  }

  const handleStartingPageNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'starting_page_number') {
        return {
          ...setting,
          value: e.target.value
        }
      }
      return setting
    }))
  }
  const handleRequestTimeout = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === EApiSettingsFields.REQUEST_TIMEOUT) {
        return {
          ...setting,
          value: e.target.value
        }
      }
      return setting
    }))
  }

  const handleStateField = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'state_field') {
        return {
          ...setting,
          value: e.target.value
        }
      }
      return setting
    }))
  }

  const handleStateFtdField = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'state_field_ftd_value') {
        return {
          ...setting,
          value: e.target.value
        }
      }
      return setting
    }))
  }
  // const handleUsePullLeadStateSettings = (e: any) => {
  //   const checked = e?.target?.checked ? 'true' : 'false'
  //   settings && setSettings?.(settings?.map((setting: IApiSettings) => {
  //     if (setting?.key === 'use_pull_lead_state_settings') {
  //       return {
  //         ...setting,
  //         value: e?.target?.checked ? 'true' : 'false'
  //       }
  //     }
  //     if (checked === 'true' && (setting?.key === 'state_field' || setting?.key === 'state_field_ftd_value')) {
  //       return {
  //         ...setting,
  //         value: ''
  //       }
  //     }
  //     return setting
  //   }))
  //   setUsePullLeadState(checked)
  // }

  return (
    <>
      <FlexBox column gap={3}>
        <Box>
          <SettingsTextField title={'Url'} value={settingsUrl} onChange={handleUrlChange} />
          <Typography variant='body2' color='error'>
            {error.url}
          </Typography>
        </Box>
        {settingsType === 'pullLeadStatusesSettings' &&
         <Box>
           <SettingsTextField title={'Status Field'} value={statusField} onChange={handleStatusChange}/>
           <Typography variant='body2' color='error'>
             {error.status}
           </Typography>
         </Box>
        }
        <Box>
          <SettingsTextField title={'Lead Id Field'} value={leadIdField} onChange={handleLeadIdField} />
          <Typography variant='body2' color='error'>
            {error.leadId}
          </Typography>
        </Box>
        { !(settingsType === 'pullLeadStatusesSettings' && pullLeadState) &&
        <Box>
          <SettingsTextField title={'State Field'} value={stateField} onChange={handleStateField} />
          <Typography variant='body2' color='error'>
            {error.state}
          </Typography>
        </Box>
        }
        {!(settingsType === 'pullLeadStatusesSettings' && pullLeadState) &&
         <Box>
           <SettingsTextField title={'State Field FTD Value'} value={stateFieldFtd}
                              onChange={handleStateFtdField}/>
           <Typography variant='body2' color='error'>
             {error.stateFtd}
           </Typography>
         </Box>
        }
        <Box>
          <SettingsTextField title={'Starting page number (leave blank for page 1)'} defaultValue={'0'}
            value={startingPageNumber} onChange={handleStartingPageNumber} />
          <Typography variant='body2' color='error'>
            {error.pageNumber}
          </Typography>
        </Box>

          <SettingsTextField title={'Request timeout in ms (leave blank to use default)'} defaultValue={''}
                             value={requestTimeout} onChange={handleRequestTimeout} />

        <Box>
          <SettingsTextField title={'Lead List Field'} value={leadListField} onChange={handleLeadListChange} />
        </Box>
        <Box>
          <SettingsTextField title={'Page Field'} value={pageField} onChange={handlePageChange} />
        </Box>
        <Box>
          <Typography
            color={'#393E51'}
            fontSize={18}
            fontWeight={400}
            marginBottom={'4.5px'}
          >Request type (POST is used by default)
          </Typography>
          <AccountsAutocomplete
            defaultValue={requestType
              ? { value: requestType, title: requestType.toUpperCase() }
              : { value: 'get', title: 'GET' }}
            options={[{ value: 'get', title: 'GET' }, { value: 'post', title: 'POST' }]}
            onChange={(e: { value: string, title: string } | null) => { handleRequestTypeChange(e) }}
            placeholder="Change Request Type"
            sx={{ width: '100%' }}
          />
        </Box>

        {remoteError && <Typography variant='body1' color='error'>
          {remoteError}
        </Typography>}
        <ActionButtons submitType='submit' submitText='Submit' onCancel={handleClose}
          onSubmit={onSubmit} sx={{ marginBottom: '15px', marginTop: '10px' }} />
      </FlexBox>
    </>
  )
}
