/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useState } from 'react'

import { Box, Card, IconButton, Typography, useTheme } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'

import { format } from 'date-fns'

import ClipboardIcon from '@/designSystem/Icons/Clipboard'
import ExpandIcon from '@/designSystem/Icons/Expand'
import PointIcon from './Icons/Point'
import ClockIcon from './Icons/Clock'
import CalendarIcon from './Icons/Calendar'
import { AdvertiserTypeColorsLogs, type IAdvertiserLogs } from './AdvertiserLogs'
import JsonView from '@uiw/react-json-view'

export interface LogsProps {
  logs: IAdvertiserLogs[]
}

export default function AdvertiserLogsLayout ({
  logs
}: LogsProps) {
  const [areCollapsedIds, setAreCollapsedIds] = useState<boolean[]>(() => new Array(logs?.length).fill(true))
  const [areCollapsedData] = useState<boolean[]>(() => new Array(logs?.length).fill(true))
  const { palette, spacing } = useTheme()

  const onCopy = (text: string) => text && navigator?.clipboard?.writeText(text)
  const onExpand = (index: number) => setAreCollapsedIds((areCollapsed) => {
    const collapsed = [...areCollapsed]
    collapsed[index] = !collapsed[index]
    return collapsed
  })

  return (
    <FlexBox
      position='relative'
      column
      borderLeft={`3px dashed ${palette.text2.main}`}
      margin={spacing(3)}
      padding={spacing(0, 3)}
      gap={6}
    >
      {logs.map(({ id, createdAt, updatedAt, logData }: IAdvertiserLogs, index) => (
        <Box key={id}>
          <Box
            position="absolute"
            left={spacing(-4)}
          >
            <PointIcon />
          </Box>
          <Card
            variant="outlined"
            sx={{
              padding: spacing(4, 6),
              borderRadius: '16px',
              minWidth: '750px',
              maxWidth: '1350px',
              width: 'max-content',
              marginLeft: spacing(6),
              backgroundColor: AdvertiserTypeColorsLogs['FSM state switched']
            }}
          >
            <FlexBox column gap={4}>
              <FlexBox gap={8} alignItems={'center'}>
                <Box
                  display="inline"
                  color={palette.text1.main}
                  fontWeight={500}
                  fontSize={'18px'}
                >
                  Created At
                </Box>
                <FlexBox gap={2}>
                  <CalendarIcon />
                  <Typography variant='body2' color={palette.text2.main}>
                    {createdAt && format(createdAt, 'qq/MM/yyyy')}
                  </Typography>
                </FlexBox>
                <FlexBox gap={2}>
                  <ClockIcon />
                  <Typography variant='body2' color={palette.text2.main}>
                    {createdAt && format(createdAt, 'kk:mm:ss')}
                  </Typography>
                </FlexBox>
              </FlexBox>
              <FlexBox gap={8}>
                <Box
                  display="inline"
                  color={palette.text1.main}
                  fontWeight={500}
                  fontSize={'18px'}
                >
                  Updated At
                </Box>
                <FlexBox gap={2}>
                  <CalendarIcon />
                  <Typography variant='body1' color={palette.text2.main}>
                    {updatedAt && format(updatedAt, 'qq/MM/yyyy')}
                  </Typography>
                </FlexBox>
                <FlexBox gap={2}>
                  <ClockIcon />
                  <Typography variant='body2' color={palette.text2.main}>
                    {updatedAt && format(updatedAt, 'kk:mm:ss')}
                  </Typography>
                </FlexBox>
              </FlexBox>
              <FlexBox gap={2} alignCenter>
                <Row title="ID" text={id} isLimited={areCollapsedIds[index]} />
                <FlexBox gap={1} alignCenter>
                  <IconButton onClick={() => onExpand(index)}>
                    <ExpandIcon />
                  </IconButton>
                  <IconButton onClick={() => onCopy(id)}>
                    <ClipboardIcon opacity="0.7" />
                  </IconButton>
                </FlexBox>
              </FlexBox>
               {logData && <FlexBox gap={2} alignCenter>
                <Row title="Data" isLimited={false}
                  data={<JsonView value={logData} displayDataTypes={false} displayObjectSize={false}
                    onCopied={(text, value) => onCopy(text)} shortenTextAfterLength={100} enableClipboard={false} />}
                />
                <IconButton onClick={() => onCopy(JSON.stringify(logData))}>
                  <ClipboardIcon opacity="0.7" />
                </IconButton>
              </FlexBox>}
            </FlexBox>
          </Card>
        </Box>
      ))}
    </FlexBox>
  )
}

interface RowProps {
  title: string
  text?: string
  isLimited?: boolean
  data?: any
}
function Row ({ text, title, isLimited, data }: RowProps) {
  const { palette } = useTheme()

  return (
    <Typography
      color={palette.text2.main}
      sx={isLimited
        ? {
            maxWidth: '80px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }
        : undefined}
    >
      <Box
        display="inline"
        color={palette.text1.main}
        fontWeight={500}
      >
        {`${title}: `}
      </Box>
      {text || data}
    </Typography>
  )
}
