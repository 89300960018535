import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import Button from '@/designSystem/Button'
import { useNavigate } from 'react-router-dom'

interface ChartLayoutProps {
  children: React.ReactNode
  title?: React.ReactNode
  rightActionButtons?: React.ReactNode
  leftActionButtons?: React.ReactNode
  width?: string
  miniReload?: boolean
}

export default function ChartLayout ({
  children,
  title,
  rightActionButtons,
  leftActionButtons,
  width = '100%',
  miniReload = false
}: ChartLayoutProps) {
  const theme = useTheme()
  // const navigate = useNavigate()
  // const onClickReload = () => navigate(0)
  const [updateChart, setUpdateChart] = useState(false)

  const onClickReload = () => {
    setUpdateChart(prevState => !prevState)
  }

  return (
    <>
      <Box
        width={'100%'}
        alignItems='center'
      >
        <Box width={'100%'} display="flex" flexDirection="column" gap={'24px'}>
          <Box display="flex" justifyContent="space-between" alignItems="center" >
            <Box display="flex" alignItems="center" gap={theme.spacing(2)}>
              <Box
                display="flex"
                alignItems="center"
                gap={theme.spacing(2)}
                marginRight={theme.spacing(6)}
              >
                <Typography variant='h2' color={theme.palette.text1.main} fontSize={'24px'} fontWeight={500} lineHeight={'31px'} whiteSpace={'nowrap'}>
                  {title}
                </Typography>
              </Box>
              {leftActionButtons}
            </Box>
            {rightActionButtons}
          </Box>
          <Box width={'100%'} >
            {children}
          </Box>
        </Box>
      </Box>
    </>
  )
}
