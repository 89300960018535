import React, { useState } from 'react'

import IconButton from '@mui/material/IconButton'
import LockIcon from '@mui/icons-material/Lock'
import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import Button from '@/designSystem/Button'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'

import type { Tab } from '@/designSystem/NavigationPanel/types'
import type { Option } from '@/designSystem/Autocomplete/types'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectAccessRights } from '@/store/auth'

interface HiddenPanelProps {
  tabs: Tab[]
  onRoute: (tab: Option<string>) => void
  unHide: () => void
}

export default function HiddenPanel ({ tabs, onRoute, unHide }: HiddenPanelProps) {
  const theme = useTheme()
  const accessRights = useSelector(selectAccessRights)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedTab, setSelectedTab] = useState<null | string>(null)

  const open = Boolean(anchorEl)

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    value: string,
    title: string
  ) => {
    onRoute({ value, title })
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onClickIconButton = (event: React.MouseEvent<HTMLElement>, { ref, text: title }: Tab) => {
    if (ref) {
      onRoute({ value: ref, title })
    } else {
      setSelectedTab(title)
      setAnchorEl(event.currentTarget)
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="71px"
      alignItems="center"
      marginTop={theme.spacing(8)}
      gap={theme.spacing(4)}
    >
      {tabs.map((tab) => (
        <React.Fragment key={tab.text}>
          <IconButton
            sx={{
              padding: '8px 0',
              width: '40px',
              pointerEvents: tab.noAccessTab ? 'none' : undefined
            }}
            onClick={(event) => onClickIconButton(event, tab)}
          >
            {tab?.noAccessTab ? <LockIcon /> : tab?.icon}
          </IconButton>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={tab.text === selectedTab && open && !tab.noAccessTab}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox'
            }}
            sx={{
              top: '-23px',
              left: '41px',
              '& .MuiPaper-root': {
                borderRadius: '16px',
                padding: theme.spacing(1)
              }
            }}
          >
            {tab.nestedTabs?.map(({ text, ref, access, noAccessTab, isHidden }) => {
              const isTabAllowed = accessRights?.includes(access)
              return (isTabAllowed && !noAccessTab && !isHidden
                ? <MenuItem
                key={ref}
                selected={text === selectedTab}
                onClick={(event) => handleMenuItemClick(event, ref ?? '#', text)}
              >
                <Typography variant="subtitle2">
                  {text}
                </Typography>
              </MenuItem>
                : <div key={ref} />)
            })}
          </Menu>
        </React.Fragment>
      ))}
      <Button
        sx={{
          minWidth: '44px',
          minHeight: '44px',
          width: '44px',
          height: '44px'
        }}
        icon={<KeyboardArrowRightRoundedIcon />}
        variant='contained'
        onClick={unHide}
      />
    </Box>
  )
}
