import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box, styled, useTheme } from '@mui/material'

interface ITable {
  headers: string[]
  rows: any
  sx?: Record<string, unknown>
}

export default function BasicTable ({ headers, rows, ...sx }: ITable) {
  const theme = useTheme()
  return (
        <TableContainer component={Paper} sx={{
          borderRadius: '16px',
          boxShadow: 'none',
          padding: '16px 24px',
          '::-webkit-scrollbar': {
            height: '8px'
          },
          '::-webkit-scrollbar-track': {
            background: '#E0E2F0'
          },
          '::-webkit-scrollbar-thumb': {
            background: '#4141D5',
            borderRadius: 16
          }
        }}>
            <CustomTable sx={{ ...sx }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {headers?.map((header, index) => (
                            <TableCell align="left" key={index}>
                                <Box
                                    sx={{
                                      fontSize: theme.typography.body1.fontSize,
                                      fontWeight: 'bold',
                                      lineHeight: theme.typography.body1.lineHeight,
                                      letterSpacing: '0.1em',
                                      color: theme.palette.text1.main
                                    }}
                                >
                                    {header}
                                </Box>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rows?.length > 0) && rows?.map((row: any, index: any) => (
                        <TableRow
                            key={index}
                        >
                            {Object.values(row).map((value: any, index) => (
                                <TableCell align="left" key={index}>{value}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </CustomTable>
        </TableContainer>
  )
}

const CustomTable = styled(Table)(`
.MuiTableCell-root {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: none;
    color: #393E51;
    border-bottom: none;
}
`)
