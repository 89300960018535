import React, { useEffect } from 'react'

import {
  setAdvertiserSettings,
  useGetAdvertisersQuery
} from '@/store/advertisers'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PushSettingsTabContent
  from '@/designSystem/Pages/Advertisers/IntegrationSettings/PushSettingsTabContent/PushSettingsTabContent'

export default function PushSettingsTab () {
  const { advertiserId } = useParams()
  const dispatch = useDispatch()

  const [advertiser] = useGetAdvertisersQuery({
    limit: 1,
    filters:
      { id: advertiserId ? [advertiserId] : undefined }
  })?.data?.advertisers ?? []

  const getAdvertiser = () => {
    if (!advertiserId) return
    return advertiser
  }

  useEffect(() => {
    const advertiser = getAdvertiser()
    if (!advertiser) return
    dispatch(setAdvertiserSettings(advertiser?.advertiserSettings ?? []))
  }, [advertiserId, dispatch, getAdvertiser])

  return (
    <PushSettingsTabContent
      advertiserId={advertiserId ?? ''}
    />
  )
}
