import React from 'react'

import { useTheme, Typography, Box, IconButton, type SxProps } from '@mui/material'
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'

const iconSx: SxProps = {
  width: '20px',
  height: '20px'
}

export default function InjectTasksPagination ({ count }: { count: number }) {
  const { spacing, palette } = useTheme()

  const page = 1
  const totalPages = 1
  const range = `${count} Elements / ${count} Total`

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="row"
      gap={spacing(12)}
    >
      <Typography
        variant='headline'
        color={palette.text1.main}
      >
        {range}
      </Typography>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="row"
        gap={spacing(3)}
      >
        <Typography variant='headline'>
          Page:
        </Typography>
        <Box
          padding={spacing(1.5, 3)}
          border={`1px solid ${palette.primary.main}`}
          borderRadius="12px"
          display="flex"
          alignItems="center"
          gap={spacing(3)}
          sx={{ backgroundColor: palette.card.main }}
        >
          <IconButton
            sx={iconSx}
            disabled={page === 1}
          >
            <KeyboardArrowLeftRoundedIcon />
          </IconButton>
          <Typography variant='headline'>
            {page}
          </Typography>
          <IconButton
            sx={iconSx}
            disabled={page === totalPages}
          >
            <KeyboardArrowRightRoundedIcon />
          </IconButton>
        </Box>
        <Typography variant='headline'>
          of {totalPages}
        </Typography>
      </Box>
    </Box>
  )
}
