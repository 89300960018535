import React, { useEffect, useState } from 'react'
import { TextField as TextFieldMUI } from '@mui/material'
import { autocompleteStyle } from './JsxStyles'
import { formatTime } from './helpers'

const HoursPicker = ({ value, onChange }: { value: string | null, onChange: (value: string) => void }) => {
  const val = value?.replaceAll('\'', '').split(':')
  const [hours, setHours] = useState<number | null>(val ? +val[0] : null)
  const [minutes, setMinutes] = useState<number | null>(val ? +val[1] : null)

  const [sendHours, setSendHours] = useState<number | null>(val ? +val[0] : null)
  const [sendMinutes, setSendMinutes] = useState<number | null>(val ? +val[1] : null)

  useEffect(() => {
    const h = val ? +val[0] : null
    const m = val ? +val[1] : null
    const isValidHours = typeof sendHours !== 'undefined' && sendHours !== null
    const isValidMinutes = typeof sendMinutes !== 'undefined' && sendMinutes !== null
    if (isValidHours && isValidMinutes && (sendMinutes !== m || sendHours !== h)) {
      onChange(`'${formatTime(sendHours)}:${formatTime(sendMinutes)}'`)
    }
  }, [sendHours, sendMinutes])
  return <>
        <TextFieldMUI
          sx={{ ...autocompleteStyle, flex: '1 1 100%', width: 'fill-available', boxSizing: 'border-box !important', '>div': { borderRadius: '16px' }, input: { padding: '8px 12px !important', borderRadius: '12px' } }}
          onChange={(e) => setHours(+e.target.value)}
          onBlur={(e) => setSendHours(+e.target.value)}
          value={hours}
          type='number'
          InputProps={{
            inputProps: { min: 0, max: 23 }
          }}
          placeholder={'Hours'}
        />
      :
        <TextFieldMUI
          sx={{ ...autocompleteStyle, flex: '1 1 100%', width: 'fill-available', boxSizing: 'border-box !important', '>div': { borderRadius: '16px' }, input: { padding: '8px 12px !important', borderRadius: '12px' } }}
          onChange={(e) => setMinutes(+e.target.value)}
          onBlur={(e) => setSendMinutes(+e.target.value)}
          value={minutes}
          InputProps={{
            inputProps: { min: 0, max: 59 },
            type: 'number'
          }}
          placeholder={'Minutes'}
        />
      </>
}

export default HoursPicker
