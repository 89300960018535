import React, { type CSSProperties } from 'react'
import FlexBox from '@/designSystem/FlexBox'
import { useGetAdvertiserQuery } from '@/store/advertisers'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  id: string
  removeItem: () => void
  sx?: CSSProperties
}

const AdvertiserSelected = ({ id, removeItem, sx }: Props) => {
  const { data } = useGetAdvertiserQuery(id)
  return <FlexBox sx={sx}>{data?.name} <CloseIcon onClick={removeItem} /></FlexBox>
}

export default AdvertiserSelected
