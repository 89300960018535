import { Button, Box } from '@mui/material'
import type { Option } from '@/designSystem/Autocomplete/types'
import React, { useEffect } from 'react'
import Autocomplete from '@/designSystem/Pages/Advertisers/Autocomplete'
import WitgetItem from './WitgetItem'
import {
  useGetTermsQuery, useDeleteTermsMutation,
  setTermsState,
  type ITerm
} from '@/store/terms'
import { nanoid } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { selectTermsQueryParams } from '@/store/queryParams'
// import { useSearchWidgetLabelMutation } from '@/store/dashboard'
export default function Witgets () {
  const queryParams = useSelector(selectTermsQueryParams)
  const termsData = useGetTermsQuery(queryParams)?.data?.terms
  console.log('🚀 ~ Witgets ~ termsData:', termsData)

  return (
    <Box
      display="flex"
      gap={'24px'}
      width={'100%'}
      alignItems="center"
      flexDirection={'row'}
      flexWrap={'nowrap'}
      justifyContent={'start'}
      // overflowX={'auto'}
      overflow={'auto'}
      paddingY={'24px'}
    >
      {termsData?.map((term) => (
        <WitgetItem
          key={nanoid()}
          term={term}
          label={term.label}
          icon={term.icon}
          description={term.description}
        />
      ))}
    </Box>
  )
}
