import React from 'react'
import { Box } from '@mui/material'
import RejectPresetsGroups from './RejectPresetsGroups'
import MetricConditions from '../MetricConditions'
import Form from '@/components/Form'
import { useDispatch } from 'react-redux'
import { type GetDrillReportsArgs, useCreateDrillReportsMutation, setDrillReportsState } from '@/store/drillReports'
import { type SubmitHandler, useForm } from 'react-hook-form'
import MetricReport from '../MetricReportLayout/MetricReport'

interface IRejectLayout {
  data: {
    presetsGroups: string[]
    conditionFieldsOptions: Record<string, any>
  }
}

const RejectLayout = ({ data }: IRejectLayout) => {
  const dispatch = useDispatch()
  const [createDrillReport] = useCreateDrillReportsMutation()

  const form = useForm<GetDrillReportsArgs>({
    defaultValues: {
      groups: [],
      pql: '',
      termsIds: [],
      createdAt: {
        from: '',
        to: ''
      },
      dateAt: { value: '', title: '' },
      isEmptyPql: false
    }
  })

  const onSubmit: SubmitHandler<GetDrillReportsArgs> = async ({ groups, pql, termsIds, createdAt, dateAt }) => {
    const datePeriodCreatedAt = dateAt?.value ? { from: dateAt?.value, to: new Date().toISOString() } : createdAt
    const report = await createDrillReport({ groups, pql, termsIds, createdAt: datePeriodCreatedAt })
    report && dispatch(setDrillReportsState(report))
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'40px'}
        justifyContent={'start'}
      >
        <RejectPresetsGroups data={data} form={form} />
        <MetricConditions data={data} form={form} />
        <MetricReport form={form} noCsv/>
      </Box>
    </Form>
  )
}

export default RejectLayout
