import React from 'react'

import {
  DateTimePicker as MuiDateTimePicker,
  type DateTimePickerProps
} from '@mui/x-date-pickers/DateTimePicker'
import { useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectTimezone } from '@/store/settings'

export default function DateTimePicker<T> ({ sx, customTimezone, ...rest }:
DateTimePickerProps<T> & { customTimezone?: string }) {
  const { palette } = useTheme()
  const timezone = useSelector(selectTimezone)

  return (
    <MuiDateTimePicker
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      timezone={customTimezone || timezone}
      format='dd/MM/yyyy HH:mm:ss'
      sx={{
        width: '100%',
        '& .MuiInputBase-root': {
          '& fieldset': {
            border: `1px solid ${palette.primary.main}`,
            borderRadius: '16px'
          },
          '&:hover fieldset': {
            borderColor: palette.primary.main
          },
          '&.Mui-focused fieldset': {
            borderColor: palette.primary.main
          }
        },
        ...sx
      }}
      {...rest}
    />
  )
}
