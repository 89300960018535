import React from 'react'

import FlexBox from '@/designSystem/FlexBox'

import { Typography, useTheme } from '@mui/material'
import Popup from '@/designSystem/Popup'

interface DrillReportsInfoModalProps {
  handleClose: () => void
}
export default function DrillReportsInfoModal ({
  handleClose
}: DrillReportsInfoModalProps) {
  return (
    <Popup
      open
      handleClose={handleClose}
      title="Help Information"
      subTitle="Drilldown Reports"
    >
      <FlexBox gap={3} column>
        <Paragraph body="Drilldown Reports is a quick reports generator with multiple groups and conditions. Unlike the Data Exporter, here you can see statistics numbers rather than leads details." />
        <Paragraph body="Here you can choose necessary groups for the report and set up presets according to your needs." />
        <Paragraph body="WARNING! Test leads (they are marked as “Test Status: yes”) are not included into the report." />
      </FlexBox>
    </Popup>
  )
}

interface ParagraphProps {
  body: string
}
function Paragraph ({ body }: ParagraphProps) {
  const { palette } = useTheme()
  return (
    <FlexBox gap={2} column>
      <Typography variant="body2" color={palette.text2.main}>{body}</Typography>
    </FlexBox>
  )
}
