import { type ConversionsFilters } from '@/store/conversions'

interface FilterItemType {
  title: string
  name: keyof ConversionsFilters
  isMultiple: boolean
}

export type IFilterCategories = Record<string, FilterItemType[]>

const filterCategories: IFilterCategories = {
  Main: [
    {
      title: 'UUIDs',
      name: 'id',
      isMultiple: true
    },
    {
      title: 'Lead UUIDs',
      name: 'leadId',
      isMultiple: true
    },
    {
      title: 'Goal Type',
      name: 'goalType',
      isMultiple: true
    },
    {
      title: 'Country',
      name: 'country',
      isMultiple: true
    },
    {
      title: 'Is test',
      name: 'isTest',
      isMultiple: false
    },
    {
      title: 'IP',
      name: 'ip',
      isMultiple: false
    }
    // {
    //   title: 'Is Autologin Success',
    //   name: 'isAutologinSuccess',
    //   isMultiple: false
    // }
  ],
  Advertiser: [
    {
      title: 'Advertiser',
      name: 'advertiserId',
      isMultiple: true
    }
  ],
  'Aff Sub (All 15)': [
    {
      title: 'Aff Sub',
      name: 'affSub1',
      isMultiple: false
    },
    {
      title: 'Aff Sub2',
      name: 'affSub2',
      isMultiple: false
    },
    {
      title: 'Aff Sub3',
      name: 'affSub3',
      isMultiple: false
    },
    {
      title: 'Aff Sub4',
      name: 'affSub4',
      isMultiple: false
    },
    {
      title: 'Aff Sub5',
      name: 'affSub5',
      isMultiple: false
    },
    {
      title: 'Aff Sub6',
      name: 'affSub6',
      isMultiple: false
    },
    {
      title: 'Aff Sub7',
      name: 'affSub7',
      isMultiple: false
    },
    {
      title: 'Aff Sub2',
      name: 'affSub2',
      isMultiple: false
    },
    {
      title: 'Aff Sub8',
      name: 'affSub8',
      isMultiple: false
    },
    {
      title: 'Aff Sub9',
      name: 'affSub9',
      isMultiple: false
    },
    {
      title: 'Aff Sub10',
      name: 'affSub10',
      isMultiple: false
    },
    {
      title: 'Aff Sub11',
      name: 'affSub11',
      isMultiple: false
    },
    {
      title: 'Aff Sub12',
      name: 'affSub12',
      isMultiple: false
    },
    {
      title: 'Aff Sub13',
      name: 'affSub13',
      isMultiple: false
    },
    {
      title: 'Aff Sub14',
      name: 'affSub14',
      isMultiple: false
    },
    {
      title: 'Aff Sub15',
      name: 'affSub15',
      isMultiple: false
    }
  ],
  Dates: [
    {
      title: 'Created At',
      name: 'createdAt',
      isMultiple: false
    }
  ],
  Profile: [
    {
      title: 'Email',
      name: 'email',
      isMultiple: false
    },
    {
      title: 'First name',
      name: 'firstName',
      isMultiple: false
    },
    {
      title: 'Last name',
      name: 'lastName',
      isMultiple: false
    },
    // {
    //   title: 'Password',
    //   name: 'password',
    //   isMultiple: false
    // },
    {
      title: 'Phone',
      name: 'phone',
      isMultiple: false
    }
  ]
}

const leadFilters = ['ip', ...filterCategories['Aff Sub (All 15)'].map((i) => i.name), ...filterCategories.Profile.map((i) => i.name)]

export { filterCategories, type FilterItemType, leadFilters }
