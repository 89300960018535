import React, { useEffect } from 'react'

import { Outlet } from 'react-router-dom'

import Box from '@mui/material/Box'
import LeftBar from '@/components/LeftBar'
import NavigationPanel from '@/components/NavigationPanel'

import { zoom } from '@/constants'
import { setSaleStatuses, useGetSaleStatusesQuery } from '@/store/saleStatuses'
import { useDispatch } from 'react-redux'

export default function Layout () {
  const dispatch = useDispatch()
  const saleStatuses = useGetSaleStatusesQuery({})?.data?.saleStatuses ?? []

  useEffect(() => {
    if (saleStatuses.length > 0) {
      dispatch(setSaleStatuses(saleStatuses))
    }
  }, [saleStatuses])

  return (
    <Box
      display="flex"
      sx={{
        transform: `scale(${zoom})`,
        transformOrigin: 'left top',
        width: `calc(100% / ${zoom})`,
        height: `calc(100% / ${zoom})`,
        position: 'fixed'
      }}
    >
      <LeftBar />
      <NavigationPanel />
      <Outlet />
    </Box>
  )
}
