import { configureStore, combineReducers, type Middleware, isRejectedWithValue } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer, createTransform } from 'redux-persist'

import advertisersReducer, { advertisersApi } from '@/store/advertisers'
import usersReduser, { usersApi } from './users'
import authReduser, { authApi } from './auth'
import injectionsReduser, { injectionsApi } from './injections'
import autoInjectionsReduser, { autoInjectionsApi } from './autoInjections'
import { rotationControlApi } from './rotationControl'
import { advertiserRevenueApi } from './advertiserRevenue'
import { resetTimeApi } from './counterTimeReset'
import leadsReducer, { leadsApi } from './leads'
import holdReducer, { holdApi } from './hold'
import dealsReducer, { dealsApi } from './deals'
import ConversionsReduser, { conversionsApi } from './conversions'
import logsReduser, { logsApi } from './logs'
import advertisersLogsReduser, { advertisersLogsApi } from './advertisersLogs'
import saleStatusesReducer, { saleStatusesApi } from './saleStatuses'
import saleStatusesMappingReducer, { saleStatusesMappingApi } from './saleStatusesMapping'
import termsReducer, { termsApi } from './terms'
import settingsReducer, { settingsApi } from './settings'
import apiTokensReduser, { apiTokensApi } from './apiTokens'
import presetsReduser, { presetsApi } from './presets'
import dashboardReduser, { dashboardApi } from './dashboard'
import postbacksReduser, { postBacksApi } from './postBacks'
import postbackSentReduser, { postBackSentApi } from './postBacksSent'
import drillReportsReduser, { drillReportsApi } from './drillReports'
import queryParamsReducer from './queryParams'

const unauthenticatedMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action) &&
    (action.payload?.data?.message === 'Please authenticate' || action.payload?.status === 401)) {
    localStorage.removeItem('token')
    localStorage.removeItem('role')
    localStorage.removeItem('accessRights')
    window.location.reload()
  }
  return next(action)
}

const reducers = {
  advertisers: advertisersReducer,
  [advertisersApi.reducerPath]: advertisersApi.reducer,
  users: usersReduser,
  [usersApi.reducerPath]: usersApi.reducer,
  auth: authReduser,
  [authApi.reducerPath]: authApi.reducer,
  injections: injectionsReduser,
  [injectionsApi.reducerPath]: injectionsApi.reducer,
  autoInjections: autoInjectionsReduser,
  [autoInjectionsApi.reducerPath]: autoInjectionsApi.reducer,
  leads: leadsReducer,
  [rotationControlApi.reducerPath]: rotationControlApi.reducer,
  [resetTimeApi.reducerPath]: resetTimeApi.reducer,
  [advertiserRevenueApi.reducerPath]: advertiserRevenueApi.reducer,
  hold: holdReducer,
  [holdApi.reducerPath]: holdApi.reducer,
  [dealsApi.reducerPath]: dealsApi.reducer,
  deals: dealsReducer,
  [conversionsApi.reducerPath]: conversionsApi.reducer,
  conversions: ConversionsReduser,
  [leadsApi.reducerPath]: leadsApi.reducer,
  logs: logsReduser,
  [logsApi.reducerPath]: logsApi.reducer,
  [saleStatusesApi.reducerPath]: saleStatusesApi.reducer,
  saleStatuses: saleStatusesReducer,
  [saleStatusesMappingApi.reducerPath]: saleStatusesMappingApi.reducer,
  saleStatusesMapping: saleStatusesMappingReducer,
  [termsApi.reducerPath]: termsApi.reducer,
  terms: termsReducer,
  settings: settingsReducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  apiTokens: apiTokensReduser,
  [apiTokensApi.reducerPath]: apiTokensApi.reducer,
  advertisersLogs: advertisersLogsReduser,
  [advertisersLogsApi.reducerPath]: advertisersLogsApi.reducer,
  presets: presetsReduser,
  [presetsApi.reducerPath]: presetsApi.reducer,
  dashboard: dashboardReduser,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  postbacks: postbacksReduser,
  [postBacksApi.reducerPath]: postBacksApi.reducer,
  postBackSent: postbackSentReduser,
  [postBackSentApi.reducerPath]: postBackSentApi.reducer,
  drillReports: drillReportsReduser,
  [drillReportsApi.reducerPath]: drillReportsApi.reducer,
  queryParams: queryParamsReducer
}

const rootReducer = combineReducers<typeof reducers>(reducers)

const saveQueryParamsTransform = createTransform(
  (inboundState, key) => {
    if (key === 'queryParams') {
      return inboundState
    }
    return inboundState
  },
  (outboundState, key) => {
    if (key === 'queryParams') {
      return outboundState
    }
    return outboundState
  },
  {
    whitelist: ['queryParams']
  }
)

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveQueryParamsTransform],
  whitelist: ['queryParams']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(advertisersApi.middleware)
      .concat(usersApi.middleware)
      .concat(authApi.middleware)
      .concat(injectionsApi.middleware)
      .concat(autoInjectionsApi.middleware)
      .concat(rotationControlApi.middleware)
      .concat(resetTimeApi.middleware)
      .concat(advertiserRevenueApi.middleware)
      .concat(dealsApi.middleware)
      .concat(conversionsApi.middleware)
      .concat(leadsApi.middleware)
      .concat(holdApi.middleware)
      .concat(logsApi.middleware)
      .concat(saleStatusesApi.middleware)
      .concat(saleStatusesMappingApi.middleware)
      .concat(unauthenticatedMiddleware)
      .concat(termsApi.middleware)
      .concat(settingsApi.middleware)
      .concat(apiTokensApi.middleware)
      .concat(advertisersLogsApi.middleware)
      .concat(presetsApi.middleware)
      .concat(postBacksApi.middleware)
      .concat(postBackSentApi.middleware)
      .concat(drillReportsApi.middleware)
      .concat(dashboardApi.middleware),

  devTools: process.env.NODE_ENV !== 'production'
})
export default store

export type RootState = ReturnType<typeof store.getState>

setupListeners(store.dispatch)

export const persistor = persistStore(store)
