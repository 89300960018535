import React from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import AddPostbackPopup from './OnlyAddModal'
import { type LinkParameter, useCreatePostbackMutation, selectPostBackData, type PassingRules } from '@/store/postBacks'
import { type Option } from '@/designSystem/Autocomplete/types'
import { useSelector } from 'react-redux'
import { type LeadState } from '@/enums/leads.enum'
interface AddPopupProps {
  handleClose: () => void
  nodeParentId?: string | null
}
export enum ERenderer {
  COUNTRY = 'country',
  FREE_PQL = 'free_pql',
}
export interface Inputs {
  name: string
  description: string
  goalType: Option<LeadState.FTD | LeadState.PUSHED_LEAD>
  country?: {
    title: string
    value: string
  }
  pql?: string
  requestMethod: Option<'GET' | 'POST'>
  postbackLink: string
  linkParameters: LinkParameter[]
  priority?: number
  active: boolean
  renderer: ERenderer
}

export function extractQueryParams (url: string): LinkParameter[] {
  const params = new URLSearchParams(url.split('?')[1])
  console.log('🚀 ~ extractQueryParams ~ params:', params)
  const queryParams: any = []

  const entriesArray = Array.from(params.entries())
  entriesArray.forEach(([key, value]) => {
    queryParams.push({
      key,
      value
    })
  })

  return queryParams
}

export default function AddPopup (
  { handleClose, nodeParentId }: AddPopupProps
) {
  const form = useForm<any>()
  const poskDataState: PassingRules[] = useSelector(selectPostBackData)
  const [createPostback, { error: createError }] = useCreatePostbackMutation()
  const onSubmit: SubmitHandler<Inputs> = async (form) => {
    const {
      name,
      // priority,
      active,
      pql,
      renderer,
      description,
      country,
      postbackLink,
      requestMethod,
      goalType
    } = form
    await createPostback({
      name,
      description,
      priority: poskDataState ? poskDataState.length + 1 : 1,
      active,
      goalType: goalType.value,
      ...renderer === ERenderer.COUNTRY ? { country: [`${country?.value}`] } : {},
      ...renderer === ERenderer.FREE_PQL ? { pql } : {},
      requestMethod: requestMethod.value,
      postbackLink,
      linkParameters: extractQueryParams(postbackLink)
    })
    console.log('🚀 ~ constonSubmit:SubmitHandler<Inputs>= ~ form:', {
      name,
      description,
      priority: poskDataState ? poskDataState.length + 1 : 1,
      active,
      goalType: goalType.value,
      ...renderer === ERenderer.COUNTRY ? { country: [`${country?.value}`] } : {},
      ...renderer === ERenderer.FREE_PQL ? { pql } : {},
      requestMethod: requestMethod.value,
      postbackLink,
      linkParameters: extractQueryParams(postbackLink)
    })

    handleClose()
  }

  return (<AddPostbackPopup
      // nodeParentId={nodeParentId}
      // parentRotation={postbacks}
      form={form}
      onSubmit={onSubmit}
      handleClose={handleClose}
      title='Create Passing Rule'
      subtitle='Choose type & enter data'
    />
  )
}
