import { type Option } from '@/designSystem/Autocomplete/types'
import { type HoldsFilters } from '@/store/hold'
import { LeadState } from '@/enums/leads.enum'

interface FilterItemType {
  title: string
  name: keyof HoldsFilters
  isMultiple: boolean
}

export type IFilterCategories = Record<string, FilterItemType[]>

const filterCategories: IFilterCategories = {
  Main: [
    {
      title: 'UUIDS',
      name: 'id',
      isMultiple: true
    },
    {
      title: 'Country',
      name: 'country',
      isMultiple: true
    },
    // {
    //   title: 'State',
    //   name: 'state',
    //   isMultiple: true
    // },
    {
      title: 'Is test',
      name: 'isTest',
      isMultiple: false
    },
    {
      title: 'IP',
      name: 'ip',
      isMultiple: false
    },
    {
      title: 'Lead Source',
      name: 'leadSource',
      isMultiple: false
    },
    {
      title: 'External Id',
      name: 'externalId',
      isMultiple: true
    }
    // {
    //   title: 'Is Autologin Success',
    //   name: 'isAutologinSuccess',
    //   isMultiple: false
    // }
  ],
  Advertiser: [
    {
      title: 'Advertiser',
      name: 'advertiserId',
      isMultiple: true
    },
    {
      title: 'Sale Status',
      name: 'saleStatus',
      isMultiple: true
    },
    {
      title: 'Advertiser Sale Status',
      name: 'advertiserSaleStatus',
      isMultiple: true
    }
  ],
  'Aff Sub (All 15)': [
    {
      title: 'Aff Sub',
      name: 'affSub1',
      isMultiple: false
    },
    {
      title: 'Aff Sub2',
      name: 'affSub2',
      isMultiple: false
    },
    {
      title: 'Aff Sub3',
      name: 'affSub3',
      isMultiple: false
    },
    {
      title: 'Aff Sub4',
      name: 'affSub4',
      isMultiple: false
    },
    {
      title: 'Aff Sub5',
      name: 'affSub5',
      isMultiple: false
    },
    {
      title: 'Aff Sub6',
      name: 'affSub6',
      isMultiple: false
    },
    {
      title: 'Aff Sub7',
      name: 'affSub7',
      isMultiple: false
    },
    {
      title: 'Aff Sub2',
      name: 'affSub2',
      isMultiple: false
    },
    {
      title: 'Aff Sub8',
      name: 'affSub8',
      isMultiple: false
    },
    {
      title: 'Aff Sub9',
      name: 'affSub9',
      isMultiple: false
    },
    {
      title: 'Aff Sub10',
      name: 'affSub10',
      isMultiple: false
    },
    {
      title: 'Aff Sub11',
      name: 'affSub11',
      isMultiple: false
    },
    {
      title: 'Aff Sub12',
      name: 'affSub12',
      isMultiple: false
    },
    {
      title: 'Aff Sub13',
      name: 'affSub13',
      isMultiple: false
    },
    {
      title: 'Aff Sub14',
      name: 'affSub14',
      isMultiple: false
    },
    {
      title: 'Aff Sub15',
      name: 'affSub15',
      isMultiple: false
    }
  ],
  Dates: [
    {
      title: 'Created At',
      name: 'createdAt',
      isMultiple: false
    },
    {
      title: 'State Updated At',
      name: 'stateUpdatedAt',
      isMultiple: false
    }
  ],
  Profile: [
    {
      title: 'Email',
      name: 'email',
      isMultiple: false
    },
    {
      title: 'First name',
      name: 'firstName',
      isMultiple: false
    },
    {
      title: 'Last name',
      name: 'lastName',
      isMultiple: false
    },
    // {
    //   title: 'Password',
    //   name: 'password',
    //   isMultiple: false
    // },
    {
      title: 'Phone',
      name: 'phone',
      isMultiple: false
    }
  ]
}

const textFieldNames: string[] = ['id', 'ip', 'leadSource', 'externalId', 'conversionId', 'leadId', ...filterCategories['Aff Sub (All 15)'].map((i) => i.name), ...filterCategories.Profile.map((i) => i.name), 'name']

const activeStateOptions: Array<Option<string>> = [
  {
    title: 'Duplicated',
    value: LeadState.DUPLICATED.toString()
  },
  {
    title: 'Pending',
    value: LeadState.PENDING.toString()
  },
  {
    title: 'Pushed Lead',
    value: LeadState.PUSHED_LEAD.toString()
  },
  {
    title: 'FTD',
    value: LeadState.FTD.toString()
  },
  {
    title: 'FTD Late',
    value: LeadState.FTD_LATE.toString()
  }
]

const activeStateConversionOptions: Array<Option<string>> = [
  {
    title: 'Pushed Lead',
    value: LeadState.PUSHED_LEAD.toString()
  },
  {
    title: 'FTD',
    value: LeadState.FTD.toString()
  }
]

const saleStatusOptions: Array<Option<string>> = [
  {
    title: 'Pushed Lead',
    value: LeadState.PUSHED_LEAD.toString()
  },
  {
    title: 'Rejected',
    value: LeadState.REJECTED.toString()
  }
]

const booleanOptions: Array<Option<boolean>> = [
  {
    title: 'Yes',
    value: true
  },
  {
    title: 'No',
    value: false
  }
]

const sx = {
  '>div>div>div': { padding: '0 0 0 9px !important' },
  '& .MuiInputBase-root': {
    height: '40px',
    borderRadius: '8px',
    width: '330px',
    background: 'white'
  },
  '& .MuiOutlinedInput-root:hover': {
    '& > fieldset': {
      outline: 'none'
    }
  },
  '& .Mui-disabled:hover': {
    '& > fieldset': {
      outline: 'none'
    }
  },
  '& .Mui-disabled:active': {
    '& > fieldset': {
      outline: 'none'
    }
  },
  '& fieldset': { outline: 'none' }
}

export { filterCategories, type FilterItemType, textFieldNames, activeStateOptions, activeStateConversionOptions, saleStatusOptions, booleanOptions, sx }
