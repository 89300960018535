import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import { Typography, useTheme } from '@mui/material'
import ApiTokensPagination from './ApiTokensPagination'
import ApiTokensFilterBar from './Filters/ApiTokensBar'
import TableLayout from '@/designSystem/Table'
import elementMap from './ElementMap'
import { EmptyTableApiTokens } from './EmptyTableApiTokens'
import { ModalEventInfo } from '../../../../designSystem/ModalMessagesLogs'
import {
  type IApiTokens, selectApiTokens, selectApiTokensMenu,
  setApiTokens,
  setApiTokensTotalCount, useGetApiTokensQuery,
  useDeleteApiTokensMutation
} from '@/store/apiTokens'
import { AddEditApiTokensModal } from './AddEditApiTokensModal'
import { selectApiTokensQueryParams } from '@/store/queryParams'

const Header = ({ header }: { header: string }) => {
  if (header.startsWith('::')) {
    return ''
  }
  return (
    <Typography
      variant='body1'
      color="text1"
      width="max-content"
      sx={{
        display: 'flex'
      }}
    >
      {header}
    </Typography>
  )
}

export default function ApiTokensTable () {
  const dispatch = useDispatch()
  const { limit = 10, headers, filters, page } = useSelector(selectApiTokensQueryParams)
  const menu = useSelector(selectApiTokensMenu)
  const { data } = useGetApiTokensQuery({ limit, filters: filters ?? undefined, page })
  const [deleteApiToken] = useDeleteApiTokensMutation()
  const apiTokensState: IApiTokens[] = useSelector(selectApiTokens)
  const { spacing } = useTheme()
  const [apiTokens, setApiToken] = React.useState<any>(apiTokensState ?? [])
  const [modalLogs, setModalLogs] = React.useState<any>(null)
  const [editToken, setEditToken] = React.useState<IApiTokens | null>(null)
  const headersWithExtraFields = ['::indicatorApiTokens']?.concat(headers)
  const headerNodes: React.ReactNode[] =
    headersWithExtraFields?.map((header) => <Header key={header} header={header} />)

  const handleOpenEdit = (row: IApiTokens) => {
    setEditToken(row)
  }

  const handleCloseEditModal = () => {
    setEditToken(null)
  }

  const handleDelete = async (id: string) => {
    await deleteApiToken({ id })
  }

  const rowNodes: any = apiTokens?.map((row: any) => (
    headersWithExtraFields?.map((header) =>
      elementMap(header, row, setModalLogs, handleOpenEdit, handleDelete))
  ))

  useEffect(() => {
    if (data) {
      data && dispatch(setApiTokens(data?.apiTokens ?? []))
      setApiToken(data?.apiTokens ?? [])
      dispatch(setApiTokensTotalCount(data?.totalCount ?? 0))
    }
  }, [data])

  useEffect(() => {
    if (menu?.name === 'Active') {
      const activeApiTokens = apiTokensState?.filter((token: any) => token?.active === true)
      setApiToken(activeApiTokens)
    } else if (menu?.name === 'Not Active') {
      const finishedApiTokens = apiTokensState?.filter((token: any) => token?.active === false)
      setApiToken(finishedApiTokens)
    } else {
      setApiToken(apiTokensState)
    }
  }, [menu])

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={spacing(6)}
    >
      <ApiTokensPagination />
      <ApiTokensFilterBar />
      <TableLayout
        headers={headerNodes}
        rows={rowNodes}
        sx={{
          '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-of-type': {
            width: 0
          }
        }}
      />
      {(rowNodes?.length === 0) && <EmptyTableApiTokens />}
      {modalLogs && <ModalEventInfo
        open={!!modalLogs}
        handleClose={() => setModalLogs(null)}
        logs={modalLogs}
      />}
      {!!editToken && (
        <AddEditApiTokensModal handleClose={handleCloseEditModal} open={!!editToken} editToken={editToken} />
      )}
    </Box>
  )
}
