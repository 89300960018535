import React, { type SVGProps } from 'react'

export default function ClipboardIcon (props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15.24 2L11.346 2C9.582 2 8.184 2 7.091 2.148C5.965 2.3 5.054 2.62 4.336 3.341C3.617 4.062 3.298 4.977 3.147 6.107C3 7.205 3 8.608 3 10.379L3 16.217C3 17.725 3.92 19.017 5.227 19.559C5.16 18.649 5.16 17.374 5.16 16.312V11.302C5.16 10.021 5.16 8.916 5.278 8.032C5.405 7.084 5.691 6.176 6.425 5.439C7.159 4.702 8.064 4.415 9.008 4.287C9.888 4.169 10.988 4.169 12.265 4.169H15.335C16.611 4.169 17.709 4.169 18.59 4.287C18.326 3.61337 17.8652 3.03489 17.2676 2.62696C16.6701 2.21902 15.9635 2.00054 15.24 2Z" fill="#4141D5"/>
      <path d="M6.59961 11.3963C6.59961 8.67028 6.59961 7.30728 7.44361 6.46028C8.28661 5.61328 9.64361 5.61328 12.3596 5.61328L15.2396 5.61328C17.9546 5.61328 19.3126 5.61328 20.1566 6.46028C20.9996 7.30728 20.9996 8.67028 20.9996 11.3963V16.2163C20.9996 18.9423 20.9996 20.3053 20.1566 21.1523C19.3126 21.9993 17.9546 21.9993 15.2396 21.9993H12.3596C9.64461 21.9993 8.28661 21.9993 7.44361 21.1523C6.59961 20.3053 6.59961 18.9423 6.59961 16.2163L6.59961 11.3963Z" fill="#4141D5"/>
    </svg>
  )
}
