import React from 'react'
import { Box } from '@mui/material'
import PageContentLayout from '@/designSystem/PageContentLayout'
import DashboardGeneralChart from '@/components/Dashboard/GeneralChart/GeneralChart'
import LeftActionButtons from '@/components/Dashboard/LeftActionButtons'
import RightActionButtons from '@/components/Dashboard/RightActionButtons'
import TopAdvertisersCharts from '@/components/Dashboard/TopAdvertisersCharts/TopAdvertisersCharts'
import TopCountryChart from '@/components/Dashboard/TopCountryChart/TopCountryChart'
import Witgets from '@/components/Dashboard/Witgets/Witgets'

export default function Dashboard () {
  return (
    <PageContentLayout
    title={
    <Box
      display={'flex'}
      alignItems={'center'}
    >
      {/* <SettingsIcon /> */}
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          fontSize: '28px',
          fontWeight: 500,
          color: '#000931'
        }}
      >
       Company  Overview
      </Box>
    </Box>
    }
    leftActionButtons={<LeftActionButtons />}
    rightActionButtons={<RightActionButtons />}
    >
      <Box
              display="flex"
              gap={'30px'}
              alignItems="center"
              flexDirection={'column'}
      >
        <Witgets />
        <DashboardGeneralChart/>
        <TopAdvertisersCharts />
        <TopCountryChart />
      </Box>
    </PageContentLayout>
  )
}
