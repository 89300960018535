import React from 'react'

import Box from '@mui/material/Box'

import Form from '@/components/Form'
import Checkbox from '@/components/Form/Checkbox'
import IOSSwitch from '@/components/Form/Switch'
import TextField from '@/components/Form/TextField'

import Popup from '@/designSystem/Popup'

import { type SubmitHandler, type UseFormReturn } from 'react-hook-form'
import ActionButtons from '@/designSystem/ActionButtons'

import { useTheme } from '@mui/material'

export interface Inputs {
  name: string
  description: string
  pullLeadStatuses: { value: boolean }
  active: boolean
}

export interface AddPopupProps {
  form: UseFormReturn<Inputs>
  onSubmit: SubmitHandler<Inputs>
  handleClose: () => void
  title: string
  subtitle: string
}

export default function AddOrEditPopup ({
  form,
  onSubmit,
  handleClose,
  title,
  subtitle
}: AddPopupProps) {
  const theme = useTheme()
  return (
    <Popup
      open
      title={title}
      subTitle={subtitle}
      handleClose={handleClose}
    >
      <Form form={form} onSubmit={onSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          gap={theme.spacing(6)}
        >
          <Box display="flex" flexDirection="column" gap={theme.spacing(3)}>
            <TextField title="Name" name="name" />
            <TextField title="Description" name="description" />
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Checkbox
              defaultValue={{ value: true }}
              label="Pull leads sale statuses"
              name='pullLeadStatuses'
              sx={{
                '& .MuiButtonBase-root': {
                  padding: '0 12px 0 11px'
                }
              }}
            />
            <IOSSwitch
              defaultValue
              formControlLabel={{
                label: 'Active',
                labelPlacement: 'start',
                sx: {
                  margin: 0,
                  '& .MuiTypography-root': {
                    marginRight: theme.spacing(3)
                  }
                }
              }}
              name="active"
            />
          </Box>
          <ActionButtons
            onCancel={handleClose}
          />
        </Box>
      </Form>
    </Popup>
  )
}
