import React from 'react'
import Popup from '@/designSystem/Popup'
import { Box } from '@mui/material'
import { ReactComponent as CopyIcon } from '@/assets/icons/copy-info.svg'
import JsonView from '@uiw/react-json-view'

interface IModalEventInfo {
  handleClose: () => void
  open: boolean
  logs: object | string
}

export const ModalEventInfo = ({ handleClose, open, logs }: IModalEventInfo) => {
  const onCopy = (text: string) => text && navigator?.clipboard?.writeText(text)
  return (
    <Popup
      open={open}
      title="Event Info"
      handleClose={handleClose}
      sx={{
        width: '100wh',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          gap: '8px',
          borderRadius: '12px',
          border: '1px solid var(--ui-main, #4141D5)',
          background: 'var(--ui-left, #FFFFFF)',
          width: '521px',
          minHeight: '100px',
          padding: '10px 10px 10px 16px',
          overflow: 'auto',
          overflowWrap: 'anywhere',
          '::-webkit-scrollbar': {
            height: '4px',
            width: '6px'
          },
          '::-webkit-scrollbar-track': {
            background: '#E0E2F0'
          },
          '::-webkit-scrollbar-thumb': {
            background: '#4141D5',
            borderRadius: 16
          }
        }}
      >{typeof logs === 'object' && <JsonView value={logs} displayDataTypes={false} displayObjectSize={false}
        onCopied={(text, value) => onCopy(text)} shortenTextAfterLength={100} enableClipboard={false} />}
        {typeof logs === 'string' && <Box>{logs}</Box>}
        <Box
          sx={{
            width: '42px',
            height: '42px'
          }} >
          <CopyIcon
            cursor={'pointer'}
            onClick={() => logs && onCopy(typeof logs === 'string' ? logs : `${JSON.stringify(logs)}`)} />
        </Box>
      </Box>
    </Popup >
  )
}
