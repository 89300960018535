import React from 'react'
import Popup from '@/designSystem/Popup'
import { Box } from '@mui/material'
import Button from '@/designSystem/Button'

interface IAccountModal {
  open: boolean
  onCancel: () => void
  onSubmit: () => void
  qrCode: string
}

export const QrCodeModal = ({ open, onCancel, onSubmit, qrCode }: IAccountModal) => {
  return (
      <Popup
         open={open}
         title="Scan this barcode"
         maxWidth='569px'
         handleClose={onCancel}
      >
         <Box sx={{
           display: 'flex',
           flexDirection: 'column',
           gap: '32px',
           alignItems: 'center'
         }}>
            <div className="flex justify-center">
               <img src={qrCode} alt="QR Code" />
            </div>
            <Button sx={{
              width: '521px',
              height: '43px'
            }} variant='contained' text='Continue'
               onClick={onSubmit} />
         </Box>
      </Popup>
  )
}
