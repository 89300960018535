import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import SearchIcon from '@/designSystem/NavigationPanel/Icons/Search'
import TextField from '@/designSystem/TextField'
import InputAdornment from '@mui/material/InputAdornment'

import useTheme from '@mui/material/styles/useTheme'

import type { TextFieldProps as MuiTextFiledProps } from '@mui/material/TextField'
import type { AutocompleteProps } from '@mui/material/Autocomplete'

import type { Option } from '@/designSystem/Autocomplete/types'

type CustomAutocomplete<T> = Omit<AutocompleteProps<Option<T>, undefined, undefined, undefined>, 'renderInput'>

type SearchAutocompleteProps<T> = CustomAutocomplete<T> & {
  options: Array<Option<T>>
  onChange: (value: Option<T>) => void
  TextFieldProps?: MuiTextFiledProps
  placeholder?: string
}

export default function SearchAutocomplete<T> ({
  options,
  placeholder,
  TextFieldProps,
  onChange,
  ...rest
}: SearchAutocompleteProps<T>) {
  const theme = useTheme()
  return (
    <Autocomplete
      disablePortal
      options={options}
      getOptionLabel={(option) => option.title}
      onChange={(_, newValue) => {
        if (newValue) {
          onChange(newValue)
        }
      }}
      popupIcon={''}
      {...rest}
      renderInput={(params) => (
        <TextField
          {...params}
          {...TextFieldProps}
          placeholder='Search'
          sx={{
            textField: {
              padding: `0 ${theme.spacing(10)} 0 ${theme.spacing(4)}`
            }
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}
