import React, { useEffect } from 'react'

import { Box } from '@mui/material'

import { ReactComponent as SettingsIcon } from '@/assets/icons/settings-icon.svg'
import PageContentLayout from '@/designSystem/PageContentLayout'
import LeftActionButtons from '../../../components/Pages/Settings/Terms/LeftActionButtons'
import RightActionButtons from '../../../components/Pages/Settings/Terms/RightActionButtons'
import TermsPagination from '../../../components/Pages/Settings/Terms/TermsPagination'
import TermsFilterBar from '../../../components/Pages/Settings/Terms/Filters/TermsFilterBar'
import {
  useGetTermsQuery, useDeleteTermsMutation,
  setTermsState,
  selectTermsState,
  type ITerm
} from '@/store/terms'
import { useSelector, useDispatch } from 'react-redux'
import { TermsItem } from '@/components/Pages/Settings/Terms/TermsItem'
import { AddEditTermModal } from '@/components/Pages/Settings/Terms/AddEditTermModal'
import { selectTermsQueryParams } from '@/store/queryParams'

export default function TermsPage () {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectTermsQueryParams)
  const termsDataRemote = useGetTermsQuery(queryParams)?.data?.terms
  const termsData = useSelector(selectTermsState)
  const [deleteTerms] = useDeleteTermsMutation()

  const [editTermId, setEditTermId] = React.useState<string>('')

  useEffect(() => {
    dispatch(setTermsState(termsDataRemote))
  }, [termsDataRemote])

  const deleteTerm = async (id: string) => {
    await deleteTerms(id)
  }

  const handleOpenEditModal = (termId: string) => {
    setEditTermId(termId)
  }

  const handleCloseEditModal = () => {
    setEditTermId('')
  }

  return (
    <>
      <PageContentLayout
        title={<Box
          display={'flex'}
          alignItems={'center'}
        >
          <SettingsIcon />
          <Box
            display={'flex'}
            alignItems={'center'}
            sx={{
              fontSize: '28px',
              fontWeight: 500,
              color: '#000931',
              marginLeft: '15px'
            }}
          >
            Settings / <Box sx={{ color: '#7A7F8F', marginLeft: '8px', marginTop: '1px' }}>CRM Terms</Box>
          </Box>
        </Box>}
        leftActionButtons={<LeftActionButtons />}
        rightActionButtons={<RightActionButtons />}
      >
        <TermsFilterBar />
        <TermsPagination />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '24px'
          }}
        >
          {termsData?.map((term: ITerm) => (
            <TermsItem key={term.id} term={term} deleteTerm={deleteTerm} editTerm={handleOpenEditModal} />
          ))}
        </Box>
      </PageContentLayout>
      <AddEditTermModal open={!!editTermId} handleClose={handleCloseEditModal}
        termId={editTermId} />
    </>
  )
}
