import React from 'react'
import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'

import Form from '@/components/Form'
import TextField from '@/components/Form/TextField'
import DateTimePicker from '@/components/Form/DateTimePicker'

import Popup from '@/designSystem/Popup'
import Button from '@/designSystem/Button'
import Accordion from '@/designSystem/Accordion'
import composeSx from '@/designSystem/TextField/v2'

import { type SxProps, Typography, styled } from '@mui/material'

import { useSelector, useDispatch } from 'react-redux'
import { type SubmitHandler, useForm, type UseFormReturn } from 'react-hook-form'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import { selectApiTokensQueryParams, setApiTokensQueryParams } from '@/store/queryParams'

interface FiltersPopupProps {
  handleClose: () => void
}

interface ApiTokensInputs {
  createdAt?: {
    from?: Date
    to?: Date
  }
  name?: string
}

export interface FilterPopupFormProps {
  form: UseFormReturn<ApiTokensInputs>
  onSubmit: SubmitHandler<ApiTokensInputs>
  handleClose: () => void
  resetFilters: () => void
}

export default function ApiTokensFiltersPopup ({
  handleClose
}: FiltersPopupProps) {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectApiTokensQueryParams)
  const theme = useTheme()
  const { palette, spacing } = theme

  const createdAt = queryParams?.filters?.createdAt

  const defaultValues: ApiTokensInputs = {
    createdAt: createdAt && {
      from: createdAt?.from ? new Date(createdAt.from) : undefined,
      to: createdAt?.to ? new Date(createdAt.to) : undefined
    },
    name: queryParams?.filters?.name
  }

  const form = useForm<ApiTokensInputs>({ defaultValues })

  const onSubmit: SubmitHandler<ApiTokensInputs> = (data) => {
    const { createdAt, name } = data

    dispatch(setApiTokensQueryParams({
      ...queryParams,
      filters: {
        createdAt: createdAt && {
          from: createdAt?.from?.toISOString(),
          to: createdAt?.to?.toISOString()
        },
        name
      }
    }))
    handleClose()
  }

  const resetFilters = () => {
    dispatch(setApiTokensQueryParams({ ...queryParams, filters: undefined }))
    handleClose()
  }

  const accordionSx: SxProps = {
    backgroundColor: palette.inputText.main,
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '& .MuiAccordionSummary-root': {
      color: palette.card.main
    },
    '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
      fill: palette.card.main,
      marginRight: '1.3px'
    }
  }

  const datePickerSx: SxProps = {
    backgroundColor: palette.inputText.main,
    borderRadius: '16px'
  }

  const textFieldsSx = composeSx(theme)

  return (
    <Popup
      open
      title={<Title /> as any}
      handleClose={handleClose}
      titleSx={{ color: palette.card.main }}
      maxWidth='404px'
      rootBoxSx={{
        padding: spacing(4, 6),
        backgroundColor: palette.inputText.main
      }}
      closeButtonSx={{
        '& .MuiSvgIcon-root': { fill: palette.card.main }
      }}
    >
      <ScrollBarContainer>
        <Form form={form} onSubmit={onSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            gap={spacing(3)}
          >
            <Accordion defaultExpanded title="Main" sx={accordionSx}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                width="100%"
                gap={spacing(3)}
              >
                <TextField title="Name" sx={textFieldsSx} placeholder='Enter Name' name='name' />
              </Box>
            </Accordion>
            <Accordion title="Dates" sx={accordionSx}>
              <Box
                display="flex"
                flexDirection="column"
                gap={spacing(3)}
                justifyContent="flex-start"
              >
                <Typography variant="body1" color={palette.card.main}>Created From</Typography>
                <CustomizedDatePickers sx={datePickerSx} label='Select date' name="createdAt.from" />
                <Typography variant="body1" color={palette.card.main}>Created To</Typography>
                <CustomizedDatePickers sx={datePickerSx} label='Select date' name="createdAt.to" />
              </Box>
            </Accordion>
            <Box
              display="flex"
              flexDirection="column"
              gap={theme.spacing(2)}
            >
              <Button
                text="Apply Filters"
                variant="contained"
                type="submit"
              />
              <Button
                text="Reset Filters"
                variant="text"
                textColor={theme.palette.red2.main}
                onClick={resetFilters}
              />
            </Box>
          </Box>
        </Form>
      </ScrollBarContainer>
    </Popup>
  )
}

function Title () {
  const theme = useTheme()
  return (
    <Typography
      variant='headline'
      color={theme.palette.whiteText.main}
    >
      Filters
    </Typography>
  )
}

const CustomizedDatePickers = styled(DateTimePicker)(`  
.MuiInputBase-root{
  background: #FFFFFF;
  border-radius: 16px;
  padding: 8px 16px;
}
input{
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #393E51;
}
label{
  color: #6B6B6B;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: -9px;
  &.Mui-focused{
    margin-top: -4px;
  }
}
`)
