import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PageContentLayout from '@/designSystem/PageContentLayout'
import HelpInformationModal from '@/designSystem/Pages/TrafficDistribution/RotationControl/HelpInformationModal'

import LeftActionButtons from '@/components/Pages/TrafficDistribution/RotationControl/LeftActionButtons'
import RightActionButtons from '@/components/Pages/TrafficDistribution/RotationControl/RightActionButtons'
import TreeView, { type IActions } from '@/designSystem/TreeView'
import { useBatchUpdatePriorityMutation, useCloneRotationMutation, useDeleteRotationMutation, useGetRotationsQuery } from '@/store/rotationControl'
import { EType, type IRotationControl } from '@/components/Pages/TrafficDistribution/RotationControl/RotationControlTypes'
import ExtraActions from '@/components/Pages/TrafficDistribution/RotationControl/ExtraActions'
import { useDebounce } from 'use-debounce'
import AddPopup from '@/components/Pages/TrafficDistribution/RotationControl/AddPopup'
import { Alert } from '@mui/material'
import { findElementById } from '@/designSystem/TreeView/helpers'
import RotationControlFilterBar from '@/components/Pages/TrafficDistribution/RotationControl/Filters/RotationControlFilterBar'
import { selectRotationControlQueryParams } from '@/store/queryParams'

export default function RotationControlPage () {
  const [expanded, setExpanded] = useState<string[]>([])
  const [search, setSearch] = useState<string>('')
  const [selectedElement, setSelectedElement] = useState<IRotationControl | null>(null)
  const [mode, setMode] = useState<string | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [deleteRotation] = useDeleteRotationMutation()
  const [cloneRotation] = useCloneRotationMutation()

  const { filters } = useSelector(selectRotationControlQueryParams)
  const { data } = useGetRotationsQuery({ filters })

  const [batchUpdatePriority] = useBatchUpdatePriorityMutation()

  const [rotations, setRotations] = useState<IRotationControl[]>([])

  const [debouncedSearch] = useDebounce(search, 500)

  const matchSearch = (data: IRotationControl[], search: string) => {
    return data.reduce((acc: IRotationControl[], item) => {
      if (item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) return [...acc, item]
      const children = item.children
      if (children.length) {
        const matchingChildren = matchSearch(children, search)
        if (matchingChildren.length > 0) { acc = [...acc, { ...item, children: matchingChildren }] }
      }
      return acc
    }, [])
  }

  const findIds = (data: IRotationControl[]) => {
    return data.reduce((acc: string[], item) => {
      const children = item.children
      if (children.length) {
        const childrensIds = findIds(children)
        acc = [...acc, ...childrensIds, item.id]
      }
      return acc
    }, [])
  }

  useEffect(() => {
    if (data) {
      if (debouncedSearch) {
        const tmp: IRotationControl[] = matchSearch(data, debouncedSearch)
        setRotations(tmp)
        const ids = findIds(tmp)
        setExpanded(ids)
      } else {
        setRotations(data)
      }
    }
  }, [debouncedSearch, data])

  const expandAll = () => {
    if (rotations) {
      const ids = findIds(rotations)
      setExpanded(ids)
    }
  }

  const hideAll = () => {
    setExpanded([])
  }

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  function updatePriorityById (items: IRotationControl[], targetId: string, direction: string) {
    const indexedItems = items?.map((item: IRotationControl, index: number) => ({
      ...item,
      index
    }))
    indexedItems?.sort((a: IRotationControl & { index: number },
      b: IRotationControl & { index: number }) => a.index - b.index)
    const targetIndex = indexedItems?.findIndex((item: IRotationControl & { index: number }) => item.id === targetId)

    if (direction === 'up' && targetIndex > 0) {
      [indexedItems[targetIndex], indexedItems[targetIndex - 1]] =
      [indexedItems[targetIndex - 1], indexedItems[targetIndex]]
    } else if (direction === 'down' && targetIndex < indexedItems?.length - 1) {
      [indexedItems[targetIndex], indexedItems[targetIndex + 1]] =
      [indexedItems[targetIndex + 1], indexedItems[targetIndex]]
    }

    const updatedItems = indexedItems?.map((item: IRotationControl, index: number) => ({
      ...item,
      priority: index + 1
    }))

    return updatedItems
  }

  const handleMoveUpElement = async (id: string) => {
    const rotation = findElementById(data, id)
    const parentRotation = findElementById(data, rotation?.nodeParentId)
    const sameLevelRotations: IRotationControl[] = parentRotation ? parentRotation?.children : data
    const elementsWithPrioritiesToReduce = updatePriorityById(sameLevelRotations, id, 'up')
    const reducePriorityBy1 = elementsWithPrioritiesToReduce?.map((i: IRotationControl) => ({
      id: i.id,
      priority: i.priority
    }))
    await batchUpdatePriority(reducePriorityBy1)
  }

  const handleMoveDownElement = async (id: string) => {
    const rotation = findElementById(data, id)
    const parentRotation = findElementById(data, rotation?.nodeParentId)
    const sameLevelRotations: IRotationControl[] = parentRotation ? parentRotation?.children : data
    const elementsWithPrioritiesToReduce = updatePriorityById(sameLevelRotations, id, 'down')
    const reducePriorityBy1 = elementsWithPrioritiesToReduce?.map((i: IRotationControl) => ({
      id: i.id,
      priority: i.priority
    }))
    await batchUpdatePriority(reducePriorityBy1)
  }

  const deleteRotationWithPriorityUpdate = async (id: string) => {
    await deleteRotation({ id })
    const rotation = findElementById(data, id)
    const parentRotation = findElementById(data, rotation?.nodeParentId)
    const sameLevelRotations: IRotationControl[] = parentRotation ? parentRotation?.children : data
    const elementsWithPrioritiesToReduce = sameLevelRotations?.filter((i) => (rotation && (i.priority > rotation?.priority)) ?? false)
    const reducePriorityBy1 = elementsWithPrioritiesToReduce.map((i) => ({
      id: i.id,
      priority: i.priority - 1
    }))
    await batchUpdatePriority(reducePriorityBy1)
  }

  const actions: IActions = {
    deleteElement: (id) => deleteRotationWithPriorityUpdate(id),
    selectElement: (el) => setSelectedElement(el),
    cloneElement: (id) => cloneRotation({ id }),
    getLogs: () => true,
    selectMode: (mode) => setMode(mode), // if edit false, if create true
    moveUpElement: (id) => handleMoveUpElement(id),
    moveDownElement: (id) => handleMoveDownElement(id)
  }

  const isCreate = selectedElement
    ? ((selectedElement.type === EType.RotationFolder && mode === 'create' && !selectedElement.children.some((i) => i.type === EType.Advertiser)) ||
     (selectedElement.type === EType.RotationFolder && mode === 'create' && selectedElement.children.some((i) => i.type === EType.Advertiser)))
    : false

  return (
    <PageContentLayout
      title="Rotation control"
      support={{ content: <HelpInformationModal />, subtitle: 'Rotation control' }}
      leftActionButtons={<LeftActionButtons />}
      rightActionButtons={<RightActionButtons setErrorMessage={setErrorMessage} />}
    >
      <RotationControlFilterBar />
      <ExtraActions onHide={hideAll} onExpand={expandAll} search={search} onChange={setSearch}/>
      <TreeView items={rotations} expanded={expanded} handleToggle={handleSelect} actions={actions}/>
      {selectedElement && mode && <AddPopup setErrorMessage={setErrorMessage} rotation={selectedElement} isCreate={isCreate} nodeParentId={selectedElement.id} handleClose={() => { setSelectedElement(null); setMode(undefined) }} />}
      {errorMessage && <Alert severity="error" sx={{ position: 'fixed', left: '50%', top: '200px', transform: 'translate(-50%,0)', boxShadow: '0 0 15px grey' }} onClick={() => setErrorMessage(null)}>
      {errorMessage}
    </Alert>}
    </PageContentLayout>
  )
}
