import React, { useEffect } from 'react'

import TableLayout from '@/designSystem/Pages/Advertisers/Table'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import { setTotalCount, type Advertiser } from '@/store/advertisers'
import { useGetAdvertisersQuery } from '@/store/advertisers'
import { useTheme } from '@mui/material'

import FilterBar from '@/components/Advertisers/Filters/FilterBar'
import Pagination from '@/components/Advertisers/Pagination'
import { selectQueryParamsAdvertisers } from '@/store/queryParams'

const defaultRows: Advertiser[] = [
  {
    id: '8737fde1-9eba-43e6-bd38-0f9a02d3a33c',
    name: 'Beier - Schuster',
    description: 'Some cool information',
    createdAt: '2023-07-28T15:20:59.156Z',
    status: 'ACTIVE'
  },
  {
    id: '201d65d0-2f1a-4c93-b5ac-1115a820b48b',
    name: 'Jenkins, Weber and Fadel',
    description: 'Some cool information 2',
    createdAt: '2023-02-09T18:36:52.035Z',
    status: 'INACTIVE'
  },
  {
    id: '3b229918-d60d-4541-9206-b3fbad635036',
    name: 'Ernser, Fay and Runolfsdottir',
    description: 'Some cool information 3',
    createdAt: '2023-10-02T18:48:49.165Z',
    status: 'ACTIVE'
  },
  {
    id: '0a3c640e-5078-471d-8d09-f048eb547f95',
    name: 'Gleason - Cremin',
    description: 'Some cool information 4',
    createdAt: '2022-12-05T18:24:14.189Z',
    status: 'ACTIVE'
  },
  {
    id: '78cc65ad-3608-472c-996b-b99ef73b25b8',
    name: 'Kohler Group',
    createdAt: '2022-11-24T02:59:50.129Z',
    status: 'ACTIVE',
    updatedAt: '2023-11-2T02:59:50.129Z'
  }
]

export default function Table () {
  const dispatch = useDispatch()
  const { limit = 10, headers, filters, page } = useSelector(selectQueryParamsAdvertisers)
  const { data } = useGetAdvertisersQuery({ limit, filters, page })
  const { spacing } = useTheme()

  useEffect(() => {
    if (data?.totalCount) {
      dispatch(setTotalCount(data.totalCount))
    }
  }, [data?.totalCount])

  if (!data) {
    return null
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={spacing(6)}
    >
      <Pagination />
      <FilterBar />
      <TableLayout
        headers={headers}
        rows={data?.advertisers ?? defaultRows}
      />
    </Box>
  )
}
