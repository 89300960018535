import React from 'react'

const FolderIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.605 5.887C1.5 6.439 1.5 7.102 1.5 8.425V19C1.5 24.6565 1.5 27.4855 3.258 29.242C5.0145 31 7.8435 31 13.5 31H19.5C25.1565 31 27.9855 31 29.742 29.242C31.5 27.4855 31.5 24.6565 31.5 19V15.697C31.5 11.749 31.5 9.7735 30.345 8.491C30.2391 8.37262 30.1269 8.25996 30.009 8.1535C28.7265 7 26.751 7 22.803 7H22.242C20.5125 7 19.647 7 18.84 6.7705C18.3971 6.64391 17.9703 6.46674 17.568 6.2425C16.836 5.836 16.224 5.2225 15 4L14.175 3.175C13.764 2.764 13.56 2.56 13.344 2.38C12.415 1.60996 11.2754 1.13792 10.074 1.0255C9.795 1 9.504 1 8.925 1C7.6005 1 6.939 1 6.387 1.105C5.20176 1.32884 4.1115 1.90471 3.25846 2.75748C2.40542 3.61025 1.82921 4.70183 1.605 5.887ZM16.875 13C16.875 12.7016 16.9935 12.4155 17.2045 12.2045C17.4155 11.9935 17.7016 11.875 18 11.875H25.5C25.7984 11.875 26.0845 11.9935 26.2955 12.2045C26.5065 12.4155 26.625 12.7016 26.625 13C26.625 13.2984 26.5065 13.5845 26.2955 13.7955C26.0845 14.0065 25.7984 14.125 25.5 14.125H18C17.7016 14.125 17.4155 14.0065 17.2045 13.7955C16.9935 13.5845 16.875 13.2984 16.875 13Z" fill="#D6178C"/>
      <path d="M16.875 13C16.875 12.7016 16.9935 12.4155 17.2045 12.2045C17.4155 11.9935 17.7016 11.875 18 11.875H25.5C25.7984 11.875 26.0845 11.9935 26.2955 12.2045C26.5065 12.4155 26.625 12.7016 26.625 13C26.625 13.2984 26.5065 13.5845 26.2955 13.7955C26.0845 14.0065 25.7984 14.125 25.5 14.125H18C17.7016 14.125 17.4155 14.0065 17.2045 13.7955C16.9935 13.5845 16.875 13.2984 16.875 13Z" fill="#D6178C"/>
      <path d="M12.994 24V14.2H19.14V15.404H14.478V18.498H18.342V19.688H14.478V24H12.994Z" fill="#F1F3FF"/>
    </svg>
  )
}

export default FolderIcon
