import React from 'react'

import FlexBox from '@/designSystem/FlexBox'

import { Typography, useTheme } from '@mui/material'
import Popup from '@/designSystem/Popup'

interface TermsInfoModalProps {
  handleClose: () => void
}
export default function TermsInfoModal ({
  handleClose
}: TermsInfoModalProps) {
  return (
    <Popup
      open
      handleClose={handleClose}
      title="Help Information"
      subTitle="CRM Metrics"
    >
      <FlexBox gap={3} column>
        <Paragraph body="CRM Terms are basically the formulas consisting of the variables and mathematical signs." />
      </FlexBox>
    </Popup>
  )
}

interface ParagraphProps {
  body: string
}
function Paragraph ({ body }: ParagraphProps) {
  const { palette } = useTheme()
  return (
    <FlexBox gap={2} column>
      <Typography variant="body2" color={palette.text2.main}>{body}</Typography>
    </FlexBox>
  )
}
