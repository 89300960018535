import React, { useEffect } from 'react'
import { useGetRotationQuery } from '@/store/rotationControl'
import LogsLayout from './LogsLayout'
import { RotationLogType } from '@/enums/rotations.enum'
import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/material'
import { toast } from 'react-toastify'
import { getApiError } from '@/utils'

const LogsWrapper = ({ id }: { id: string }) => {
  const { data, isFetching, isError, error } = useGetRotationQuery({
    id,
    include: ['logs']
  })
  useEffect(() => {
    if (isError && error) {
      toast.error(getApiError(error))
    }
  }, [isError, error])
  const logs = (data?.logs ?? []).map((i) => ({
    ...i,
    logData: i.logData?.data ?? {},
    message: `${i.action}`,
    createdAt: new Date(i.createdAt)
  }))
  console.log('logs', logs)
  if (isFetching) {
    return (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '100%', height: '450px' }}>
      <CircularProgress />
    </Box>)
  }
  if (isError) {
    return (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '100%', height: '450px' }}>
      API error
    </Box>)
  }
  return <LogsLayout logs={logs} logType={RotationLogType.LOGS}/>
}

export default LogsWrapper
