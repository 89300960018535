import React from 'react'
import { useSelector } from 'react-redux'
import { Menu, MenuItem } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'
import { usePatchLeadMutation, useUpdateLeadStateMutation } from '@/store/leads'
import { selectLeadsQueryParams } from '@/store/queryParams'
import api from '@/api'

interface LeadsActionsProps {
  ids: string[]
  clearIds?: () => void
  setOpenUpdateLeadStateModal?: (value: boolean) => void
}

const LeadsActions = ({ ids, clearIds, setOpenUpdateLeadStateModal }: LeadsActionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { headers } = useSelector(selectLeadsQueryParams)
  const [patchLead] = usePatchLeadMutation()
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onUpdateToFtdClick = async () => {
    setOpenUpdateLeadStateModal && setOpenUpdateLeadStateModal(true)
    handleClose()
  }
  const onExportToCSV = async () => {
    await api.getCSV('leads', ids, headers, 'Leads')
    handleClose()
  }
  const handleUpdateTestToTrue = async () => {
    ids.forEach(async (id) => {
      await patchLead({ id, data: { isTest: true } })
    })
    clearIds && clearIds()
    handleClose()
  }

  const handleUpdateTestToFalse = async () => {
    ids.forEach(async (id) => {
      await patchLead({ id, data: { isTest: false } })
    })
    clearIds && clearIds()
    handleClose()
  }

  const triggerStyles: React.CSSProperties = {
    padding: '0 8px',
    borderRadius: '12px',
    border: '1px solid black',
    height: '40px',
    alignItems: 'center',
    marginLeft: 'auto',
    background: 'white',
    cursor: 'pointer',
    justifySelf: 'flex-end'
  }

  return <>
  <FlexBox
        onClick={(e) => handleClick(e)}
        sx={triggerStyles}
    >
        Actions ({ids.length})
    </FlexBox>
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
    >
        <MenuItem onClick={onExportToCSV}>Export to CSV</MenuItem>
      {!!setOpenUpdateLeadStateModal && <MenuItem onClick={onUpdateToFtdClick}>Update to FTD</MenuItem>}
        <MenuItem onClick={handleUpdateTestToTrue}>Update Test status to true</MenuItem>
        <MenuItem onClick={handleUpdateTestToFalse}>Update Test status to false</MenuItem>
    </Menu>
  </>
}

export default LeadsActions
