import React from 'react'

export default function EplCPA () {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="45" height="45" rx="12" fill="white" />
      <path d="M23 33C21.6167 33 20.3167 32.7373 19.1 32.212C17.8833 31.6867 16.825 30.9743 15.925 30.075C15.025 29.175 14.3127 28.1167 13.788 26.9C13.2633 25.6833 13.0007 24.3833 13 23C13 21.6167 13.2627 20.3167 13.788 19.1C14.3133 17.8833 15.0257 16.825 15.925 15.925C16.825 15.025 17.8833 14.3127 19.1 13.788C20.3167 13.2633 21.6167 13.0007 23 13C24.3833 13 25.6833 13.2627 26.9 13.788C28.1167 14.3133 29.175 15.0257 30.075 15.925C30.975 16.825 31.6877 17.8833 32.213 19.1C32.7383 20.3167 33.0007 21.6167 33 23C33 24.3833 32.7373 25.6833 32.212 26.9C31.6867 28.1167 30.9743 29.175 30.075 30.075C29.175 30.975 28.1167 31.6877 26.9 32.213C25.6833 32.7383 24.3833 33.0007 23 33ZM23 31C25.2333 31 27.125 30.225 28.675 28.675C30.225 27.125 31 25.2333 31 23C31 20.7667 30.225 18.875 28.675 17.325C27.125 15.775 25.2333 15 23 15C20.7667 15 18.875 15.775 17.325 17.325C15.775 18.875 15 20.7667 15 23C15 25.2333 15.775 27.125 17.325 28.675C18.875 30.225 20.7667 31 23 31ZM23 29C21.3333 29 19.9167 28.4167 18.75 27.25C17.5833 26.0833 17 24.6667 17 23C17 21.3333 17.5833 19.9167 18.75 18.75C19.9167 17.5833 21.3333 17 23 17C24.6667 17 26.0833 17.5833 27.25 18.75C28.4167 19.9167 29 21.3333 29 23C29 24.6667 28.4167 26.0833 27.25 27.25C26.0833 28.4167 24.6667 29 23 29ZM23 27C24.1 27 25.0417 26.6083 25.825 25.825C26.6083 25.0417 27 24.1 27 23C27 21.9 26.6083 20.9583 25.825 20.175C25.0417 19.3917 24.1 19 23 19C21.9 19 20.9583 19.3917 20.175 20.175C19.3917 20.9583 19 21.9 19 23C19 24.1 19.3917 25.0417 20.175 25.825C20.9583 26.6083 21.9 27 23 27ZM23 25C22.45 25 21.979 24.804 21.587 24.412C21.195 24.02 20.9993 23.5493 21 23C21 22.45 21.196 21.979 21.588 21.587C21.98 21.195 22.4507 20.9993 23 21C23.55 21 24.021 21.196 24.413 21.588C24.805 21.98 25.0007 22.4507 25 23C25 23.55 24.804 24.021 24.412 24.413C24.02 24.805 23.5493 25.0007 23 25Z" fill="#4141D5" />
    </svg>

  )
}
