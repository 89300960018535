/* eslint-disable react/prop-types */
import React from 'react'

import { Box, Typography } from '@mui/material'
import BasicTable from '@/designSystem/BasikTable'
import { EmptyTableDeals } from '../EmptyTableDeals'

const DealsTableWrapper = ({
  rowsDealsSettings, rowsDealsBasikInfo,
  timeStampsDealsSettings, conversionsHeaders,
  rowsConversions, fullMode
}: any) => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: '40px',
          gap: '12px'
        }}
      >
        <Box
          width={'50%'}
        >
          <Typography
            variant='body1'
            width="max-content"
            sx={{
              fontSize: '26px',
              color: '#000931',
              display: 'flex',
              fontWeight: '700',
              paddingBottom: '16px'
            }}
          >
            Basic Info
          </Typography>
          <BasicTable
            headers={['Deal UUID', 'User UUID', 'Reason']}
            rows={rowsDealsBasikInfo}
          />
        </Box>
        <Box
          width={'50%'}
        >
          <Typography
            variant='body1'
            width="max-content"
            sx={{
              fontSize: '26px',
              color: '#000931',
              display: 'flex',
              fontWeight: '700',
              paddingBottom: '16px'
            }}
          >
            Timestamps
          </Typography>
          <BasicTable
            headers={['Updated At', 'Created At']}
            rows={timeStampsDealsSettings}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          paddingTop: '24px'
        }}
      >
        <Box
          width={'100%'}
        >
          <Typography
            variant='body1'
            width="max-content"
            sx={{
              fontSize: '26px',
              color: '#000931',
              display: 'flex',
              fontWeight: '700',
              paddingBottom: '16px'
            }}
          >
            Settings
          </Typography>
          <BasicTable
            headers={['Advertiser UUID', 'Countries', 'Goal Type', 'New Revenue']}
            rows={rowsDealsSettings}
          />
        </Box>
      </Box>

      {fullMode && <Box>
        <Box
          sx={{
            display: 'flex',
            paddingTop: '40px'
          }}
        >
        </Box>
        <BasicTable
          headers={conversionsHeaders}
          rows={rowsConversions}
        />
        {(rowsConversions?.length === 0) && <EmptyTableDeals />}
      </Box>}
    </>
  )
}

export default DealsTableWrapper
