import React from 'react'

import {
  Checkbox as MuiCheckbox,
  type CheckboxProps as MuiCheckboxProps
} from '@mui/material'

import CheckedIcon from '@/designSystem/Checkbox/Icons/CheckedCheckbox'
import UncheckedCheckbox from '@/designSystem/Checkbox/Icons/UncheckedCheckbox'

export default function Checkbox (props: MuiCheckboxProps) {
  return (
    <MuiCheckbox
      checkedIcon={<CheckedIcon />}
      icon={<UncheckedCheckbox />}
      {...props}
    />
  )
}
