import React, { useEffect, useMemo, useState, memo } from 'react'

import { useDrop, DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

import cloneDeep from 'lodash.clonedeep'
import Box from '@mui/material/Box'
import { TableHead, Typography, useTheme, styled, type TableCellProps, Icon } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import StatusBox from './StatusBox'
import FlexBox from '@/designSystem/FlexBox'
import { useGetSaleStatusesQuery } from '@/store/saleStatuses'
import { useDeleteSaleStatusMappingMutation, useEditSaleStatusMappingMutation, useGetSaleStatusesMappingQuery } from '@/store/saleStatusesMapping'

import EditIcon from '@mui/icons-material/Edit'

export interface AdvertiserStatus { id: string, content: string }

type SaleStatusesTypes = string
export type SaleStatusesWithUnused = SaleStatusesTypes | 'Unused'
interface UsedSaleStatuses { color: string, statuses: string[] }
// const inUseSaleStatuses: Record<SaleStatusesTypes, UsedSaleStatuses> = {
//   Depositor: { color: '#90E63D', statuses: ['status1'] },
//   Canceled: { color: '#0ED9BD', statuses: ['status2'] },
//   'Wrong Number': { color: '#AD0F86', statuses: [] },
//   New: { color: '#F0571B', statuses: [] },
//   'Not Interested': { color: '#990205', statuses: [] },
//   'Language Barrier': { color: '#ACBC4C', statuses: [] }
// }

export type StatusEntry = [string, AdvertiserStatus]
type UsedStatusEntry = [SaleStatusesTypes, UsedSaleStatuses]

const headers = ['CRM Sale Status', 'Advertiser Sale Status']

interface Props {
  editCRMSaleStatus: (val: unknown) => void
}
export default function SaleStatuses ({ editCRMSaleStatus }: Props) {
  // const [editSaleStatus, setEditSaleStatus] = useState<SaleStatusesTypes | null>(null)

  const [editSaleStatusMapping] = useEditSaleStatusMappingMutation()

  const saleStatuses = useGetSaleStatusesQuery({}).data?.saleStatuses ?? []
  const saleStatusesMapping = useGetSaleStatusesMappingQuery({}).data?.saleStatusMappings ?? []

  const transformedMapping = saleStatusesMapping.reduce((acc, item) => ({
    ...acc,
    [item.id]: {
      id: item.id,
      content: item.advertiserSaleStatus
    }
  }), {})

  const transformedStatuses = saleStatuses.reduce((acc, item) => ({
    ...acc,
    [item.name]: {
      color: item.color,
      description: item.description,
      id: item.id,
      statuses: saleStatusesMapping.reduce((acc: any[], i) => {
        if (i.crmSaleStatusId === item.id) return [...acc, i.id]
        return acc
      }, [])
    }
  }), {})

  const inUseSaleStatusEntries = useMemo<UsedStatusEntry[]>(() =>
    Object.entries(transformedStatuses) as UsedStatusEntry[], [transformedStatuses]
  )

  const allAdvertiserStatuses = useMemo<StatusEntry[]>(() => Object.entries(transformedMapping), [transformedMapping])

  const [state, setState] = useState(() => ({
    inUseSaleStatuses: inUseSaleStatusEntries,
    unusedAdvertiserStatuses: allAdvertiserStatuses
      .filter(filterUsedStatuses(composeUsedStatuses(inUseSaleStatusEntries)))
  }))

  useEffect(() => {
    if (inUseSaleStatusEntries && allAdvertiserStatuses) {
      setState({
        inUseSaleStatuses: inUseSaleStatusEntries,
        unusedAdvertiserStatuses: allAdvertiserStatuses
          .filter(filterUsedStatuses(composeUsedStatuses(inUseSaleStatusEntries)))
      })
    }
  }, [transformedStatuses, transformedMapping])

  const handleStatusMove = async (
    id: string,
    oldRowName: SaleStatusesWithUnused,
    newRowName: SaleStatusesWithUnused
  ) => {
    const findSaleStatusIdByName = (name: string): string | null => {
      const status = saleStatuses.find((i) => i.name === name)
      return status?.id ?? null
    }
    if (oldRowName === newRowName) {
      return
    }
    const newStatusId = findSaleStatusIdByName(newRowName)

    await editSaleStatusMapping({
      id,
      crmSaleStatusId: newStatusId
    })
  }

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <FlexBox column gap={5}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <CustomTableCell key={header} align="left">
                      <Typography variant="body1">
                        {header}
                      </Typography>
                    </CustomTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {state.inUseSaleStatuses.map(([key, value]) => (
                  <DroppableTableRow key={key} onStatusMove={handleStatusMove} rowName={key}>
                    <CustomTableCell>
                      <FlexBox gap={2} alignCenter>
                        <Box
                          sx={{
                            borderRadius: '100px',
                            width: '16px',
                            height: '16px',
                            backgroundColor: value.color
                          }}
                        />
                        <Typography variant="table1">
                          {key}
                        </Typography>
                        <Icon sx={{ cursor: 'pointer' }} onClick={() => editCRMSaleStatus({ key, value })}>
                          <EditIcon />
                        </Icon>
                      </FlexBox>
                    </CustomTableCell>
                    <CustomTableCell>
                      <AdvertiserStatusesRow
                        statuses={composeAdvertiserStatuses(transformedMapping, value.statuses)}
                        onStatusMove={handleStatusMove}
                        rowName={key}
                        isTable
                      />
                    </CustomTableCell>
                    {/* <CustomTableCell>
                      <IconButton>
                        <EditIcon htmlColor={palette.primary.main} />
                      </IconButton>
                    </CustomTableCell> */}
                  </DroppableTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <FlexBox column gap={3}>
            <Typography variant="h2">
              Unused Advertiser Statuses
            </Typography>
            <AdvertiserStatusesRow
              onStatusMove={handleStatusMove}
              statuses={state.unusedAdvertiserStatuses}
              rowName='Unused'
            />
          </FlexBox>
        </FlexBox>
      </DndProvider>
      {/* {editSaleStatus && (
        <EditPopup />
      )} */}
    </>
  )
}

type OnStatusMove = (id: string, oldRowName: SaleStatusesWithUnused, newRowName: SaleStatusesWithUnused) => void

interface DroppableTableRowProps {
  children: React.ReactNode
  onStatusMove: OnStatusMove
  rowName: SaleStatusesTypes
}
const DroppableTableRow = ({ children, onStatusMove, rowName }: DroppableTableRowProps) => {
  const [, dropRef] = useDrop({
    accept: 'STATUS',
    drop: (item: any) => onStatusMove(item.id, item.rowName, rowName)
  })

  return (
    <TableRow ref={dropRef}>
      {children}
    </TableRow>
  )
}

const composeUsedStatuses = (statuses: UsedStatusEntry[]) =>
  statuses.reduce<string[]>((prev, [, value]) => prev.concat(value.statuses), [])

const filterUsedStatuses = (usedStatuses: string[]) => ([key]: StatusEntry) =>
  !usedStatuses.includes(key)

const composeAdvertiserStatuses = (transformedMapping: any, statuses: string[]): StatusEntry[] => {
  return statuses.map((status) => (
    [
      status,
      {
        id: status,
        content: transformedMapping[status]?.content
      }
    ]
  ))
}

interface AdvertiserStatusesRowProps {
  isTable?: boolean
  statuses?: StatusEntry[]
  onStatusMove: OnStatusMove
  rowName: SaleStatusesWithUnused
}
const AdvertiserStatusesRow = ({ isTable, statuses, onStatusMove, rowName }: AdvertiserStatusesRowProps) => {
  const { spacing, palette } = useTheme()

  const isUsed = rowName !== 'Unused'
  const [deleteSaleStatusMapping] = useDeleteSaleStatusMappingMutation()

  const [{ handlerId }, dropRef] = useDrop({
    accept: 'STATUS',
    drop: (item: any) => onStatusMove(item.id, item.rowName, rowName),
    collect: (monitor) => {
      return {
        handlerId: monitor.getHandlerId()
      }
    }
  })

  if (!statuses?.length) {
    return (
      <Typography variant="table1" color={palette.gray.main}>
        Statuses have not been added yet
      </Typography>
    )
  }

  return (
    <FlexBox
      gap={3}
      width="100%"
      columnGap={spacing(3)}
      flexWrap="wrap"
      ref={dropRef}
      data-handler-id={handlerId}
    >
      {statuses.map(([statusKey, statusValue], index) => (
        <StatusBox
          key={statusKey}
          statuses={statuses}
          onStatusMove={onStatusMove}
          value={statusValue?.content}
          isTable={isTable}
          rowName={rowName}
          id={statusKey}
          onDelete={() => deleteSaleStatusMapping({ id: statusKey })}
        />
      ))}
    </FlexBox>
  )
}

const CustomTableCell = styled((props: TableCellProps) => (
  <TableCell {...props} />
))(() => ({
  borderBottom: 'none',
  width: 'fit-content'
}))
