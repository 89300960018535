export function getApiError (err: any): string {
  if (err && typeof err === 'object') {
    console.log('object', err.message)
    if (err?.data?.message) {
      return err?.data?.message
    }
  }

  if (typeof err === 'string') {
    return err
  }

  return 'An unknown error occurred'
}
