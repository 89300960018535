import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PageContentLayout from '@/designSystem/PageContentLayout'
import HelpInformationModal from '@/designSystem/Pages/LeadConvertions/AdvertiserRevenue/HelpInformationModal'
import LeftActionButtons from '@/components/Pages/LeadConvertions/AdvertiserRevenue/LeftActionButtons'
import { TreeView, type IActions } from '@/designSystem/TreeView'
import { useGetRevenuesQuery } from '@/store/advertiserRevenue'

import { type IAdveriserRevenue } from '@/components/Pages/LeadConvertions/AdvertiserRevenue/AdvertiserRevenueTypes'
import ExtraActions from '@/components/Pages/LeadConvertions/AdvertiserRevenue/ExtraActions'
import { useDebounce } from 'use-debounce'
import AddPopup from '@/components/Pages/LeadConvertions/AdvertiserRevenue/AddPopup'
import RevenueRow from '@/components/Pages/LeadConvertions/AdvertiserRevenue/TableRow'
import { selectAdvertiserRevenueQueryParams } from '@/store/queryParams'

export default function AdvertiserRevenuePage () {
  const [expanded, setExpanded] = useState<string[]>([])
  const [search, setSearch] = useState<string>('')
  const [selectedElement, setSelectedElement] = useState<IAdveriserRevenue | null>(null)
  // const [mode, setMode] = useState<'create' | 'edit' | null>(null)

  const { filters } = useSelector(selectAdvertiserRevenueQueryParams)

  const { data } = useGetRevenuesQuery({ filters })
  // const data = mockedData

  const [rotations, setRotations] = useState<IAdveriserRevenue[]>([])

  const [debouncedSearch] = useDebounce(search, 500)

  const matchSearch = (data: IAdveriserRevenue[], search: string) => {
    return data.reduce((acc: IAdveriserRevenue[], item) => {
      if (item.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase())) return [...acc, item]
      const children = item.children
      if (children?.length) {
        const matchingChildren = matchSearch(children, search)
        if (matchingChildren?.length > 0) { acc = [...acc, { ...item, children: matchingChildren }] }
      }
      return acc
    }, [])
  }

  const findIds = (data: IAdveriserRevenue[]) => {
    return data.reduce((acc: string[], item) => {
      const children = item.children
      if (children?.length) {
        const childrensIds = findIds(children)
        acc = [...acc, ...childrensIds, item.id]
      }
      return acc
    }, [])
  }

  useEffect(() => {
    if (data) {
      if (debouncedSearch) {
        const tmp: IAdveriserRevenue[] = matchSearch(data, debouncedSearch)
        setRotations(tmp)
        const ids = findIds(tmp)
        setExpanded(ids)
      } else {
        setRotations(data)
      }
    }
  }, [debouncedSearch, data])

  const expandAll = () => {
    if (rotations) {
      const ids = findIds(rotations)
      setExpanded(ids)
    }
  }

  const hideAll = () => {
    setExpanded([])
  }

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  const actions: IActions = {
    selectElement: (el) => setSelectedElement(el)
  }

  return (
    <PageContentLayout
      title="Advertiser Revenue"
      support={{ content: <HelpInformationModal />, subtitle: 'Advertiser Revenue' }}
      leftActionButtons={<LeftActionButtons />}
    >
      <ExtraActions onHide={hideAll} onExpand={expandAll} search={search} onChange={setSearch}/>
      <TreeView CustomLabel={(item: any) => <RevenueRow item={item} actions={actions}/>} items={rotations} expanded={expanded} handleToggle={handleSelect} actions={actions}/>
      {selectedElement && <AddPopup revenue={selectedElement} nodeParentId={selectedElement.id} handleClose={() => setSelectedElement(null)} />}
    </PageContentLayout>
  )
}
