import React from 'react'

import { Box, Typography, useTheme } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'

export default function SupportPopupContent () {
  const { spacing } = useTheme()
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={spacing(3)}
    >
      <Typography
        variant="headline"
        color={'#393E51'}
        fontSize={18}
        fontWeight={600}

      >Basic Information
      </Typography>
      <Paragraph text="Here you can view basic information about the advertiser." />
      <Typography
        variant="headline"
        color={'#393E51'}
        fontSize={18}
        fontWeight={600}

      >Push Leads
      </Typography>
      <Paragraph text="Here you can specify settings for sending a request to Advertiser API to push a lead and settings for parsing a response returned from it." />
       <Typography
        variant="headline"
        color={'#393E51'}
        fontSize={18}
        fontWeight={600}
      >Pull Leads
      </Typography>
      <Paragraph text="Here you can specify settings for sending a request to Advertiser API to fetch data stored for leads, parse leads status values from this data and compare them with status values stored for leads in CRM." />
      <Typography
        variant="headline"
        color={'#393E51'}
        fontSize={18}
        fontWeight={600}

      >Logs
      </Typography>
      <Paragraph text="Here you can view logs related to sending request to Pull Leads Status for this advertiser." />
     </Box>
  )
}
interface ParagraphProps {
  text: string
}
function Paragraph ({ text }: ParagraphProps) {
  const { palette } = useTheme()
  return (
      <Typography variant="body2" color={palette.text2.main}>{text}</Typography>
  )
}
