import React, { useState } from 'react'
import { type FilterItemProps } from '.'
import AutocompleteFilterItem from './AutocompleteFilterItem'
import AdvertiserAutocomplete from '@/designSystem/TreeView/PQL/AdvertiserAutocomplete'
import { type Option } from '@/designSystem/Autocomplete/types'
import { useGetAdvertisersQuery } from '@/store/advertisers'
import { useDebounce } from 'use-debounce'
import { useGetSaleStatusesMappingQuery } from '@/store/saleStatusesMapping'

const AdvertiserSaleStatusFilterItem = (props: FilterItemProps) => {
  const [search, setSearch] = useState<string>('')

  const [debouncedSearch] = useDebounce(search, 500)

  const advertisersData = useGetSaleStatusesMappingQuery({ filters: { name: debouncedSearch }, page: 1, limit: 10 }).data

  const advertisersOptions = advertisersData?.saleStatusMappings?.map((item) => ({
    title: item.advertiserSaleStatus,
    value: item.id
  })) ?? []

  return <AutocompleteFilterItem {...props} options={advertisersOptions} setSearch={setSearch} />
}

export default AdvertiserSaleStatusFilterItem
