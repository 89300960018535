/* eslint-disable react/prop-types */
import React from 'react'

import { Box, Typography } from '@mui/material'
import BasicTable from '@/designSystem/BasikTable'
import { EmptyTableInject } from '@/components/Injections/EmptyTableInject'
import InjectTasksPagination from '@/components/Injections/InjectTasksPagination'

const PullingTableWrapper = ({
  advertiserSaleStatusMain, conversionsData, conversions
}: any) => {
  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        gap={'16px'}
        paddingTop={'40px'}
        paddingBottom={'12px'}
      >
        <Typography
          width="max-content"
          sx={{
            color: '#000931',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            textTransform: 'capitalize'
          }}
        >
          Advertiser Sale status registry
        </Typography>
      </Box>
      <BasicTable
        headers={['UUID', 'CRM Sale Status', 'External sale status', 'Created At']}
        rows={advertiserSaleStatusMain}
      />

      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '12px',
            paddingTop: '47px',
            paddingBottom: '12px'
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'16px'}
          >
            <Typography
              width="max-content"
              sx={{
                color: '#000931',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                textTransform: 'capitalize'
              }}
            >
              Conversions
            </Typography>
          </Box>
          <InjectTasksPagination count={conversions?.length ?? 0} />
        </Box>
        <BasicTable
          headers={['UUID', 'Lead UUID', 'Advertiser UUID',
            'Sent Lead UUID', 'Revenue', 'Country', 'Goal type', 'Created By', 'Created At', 'Updated At']}
          rows={conversionsData || []}
        />
        {conversions?.length === 0 && <EmptyTableInject />}
      </Box>
    </>
  )
}

export default PullingTableWrapper
