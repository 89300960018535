import React from 'react'

import FlexBox from '@/designSystem/FlexBox'

import { Typography, useTheme } from '@mui/material'
import Popup from '@/designSystem/Popup'

interface AutoInjectionModalProps {
  handleClose: () => void
}
export default function AutoInjectionInfoModal ({
  handleClose
}: AutoInjectionModalProps) {
  return (
    <Popup
      open
      handleClose={handleClose}
      title="Help Information"
      subTitle="Conversions"
    >
      <FlexBox gap={3} column>
        <Paragraph body="The conversions lists allows you to see all the conversions, which are leads that your business is paid for. They can be leads on any state, determined by the goals, and created once a lead fulfills the goal." />
        <Paragraph body="WARNING! To see the conversion you should set up the goal for each advertiser. You can find them in the Settings Navigation section (press the Gear Button on the left down corner of the page)." />
      </FlexBox>
    </Popup>
  )
}

interface ParagraphProps {
  body: string
}
function Paragraph ({ body }: ParagraphProps) {
  const { palette } = useTheme()
  return (
    <FlexBox gap={2} column>
      <Typography variant="body2" color={palette.text2.main}>{body}</Typography>
    </FlexBox>
  )
}
