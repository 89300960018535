import React, { useState } from 'react'

import TableLayout from '@/designSystem/Table'
import fieldToElementMap from '@/designSystem/Pages/Advertisers/Table/fieldToElementMap'
import { Typography } from '@mui/material'
import EditPopup from '@/components/Advertisers/EditPopup'
import { useDeleteAdvertiserMutation, type Advertiser } from '@/store/advertisers'
import DeleteAdvertiserPopup from '../Popups/DeleteAdvertiserPopup'

const Header = ({ header }: { header: string }) => {
  if (header.startsWith('::')) {
    return ''
  }
  return (
    <Typography
      variant='body1'
      color="text1"
      width="max-content"
      sx={{
        display: 'flex'
      }}
    >
      {header}
    </Typography>
  )
}

interface TableProps {
  headers: string[]
  rows: Advertiser[]
}

export default function Table ({ rows, headers }: TableProps) {
  const [advertiserUUIDToEdit, setAdvertiserUUIDToEdit] = useState<string | null>(null)
  const [advertiserUUIDDelete, setAdvertiserUUIDDelete] = useState<string | null>(null)
  const [deleteAdvertiser] = useDeleteAdvertiserMutation()

  const headersWithExtraFields = ['::indicator'].concat(headers).concat('::edit').concat('::delete')
  const headerNodes: React.ReactNode[] = headersWithExtraFields
    .map((header) => <Header key={header} header={header} />)

  const handleDelete = (id: string) => {
    setAdvertiserUUIDDelete(id)
  }

  const advertiserToEdit = rows.find(({ id }) => id === advertiserUUIDToEdit)
  const advertiserNameToDelete = rows.find(({ id }) => id === advertiserUUIDDelete)?.name

  const rowNodes = rows.map((row) => (
    headersWithExtraFields.map((header) =>
      fieldToElementMap(header, row, setAdvertiserUUIDToEdit, handleDelete))
  ))

  const submitDelete = async () => {
    if (advertiserUUIDDelete) {
      await deleteAdvertiser(advertiserUUIDDelete)
      setAdvertiserUUIDDelete(null)
    }
  }

  const handleCanselDelete = () => {
    setAdvertiserUUIDDelete(null)
  }

  return (
    <>
      <TableLayout
        headers={headerNodes}
        rows={rowNodes}
        sx={{
          '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-of-type': {
            width: 0
          }
        }}
      />
      {advertiserToEdit && (
        <EditPopup
          defaultValues={advertiserToEdit}
          handleClose={() => setAdvertiserUUIDToEdit(null)}
        />
      )}
      {advertiserUUIDDelete && (
        <DeleteAdvertiserPopup
          onSubmit={submitDelete}
          handleClose={handleCanselDelete}
          title='Delete Advertiser'
          subtitle={`Are you sure you want to delete this advertiser ${advertiserNameToDelete}?`}
        />
      )}
    </>
  )
}
