import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'

export interface LogsState {
  logs: any[]
}

const initialState: LogsState = {
  logs: []
}

export const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    setLogs: (state, action: PayloadAction<any[]>) => {
      state.logs = action.payload
    }
  }
})

export const { setLogs } = logsSlice.actions

export const selectLogs = (state: RootState) => state.logs.logs

export default logsSlice.reducer

interface Filters {
  leadId?: string
  advertiserId?: string
  injectionId?: string
  userId?: string
  autoInjectionId?: string
  action?: string
}

interface GetLogsArgs {
  page?: number
  limit?: number
  format?: string
  filter?: Filters
  sortOrder?: string
}

interface GetLogsReturn {
  logs?: any[]
  totalCount?: number
}

export const logsApi = createApi({
  reducerPath: 'logsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/logs`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['Logs'],
  endpoints: (builder) => ({
    getLogs: builder.query<GetLogsReturn, GetLogsArgs>({
      query: ({ limit = 10, page = 1, format, filter, sortOrder = 'desc' }) => ({
        url: '/search',
        method: 'POST',
        body: {
          limit,
          page,
          format,
          sortField: 'createdAt',
          sortOrder,
          filter
        }
      }),
      providesTags: [{ type: 'Logs', id: 'LogsLIST' }]
    })
  })
})

export const { useGetLogsQuery } = logsApi
