import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@/designSystem/Button'
import useTheme from '@mui/material/styles/useTheme'
import { IconButton } from '@mui/material'
import AddIcon from '@/designSystem/Icons/Add'
import { AddInjectionModal } from './AddInjectionModal'
import { useLocation, useNavigate } from 'react-router-dom'

export default function LeftActionButtons ({ isMenu }:
{ isMenu: boolean }): JSX.Element {
  const location = useLocation()
  const [openAddPopup, setOpenAddPopup] = useState(false)
  const theme = useTheme()
  const navigate = useNavigate()
  const onClickOpenAddButton = () => {
    setOpenAddPopup(true)
  }

  useEffect(() => {
    setOpenAddPopup(!!location.state?.uuids)
  }, [])

  const onClickReload = () => navigate(0)
  const handleCloseModal = () => {
    setOpenAddPopup(false)
  }

  return (
    <>
      <Box
        display="flex"
        gap={theme.spacing(3)}
        alignItems="center"
      >
        <Button
          sx={{
            borderRadius: '16px',
            height: '30px',
            padding: `${theme.spacing(1.5)} ${theme.spacing(6)}`
          }}
          variant='contained'
          onClick={onClickReload}
          text="Reload"
          textVariant='subtitle1'
        />
        {!isMenu && <IconButton onClick={onClickOpenAddButton}>
          <AddIcon />
        </IconButton>}
      </Box>
      {openAddPopup && (
        <AddInjectionModal handleClose={handleCloseModal} open={openAddPopup}/>
      )}
    </>
  )
}
