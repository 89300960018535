import React from 'react'

export default function Roi () {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="45" height="45" rx="12" fill="white" />
      <g clipPath="url(#clip0_485_67493)">
        <path d="M29.0003 13.3721C29.0003 13.2349 28.9732 13.0991 28.9205 12.9725C28.8678 12.8459 28.7906 12.731 28.6934 12.6343C28.5961 12.5376 28.4806 12.4612 28.3537 12.4093C28.2267 12.3574 28.0908 12.3312 27.9537 12.3321H14.7137C14.5765 12.3312 14.4406 12.3574 14.3136 12.4093C14.1867 12.4612 14.0712 12.5376 13.974 12.6343C13.8767 12.731 13.7995 12.8459 13.7468 12.9725C13.6941 13.0991 13.667 13.2349 13.667 13.3721V29.9587C13.667 30.0959 13.6941 30.2316 13.7468 30.3583C13.7995 30.4849 13.8767 30.5998 13.974 30.6965C14.0712 30.7931 14.1867 30.8696 14.3136 30.9215C14.4406 30.9733 14.5765 30.9996 14.7137 30.9987H15.0603V13.7121H29.0003V13.3721Z" fill="#4141D5" />
        <path d="M30.6663 15H17.333C16.7807 15 16.333 15.4477 16.333 16V32.6667C16.333 33.219 16.7807 33.6667 17.333 33.6667H30.6663C31.2186 33.6667 31.6663 33.219 31.6663 32.6667V16C31.6663 15.4477 31.2186 15 30.6663 15Z" fill="#4141D5" />
      </g>
      <defs>
        <clipPath id="clip0_485_67493">
          <rect width="24" height="24" fill="white" transform="translate(11 11)" />
        </clipPath>
      </defs>
    </svg>
  )
}
