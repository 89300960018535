import React, { useEffect } from 'react'
import Editor from 'react-simple-code-editor'
import { Box, Typography, useTheme } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'
import ActionButtons from '@/designSystem/ActionButtons'
import SettingsTextField from './SettingsTextField'
import { toast } from 'react-toastify'
import {
  selectAdvertiserSettings,
  setAdvertiserSettings,
  useEditAdvertiserMutation, useLazyTestPreRequestCodeQuery,
  useTestLeadPullQuery, useTestPreRequestCodeQuery
} from '@/store/advertisers'
import { EApiSettingsFields, type IApiSettings } from './EditPushSettingsForm'
import Button from '@/designSystem/Button'
import { useDispatch, useSelector } from 'react-redux'
import AccountsAutocomplete from '@/pages/Accounts/AccountsAutocomplete'
import { selectSaleStatuses } from '@/store/saleStatuses'
import Prism, { highlight, languages } from 'prismjs'
import 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism.css'
import IOSSwitch from '@/designSystem/IOSSwitch'
import JsonView from '@uiw/react-json-view'
import { checkRequestTimeout } from '@/utils/advertisers'

interface EditPushSettingsFormModalProps {
  advertiserId?: string
  handleClose: () => void
  settings?: IApiSettings[]
  setSettings?: (settings: IApiSettings[]) => void
}

enum StatusCheckTypeEnum {
  STATUS_FIELD_VALUE = 'status_field_value',
  RESPONSE_CODE_VALUE = 'response_code_value',
  LEAD_ID_AVAILABILITY = 'lead_id_availability'
}

const regexValidUrl = /^(http|https):\/\/[^ "]+$/

const emptyError = {
  url: '',
  leadId: '',
  saleStatus: '',
  statusType: '',
  statusField: '',
  statusValue: '',
  responseCode: '',
  preRequestCode: ''
}

export default function EditPushSettingsFormModal ({
  advertiserId,
  handleClose,
  settings,
  setSettings
}: EditPushSettingsFormModalProps) {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [editAdvertiser] = useEditAdvertiserMutation()
  const [error, setError] = React.useState(emptyError)
  const saleStatuses = useSelector(selectSaleStatuses)
  const [remoteError, setRemoteError] = React.useState('')
  const preRequestCode = settings?.find((setting: IApiSettings) => setting?.key === 'pre_request_code')?.value
  const [addPreRequestCode, setAddPreRequestCode] = React.useState(preRequestCode !== '')
  const settingsUrl = settings?.find((setting: IApiSettings) => setting?.key === 'url')?.value
  const settingsLeadId = settings?.find((setting: IApiSettings) => setting?.key === 'lead_id_field')?.value
  const settingsSaleStatusToUse = settings?.find((setting: IApiSettings) => setting?.key === 'crm_sale_status_to_use')?.value
  const settingsStatusCheckType = settings?.find((setting: IApiSettings) => setting?.key === 'status_check_type')?.value
  const settingsStatus = settings?.find((setting: IApiSettings) => setting?.key === 'status_field')?.value
  const settingsStatusField = settings?.find((setting: IApiSettings) => setting?.key === 'status_field_value')?.value
  const settingsResponseCode = settings?.find((setting: IApiSettings) => setting?.key === 'response_code_value')?.value
  const settingsResponseType = settings?.find((setting: IApiSettings) => setting?.key === 'request_type')?.value
  const settingsAutoLogin = settings?.find((setting: IApiSettings) => setting?.key === 'auto_login_url_field')?.value
  const requestTimeout = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.REQUEST_TIMEOUT)?.value
  const [testPreRequestCodeResponse, setTestPreRequestCodeResponse] = React.useState<any | null>(null)
  const [testPreCodeRequestQuery] = useLazyTestPreRequestCodeQuery()

  const isStatusField = settingsStatusCheckType === 'status_field_value'
  const isResponseCode = settingsStatusCheckType === 'response_code_value'
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const isValueCheckTypeFields1 = isStatusField && (settingsStatus &&
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    settingsStatusField)
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const isValueCheckTypeFields2 = isResponseCode && settingsResponseCode
  const isLeadIdAvailability = settingsStatusCheckType === 'lead_id_availability'
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const isValueCheckTypeFields = !!(isValueCheckTypeFields1 || isValueCheckTypeFields2 || isLeadIdAvailability)
  const statusesOptions = saleStatuses?.map((item: any) => ({
    title: item?.name,
    value: item?.name
  })) ?? []

  useEffect(() => {
    if (isLeadIdAvailability) {
      settings && setSettings?.(settings?.map((setting: IApiSettings) => {
        if (setting?.key === 'status_field_value' ||
          setting?.key === 'response_code_value' ||
          setting?.key === 'status_field') {
          return {
            ...setting,
            value: ''
          }
        }
        return setting
      }))
    }
  }, [isLeadIdAvailability])

  useEffect(() => {
    if (settingsUrl && settingsLeadId &&
      settingsSaleStatusToUse && settingsStatusCheckType && isValueCheckTypeFields) {
      setError(emptyError)
    }
  }, [settingsUrl, settingsLeadId,
    settingsSaleStatusToUse, settingsStatusCheckType, isValueCheckTypeFields])

  const onSubmit = async () => {
    const newSettings = settings && [...settings]
    const validUrl = regexValidUrl.test(settingsUrl ?? '')
    const preRequestCodeError = addPreRequestCode && preRequestCode === '' ? 'Pre-request code is empty' : ''
    const error = checkRequestTimeout(requestTimeout)
    if (error) {
      return toast.error(error)
    }
    if (!settingsUrl || !validUrl || !settingsLeadId ||
      !settingsSaleStatusToUse || !settingsStatusCheckType || !isValueCheckTypeFields || preRequestCodeError) {
      !remoteError && setError({
        url: !settingsUrl || !validUrl ? 'Not a valid URL' : '',
        leadId: !settingsLeadId ? 'Lead Id Field is required' : '',
        saleStatus: !settingsSaleStatusToUse ? 'CRM Sale Status To Use is required' : '',
        statusType: !settingsStatusCheckType ? 'Status Check Type is required' : '',
        statusField: isStatusField && !settingsStatus ? 'Status Field is required' : '',
        statusValue: isStatusField && !settingsStatusField ? 'Status Field Value is required' : '',
        responseCode: isResponseCode && !settingsResponseType ? 'Response Code Value is required' : '',
        preRequestCode: preRequestCodeError
      })
    } else {
      try {
        const dataAdvertiser = await editAdvertiser({
          id: advertiserId ?? '',
          advertiserSettings: newSettings
        }).unwrap()
        if (dataAdvertiser?.advertiserSettings) {
          setSettings && setSettings(dataAdvertiser?.advertiserSettings)
          dispatch(setAdvertiserSettings(dataAdvertiser?.advertiserSettings))
          handleClose()
        }
        setRemoteError('')
        handleClose()
      } catch (e: any) {
        if (e?.data?.message.includes('Pre-request code error')) {
          setTestPreRequestCodeResponse(e?.data)
        }
        setRemoteError(e?.data?.message)
        return false
      }
    }
  }

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'url') {
        return {
          ...setting,
          value: e.target.value
        }
      }
      return setting
    }))
  }
  const onCopy = (text: string) => text && navigator?.clipboard?.writeText(text)

  const testPreRequestCode = async () => {
    if (preRequestCode) {
      const response = await testPreCodeRequestQuery({ preRequestCode, advertiserId: advertiserId ?? '' })
      if (response.error) {
        setTestPreRequestCodeResponse(response.error)
      } else {
        setTestPreRequestCodeResponse(response.data)
      }
    }
  }
  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'status_field') {
        return {
          ...setting,
          value: e.target.value
        }
      } else if (setting?.key === 'response_code_value') {
        return {
          ...setting,
          value: ''
        }
      }
      return setting
    }))
  }

  const handleLeadIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'lead_id_field') {
        return {
          ...setting,
          value: e.target.value
        }
      }
      return setting
    }))
  }

  const handleRequestTypeChange = (e: { value: string, title: string } | null) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'request_type' && e) {
        return {
          ...setting,
          value: e?.value
        }
      }
      return setting
    }))
  }
  const handleRequestTimeout = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === EApiSettingsFields.REQUEST_TIMEOUT) {
        return {
          ...setting,
          value: e.target.value
        }
      }
      return setting
    }))
  }
  const handleCrmSaleStatusChange = (e: { value: string, title: string } | null) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'crm_sale_status_to_use' && e) {
        return {
          ...setting,
          value: e?.value
        }
      }
      return setting
    }))
  }

  const handleCheckTypeChange = (e: { value: string, title: string } | null) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'status_check_type' && e) {
        return {
          ...setting,
          value: e?.value
        }
      }
      return setting
    }))
  }

  const handleStatusValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'status_field_value') {
        return {
          ...setting,
          value: e.target.value
        }
      } else if (setting?.key === 'response_code_value') {
        return {
          ...setting,
          value: ''
        }
      }
      return setting
    }))
  }

  const handleResponseCodeValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'response_code_value') {
        return {
          ...setting,
          value: e.target.value
        }
      } else if ((setting?.key === 'status_field_value') || (setting?.key === 'status_field')) {
        return {
          ...setting,
          value: ''
        }
      }
      return setting
    }))
  }

  const handleAutoLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'auto_login_url_field') {
        return {
          ...setting,
          value: e.target.value
        }
      }
      return setting
    }))
  }

  const handlePreRequestChange = (code: string) => {
    settings && setSettings?.(settings?.map((setting: IApiSettings) => {
      if (setting?.key === 'pre_request_code') {
        return {
          ...setting,
          value: code
        }
      }
      return setting
    }))
  }

  return (
    <>
      <FlexBox column gap={3}>
        <Box>
          <SettingsTextField title={'Url'} value={settingsUrl} onChange={handleUrlChange} />
          <Typography variant='body2' color='error'>
            {error.url}
          </Typography>
        </Box>
        <Box>
          <SettingsTextField title={'Lead Id Field'} value={settingsLeadId} onChange={handleLeadIdChange} />
          <Typography variant='body2' color='error'>
            {error.leadId}
          </Typography>
        </Box>
        <Box>
          <Typography
            color={'#393E51'}
            fontSize={18}
            fontWeight={400}
            marginBottom={'4.5px'}
          >CRM Sale Status To Use
          </Typography>
          <AccountsAutocomplete
            defaultValue={settingsSaleStatusToUse
              ? { value: settingsSaleStatusToUse, title: settingsSaleStatusToUse }
              : { value: settingsSaleStatusToUse ?? 'NEW', title: settingsSaleStatusToUse ?? 'NEW' }}
            options={statusesOptions}
            onChange={(e: { value: string, title: string } | null) => { handleCrmSaleStatusChange(e) }}
            placeholder="Change Status Field"
            sx={{ width: '100%' }}
          />
          <Typography variant='body2' color='error'>
            {error.saleStatus}
          </Typography>
        </Box>
        <Box>
          <Typography
            color={'#393E51'}
            fontSize={18}
            fontWeight={400}
            marginBottom={'4.5px'}
          >Status Check Type
          </Typography>
          <AccountsAutocomplete
            defaultValue={settingsStatusCheckType
              ? { value: settingsStatusCheckType, title: settingsStatusCheckType }
              : { value: StatusCheckTypeEnum.STATUS_FIELD_VALUE, title: 'Status field' }}
            options={[{ value: StatusCheckTypeEnum.STATUS_FIELD_VALUE, title: 'Status field' },
              { value: StatusCheckTypeEnum.RESPONSE_CODE_VALUE, title: 'Response code' },
              { value: StatusCheckTypeEnum.LEAD_ID_AVAILABILITY, title: 'Lead id availability' }]}
            onChange={(e: { value: string, title: string } | null) => { handleCheckTypeChange(e) }}
            placeholder="Change Status Type"
            sx={{ width: '100%' }}
          />
          <Typography variant='body2' color='error'>
            {error.statusType}
          </Typography>
        </Box>
        {isStatusField && <Box>
          <SettingsTextField title={'Status Field'} value={settingsStatus ?? ''}
            onChange={handleStatusChange} />
          <Typography variant='body2' color='error'>
            {error.statusField}
          </Typography>
        </Box>}
        {isStatusField && <Box>
          <SettingsTextField title={'Status Field Value'} value={settingsStatusField ?? ''}
            onChange={handleStatusValueChange} />
          <Typography variant='body2' color='error'>
            {error.statusValue}
          </Typography>
        </Box>}
        {isResponseCode && <Box>
          <SettingsTextField title={'Response Code Value'} value={settingsResponseCode ?? ''}
            onChange={handleResponseCodeValueChange} />
          <Typography variant='body2' color='error'>
            {error.responseCode}
          </Typography>
        </Box>}
        <Box>
          <Typography
            color={'#393E51'}
            fontSize={18}
            fontWeight={400}
            marginBottom={'4.5px'}
          >Request type (POST is used by default)
          </Typography>
          <AccountsAutocomplete
            defaultValue={settingsResponseType
              ? { value: settingsResponseType, title: settingsResponseType.toUpperCase() }
              : { value: 'get', title: 'GET' }}
            options={[{ value: 'get', title: 'GET' }, { value: 'post', title: 'POST' }]}
            onChange={(e: { value: string, title: string } | null) => { handleRequestTypeChange(e) }}
            placeholder="Change Request Type"
            sx={{ width: '100%' }}
          />
        </Box>

          <SettingsTextField title={'Request timeout in ms (leave blank to use default)'} defaultValue={''}
                             value={requestTimeout} onChange={handleRequestTimeout} />

          <SettingsTextField title={'Auto Login Url Field'} value={settingsAutoLogin ?? ''}
            onChange={handleAutoLoginChange} />
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3 }}>
          <IOSSwitch
            formControlLabel={{
              label: 'Add pre-request code',
              labelPlacement: 'end',
              onChange: (e: any) => {
                const checked = !!e?.target?.checked
                setAddPreRequestCode(checked)
                if (!checked) {
                  setError({ ...error, preRequestCode: '' })
                  setTestPreRequestCodeResponse(null)
                  handlePreRequestChange('')
                }
              },
              checked: addPreRequestCode,
              sx: {
                margin: 0,
                '& .MuiTypography-root': {
                  marginRight: '12px',
                  marginLeft: '10px'
                }
              }
            }}
          />
             <Button
              text="Test code"
              sx={{
                borderRadius: '10px',
                maxWidth: '150px'

              }}
              variant='contained'
               disabled={!preRequestCode}
              onClick={testPreRequestCode}
             />
          </Box>
          {addPreRequestCode &&
           <Editor
            value={preRequestCode ?? ''}
            onValueChange={handlePreRequestChange}
            highlight={code => Prism.highlight(code, Prism.languages.javascript, 'javascript')}

            padding={10}
            style={{
              fontFamily: '"Fira Code", "Fira Mono", monospace',
              fontSize: 12,
              backgroundColor: '#f5f5f5',
              borderRadius: '4px',
              minHeight: '200px',
              whiteSpace: 'pre-wrap',
              overflowX: 'hidden'
            }}
           />
          }
          {(addPreRequestCode && testPreRequestCodeResponse) &&
           <Box>
             <Typography variant='body2' sx={{
               color: theme.palette.text1.main,
               marginTop: '10px',
               marginBottom: '10px'
             }}>
               Test code response
             </Typography>
           <JsonView style={{
             backgroundColor: '#f5f5f5'
           }} value={testPreRequestCodeResponse} displayDataTypes={false} displayObjectSize={false}
                     onCopied={(text, value) => onCopy(text)} shortenTextAfterLength={100000} enableClipboard={false} />
           </Box>
          }
          <Typography variant='body2' color='error'>
            {error.preRequestCode}
          </Typography>
        </Box>
        {remoteError && <Typography variant='body1' color='error'>
          {remoteError}
        </Typography>}
        <ActionButtons submitType='submit' submitText='Submit' onCancel={handleClose}
          onSubmit={onSubmit} sx={{ marginBottom: '15px', marginTop: '10px' }} />
      </FlexBox>
    </>
  )
}
