import React, { useEffect } from 'react'
import DrillDownPageLayout from '@/components/Pages/DrillDownReports/DrillDownPageLayout'
import {
  setPresetsState, useGetPresetsConditionsFieldsQuery,
  useGetPresetsGroupFieldsQuery, useGetPresetsQuery
} from '@/store/presets'
import { setTermsState, useGetTermsQuery } from '@/store/terms'
import { useDispatch } from 'react-redux'

export default function DrillDownReportsPage () {
  const dispatch = useDispatch()
  const presetsData = useGetPresetsQuery({ limit: 100, page: 1 })?.data?.presets
  const termsData = useGetTermsQuery({ limit: 100, page: 1 })?.data?.terms
  const presetsGroups = useGetPresetsGroupFieldsQuery({})?.data
  const conditionFieldsOptions = useGetPresetsConditionsFieldsQuery({})?.data

  useEffect(() => {
    presetsData && dispatch(setPresetsState(presetsData))
    termsData && dispatch(setTermsState(termsData))
  }, [presetsData, termsData])

  return (
    <DrillDownPageLayout
      data={{ presetsGroups, conditionFieldsOptions }}
    />
  )
}
