import React, { useEffect } from 'react'
import { Box, useTheme } from '@mui/material'
import Form from '@/components/Form'
import Button from '@/designSystem/Button'
import Autocomplete from '@/components/Form/Autocomplete'
import TextField from '@/components/Form/TextField'
import { type UseFormReturn } from 'react-hook-form'
import { type IDealsSubmit } from '.'
import { setConversions, setConversionsTotalCount, useGetConversionsQuery } from '@/store/conversions'
import FormAutocomplete from '@/components/Form/FormAutocomplete'
import { type Option } from '@/designSystem/Autocomplete/types'
import { countryOptions } from '@/constants'
import { useDispatch } from 'react-redux'
import { useDebounce } from 'use-debounce'
import { LeadState } from '@/enums/leads.enum'

export interface IDealsForm {
  form: UseFormReturn<IDealsSubmit>
  onSubmit: (data: any) => void
  setStep: (step: number) => void
  optionsAdvertisers?: [{ title: string, value: string | null }] | any
  deals?: any
}

export const DealsFormLayout = ({ form, onSubmit, setStep, optionsAdvertisers }: IDealsForm) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const advertiserId = form.watch('advertiserId')?.value
  const countryArr = form.watch('countries')
  const goalType = form.watch('goalType')?.value
  const conversions = form.watch('conversionIds')
  const [debouncedConversions] = useDebounce(conversions, 450)

  const allConversions = useGetConversionsQuery({
    filters: {
      advertiserId: advertiserId ? [advertiserId] : undefined,
      country: countryArr?.length > 0 ? countryArr : undefined,
      goalType: goalType ? [goalType] : undefined
    }
  }).data?.conversions ?? []

  const allIdsConversions = useGetConversionsQuery({
    filters: {
      id: debouncedConversions?.length > 0 ? debouncedConversions : undefined
    }
  }).data?.conversions ?? []

  useEffect(() => {
    if (conversions?.length === 0) {
      dispatch(setConversions([]))
      dispatch(setConversionsTotalCount(0))
    } else if (conversions?.length > 0) {
      form.setValue('advertiserId', { title: '', value: '' })
      form.setValue('goalType', { title: '', value: '' })
      form.setValue('countries', [])
    }
  }, [conversions])

  useEffect(() => {
    if ((conversions?.length > 0) && (allIdsConversions?.length > 0)) {
      dispatch(setConversions(allIdsConversions))
      dispatch(setConversionsTotalCount(allIdsConversions?.length))
    } else if (allConversions?.length > 0) {
      dispatch(setConversions(allConversions))
      dispatch(setConversionsTotalCount(allConversions?.length))
    }
  }, [allConversions, conversions, allIdsConversions])

  const isRevenue = !!form.watch('newRevenue')
  const isConversionUuids = form.watch('conversionIds')?.length > 0

  const conversionsOptions = allConversions?.map((conversion) => {
    return {
      title: conversion?.id,
      value: conversion?.id
    }
  })

  const isDisabled = !(isRevenue && isConversionUuids)

  const handleStep = () => {
    setStep(2)
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Box
        height={'850px'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <Box>
          <Box
            color={theme.palette.text1.main}
            fontSize={18}
            fontWeight={400}
            paddingTop='32px'
          >Advertiser</Box>
          <Autocomplete name="advertiserId"
            options={optionsAdvertisers ?? [{ title: 'Select advertiser', value: null }]}
            // placeholder="Enter advertiser name"
            sx={{ width: '521px' }}
            textFieldProps={{
              input: {
                sx: {
                  input: {
                    padding: '0px 0px 0px 10px !important'
                  }
                }
              }
            }}
          />

          <Box
            color={theme.palette.text1.main}
            fontSize={18}
            fontWeight={400}
            paddingTop='24px'
          >Goal Type</Box>
          <Autocomplete
            options={[{ title: 'Push Lead', value: LeadState.PUSHED_LEAD },
              { title: 'FTD', value: LeadState.FTD }]}
            name="goalType"
            // placeholder="Enter goal type"
            sx={{ width: '521px' }}
            textFieldProps={{
              input: {
                sx: {
                  input: {
                    padding: '0px 0px 0px 10px !important'
                  }
                }
              }
            }}
          />

          <Box
            color={theme.palette.text1.main}
            fontSize={18}
            fontWeight={400}
            paddingTop='24px'
          >Countries</Box>
          <FormAutocomplete
            name="countries"
            options={countryOptions}
            selectedOptions={form.watch('countries')?.length > 0 ? form.watch('countries') : []}
            onChange={(option: Option<string> | null) => {
              option && form.setValue('countries', [...form.watch('countries'), option?.value])
            }}
            deleteField={(value: string) => () => {
              form.setValue('countries', form.watch('countries')?.filter((item: string) => item !== value))
            }}
            placeholder="Enter countries"
            sx={{ width: '521px' }}
            textFieldProps={{
              input: {
                sx: {
                  input: {
                    padding: '0px 0px 0px 10px !important'
                  }
                }
              }
            }}
          />

          <Box
            color={theme.palette.text1.main}
            fontSize={18}
            fontWeight={400}
            paddingTop='24px'
          >Conversions UUIDs</Box>
          <FormAutocomplete
            name="conversionIds"
            options={conversionsOptions?.length === 0 ? [] : conversionsOptions}
            selectedOptions={form.watch('conversionIds')?.length > 0 ? form.watch('conversionIds') : []}
            onChange={(option: Option<string> | null) => {
              option && form.setValue('conversionIds', [...form.watch('conversionIds'), option?.value])
            }}
            deleteField={(value: string) => () => {
              form.setValue('conversionIds', form.watch('conversionIds')?.filter((item: string) => item !== value))
            }}
            onInputChange={(value: string) => {
              if (value === '') {
                form.setValue('conversionIds', [])
              } else if (value?.length >= 1) {
                form.setValue('conversionIds', [value])
              }
            }}
            placeholder="Enter conversions UUIDs"
            sx={{ width: '521px' }}
            textFieldProps={{
              input: {
                sx: {
                  input: {
                    padding: '0px 0px 0px 10px !important'
                  }
                }
              }
            }}
          />

          <Box
            color={theme.palette.text1.main}
            fontSize={18}
            fontWeight={400}
            paddingTop='24px'
          >New Revenue ($)</Box>
          <TextField name='newRevenue' placeholder='Enter value' type='number' />

        </Box>
        <Button
          sx={{
            borderRadius: '16px',
            height: '40px',
            width: '100%',
            padding: '8px 16px',
            marginBottom: '35px'
          }}
          variant='contained'
          text="Next Step"
          textVariant='subtitle1'
          onClick={handleStep}
          disabled={isDisabled} />

      </Box>
    </Form>
  )
}
