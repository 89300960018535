import React, { useState } from 'react'
import { Typography, TextField, useTheme } from '@mui/material'
import { type FilterItemProps } from '.'
import SelectedValues from './components/SelectedValues'

const TextFilterItem = (props: FilterItemProps) => {
  const { onChange, isMultiple, title, name } = props
  const { spacing, palette } = useTheme()
  const [value, setValue] = useState<string>(props.isMultiple ? '' : props.selectedValues as string)

  const textFieldSx = {
    '& .MuiInputBase-root': {
      height: '40px',
      borderRadius: '8px',
      width: '330px',
      background: 'white'
    },
    '& .MuiOutlinedInput-root:hover': {
      '& > fieldset': {
        outline: 'none'
      }
    },
    '& .Mui-disabled:hover': {
      '& > fieldset': {
        outline: 'none'
      }
    },
    '& .Mui-disabled:active': {
      '& > fieldset': {
        outline: 'none'
      }
    },
    '& fieldset': { outline: 'none' }
  }

  const onSave = () => {
    onChange(name as any, value, isMultiple)
    isMultiple && setValue('')
  }

  return (
    <>
      <Typography variant="body1" sx={{ marginTop: spacing(4) }} color={palette.card.main}>{title}</Typography>
      <TextField
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onSave}
        onKeyDown={(e) => e.key === 'Enter' && onSave()}
        sx={textFieldSx}
      />
       <SelectedValues {...props} />
    </>)
}

export default TextFilterItem
