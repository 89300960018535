import React, { useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@/designSystem/IconButton'
import Button from '@/designSystem/Button'
import { ReactComponent as PlusIcon } from '@/assets/icons/add-plus-white.svg'
import useTheme from '@mui/material/styles/useTheme'
import FilterIcon from '@/designSystem/Pages/Advertisers/Icons/Filter'
import PostBackFiltersPopup from '../Postbacks/Popups/PostBackFiltersPopup'
import AddPostBackPopup from './Popups/PostBackPopup/AddPostBackPopup'
// interface Props
// }
export default function RightActionButtons () {
  const [isOpenFilterPopup, setIsOpenFilterPopup] = useState(false)
  const [isAddPopup, setIsAddPopup] = useState(false)

  // const timeResetsData = useGetTimeResetsQuery({}).data ?? []

  const theme = useTheme()
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={theme.spacing(4)}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={theme.spacing(3)}
        >
          <IconButton onClick={() => setIsOpenFilterPopup(true)}>
          <FilterIcon />
        </IconButton>
        </Box>
        <Button
            text={<Box
              display='flex'
              alignItems='center'
              justifyContent={'space-between'}
              width={'100%'}
              fontSize={16}
              fontWeight={500}
              whiteSpace={'nowrap'}
            >
              <PlusIcon style={{ marginRight: '8px' }} />
              Add New</Box>}
            variant='contained'
            sx={{
              width: '130px',
              height: '37px',
              borderRadius: '12px',
              backgroundColor: '#4141D5',
              padding: '8px 20px',
              paddingLeft: '8px'
            }}
            onClick={() => setIsAddPopup(true)}
          />
      </Box>
      {isOpenFilterPopup && (
        <PostBackFiltersPopup handleClose={() => setIsOpenFilterPopup(false)} />
      )}
      {isAddPopup && (
        <AddPostBackPopup handleClose={() => setIsAddPopup(false)} nodeParentId={null}/>
      )}
    </>
  )
}
