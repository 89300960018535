import React from 'react'

export default function SettingsIcon () {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="12" fill="#4141D5"/>
      <path d="M20 12.4994C19.77 12.4994 19.55 12.5094 19.32 12.5294L17.91 10.6694C17.61 10.2694 17.05 10.1594 16.61 10.4094L13.38 12.2794C13.1682 12.3993 13.0081 12.5932 12.9306 12.8239C12.8531 13.0546 12.8636 13.3059 12.96 13.5294L13.87 15.6794C13.61 16.0494 13.38 16.4394 13.19 16.8494L10.88 17.1394C10.38 17.1994 10 17.6294 10 18.1294L10 21.8594C10 22.3694 10.38 22.7994 10.88 22.8594L13.19 23.1494C13.38 23.5594 13.61 23.9494 13.87 24.3194L12.96 26.4694C12.76 26.9294 12.94 27.4694 13.38 27.7194L16.62 29.5894C17.06 29.8394 17.61 29.7294 17.92 29.3294L19.33 27.4694C19.779 27.5108 20.231 27.5108 20.68 27.4694L22.09 29.3294C22.39 29.7294 22.95 29.8394 23.39 29.5894L26.62 27.7194C27.06 27.4694 27.24 26.9294 27.04 26.4694L26.13 24.3194C26.39 23.9494 26.62 23.5594 26.81 23.1494L29.12 22.8594C29.62 22.7994 30 22.3694 30 21.8694L30 18.1394C30 17.6394 29.62 17.2094 29.12 17.1494L26.81 16.8594C26.62 16.4494 26.39 16.0594 26.13 15.6894L27.04 13.5394C27.24 13.0794 27.06 12.5394 26.62 12.2894L23.39 10.4194C22.95 10.1694 22.4 10.2794 22.09 10.6794L20.68 12.5394C20.45 12.5094 20.23 12.4994 20 12.4994ZM23.5 19.9594C23.5 21.8894 21.93 23.4594 20 23.4594C18.07 23.4594 16.5 21.8894 16.5 19.9594C16.5 18.0294 18.07 16.4594 20 16.4594C21.93 16.4594 23.5 18.0294 23.5 19.9594Z" fill="#F1F3FF"/>
    </svg>
  )
}
