import React from 'react'

export default function UncheckedCheckbox () {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" fill="white"/>
      <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="#000931"/>
    </svg>
  )
}
