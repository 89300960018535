import React, { useEffect } from 'react'

import { useTheme, Typography, Box, IconButton, type SxProps } from '@mui/material'
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import AdvertiserLogs, { type IAdvertiserLogs } from './AdvertiserLogs'
import { useSearchLogsQuery } from '@/store/advertisersLogs'
import FlexBox from '@/designSystem/FlexBox'

interface IAccPaginationNavigation {
  setQueryParams: (params: { page: number, limit: number }) => void
  queryParams: { page: number, limit: number }
  totalCount: number
}

const PaginationNavigation = ({ setQueryParams, queryParams, totalCount }: IAccPaginationNavigation) => {
  const { spacing, palette } = useTheme()

  const { page = 1, limit } = queryParams
  const totalPages = Math.ceil(totalCount / limit)

  const iconSx: SxProps = {
    width: '20px',
    height: '20px'
  }

  return (
    <Box
      padding={spacing(1.5, 3)}
      border={`1px solid ${palette.primary.main}`}
      borderRadius="12px"
      display="flex"
      alignItems="center"
      gap={spacing(3)}
      sx={{ backgroundColor: palette.card.main }}
    >
      <IconButton
        sx={iconSx}
        disabled={page === 1}
        onClick={() => setQueryParams({
          ...queryParams,
          page: page - 1
        })}
      >
        <KeyboardArrowLeftRoundedIcon />
      </IconButton>
      <Typography variant='headline'>
        {page}
      </Typography>
      <IconButton
        sx={iconSx}
        disabled={page === totalPages}
        onClick={() => setQueryParams({
          ...queryParams,
          page: page + 1
        })}
      >
        <KeyboardArrowRightRoundedIcon />
      </IconButton>
    </Box>
  )
}

export default function AdvertisersLogsPagination ({ advertiserId, setLogs }: {
  advertiserId: string
  setLogs: (logs: IAdvertiserLogs[]) => void
}) {
  const { spacing, palette } = useTheme()
  const [queryParams, setQueryParams] = React.useState({ page: 1, limit: 50 })
  const { data, isError, isFetching }: any = useSearchLogsQuery({ limit: queryParams.limit, page: queryParams.page, sortField: 'createdAt', filter: { advertiserId } })
  const currentLogs = data?.logs ?? []
  const [totalCount, setTotalCount] = React.useState(currentLogs.length)

  useEffect(() => {
    setLogs(data?.logs)
    setTotalCount(data?.totalCount)
  }, [data])
  console.log('isFetching', isFetching)
  console.log('isError', isError)
  console.log('totalCount', totalCount)
  if (isFetching) {
    return null
  }
  if (isError || !currentLogs.length) {
    return (
      <Typography variant="h1">
        No logs yet
      </Typography>
    )
  }

  const handleQueryParams = (params: { page: number, limit: number }) => { setQueryParams(params) }

  const firstElement = (queryParams?.page - 1) * queryParams?.limit + 1
  const lastElement = Math.min(queryParams?.page * queryParams?.limit, totalCount)
  const range = `${firstElement} to ${lastElement} Elements / ${totalCount} Total`
  const totalPages = Math.ceil(totalCount / queryParams?.limit)

  return (
    <FlexBox column gap={4}>
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="row"
      gap={spacing(12)}
      paddingBottom={'15px'}
    >
      <Typography
        variant='headline'
        color={palette.text1.main}
      >
        {range}
      </Typography>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="row"
        gap={spacing(3)}
      >
        <Typography variant='headline'>
          Page:
        </Typography>
        <PaginationNavigation queryParams={queryParams}
          setQueryParams={handleQueryParams} totalCount={totalCount} />
        <Typography variant='headline'>
          of {totalPages}
        </Typography>
      </Box>
    </Box>
      <AdvertiserLogs advertiserLogs={currentLogs} />
    </FlexBox>
  )
}
