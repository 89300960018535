/* eslint-disable no-lone-blocks */
import React, { type ReactNode, useRef, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { TreeView as MUTreeView } from '@mui/x-tree-view/TreeView'
import { TreeItem, type TreeItemProps } from '@mui/x-tree-view/TreeItem'
import { ERenderer, EType, type IItem } from './types'
import styled from '@emotion/styled'
import { Box, Menu, MenuItem, Typography, useTheme } from '@mui/material'
import AdvertiserIcon from '@/designSystem/Pages/TrafficDistribution/RotationControl/icons/Advertiser'
import RotationFolderIcon from '@/designSystem/Pages/TrafficDistribution/RotationControl/icons/RotationFolder'
import FolderIcon from '@/designSystem/Pages/LeadConvertions/AdvertiserRevenue/icons/FolderIcon'
import RevenueIcon from '@/designSystem/Pages/LeadConvertions/AdvertiserRevenue/icons/RevenueIcon'
import { ReactComponent as ArrowBottomIcon } from '@/assets/icons/arrow-bottom-round.svg'
import { ReactComponent as ArrowUpIcon } from '@/assets/icons/arrow-up-round.svg'
import FlexBox from '@/designSystem/FlexBox'
import IconButton from '@/designSystem/IconButton'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import Close from '@mui/icons-material/Close'
import Edit from '@mui/icons-material/Edit'
import AddIcon from '@/designSystem/Icons/Add'
import { CountryField } from '@/designSystem/Table/Fields'
import Popup from '@/designSystem/Popup'
import LogsWrapper from './LogsWrapper'
import { useGetRotationsQuery } from '@/store/rotationControl'
import { selectRotationControlQueryParams } from '@/store/queryParams'
import { useSelector } from 'react-redux'
import LinearProgress from '@mui/material/LinearProgress'
import RejectedLeadsWrapper from '@/designSystem/TreeView/RejectedLeadsWrapper'
import { RotationLogType } from '@/enums/rotations.enum'
import { ToastContainer } from 'react-toastify'
import {
  DragDropContext,
  Draggable,
  type DraggableProvided,
  type DraggableStateSnapshot,
  Droppable
} from 'react-beautiful-dnd'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

const PQL = (props: { children: string }) => {
  const theme = useTheme()

  const getTime = (query: string) => {
    const regex = /([+]\d*)/
    const timeRegex = regex.exec(query)
    const time = timeRegex ? timeRegex[0] : ''
    const formattedTime = `${time.slice(0, 3)}:${time.slice(3)}`
    return formattedTime
  }

  const getDays = (query: string) => {
    const regex = /\[.*\]/
    const daysRegex = regex.exec(query)
    const days = daysRegex ? daysRegex[0] : ''
    const result = days.replace('[', '').replace(']', '')
      .split('\'').join('')
      .split(',').join(', ')
    return result
  }

  const getHours = (query: string) => {
    const regex = /'\S*'/g
    const daysRegex = query.match(regex)
    if (daysRegex) {
      const times = daysRegex.map((item) => item.replaceAll('\'', ''))
      return times.join(' - ')
    }
    return ''
  }

  const query = props.children
  if (query.includes('dayOfWeek')) {
    const time = getTime(query)
    const days = getDays(query)
    return <FlexBox sx={{ gap: theme.spacing(2) }}>Day | <FlexBox sx={{ color: theme.palette.green.main, display: 'inline' }}>{time}</FlexBox> {days}</FlexBox>
  }
  if (query.includes('hourOfDay')) {
    const time = getTime(query)
    const hours = getHours(query)
    return <FlexBox sx={{ gap: theme.spacing(2) }}>Hour | <FlexBox sx={{ color: theme.palette.green.main, display: 'inline' }}>{time}</FlexBox> {hours}</FlexBox>
  }
  return <Typography>PQL | {query}</Typography>
}

interface IBasicMenuProps {
  id: string
  cloneElement?: (id: string) => void
  getLogs?: () => boolean
  item?: IItem
  onAdd?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>, element: IItem) => void
}
export const BasicMenu = (props: IBasicMenuProps) => {
  const [isLogsVisible, setLogsVisible] = useState<boolean>(false)
  const [logsType, setLogsType] = useState<RotationLogType | null>(null)
  const [rotationItem, setRotationItem] = useState<IItem | null>(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onClone = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation()
    if (props?.item?.type === 'advertiser') {
      const itemWithOutAdvrtiserId = { ...props.item, advertiserId: null }
      if (props.onAdd) {
        props.onAdd(e, itemWithOutAdvrtiserId)
      }
    } else if (props.cloneElement) {
      props.cloneElement(props.id)
    }
    handleClose()
  }

  const onLogs = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, logType: RotationLogType, rotationItem?: IItem) => {
    e.stopPropagation()
    setLogsType(logType)
    setLogsVisible(true)
    rotationItem && setRotationItem(rotationItem)
    handleClose()
  }
  const renderLogsPopup = () => {
    if (!isLogsVisible || !logsType) return null

    const WrapperComponent = logsType === 'logs' ? LogsWrapper : RejectedLeadsWrapper
    const title = logsType === 'logs' ? 'Logs' : 'Reject Logs'

    return (
      <Popup open handleClose={() => setLogsVisible(false)} title={title}>
        <WrapperComponent id={props.id} rejectedCapCount={rotationItem?.rejectedCapCount ?? 0} />
      </Popup>
    )
  }
  return (
    <>
      <div>
        <IconButton id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreHoriz sx={{ fill: 'white' }} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          {props.cloneElement && <MenuItem onClick={onClone}>Clone</MenuItem>}
          <MenuItem onClick={(ev) => onLogs(ev, RotationLogType.LOGS)}>Logs</MenuItem>
          {(props?.item?.rejectedCapCount ?? 0) > 0 && <MenuItem onClick={(ev) => onLogs(ev, RotationLogType.REJECTED_LEADS, props?.item)}>Reject Logs</MenuItem>}
        </Menu>
      </div>
      {renderLogsPopup()}
    </>
  )
}

interface IDeleteWithConfirmationProps {
  onDelete: (e: React.MouseEvent<HTMLLIElement | SVGSVGElement, MouseEvent>) => void
}
export const DeleteWithConfirmation = (props: IDeleteWithConfirmationProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onDelete = (e: React.MouseEvent<HTMLLIElement | SVGSVGElement, MouseEvent>) => {
    e.stopPropagation()
    props.onDelete(e)
  }

  return (
    <>
      <div>
        <IconButton id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ background: 'transparent', '&:hover': { background: 'none' } }}
        >
          <Close sx={{ fill: '#ff0000' }} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem onClick={onDelete}>Confirm deletion</MenuItem>
          <MenuItem onClick={handleClose}>Cancel</MenuItem>
        </Menu>
      </div>
    </>
  )
}

const Headers = () => {
  const { palette, spacing } = useTheme()

  const headers = ['Name', 'Passing Rule', 'Settings', '']

  return <FlexBox sx={{ paddingLeft: spacing(4) }}>
    {headers.map((item) => <Typography key={item} variant='h1' color={palette.text2.main} sx={{ flex: `0 0 ${100 / headers.length}%` }}>
      {item}
    </Typography>)}
  </FlexBox>
}

const Type = (props: { node: IItem }) => {
  switch (props.node.type) {
    case EType.RotationFolder: return <RotationFolderIcon />
    case EType.Advertiser: return <AdvertiserIcon />
    case EType.Folder: return <FolderIcon />
    case EType.Revenue: return <RevenueIcon />
    default: return null
  }
}

interface IRow {
  item: IItem
  actions: IActions
  draggableProvided?: DraggableProvided
}
interface FillRateProps {
  title: string
  value: number
  total: number
  color: string
}

const FillRate: React.FC<FillRateProps> = ({ value, total, color, title }) => {
  const percentage = (value / total) * 100
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 200, marginTop: 0 }}>
      <Box sx={{ flex: '0 0 100px', backgroundColor: '#F2F2F2', height: 12, borderRadius: 4, position: 'relative', overflow: 'hidden' }}>
        <LinearProgress
          variant="determinate"
          value={percentage}
          sx={{
            height: '100%',
            borderRadius: 4,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: 'transparent',
            '& .MuiLinearProgress-bar': {
              backgroundColor: color,
              borderRadius: 4
            }
          }}
        />
      </Box>

      <Typography sx={{
        fontSize: '15px',
        lineHeight: '15px',
        height: '19px',
        marginLeft: 2,
        color,
        fontWeight: 'bold',
        minWidth: '50px'
      }}>
        {value}/{total}
      </Typography>
      <Typography sx={{
        fontSize: '15px',
        lineHeight: '15px',
        height: '19px',
        marginLeft: 4,
        color: '#999a9e'
      }}>
        {title}
      </Typography>
    </Box>

  )
}

const Row = ({ item, actions, draggableProvided }: IRow) => {
  const { spacing, palette } = useTheme()
  const provided = draggableProvided as DraggableProvided
  const query = useSelector(selectRotationControlQueryParams)
  const rotations = useGetRotationsQuery({ filters: query.filters }).data ?? []

  const isPriorityVisible = item.type === EType.RotationFolder || rotations.find((i) => i.id === item.id)

  const onDelete = async (e: React.MouseEvent<HTMLLIElement | SVGSVGElement, MouseEvent>, id: string) => {
    e.stopPropagation()
    if (actions.deleteElement) {
      await actions.deleteElement(id)
    }
  }
  const onAdd = async (e: React.MouseEvent<SVGElement | HTMLLIElement, MouseEvent>, element: IItem) => {
    e.stopPropagation()
    actions.selectElement(element)
    if (actions.selectMode) {
      actions.selectMode('create')
    }
  }
  const onEdit = async (e: React.MouseEvent<SVGElement>, element: IItem) => {
    e.stopPropagation()
    actions.selectElement(element)
    if (actions.selectMode) {
      actions.selectMode('edit')
    }
  }

  const onMoveUp = (e: React.MouseEvent<SVGElement>, id: string) => {
    e.stopPropagation()
    if (actions?.moveUpElement && id) {
      actions.moveUpElement(id)
    }
  }
  const onMoveBottom = (e: React.MouseEvent<SVGElement>, id: string) => {
    e.stopPropagation()
    if (actions?.moveDownElement && id) {
      actions.moveDownElement(id)
    }
  }

  const getCountries = (query: string) => {
    const regex = /\[.*\]/
    const countryRegex = regex.exec(query)
    const country = countryRegex ? countryRegex[0] : ''
    const result = country.replace('[', '').replace(']', '')
      .split('\'').join('').toLocaleLowerCase()
      .split(',')
    return result
  }

  return <FlexBox >
    <Box
      sx={{ display: 'flex', gap: spacing(3), flex: '0 0 25%', alignItems: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
      {/* {!!item.priority && isPriorityVisible && <Box sx={{ */}
      {/*  width: 32, */}
      {/*  height: 32, */}
      {/*  display: 'flex', */}
      {/*  alignItems: 'center', */}
      {/*  justifyContent: 'center', */}
      {/*  borderRadius: '4px', */}
      {/*  background: '#F1F3FF', */}
      {/*  flexShrink: 0 */}
      {/* }}> */}
      {/*  {item.priority} */}
      {/* </Box>} */}
      <Box
        {...provided?.dragHandleProps ?? {}}
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          cursor: 'grab',
          marginRight: 1
        }}
        // onClick={(e) => {
        //   e.stopPropagation()
        // }}
      >
        <DragIndicatorIcon />
      </Box>
      <Type node={item} />
      <Box sx={{
        display: 'flex',
        alignItems: 'center'
      }}>{item.name}</Box>
    </Box>
    <Box sx={{ display: 'flex', flex: '0 0 25%', alignItems: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
      {item.renderer === ERenderer.Country && getCountries(item.pql).map((item) => <CountryField key={item} countryCode={item} />)}
      {item.renderer === ERenderer.PassAll && <Typography>PQL | Pass All</Typography>}
      {item.renderer === ERenderer.Custom && <PQL>{item.pql}</PQL>}
    </Box>
    <Box sx={{ display: 'flex', flex: '0 0 25%', flexDirection: 'column', alignItems: 'flex-start', whiteSpace: 'nowrap', justifyContent: 'center', textOverflow: 'ellipsis', overflow: 'hidden' }}>

      {item.capAmount && <FillRate value={item.capCount ?? 0} title="Push Cap" total={item.capAmount} color={palette.orange1.main}></FillRate>}
      {item.rejectedCap && <FillRate value={item.rejectedCapCount ?? 0} title="Reject Cap" total={item.rejectedCap} color={palette.cyen1.main}></FillRate>}
      {item.rejectedTimeoutCap && <FillRate value={item.rejectedTimeoutCapCount ?? 0} title="Timeout Cap" total={item.rejectedTimeoutCap} color={palette.red2.main}></FillRate>}
    </Box>
    <Box sx={{ display: 'flex', flex: '0 0 25%', alignItems: 'center', justifyContent: 'flex-end', gap: spacing(2) }}>
      <ArrowBottomIcon onClick={(e) => onMoveBottom(e, item.id)} />
      <ArrowUpIcon onClick={(e) => onMoveUp(e, item.id)} />
      <DeleteWithConfirmation onDelete={(e) => onDelete(e, item.id)} />
      <Edit onClick={(e) => onEdit(e, item)} />
      <BasicMenu id={item.id} cloneElement={actions.cloneElement} getLogs={actions.getLogs}
        item={item} onAdd={onAdd}/>
      {item?.level !== 4 && item?.type !== 'advertiser' && <AddIcon onClick={(e) => onAdd(e, item)} />}
    </Box>
  </FlexBox>
}

const StyledTreeItem = ({ className, ...props }: TreeItemProps) => (
  <TreeItem className={className} {...props} />
)

const CustomTreeItem = styled(StyledTreeItem)(({ theme }) => ({
  margin: '12px 0 0 0',
  '& .MuiTreeItem-iconContainer': {
    width: 24,
    height: 24
  },
  '& .MuiTreeItem-iconContainer > svg': {
    width: 24,
    height: 24,
    display: 'flex'
  },
  '& .MuiTreeItem-content': {
    height: 64,
    boxSizing: 'border-box',
    border: '1px solid transparent',
    backgroundColor: 'white',
    borderRadius: '16px'
  },
  '& .MuiTreeItem-content.dragging': {
    border: '1px solid #EDB077'
  },
  '& .MuiTreeItem-content:hover': {
    border: '1px solid black',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
  },
  '& .Mui-selected': {
    backgroundColor: 'unset'
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24
  }
}))
//
// const renderDragAndDropTree2 = (nodes: IItem[], actions: IActions, parentNode: IItem | null, CustomNode?: (el: any) => ReactNode | null) => {
//   const [treeItems, setTreeItems] = useState(nodes)
//
//   const onDragEndNested = (result: any) => {
//     const { destination, source } = result
//     console.log('onDragEndNested, destination, source', destination, source)
//     if (!destination) return
//
//     const sourceParentId = source.droppableId.split('-').pop()
//     const destinationParentId = destination.droppableId.split('-').pop()
//     console.log('onDragEndNested sourceParentId', sourceParentId)
//     console.log('onDragEndNested destinationParentId', destinationParentId)
//
//     const reorderedItems = Array.from(treeItems)
//     if (reorderedItems.length) {
//       console.log('onDragEndNested reorderedItems', JSON.stringify(reorderedItems.map(item => ({
//         name: item.name
//       }))))
//     }
//
//     const [removed] = reorderedItems.splice(source.index, 1)
//     reorderedItems.splice(destination.index, 0, removed)
//
//     setTreeItems(reorderedItems)
//
//     if (actions?.reorderItems) {
//       actions.reorderItems(reorderedItems)
//     }
//   }
//   return (<DragDropContext onDragEnd={onDragEndNested} >
//    <Droppable droppableId={parentNode ? `tree-item-${parentNode.id}` : 'treeview'}>
//      {(provided) => (
//        <Box {...provided.droppableProps} ref={provided.innerRef}>
//
//          { nodes.map((el, index) => (
//            <Draggable
//              key={el.id}
//              draggableId={el.id}
//              index={index}
//            >
//              {(provided, snapshot) => (
//                <Box
//                  ref={provided.innerRef}
//                  {...provided.draggableProps}
//                  {...provided.dragHandleProps}
//
//                  style={{
//                    ...provided.draggableProps.style,
//                    backgroundColor: snapshot.isDragging ? 'lightgreen' : 'inherit'
//                  }}
//                >
//                   <CustomTreeItem key={el.id} nodeId={el.id?.toString()}
//                                   label={CustomNode ? CustomNode(el) : <Row actions={actions} item={el}/>}>
//                     {Array.isArray(el.children) && el.children.length > 0
//                       ? renderDragAndDropTree2(el.children, actions, el, CustomNode)
//                       : null}
//                   </CustomTreeItem>
//                </Box>
//              )}
//            </Draggable>
//          ))}
//        </Box>
//      )}
//       </Droppable>
//     </DragDropContext>
//
//   )
// }

const renderTree = (nodes: IItem[], actions: IActions, CustomNode?: (el: any) => ReactNode | null) =>
  nodes.map((el, index) => {
    return (
      <CustomTreeItem key={el.id} nodeId={el.id?.toString()} label={CustomNode ? CustomNode(el) : <Row actions={actions} item={el} />}>
        {Array.isArray(el.children) && el.children.length > 0
          ? renderTree(el.children, actions, CustomNode)
          : null}
      </CustomTreeItem>
    )
  })
export interface IActions {
  moveUpElement?: (id: string) => any
  moveDownElement?: (id: string) => any
  reorderItems?: (items: any[]) => any
  deleteElement?: (id: string) => any
  selectElement: (value: any) => void
  cloneElement?: (id: string) => void
  selectMode?: (mode: 'create' | 'edit') => void
  getLogs?: () => boolean
  setErrorMessage?: (value: string) => void
}
interface Props {
  expanded: string[]
  items: any[]
  handleToggle: (e: React.SyntheticEvent<Element, Event>, nodeIds: string[]) => void
  actions: IActions
  CustomLabel?: (item: any) => ReactNode
}
const createItemSubItemMap = (items: IItem[]): Record<string, IItem[]> => {
  const map: Record<string, IItem[]> = {}

  const addItemsToMap = (item: IItem) => {
    map[item.id] = item.children ?? []

    if (item.children && item.children.length > 0) {
      item.children.forEach((child) => {
        addItemsToMap(child)
      })
    }
  }

  items.forEach((item) => {
    addItemsToMap(item)
  })

  return map
}

export function TreeWithDragAndDrop (props: Props) {
  const { items, actions } = props
  const itemSubItemMap = createItemSubItemMap(items)
  const [treeItems, setTreeItems] = useState(items ?? [])

  const onDragEnd = (result: any) => {
    const { destination, source } = result
    if (!destination) return

    const destinationParentId = destination.droppableId.split('_').pop()

    const reorderedItems = destinationParentId === 'treeview' ? Array.from(treeItems) : Array.from(itemSubItemMap[destinationParentId] ?? [])
    if (!reorderedItems.length) return
    const [removed] = reorderedItems.splice(source.index, 1)
    reorderedItems.splice(destination.index, 0, removed)
    if (destinationParentId === 'treeview') {
      setTreeItems(reorderedItems)
    }

    if (actions?.reorderItems) {
      actions?.reorderItems(reorderedItems)
    }
  }

  const renderDragAndDropTree = (nodes: IItem[], parentNode: IItem | null, CustomNode?: (el: any) => ReactNode | null) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={parentNode ? `treeitem_${parentNode.id}` : 'treeview'}>
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {nodes.map((el, index) => (
                <Draggable
                                                  key={el.id} draggableId={el.id} index={index} >
                  {(provided, snapshot) => {
                    const draggableStyle = provided.draggableProps.style as any
                    return (
                    <Box
                       ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...provided.draggableProps.style,
                        left: snapshot.isDragging
                          ? `${parseFloat(draggableStyle?.left || '0') + 100}px`
                          : draggableStyle?.left,
                        top: snapshot.isDragging
                          ? `${parseFloat(draggableStyle?.top || '0') * 1.30}px`
                          : draggableStyle?.top
                      }}
                    >
                        <CustomTreeItem
                          classes = {{ content: snapshot.isDragging ? 'dragging' : '' }}
                           key={el.id}
                          nodeId={el.id?.toString()}
                           label={
                            CustomNode ? CustomNode(el) : <Row actions={actions} item={el} draggableProvided={provided} />
                          }
                        >
                          {Array.isArray(el.children) && el.children.length > 0
                            ? renderDragAndDropTree(el.children, el, CustomNode)
                            : null}
                        </CustomTreeItem>
                     </Box>
                    )
                  }}
                </Draggable>
              ))}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
  return (
     <>
            <Headers />
            <ToastContainer/>
            <MUTreeView
              // sx={{ transform: 'scale(1.15)', transformOrigin: 'left top' }}
              aria-label="file system navigator"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon height={24} width={24} />}
              expanded={props.expanded}
              onNodeToggle={props.handleToggle}
              selected={null}
            >
              {renderDragAndDropTree(items ?? [], null, props.CustomLabel ? props.CustomLabel : undefined)}
            </MUTreeView>
     </>
  )
}

export function TreeView (props: Props) {
  const data = props.items

  return <>
    <Headers />
    <ToastContainer/>
    <MUTreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon height={24} width={24} />}
      expanded={props.expanded}
      onNodeToggle={props.handleToggle}
      selected={null}
    >
      {renderTree(data ?? [], props.actions, props.CustomLabel ? props.CustomLabel : undefined)}
    </MUTreeView>
  </>
}

/* not used react-dnd not used --> */
const ItemType = {
  TREE_ITEM: 'treeItem'
}

interface DraggableItemProps {
  item: IItem
  index: number
  moveItem: (fromIndex: number, toIndex: number) => void
  children: ReactNode
}

const DraggableItem = ({ item, index, moveItem, children }: DraggableItemProps) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: ItemType.TREE_ITEM,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  const [, dropRef] = useDrop({
    accept: ItemType.TREE_ITEM,
    hover (draggedItem: { index: number }) {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index)
        draggedItem.index = index
      }
    }
  })
  const ref = useRef(null)
  dropRef(ref)

  return (
    <Box

      ref={ref}
      style={{
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1
      }}
    >
      {children}
    </Box>
  )
}
const DropContainer = ({ children }: { children: React.ReactNode }) => {
  const [, dropRef] = useDrop({
    accept: ItemType.TREE_ITEM,
    drop: (item: any) => {
      console.log('on drop', item)
    },
    hover (draggedItem: { index: number }) {
      console.log('draggedItem', draggedItem)
    }
  })

  const ref = useRef<HTMLDivElement | null>(null)
  dropRef(ref)

  return <Box ref={ref}>{children}</Box>
}
export function TreeWithDragAndDropDND (props: Props) {
  const { items, actions } = props
  const [treeItems, setTreeItems] = useState(items ?? [])

  const moveItem = (fromIndex: number, toIndex: number) => {
  }

  const renderDragAndDropTree = (nodes: IItem[], parentNode: IItem | null, CustomNode?: (el: any) => ReactNode | null) => {
    return (nodes.map((el, index) => (
          <DraggableItem key={el.id} item={el} index={index} moveItem={moveItem}>
            <Box>{el.name}</Box>
            <CustomTreeItem
              nodeId={el.id?.toString()}
              label={CustomNode ? CustomNode(el) : <Row actions={actions} item={el} />}
            >
            </CustomTreeItem>
          </DraggableItem>
    ))

    )
  }

  return (
      <>
        <Headers />
        <ToastContainer />
        <MUTreeView
          aria-label="file system navigator"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon height={24} width={24} />}
          expanded={props.expanded}
          onNodeToggle={props.handleToggle}
          selected={null}
        >
          <DndProvider backend={HTML5Backend}>
           {renderDragAndDropTree(treeItems, null, props.CustomLabel)}
          </DndProvider>
        </MUTreeView>
      </>
  )
}
/* <-- not used react-dnd */
