import React from 'react'
import FlexBox from '@/designSystem/FlexBox'
import { CountryField } from '@/designSystem/Table/Fields'
import { type IActions } from '@/designSystem/TreeView'
import { type IItem } from '@/designSystem/TreeView/types'
import Edit from '@mui/icons-material/Edit'
import { Box, useTheme } from '@mui/material'

interface IRow {
  item: any
  actions: IActions
}

const RevenueRow = ({ item, actions }: IRow) => {
  const { spacing } = useTheme()

  const onEdit = async (e: React.MouseEvent<SVGElement>, element: IItem) => {
    e.stopPropagation()
    actions.selectElement(element)
    if (actions.selectMode) {
      actions.selectMode('edit')
    }
  }

  const isRevenue = item.revenue

  return <FlexBox>
      <Box sx={{ display: 'flex', gap: spacing(3), flex: '0 0 25%', alignItems: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
      {item.name ?? ''}
      </Box>
      <Box sx={{ display: 'flex', flex: '0 0 25%', alignItems: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {item.country ? (item.country !== 'no_country' ? <CountryField countryCode={item.country.toLocaleLowerCase()} /> : 'No country') : null}
        {item.goalType ? <FlexBox sx={{ padding: `${spacing(2)} ${spacing(4)}`, borderRadius: spacing(2), background: '#06D8BC', color: 'white' }}>{item.goalType}</FlexBox> : null}
      </Box>
      <Box sx={{ display: 'flex', flex: '0 0 25%', alignItems: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {item.revenue ? item.revenue : ''}
      </Box>
      <Box sx={{ display: 'flex', flex: '0 0 25%', alignItems: 'center', justifyContent: 'flex-end', gap: spacing(2) }}>
        {isRevenue && <Edit sx={{ marginRight: spacing(2) }} onClick={(e) => onEdit(e, item)} />}
      </Box>
    </FlexBox>
}

export default RevenueRow
