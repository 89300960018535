import React, { useMemo } from 'react'
import Chip from '@/components/Advertisers/Filters/Chip'
import { useDispatch, useSelector } from 'react-redux'
import { Box, useTheme } from '@mui/material'
import cloneDeep from 'lodash.clonedeep'
import unset from 'lodash.unset'
import { type IDealFilters } from '@/store/deals'
import { format } from 'date-fns'
import { selectDealsQueryParams, setDealsQueryParams } from '@/store/queryParams'

const Bar = ({ children }: { children: React.ReactNode }) => {
  const { spacing, palette } = useTheme()
  return (
    <Box
      display={'flex'}
      flexWrap={'wrap'}
      rowGap={spacing(3)}
      gap={spacing(3)}
      justifyContent="flex-start"
      padding={spacing(3, 4)}
      maxWidth="1750px"
      borderRadius="12px"
      sx={{
        backgroundColor: palette.card.main
      }}
    >
      {children}
    </Box>
  )
}

const areFiltersExist = (filters?: IDealFilters) => {
  if (!filters) {
    return false
  }
  return (
    !!filters?.reason ||
    !!filters?.newRevenue ||
    !!filters?.countries ||
    !!filters?.advertiserId ||
    !!filters?.userName ||
    !!filters?.userId ||
    !!filters?.createdTo?.from ||
    !!filters?.createdTo?.to
  )
}

export default function DealsFilterBar () {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectDealsQueryParams)

  const isParamExist = useMemo(() => areFiltersExist(queryParams?.filters), [queryParams?.filters])

  if (!isParamExist) {
    return null
  }

  const onDeleteParam = (label: string) => () => {
    const params = cloneDeep(queryParams)
    unset(params, `filters.${label}`)
    dispatch(setDealsQueryParams(params))
  }

  const filters = queryParams.filters

  return (
    <Bar>
      {filters?.userId && (
        <Chip
          label={`UIUD: "${filters.userId}"`}
          onDelete={onDeleteParam('userId')}
        />
      )}
      {filters?.reason && (
        <Chip
          label={`Reason: "${filters.reason}"`}
          onDelete={onDeleteParam('reason')}
        />
      )}
      {filters?.newRevenue && (
        <Chip
          label={`New Revenue: "${filters.newRevenue}"`}
          onDelete={onDeleteParam('newRevenue')}
        />
      )}
      {filters?.countries && (
        <Chip
          label={`Countries: "${filters.countries?.[0]}"`}
          onDelete={onDeleteParam('countries')}
        />
      )}
      {filters?.userName && (
        <Chip
          label={`User Name: "${filters.userName}"`}
          onDelete={onDeleteParam('userName')}
        />
      )}
      {filters?.advertiserId && (
        <Chip
          label={`Advertiser Id: "${filters.advertiserId}"`}
          onDelete={onDeleteParam('advertiserId')}
        />
      )}
      {filters?.createdTo?.from && (
        <Chip
          label={`Show from: ${format(new Date(filters.createdTo.from), 'P HH:mm')}`}
          onDelete={onDeleteParam('createdTo.from')}
        />
      )}
      {filters?.createdTo?.to && (
        <Chip
          label={`Show before: ${format(new Date(filters.createdTo.to), 'P HH:mm')}`}
          onDelete={onDeleteParam('createdTo.to')}
        />
      )}
    </Bar>
  )
}
