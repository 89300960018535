import React from 'react'
import { BooleanField, DateTimeField, NameField, TextField } from '@/designSystem/Table/Fields'
import IconButton from '@/designSystem/IconButton'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import { ReactComponent as DeleteIcon } from '@/assets/icons/delete.svg'
import { Box } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { type Advertiser, advertisersHeaders } from '@/store/advertisers'

interface EditFieldProps {
  uuid: string
  onClick: (uuid: string) => void
}
const EditField = ({ onClick, uuid }: EditFieldProps) => {
  const { palette } = useTheme()
  return (
    <IconButton sx={{ background: 'white' }} onClick={() => onClick(uuid)}>
      <CreateRoundedIcon sx={{ color: palette.primary.main }} />
    </IconButton>
  )
}

const DeleteField = ({ onClick, uuid }: EditFieldProps) => {
  return (
    <IconButton sx={{ background: 'white' }} onClick={() => onClick(uuid)}>
      <DeleteIcon width={24}/>
    </IconButton>
  )
}

const Indicator = ({ active }: { active: boolean }) => {
  const { palette } = useTheme()
  return (
    <Box
      position='relative'
      display='flex'
      alignItems='center'
      width={0}
    >
      <Box
        sx={{
          borderRadius: '0px 6px 6px 0px',
          width: '6px',
          height: '36px',
          backgroundColor: active ? '#15A81B' : palette.red2.main,
          position: 'absolute'
        }}
      />
    </Box>
  )
}

const checkIsActive = (activity: Advertiser['status']) => activity === 'ACTIVE'

const fieldToElementMap = (header: string, row: Advertiser, onClickEdit: (uuid: string) => void,
  onClickDelete: (uuid: string) => void) => {
  const {
    name,
    id,
    description,
    status,
    pullLeadStatuses,
    createdAt,
    updatedAt
  } = row

  const isActive = checkIsActive(status)

  switch (header) {
    case '::indicator':
      return <Indicator active={isActive} />
    case advertisersHeaders.id:
      return <TextField text={id} limitation={1} />
    case advertisersHeaders.name:
      return <NameField name={name} id={id} />
    case advertisersHeaders.description:
      return <TextField text={description} />
    case advertisersHeaders.active:
      return <BooleanField isSomething={isActive} />
    case advertisersHeaders.pullLeadStatuses:
      return <BooleanField isSomething={!!pullLeadStatuses} />
    case advertisersHeaders.createdAt:
      return <DateTimeField date={new Date(createdAt)} />
    case advertisersHeaders.updatedAt:
      return updatedAt ? <DateTimeField date={new Date(updatedAt)} /> : null
    case '::edit':
      return <EditField onClick={onClickEdit} uuid={id} />
    case '::delete':
      return <DeleteField onClick={onClickDelete} uuid={id} />
  }
}

export default fieldToElementMap
