import React, { SyntheticEvent } from 'react'
import IconButton from '@/designSystem/IconButton'
import { ReactComponent as ExpandIcon } from '@/assets/icons/expand.svg'
import { ReactComponent as CollapseIcon } from '@/assets/icons/collapse.svg'
import { Box, TextField, useTheme } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'
import SearchIcon from '@/designSystem/NavigationPanel/Icons/Search'
import InputAdornment from '@mui/material/InputAdornment'

interface Props {
  // onHide: () => void
  // onExpand: () => void
  search: string
  onChange: (value: string) => void
}

const ExtraActions = ({ search, onChange }: Props) => {
  // const { spacing } = useTheme()
  const theme = useTheme()
  return <FlexBox sx={{ gap: theme.spacing(4), marginBottom: theme.spacing(10), alignItems: 'center' }}>
    <TextField
      InputProps={{
        startAdornment: (
          <>
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          </>
        )
      }}
      // sx={{ input: { paddingTop: '10px !important', paddingBottom: '10px !important', } }}
      placeholder='Search'
          sx={{
            input: {
              paddingTop: '10px !important',
              paddingBottom: '10px !important'
            },
            backgroundColor: 'white',
            borderRadius: '5px'
          }}
      size='small' value={search}
      onChange={(e) => onChange(e.target.value || '')}
    />
    {/* <IconButton style={{ height: 40, width: 40 }} onClick={onExpand}>
      <ExpandIcon />
    </IconButton>
    <IconButton style={{ height: 40, width: 40 }} onClick={onHide}>
      <CollapseIcon />
    </IconButton> */}
  </FlexBox>
}

export default ExtraActions
