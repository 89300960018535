import React, { type CSSProperties } from 'react'
import FlexBox from '@/designSystem/FlexBox'

import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material'
import { nanoid } from '@reduxjs/toolkit'
import AdvertiserSelected from './AdvertiserSelected'
import { type FilterItemProps } from '..'

const SelectedValues = ({ onDelete, selectedValues, isMultiple, name }: FilterItemProps) => {
  const { spacing } = useTheme()

  const boxSx: CSSProperties = {
    background: '#4c586b',
    color: '#edf2f4',
    padding: `${spacing(1)} ${spacing(2)}`,
    borderRadius: spacing(2),
    gap: spacing(1.5)
  }
  const flexBoxSx: CSSProperties = {
    gap: spacing(2),
    flexWrap: 'wrap',
    marginTop: spacing(2)
  }

  const isCorrectType = Array.isArray(selectedValues) && selectedValues.length > 0 && typeof selectedValues[0] === 'string'

  if (!isCorrectType) return null

  if (name === 'advertiserId') { // because we need to show names and not ids
    return <FlexBox sx={flexBoxSx}>{selectedValues.map((val) => <FlexBox sx={boxSx} key={nanoid()}>
    <AdvertiserSelected id={val} removeItem={() => onDelete(name as any, val, isMultiple)} />
  </FlexBox>)}</FlexBox>
  }

  return (
  <FlexBox sx={flexBoxSx}>{selectedValues.map((val) => <FlexBox sx={boxSx} key={nanoid()}>
    {val}
    <CloseIcon onClick={() => onDelete(name as any, val, isMultiple)} sx={{ width: spacing(4) }} />
  </FlexBox>)}</FlexBox>)
}

export default SelectedValues
