import React from 'react'
import IOSSwitch, { type IOSSwitchProps } from '@/designSystem/IOSSwitch'

import camelCase from 'lodash.camelcase'
import { useFormContext, Controller } from 'react-hook-form'

type SwitchProps = Omit<IOSSwitchProps, 'defaultValue'> & {
  name?: string
  defaultValue?: boolean
}

export default function Switch ({
  name,
  defaultValue,
  formControlLabel,
  ...rest
}: SwitchProps) {
  const { control } = useFormContext()

  const fieldName = name ?? (typeof formControlLabel.label === 'string' && camelCase(formControlLabel.label))
  if (!fieldName) {
    console.error('Switch doesn\'t have a name')
    return null
  }

  return (
    <Controller
      control={control}
      name={fieldName}
      defaultValue={defaultValue}
      render={({ field: { value, ...field } }) => {
        return (
          <IOSSwitch
            checked={value?.value ?? value}
            {...rest}
            {...field}
            formControlLabel={{
              ...formControlLabel
            }}
          />
        )
      }}
    />
  )
}
