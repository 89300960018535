import React, { useState } from 'react'

import PageContentLayout from '@/designSystem/PageContentLayout'
import Tabs from '@/designSystem/Tabs'

import { Box, IconButton } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import FlexBox from '@/designSystem/FlexBox'
import MetricLayout from './MetricReportLayout/MetricLayout'
import RejectLayout from './RejectReportLayout/RejectLayout'
import Button from '@/designSystem/Button'
import { useNavigate } from 'react-router-dom'
import QuestionMarkIcon from '@/designSystem/PageContentLayout/Icons/QuestionMark'
import DrillReportsInfoModal from './DrillReportsInfoModal'

interface DrillDownPageProps {
  data: {
    presetsGroups: string[]
    conditionFieldsOptions: string[]
  }
}

export default function DrillDownPageLayout ({
  data
}: DrillDownPageProps) {
  const [chosenTab, setChosenTab] = useState(0)
  const { spacing } = useTheme()
  const navigate = useNavigate()

  const [openInfoModal, setOpenInfoModal] = useState(false)

  const handleCreatePreset = () => {
    navigate('/settings/presets-list')
  }

  const handleOpenInfoModal = () => {
    setOpenInfoModal(true)
  }

  const handleCloseInfoModal = () => {
    setOpenInfoModal(false)
  }

  return (
    <>
      <PageContentLayout
        title={<Box sx={{ color: '#000931', fontSize: '28px', marginRight: '16px' }}>Drilldown Reports</Box>}
        leftActionButtons={<Box marginLeft={spacing(-5)}>
          <IconButton onClick={handleOpenInfoModal}>
            <QuestionMarkIcon />
          </IconButton>
        </Box>}
        rightActionButtons={<Box marginLeft={spacing(-5)} onClick={handleCreatePreset}>
          <Button
            sx={{
              borderRadius: '16px',
              height: '40px',
              width: '150px',
              padding: '4px 24px',
              whiteSpace: 'nowrap'
            }}
            text="Create preset"
            textVariant='body2'
          />
        </Box>}
      >
        <FlexBox column gap={14}>
          <Box>
            <Tabs
              chosenTab={chosenTab}
              setChosenTab={setChosenTab}
              tabs={['Metric Report', 'Reject Report']}
              tabsProps={{ sx: { paddingTop: '20px' } }}
            />
          </Box>
          {chosenTab === 0 && <MetricLayout data={data} />}
          {chosenTab === 1 && <RejectLayout data={data} />}
        </FlexBox>
      </PageContentLayout>
      {openInfoModal && (
        <DrillReportsInfoModal handleClose={handleCloseInfoModal} />
      )}
    </>
  )
}
