import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

import FilterPopupLayout from '@/designSystem/Pages/Advertisers/Popups/Filter'
import { selectQueryParamsAdvertisers } from '@/store/queryParams'

interface FiltersPopupProps {
  handleClose: () => void
}

export default function FiltersPopup ({
  handleClose
}: FiltersPopupProps) {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectQueryParamsAdvertisers)

  const resetFilters = () => {
    dispatch(selectQueryParamsAdvertisers({ ...queryParams, filters: undefined }))
    handleClose()
  }

  return (
    <FilterPopupLayout
      handleClose={handleClose}
      resetFilters={resetFilters}
    />
  )
}
