import React from 'react'
import { Box, Typography } from '@mui/material'

import DealsCountriesPagination from './DealsCountriesPagination'

import TableLayout from '@/designSystem/Table'
import elementDealsMap from './ElementDealsAutoMap'

interface IDealsTable {
  advertiser: { title: string, value: string | null }
  deals: any
}

const Header = ({ header }: { header: string }) => {
  if (header.startsWith('::')) {
    return ''
  }
  return (
    <Typography
      variant='body1'
      color="text1"
      width="max-content"
      sx={{
        display: 'flex'
      }}
    >
      {header}
    </Typography>
  )
}

const defaultDealsHeaders = ['UUID', 'Advertiser UUID', 'Revenue', 'Goal Type', 'Country']

export const DealsCountriesTable = ({ advertiser, deals }: IDealsTable) => {
  const headerNodes: React.ReactNode[] =
  defaultDealsHeaders?.map((header) => <Header key={header} header={header} />)

  const rowNodes: any = deals?.map((row: any) => (
    defaultDealsHeaders?.map((header) =>
      elementDealsMap(header, row))
  ))

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'start'}
      paddingLeft={'40px'}
      width={'100%'}
    >
      <Box
        fontSize={'24px'}
        fontStyle={'normal'}
        fontWeight={700}
        color={'#393E51'}
      >Current Countries Revenue</Box>
      <Box
        fontSize={'20px'}
        fontStyle={'normal'}
        fontWeight={500}
        color={'#393E51'}
        paddingTop={'12px'}
      >Advertiser: {advertiser?.title}</Box>
      <Box
        paddingTop={'24px'}
        paddingBottom={'24px'}
      >
        <DealsCountriesPagination />
      </Box>
      <TableLayout
        headers={headerNodes}
        rows={rowNodes}
        sx={{
          '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-of-type': {
            width: 0
          }
        }}
      />
    </Box>
  )
}
