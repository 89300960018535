import React from 'react'

export default function User () {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="12" fill="#F1F3FF"/>
      <path d="M27.9987 28.0007C32.6029 28.0007 36.332 24.2715 36.332 19.6673C36.332 15.0632 32.6029 11.334 27.9987 11.334C23.3945 11.334 19.6654 15.0632 19.6654 19.6673C19.6654 24.2715 23.3945 28.0007 27.9987 28.0007ZM27.9987 32.1673C22.4362 32.1673 11.332 34.959 11.332 40.5007V42.584C11.332 43.7298 12.2695 44.6673 13.4154 44.6673H42.582C43.7279 44.6673 44.6654 43.7298 44.6654 42.584V40.5007C44.6654 34.959 33.5612 32.1673 27.9987 32.1673Z" fill="#4141D5"/>
    </svg>
  )
}
