import React, { useState } from 'react'

import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'
import { IconButton } from '@mui/material'
import AddIcon from '@/designSystem/Icons/Add'
import AddPopup from '@/components/Advertisers/AddPopup'
import ReloadButton from '@/designSystem/ReloadButton'

interface LeftActionButtonsProps {
  onClickReload: () => void
  onClickOpenAdd?: () => void
}

export default function LeftActionButtonsLayout ({
  onClickReload,
  onClickOpenAdd
}: LeftActionButtonsProps) {
  const [openAddPopup, setOpenAddPopup] = useState(false)
  const theme = useTheme()
  const onClickOpenAddButton = () => {
    if (onClickOpenAdd) {
      onClickOpenAdd()
    } else {
      setOpenAddPopup(true)
    }
  }

  return (
    <>
      <Box
        display="flex"
        gap={theme.spacing(3)}
        alignItems="center"
      >
        <ReloadButton onClick={onClickReload} />
        <IconButton onClick={onClickOpenAddButton}>
          <AddIcon />
        </IconButton>
      </Box>
      {openAddPopup && (
        <AddPopup handleClose={() => setOpenAddPopup(false)} />
      )}
    </>
  )
}
