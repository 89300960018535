import { type IPQL } from '.'

const transformPqlToString = (pql: IPQL[]): string => pql.reduce((acc: string, item) => {
  const transformValue = (value: string[] | string | boolean | null) => {
    if (typeof value === 'boolean') return value
    if (Array.isArray(value)) {
      return `[${value.map((item) => `'${item}'`).join(',')}]`
    }
    if (!value) return null
    return value
  }
  if (item.connector) {
    return `${acc} ${item.connector} ${item.field ?? ''} ${item.sign ?? ''} ${transformValue(item.value) ?? ''}`
  } else {
    return `${acc} ${item.field ?? ''} ${item.sign ?? ''} ${transformValue(item.value) ?? ''}`
  }
}, '')

const stringParcer = (value: string | undefined): IPQL[] => {
  if (!value || value === 'true') return []
  const temp = value.split(' ')
  if (temp[0] === 'country') { // if switch from country to custom PQL
    temp.unshift('')
  }
  const res = temp.reduce((acc: IPQL[], item, index) => {
    const pqlIndex = Math.ceil((index + 1) / 4) - 1
    if (index % 4 === 1) {
      const tmp = acc
      tmp[pqlIndex] = {
        ...tmp[pqlIndex],
        field: item
      }
    }
    if (index % 4 === 2) {
      const tmp = acc
      tmp[pqlIndex] = {
        ...tmp[pqlIndex],
        sign: item
      }
    }
    if (index % 4 === 3) {
      const tmp = acc
      if (item.includes('[')) {
        tmp[pqlIndex] = {
          ...tmp[pqlIndex],
          value: item.replace('[', '').replace(']', '').split(',').map((item) => item.split('\'').join(''))
        }
      } else {
        tmp[pqlIndex] = {
          ...tmp[pqlIndex],
          value: item
        }
      }
    }
    if (index % 4 === 0) {
      const tmp = acc
      tmp[pqlIndex] = {
        ...tmp[pqlIndex],
        connector: item
      }
    }
    return acc
  }, [])
  return res
}

const formatTime = (time: number) => {
  if (time < 10) return `0${time}`
  return time
}

export {
  transformPqlToString,
  stringParcer,
  formatTime
}
