import React from 'react'

import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'
import ReloadButton from '@/designSystem/ReloadButton'
import { useNavigate } from 'react-router'

export default function LeftActionButtons () {
  const theme = useTheme()
  const navigate = useNavigate()
  const onClickReload = () => navigate(0)
  return (
    <>
      <Box
        display="flex"
        gap={theme.spacing(3)}
        alignItems="center"
      >
        <ReloadButton onClick={onClickReload} />
      </Box>
    </>
  )
}
