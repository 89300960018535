import React from 'react'

import { useForm, type SubmitHandler } from 'react-hook-form'
import AddPopupLayout, {
  type Inputs,
  type AddPopupProps as AddPopupMuiProps
} from '@/designSystem/Pages/Advertisers/Popups/AddOrEdit'
import { useCreateAdvertiserMutation, useEditAdvertiserMutation } from '@/store/advertisers'

type AddPopupProps = Pick<AddPopupMuiProps, 'handleClose'>

export default function AddPopup ({ handleClose }: AddPopupProps) {
  const form = useForm<Inputs>()
  const [addAdvertiser] = useCreateAdvertiserMutation()
  const [editAdvertiser] = useEditAdvertiserMutation()

  const onSubmit: SubmitHandler<Inputs> = async ({
    name,
    description,
    pullLeadStatuses,
    active
  }) => {
    const data = await addAdvertiser({
      name,
      description,
      pullLeadStatuses: pullLeadStatuses?.value,
      status: 'ACTIVE'
    }).unwrap()
    if (data?.id && !active) {
      await editAdvertiser({ id: data.id, status: 'INACTIVE' })
    }
    handleClose()
  }

  return (
    <AddPopupLayout
      form={form}
      onSubmit={onSubmit}
      handleClose={handleClose}
      title='Create Advertiser'
      subtitle='Advertisers'
    />
  )
}
