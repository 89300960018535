import React, { useEffect, useMemo, useState } from 'react'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'

import Popup from '@/designSystem/Popup'
import { Box, Typography, useTheme } from '@mui/material'
import Form from '../Form'
import ActionButtons from '@/designSystem/ActionButtons'
import TextField from '@/designSystem/TextField'
import { ReactComponent as RoundIcon } from '@/assets/icons/add-plus-round.svg'
import { ReactComponent as TrashIcon } from '@/assets/icons/trash-red.svg'
import AccountsAutocomplete from '@/pages/Accounts/AccountsAutocomplete'
import { useCreateInjectionsMutation } from '@/store/injections'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import { type Advertiser, useGetAdvertisersQuery } from '@/store/advertisers'
import Autocomplete from '../Form/Autocomplete'
import composeSx from '@/designSystem/TextField/v2'
import { useLocation, useNavigate } from 'react-router-dom'
import { type Lead, useGetLeadsQuery } from '@/store/leads'
import FormAutocomplete from '../Form/FormAutocomplete'
import { type Option } from '@/designSystem/Autocomplete/types'
import { placeholderValues } from '@/constants'
import { LeadState } from '@/enums/leads.enum'

interface AddInjection {
  name: string
  description: string
  intervalMin: string
  leadsUuids: string[]
  timeMin: { title: string, value: string }
  advertiserId?: { title: string, value: string }
  autoLogin: { title: string, value: string }
  overrideFields: Record<string, string>
  state: string
}

interface IPropsAddInjModal {
  handleClose: () => void
  open: boolean
}

const timeMilSec = (time: string, timeType: string) => {
  if (timeType === 'minutes') {
    return parseInt(time) * 60 * 1000
  } else if (timeType === 'seconds') {
    return parseInt(time) * 1000
  }
}

const emptyError = {
  name: '',
  leadsUuids: '',
  intervalMin: '',
  timeMin: '',
  description: ''
}
export const AddInjectionModal = ({ handleClose, open }: IPropsAddInjModal) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [addInjection] = useCreateInjectionsMutation()
  const { data } = useGetAdvertisersQuery({ limit: 500, page: 1 })
  const dataLeads = useGetLeadsQuery({ limit: 500, page: 1, filters: { state: [LeadState.HOLD] } }).data?.leads
  const theme = useTheme()

  const [error, setError] = useState(emptyError)
  const [overrideFields, setOverrideFields] = useState<any>([])

  useEffect(() => {
    setTimeout(() => {
      navigate(location.pathname, { replace: true })
    }, 2000)
  }, [])

  const form = useForm<AddInjection>({
    defaultValues: {
      name: '',
      description: '',
      intervalMin: '',
      leadsUuids: location.state?.uuids?.split(',') ?? [],
      advertiserId: { title: '', value: '' },
      autoLogin: { title: '', value: '' },
      overrideFields: {},
      timeMin: { title: '', value: '' },
      state: ''
    }
  })

  const optionsAdvertisers = data?.advertisers?.map((advertiser: Advertiser) => {
    return {
      title: advertiser?.name,
      value: advertiser?.id
    }
  })

  const optionsLeads: any = dataLeads?.map((lead: Lead) => {
    return {
      title: lead?.id,
      value: lead?.id
    }
  })

  const {
    control: formControl
  } = form

  const onSubmit: SubmitHandler<AddInjection> = async (data) => {
    if (!(data?.intervalMin && data?.timeMin?.value &&
      data?.leadsUuids?.length > 0 &&
      data?.name && data?.description)) {
      setError({
        name: data?.name ? '' : 'This field is required',
        leadsUuids: data?.leadsUuids?.length > 0 ? '' : 'This field is required',
        intervalMin: data?.intervalMin ? '' : 'Must be less than or equal to 900 seconds or 15 minutes',
        timeMin: data?.timeMin?.value ? '' : 'This field is required',
        description: data?.description ? '' : 'This field is required'
      })
      return
    } else {
      setError(emptyError)
    }
    const intervalMin = timeMilSec(data?.intervalMin, data.timeMin?.value)
    const leadsUuids = data?.leadsUuids?.length > 0 ? data?.leadsUuids : []
    const newOverrideFields: Record<string, string> = {}
    overrideFields?.forEach((field: { key: string, value: string }) => {
      newOverrideFields[field.key] = field.value
    })

    await addInjection({
      name: data.name ?? '',
      description: data.description ?? '',
      interval: intervalMin && intervalMin > 0 ? intervalMin : 0,
      overrideFields: overrideFields?.length > 0 ? newOverrideFields : undefined,
      advertiserId: data.advertiserId?.value ? data.advertiserId?.value : undefined,
      leadIds: leadsUuids
    }).unwrap()
    handleClose()
  }

  const textFieldsSx = composeSx(theme)

  const handleAddOverrideField = () => {
    ((overrideFields[overrideFields?.length - 1]?.key !== '') &&
      (overrideFields[overrideFields?.length - 1]?.value !== '')) &&
      setOverrideFields([...overrideFields, { key: '', value: '' }])
  }

  const fields = useMemo(() => {
    return overrideFields?.map((field: { key: string, value: string }, index: number) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
          gap: '16px'
        }}
      >
        <TextField
          placeholder='Enter override key'
          placeholders={placeholderValues}
          value={field?.key}
          onChange={(e) => {
            const newOverrideFields = [...overrideFields]
            newOverrideFields[index].key = e.target.value
            setOverrideFields(newOverrideFields)
          }}
        />
        <TextField
          placeholder='Enter override value'
          value={field?.value}
          onChange={(e) => {
            const newOverrideFields = [...overrideFields]
            newOverrideFields[index].value = e.target.value
            setOverrideFields(newOverrideFields)
          }}
        />
        {overrideFields?.length - 1 === index &&
          <TrashIcon
            style={{ cursor: 'pointer', marginBottom: '10px' }}
            onClick={() => {
              const newOverrideFields = [...overrideFields]
              newOverrideFields.splice(index, 1)
              setOverrideFields(newOverrideFields)
            }}
          />}
      </Box>
    ))
  }, [overrideFields])

  return (
    <Popup
      open={open}
      title="Create Injection"
      handleClose={handleClose}
      overflow='auto'
      maxWidth='600px'
    >
      <ScrollBarContainer>
        <Form form={form} onSubmit={onSubmit}>
          <Box
            display="flex"
            flexDirection="column"
          >
            <Box display="flex" flexDirection="column">
              <Box
                color={theme.palette.text1.main}
                fontSize={18}
                fontWeight={400}
              >Name</Box>
              <Controller
                control={formControl}
                name="name"
                render={({ field }) => (
                  <TextField {...field} />
                )}
              />
              <Typography sx={{
                color: theme.palette.error.main,
                marginTop: '1px',
                fontSize: '12px'
              }}>{error.name}</Typography>
              <Typography
                color={theme.palette.text1.main}
                fontSize={18}
                fontWeight={400}
                marginTop={2}
              >Advertiser UUID</Typography>
              <Autocomplete
                textFieldProps={{
                  root: {
                    title: 'Advertiser',
                    placeholder: 'Choose Advertiser',
                    sx: textFieldsSx
                  }
                }}
                name="advertiserId"
                options={optionsAdvertisers ?? [{ title: 'Select advertiser', value: '' }]}
              />
              <Typography
                color={theme.palette.text1.main}
                fontSize={18}
                fontWeight={400}
                marginTop={2}
              >Leads UUIDs</Typography>
              <FormAutocomplete
                name="leadsUuids"
                rowSelectedOptions
                options={optionsLeads?.length === 0 ? [] : optionsLeads}
                selectedOptions={form.watch('leadsUuids')?.length > 0 ? form.watch('leadsUuids') : []}
                onChange={(option: Option<string> | null) => {
                  option && form.setValue('leadsUuids', [...form.watch('leadsUuids'), option?.value])
                }}
                deleteField={(value: string) => () => {
                  form.setValue('leadsUuids', form.watch('leadsUuids')?.filter((item: string) => item !== value))
                }}
                placeholder="Choose Leads"
                sx={textFieldsSx}
                textFieldProps={{
                  input: {
                    sx: {
                      input: {
                        padding: '0px 0px 0px 10px !important'
                      }
                    }
                  }
                }}
              />
              <Typography sx={{
                color: theme.palette.error.main,
                marginTop: '1px',
                fontSize: '12px'
              }}>{error.leadsUuids}</Typography>
              <Box
                color={theme.palette.text1.main}
                fontSize={18}
                fontWeight={400}
                paddingTop='24px'
              >Interval</Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  gap: theme.spacing(2)
                }}
              >
                <Box
                  display='flex'
                  flexDirection='column'>
                  <Controller
                    control={formControl}
                    name="intervalMin"
                    render={({ field }) => (
                      <TextField type="number"
                        style={{ width: '187px' }}
                        {...field}
                      />
                    )}
                  />
                  <Typography sx={{
                    color: theme.palette.error.main,
                    marginTop: '1px',
                    fontSize: '12px'
                  }}>{error.intervalMin}</Typography>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'>
                  <Controller
                    control={formControl}
                    name="timeMin"
                    render={({ field }) => (
                      <AccountsAutocomplete
                        options={[{ title: 'Seconds', value: 'seconds' }, { title: 'Minutes', value: 'minutes' }]}
                        onChange={field.onChange}
                        placeholder="Enter Time"
                        sx={{ width: '159px' }}
                      />
                    )}
                  />
                  <Typography sx={{
                    color: theme.palette.error.main,
                    marginTop: '1px',
                    fontSize: '12px'
                  }}>{error.timeMin}</Typography>
                </Box>
              </Box>
              <Box
                color={theme.palette.text1.main}
                fontSize={18}
                fontWeight={400}
                paddingTop='24px'
              >Description</Box>
              <Controller
                control={formControl}
                name="description"
                render={({ field }) => (
                  <TextField {...field} multiline rows={4} inputHeight='165px' />
                )}
              />
              <Typography sx={{
                color: theme.palette.error.main,
                marginTop: '1px',
                fontSize: '12px'
              }}>{error.description}</Typography>
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={2}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      fontSize: '24px',
                      color: theme.palette.text1.main,
                      fontWeight: '700',
                      paddingTop: '24px'
                    }}
                  >
                    Override and Custom Fields
                  </Box>
                  <RoundIcon style={{ marginBottom: '-25px', cursor: 'pointer' }}
                    onClick={handleAddOverrideField} />
                </Box>
                {fields}
              </Box>
            </Box>
            <ActionButtons
              sx={{ paddingTop: '24px' }}
              onCancel={handleClose}
              submitText="Submit"
              cancelText='Cancel'
            />
          </Box>
        </Form>
      </ScrollBarContainer>
    </Popup>
  )
}
