import React from 'react'

export default function Pnl () {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="45" height="45" rx="12" fill="white" />
      <path d="M17.5 32H14C13.45 32 13 31.55 13 31V21C13 20.45 13.45 20 14 20H17.5C18.05 20 18.5 20.45 18.5 21V31C18.5 31.55 18.05 32 17.5 32ZM24.75 14H21.25C20.7 14 20.25 14.45 20.25 15V31C20.25 31.55 20.7 32 21.25 32H24.75C25.3 32 25.75 31.55 25.75 31V15C25.75 14.45 25.3 14 24.75 14ZM32 22H28.5C27.95 22 27.5 22.45 27.5 23V31C27.5 31.55 27.95 32 28.5 32H32C32.55 32 33 31.55 33 31V23C33 22.45 32.55 22 32 22Z" fill="#4141D5" />
    </svg>

  )
}
