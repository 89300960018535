import React from 'react'

const RotationFolderIcon = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ flexShrink: 0 }}>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.105 5.887C2 6.439 2 7.102 2 8.425V19C2 24.6565 2 27.4855 3.758 29.242C5.5145 31 8.3435 31 14 31H20C25.6565 31 28.4855 31 30.242 29.242C32 27.4855 32 24.6565 32 19V15.697C32 11.749 32 9.7735 30.845 8.491C30.7391 8.37262 30.6269 8.25996 30.509 8.1535C29.2265 7 27.251 7 23.303 7H22.742C21.0125 7 20.147 7 19.34 6.7705C18.8971 6.64391 18.4703 6.46674 18.068 6.2425C17.336 5.836 16.724 5.2225 15.5 4L14.675 3.175C14.264 2.764 14.06 2.56 13.844 2.38C12.915 1.60996 11.7754 1.13792 10.574 1.0255C10.295 1 10.004 1 9.425 1C8.1005 1 7.439 1 6.887 1.105C5.70176 1.32884 4.6115 1.90471 3.75846 2.75748C2.90542 3.61025 2.32921 4.70183 2.105 5.887ZM17.375 13C17.375 12.7016 17.4935 12.4155 17.7045 12.2045C17.9155 11.9935 18.2016 11.875 18.5 11.875H26C26.2984 11.875 26.5845 11.9935 26.7955 12.2045C27.0065 12.4155 27.125 12.7016 27.125 13C27.125 13.2984 27.0065 13.5845 26.7955 13.7955C26.5845 14.0065 26.2984 14.125 26 14.125H18.5C18.2016 14.125 17.9155 14.0065 17.7045 13.7955C17.4935 13.5845 17.375 13.2984 17.375 13Z" fill="#8F5DF8"/>
        <path d="M17.375 13C17.375 12.7016 17.4935 12.4155 17.7045 12.2045C17.9155 11.9935 18.2016 11.875 18.5 11.875H26C26.2984 11.875 26.5845 11.9935 26.7955 12.2045C27.0065 12.4155 27.125 12.7016 27.125 13C27.125 13.2984 27.0065 13.5845 26.7955 13.7955C26.5845 14.0065 26.2984 14.125 26 14.125H18.5C18.2016 14.125 17.9155 14.0065 17.7045 13.7955C17.4935 13.5845 17.375 13.2984 17.375 13Z" fill="#8F5DF8"/>
        <path d="M13.494 24V14.2H19.64V15.404H14.978V18.498H18.842V19.688H14.978V24H13.494Z" fill="#F1F3FF"/>
    </svg>
  )
}

export default RotationFolderIcon
