import React from 'react'
import { TextField, Typography, useTheme } from '@mui/material'
import { type FilterItemProps } from '.'
import { sx } from '../constants'

const FromToDateNumberItem = (props: FilterItemProps) => {
  const { spacing, palette } = useTheme()
  const { onChange, isMultiple, title, name, selectedValues } = props

  if (typeof selectedValues === 'string' || typeof selectedValues === 'boolean' || !selectedValues || Array.isArray(selectedValues)) return null

  const onSave = (value: string, isFrom: boolean) => {
    if (+value) {
      onChange(name as any, { ...typeof selectedValues === 'object' ? selectedValues : {}, ...isFrom ? { from: value } : { to: value } }, isMultiple)
    }
  }

  const { from, to } = selectedValues

  return <>
    <Typography variant="body1" sx={{ marginTop: spacing(4) }} color={palette.card.main}>{title}</Typography>
    <Typography variant="subtitle1" color={palette.card.main} sx={{ marginTop: spacing(1) }}>From: </Typography><TextField type="number" value={from} sx={sx} onChange={(e) => onSave(e.target.value, true)}/>
    <Typography variant="subtitle1" color={palette.card.main} sx={{ marginTop: spacing(1) }}>To: </Typography><TextField type="number" value={to} sx={sx} onChange={(e) => onSave(e.target.value, false)} />
  </>
}

export default FromToDateNumberItem
