import React from 'react'

import LeftActionButtonsLayout from '@/designSystem/Pages/Advertisers/LeftActionButtonsLayout'
import { useNavigate } from 'react-router'

export default function LeftActionButtons () {
  const navigate = useNavigate()
  const onClickReload = () => navigate(0)

  return <LeftActionButtonsLayout onClickReload={onClickReload} />
}
