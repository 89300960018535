import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { Option } from '@/designSystem/Autocomplete/types'
import setProperty from '@/utils/setProperty'
import FilterIcon from '@/designSystem/Pages/Advertisers/Icons/Filter'
import SettingsIcon from '@/assets/icons/Settings'
import { Box, useTheme } from '@mui/material'
import Button from '@/designSystem/Button'
import IconButton from '@/designSystem/IconButton'
import Autocomplete from '@/designSystem/Pages/Advertisers/Autocomplete'
import AdvertisersLogsFiltersPopup from './Filters/AdvertisersLogsFiltersPopup'
import AdvertisersLogsSettingsPopup from './Filters/AdvertisersLogsSettingsPopup'
import { useSearchLogsQuery } from '@/store/advertisersLogs'
import isObjectNotEmpty from '@/utils/isObjectNotEmpty'
import { selectAdvertiserLogsQueryParams, setAdvertiserLogsQueryParams } from '@/store/queryParams'

export default function RightActionButtons () {
  const theme = useTheme()
  const [isOpenFilterPopup, setIsOpenFilterPopup] = useState(false)
  const [isSettingsOpen, setIsSettingOpen] = useState(false)
  const [createdAt, setCreatedAt] = useState<string | null>(null)

  const [name, setName] = useState<string | null>(null)
  const dispatch = useDispatch()
  const queryParams = useSelector(selectAdvertiserLogsQueryParams)

  const isFilter = queryParams?.filter && isObjectNotEmpty(queryParams?.filter)
  const { limit, filter, page } = useSelector(selectAdvertiserLogsQueryParams)
  // const nameOptions: any = useSearchLogsQuery({ sortField: 'logData', limit, page, filter: filter ? { advertiserId: filter?.advertiserId, id: filter?.uuid } : {}, createdAt: filter?.createdAt ? filter?.createdAt : undefined }).data?.logs?.map(({ id }) => ({ title: id, value: id }))
  const [nameOptions, setNameOptions] = useState<any>()
  const { data: logsData, isLoading: logsLoading } = useSearchLogsQuery({ sortField: 'logData', limit, page, filter: filter ? { advertiserId: filter?.advertiserId, id: filter?.uuid } : {}, createdAt: filter?.createdAt ? filter?.createdAt : undefined })
  useEffect(() => {
    if (!logsLoading && logsData) {
      const newOptions = logsData.logs.map(({ id }) => ({ title: id, value: id }))
      setNameOptions(newOptions)
    }
  }, [logsLoading, logsData])
  console.log('🚀 ~ RightActionButtons ~ nameOptions:', nameOptions)

  const onInputChangeName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value
    setName(value ?? null)
    if (!value) {
      dispatch(setAdvertiserLogsQueryParams({
        ...queryParams,
        filter: setProperty({ ...queryParams?.filter }, 'id')
      }))
    } else {
      dispatch(setAdvertiserLogsQueryParams({
        ...queryParams,
        filter: setProperty({ ...queryParams?.filter }, 'id', name)
      }))
    }
  }
  const onChangeName = (value: Option<string> | null) => {
    setName(value?.value ?? null)
    return value && dispatch(
      setAdvertiserLogsQueryParams({
        ...queryParams,
        page: 1,
        filter: setProperty({ ...queryParams?.filter }, 'id', value?.value)
      })
    )
  }

  useEffect(() => {
    if (!queryParams?.filter?.uuid) {
      setName(null)
    }
    if (!queryParams?.filter?.createdAt) {
      setCreatedAt(null)
    }
  }, [queryParams?.filter?.uuid, queryParams?.filter?.createdAt])
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={theme.spacing(4)}
      >
        {isFilter && <Button
          onClick={() => {
            dispatch(setAdvertiserLogsQueryParams({ ...queryParams, filter: undefined }))
          }}
          text='Reset filter'
          variant='contained'
          sx={{
            backgroundColor: theme.palette.red2.main,
            padding: `${theme.spacing(2)} ${theme.spacing(6)}`
          }}
        />}
        <IconButton onClick={() => setIsOpenFilterPopup(true)}>
          <FilterIcon />
        </IconButton>
        <Box
          display="flex"
          alignItems="center"
          gap={theme.spacing(3)}
        >
          <Autocomplete
            onInputChange={onInputChangeName}
            onChange={onChangeName}
            value={{ value: name ?? '', title: name ?? '' }}
            clearIcon=''
            options={nameOptions ?? []}
            placeholder='Name'
            leftShift
          />
         {/* <AutocompleteERR
            onInputChange={onInputChangeName}
            onChange={onChangeName}
            value={{ value: name ?? '', title: name ?? '' }}
            clearIcon=''
            options={nameOptions ?? []}
            placeholder='Enter Text...'
            // leftShift
          /> */}
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => setIsSettingOpen(true)}
          >
            <SettingsIcon />
          </IconButton>
        </Box>
      </Box>
      {isSettingsOpen && (<AdvertisersLogsSettingsPopup handleClose={() => setIsSettingOpen(false)} />)}
      {isOpenFilterPopup && (
        <AdvertisersLogsFiltersPopup handleClose={() => setIsOpenFilterPopup(false)} />
      )}
    </>
  )
}
