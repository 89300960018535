import React from 'react'

export default function PartnerEPC () {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="45" height="45" rx="12" fill="white" />
      <path d="M15 29H17V33.081L22.101 29H27C28.103 29 29 28.103 29 27V19C29 17.897 28.103 17 27 17H15C13.897 17 13 17.897 13 19V27C13 28.103 13.897 29 15 29Z" fill="#4141D5" />
      <path d="M31 13H19C17.897 13 17 13.897 17 15H29C30.103 15 31 15.897 31 17V25C32.103 25 33 24.103 33 23V15C33 13.897 32.103 13 31 13Z" fill="#4141D5" />
    </svg>
  )
}
