import React from 'react'

import MuiChip, { type ChipProps } from '@mui/material/Chip'
import { useTheme, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

type FilterChipProps = Omit<ChipProps, 'label'> & {
  label: string
}

export default function FilterChip ({ onDelete, label, ...rest }: FilterChipProps) {
  const { spacing, palette } = useTheme()
  return (
    <MuiChip
      sx={{
        padding: spacing(2.5, 4),
        backgroundColor: palette.primary.main,
        color: palette.whiteText.main,
        height: 'auto',
        '& .MuiChip-label': {
          padding: 0
        }
      }}
      onDelete={onDelete}
      label={<Typography padding={0} variant="body2">{label}</Typography>}
      deleteIcon={
        <CloseIcon
          sx={{
            fill: palette.whiteText.main,
            margin: `0 0 0 ${spacing(3)} !important`
          }}
        />
      }
      {...rest}
    />
  )
}
