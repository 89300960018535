import React from 'react'

import { DateTimeField } from '@/designSystem/Table/Fields'
import { Box, Typography } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { ReactComponent as EyeIcon } from '@/assets/icons/eye.svg'
import { ReactComponent as ThreeDots } from '@/assets/icons/three-dots.svg'
import { injectionsHeaders } from '@/store/injections'

export function FieldMap ({ children }: any) {
  const { palette } = useTheme()
  return (
    <Typography
      sx={{ display: 'flex' }}
      variant='table1'
      width="max-content"
      color={palette.text2.main}
    >
      {children}
    </Typography>
  )
}

const IndicatorInjections = ({ active }: { active: boolean }) => {
  const { palette } = useTheme()
  return (
    <Box
      position='relative'
      display='flex'
      alignItems='center'
      width={0}
    >
      <Box
        sx={{
          borderRadius: '0px 6px 6px 0px',
          width: '6px',
          height: '36px',
          backgroundColor: active ? '#6B6B6B' : palette.grey[300],
          position: 'absolute'
        }}
      />
    </Box>
  )
}

const elementMap = (header: string, row: any,
  setModal: (description: string) => void,
  handleOpenInject: ({ name, id }: { name: string, id: string }) => void) => {
  const {
    name,
    id,
    description,
    interval,
    state,
    status,
    createdAt,
    updatedAt
  } = row

  const { palette } = useTheme()

  switch (header) {
    case '::indicatorInjections':
      return <IndicatorInjections active />
    case injectionsHeaders.name:
      return <Box sx={{
        marginLeft: '8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '4px',
        color: '#4141D5',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        cursor: 'pointer'
      }}
        onClick={() => handleOpenInject({ name, id })}>
         {name}
      </Box>
    case injectionsHeaders.description:
      return <>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '300px',
            gap: '4px'
          }}
        >
          <Typography
            display="flex"
            variant="body1"
            color={palette.text1.main}
            width="max-content"
            style={{
              cursor: 'pointer',
              textDecoration: 'none',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: '240px'
            }}
          >{description || 'No description'}
          </Typography>
          {description && (description?.length > 40) && <ThreeDots
            cursor={'pointer'}
            onClick={() => setModal(description)}
          />}
        </Box >
      </>
    case injectionsHeaders.status:
      return <FieldMap>
        <Box>
          <Typography sx={{
            color: '#6B6B6B',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            textTransform: 'capitalize'
          }}>
            {status}
          </Typography>
        </Box>
      </FieldMap>
    case injectionsHeaders.state:
      return <FieldMap>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '4px',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          textTransform: 'capitalize'
        }}>
          {state}
        </Box>
      </FieldMap>
    case injectionsHeaders.interval:
      return <FieldMap>{interval ? `${interval} Millisec` : 'Empty'}</FieldMap>
    case injectionsHeaders.createdAt:
      return <DateTimeField date={new Date(createdAt)} />
    case injectionsHeaders.updatedAt:
      return updatedAt ? <DateTimeField date={new Date(updatedAt)} /> : null
  }
}

export default elementMap
