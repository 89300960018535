import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import FilterIcon from '@/designSystem/Pages/Advertisers/Icons/Filter'
import SettingsIcon from '@/assets/icons/Settings'
import { Box, useTheme } from '@mui/material'
import Button from '@/designSystem/Button'
import IconButton from '@/designSystem/IconButton'
import ConversionsFiltersPopup from './Filters/ConversionsFiltersPopup'
import ConversionsSettingsPopup from './Filters/ConversionsSettingsPopup'
import isObjectNotEmpty from '@/utils/isObjectNotEmpty'
import { selectConversionsQueryParams, setConversionsQueryParams } from '@/store/queryParams'

export default function RightActionButtons () {
  const theme = useTheme()
  const [isOpenFilterPopup, setIsOpenFilterPopup] = useState(false)
  const [isSettingsOpen, setIsSettingOpen] = useState(false)

  const dispatch = useDispatch()
  const queryParams: any = useSelector(selectConversionsQueryParams)

  const isFilter = queryParams?.filters && isObjectNotEmpty(queryParams?.filters)

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={theme.spacing(4)}
      >
        {isFilter && <Button
          onClick={() => dispatch(setConversionsQueryParams({ ...queryParams, filters: undefined }))}
          text='Reset filter'
          variant='contained'
          sx={{
            backgroundColor: theme.palette.red2.main,
            padding: `${theme.spacing(2)} ${theme.spacing(6)}`
          }}
        />}
        <IconButton onClick={() => setIsOpenFilterPopup(true)}>
          <FilterIcon />
        </IconButton>
        <Box
          display="flex"
          alignItems="center"
          gap={theme.spacing(3)}
        >
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => setIsSettingOpen(true)}
          >
            <SettingsIcon />
          </IconButton>
        </Box>
      </Box>
      {isSettingsOpen && (<ConversionsSettingsPopup handleClose={() => setIsSettingOpen(false)} />)}
      {isOpenFilterPopup && (
        <ConversionsFiltersPopup handleClose={() => setIsOpenFilterPopup(false)} />
      )}
    </>
  )
}
