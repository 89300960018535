import React from 'react'

import { type ITerm } from '@/store/terms'
import { Box } from '@mui/material'
import { ReactComponent as EditTermIcon } from '@/assets/icons/edit-term.svg'
import { ReactComponent as DeleteIcon } from '@/assets/icons/delete.svg'
import { format } from 'date-fns'
import IconButton from '@/designSystem/IconButton'
import { iconData } from '@/designSystem/SelectIconTagField'

export const TermsItem = ({ term, deleteTerm, editTerm }:
{
  term: ITerm
  deleteTerm: (id: string) => void
  editTerm: (id: string) => void
}): JSX.Element => {
  const validDate = (date: Date) => { return date && !isNaN(date.getTime()) }
  const createdAt = validDate(new Date(term?.createdAt)) ? format(new Date(term?.createdAt), 'dd/MM/yyyy HH:mm:ss') : term?.createdAt
  const matchedIcon = term?.icon && iconData?.find((item) => item?.value === term.icon)?.icon

  const handleDelete = () => {
    deleteTerm(term?.id)
  }

  const handleEdit = () => {
    editTerm(term?.id)
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '196px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 16px 20px 16px',
        borderRadius: '16px',
        border: '1px solid #4141D5',
        backgroundColor: '#FFFFFF'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'start'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px'
          }}
        >
          {matchedIcon &&
            <Box border={'1px solid #4141D5'} display={'inline-block'} height={'47px'} borderRadius={'10px'}>
              {matchedIcon}
            </Box>
          }
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '6px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px'
              }}
            >
              <Box sx={{ color: '#000931', fontSize: '18px', fontWeight: 500 }}>{term?.label}</Box>
              *
              <Box sx={{ color: '#7A7F8F', fontSize: '18px', fontWeight: 400 }}>{term?.format}</Box>
            </Box>
            <Box sx={{ color: '#393E51', fontSize: '14px', fontWeight: 400 }}>{term?.description}</Box>
            {createdAt && <Box sx={{ color: '#393E51', fontSize: '14px', fontWeight: 400 }}>{createdAt}</Box>}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '28px'
          }}>
          <IconButton sx={{ background: 'white' }} onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
          <IconButton sx={{ background: 'white' }} onClick={handleEdit}>
            <EditTermIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          minHeight: '55px',
          maxHeight: '75px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '7px 14px',
          margin: '7px 0',
          borderRadius: '16px',
          border: '1px solid #4141D5',
          backgroundColor: '#FFFFFF',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >{term?.formula}</Box>
    </Box>
  )
}
