import { Box, Typography } from '@mui/material'
import { LineChart, lineElementClasses } from '@mui/x-charts/LineChart'
import React, { useEffect } from 'react'
import { axisClasses } from '@mui/x-charts/ChartsAxis'
import CircularProgress from '@mui/material/CircularProgress'
import { selectGeneralCharts, selectGlobalTimeRange, type IChartLead, type IGetChartsAnswer } from '@/store/dashboard'
import { generateChartDayData, generateChartLastMonthData, generateChartLastWeekData, generateChartLastYearData, generateChartMonthData, generateChartWeekData, generateChartYearData, generateChartYesterdayData, getCurrentDay, getCurrentMonth, getCurrentWeek, getCurrentYear, getLastMonth, getLastWeek, getLastYear, getYesterday } from '../../utils'
import { useSelector } from 'react-redux'
import { LeadState } from '@/enums/leads.enum'

const RejectChart = () => {
  const [xData, setXData] = React.useState<any>()
  const [rejectDataChart, setRejectDataChart] = React.useState<any>(null)
  const [rejectDataChartLast, setRejectDataChartLast] = React.useState<any>(null)

  const [yData, setYData] = React.useState<any>()
  const searchLeadChart = useSelector(selectGeneralCharts)
  const globalTimeRange = useSelector(selectGlobalTimeRange)
  const [yPastData, setPastYData] = React.useState<any>()

  useEffect(() => {
    searchLeadChart.leads && setRejectDataChart(searchLeadChart.leads?.filter((lead: IChartLead) => lead.state === LeadState.HOLD))
    if (searchLeadChart.timePeriod !== 'none') {
      searchLeadChart.pastTimeLeads && setRejectDataChartLast(searchLeadChart.pastTimeLeads?.filter((lead: IChartLead) => lead.state === LeadState.HOLD))
    }
  }, [searchLeadChart.leads, searchLeadChart.pastTimeLeads, searchLeadChart.timePeriod])

  useEffect(() => {
    if (rejectDataChart) {
      if (searchLeadChart.timePeriod === 'none') {
        if (globalTimeRange?.globalTimeSelect === 'today') {
          const { xData, yData } = generateChartDayData(rejectDataChart)
          setYData(yData)
          setXData(xData)
        } else if (globalTimeRange?.globalTimeSelect === 'currentYear') {
          const { xData, yData } = generateChartYearData(rejectDataChart)
          setYData(yData)
          setXData(xData)
        } else if (globalTimeRange?.globalTimeSelect === 'lastMonth') {
          const { xData, yData } = generateChartLastMonthData(rejectDataChart)
          setYData(yData)
          setXData(xData)
        } else if (globalTimeRange?.globalTimeSelect === 'currentWeek') {
          const { xData, yData } = generateChartWeekData(rejectDataChart)
          setYData(yData)
          setXData(xData)
        } else if (globalTimeRange?.globalTimeSelect === 'yesterday') {
          const { xData, yData } = generateChartYesterdayData(rejectDataChart)
          setYData(yData)
          setXData(xData)
        } else if (globalTimeRange?.globalTimeSelect === 'lastWeek') {
          const { xData, yData } = generateChartLastWeekData(rejectDataChart)
          setYData(yData)
          setXData(xData)
        } else if (globalTimeRange?.globalTimeSelect === 'currentMonth') {
          const { xData, yData, zData } = generateChartMonthData(rejectDataChart)
          setYData(yData)
          setXData(xData)
        }
      } else if (searchLeadChart.timePeriod === 'yesterday_today') {
        const { xData, yData } = generateChartDayData(rejectDataChart)
        const { yData: yLast } = generateChartYesterdayData(rejectDataChartLast)
        setYData(yData)
        setXData(xData)
        setPastYData(yLast)
      } else if (searchLeadChart.timePeriod === 'lastWeek_currentWeek') {
        const { xData, yData } = generateChartWeekData(rejectDataChart)
        const { yData: yLast } = generateChartLastWeekData(rejectDataChartLast)
        setYData(yData)
        setXData(xData)
        setPastYData(yLast)
      } else if (searchLeadChart.timePeriod === 'lastMonth_currentMonth') {
        const { xData, yData } = generateChartMonthData(rejectDataChart)
        const { yData: yLast } = generateChartLastMonthData(rejectDataChartLast)
        setYData(yData)
        setXData(xData)
        setPastYData(yLast)
      } else if (searchLeadChart.timePeriod === 'lastYear_currentYear') {
        const { xData, yData } = generateChartYearData(rejectDataChart)
        const { yData: yLast } = generateChartLastYearData(rejectDataChartLast)
        setYData(yData)
        setXData(xData)
        setPastYData(yLast)
      }
    }
  }, [globalTimeRange?.globalTimeSelect, rejectDataChartLast, rejectDataChart, searchLeadChart.timePeriod])
  return (
  <>
  {(searchLeadChart.timePeriod === 'none' ? (xData && yData) : (xData && yData && yPastData))
    ? (<LineChart
          grid={{ vertical: true, horizontal: true }}
          height={550}
          margin={{ top: 40, right: 60, bottom: 140, left: 70 }}
          slotProps={{
            legend: {
              labelStyle: {
                fontSize: 14,
                fill: 'black',
                fontWeight: 500
              },
              itemMarkWidth: 20,
              itemMarkHeight: 20,
              markGap: 19,
              itemGap: 19,
              position: {
                vertical: 'bottom',
                horizontal: 'middle'
              },
              direction: 'row',
              // position: { vertical: 'top', horizontal: 'middle' },
              padding: 20
            }
          }}
          bottomAxis={{
            disableLine: true,
            disableTicks: true,
            tickLabelStyle: {
              // angle: 7,
              textAnchor: 'start',
              fontSize: 18,
              lineHeight: 30,
              fill: '##000931'
            }
          }}
          leftAxis={{
            disableLine: true,
            disableTicks: true,
            tickLabelStyle: {
              // angle: 7,
              textAnchor: 'end',
              fontSize: 18,
              lineHeight: 30,
              fill: '##000931'
            }
          }}
          series={searchLeadChart.timePeriod === 'none'
            ? [
                {
                  data: yData,
                  label: 'Reject',
                  area: true,
                  showMark: false,
                  curve: 'linear',
                  highlightScope: {
                    highlighted: 'item',
                    faded: 'global'
                  }
                }
              ]
            : [
                {
                  data: yData,
                  label: 'Reject',
                  area: true,
                  showMark: false,
                  curve: 'linear',
                  highlightScope: {
                    highlighted: 'item',
                    faded: 'global'
                  }
                },
                {
                  data: yPastData,
                  label: searchLeadChart.timePeriod === 'yesterday_today' ? 'Reject Yesterday' : searchLeadChart.timePeriod === 'lastWeek_currentWeek' ? 'Reject Last Week' : searchLeadChart.timePeriod === 'lastMonth_currentMonth' ? 'Reject Last Month' : searchLeadChart.timePeriod === 'lastYear_currentYear' ? 'Reject Last Year' : '',
                  area: true,
                  showMark: false,
                  curve: 'linear',
                  highlightScope: {
                    highlighted: 'item',
                    faded: 'global'
                  }
                }
              ]}
          xAxis={[{
            scaleType: 'point',
            data: xData,
            valueFormatter: (value: number | null) => (value === null ? '' : value.toString())
          }]}
          yAxis={[
            {
              valueFormatter: (value) => `${(value).toLocaleString()}`,
              // label: 'Number',
              // position: 'right',
              labelStyle: {
                fontSize: 24,
                fontFamily: 'sans-serif',
                fill: 'black',
                fontWeight: 700,
                writingMode: 'vertical-lr'
                // transform: 'translateY(40px)',
              }
            }
          ]}
          sx={{
            [`& .${lineElementClasses.root}`]: {
              display: 'flex'
            },
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              // Move the y-axis label with CSS
              transform: 'translateX(-75px)'
            }
          }}
      />)
    : (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '550px' }}>
      <CircularProgress />
    </Box>)
    }
  </>
  )
}

export default RejectChart
