import React, { useMemo } from 'react'
import Chip from '@/components/Advertisers/Filters/Chip'
import { useDispatch, useSelector } from 'react-redux'
import {
  type HoldsFilters
} from '@/store/hold'
import { Box, useTheme } from '@mui/material'
import cloneDeep from 'lodash.clonedeep'
import unset from 'lodash.unset'
import { selectHoldQueryParams, setHoldQueryParams } from '@/store/queryParams'

const Bar = ({ children }: { children: React.ReactNode }) => {
  const { spacing, palette } = useTheme()
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={spacing(3)}
      justifyContent="flex-start"
      padding={spacing(3, 4)}
      width="100%"
      borderRadius="12px"
      sx={{
        backgroundColor: palette.card.main
      }}
    >
      {children}
    </Box>
  )
}

const areFiltersExist = (filters?: HoldsFilters) => {
  if (!filters) {
    return false
  }

  return Object.entries(filters).some(([key, value]) => {
    if (Array.isArray(value)) return !!value.length
    return !!value
  })
}

export default function LeadsFilterBar () {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectHoldQueryParams)

  const isParamExist = useMemo(() => areFiltersExist(queryParams?.filters), [queryParams?.filters])

  if (!isParamExist) {
    return null
  }

  const onDeleteParam = (label: string) => () => {
    const params = cloneDeep(queryParams)
    unset(params, `filters.${label}`)
    dispatch(setHoldQueryParams(params))
  }

  const filters = queryParams.filters!

  if (!isParamExist) {
    return null
  }

  return (<Bar>
      {Object.entries(filters).map(([key, value]) => <Chip
          key={key}
          label={`${key}: ${JSON.stringify(value).replaceAll('"', '')}`}
          onDelete={onDeleteParam(key)}
        />)}
    </Bar>
  )
}
