import React, { useState } from 'react'
import RightActionButtonsLayout from '@/designSystem/Pages/TrafficDistribution/RotationControl/RightActionButtons'
import AddPopup from './AddPopup'

interface Props {
  setErrorMessage: (value: string) => void
}

export default function RightActionButtons ({ setErrorMessage }: Props) {
  const [isAddPopup, setIsAddPopup] = useState(false)

  return (
    <>
      <RightActionButtonsLayout
        onClickAdd={() => setIsAddPopup(true)}
      />
      {isAddPopup && (
        <AddPopup setErrorMessage={setErrorMessage} isCreate={true} handleClose={() => setIsAddPopup(false)} nodeParentId={null}/>
      )}
    </>
  )
}
