import React from 'react'

import ResetTimePopup, {
  type SettingPopupFormProps
} from '@/designSystem/Pages/TrafficDistribution/RotationControl/Popups/ResetTime'

type SettingsPopupProps = Pick<SettingPopupFormProps, 'handleClose' | 'data'>

export default function SettingPopup ({ handleClose, data }: SettingsPopupProps) {
  return (
    <ResetTimePopup
      handleClose={handleClose}
      data={data}
    />
  )
}
