import React from 'react'
import { Box, Tooltip, Typography, useTheme } from '@mui/material'
import { type TimeReset } from '@/store/rotationControl'
import FlexBox from '@/designSystem/FlexBox'
import { formatTime } from '@/designSystem/TreeView/PQL/helpers'

interface Props {
  data: TimeReset[]
}

const CounterTooltip = ({ data }: Props) => {
  const { spacing } = useTheme()
  const { length } = data
  if (length <= 1) {
    return null
  }
  return <Tooltip title={<Box>
    {data.slice(1).map((item) => <Box key={item.id}>{formatTime(item.hour)}:00 UTC</Box>)}
  </Box>}>
    <Box sx={{
      borderRadius: spacing(1),
      background: '#000e4b',
      color: 'white',
      padding: `${spacing(1)} ${spacing(2)}`,
      cursor: 'default !important'
    }}>+{length - 1}</Box>
</Tooltip>
}

const ResetTime = ({ data }: Props) => {
  const { spacing } = useTheme()
  const { length } = data
  if (length === 0) { return null }
  return <FlexBox sx={{ gap: spacing(2), alignItems: 'center' }}>
    <Typography variant='h2'>Reset time: {formatTime(data[0].hour)}:00 UTC</Typography>
    <CounterTooltip data={data}/>
    </FlexBox>
}

export default ResetTime
