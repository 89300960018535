import type { RootState } from '@/store'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface PatchUser {
  password?: string
  name?: string
  role?: string
  accessRights?: string[]
  accessCodes?: string[]
}

interface RequestUser {
  email: string
  password: string
  name: string
  role: string
}

export interface IUsers {
  id: string
  email: string
  name: string
  status: string
  lastLoggedIn: string
  role: string
  createdAt: string
  updatedAt: string
  accessRights?: string[]
}

interface SearchUsersRequest {
  page: number
  limit: number
  sortField: string
  sortOrder: string
}

interface SearchUsersResponse {
  users: IUsers[]
  total: number
}

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/users`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    createUser: builder.mutation<RequestUser, IUsers>({
      query: (user) => ({
        url: '',
        method: 'POST',
        body: user
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }]
    }),
    searchUsers: builder.query<SearchUsersResponse, SearchUsersRequest>({
      query: (params) => ({
        url: '/search',
        method: 'POST',
        body: params
      }),
      providesTags: [{ type: 'Users', id: 'LIST' }]
    }),
    getUser: builder.query<IUsers, string>({
      query: (userId) => ({
        url: `/${userId}`,
        method: 'GET'
      })
    }),
    updateUser: builder.mutation<IUsers, { user: PatchUser, userId: string }>({
      query: ({ user, userId }) => ({
        url: `/${userId}`,
        method: 'PATCH',
        body: user
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }]
    }),
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    deleteUser: builder.mutation<void, string>({
      query: (userId: string) => ({
        url: `/${userId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }]
    })
  })
})

export const {
  useCreateUserMutation,
  useSearchUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation
} = usersApi

interface UsersState {
  users: IUsers[]
}

const initialState: UsersState = {
  users: []
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<IUsers[]>) => {
      state.users = action.payload
    }
  }
})

export const { setUsers } = usersSlice.actions

export const selectUsers = (state: RootState) => state.users.users

export default usersSlice.reducer
