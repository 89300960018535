import React from 'react'

export default function Settings () {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="12" fill="#F1F3FF"/>
      <path d="M40.4985 28.0007C40.4985 27.6173 40.4818 27.2507 40.4485 26.8673L43.5485 24.5173C44.2151 24.0173 44.3985 23.084 43.9818 22.3507L40.8651 16.9673C40.6653 16.6143 40.3421 16.3476 39.9575 16.2184C39.573 16.0891 39.1543 16.1066 38.7818 16.2673L35.1985 17.784C34.5818 17.3507 33.9318 16.9673 33.2485 16.6507L32.7651 12.8007C32.6651 11.9673 31.9485 11.334 31.1151 11.334H24.8985C24.0485 11.334 23.3318 11.9673 23.2318 12.8007L22.7485 16.6507C22.0651 16.9673 21.4151 17.3507 20.7985 17.784L17.2151 16.2673C16.4485 15.934 15.5485 16.234 15.1318 16.9673L12.0151 22.3673C11.5985 23.1007 11.7818 24.0173 12.4485 24.534L15.5485 26.884C15.4794 27.6324 15.4794 28.3856 15.5485 29.134L12.4485 31.484C11.7818 31.984 11.5985 32.9173 12.0151 33.6507L15.1318 39.034C15.5485 39.7673 16.4485 40.0673 17.2151 39.734L20.7985 38.2173C21.4151 38.6507 22.0651 39.034 22.7485 39.3507L23.2318 43.2007C23.3318 44.034 24.0485 44.6673 24.8818 44.6673H31.0985C31.9318 44.6673 32.6485 44.034 32.7485 43.2007L33.2318 39.3507C33.9151 39.034 34.5651 38.6507 35.1818 38.2173L38.7651 39.734C39.5318 40.0673 40.4318 39.7673 40.8485 39.034L43.9651 33.6507C44.3818 32.9173 44.1985 32.0007 43.5318 31.484L40.4318 29.134C40.4818 28.7507 40.4985 28.384 40.4985 28.0007ZM28.0651 33.834C24.8485 33.834 22.2318 31.2173 22.2318 28.0007C22.2318 24.784 24.8485 22.1673 28.0651 22.1673C31.2818 22.1673 33.8985 24.784 33.8985 28.0007C33.8985 31.2173 31.2818 33.834 28.0651 33.834Z" fill="#4141D5"/>
    </svg>
  )
}
