import React from 'react'

export default function FtdFromManual () {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="45" height="45" rx="12" fill="white" />
      <path d="M18.6067 27.9686L14.1534 23.5153C14.037 23.3974 13.8983 23.3038 13.7454 23.2399C13.5925 23.1761 13.4284 23.1432 13.2628 23.1432C13.0971 23.1432 12.933 23.1761 12.7801 23.2399C12.6272 23.3038 12.4885 23.3974 12.3721 23.5153C12.2542 23.6317 12.1606 23.7704 12.0968 23.9233C12.0329 24.0762 12 24.2402 12 24.4059C12 24.5716 12.0329 24.7357 12.0968 24.8886C12.1606 25.0415 12.2542 25.1801 12.3721 25.2966L17.7033 30.6278C18.1996 31.1241 19.0011 31.1241 19.4974 30.6278L32.9845 17.1534C33.1024 17.037 33.196 16.8983 33.2598 16.7454C33.3237 16.5925 33.3566 16.4284 33.3566 16.2628C33.3566 16.0971 33.3237 15.933 33.2598 15.7801C33.196 15.6272 33.1024 15.4885 32.9845 15.3721C32.8681 15.2542 32.7294 15.1606 32.5765 15.0968C32.4236 15.0329 32.2596 15 32.0939 15C31.9282 15 31.7641 15.0329 31.6112 15.0968C31.4583 15.1606 31.3196 15.2542 31.2032 15.3721L18.6067 27.9686Z" fill="#4141D5" />
    </svg>
  )
}
