import React from 'react'

import { useForm, type SubmitHandler } from 'react-hook-form'
import AddPopupLayout, {
  type Inputs,
  type AddPopupProps as AddPopupMuiProps
} from '@/designSystem/Pages/Advertisers/Popups/AddOrEdit'

import { useEditAdvertiserMutation, type Advertiser } from '@/store/advertisers'

type AddPopupProps = Pick<AddPopupMuiProps, 'handleClose'> & {
  defaultValues: Advertiser
}

export default function EditPopup ({
  handleClose,
  defaultValues: { id, status, pullLeadStatuses, name, description }
}: AddPopupProps) {
  const [editAdvertiser] = useEditAdvertiserMutation()

  const form = useForm<Inputs>({
    defaultValues: {
      name,
      description,
      pullLeadStatuses: { value: pullLeadStatuses },
      active: status === 'ACTIVE'
    }
  })

  const onSubmit: SubmitHandler<Inputs> = async ({ name, description, pullLeadStatuses, active }) => {
    await editAdvertiser({
      name,
      description,
      id,
      pullLeadStatuses: pullLeadStatuses?.value,
      status: active ? 'ACTIVE' : 'INACTIVE'
    })
    handleClose()
  }

  return (
    <AddPopupLayout
      form={form}
      onSubmit={onSubmit}
      handleClose={handleClose}
      title='Edit Advertiser'
      subtitle={'Advertiser:  ' + id}
    />
  )
}
