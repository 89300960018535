import React, { type ReactNode, useState, useEffect } from 'react'
import { Box, Menu, MenuItem, Typography, useTheme } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { type SxProps, styled } from '@mui/material'
import PostBacksItem from './PostBacksItem'
import List from '@mui/material/List'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ListItem from '@mui/material/ListItem'
import { type PassingRules, ESoftFilled, useSearchPostBacksQuery, selectPostBackData, setPostBack, setPriority, type UpdatePriorityPostbacksItem, useUpdatePriorityPostbacksMutation } from '@/store/postBacks'
import { useSearchLeadChartQuery } from '@/store/dashboard'
import { selectPostbacksQueryParams } from '@/store/queryParams'
export enum ERenderer {
  COUNTRY = 'country',
  FREE_PQL = 'free_pql',
}
function findPriorityPostbacks (inputArray: PassingRules[]): UpdatePriorityPostbacksItem[] {
  return inputArray?.map(({ id, priority }) => ({ id, priority }))
}
export function findPriorityItemPostbacks (inputArray: PassingRules[]): UpdatePriorityPostbacksItem[] {
  return inputArray?.map(({ id, priority }, index) => ({ id, priority: index + 1 }))
}

export default function PostBacksList () {
  const dispatch = useDispatch()
  const poskDataState: PassingRules[] = useSelector(selectPostBackData)
  const { filter } = useSelector(selectPostbacksQueryParams)
  const [postBacks, setPostBackData] = useState(poskDataState ?? [])
  console.log('🚀 ~ PostBacksList ~ postBacks:', postBacks)
  const { data: postbacksApi }: any = useSearchPostBacksQuery({ sortField: ESoftFilled.PRIORITY, filter })
  const [updatePriorityPostbacks] = useUpdatePriorityPostbacksMutation()

  useEffect(() => {
    if (postbacksApi) {
      postbacksApi && dispatch(setPostBack(postbacksApi.passingRules ?? []))
      setPostBackData(postbacksApi.passingRules ?? [])
      postbacksApi && dispatch(setPriority(findPriorityPostbacks(postbacksApi.passingRules)))
    }
  }, [postbacksApi])

  const handleOnDragEnd = async (result: any) => {
    if (!result.destination) return
    const items = Array.from(postBacks)
    const [reorderedItem] = items.splice(result.source.index, 1)
    console.log('🚀 ~ handleOnDragEnd ~ reorderedItem:', reorderedItem)
    items.splice(result.destination.index, 0, reorderedItem)
    setPostBackData(items)
    await updatePriorityPostbacks({
      items: findPriorityItemPostbacks(items)
    })

    console.log('🚀 ~ handleOnDragEnd ~ items:', items)
    console.log('🚀 ~ handleOnDragEnd2 ~ items:', findPriorityItemPostbacks(items))
  }
  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable" direction='vertical'>
          {(provided) => (
            <List
              ref={provided.innerRef}
              {...provided.droppableProps}>
              {postBacks
                .map((postBack, index) => (
                  <Draggable
                    key={postBack.id}
                    draggableId={postBack.id}
                    index={index}
                  >
                    {(provided) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <PostBacksItem postback={postBack} />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>

      </DragDropContext>
    </>
  )
}
