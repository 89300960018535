import React, { useEffect } from 'react'

import { useForm, type SubmitHandler } from 'react-hook-form'
import AddPopupLayout, {
  type Inputs,
  type AddPopupProps as AddPopupMuiProps,
  goalTypeOptions
} from '@/designSystem/Pages/TrafficDistribution/RotationControl/Popups/AddOrEdit'
import { useCreateRotationMutation, useEditRotationMutation, useDeleteRotationMutation, useGetRotationsQuery } from '@/store/rotationControl'
import { ERenderer, EType, type IRotationControl } from './RotationControlTypes'
import { useSelector } from 'react-redux'
import { selectRotationControlQueryParams } from '@/store/queryParams'

type AddPopupProps = Pick<AddPopupMuiProps, 'handleClose' | 'nodeParentId'> & { rotation?: IRotationControl, isCreate: boolean, setErrorMessage: (value: string) => void }

export default function AddPopup ({ handleClose, nodeParentId, rotation, isCreate, setErrorMessage }: AddPopupProps) {
  const form = useForm<any>({
    defaultValues: { ...!isCreate ? { ...rotation, goalType: goalTypeOptions.find((i) => i.value === rotation?.goalType) } : { weight: 1, fillFirst: false } }
  })

  const { filters } = useSelector(selectRotationControlQueryParams)
  const rotations = useGetRotationsQuery({ filters })?.data

  let priority = 1
  if (!rotation?.id) {
    priority = (rotations && rotations.length > 0) ? rotations[rotations.length - 1].priority + 1 : priority
  } else {
    priority = rotation.children.length > 0 ? rotation.children[rotation.children.length - 1].priority + 1 : priority
  }

  const [addRotation, { error: createError }] = useCreateRotationMutation()
  const [editRotation, { error: editError }] = useEditRotationMutation()
  const [deleteRotation] = useDeleteRotationMutation()

  useEffect(() => {
    if (editError ?? createError) {
      setErrorMessage((editError ?? createError as any)?.data.message)
    }
  }, [editError, createError])

  const onSubmit: SubmitHandler<Inputs> = async (form) => {
    const {
      name,
      renderer,
      nodeParentId,
      active,
      pql,
      advertiserId,
      type,
      description,
      ads,
      capAmount,
      revenue,
      goalType,
      rejectedCap,
      rejectedTimeoutCap,
      weight,
      fillFirst,
      resetCounterHours
    } = form

    const pqlTransformer = (pql: any, renderer: ERenderer) => {
      if (renderer === ERenderer.Country) {
        if (typeof pql === 'string') return pql
        const result = `country IN ['${pql.value}']`
        return result
      }
      if (renderer === ERenderer.PassAll) {
        return 'true'
      }
      return pql
    }

    if (!isCreate) { // add to existing
      if (nodeParentId) {
        if (rotation?.advertiserId === null && type === EType.Advertiser) {
          await addRotation({
            name,
            advertiserId,
            renderer,
            revenue,
            goalType: goalType.value,
            fillFirst,
            description,
            weight,
            pql: pqlTransformer(pql, renderer),
            status: active ? 'ACTIVE' : 'INACTIVE',
            nodeParentId: rotation?.nodeParentId,
            type: EType.Advertiser,
            capAmount,
            rejectedCap,
            rejectedTimeoutCap,
            resetCounterHours: resetCounterHours ?? []
          })
        } else {
          await editRotation({
            id: nodeParentId,
            advertiserId,
            name,
            renderer,
            pql: pqlTransformer(pql, renderer),
            status: active ? 'ACTIVE' : 'INACTIVE',
            description,
            resetCounterHours: resetCounterHours ?? [],
            type,
            ...type === EType.Advertiser ? { rejectedCap } : {},
            ...type === EType.Advertiser ? { rejectedTimeoutCap } : {},
            ...type === EType.Advertiser ? { goalType: goalType.value } : {},
            ...type === EType.Advertiser ? { capAmount } : {}
          })
          for (let i = 0; i < ads.length; i++) {
            const child = ads[i]
            const { name, advertiserId, revenue, goalType, fillFirst, weight, pql, status, type, capAmount, rejectedCap, rejectedTimeoutCap } = child
            if (child.temp) {
              await addRotation({
                name,
                advertiserId,
                renderer,
                ...type === EType.Advertiser ? { revenue } : {},
                ...type === EType.Advertiser ? { goalType } : {},
                fillFirst,
                weight,
                pql: renderer === ERenderer.PassAll ? 'true' : pql,
                status,
                nodeParentId,
                type: EType.Advertiser,
                ...type === EType.Advertiser ? { capAmount } : {},
                ...type === EType.Advertiser ? { rejectedCap } : {},
                ...type === EType.Advertiser ? { rejectedTimeoutCap } : {}
              })
            }
            if (child.isOnDelete) {
              await deleteRotation({ id: child.id })
            }
            if (child.touched && !child.isOnDelete) {
              const {
                advertiserId,
                renderer,
                revenue,
                goalType,
                fillFirst,
                weight,
                pql,
                status,
                rejectedCap,
                rejectedTimeoutCap,
                capAmount,
                resetCounterHours
              } = child
              await editRotation({
                advertiserId,
                revenue,
                goalType,
                fillFirst,
                weight,
                pql: pqlTransformer(pql, renderer ?? ERenderer.PassAll),
                status,
                capAmount,
                type: EType.Advertiser,
                rejectedCap,
                rejectedTimeoutCap,
                id: child.id,
                resetCounterHours: resetCounterHours ?? []
              })
            }
          }
        }
      }
    } else { // new
      const data = await addRotation({
        name,
        renderer,
        pql: pqlTransformer(pql, renderer),
        type,
        status: active ? 'ACTIVE' : 'INACTIVE',
        description,
        resetCounterHours: resetCounterHours ?? [],
        priority,
        ...nodeParentId ? { nodeParentId } : {},
        ...type === EType.Advertiser ? { advertiserId } : {},
        ...type === EType.Advertiser ? { revenue } : {},
        ...type === EType.Advertiser ? { goalType: goalType.value } : {},
        ...type === EType.Advertiser ? { capAmount } : {},
        ...type === EType.Advertiser ? { rejectedCap } : {},
        ...type === EType.Advertiser ? { rejectedTimeoutCap } : {},
        ...type === EType.Advertiser ? { weight } : {},
        ...type === EType.Advertiser ? { fillFirst } : {}
      }).unwrap()
      if (data?.id && !active) {
        await editRotation({ id: data.id, status: 'INACTIVE' })
      }
      if (data?.id && ads.length) {
        for (let i = 0; i < ads.length; i++) {
          const child = ads[i]
          const { name, advertiserId, revenue, goalType, fillFirst, weight, status, rejectedCap, rejectedTimeoutCap, capAmount } = child
          await addRotation({
            name,
            advertiserId,
            renderer,
            revenue,
            goalType,
            fillFirst,
            weight,
            pql: pqlTransformer(pql, renderer ?? ERenderer.PassAll),
            status,
            capAmount,
            nodeParentId: data.id,
            type: EType.Advertiser,
            rejectedCap,
            rejectedTimeoutCap
          })
        }
      }
    }
    handleClose()
  }

  return (<AddPopupLayout
    nodeParentId={nodeParentId}
    parentRotation={rotation}
    form={form}
    onSubmit={onSubmit}
    handleClose={handleClose}
    title={isCreate ? 'Create Passing Rule' : 'Edit Passing Rule'}
    subtitle='Choose type & enter data'
    isCreate={isCreate}
  />
  )
}
