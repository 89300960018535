import FlexBox from '@/designSystem/FlexBox'
import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@/designSystem/IconButton'
import { useCreateSaleStatusMappingMutation } from '@/store/saleStatusesMapping'
import { TextField, useTheme } from '@mui/material'

const CreateSaleStatus = () => {
  const { spacing } = useTheme()
  const [title, setTitle] = useState('')

  const [createSaleStatus] = useCreateSaleStatusMappingMutation()

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    await createSaleStatus({
      advertiserSaleStatus: title
    })
    setTitle('')
  }
  return <FlexBox sx={{ paddingTop: spacing(3), gap: spacing(2) }}>
        <TextField sx={{ width: '400px', input: { padding: '8px !important' } }} size='small' value={title} onChange={(e) => setTitle(e.target.value)} />
        <IconButton onClick={onSubmit}><AddIcon sx={{ fill: 'white' }}/></IconButton>
    </FlexBox>
}

export default CreateSaleStatus
