import React from 'react'
import SignInPage from '@/pages/SignInPage'
import { Route, Routes } from 'react-router-dom'

const PublicRoutes = () => {
  return (
      <Routes>
        <Route path="/" element={<SignInPage />} />
        <Route path="*" element={<SignInPage />} />
      </Routes>
  )
}

export default PublicRoutes
