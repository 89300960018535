/* eslint-disable react/prop-types */
import React from 'react'

import { Box, Typography } from '@mui/material'
import BasicTable from '@/designSystem/BasikTable'
import { ReactComponent as EmptyTableIcon } from '@/assets/icons/empty-table.svg'
import AutoInjectTasksPagination from '../AutoInjectTasksPagination'

const AutoInjectTableWrapper = ({
  rowsAutoInjectMain, rowsAutoInjectSettings,
  timeStampsAutoInjectSettings, rowsAutoInjectFilters, rowsAutoInjectTasks, tasksLength
}: any) => {
  return (
    <Box
      width={'1650px'}
      sx={{
        overflowY: 'auto'
      }}
    >
      <Typography
        width="max-content"
        sx={{
          color: '#000931',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          textTransform: 'capitalize',
          paddingTop: '40px',
          paddingBottom: '12px'
        }}
      >
        Basic Information
      </Typography>
      <BasicTable
        headers={['UUID', 'Name', 'Description', 'Status']}
        rows={rowsAutoInjectMain}
      />
      <Typography
        width="max-content"
        sx={{
          color: '#000931',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          textTransform: 'capitalize',
          paddingTop: '24px',
          paddingBottom: '12px'
        }}
      >
        Settings
      </Typography>
      <BasicTable
        headers={['Countries', 'Interval', 'Final State', 'Auto login']}
        rows={rowsAutoInjectSettings}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '12px',
          paddingTop: '24px'
        }}
      >
        <Box>
          <Typography
            width="max-content"
            sx={{
              color: '#000931',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              textTransform: 'capitalize',
              paddingTop: '24px',
              paddingBottom: '12px'
            }}
          >
            Filters
          </Typography>
          <BasicTable
            headers={['From Date', 'To Date', 'From Hours', 'State', 'Override Fields']}
            rows={rowsAutoInjectFilters}
          />
        </Box>
        <Box>
          <Typography
            width="max-content"
            sx={{
              color: '#000931',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              textTransform: 'capitalize',
              paddingTop: '24px',
              paddingBottom: '12px'
            }}
          >
            Timestamps
          </Typography>
          <BasicTable
            headers={['Created At', 'Updated At']}
            rows={timeStampsAutoInjectSettings}
          />
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '12px',
            paddingTop: '47px',
            paddingBottom: '12px'
          }}
        >
          <Typography
            width="max-content"
            sx={{
              color: '#000931',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              textTransform: 'capitalize'
            }}
          >
            Auto Injector Processed Leads
          </Typography>
          <AutoInjectTasksPagination totalCountPage={tasksLength} />
        </Box>
        <BasicTable
          headers={['Lead UUID', 'Lead State', 'New Sent Lead UUIDs', 'Processing Status', 'Started At', 'Finished At', 'Action ID']}
          rows={rowsAutoInjectTasks || []}
        />
        {rowsAutoInjectTasks?.length === 0 && <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding='40px 0'
          width="100%"
          height="300px"
          borderRadius="12px"
          marginTop="24px"
          gap="24px"
          sx={{
            backgroundColor: '#FFFFFF'
          }}
        >
          <EmptyTableIcon />
          <Typography
            variant='body1'
            color="text1"
            textAlign='center'
            sx={{
              fontWeight: '500',
              width: '445px',
              color: '#000931'
            }}
          >
            Data wasn’t found for the current filters.
          </Typography>
        </Box>
        }
      </Box>
    </Box>
  )
}

export default AutoInjectTableWrapper
