import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@/designSystem/Button'
import useTheme from '@mui/material/styles/useTheme'
import { IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import QuestionMarkIcon from '@/designSystem/PageContentLayout/Icons/QuestionMark'
import ConversionsInfoModal from './ConversionsInfoModal'

export default function LeftActionButtons (): JSX.Element {
  const [openSupportConversionsModal, setOpenSupportConversionsModal] = useState(false)
  const theme = useTheme()
  const navigate = useNavigate()

  const onClickReload = () => navigate(0)

  const handleOpenInfoModal = () => {
    setOpenSupportConversionsModal(true)
  }

  return (
    <>
      <Box
        display="flex"
        gap={theme.spacing(3)}
        alignItems="center"
      >
        <IconButton onClick={handleOpenInfoModal}>
          <QuestionMarkIcon />
        </IconButton>
        <Button
          sx={{
            borderRadius: '16px',
            height: '30px',
            padding: `${theme.spacing(1.5)} ${theme.spacing(6)}`
          }}
          variant='contained'
          onClick={onClickReload}
          text="Reload"
          textVariant='subtitle1'
        />
      </Box>
      {openSupportConversionsModal && (
        <ConversionsInfoModal handleClose={() => setOpenSupportConversionsModal(false)} />
      )}
    </>
  )
}
