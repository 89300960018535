import React from 'react'
import FlexBox from '@/designSystem/FlexBox'
import { useGetAdvertiserQuery } from '@/store/advertisers'
import CloseIcon from '@mui/icons-material/Close'
import { itemsStyles } from './JsxStyles'

interface Props {
  id: string
  removeItem: () => void
}

const AdvertiserSelected = ({ id, removeItem }: Props) => {
  const { data } = useGetAdvertiserQuery(id)
  return <FlexBox sx={itemsStyles}>{data?.name} <CloseIcon onClick={removeItem} /></FlexBox>
}

export default AdvertiserSelected
