import React, { type CSSProperties, useState } from 'react'
import { TextField, useTheme, Box, Typography } from '@mui/material'
import { HexColorPicker } from 'react-colorful'
import Popup from '@/designSystem/Popup'
import FlexBox from '@/designSystem/FlexBox'
import Button from '@/designSystem/Button'
import { useCreateSaleStatusMutation, useDeleteSaleStatusMutation, useEditSaleStatusMutation } from '@/store/saleStatuses'

const predefinedColors = [
  '#df632c', '#f19638', '#64d7b5', '#62bede', '#1f23d3', '#7742e5',
  '#c33289', '#e23126', '#60a975', '#56b636', '#721da7', '#2567f6',
  '#f9e64f', '#a1247b', '#ea74d3', '#275517', '#a7e14e', '#b1bc54'
]

const boxSx: CSSProperties = {
  borderRadius: '50%',
  width: 30,
  height: 30
}

const CreateSaleStatus = ({ handleClose, selectedCRMStatus }: any) => {
  const theme = useTheme()
  const [name, setName] = useState(selectedCRMStatus?.key ?? '')
  const [color, setColor] = useState(selectedCRMStatus?.value?.color ?? '#df632c')

  const [createSaleStatus] = useCreateSaleStatusMutation()
  const [editSaleStatus] = useEditSaleStatusMutation()
  const [deleteSaleStatus] = useDeleteSaleStatusMutation()

  const onDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    await deleteSaleStatus({ id: selectedCRMStatus.value.id })
    handleClose()
  }

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    if (selectedCRMStatus) {
      // edit
      await editSaleStatus({
        id: selectedCRMStatus.value.id,
        color,
        name
      })
    } else {
      await createSaleStatus({
        color,
        name
      })
    }
    handleClose()
  }

  return <Popup
    open
    title={'Create/Edit Sale Status'}
    handleClose={handleClose}
    // titleSx={{ color: theme.palette.card.main }}
    rootBoxSx={{
      padding: theme.spacing(4, 6)
    }}
    closeButtonSx={{
      '& .MuiSvgIcon-root': { fill: 'unset' }
    }}
  >
    <Box
      display="flex"
      flexDirection="column"
      gap={theme.spacing(4)}
    >
      <Box>
        <Typography variant="body1">Name</Typography>
        <TextField required sx={{ width: '100%', input: { padding: '8px !important' } }} size='small' value={name} onChange={(e) => setName(e.target.value)} />
      </Box>
      <FlexBox justifyContent='space-between'>
        <FlexBox flexWrap='wrap' gap={3} width={250}>
          {predefinedColors.map((i) => <Box onClick={() => setColor(i)} sx={{ ...boxSx, backgroundColor: i }} key={i} />)}
        </FlexBox>
        <HexColorPicker style={{ width: 135, height: 135, marginRight: '35px' }} color={color} onChange={setColor} />
      </FlexBox>

      <FlexBox gap={3}>
        <Button onClick={handleClose} size='small' text='Cancel' />
        <Button onClick={onSubmit} variant="contained" size='small' text='Submit' />
        {selectedCRMStatus?.value?.id && <Button sx={{ background: 'red', ':hover': { background: '#940f1e' } }} onClick={onDelete} variant="contained" size='small' text='Delete' />}
      </FlexBox>
    </Box>
  </Popup>
}

export default CreateSaleStatus
