import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button, IconButton, Menu, MenuItem } from '@mui/material'

import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow-left.svg'
import { ReactComponent as Arrows } from '@/assets/icons/arrows.svg'
import PageContentLayout from '@/designSystem/PageContentLayout'
import LeftActionButtons from '@/components/Conversions/LeftActionButtons'
import RightActionButtons from '@/components/Conversions/RightActionButtons'
import { selectConversionsMenu, setConversionsMenu } from '@/store/conversions'
import ConversionsTable from '@/components/Conversions/ConversionsTable'

export default function ConversionsPage () {
  const dispatch = useDispatch()
  const menu = useSelector(selectConversionsMenu)

  const isMenu: boolean = !!menu?.id

  const handleSetActive = (name: string) => {
    dispatch(setConversionsMenu({ name, id: '' }))
  }

  const handleReloadInject = () => {
    dispatch(setConversionsMenu({ name: 'All', id: '' }))
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onMenuItemClicked = (name: string) => {
    handleSetActive(name)
    handleClose()
  }

  return (
    <PageContentLayout
      title={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {isMenu && <IconButton onClick={handleReloadInject} sx={{
            marginRight: '16px',
            marginTop: '2px'
          }}>
            <ArrowIcon width={24} height={24} />
          </IconButton>}
          <Box sx={{
            color: '#6B6B6B',
            fontSize: '28px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal'
          }}
          >Conversions:
          </Box>
        </Box>
      }
      activeMenu={<Box sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: '16px',
        marginTop: '2px',
        color: '#000931',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal'
      }}>{menu?.name}
        {!isMenu &&
        <>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            >
            <Arrows />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={() => onMenuItemClicked('')}>All</MenuItem>
            <MenuItem onClick={() => onMenuItemClicked('Late')}>Late</MenuItem>
            <MenuItem onClick={() => onMenuItemClicked('Not Late')}>Not Late</MenuItem>
          </Menu>
        </>}
      </Box>}
      leftActionButtons={<LeftActionButtons />}
      rightActionButtons={!isMenu && <RightActionButtons />}
    >
      {!isMenu && <ConversionsTable />}
    </PageContentLayout>
  )
}
