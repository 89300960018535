import React, { useEffect } from 'react'
import { useGetRotationQuery } from '@/store/rotationControl'
import LogsLayout from './LogsLayout'
import { RotationLogType } from '@/enums/rotations.enum'
import { SentLeadStatus } from '@/enums/sentLeads.enum'
import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/material'
import { toast } from 'react-toastify'
import { getApiError } from '@/utils'

const RejectedLeadsWrapper = ({ id, rejectedCapCount }: { id: string, rejectedCapCount: number }) => {
  const { data, isFetching, isError, error } = useGetRotationQuery({
    id,
    include: ['sentLeads'],
    sentLeadsFilter: { status: SentLeadStatus.REJECTED, take: rejectedCapCount }
  })
  useEffect(() => {
    if (isError && error) {
      console.log('error', error)
      toast.error(getApiError(error))
    }
  }, [isError, error])
  const sentLeads = data?.sentLeads ?? []
  const logs = sentLeads.map((i) => ({
    ...i,
    logData: {
      requestData: i.requestData,
      responseData: i.responseData
    },
    message: `${i.id}`,
    createdAt: new Date(i.createdAt)
  }))
  if (isFetching) {
    return (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '100%', height: '450px' }}>
      <CircularProgress />
    </Box>)
  }
  if (isError) {
    return (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '100%', height: '450px' }}>
      API error
    </Box>)
  }
  return <LogsLayout logs={logs} logType={RotationLogType.REJECTED_LEADS}/>
}

export default RejectedLeadsWrapper
