import React, { useEffect, useMemo } from 'react'

import { Box, type SxProps, Typography, styled, useTheme } from '@mui/material'
import { ReactComponent as PlusIcon } from '@/assets/icons/add-plus-round.svg'
import { ReactComponent as TrushIcon } from '@/assets/icons/trash-red.svg'
import { ReactComponent as ArrowBottomIcon } from '@/assets/icons/arrow-bottom-round.svg'
import { ReactComponent as ArrowUpIcon } from '@/assets/icons/arrow-up-round.svg'
import { type IConditionalItem } from '../Settings/PresetsList/AddEditPresetsModal'
import DateTimePicker from '@/components/Form/DateTimePicker'
import { type UseFormReturn } from 'react-hook-form'
import { type GetDrillReportsArgs } from '@/store/drillReports'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import IOSSwitch from '@/designSystem/IOSSwitch'
import BaseTextField from '@/designSystem/TextField'
import AccountsAutocomplete from '@/pages/Accounts/AccountsAutocomplete'
import { type Option } from '@/designSystem/Autocomplete/types'
import { countryOptions } from '@/constants'
import Autocomplete from '@/designSystem/Pages/Advertisers/Autocomplete'

const subtractDays = (startingDate: Date, daysCount: number) =>
  new Date(new Date().setDate(startingDate.getDate() - daysCount)).toISOString()
const subtractMonth = (startingDate: Date, monthsCount: number) =>
  new Date(new Date().setMonth(startingDate.getMonth() - monthsCount)).toISOString()
const subtractYears = (startingDate: Date, yearsCount: number) =>
  new Date(new Date().setFullYear(startingDate.getFullYear() - yearsCount)).toISOString()

const getDateValues = (someDate: Date): Array<Option<string>> => [
  { title: 'Day', value: subtractDays(someDate, 1) },
  { title: 'Week', value: subtractDays(someDate, 7) },
  { title: '1 Month', value: subtractMonth(someDate, 1) },
  { title: '6 Months', value: subtractMonth(someDate, 6) },
  { title: 'Year', value: subtractYears(someDate, 1) }
]
interface IMetricConditions {
  data: { presetsGroups: string[], conditionFieldsOptions: Record<string, any> }
  form: UseFormReturn<GetDrillReportsArgs>
}

const MetricConditions = ({ data, form }: IMetricConditions) => {
  const theme = useTheme()
  const { palette } = theme

  const [conditional, setConditional] = React.useState<IConditionalItem[]>([
    {
      option: 'Is Test',
      sign: '==',
      value: 'false'
    }
  ])
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false)
  const [checkAndOr, setCheckAndOr] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')

  const { conditionFieldsOptions } = data
  const datePickerSx: SxProps = {
    backgroundColor: palette.inputText.main,
    borderRadius: '16px',
    height: '38px'
  }

  const conditionalOptions = conditionFieldsOptions && Object.keys(conditionFieldsOptions)?.map((key) => ({
    title: key,
    value: key
  }))

  function objectsToString (objects: IConditionalItem[]) {
    const parts = objects.map(obj => `${obj.option} ${obj.sign} ${obj.value}`)
    const orAnd = checkAndOr ? 'AND' : 'OR'
    return parts.join(` ${orAnd} `)
  }

  function formatDateTime (inputTime: Date) {
    const date = new Date(inputTime)
    const formattedDate = date.toISOString()
    return formattedDate
  }

  function areAllFieldsNotEmpty (objects: IConditionalItem[]) {
    return objects.every(obj =>
      Object.values(obj).every(value => typeof value === 'string' && value.trim() !== '')
    )
  }

  useEffect(() => {
    const pql = objectsToString(conditional)
    form.setValue('pql', pql)
    const isNotEmpty = areAllFieldsNotEmpty(conditional)
    setError(!isNotEmpty ? 'Required field and must change all fields' : '')
    if (!isNotEmpty) {
      form.setValue('isEmptyPql', !isNotEmpty)
    } else {
      form.setValue('isEmptyPql', false)
    }
  }, [conditional])

  const deleteConditions = () => {
    setConditional([])
    form.setValue('pql', '')
  }

  const handleAddConditional = () => {
    setConditional([...conditional, {
      option: '',
      sign: '',
      value: ''
    }]
    )
  }

  const handleOpenConditional = () => {
    setIsExpanded(prev => !prev)
  }

  const handleChangeCheckAndOr = (e: any) => {
    setCheckAndOr(e?.target?.checked)
  }

  const handleChangeValueContitional = (index: number, item: string) => (e: any) => {
    const newConditional = [...conditional]
    if (item === 'option') {
      newConditional[index] = {
        [item]: e,
        sign: '',
        value: ''
      }
    } else {
      newConditional[index] = {
        ...newConditional[index],
        [item]: e
      }
    }
    setConditional(newConditional)
  }

  const ConditionalItem = (option: string, sign: string, value: string, index: number) => {
    const optionSign = conditionFieldsOptions?.[option]?.sign
    const isOptions = conditionFieldsOptions?.[option]?.value === 'options' ||
      conditionFieldsOptions?.[option]?.value === 'option'
    const isText = conditionFieldsOptions?.[option]?.value === 'text'
    const isCountry = conditionFieldsOptions?.[option]?.value === 'country'
    const isDate = conditionFieldsOptions?.[option]?.value === 'date'

    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        gap={'7px'}
      >
        <Box
          display={'flex'}
          gap={'20px'}
          width={'100%'}
        >
          <AccountsAutocomplete
            defaultValue={{ value: option, title: option }}
            options={conditionalOptions ?? [{ title: 'Change option', value: null }] as unknown as Array<Option<string>>}
            onChange={(e: any) => handleChangeValueContitional(index, 'option')(e?.value)}
            placeholder="Enter Option"
            sx={{ minWidth: '120px', width: '100%' }}
          />
          {optionSign && <AccountsAutocomplete
            defaultValue={{ value: sign, title: sign }}
            options={optionSign ?? [{ title: 'Change sign', value: null }] as unknown as Array<Option<string>>}
            onChange={(e: any) => handleChangeValueContitional(index, 'sign')(e?.value)}
            placeholder="Change Option"
            sx={{ minWidth: '120px', width: '100%' }}
          />}
          {isText && <BaseTextField
            value={value}
            onChange={(e: any) => handleChangeValueContitional(index, 'value')(e?.target?.value)}
            placeholder="Enter Value"
            sx={{ root: { minWidth: '120px', width: '100%' } }} />}
          {isOptions && <AccountsAutocomplete
            defaultValue={{ value, title: value }}
            options={conditionFieldsOptions?.[option]?.options ??
              [{ title: 'Change option', value: null }] as unknown as Array<Option<string>>}
            onChange={(e: any) => handleChangeValueContitional(index, 'value')(e?.value)}
            placeholder="Change Option"
            sx={{ minWidth: '120px', width: '100%' }} />}
          {isCountry && <AccountsAutocomplete
            defaultValue={{ value, title: value }}
            options={countryOptions ?? [{ title: 'Change country', value: null }] as unknown as Array<Option<string>>}
            onChange={(e: any) => handleChangeValueContitional(index, 'value')(e?.value)}
            placeholder="Change Country"
            sx={{ minWidth: '120px', width: '100%' }} />}
          {isDate && <CustomizedDatePickers sx={datePickerSx}
            value={value}
            onAccept={(e: any) => {
              const date = formatDateTime(e)
              handleChangeValueContitional(index, 'value')(date)
            }}
          />}
        </Box>
        {index < conditional?.length - 1 && <Typography
          sx={{
            color: theme.palette.text1.main,
            fontSize: 15,
            fontWeight: 700
          }}
        >{checkAndOr ? 'AND' : 'OR'}
        </Typography>}
      </Box>
    )
  }

  const conditionalMemo = useMemo(() => {
    return conditional?.map((item, index) => {
      return (
        <Box
          width={'100%'}
          key={index}
        >
          {ConditionalItem(item.option, item.sign, item.value, index)}
        </Box>
      )
    }) ?? []
  }, [conditional, isExpanded])

  return (
    <Box
      display={'flex'}
      alignItems={'start'}
      justifyContent={'space-between'}
      gap={'40px'}
    >
      <Box
        sx={{
          width: '50%',
          minHeight: '70px',
          display: 'flex',
          alignItems: 'center',
          padding: '20px',
          backgroundColor: '#FFFFFF',
          borderRadius: '10px'
        }}
      >
        <Box display="flex" flexDirection="column" width={'100%'}>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography
              sx={{
                color: theme.palette.text1.main,
                fontSize: 18,
                fontWeight: 700
              }}
            >Conditions
            </Typography>
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={'14px'}
            >
              {isExpanded && (
                <>
                  {form.watch('pql') && <Box
                    sx={{
                      cursor: 'pointer',
                      marginTop: '6px'
                    }}
                    onClick={deleteConditions}
                  >
                    <TrushIcon width={'28px'} height={'28px'} />
                  </Box>}
                  {form.watch('pql') && <IOSSwitch
                    checked={checkAndOr}
                    onChange={(e) => handleChangeCheckAndOr(e)}
                    formControlLabel={{
                      label: 'OR / AND',
                      labelPlacement: 'end',
                      sx: {
                        margin: 0,
                        '& .MuiTypography-root': {
                          marginRight: theme.spacing(3),
                          marginLeft: theme.spacing(1.5),
                          marginB0ttom: '2px'
                        }
                      }
                    }} />}
                </>)}
              <Box
                sx={{
                  cursor: 'pointer',
                  marginTop: '5px'
                }}
                onClick={!isExpanded ? handleOpenConditional : handleAddConditional}
              >
                <PlusIcon width={'29px'} height={'29px'} />
              </Box>
              <Box
                sx={{
                  cursor: 'pointer',
                  marginTop: '5px'
                }}
                onClick={handleOpenConditional}
              >
                {!isExpanded && <ArrowBottomIcon width={'29px'} height={'29px'} />}
                {isExpanded && <ArrowUpIcon width={'29px'} height={'29px'} />}
              </Box>
            </Box>
          </Box>
          <ScrollBarContainer>
            <Box display={isExpanded ? 'flex' : 'none'} flexDirection={'column'} maxHeight={'700px'}
              marginTop={'15px'}>
              <Box marginBottom={'10px'}>
                <BaseTextField multiline rows={2} inputHeight='70px' disabled
                  defaultValue={form.watch('pql')} placeholder='Change conditional options'/>
              </Box>
              {conditionalMemo}
              {error && <Typography sx={{
                color: theme.palette.error.main,
                marginTop: '1px',
                fontSize: '16px'
              }}>{error}</Typography>}
            </Box>
          </ScrollBarContainer>
        </Box>
      </Box>
      <Box
        sx={{
          width: '50%',
          minHeight: '70px',
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          padding: '21px 20px',
          justifyContent: 'center',
          backgroundColor: '#FFFFFF',
          borderRadius: '10px'
        }}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={'20px'}
        >
          <CustomizedDatePickers
            sx={datePickerSx}
            disabled={!!form.watch('dateAt')?.value}
            label={form.watch('dateAt')?.value ? '' : 'Select date'}
            name='createdAt.from'
            onAccept={(e: any) => {
              form.setValue('createdAt.from', e)
              form.setValue('dateAt', { value: '', title: '' })
            }}
          />
          -
          <CustomizedDatePickers
            sx={datePickerSx}
            disabled={!!form.watch('dateAt')?.value}
            label={form.watch('dateAt')?.value ? '' : 'Select date'}
            name='createdAt.to'
            onAccept={(e: any) => {
              form.setValue('createdAt.to', e)
              form.setValue('dateAt', { value: '', title: '' })
            }}
          />
          <Autocomplete
            sx={{ width: '100%', cursor: 'pointer' }}
            onChange={(e: any) => {
              form.setValue('dateAt', e)
              form.setValue('createdAt', {
                from: '',
                to: ''
              })
            }}
            options={getDateValues(new Date())}
            value={form.watch('dateAt')}
            placeholder='Date'
            leftShift
          />
        </Box>
      </Box>
    </Box>
  )
}

export default MetricConditions

const CustomizedDatePickers = styled(DateTimePicker)(`  
.MuiInputBase-root{
  background: #FFFFFF;
  border-radius: 16px;
  padding: 10px 16px;
}
input{
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #393E51;
}
label{
  color: #6B6B6B;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: -9px;
  &.Mui-focused{
    margin-top: -4px;
  }
}
`)
