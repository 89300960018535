import React from 'react'

import FlexBox from '@/designSystem/FlexBox'

import { Typography, useTheme } from '@mui/material'
import Popup from '@/designSystem/Popup'

interface AutoInjectionModalProps {
  handleClose: () => void
}
export default function AutoInjectionInfoModal ({
  handleClose
}: AutoInjectionModalProps) {
  return (
    <Popup
      open
      handleClose={handleClose}
      title="Help Information"
      subTitle="Auto Injector"
    >
      <FlexBox gap={3} column>
        <Paragraph body="Auto Injector re-pushes rejected leads to other advertisers until they reach a selected state, for example, FTD." />
        <Paragraph body="Configure which leads to re-push: filter them by geolocation, state, related affiliate, etc." />
        <Paragraph body="Auto Injector creates a task for each lead. A task gets closed either when it reaches the deadline\retry limit or when a re-push is successful. Limits are customizable." />
        <Paragraph body="When a task gets closed, a new one gets created automatically in the Auto Injector." />
      </FlexBox>
    </Popup>
  )
}

interface ParagraphProps {
  body: string
}
function Paragraph ({ body }: ParagraphProps) {
  const { palette } = useTheme()
  return (
    <FlexBox gap={2} column>
      <Typography variant="body2" color={palette.text2.main}>{body}</Typography>
    </FlexBox>
  )
}
