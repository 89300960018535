import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ChartLayout from '@/designSystem/ChartLayout/ChartLayout'
import GeneralChartRightButtons from './GeneralChartRightButtons'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentRange, selectGeneralCharts, setGeneralCharts, useSearchChartRevenueQuery, useSearchLeadChartQuery } from '@/store/dashboard'
import LeadFTDChart from './Charts/LeadFTDChart'
import RevenueChart from './Charts/RevenueChart'
import RejectChart from './Charts/RejectChart'
import GeneralChartLeftButtons from './GeneralChartLeftButtons'

export default function GeneralChart () {
  const dispatch = useDispatch()
  const generalCharts = useSelector(selectGeneralCharts)
  const currentRange = useSelector(selectCurrentRange)
  const searchLeadChartRevenue: any = useSearchChartRevenueQuery(generalCharts.timePeriod === 'none' ? currentRange : generalCharts.localCurrentRange).data
  const searchLeadChartRevenueLast: any = useSearchChartRevenueQuery(generalCharts.localLastRange).data
  const searchLeadChart: any = useSearchLeadChartQuery(generalCharts.timePeriod === 'none' ? currentRange : generalCharts.localCurrentRange).data
  const searchLeadChartLast: any = useSearchLeadChartQuery(generalCharts.localLastRange).data

  useEffect(() => {
    dispatch(
      setGeneralCharts({
        ...generalCharts,
        leads: searchLeadChart?.data && searchLeadChart?.data,
        revenue: searchLeadChartRevenue?.data && searchLeadChartRevenue?.data,
        pastTimeLeads: searchLeadChartLast?.data && searchLeadChartLast?.data,
        pastTimeRevenues: searchLeadChartRevenueLast?.data && searchLeadChartRevenueLast?.data
      })
    )
  }, [searchLeadChartLast?.data, searchLeadChartRevenueLast?.data, searchLeadChartRevenue?.data, searchLeadChart?.data, currentRange])
  return (
    <ChartLayout
    title='General Chart'
    rightActionButtons={
      <GeneralChartRightButtons/>
    }
    leftActionButtons={
      <GeneralChartLeftButtons/>
    }
    width='100%'>
      {(searchLeadChart && searchLeadChart?.data?.length > 0) || (searchLeadChartRevenue && searchLeadChartRevenue?.data?.length > 0)
        ? (<Box width={{ base: '1300px', lg: '100%' }} overflow={'auto'} sx={{ height: '100%', bgcolor: 'white', borderRadius: '16px', display: 'flex', justifyContent: 'center' }}>
        {generalCharts.view === 'revenue' &&
          <RevenueChart />
        }
        {generalCharts.view === 'lead' &&
          <LeadFTDChart />
        }
        {generalCharts.view === 'reject' &&
          <RejectChart />
        }
        </Box>)
        : (<Box sx={{ height: '100%', padding: '24px', bgcolor: 'white', borderRadius: '16px', width: '100%', color: 'black', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
        >
        No data for current period
      </Box>)
      }
    </ChartLayout>
  )
}
