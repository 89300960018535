import { type Option } from '@/designSystem/Autocomplete/types'
import { type FiltersRotationControl } from '@/store/rotationControl'
import { LeadState } from '@/enums/leads.enum'

interface FilterItemType {
  title: string
  name: keyof FiltersRotationControl
  isMultiple: boolean
}

export type IFilterCategories = Record<string, FilterItemType[]>

const filterCategories: IFilterCategories = {
  Main: [
    {
      title: 'Country',
      name: 'country',
      isMultiple: false
    },
    {
      title: 'Advertiser',
      name: 'advertiserId',
      isMultiple: false
    },
    {
      title: 'Only active',
      name: 'status',
      isMultiple: false
    }
  ]
}

const activeStateOptions: Array<Option<LeadState>> = [
  {
    title: 'Duplicated',
    value: LeadState.DUPLICATED
  },
  {
    title: 'Pending',
    value: LeadState.PENDING
  },
  {
    title: 'Pushed Lead',
    value: LeadState.PUSHED_LEAD
  },
  {
    title: 'FTD',
    value: LeadState.FTD
  },
  {
    title: 'FTD Late',
    value: LeadState.FTD_LATE
  }
]

const booleanOptions: Array<Option<boolean>> = [
  {
    title: 'Yes',
    value: true
  },
  {
    title: 'No',
    value: false
  }
]

const sx = {
  '>div>div>div': { padding: '0 0 0 9px !important' },
  '& .MuiInputBase-root': {
    height: '40px',
    borderRadius: '8px',
    width: '330px',
    background: 'white'
  },
  '& .MuiOutlinedInput-root:hover': {
    '& > fieldset': {
      outline: 'none'
    }
  },
  '& .Mui-disabled:hover': {
    '& > fieldset': {
      outline: 'none'
    }
  },
  '& .Mui-disabled:active': {
    '& > fieldset': {
      outline: 'none'
    }
  },
  '& fieldset': { outline: 'none' }
}

export { filterCategories, type FilterItemType, activeStateOptions, booleanOptions, sx }
