import React, { useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import EditPostbackPopup from './OnlyEditModal'
import {
  ESoftFilled, type LinkParameter,
  type PassingRules, useUpdatePostbackMutation,
  selectPostBackData
} from '@/store/postBacks'
import { extractQueryParams } from './AddPostBackPopup'
import { useSelector } from 'react-redux'
export enum ERenderer {
  // GOALTYPE = 'goalType',
  COUNTRY = 'country',
  FREE_PQL = 'free_pql',
}
interface UpdatePopupProps {
  handleClose: () => void
  postback: PassingRules
}
export interface Inputs {
  name: string
  description: string
  goalType: {
    title: string
    value: string
  }
  country?: {
    title: string | any
    value: string | any
  }
  pql?: string
  requestMethod: {
    title: string
    value: string
  }
  postbackLink: string
  linkParameters: LinkParameter[]
  priority?: number
  active: boolean
  renderer: ERenderer
}

export default function UpdatePopup (
  { handleClose, postback }: UpdatePopupProps
) {
  const [countryState, setCountry] = useState<any>()
  const form = useForm<any>({
    defaultValues: {
      name: postback.name,
      description: postback.description,
      goalType: postback.goalType,
      pql: postback.pql,
      country: postback.country?.[0] ? { title: countryState, value: countryState } : { title: '', value: '' },
      requestMethod: postback.requestMethod,
      postbackLink: postback.postbackLink,
      linkParameters: postback.linkParameters,
      priority: postback.priority,
      active: postback.active
    }
  })
  const poskDataState: PassingRules[] = useSelector(selectPostBackData)

  const [updatePostback] = useUpdatePostbackMutation()
  const onSubmit: SubmitHandler<Inputs> = async (form) => {
    const {
      name,
      active,
      pql,
      renderer,
      description,
      country,
      postbackLink,
      requestMethod,
      goalType
    } = form
    await updatePostback({
      id: postback.id,
      name,
      description,
      priority: postback.priority,
      active,
      goalType: goalType.title,
      ...renderer === ERenderer.COUNTRY ? { country: [`${country?.value}`] } : {},
      ...renderer === ERenderer.FREE_PQL ? { pql } : {},
      requestMethod: requestMethod.value,
      postbackLink,
      linkParameters: extractQueryParams(postbackLink)
    })
    handleClose()
  }

  return (<EditPostbackPopup
      // nodeParentId={nodeParentId}
      // parentRotation={postbacks}
      form={form}
      postback={postback}
      onSubmit={onSubmit}
      handleClose={handleClose}
      title='Update Passing Rule'
      subtitle='Update type & data'
    />
  )
}
