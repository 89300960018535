import React, { useEffect, useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import Popup from '@/designSystem/Popup'
import { Box, Typography, useTheme } from '@mui/material'
import ActionButtons from '@/designSystem/ActionButtons'
import { type ITerm, useCreateTermsMutation, useGetPlaceholdersQuery, useEditTermsMutation, selectTermsState } from '@/store/terms'
import Form from '@/components/Form'
import Autocomplete from '@/components/Form/Autocomplete'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import TextField from '@/components/Form/TextField'
import { useSelector } from 'react-redux'
import { SelectIconTagField } from '@/designSystem/SelectIconTagField'
import { ToastContainer, toast } from 'react-toastify'

interface AddTerm {
  label: string
  description: string
  format: { title: string, value: string }
  formula: string
  icon: string
}

interface IPropsAddInjModal {
  handleClose: () => void
  open: boolean
  termId?: string
}
const areBracketsProperlyNested = (str: string): boolean => {
  const stack: string[] = []
  for (const char of str) {
    if (char === '(') {
      stack.push(char) // Push opening bracket onto stack
    } else if (char === ')') {
      if (stack.length === 0) {
        // No matching opening bracket for this closing bracket
        return false
      }
      stack.pop() // Pop the last opening bracket
    }
  }

  // If stack is empty, all brackets are properly nested
  return stack.length === 0
}

export const AddEditTermModal = ({ handleClose, open, termId }: IPropsAddInjModal) => {
  const [addTerm] = useCreateTermsMutation()
  const placeholders = useGetPlaceholdersQuery({})?.data
  const [editTerms] = useEditTermsMutation()
  const term = useSelector(selectTermsState)?.find((term: ITerm) => term.id === termId)

  const theme = useTheme()

  const [error, setError] = useState({ label: '', formula: '', format: '' })

  const defaultValues = {
    label: '',
    description: '',
    format: { title: '', value: '' },
    formula: '',
    icon: ''
  }

  useEffect(() => {
    if (termId) {
      term?.label && form.setValue('label', term?.label)
      term?.description && form.setValue('description', term?.description)
      term?.format && form.setValue('format', { title: term?.format, value: term?.format })
      term?.formula && form.setValue('formula', term?.formula)
      term?.icon && form.setValue('icon', term?.icon)
    }
  }, [termId])

  const form = useForm<AddTerm>({ defaultValues })

  const onSubmit: SubmitHandler<AddTerm> = async (data) => {
    if (!(data?.formula &&
      data?.label && data?.format?.value)) {
      setError({
        label: !data?.label ? 'Required field' : '',
        formula: !data?.formula ? 'Required field' : '',
        format: !data?.format?.value ? 'Required field' : ''
      })
      return
    } else {
      setError({ label: '', formula: '', format: '' })
    }

    const formulaWithoutPlaceholders = data.formula.replace(/\{[^}]*\}/g, '')
    if (!areBracketsProperlyNested(formulaWithoutPlaceholders)) {
      return toast.error('Formula is not valid. Parentheses are not properly nested')
    }
    if (formulaWithoutPlaceholders !== '' && !formulaWithoutPlaceholders.match(/^[0-9+\-*/()\s]+$/)) {
      return toast.error('Formula is not valid. Only +,-,*, (), / and digits are allowed')
    }

    if (termId) {
      await editTerms({
        id: term?.id,
        label: data.label ?? '',
        description: data.description ? data.description : undefined,
        format: data.format?.value,
        formula: data.formula ?? '',
        icon: data.icon
      })
      handleClose()
      form.reset()
      return
    }
    await addTerm({
      label: data.label ?? '',
      description: data.description ? data.description : undefined,
      format: data.format?.value,
      formula: data.formula ?? '',
      icon: data.icon
    }).unwrap()
    handleClose()
  }

  return (
    <Popup
      open={open}
      title={termId ? 'Edit Term' : ' Create Term'}
      subTitle={termId ? undefined : 'New Item'}
      handleClose={handleClose}
      overflow='auto'
      maxWidth='600px'
    >
      <ScrollBarContainer>
        <Form form={form} onSubmit={onSubmit}>
          <Box
            display="flex"
            flexDirection="column"
          >
            <Box display="flex" flexDirection="column" gap={'15px'}>
              <Box>
                <TextField title='Label' name='label' />
                <Typography sx={{
                  color: theme.palette.error.main,
                  marginTop: '1px',
                  fontSize: '12px'
                }}>{error.label}</Typography>
              </Box>

              <Box>
                <TextField
                  multiline
                  rows={2}
                  inputHeight='70px'
                  plusPlaceholder
                  name='formula'
                  title='Formula'
                  placeholders={placeholders} />
                <Typography sx={{
                  color: theme.palette.error.main,
                  marginTop: '1px',
                  fontSize: '12px'
                }}>{error.formula}</Typography>
              </Box>

              <Box>
                <Autocomplete
                  title='Format'
                  textFieldProps={{
                    root: {
                      title: 'Format',
                      placeholder: 'Choose Format'
                    }
                  }}
                  name="format"
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  options={[{ title: 'MONEY', value: 'MONEY' },
                    { title: 'PERCENTAGE', value: 'PERCENTAGE' }, { title: 'NUMBER', value: 'NUMBER' }]}
                />
                <Typography sx={{
                  color: theme.palette.error.main,
                  marginTop: '1px',
                  fontSize: '12px'
                }}>{error.format}</Typography>
              </Box>

              <SelectIconTagField form={form} />

              <TextField title='Description' name='description' />

            </Box>
            <ActionButtons
              sx={{ paddingTop: '24px', paddingBottom: '24px' }}
              onCancel={handleClose}
              submitText="Submit"
              cancelText='Cancel'
            />
          </Box>
        </Form>
      </ScrollBarContainer>
      <ToastContainer/>
    </Popup>
  )
}
