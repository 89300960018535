import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'

export const apiTokensHeaders: Record<string, string> = {
  id: 'UUID',
  name: 'Name',
  token: 'Token',
  active: 'Active',
  ips: 'IPs',
  phone: 'Created At',
  leadProfile: 'Lead Profile',
  settings: 'Settings'
}
export interface IApiTokens {
  id?: string
  name: string
  ips?: string[]
  leadProfile?: Record<string, string>
  apiToken?: string
  active?: boolean
  token?: string
}

export interface ApiTokensFilters {
  createdAt?: {
    from?: string
    to?: string
  }
  name?: string | undefined
}

export interface ApiTokensState {
  apiTokens: IApiTokens[]
  totalCount?: number
  menu?: {
    name: string
    id: string
  }
}

const initialState: ApiTokensState = {
  apiTokens: [],
  totalCount: 0,
  menu: { name: '', id: '' }
}

export const apiTokensSlice = createSlice({
  name: 'apiTokens',
  initialState,
  reducers: {
    setApiTokensTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setApiTokensMenu: (state, action: PayloadAction<{ name: string, id: string }>) => {
      state.menu = action.payload
    },
    setApiTokens: (state, action: PayloadAction<IApiTokens[]>) => {
      state.apiTokens = action.payload
    }
  }
})

export const { setApiTokensTotalCount, setApiTokensMenu, setApiTokens } = apiTokensSlice.actions

export const selectApiTokensTotalCount = (state: RootState) => state.apiTokens.totalCount
export const selectApiTokensMenu = (state: RootState) => state.apiTokens.menu
export const selectApiTokens = (state: RootState) => state.apiTokens.apiTokens

export default apiTokensSlice.reducer

interface GetApiTokensArgs {
  limit?: number
  page?: number
  filters?: ApiTokensFilters
}

type CreateEditApiTokensArgs = Pick<IApiTokens, 'name' | 'ips' | 'leadProfile' | 'apiToken' | 'active' | 'id'>

interface GetApiTokensReturn {
  apiTokens: IApiTokens[]
  totalCount: number
}

export const apiTokensApi = createApi({
  reducerPath: 'apiTokensApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/api-token`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['ApiTokens'],
  endpoints: (builder) => ({
    getApiTokens: builder.query<GetApiTokensReturn, GetApiTokensArgs>({
      query: ({ limit = 10, page = 1, filters }) => ({
        url: '/search',
        method: 'POST',
        body: {
          limit,
          page,
          sortField: 'createdAt',
          sortOrder: 'desc',
          createdAt: filters?.createdAt ?? undefined,
          filter: filters
            ? {
                name: filters?.name ?? undefined
              }
            : undefined
        }
      }),
      providesTags: [{ type: 'ApiTokens', id: 'ApiTokensLIST' }]
    }),
    createApiTokens: builder.mutation<IApiTokens, CreateEditApiTokensArgs>({
      query: ({ name, ips, leadProfile }) => ({
        url: '',
        method: 'POST',
        body: {
          name: name ?? '',
          ips: ips ?? undefined,
          leadProfile: leadProfile ?? undefined
        }
      }),
      invalidatesTags: [{ type: 'ApiTokens', id: 'ApiTokensLIST' }]
    }),
    updateApiTokens: builder.mutation<IApiTokens, Partial<CreateEditApiTokensArgs>>({
      query: ({ ips, id, leadProfile, active }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: {
          ips,
          leadProfile,
          active
        }
      }),
      invalidatesTags: [{ type: 'ApiTokens', id: 'ApiTokensLIST' }]
    }),
    deleteApiTokens: builder.mutation<IApiTokens, Partial<CreateEditApiTokensArgs>>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'ApiTokens', id: 'ApiTokensLIST' }]
    })
  })
})

export const {
  useGetApiTokensQuery, useCreateApiTokensMutation,
  useUpdateApiTokensMutation, useDeleteApiTokensMutation
} = apiTokensApi
