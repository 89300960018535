import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'

export interface ITermFilters {
  label?: string
  createdTo?: {
    from: string
    to: string
  }
}
export interface TermsState {
  totalCount?: number
  termsState?: ITerm[]
}

const initialState: TermsState = {
  totalCount: 0,
  termsState: []
}
export interface ITerm {
  id: string
  label: string
  formula: string
  format: 'MONEY' | 'PERCENTAGE' | 'NUMBER'
  description?: string
  icon: string
  createdAt: string
  updatedAt: string
}

export const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    setTermsTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setTermsState: (state, action: PayloadAction<any>) => {
      state.termsState = action.payload
    }
  }
})

export const { setTermsTotalCount, setTermsState } = termsSlice.actions

export const selectTermsTotalCount = (state: RootState) => state.terms.totalCount
export const selectTermsState = (state: RootState) => state.terms.termsState

export default termsSlice.reducer

interface GetTermsArgs {
  limit?: number
  page?: number
  filters?: ITermFilters
}

interface GetTermsReturn {
  totalCount?: number
  terms: ITerm[]
}

export const termsApi = createApi({
  reducerPath: 'termsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/terms`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['Terms'],
  endpoints: (builder) => ({
    getTerms: builder.query<GetTermsReturn, GetTermsArgs>({
      query: ({ limit = 10, page = 1, filters = {} }) => ({
        url: '/search',
        method: 'POST',
        body: {
          limit,
          page,
          sortField: 'priority',
          sortOrder: 'asc',
          createdAt: filters?.createdTo !== undefined ? filters?.createdTo : undefined,
          filter: Object.keys(filters).length
            ? {
                label: filters?.label ? filters?.label : undefined
              }
            : undefined
        }
      }),
      providesTags: [{ type: 'Terms', id: 'TermsLIST' }]
    }),
    createTerms: builder.mutation({
      query: ({
        ...rest
      }) => ({
        url: '',
        method: 'POST',
        body: {
          ...rest
        }
      }),
      invalidatesTags: [{ type: 'Terms', id: 'TermsLIST' }]
    }),
    getPlaceholders: builder.query({
      query: () => ({
        url: '/placeholders',
        method: 'GET'
      })
    }),
    deleteTerms: builder.mutation({
      query: (id: string) => ({
        url: `/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Terms', id: 'TermsLIST' }]
    }),
    editTerms: builder.mutation({
      query: ({ id, label, formula, format, description, icon }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: {
          label,
          formula,
          format,
          description,
          icon
        }
      }),
      invalidatesTags: [{ type: 'Terms', id: 'TermsLIST' }]
    }),
    updateTermOrder: builder.mutation({
      query: (ids: string[]) => ({
        url: '/update-terms-order',
        method: 'POST',
        body: {
          items: ids
        }
      }),
      invalidatesTags: [{ type: 'Terms', id: 'TermsLIST' }]
    })
  })
})

export const {
  useGetTermsQuery, useCreateTermsMutation,
  useGetPlaceholdersQuery, useDeleteTermsMutation,
  useEditTermsMutation, useUpdateTermOrderMutation
} = termsApi
