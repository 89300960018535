import React from 'react'

import AutocompleteLayout, {
  type AutocompleteProps as AutocompleteLayoutProps
} from '@/designSystem/Autocomplete'
import { Controller, useFormContext } from 'react-hook-form'
import camelCase from 'lodash.camelcase'

type AutocompleteProps<T> = Omit<AutocompleteLayoutProps<T>, 'onChange'> & {
  name?: string
}

export default function Autocomplete<T> ({
  name,
  ...rest
}: AutocompleteProps<T>) {
  const { control } = useFormContext()
  const fieldName = name ?? camelCase(rest.title)
  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { onChange, value } }) => (
        <AutocompleteLayout
          onChange={onChange}
          value={value}
          {...rest}
        />
      )}
    />
  )
}
