export const zoom = '0.75'

export const windowHeight = '134vh'

export const countryList = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CD: 'Congo',
  CG: 'Congo',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  CI: "Côte d'Ivoire",
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: 'North Korea',
  KR: 'South Korea',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  MK: 'Republic of North Macedonia',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  UM: 'United States Minor Outlying Islands',
  US: 'United States of America',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Bolivarian Republic of)',
  VN: 'Viet Nam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AX: 'Åland Islands'
}

export const placeholderValues = [
  'firstName',
  'lastName',
  'email',
  'ip',
  'affSub1',
  'affSub2',
  'affSub3',
  'affSub4',
  'affSub5',
  'affSub6',
  'affSub7',
  'affSub8',
  'affSub9',
  'affSub10',
  'affSub11',
  'affSub12',
  'affSub13',
  'affSub14',
  'affSub15',
  'country',
  'phone',
  'autoLoginUrl',
  'autoLoginDomain',
  'leadSource',
  'comment',
  'id',
  'createdAt',
  'updatedAt',
  'advertiserId',
  'state',
  'conversionId',
  'isTest',
  'statusCheckedAt',
  'externalId',
  'externalStatus',
  'stateUpdatedAt',
  'withConversion',
  'password',
  'countryLanguage',
  'revenue'
]

export const validTimezones = ['Europe/Kiev', 'America/New_York', 'America/Los_Angeles', 'Asia/Tokyo',
  'Europe/London', 'Australia/Sydney', 'Africa/Johannesburg', 'Australia/Darwin', 'Australia/Brisbane',
  'America/Anchorage', 'America/Halifax', 'Australia/Perth', 'Africa/Maputo',
  'Europe/Paris', 'America/Mexico_City', 'Africa/Nairobi', 'Europe/Moscow', 'Africa/Abidjan',
  'Pacific/Honolulu', 'America/Denver', 'America/St_Johns', 'UTC', 'Africa/Lagos', 'Europe/Lisbon']

export const timeZones = [
  { value: 'Europe/Kiev', title: '+02:00 Europe/Kiev', timezone: '2' },
  { value: 'Europe/Moscow', title: '+03:00 Europe/Moscow', timezone: '3' },
  { value: 'America/New_York', title: '-04:00 America/New_York', timezone: '-4' },
  { value: 'America/Los_Angeles', title: '-07:00 America/Los_Angeles', timezone: '-7' },
  { value: 'Asia/Tokyo', title: '+09:00 Asia/Tokyo', timezone: '9' },
  { value: 'Europe/London', title: '+00:00 Europe/London', timezone: '0' },
  { value: 'Australia/Sydney', title: '+10:00 Australia/Sydney', timezone: '10' },
  { value: 'Africa/Johannesburg', title: '+02:00 Africa/Johannesburg', timezone: '2' },
  { value: 'Australia/Darwin', title: '+09:30 Australia/Darwin', timezone: '9.5' },
  { value: 'Australia/Brisbane', title: '+10:00 Australia/Brisbane', timezone: '10' },
  { value: 'America/Anchorage', title: '-08:00 America/Anchorage', timezone: '-8' },
  { value: 'America/Halifax', title: '-03:00 America/Halifax', timezone: '-3' },
  { value: 'Australia/Perth', title: '+08:00 Australia/Perth', timezone: '8' },
  { value: 'Africa/Maputo', title: '+02:00 Africa/Maputo', timezone: '2' },
  { value: 'Europe/Paris', title: '+02:00 Europe/Paris', timezone: '2' },
  { value: 'America/Mexico_City', title: '-06:00 America/Mexico_City', timezone: '-6' },
  { value: 'Africa/Nairobi', title: '+03:00 Africa/Nairobi', timezone: '3' },
  { value: 'Africa/Abidjan', title: '+00:00 Africa/Abidjan', timezone: '0' },
  { value: 'Pacific/Honolulu', title: '-10:00 Pacific/Honolulu', timezone: '-10' },
  { value: 'America/Denver', title: '-06:00 America/Denver', timezone: '-6' },
  { value: 'America/St_Johns', title: '-02:30 America/St_Johns', timezone: '-2.5' },
  { value: 'America/Los_Angeles', title: '-07:00 America/Los_Angeles', timezone: '-7' },
  { value: 'UTC', title: '+00:00 UTC', timezone: '0' },
  { value: 'Africa/Lagos', title: '+01:00 Africa/Lagos', timezone: '1' },
  { value: 'Europe/Lisbon', title: '+01:00 Europe/Lisbon', timezone: '1' }
]

export const countryOptions = [
  {
    value: 'AF',
    title: 'Afghanistan'
  },
  {
    value: 'AL',
    title: 'Albania'
  },
  {
    value: 'DZ',
    title: 'Algeria'
  },
  {
    value: 'AS',
    title: 'American Samoa'
  },
  {
    value: 'AD',
    title: 'Andorra'
  },
  {
    value: 'AO',
    title: 'Angola'
  },
  {
    value: 'AI',
    title: 'Anguilla'
  },
  {
    value: 'AQ',
    title: 'Antarctica'
  },
  {
    value: 'AG',
    title: 'Antigua and Barbuda'
  },
  {
    value: 'AR',
    title: 'Argentina'
  },
  {
    value: 'AM',
    title: 'Armenia'
  },
  {
    value: 'AW',
    title: 'Aruba'
  },
  {
    value: 'AU',
    title: 'Australia'
  },
  {
    value: 'AT',
    title: 'Austria'
  },
  {
    value: 'AZ',
    title: 'Azerbaijan'
  },
  {
    value: 'BS',
    title: 'Bahamas'
  },
  {
    value: 'BH',
    title: 'Bahrain'
  },
  {
    value: 'BD',
    title: 'Bangladesh'
  },
  {
    value: 'BB',
    title: 'Barbados'
  },
  {
    value: 'BY',
    title: 'Belarus'
  },
  {
    value: 'BE',
    title: 'Belgium'
  },
  {
    value: 'BZ',
    title: 'Belize'
  },
  {
    value: 'BJ',
    title: 'Benin'
  },
  {
    value: 'BM',
    title: 'Bermuda'
  },
  {
    value: 'BT',
    title: 'Bhutan'
  },
  {
    value: 'BO',
    title: 'Bolivia (Plurinational State of)'
  },
  {
    value: 'BQ',
    title: 'Bonaire, Sint Eustatius and Saba'
  },
  {
    value: 'BA',
    title: 'Bosnia and Herzegovina'
  },
  {
    value: 'BW',
    title: 'Botswana'
  },
  {
    value: 'BV',
    title: 'Bouvet Island'
  },
  {
    value: 'BR',
    title: 'Brazil'
  },
  {
    value: 'IO',
    title: 'British Indian Ocean Territory'
  },
  {
    value: 'BN',
    title: 'Brunei Darussalam'
  },
  {
    value: 'BG',
    title: 'Bulgaria'
  },
  {
    value: 'BF',
    title: 'Burkina Faso'
  },
  {
    value: 'BI',
    title: 'Burundi'
  },
  {
    value: 'CV',
    title: 'Cabo Verde'
  },
  {
    value: 'KH',
    title: 'Cambodia'
  },
  {
    value: 'CM',
    title: 'Cameroon'
  },
  {
    value: 'CA',
    title: 'Canada'
  },
  {
    value: 'KY',
    title: 'Cayman Islands'
  },
  {
    value: 'CF',
    title: 'Central African Republic'
  },
  {
    value: 'TD',
    title: 'Chad'
  },
  {
    value: 'CL',
    title: 'Chile'
  },
  {
    value: 'CN',
    title: 'China'
  },
  {
    value: 'CX',
    title: 'Christmas Island'
  },
  {
    value: 'CC',
    title: 'Cocos (Keeling) Islands'
  },
  {
    value: 'CO',
    title: 'Colombia'
  },
  {
    value: 'KM',
    title: 'Comoros'
  },
  {
    value: 'CD',
    title: 'Congo'
  },
  {
    value: 'CG',
    title: 'Congo'
  },
  {
    value: 'CK',
    title: 'Cook Islands'
  },
  {
    value: 'CR',
    title: 'Costa Rica'
  },
  {
    value: 'HR',
    title: 'Croatia'
  },
  {
    value: 'CU',
    title: 'Cuba'
  },
  {
    value: 'CW',
    title: 'Curaçao'
  },
  {
    value: 'CY',
    title: 'Cyprus'
  },
  {
    value: 'CZ',
    title: 'Czechia'
  },
  {
    value: 'CI',
    title: "Côte d'Ivoire"
  },
  {
    value: 'DK',
    title: 'Denmark'
  },
  {
    value: 'DJ',
    title: 'Djibouti'
  },
  {
    value: 'DM',
    title: 'Dominica'
  },
  {
    value: 'DO',
    title: 'Dominican Republic'
  },
  {
    value: 'EC',
    title: 'Ecuador'
  },
  {
    value: 'EG',
    title: 'Egypt'
  },
  {
    value: 'SV',
    title: 'El Salvador'
  },
  {
    value: 'GQ',
    title: 'Equatorial Guinea'
  },
  {
    value: 'ER',
    title: 'Eritrea'
  },
  {
    value: 'EE',
    title: 'Estonia'
  },
  {
    value: 'SZ',
    title: 'Eswatini'
  },
  {
    value: 'ET',
    title: 'Ethiopia'
  },
  {
    value: 'FK',
    title: 'Falkland Islands'
  },
  {
    value: 'FO',
    title: 'Faroe Islands'
  },
  {
    value: 'FJ',
    title: 'Fiji'
  },
  {
    value: 'FI',
    title: 'Finland'
  },
  {
    value: 'FR',
    title: 'France'
  },
  {
    value: 'GF',
    title: 'French Guiana'
  },
  {
    value: 'PF',
    title: 'French Polynesia'
  },
  {
    value: 'TF',
    title: 'French Southern Territories'
  },
  {
    value: 'GA',
    title: 'Gabon'
  },
  {
    value: 'GM',
    title: 'Gambia'
  },
  {
    value: 'GE',
    title: 'Georgia'
  },
  {
    value: 'DE',
    title: 'Germany'
  },
  {
    value: 'GH',
    title: 'Ghana'
  },
  {
    value: 'GI',
    title: 'Gibraltar'
  },
  {
    value: 'GR',
    title: 'Greece'
  },
  {
    value: 'GL',
    title: 'Greenland'
  },
  {
    value: 'GD',
    title: 'Grenada'
  },
  {
    value: 'GP',
    title: 'Guadeloupe'
  },
  {
    value: 'GU',
    title: 'Guam'
  },
  {
    value: 'GT',
    title: 'Guatemala'
  },
  {
    value: 'GG',
    title: 'Guernsey'
  },
  {
    value: 'GN',
    title: 'Guinea'
  },
  {
    value: 'GW',
    title: 'Guinea-Bissau'
  },
  {
    value: 'GY',
    title: 'Guyana'
  },
  {
    value: 'HT',
    title: 'Haiti'
  },
  {
    value: 'HM',
    title: 'Heard Island and McDonald Islands'
  },
  {
    value: 'VA',
    title: 'Holy See'
  },
  {
    value: 'HN',
    title: 'Honduras'
  },
  {
    value: 'HK',
    title: 'Hong Kong'
  },
  {
    value: 'HU',
    title: 'Hungary'
  },
  {
    value: 'IS',
    title: 'Iceland'
  },
  {
    value: 'IN',
    title: 'India'
  },
  {
    value: 'ID',
    title: 'Indonesia'
  },
  {
    value: 'IR',
    title: 'Iran'
  },
  {
    value: 'IQ',
    title: 'Iraq'
  },
  {
    value: 'IE',
    title: 'Ireland'
  },
  {
    value: 'IM',
    title: 'Isle of Man'
  },
  {
    value: 'IL',
    title: 'Israel'
  },
  {
    value: 'IT',
    title: 'Italy'
  },
  {
    value: 'JM',
    title: 'Jamaica'
  },
  {
    value: 'JP',
    title: 'Japan'
  },
  {
    value: 'JE',
    title: 'Jersey'
  },
  {
    value: 'JO',
    title: 'Jordan'
  },
  {
    value: 'KZ',
    title: 'Kazakhstan'
  },
  {
    value: 'KE',
    title: 'Kenya'
  },
  {
    value: 'KI',
    title: 'Kiribati'
  },
  {
    value: 'KP',
    title: 'North Korea'
  },
  {
    value: 'KR',
    title: 'South Korea'
  },
  {
    value: 'KW',
    title: 'Kuwait'
  },
  {
    value: 'KG',
    title: 'Kyrgyzstan'
  },
  {
    value: 'LA',
    title: "Lao People's Democratic Republic"
  },
  {
    value: 'LV',
    title: 'Latvia'
  },
  {
    value: 'LB',
    title: 'Lebanon'
  },
  {
    value: 'LS',
    title: 'Lesotho'
  },
  {
    value: 'LR',
    title: 'Liberia'
  },
  {
    value: 'LY',
    title: 'Libya'
  },
  {
    value: 'LI',
    title: 'Liechtenstein'
  },
  {
    value: 'LT',
    title: 'Lithuania'
  },
  {
    value: 'LU',
    title: 'Luxembourg'
  },
  {
    value: 'MO',
    title: 'Macao'
  },
  {
    value: 'MG',
    title: 'Madagascar'
  },
  {
    value: 'MW',
    title: 'Malawi'
  },
  {
    value: 'MY',
    title: 'Malaysia'
  },
  {
    value: 'MV',
    title: 'Maldives'
  },
  {
    value: 'ML',
    title: 'Mali'
  },
  {
    value: 'MT',
    title: 'Malta'
  },
  {
    value: 'MH',
    title: 'Marshall Islands'
  },
  {
    value: 'MQ',
    title: 'Martinique'
  },
  {
    value: 'MR',
    title: 'Mauritania'
  },
  {
    value: 'MU',
    title: 'Mauritius'
  },
  {
    value: 'YT',
    title: 'Mayotte'
  },
  {
    value: 'MX',
    title: 'Mexico'
  },
  {
    value: 'FM',
    title: 'Micronesia'
  },
  {
    value: 'MD',
    title: 'Moldova'
  },
  {
    value: 'MC',
    title: 'Monaco'
  },
  {
    value: 'MN',
    title: 'Mongolia'
  },
  {
    value: 'ME',
    title: 'Montenegro'
  },
  {
    value: 'MS',
    title: 'Montserrat'
  },
  {
    value: 'MA',
    title: 'Morocco'
  },
  {
    value: 'MZ',
    title: 'Mozambique'
  },
  {
    value: 'MM',
    title: 'Myanmar'
  },
  {
    value: 'NA',
    title: 'Namibia'
  },
  {
    value: 'NR',
    title: 'Nauru'
  },
  {
    value: 'NP',
    title: 'Nepal'
  },
  {
    value: 'NL',
    title: 'Netherlands'
  },
  {
    value: 'NC',
    title: 'New Caledonia'
  },
  {
    value: 'NZ',
    title: 'New Zealand'
  },
  {
    value: 'NI',
    title: 'Nicaragua'
  },
  {
    value: 'NE',
    title: 'Niger'
  },
  {
    value: 'NG',
    title: 'Nigeria'
  },
  {
    value: 'NU',
    title: 'Niue'
  },
  {
    value: 'NF',
    title: 'Norfolk Island'
  },
  {
    value: 'MP',
    title: 'Northern Mariana Islands'
  },
  {
    value: 'NO',
    title: 'Norway'
  },
  {
    value: 'OM',
    title: 'Oman'
  },
  {
    value: 'PK',
    title: 'Pakistan'
  },
  {
    value: 'PW',
    title: 'Palau'
  },
  {
    value: 'PS',
    title: 'Palestine, State of'
  },
  {
    value: 'PA',
    title: 'Panama'
  },
  {
    value: 'PG',
    title: 'Papua New Guinea'
  },
  {
    value: 'PY',
    title: 'Paraguay'
  },
  {
    value: 'PE',
    title: 'Peru'
  },
  {
    value: 'PH',
    title: 'Philippines'
  },
  {
    value: 'PN',
    title: 'Pitcairn'
  },
  {
    value: 'PL',
    title: 'Poland'
  },
  {
    value: 'PT',
    title: 'Portugal'
  },
  {
    value: 'PR',
    title: 'Puerto Rico'
  },
  {
    value: 'QA',
    title: 'Qatar'
  },
  {
    value: 'MK',
    title: 'Republic of North Macedonia'
  },
  {
    value: 'RO',
    title: 'Romania'
  },
  {
    value: 'RU',
    title: 'Russian Federation'
  },
  {
    value: 'RW',
    title: 'Rwanda'
  },
  {
    value: 'RE',
    title: 'Réunion'
  },
  {
    value: 'BL',
    title: 'Saint Barthélemy'
  },
  {
    value: 'SH',
    title: 'Saint Helena, Ascension and Tristan da Cunha'
  },
  {
    value: 'KN',
    title: 'Saint Kitts and Nevis'
  },
  {
    value: 'LC',
    title: 'Saint Lucia'
  },
  {
    value: 'MF',
    title: 'Saint Martin (French part)'
  },
  {
    value: 'PM',
    title: 'Saint Pierre and Miquelon'
  },
  {
    value: 'VC',
    title: 'Saint Vincent and the Grenadines'
  },
  {
    value: 'WS',
    title: 'Samoa'
  },
  {
    value: 'SM',
    title: 'San Marino'
  },
  {
    value: 'ST',
    title: 'Sao Tome and Principe'
  },
  {
    value: 'SA',
    title: 'Saudi Arabia'
  },
  {
    value: 'SN',
    title: 'Senegal'
  },
  {
    value: 'RS',
    title: 'Serbia'
  },
  {
    value: 'SC',
    title: 'Seychelles'
  },
  {
    value: 'SL',
    title: 'Sierra Leone'
  },
  {
    value: 'SG',
    title: 'Singapore'
  },
  {
    value: 'SX',
    title: 'Sint Maarten (Dutch part)'
  },
  {
    value: 'SK',
    title: 'Slovakia'
  },
  {
    value: 'SI',
    title: 'Slovenia'
  },
  {
    value: 'SB',
    title: 'Solomon Islands'
  },
  {
    value: 'SO',
    title: 'Somalia'
  },
  {
    value: 'ZA',
    title: 'South Africa'
  },
  {
    value: 'GS',
    title: 'South Georgia and the South Sandwich Islands'
  },
  {
    value: 'SS',
    title: 'South Sudan'
  },
  {
    value: 'ES',
    title: 'Spain'
  },
  {
    value: 'LK',
    title: 'Sri Lanka'
  },
  {
    value: 'SD',
    title: 'Sudan'
  },
  {
    value: 'SR',
    title: 'Suriname'
  },
  {
    value: 'SJ',
    title: 'Svalbard and Jan Mayen'
  },
  {
    value: 'SE',
    title: 'Sweden'
  },
  {
    value: 'CH',
    title: 'Switzerland'
  },
  {
    value: 'SY',
    title: 'Syrian Arab Republic'
  },
  {
    value: 'TW',
    title: 'Taiwan'
  },
  {
    value: 'TJ',
    title: 'Tajikistan'
  },
  {
    value: 'TZ',
    title: 'Tanzania, United Republic of'
  },
  {
    value: 'TH',
    title: 'Thailand'
  },
  {
    value: 'TL',
    title: 'Timor-Leste'
  },
  {
    value: 'TG',
    title: 'Togo'
  },
  {
    value: 'TK',
    title: 'Tokelau'
  },
  {
    value: 'TO',
    title: 'Tonga'
  },
  {
    value: 'TT',
    title: 'Trinidad and Tobago'
  },
  {
    value: 'TN',
    title: 'Tunisia'
  },
  {
    value: 'TR',
    title: 'Turkey'
  },
  {
    value: 'TM',
    title: 'Turkmenistan'
  },
  {
    value: 'TC',
    title: 'Turks and Caicos Islands'
  },
  {
    value: 'TV',
    title: 'Tuvalu'
  },
  {
    value: 'UG',
    title: 'Uganda'
  },
  {
    value: 'UA',
    title: 'Ukraine'
  },
  {
    value: 'AE',
    title: 'United Arab Emirates'
  },
  {
    value: 'GB',
    title: 'United Kingdom'
  },
  {
    value: 'UM',
    title: 'United States Minor Outlying Islands'
  },
  {
    value: 'US',
    title: 'United States of America'
  },
  {
    value: 'UY',
    title: 'Uruguay'
  },
  {
    value: 'UZ',
    title: 'Uzbekistan'
  },
  {
    value: 'VU',
    title: 'Vanuatu'
  },
  {
    value: 'VE',
    title: 'Venezuela (Bolivarian Republic of)'
  },
  {
    value: 'VN',
    title: 'Viet Nam'
  },
  {
    value: 'VG',
    title: 'Virgin Islands (British)'
  },
  {
    value: 'VI',
    title: 'Virgin Islands (U.S.)'
  },
  {
    value: 'WF',
    title: 'Wallis and Futuna'
  },
  {
    value: 'EH',
    title: 'Western Sahara'
  },
  {
    value: 'YE',
    title: 'Yemen'
  },
  {
    value: 'ZM',
    title: 'Zambia'
  },
  {
    value: 'ZW',
    title: 'Zimbabwe'
  },
  {
    value: 'AX',
    title: 'Åland Islands'
  }
]
