/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useEffect, useState } from 'react'

import EditRoundedIcon from '@mui/icons-material/EditRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Box, IconButton, Typography, useTheme } from '@mui/material'

import FlexBox from '@/designSystem/FlexBox'
import Table from '@/designSystem/Table'
import AddIcon from '@/designSystem/Icons/Add'
import Popup from '@/designSystem/Popup'
import {
  Header,
  CTAField,
  TextField
} from '@/designSystem/Table/Fields'

import ActionButtons from '@/designSystem/ActionButtons'
import TextFieldModal from '@/designSystem/TextField'
import { type IApiSettings } from '@/components/Advertisers/IntegrationSettingsPage/ApiConnectionSettings/EditPushSettingsForm'
import { selectAdvertiserSettings, setAdvertiserSettings, useEditAdvertiserMutation } from '@/store/advertisers'
import { useSelector, useDispatch } from 'react-redux'
import FormAutocomplete from '@/components/Form/FormAutocomplete'
import { countryOptions, placeholderValues } from '@/constants'
import { type Option } from '@/designSystem/Autocomplete/types'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import AccountsAutocomplete from '@/pages/Accounts/AccountsAutocomplete'

const headers = ['Key', 'Value', 'Countries', 'Action']

interface AddIntegrationFieldModalProps {
  open: boolean
  handleClose: () => void
  onSubmit: (obj: IApiSettings[]) => void
  allSettings?: IApiSettings[]
  editFieldId?: string
}

interface RowPushSettings {
  key: string
  group: string
  value?: string
  data?: { countries: string[] }
}
interface PushSettingOverwriteTableProps {
  rows: RowPushSettings[]
  advertiserId?: string
}

interface IOverwriteSettings {
  group: string
  key?: string
  value: string
  data?: { countries: string[] }
}

export default function PushSettingsOverwriteTable ({
  rows,
  advertiserId
}: PushSettingOverwriteTableProps) {
  const dispatch = useDispatch()
  const [editAdvertiser] = useEditAdvertiserMutation()
  const allSettingsState = useSelector(selectAdvertiserSettings)
  const [isOpenAddPopup, setIsOpenAddPopup] = useState(false)
  const [editPopupId, setEditPopupId] = useState<string | null>(null)
  const [settings, setSettings] = useState<IOverwriteSettings[] | undefined>([])

  useEffect(() => {
    const newSettings: IOverwriteSettings[] = reduceOverwriteSettings(rows)
    setSettings(newSettings)
  }, [rows])

  function reduceOverwriteSettings (settings: IApiSettings[]) {
    return settings.reduce((acc: IOverwriteSettings[], setting: IApiSettings) => {
      const isOverwritesGroup = setting?.group === 'overwrites'
      if (isOverwritesGroup) {
        acc.push({
          group: setting?.group ?? '',
          key: setting?.key ?? '',
          value: setting?.value ?? '',
          data: setting?.data?.countries ? setting?.data : undefined
        })
      }
      return acc
    }, [])
  }

  const onSubmit = async (settingsRes: IApiSettings[]) => {
    try {
      const dataAdvertiser = await editAdvertiser({
        id: advertiserId ?? '',
        advertiserSettings: settingsRes
      }).unwrap()
      if (dataAdvertiser?.advertiserSettings) {
        const newSettings: IOverwriteSettings[] = reduceOverwriteSettings(dataAdvertiser?.advertiserSettings)
        setSettings?.(newSettings)
        dispatch(setAdvertiserSettings(dataAdvertiser?.advertiserSettings))
        setIsOpenAddPopup(false)
        setEditPopupId(null)
      }
    } catch (e) {
    }
  }

  const handleAddField = (settingsAll: IApiSettings[]) => {
    void onSubmit(settingsAll)
  }

  const handleRemoveField = (key: string) => {
    const newSettings = allSettingsState?.filter((setting: IApiSettings) =>
      !(setting?.key === key && setting?.group === 'overwrites'))
    if (newSettings) {
      void onSubmit(newSettings)
    }
  }

  const headerNodes = headers.map((header) => <Header header={header} key={header} />)
  const fieldToElementMap: any = useFieldToElementMap((key) => setEditPopupId(key), (key) => handleRemoveField(key))

  const rowNodes = settings?.map((row) => (
    headers.map((header) => fieldToElementMap(header, row))
  ))

  return (
    <>
      <FlexBox column gap={3}>
        <FlexBox gap={4} alignCenter>
          <Typography variant='h2'>
            Params Overwriting
          </Typography>
          <IconButton onClick={() => setIsOpenAddPopup(true)}>
            <AddIcon />
          </IconButton>
        </FlexBox>
        <Table headers={headerNodes} rows={rowNodes ?? []} />
      </FlexBox>
      {isOpenAddPopup && (
        <AddIntegrationFieldModal open={isOpenAddPopup}
          handleClose={() => setIsOpenAddPopup(false)} onSubmit={handleAddField}
          allSettings={allSettingsState} />
      )}
      {editPopupId && (
        <AddIntegrationFieldModal open={Boolean(editPopupId)}
          handleClose={() => setEditPopupId(null)} onSubmit={handleAddField}
          allSettings={allSettingsState} editFieldId={editPopupId} />
      )}
    </>
  )
}

const useFieldToElementMap = (
  edit: (key: string) => void,
  remove: (key: string) => void
) => (header: string, row: RowPushSettings) => {
  const { palette } = useTheme()
  const {
    key,
    value,
    data
  } = row
  const countriesString = data?.countries?.join(', ') ?? ''

  switch (header) {
    case headers[0]:
      return <TextField text={key} />
    case headers[1]:
      return <TextField text={value} limitation={80} />
    case headers[2]:
      return <TextField text={countriesString} />
    case headers[3]:
      return (
      <FlexBox sx={{ width: '130px', justifyContent: 'space-between' }}>
        <CTAField
          onClick={() => edit(key)}
          icon={<EditRoundedIcon sx={{ fill: palette.primary.main }} />}
        />
        <CTAField
          onClick={() => remove(key)}
          icon={<CloseRoundedIcon sx={{ fill: palette.red.main }} />}
        />
      </FlexBox>
      )
  }
}

function AddIntegrationFieldModal ({ open, handleClose, onSubmit, allSettings, editFieldId }: AddIntegrationFieldModalProps) {
  const setting = allSettings?.find((setting: IApiSettings) =>
    (setting?.key === editFieldId) && (setting?.group === 'overwrites'))
  const [value, setValue] = React.useState(editFieldId ? setting?.value : '')
  const [key, setKey] = React.useState(editFieldId ? setting?.key : '')
  const [countries, setCountries] = React.useState<string[] | []>(setting?.data?.countries ? setting?.data?.countries : [])
  const [error, setError] = React.useState({ value: '', countries: '', key: '' })

  const newSetAllSettings = allSettings?.filter((setting: IApiSettings) => setting?.group !== 'overwrites')
  // eslint-disable-next-line array-callback-return
  const keyOptions = newSetAllSettings?.filter((setting: IApiSettings) => setting?.key).map((setting: IApiSettings) => {
    if (setting?.group === 'parameters') {
      return { value: setting?.key, title: setting?.key }
    }
  }).filter(Boolean)

  useEffect(() => {
    setError({ value: '', countries: '', key: '' })
  }, [open])

  const handleKeyChange = (e: { value: string, title: string } | null) => {
    setKey(e?.value)
  }

  const handleValueChange = (e: any) => {
    const isPlaceholder = placeholderValues.includes(e.target.value)
    if (isPlaceholder) {
      setValue(`{{${e.target.value}}}`)
    } else {
      setValue(e.target.value)
    }
  }

  const handleSubmit = () => {
    const isCountries = countries && countries?.length > 0

    if (key && value && isCountries) {
      setError({ value: '', countries: '', key: '' })
    } else if (!key || !value || !isCountries) {
      setError({
        value: !value ? 'Required field' : '',
        key: !key ? 'Required field' : '',
        countries: !isCountries ? 'Required field' : ''
      })
      return
    }
    if (editFieldId) {
      const newSettings = allSettings?.map((setting: IApiSettings) => {
        if (setting?.key === editFieldId) {
          return {
            ...setting,
            key,
            value,
            data: (countries && countries?.length > 0) ? { countries } : undefined
          }
        }
        return setting
      })
      if (newSettings) {
        onSubmit(newSettings)
      }
    } else {
      const settingsFields = allSettings ? [...allSettings] : []
      if (key && value && isCountries) {
        settingsFields?.push({
          group: 'overwrites',
          key,
          value,
          data: (countries && countries?.length > 0) ? { countries } : undefined
        })
        setError({ value: '', countries: '', key: '' })
      }
      onSubmit(settingsFields)
    }
    setValue('')
    setCountries([])
    setKey('')
  }

  return (
    <Popup
      open={open}
      handleClose={handleClose}
      title={editFieldId ? 'Edit Overwrite Field' : 'Add Overwrite Field'}
    >
      <ScrollBarContainer>
        <FlexBox flexDirection={'column'} gap={4} minHeight={'350px'}>
          {<Box>
            <Typography
              color={'#393E51'}
              fontSize={18}
              fontWeight={400}
              marginBottom={'4.5px'}
            >Key
            </Typography>
            <AccountsAutocomplete
              defaultValue={key ? { value: key, title: key } : { value: 'Change Type', title: 'Change Type' }}
              options={keyOptions as unknown as Array<Option<string>>}
              onChange={(e: { value: string, title: string } | null) => { handleKeyChange(e) }}
              placeholder="Change Value"
              sx={{ width: '100%' }}
            />
            <Typography variant='body2' color='error'>
              {error.key}
            </Typography>
          </Box>}
          <Box>
            <TextFieldModal title={'Value'}
              onChange={handleValueChange} value={value}
              placeholders={placeholderValues} />
            <Typography variant='body2' color='error'>
              {error.value}
            </Typography>
          </Box>
          <Box>
            <Box
              color={'#393E51'}
              fontSize={18}
              fontWeight={400}
            >Countries</Box>
            <FormAutocomplete
              name="countries"
              rowSelectedOptions
              options={countryOptions ?? [{ title: 'Select groups', value: null }] as unknown as Array<Option<string>>}
              selectedOptions={countries && countries?.length > 0 ? countries : []}
              onChange={(option: Option<string> | null) => {
                option && setCountries([...countries, option.value])
              }}
              deleteField={(value: string) => () => {
                setCountries(countries?.filter((item: string) => item !== value))
              }}
              sx={{ width: '100%' }}
              textFieldProps={{
                input: {
                  sx: {
                    input: {
                      padding: '0px 0px 0px 10px !important'
                    }
                  }
                }
              }}
            />
            <Typography variant='body2' color='error'>
              {error.countries}
            </Typography>
          </Box>
        </FlexBox>
        <ActionButtons submitText='Submit changes' onCancel={handleClose} onSubmit={handleSubmit}
          sx={{
            marginTop: '20px'
          }}
        />
      </ScrollBarContainer>
    </Popup>
  )
}
