import React from 'react'

import TextFieldLayout, { type TextFiledProps as TextFiledLayoutProps } from '@/designSystem/TextField'

type TextFieldProps = TextFiledLayoutProps & {
  name?: string
  value: string | undefined
  onChange: (e: any) => void
}

export default function SettingsTextField ({ title, value, onChange, ...rest }: TextFieldProps) {
  return (
    <TextFieldLayout
      value={value}
      onChange={onChange}
      title={title}
      {...rest}
    />
  )
}
