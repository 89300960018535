import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { TextField as TextFieldMUI, Typography } from '@mui/material'
import * as AutocompleteDS from '@/designSystem/Autocomplete'
import styled from '@emotion/styled'
import { type UseFormReturn } from 'react-hook-form'
import FlexBox from '@/designSystem/FlexBox'
import { countryOptions } from '@/constants'
import { nanoid } from '@reduxjs/toolkit'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '../../Icons/Add'
import { newPQL, firstPQL, fieldOptions, signOptionsCollection1, signOptionsCollection2, signEqualOnly, weekOptions, testOptions, weekColletion, advertiserCollection } from './constants'
import { stringParcer, transformPqlToString } from './helpers'
import HoursPicker from './HoursPicker'
import AdvertiserSelected from './AdvertiserSelected'
import AdvertiserAutocomplete from './AdvertiserAutocomplete'
import { longLine, connectorStyles, disabledConnector, shortLine, itemsStyles, autocompleteStyle } from './JsxStyles'
import InputText from './InputText'

export interface IPQL {
  connector?: string
  field: string | null
  sign: string | null
  value: string[] | string | boolean | null
}

const CreatePQL = ({ onAdd }: { onAdd: () => void }) => {
  return <FlexBox sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant='h1'>Rules for PQL</Typography>
      <AddIcon onClick={onAdd} />
    </FlexBox>
}

const PQL = styled((props: { form: UseFormReturn<any> }) => {
  const [pql, setPqlValues] = useState<IPQL[]>(stringParcer(props.form.getValues().pql ?? ''))

  useEffect(() => {
    props.form.setValue('pql', transformPqlToString(pql))
  }, [pql])

  const addPQL = () => {
    if (pql.length > 0) {
      setPqlValues(prev => [...prev, { ...newPQL }])
    } else {
      setPqlValues([{ ...firstPQL }])
    }
  }

  const onChangeValue = (value: string | boolean | null | undefined, index: number, pqlField: number, isMultiple: boolean) => {
    if (typeof value === 'string') {
      const tempArray = [...pql]
      switch (pqlField) {
        case 0: tempArray[index].field = value; tempArray[index].sign = null; tempArray[index].value = null; break
        case 1: tempArray[index].sign = value; break
        case 2: {
          if (isMultiple) {
            if (Array.isArray(tempArray[index].value)) {
              tempArray[index].value = [...tempArray[index].value as string[], value]
            } else {
              tempArray[index].value = [value]
            }
          } else {
            tempArray[index].value = value
          }
          break
        }
        case 3: tempArray[index].connector = value; break
      }
      setPqlValues(tempArray)
    }
    if (typeof value === 'boolean') {
      const tempArray = [...pql]
      if (pqlField === 2) {
        tempArray[index].value = value
      }
      setPqlValues(tempArray)
    }
  }

  const removeItem = (value: string, index: number) => {
    const tempArray = [...pql]
    if (Array.isArray(tempArray[index].value)) {
      tempArray[index].value = (tempArray[index].value as string[]).filter((item) => item !== value)
      setPqlValues(tempArray)
    }
  }

  const removePql = (index: number) => {
    const newPql = pql.filter((_, ind) => index !== ind)
    if (index === 0 && newPql.length) {
      newPql[0].connector = undefined
    }
    setPqlValues(newPql)
  }

  const isValid = pql.every((i) => i.field && i.sign && (i.value !== null))

  return <>
    <FlexBox flexDirection={'column'}>
    <Typography sx={{ flex: '1 1 100%', paddingLeft: '10px', paddingBottom: '10px' }}>Query Language</Typography>

    <TextFieldMUI {...props} error={!isValid} helperText={!isValid && 'Invalid input'} disabled={true} size='medium' title="Query Language" name="pql" multiline placeholder='PQL | text' value={transformPqlToString(pql)} />

    </FlexBox>
    <CreatePQL onAdd={addPQL} />
    {pql.map((item, index) => <Box key={nanoid()}>
      <Box>
        {item.connector && <FlexBox alignCenter>
          <Box sx={longLine} />
          <Box
            sx={{ ...connectorStyles, ...item.connector !== 'AND' ? disabledConnector : {} }}
            onClick={() => onChangeValue('AND', index, 3, false)}
          >
            AND
          </Box>
          <Box sx={shortLine} />
          <Box
            sx={{ ...connectorStyles, ...item.connector !== 'OR' ? disabledConnector : {} }}
            onClick={() => onChangeValue('OR', index, 3, false)}
          >
            OR
          </Box>
          <Box sx={longLine} />
        </FlexBox>}
        <FlexBox>
          <AutocompleteDS.default
            title='Field'
            style={{ flex: '2 1 100%' }}
            sx={{ ...autocompleteStyle }}
            onChange={(option) => onChangeValue(option?.value, index, 0, false)}
            value={fieldOptions.find((i) => item.field === i.value) ?? null}
            options={fieldOptions}
            // placeholder='Sign'
          />
          {(fieldOptions.find((i) => item.field === i.value)?.collection === 'literal' || !item.field) && <AutocompleteDS.default
            title='Sign'
            style={{ flex: '1 1 100%' }}
            sx={{ ...autocompleteStyle }}
            onChange={(option) => onChangeValue(option?.value, index, 1, false)}
            value={signOptionsCollection1.find((i) => item.sign === i.value) ?? null}
            options={signOptionsCollection1}
            // placeholder='Sign'
            />}
          {fieldOptions.find((i) => item.field === i.value)?.collection === 'numeral' && <AutocompleteDS.default
            title='Sign'
            style={{ flex: '1 1 100%' }}
            sx={{ ...autocompleteStyle }}
            onChange={(option) => onChangeValue(option?.value, index, 1, false)}
            value={signOptionsCollection2.find((i) => item.sign === i.value) ?? null}
            options={signOptionsCollection2}
            // placeholder='Sign'
            />}
          {fieldOptions.find((i) => item.field === i.value)?.collection === 'advertiser' && <AutocompleteDS.default
            title='Sign'
            style={{ flex: '1 1 100%' }}
            sx={{ ...autocompleteStyle }}
            onChange={(option) => onChangeValue(option?.value, index, 1, false)}
            value={advertiserCollection.find((i) => item.sign === i.value) ?? null}
            options={advertiserCollection}
            // placeholder='Sign'
            />}
            {(fieldOptions.find((i) => item.field === i.value)?.collection === 'week') && <AutocompleteDS.default
            title='Sign'
            style={{ flex: '1 1 100%' }}
            sx={{ ...autocompleteStyle }}
            onChange={(option) => onChangeValue(option?.value, index, 1, false)}
            value={weekColletion.find((i) => item.sign === i.value) ?? null}
            options={weekColletion}
            // placeholder='Sign'
            />}
          {fieldOptions.find((i) => item.field === i.value)?.collection === 'equal' && <AutocompleteDS.default
            title='Sign'
            style={{ flex: '1 1 100%' }}
            sx={{ ...autocompleteStyle }}
            onChange={(option) => onChangeValue(option?.value, index, 1, false)}
            value={signEqualOnly.find((i) => item.sign === i.value) ?? null}
            options={signEqualOnly}
            // placeholder='Sign'
            />}
        </FlexBox>
      </Box>
        <FlexBox flexDirection={'column'}>
          <Typography sx={{ flex: '1 1 100%', paddingLeft: '10px' }}>Value</Typography>
          <FlexBox alignCenter>
          {(fieldOptions.find((i) => item.field === i.value)?.valueField === 'custom' || !item.field) && <InputText value={''} onChange={(value) => onChangeValue(value, index, 2, true)} />}
          {(fieldOptions.find((i) => item.field === i.value)?.valueField === 'advertiser') && <AdvertiserAutocomplete onChangeValue={(option) => onChangeValue(option?.value, index, 2, true)} item={item} />}
          {(fieldOptions.find((i) => item.field === i.value)?.valueField === 'country') && <AutocompleteDS.default getOptionDisabled={(option) => Array.isArray(item.value) && item.value.findIndex((i) => i.includes(option.value)) > -1} sx={autocompleteStyle} options={countryOptions} onChange={(option) => onChangeValue(option?.value, index, 2, true)} />}
          {(fieldOptions.find((i) => item.field === i.value)?.valueField === 'week') && <AutocompleteDS.default getOptionDisabled={(option) => Array.isArray(item.value) && item.value.findIndex((i) => i.includes(option.value)) > -1} sx={autocompleteStyle} options={weekOptions} onChange={(option) => onChangeValue(option?.value, index, 2, true)} />}
          {(fieldOptions.find((i) => item.field === i.value)?.valueField === 'test') && <AutocompleteDS.default getOptionDisabled={(option) => typeof item.value === 'boolean' && option.value === item.value} sx={autocompleteStyle} options={testOptions} onChange={(option) => onChangeValue(option?.value, index, 2, false)} defaultValue={testOptions.find((i) => item.value === i.value)} />}
          {(fieldOptions.find((i) => item.field === i.value)?.valueField === 'hour') && <HoursPicker value={item.value as string} onChange={(value) => onChangeValue(value, index, 2, false)} />}
          <DeleteIcon sx={{ fill: 'red' }} onClick={() => removePql(index)}/>
          </FlexBox>
        </FlexBox>
      <FlexBox sx={{ flexWrap: 'wrap' }}>
        {!!Array.isArray(item.value) && item.field !== 'advertiserUuid' && item.value.map((item) => <FlexBox sx={itemsStyles} key={nanoid()}>{item} <CloseIcon onClick={() => removeItem(item, index)} /></FlexBox>)}
        {!!Array.isArray(item.value) && item.field === 'advertiserUuid' && item.value.map((item) => <AdvertiserSelected key={nanoid()} id={item} removeItem={() => removeItem(item, index)}/>)}
      </FlexBox>
    </Box>)}
    </>
})(() => ({
  height: 'unset',
  '& .MuiOutlinedInput-root': {
    height: 'unset'
  }
}))

export default PQL
