import React, { useState } from 'react'

import {
  Typography,
  IconButton,
  useTheme
} from '@mui/material'

import QuestionMarkIcon from '@/designSystem/PageContentLayout/Icons/QuestionMark'
import FlexBox from '@/designSystem/FlexBox'
import SupportInfoModal from '../SupportInfoModal'
import PushSettingsConnectionTable from './PushSettingsConnectionTable'

export interface Row { key: string, value: string | null }

export interface PushSettingsConnectionProps {
  dataSettings: Array<{
    key?: string
    group: string
    value?: string
    label: string
  }>
  advertiserId: string
}
export default function PushSettingsConnection ({
  dataSettings,
  advertiserId
}: PushSettingsConnectionProps) {
  const { palette } = useTheme()
  const [openSupportModal, setOpenSupportModal] = useState(false)

  return (
    <>
       <PushSettingsConnectionTable rows={dataSettings} advertiserId={advertiserId} />
       {openSupportModal && (
        <SupportInfoModal handleClose={() => setOpenSupportModal(false)} />
       )}

    </>
  )
}
