import React from 'react'
import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'
import Popup from '@/designSystem/Popup'
import { Typography } from '@mui/material'

export interface IInfoPopup {
  handleClose: () => void
}
function Title () {
  return (
    <Typography
      variant='leftBar'
      color={'#000931'}
      fontWeight={600}
    >
      Help Information
    </Typography>
  )
}

export default function AdvertisersLogsInfoPopup ({ handleClose }: IInfoPopup) {
  const theme = useTheme()
  return (
    <Popup
      open
      title={<Title /> as any}
      handleClose={handleClose}
      titleSx={{ color: theme.palette.card.main }}
      maxWidth='716px'
      gap={'0px'}
      rootBoxSx={{
        padding: '24px',
        backgroundColor: '#F1F3FF'
      }}
      closeButtonSx={{
        '& .MuiSvgIcon-root': { fill: '#000931' }
      }}
    >
      <Box sx={{ marginBottom: '24px', color: '#393E51', fontWeight: 400, lineHeight: '23px', fontSize: '18px' }}>
      Advertiser Logs
      </Box>
      <Box sx={{ color: '#393E51', fontWeight: 400, lineHeight: '23px', fontSize: '18px' }}>
      Here you can see the information Advertisers integrations.
      </Box>
    </Popup>
  )
}
