import React from 'react'

export default function ConvertionRate () {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="45" height="45" rx="12" fill="white" />
      <path d="M15.0002 24.0006H23.5862L21.2932 26.2936C21.1111 26.4822 21.0103 26.7348 21.0125 26.997C21.0148 27.2592 21.12 27.51 21.3054 27.6954C21.4908 27.8808 21.7416 27.986 22.0038 27.9882C22.266 27.9905 22.5186 27.8897 22.7072 27.7076L26.7072 23.7076C26.8947 23.52 27 23.2657 27 23.0006C27 22.7354 26.8947 22.4811 26.7072 22.2936L22.7072 18.2936C22.5186 18.1114 22.266 18.0106 22.0038 18.0129C21.7416 18.0152 21.4908 18.1203 21.3054 18.3058C21.12 18.4912 21.0148 18.742 21.0125 19.0042C21.0103 19.2664 21.1111 19.519 21.2932 19.7076L23.5862 22.0006H15.0002V24.0006Z" fill="#4141D5" />
      <path opacity="0.5" d="M23.586 24H15V30C15.0008 30.7954 15.3171 31.558 15.8796 32.1204C16.442 32.6829 17.2046 32.9992 18 33H28C28.7954 32.9992 29.558 32.6829 30.1204 32.1204C30.6829 31.558 30.9992 30.7954 31 30V16C30.9992 15.2046 30.6829 14.442 30.1204 13.8796C29.558 13.3171 28.7954 13.0008 28 13H18C17.2046 13.0008 16.442 13.3171 15.8796 13.8796C15.3171 14.442 15.0008 15.2046 15 16V22H23.586L21.293 19.707C21.1975 19.6148 21.1213 19.5044 21.0689 19.3824C21.0165 19.2604 20.9889 19.1292 20.9877 18.9964C20.9866 18.8636 21.0119 18.7319 21.0622 18.609C21.1125 18.4862 21.1867 18.3745 21.2806 18.2806C21.3745 18.1867 21.4861 18.1125 21.609 18.0622C21.7319 18.0119 21.8636 17.9866 21.9964 17.9877C22.1292 17.9889 22.2604 18.0165 22.3824 18.0689C22.5044 18.1213 22.6148 18.1975 22.707 18.293L26.707 22.293C26.8945 22.4805 26.9998 22.7348 26.9998 23C26.9998 23.2652 26.8945 23.5195 26.707 23.707L22.707 27.707C22.5184 27.8892 22.2658 27.99 22.0036 27.9877C21.7414 27.9854 21.4906 27.8802 21.3052 27.6948C21.1198 27.5094 21.0146 27.2586 21.0123 26.9964C21.01 26.7342 21.1108 26.4816 21.293 26.293L23.586 24Z" fill="#4141D5" />
    </svg>
  )
}
