import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useGetAdvertisersQuery } from '@/store/advertisers'
import { useDebounce } from 'use-debounce'
import RightActionButtonsLayout from '@/designSystem/Pages/Advertisers/RightActionButtonsLayout'
import FiltersPopup from '@/components/Advertisers/Filters/Popup'
import type { Option } from '@/designSystem/Autocomplete/types'
import setProperty from '@/utils/setProperty'
import { format } from 'date-fns'
import { selectQueryParamsAdvertisers, setQueryParamsAdvertisers } from '@/store/queryParams'

const subtractDays = (startingDate: Date, daysCount: number) =>
  new Date(new Date().setDate(startingDate.getDate() - daysCount)).toISOString()
const subtractMonth = (startingDate: Date, monthsCount: number) =>
  new Date(new Date().setMonth(startingDate.getMonth() - monthsCount)).toISOString()
const subtractYears = (startingDate: Date, yearsCount: number) =>
  new Date(new Date().setFullYear(startingDate.getFullYear() - yearsCount)).toISOString()

const getDateValues = (someDate: Date): Array<Option<string>> => [
  { title: 'Day', value: subtractDays(someDate, 1) },
  { title: 'Week', value: subtractDays(someDate, 7) },
  { title: '1 Month', value: subtractMonth(someDate, 1) },
  { title: '6 Months', value: subtractMonth(someDate, 6) },
  { title: 'Year', value: subtractYears(someDate, 1) }
]

export default function RightActionButtons () {
  const [isOpenFilterPopup, setIsOpenFilterPopup] = useState(false)
  const [createdAt, setCreatedAt] = useState<string | null>(null)

  const [name, setName] = useState<string | null>(null)
  const [debouncedName] = useDebounce(name, 450)

  const dispatch = useDispatch()
  const queryParams = useSelector(selectQueryParamsAdvertisers)

  const onChangeDate = (value: Option<string> | null) => {
    setCreatedAt(value?.value ?? null)
    return value?.value && dispatch(setQueryParamsAdvertisers({
      ...queryParams,
      page: 1,
      filters: setProperty({ ...queryParams.filters }, 'createdAt', {
        from: new Date(value?.value).toISOString(),
        to: (new Date()).toISOString()
      })
    }))
  }
  const onInputChangeDate = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value
    if (!value) {
      dispatch(setQueryParamsAdvertisers({
        ...queryParams,
        page: 1,
        filters: setProperty({ ...queryParams.filters }, 'date')
      }))
    }
  }

  const nameOptions: Array<Option<string>> | undefined =
    useGetAdvertisersQuery({ limit: 6, filters: { name: debouncedName ?? '' } })
      .data?.advertisers?.map(({ name }: { name: string }) => ({ title: name, value: name }))
  const onInputChangeName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value
    setName(value ?? null)
    if (!value) {
      dispatch(setQueryParamsAdvertisers({
        ...queryParams,
        filters: setProperty({ ...queryParams.filters }, 'name')
      }))
    }
  }
  const onChangeName = (value: Option<string> | null) => {
    setName(value?.value ?? null)
    return value && dispatch(
      setQueryParamsAdvertisers({
        ...queryParams,
        page: 1,
        filters: setProperty({ ...queryParams.filters }, 'name', value?.value)
      })
    )
  }

  useEffect(() => {
    if (!queryParams.filters?.name) {
      setName(null)
    }
    if (!queryParams.filters?.createdAt) {
      setCreatedAt(null)
    }
  }, [queryParams.filters?.name, queryParams.filters?.createdAt])

  return (
    <>
      <RightActionButtonsLayout
        onResetFilters={() => dispatch(setQueryParamsAdvertisers({ ...queryParams, filters: undefined }))}
        onClickFilter={() => setIsOpenFilterPopup(true)}
        dateFilter={{
          onInputChange: onInputChangeDate,
          onChange: onChangeDate,
          options: getDateValues(new Date()),
          value: createdAt
            ? { value: createdAt, title: format(new Date(createdAt), 'P') }
            : null,
          clearIcon: ''
        }}
        nameFilter={{
          onInputChange: onInputChangeName,
          onChange: onChangeName,
          value: { value: name ?? '', title: name ?? '' },
          clearIcon: '',
          options: nameOptions ?? []
        }}
      />
      {isOpenFilterPopup && (
        <FiltersPopup handleClose={() => setIsOpenFilterPopup(false)} />
      )}
    </>
  )
}
