import React from 'react'
import { BooleanField, CountryField, DateTimeField, TextField } from '@/designSystem/Table/Fields'
import { type Lead, leadsHeaders } from '@/store/leads'
import { Box, Typography, useTheme } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'
import { ReactComponent as ArrBottomIcon } from '@/assets/icons/arr-bottom.svg'
import { ReactComponent as ArrUpIcon } from '@/assets/icons/arr-up.svg'
import { ReactComponent as ThreeDots } from '@/assets/icons/three-dots.svg'
import { FieldMap } from '@/components/Injections/ElementMap'
import { LeadState } from '@/enums/leads.enum'
import { TableLink } from '@/components/Common/Tables/TableLink'

function JsonData ({ data }: { data: string | object }) {
  const { palette } = useTheme()
  return (
    <Typography
      display="flex"
      variant="body1"
      color={palette.text1.main}
      width="max-content"
      sx={{
        maxWidth: '150px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      }}
    >
      {JSON.stringify(data)}
    </Typography>
  )
}

const Indicator = ({ color }: { color?: string }) => {
  return (
    <Box
      position='relative'
      display='flex'
      alignItems='center'
      width={0}
    >
      <Box
        sx={{
          borderRadius: '0px 6px 6px 0px',
          width: '6px',
          height: '36px',
          backgroundColor: color,
          position: 'absolute'
        }}
      />
    </Box>
  )
}

const checkIsPushed = (state: Lead['state'] | string) => {
  switch (state) {
    case LeadState.HOLD:
      return '#DF3D3D'
    case LeadState.PUSHED_LEAD:
      return '#15A81B'
    case LeadState.DUPLICATED:
      return '#7357dd'
    case LeadState.FTD:
      return '#F9C51D'
    case LeadState.FTD_LATE:
      return '#FF7F50'
  }
}

const elementLeadsMap = (header: string, row: Lead, isChecked: boolean, handleCheck: (id: string) => void,
  handleOpenLead: (id: string) => void, handleExpandLead: (id: string) => void,
  handleBackExpandLead: (id: string) => void, setModalLogs: (object: object) => void) => {
  const {
    id,
    country,
    firstName,
    lastName,
    email,
    phone,
    state,
    status,
    isTest,
    ip,
    createdAt,
    advertiserId,
    advertiser,
    additionalInfo,
    affSub1,
    affSub2,
    affSub3,
    affSub4,
    affSub5,
    affSub6,
    affSub7,
    affSub8,
    affSub9,
    affSub10,
    affSub11,
    affSub12,
    affSub13,
    affSub14,
    affSub15,
    advertiserAutoLoginUrl,
    advertiserAutoLoginDomain,
    crmAutoLoginUrl,
    isAutoLoginSuccessful,
    stateUpdatedAt,
    leadSource,
    externalId,
    apiTokenId,
    comment,
    sentLeads,
    isExpand,
    isOpenExpand,
    updatedAt,
    externalStatus,
    statusCheckedAt,
    conversionId,
    withConversion,
    crmSaleStatus
  } = row

  const isWithConversions = sentLeads && sentLeads?.length > 0
  const colorState = checkIsPushed(state || status)

  switch (header) {
    case '::indicatorLeads':
      return <FlexBox
        alignItems="center"
      >
        <Indicator color={colorState} />
        {
          !isExpand && <input
            type='checkbox'
            style={{
              marginLeft: '18px',
              width: '24px',
              height: '24px'
            }}
            checked={isChecked}
            onClick={() => handleCheck(id)}
          />
        }
      </FlexBox >
    case leadsHeaders.id:
      return <Box
        display={'flex'}
        alignItems={'center'}
      >
        <TextField text={id} limitation={1}
                   isEyeIcon={!status} onClickOpen={handleOpenLead} />
        {isWithConversions && <Box
          sx={{
            marginLeft: '8px',
            width: '23px',
            height: '23px',
            cursor: 'pointer'
          }}
          onClick={() => isOpenExpand ? handleBackExpandLead(id) : handleExpandLead(id)}
        >
          {!isOpenExpand && <ArrBottomIcon />}
          {isOpenExpand && <ArrUpIcon />}
        </Box>}
      </Box>
    case leadsHeaders.country:
      return <CountryField countryCode={country} />
    case leadsHeaders.firstName:
      return <TextField text={firstName} />
    case leadsHeaders.lastName:
      return <TextField text={lastName} />
    case leadsHeaders.email:
      return <TextField text={email} />
    case leadsHeaders.crmSaleStatus:
      return <TextField text={crmSaleStatus} />
    case leadsHeaders.phone:
      return <TextField text={phone} />
    case leadsHeaders.state:
      return <StateField state={state || status} />
    case leadsHeaders.isTest:
      return <BooleanField isSomething={isTest} />
    case leadsHeaders.advertiserId:
      return <TextField text={advertiserId} limitation={1} />
    case leadsHeaders.advertiserName:
      return advertiserId ? <TableLink link={`/trafic-distribution/advertisers/${advertiserId}`} label={advertiser?.name ?? ''} ></TableLink> : ''

    case leadsHeaders.additionalInfo:
      return <FieldMap>
        {additionalInfo ? <JsonData data={additionalInfo} /> : ''}
        {additionalInfo && <Box sx={{ cursor: 'pointer' }} onClick={() => setModalLogs(additionalInfo)} >
          <ThreeDots />
        </Box>}
      </FieldMap>
    case leadsHeaders.affSub1:
      return <TextField text={affSub1} />
    case leadsHeaders.affSub2:
      return <TextField text={affSub2} />
    case leadsHeaders.affSub3:
      return <TextField text={affSub3} />
    case leadsHeaders.affSub4:
      return <TextField text={affSub4} />
    case leadsHeaders.affSub5:
      return <TextField text={affSub5} />
    case leadsHeaders.affSub6:
      return <TextField text={affSub6} />
    case leadsHeaders.affSub7:
      return <TextField text={affSub7} />
    case leadsHeaders.affSub8:
      return <TextField text={affSub8} />
    case leadsHeaders.affSub9:
      return <TextField text={affSub9} />
    case leadsHeaders.affSub10:
      return <TextField text={affSub10} />
    case leadsHeaders.affSub11:
      return <TextField text={affSub11} />
    case leadsHeaders.affSub12:
      return <TextField text={affSub12} />
    case leadsHeaders.affSub13:
      return <TextField text={affSub13} />
    case leadsHeaders.affSub14:
      return <TextField text={affSub14} />
    case leadsHeaders.affSub15:
      return <TextField text={affSub15} />
    case leadsHeaders.ip:
      return <TextField text={ip} />
    case leadsHeaders.createdAt:
      return <DateTimeField date={new Date(createdAt)} />
    case leadsHeaders.advertiserAutoLoginUrl:
      return <TextField text={advertiserAutoLoginUrl} limitation={80}/>
    case leadsHeaders.advertiserAutoLoginDomain:
      return <TextField text={advertiserAutoLoginDomain} />
    case leadsHeaders.crmAutoLoginUrl:
      return <TextField text={crmAutoLoginUrl} limitation={80}/>
    case leadsHeaders.isAutoLoginSuccessful:
      return <BooleanField isSomething={isAutoLoginSuccessful} />
    case leadsHeaders.stateUpdatedAt:
      return <DateTimeField date={stateUpdatedAt ? new Date(stateUpdatedAt) : new Date()} />
    case leadsHeaders.leadSource:
      return <TextField text={leadSource} />
    case leadsHeaders.externalId:
      return <TextField text={externalId} limitation={80} />
    case leadsHeaders.apiTokenId:
      return <TextField text={apiTokenId} />
    case leadsHeaders.comment:
      return <TextField text={comment} />
    case leadsHeaders.withConversion:
      return <BooleanField isSomething={withConversion ?? false} />
    case leadsHeaders.updatedAt:
      return <DateTimeField date={updatedAt && new Date(updatedAt)} />
    case leadsHeaders.externalStatus:
      return <TextField text={externalStatus} />
    case leadsHeaders.statusCheckedAt:
      return <DateTimeField date={statusCheckedAt && new Date(statusCheckedAt)} />
    case leadsHeaders.conversionId:
      return <TextField text={conversionId} limitation={1} />
  }
}

export function StateField ({ state }: { state: Lead['state'] | string }) {
  const { palette } = useTheme()
  const colorState = checkIsPushed(state)
  return (
    <FlexBox gap={2} width="max-content" alignItems={'center'}>
      {state && <Box
        sx={{
          width: '16px',
          height: '16px',
          borderRadius: '100px',
          backgroundColor: colorState,
          marginTop: '1px'
        }}
      />}
      <Typography variant='table1' color={palette.text1.main}>
        {state}
      </Typography>
    </FlexBox>
  )
}

export default elementLeadsMap
