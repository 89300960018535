import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
import { type IConversions } from '@/components/Conversions/ConversionsTypes'
import { type TypeLeadsState } from './leads'
import { leadFilters } from '@/components/Conversions/Filters/constants'
import { type LeadState } from '@/enums/leads.enum'
export const conversionsHeaders: Record<string, string> = {
  id: 'ID',
  leadId: 'Lead ID',
  country: 'Country',
  goalType: 'Goal Type',
  affSub1: 'Aff sub 1',
  affSub2: 'Aff sub 2',
  affSub3: 'Aff sub 3',
  affSub4: 'Aff sub 4',
  affSub5: 'Aff sub 5',
  affSub6: 'Aff sub 6',
  affSub7: 'Aff sub 7',
  affSub8: 'Aff sub 8',
  affSub9: 'Aff sub 9',
  affSub10: 'Aff sub 10',
  affSub11: 'Aff sub 11',
  affSub12: 'Aff sub 12',
  affSub13: 'Aff sub 13',
  affSub14: 'Aff sub 14',
  affSub15: 'Aff sub 15',
  advertiserId: 'Advertiser ID',
  isTest: 'Is Test',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
  revenue: 'Revenue'
}

export interface ConversionsFilters {
  id?: string[]
  leadId?: string[]
  country?: string[]
  goalType?: string[]
  state?: LeadState[]
  isTest?: boolean
  ip?: string
  isAutologinSuccess?: boolean
  leadSource?: string
  externalId?: string[]

  advertiserId?: string[] // ids
  saleStatus?: string[]
  advertiserSaleStatus?: string[]

  affSub1?: string
  affSub2?: string
  affSub3?: string
  affSub4?: string
  affSub5?: string
  affSub6?: string
  affSub7?: string
  affSub8?: string
  affSub9?: string
  affSub10?: string
  affSub11?: string
  affSub12?: string
  affSub13?: string
  affSub14?: string
  affSub15?: string

  createdAt?: {
    from?: string
    to?: string
  }
  stateUpdatedAt?: {
    from?: string
    to?: string
  }

  firstName?: string
  lastName?: string
  email?: string
  password?: string
  phone?: string
}

interface GetConversionsArgsWithFilters {
  limit?: number
  page?: number
  filters?: ConversionsFilters
  include?: { createdByUser?: true }
}
export interface ConversionsState {
  totalCount?: number
  menu?: {
    name: string
    id: string
  }
  conversions: IConversions[]
}

const initialState: ConversionsState = {
  totalCount: 1,
  menu: { name: '', id: '' },
  conversions: []
}

export const conversionsSlice = createSlice({
  name: 'conversions',
  initialState,
  reducers: {
    setConversionsTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setConversionsMenu: (state, action: PayloadAction<{ name: string, id: string }>) => {
      state.menu = action.payload
    },
    setConversions: (state, action: PayloadAction<IConversions[]>) => {
      state.conversions = action.payload
    }
  }
})

export const {
  setConversionsTotalCount,
  setConversionsMenu, setConversions
} = conversionsSlice.actions

export const selectConversionsTotalCount = (state: RootState) => state.conversions.totalCount
export const selectConversionsMenu = (state: RootState) => state.conversions.menu
export const selectConversions = (state: RootState) => state.conversions.conversions

export default conversionsSlice.reducer

type CreateEditConversionsArgs = Pick<IConversions, 'advSub' | 'advertiser' | 'advertiserId' | 'afmDateFrom' |
'afmDateTo' | 'country' | 'createdFrom' | 'createdTo' | 'funnel' | 'id' | 'isAprooved' | 'isLate' |
'isTest' | 'leadId' | 'manual' | 'createdAt' | 'revenue' | 'affSub' | 'payout' | 'offerId' | 'languageId' |
'language' | 'goalTypeId'>

interface GetConversionsReturn {
  conversions: IConversions[]
  totalCount: number
}

const transformValues = (name: string, value: string | string [] | boolean | { from?: string, to?: string }) => {
  if (typeof value === 'string' || typeof value === 'boolean') return value
  if (Array.isArray(value)) {
    return {
      in: value
    }
  }
  if (name === 'revenue') {
    return {
      ...value.from ? { gte: value.from } : {},
      ...value.to ? { lte: value.to } : {}
    }
  }
  return {
    ...value?.from ? { gt: value?.from } : {},
    ...value?.to ? { lt: value?.to } : {}
  }
}

const transformFilters = (filters: ConversionsFilters | undefined) => {
  if (!filters) return {}
  const newFilters = Object.entries(filters).reduce((acc: ConversionsFilters, [key, value]) => {
    return {
      ...acc,
      [key]: transformValues(key, value)
    }
  }, {})
  return newFilters
}

export const conversionsApi = createApi({
  reducerPath: 'conversionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/conversions`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['Conversions'],
  endpoints: (builder) => ({
    getConversions: builder.query<GetConversionsReturn, GetConversionsArgsWithFilters>({
      query: ({ limit = 10, page = 1, filters, include }) => {
        if (!filters) {
          return {
            url: '/search',
            method: 'POST',
            body: {
              limit,
              page,
              include,
              sortField: 'createdAt',
              sortOrder: 'desc'
            }
          }
        }
        const { createdAt, ...restFilters } = filters

        const splitFilters = Object.entries(restFilters).reduce((acc: { lead: ConversionsFilters, common: ConversionsFilters }, [key, value]) => {
          if (leadFilters.includes(key)) {
            return {
              ...acc,
              lead: {
                ...acc.lead,
                [key]: value
              }
            }
          }
          return {
            ...acc,
            common: {
              ...acc.common,
              [key]: value
            }
          }
        }, { lead: {}, common: {} })

        return {
          url: '/search',
          method: 'POST',
          body: {
            limit,
            page,
            sortField: 'createdAt',
            sortOrder: 'desc',
            include,
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            ...(createdAt?.from || createdAt?.to)
              ? {
                  createdAt: {
                    ...createdAt.from ? { from: createdAt.from } : {},
                    ...createdAt.to ? { to: createdAt.to } : {}
                  }
                }
              : {},
            filter: {
              ...transformFilters(splitFilters.common),
              leadFilter: transformFilters(splitFilters.lead)
            }
          }
        }
      },
      providesTags: [{ type: 'Conversions', id: 'ConversionsLIST' }]
    }),
    getConversion: builder.query<IConversions, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'GET'
      }),
      providesTags: [{ type: 'Conversions', id: 'ConversionsLIST' }]
    }),
    getAllConversions: builder.query({
      query: () => ({
        url: '/getAllConversions',
        method: 'GET'
      }),
      providesTags: [{ type: 'Conversions', id: 'ConversionsLIST' }]
    }),
    createConversions: builder.mutation<IConversions, CreateEditConversionsArgs>({
      query: ({
        advertiserId, ...rest
      }) => ({
        url: '',
        method: 'POST',
        body: {
          advertiserId,
          ...rest
        }
      }),
      invalidatesTags: [{ type: 'Conversions', id: 'ConversionsLIST' }]
    }),
    editConversions: builder.mutation<IConversions, Partial<CreateEditConversionsArgs> & { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: {
          id
        }
      }),
      invalidatesTags: [{ type: 'Conversions', id: 'ConversionsLIST' }]
    })

  })
})

export const {
  useGetConversionsQuery, useCreateConversionsMutation, useEditConversionsMutation,
  useGetAllConversionsQuery, useGetConversionQuery
} = conversionsApi
