import React, { useEffect, useMemo } from 'react'

import { Box, Typography, useTheme } from '@mui/material'
import SettingsIcon from '@/assets/icons/Settings'
import Button from '@/designSystem/Button'
import TableLayout from '@/designSystem/Table'
import { useSelector, useDispatch } from 'react-redux'
import { type GetDrillReportsArgs, selectDrillReportsState, selectDrillReportsHeadersState, setDrillReportsHeadersState } from '@/store/drillReports'
import { type UseFormReturn } from 'react-hook-form'
import { WidgetSettingsModal } from '../WidgetSettingsModal'
import { type ITerm, selectTermsState } from '@/store/terms'
import { ReportRenderRow } from './ReportRenderRow'

interface IMetricReport {
  form: UseFormReturn<GetDrillReportsArgs>
  noCsv?: boolean
  getCsv?: () => void
}

interface ITableItem {
  title: string
  data: Record<string, string>
  level: number
  children?: ITableItem[]
  id: string
}

const Header = ({ header }: { header: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        height: '45px'
      }}
    >
      <Typography
        width="max-content"
        sx={{
          display: 'flex',
          color: '#000931',
          fontSize: '20px',
          fontWeight: 500
        }}
      >
        {header}
      </Typography>
    </Box>
  )
}

const MetricReport = ({ form, noCsv, getCsv }: IMetricReport) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const report: any = useSelector(selectDrillReportsState)
  const metricReportHeaders: string[] = useSelector(selectDrillReportsHeadersState)
  const allTerms: ITerm[] | undefined = useSelector(selectTermsState)
  const [reportState, setReportState] = React.useState<ITableItem[]>(report?.data?.data)
  const [open, setOpen] = React.useState(false)

  const isGroups = form?.watch('groups')?.length > 0
  const isCreatedAt = form?.watch('createdAt')?.from && form?.watch('createdAt')?.to
  const isDateAt = form?.watch('dateAt')?.value
  const isDate = isCreatedAt || isDateAt
  const isEmptyPql = form?.watch('isEmptyPql')
  const disabledGenerate = !isGroups || !isDate || isEmptyPql

  const termsLabelHeaders = allTerms
    ?.filter((term: ITerm) => form?.watch('termsIds')?.includes(term?.id))
    .map((term: ITerm) => term.label)
  const newHeaders = (termsLabelHeaders && termsLabelHeaders.length > 0) ? termsLabelHeaders : metricReportHeaders
  const allHeaders = ['Title']?.concat(newHeaders)
  const headersJsx = allHeaders?.map((header: string) => <Header key={header} header={header} />)

  useEffect(() => {
    const allTermsIdsArray = allTerms?.map((term: ITerm) => term.id)
    if (form?.getValues()?.termsIds?.length === 0) {
      allTermsIdsArray && form?.setValue('termsIds', allTermsIdsArray)
    }
  }, [allTerms])

  useEffect(() => {
    let newReport = []
    if (report?.data?.data?.length > 0) {
      newReport = [...report?.data?.data, {
        title: 'TOTAL',
        data: report?.data?.total,
        level: 1,
        children: [],
        id: 'total'
      }]
      setReportState(newReport)
    }
  }, [report])

  const recursiveUpdate = (data: ITableItem[], id: string, updater: (item: ITableItem) => ITableItem): ITableItem[] => {
    return data.map((item: ITableItem) => {
      if (item.id === id) {
        return updater(item)
      } else if (item.children && item.children.length > 0) {
        return { ...item, children: recursiveUpdate(item.children, id, updater) }
      } else {
        return item
      }
    })
  }

  const onExpand = (data: ITableItem[], id: string): ITableItem[] => {
    return recursiveUpdate(data, id, item => ({
      ...item,
      isOpenExpand: true,
      children: item.children?.map(child => ({ ...child, isShow: true }))
    }))
  }

  const onBackExpand = (data: ITableItem[], id: string): ITableItem[] => {
    return recursiveUpdate(data, id, item => ({
      ...item,
      isOpenExpand: false,
      children: item.children?.map(child => ({ ...child, isShow: false }))
    }))
  }

  const onMouseEnter = (data: ITableItem[], id: string): ITableItem[] => {
    return recursiveUpdate(data, id, item => ({ ...item, isHovered: true }))
  }

  const onMouseLeave = (data: ITableItem[], id: string): ITableItem[] => {
    return recursiveUpdate(data, id, item => ({ ...item, isHovered: false }))
  }

  const handleExpand = (id: string) => {
    setReportState(prevState => onExpand(prevState, id))
  }

  const handleBackExpand = (id: string) => {
    setReportState(prevState => onBackExpand(prevState, id))
  }

  const handleMouseEnter = (id: string) => {
    setReportState(prevState => onMouseEnter(prevState, id))
  }

  const handleMouseLeave = (id: string) => {
    setReportState(prevState => onMouseLeave(prevState, id))
  }

  const handleGetCsv = () => {
    getCsv && getCsv()
  }

  const rowNodes: any = reportState?.map((row: ITableItem) =>
    allHeaders?.map((header: string) =>
      ReportRenderRow({ row, header, handleExpand, handleBackExpand, handleMouseEnter, handleMouseLeave }))
  )

  const rowNodesMemo = useMemo(() => rowNodes, [reportState])

  const handleOpenTermsModal = () => {
    setOpen(true)
  }

  const handleCloseTermsModal = () => {
    setOpen(false)
  }

  const handleSetStateHeaders = () => {
    dispatch(setDrillReportsHeadersState(termsLabelHeaders))
  }

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'start'}
        gap={'30px'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'20px'}
          >
            <Typography
              sx={{
                color: theme.palette.text1.main,
                fontSize: 28,
                fontWeight: 700
              }}
            > Terms
            </Typography>
            <Box
              sx={{
                cursor: 'pointer'
              }}
              onClick={handleOpenTermsModal}
            >
              <SettingsIcon />
            </Box>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'20px'}
          >
            {!noCsv && <Button
              sx={{
                borderRadius: '16px',
                height: '40px',
                width: '167px',
                padding: '4px 24px',
                whiteSpace: 'nowrap'
              }}
              onClick={handleGetCsv}
              text="Download CSV"
              textVariant='body2'
            />}
            <Button
              disabled={disabledGenerate}
              sx={{
                borderRadius: '16px',
                height: '40px',
                width: '157px',
                padding: `${theme.spacing(1.5)} ${theme.spacing(6)}`
              }}
              variant='contained'
              text="Generate"
              textVariant='body2'
              type='submit'
              onClick={handleSetStateHeaders}
            />
          </Box>
        </Box>
        {(!report?.data?.data && !report?.error?.data?.message) && <Box
          sx={{
            width: '100%',
            minHeight: '50px',
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            padding: '20px',
            justifyContent: 'center',
            backgroundColor: '#FFFFFF',
            borderRadius: '10px'
          }}
        >
          Please generate report
        </Box>}
        {report?.error?.data?.message && <Box
          sx={{
            width: '100%',
            minHeight: '50px',
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            padding: '20px',
            justifyContent: 'center',
            backgroundColor: '#FFFFFF',
            borderRadius: '10px',
            color: '#FF0000'
          }}
        >
          Error generating report
        </Box>}
        {(report?.data?.data?.length === 0) && <Box
          sx={{
            width: '100%',
            minHeight: '50px',
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            padding: '20px',
            justifyContent: 'center',
            backgroundColor: '#FFFFFF',
            borderRadius: '10px',
            color: '#000000'
          }}
        >
          No data found
        </Box>}
        {report?.data?.data?.length > 0 &&
          <TableLayout
            headers={headersJsx ?? []}
            rows={rowNodesMemo ?? []}
            sx={{
              '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-of-type': {
                width: 0
              }
            }}
          />}
      </Box>
      {open && <WidgetSettingsModal open={open} onClose={handleCloseTermsModal} form={form} />}
    </>
  )
}

export default MetricReport
