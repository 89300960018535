/* eslint-disable @typescript-eslint/no-explicit-any */
export function localStorageGet (name: string, defaultValue: any = ''): any {
  const valueFromStore = localStorage.getItem(name)
  if (valueFromStore === null) return defaultValue

  try {
    const jsonParsed: unknown = JSON.parse(valueFromStore)
    if (['boolean', 'number', 'bigint', 'string', 'object'].includes(typeof jsonParsed)) {
      return jsonParsed // We successfully parse JS value from the store
    }
  } catch (error) {
    // Do nothing
  }

  return valueFromStore // Return string value as it is
}

export function localStorageSet (name: string, value: any) {
  if (typeof value === 'undefined') {
    return
  }
  let valueAsString: string
  if (typeof value === 'object') {
    valueAsString = JSON.stringify(value)
  } else {
    valueAsString = String(value)
  }

  localStorage.setItem(name, valueAsString)
}

export function localStorageDelete (name: string) {
  if (name) {
    localStorage.removeItem(name)
  } else {
    localStorage.clear()
  }
}
