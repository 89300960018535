import React from 'react'

import FlexBox from '@/designSystem/FlexBox'

import { useSelector } from 'react-redux'
import { selectAdvertiserSettings } from '@/store/advertisers'
import PushSettingsConnection
  from './PushSettingsConnection'
import PushSettingsOverwriteTable
  from './PushSettingsOverwrite/PushSettingsOverwriteTable'
import PushSettingsHeaderTable
  from './PushSettingsHeaderTable'
import { Box } from '@mui/material'

export interface IApiSettingsIntegration {
  id: string
  name: string
  description: string
  status: string
  pullLeadStatuses: boolean
  createdAt: string
  updatedAt: string
  advertiserSettings?: Array<{
    key?: string
    group: string
    value?: string
    label: string
  }>
  pullLeadStatusesSettings?: null | Array<{
    key: string
    group: string
    value: string
    label?: string
  }>
}

interface PushSettingsTabContentProps {
  apiConnectionSettings?: IApiSettingsIntegration
  advertiserId: string
}

export default function PushSettingsTabContent ({
  advertiserId
}: PushSettingsTabContentProps) {
  const advertiserSettings = useSelector(selectAdvertiserSettings)

  return (
    <FlexBox gap={10} column>
      <FlexBox gap={10} sx={{ justifyContent: 'space-between' }} >
        <Box flex="0 0 40%">
          <PushSettingsConnection
            dataSettings={advertiserSettings ?? []}
            advertiserId={advertiserId}
          />
        </Box>
        <Box flex="0 0 58%">
          <PushSettingsHeaderTable
            rows={advertiserSettings ?? []}
            advertiserId={advertiserId}
          />
        </Box>
      </FlexBox>
      <PushSettingsOverwriteTable rows={advertiserSettings ?? []}
                                    advertiserId={advertiserId} />
    </FlexBox>
  )
}
