import React from 'react'

import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'

import Popup from '@/designSystem/Popup'

import { Autocomplete, TextField, Typography } from '@mui/material'

import type { Option } from '@/designSystem/Autocomplete/types'
import { options } from './helper'
import { type TimeReset, useCreateTimeResetMutation, useDeleteTimeResetMutation, useGetTimeResetsQuery } from '@/store/counterTimeReset'
import FlexBox from '@/designSystem/FlexBox'
import CloseIcon from '@mui/icons-material/Close'
import { formatTime } from '@/designSystem/TreeView/PQL/helpers'

interface Column { name?: string, value: boolean, title: string, dbName: string }
export interface Inputs {
  limit: Option<number>
  columns: Column[]
}

export interface SettingPopupFormProps {
  handleClose: () => void
  data: TimeReset[]
}

export default function ResetTimePopup ({
  handleClose,
  data
}: SettingPopupFormProps) {
  const theme = useTheme()
  const [createTime] = useCreateTimeResetMutation()
  const [deleteTime] = useDeleteTimeResetMutation()

  const onCreateOption = async (e: React.SyntheticEvent<Element, Event>, value: string | null) => {
    e.preventDefault()
    if (value) {
      const [hour] = value.split(':')
      await createTime({ hour: +hour })
    }
  }

  return (
    <Popup
      open
      title={<Title /> as any}
      handleClose={handleClose}
      titleSx={{ color: theme.palette.card.main }}
      // maxWidth='404px'
      rootBoxSx={{
        padding: theme.spacing(4, 6)
      }}
      closeButtonSx={{
        '& .MuiSvgIcon-root': { fill: 'unset' }
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={theme.spacing(8)}
      >
        <Autocomplete getOptionDisabled={(option) => data.findIndex((i) => `${formatTime(i.hour)}:00 UTC` === option) > -1} renderInput={(params) => <TextField variant='outlined' {...params}/>} options={options} onChange={onCreateOption} value={null} blurOnSelect={true} />
        <FlexBox sx={{ gap: theme.spacing(2) }}>
          {data.map((item) => <FlexBox sx={{
            background: '#000e4b',
            color: '#fff',
            width: 'fit-content',
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            borderRadius: theme.spacing(2)
          }} key={item.id}>
            <Typography variant='body2'>{formatTime(item.hour)}:00</Typography>
              <CloseIcon onClick={() => deleteTime({ id: item.id })} />
          </FlexBox>)}
        </FlexBox>
      </Box>
    </Popup>
  )
}

function Title () {
  return (
    <Typography
      variant='leftBar'
    >
      Reset Counter Time
    </Typography>
  )
}
