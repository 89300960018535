import React, { useMemo, useState } from 'react'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'

import Popup from '@/designSystem/Popup'
import { Box, Typography, useTheme } from '@mui/material'
import Form from '../Form'
import ActionButtons from '@/designSystem/ActionButtons'
import TextField from '@/designSystem/TextField'
import AccountsAutocomplete from '@/pages/Accounts/AccountsAutocomplete'
import { ReactComponent as TrashIcon } from '@/assets/icons/trash-red.svg'
import { ReactComponent as RoundIcon } from '@/assets/icons/add-plus-round.svg'
import { useCreateAutoInjectionsMutation } from '@/store/autoInjections'
import { type AddAutoInjection } from './AutoInjectionTypes'
import { hoursOptions, monthOptions, weekOptions } from './AutoInjectionsOptions'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import { countryOptions, placeholderValues } from '@/constants'

const timeMilSec = (time: string, timeType: string) => {
  if (timeType === 'minutes') {
    return parseInt(time) * 60 * 1000
  } else if (timeType === 'seconds') {
    return parseInt(time) * 1000
  }
}

interface IPropsAddInjModal {
  handleClose: () => void
  open: boolean
}

const emptyError = {
  name: '',
  description: '',
  countries: '',
  dateRangeFrom: '',
  monthRangeFrom: '',
  dateRangeTo: '',
  monthRangeTo: '',
  hourRangeFrom: '',
  hourRangeTo: '',
  daysOfWeek: '',
  intervalMin: '',
  runInterval: ''
}

export const AddAutoInjectionModal = ({ handleClose, open }: IPropsAddInjModal) => {
  const [addInjection] = useCreateAutoInjectionsMutation()
  const [overrideFields, setOverrideFields] = useState<any>([])
  const [error, setError] = useState(emptyError)
  const theme = useTheme()
  const form = useForm<AddAutoInjection>({
    defaultValues: {
      name: '',
      description: '',
      countries: { title: '', value: '' },
      dateRangeFrom: '',
      monthRangeFrom: { title: '', value: '' },
      dateRangeTo: '',
      monthRangeTo: { title: '', value: '' },
      hourRangeFrom: { title: '', value: '' },
      hourRangeTo: { title: '', value: '' },
      daysOfWeek: { title: '', value: '' },
      intervalMin: '',
      runInterval: '',
      intervalMinValue: { title: '', value: '' },
      overrideFields: {}
    }
  })

  const {
    control: formControl,
    formState: { errors }
  } = form
  console.log(errors)

  const onSubmit: SubmitHandler<AddAutoInjection> = async (data) => {
    const isValidRunInterval = data.runInterval && (Number(data.runInterval) >= 1 && Number(data.runInterval) <= 60)
    const isValidInterval = data.intervalMin && (Number(data.intervalMin) >= 1 && Number(data.intervalMin) <= 900)
    if (!(data.dateRangeFrom && data.dateRangeTo && data.daysOfWeek?.value &&
      data.intervalMin && data.intervalMinValue?.value && data.monthRangeFrom?.value &&
      data.monthRangeTo?.value && data.hourRangeFrom?.value && data.hourRangeTo?.value &&
      data.name && data.description && data.countries?.value && isValidRunInterval)) {
      setError({
        name: data.name ? '' : 'Name is required',
        description: data.description ? '' : 'Description is required',
        countries: data.countries?.value ? '' : 'Country is required',
        dateRangeFrom: data.dateRangeFrom ? '' : 'Must be less than or equal to 31',
        monthRangeFrom: data.monthRangeFrom?.value ? '' : 'Must be change month from',
        dateRangeTo: data.dateRangeTo ? '' : 'Must be less than or equal to 31',
        monthRangeTo: data.monthRangeTo?.value ? '' : 'Must be change month to',
        hourRangeFrom: data.hourRangeFrom?.value ? '' : 'Hour from is required',
        hourRangeTo: data.hourRangeTo?.value ? '' : 'Hour to is required',
        daysOfWeek: data.daysOfWeek?.value ? '' : 'Day of week is required',
        intervalMin: isValidInterval ? '' : 'Must be less than or equal to 900 seconds or 15 minutes',
        runInterval: isValidRunInterval ? '' : 'Run interval must be from 1 to 60'
      })
      return
    } else {
      setError(emptyError)
    }
    const intervalMin = timeMilSec(data.intervalMin, data.intervalMinValue?.value)
    const newOverrideFields: Record<string, string> = {}
    overrideFields?.forEach((field: { key: string, value: string }) => {
      newOverrideFields[field.key] = field.value
    })
    await addInjection({
      name: data.name ? data.name : '',
      description: data.description ? data.description : undefined,
      country: data.countries?.value ? data.countries?.value : undefined,
      dateRangeFrom: data.dateRangeFrom ? Number(data.dateRangeFrom) : undefined,
      dateRangeTo: data.dateRangeTo ? Number(data.dateRangeTo) : undefined,
      monthRangeFrom: data.monthRangeFrom?.value ? Number(data.monthRangeFrom?.value) : undefined,
      monthRangeTo: data.monthRangeTo?.value ? Number(data.monthRangeTo?.value) : undefined,
      hourRangeFrom: data.hourRangeFrom?.value ? Number(data.hourRangeFrom?.value) : undefined,
      hourRangeTo: data.hourRangeTo?.value ? Number(data.hourRangeTo?.value) : undefined,
      daysOfWeek: data.daysOfWeek?.value ? [data.daysOfWeek?.value] : undefined,
      overrideFields: overrideFields?.length > 0 ? newOverrideFields : undefined,
      interval: intervalMin && intervalMin > 0 ? intervalMin : 0,
      runInterval: data.runInterval ? Number(data.runInterval) : undefined
    }).unwrap().then(() => {
      handleClose()
    })
  }

  const handleAddOverrideField = () => {
    ((overrideFields[overrideFields?.length - 1]?.key !== '') &&
      (overrideFields[overrideFields?.length - 1]?.value !== '')) &&
      setOverrideFields([...overrideFields, { key: '', value: '' }])
  }

  const fields = useMemo(() => {
    return overrideFields?.map((field: { key: string, value: string }, index: number) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
          gap: '16px'
        }}
      >
        <TextField
          placeholder='Enter override key'
          placeholders={placeholderValues}
          value={field?.key}
          onChange={(e) => {
            const newOverrideFields = [...overrideFields]
            newOverrideFields[index].key = e.target.value
            setOverrideFields(newOverrideFields)
          }}
        />
        <TextField
          placeholder='Enter override value'
          value={field?.value}
          onChange={(e) => {
            const newOverrideFields = [...overrideFields]
            newOverrideFields[index].value = e.target.value
            setOverrideFields(newOverrideFields)
          }}
        />
        {overrideFields?.length - 1 === index &&
          <TrashIcon
            style={{ cursor: 'pointer', marginBottom: '10px' }}
            onClick={() => {
              const newOverrideFields = [...overrideFields]
              newOverrideFields.splice(index, 1)
              setOverrideFields(newOverrideFields)
            }}
          />}
      </Box>
    ))
  }, [overrideFields])

  return (
    <Popup
      open={open}
      title="Create Auto Injection"
      handleClose={handleClose}
      overflow='auto'
      maxWidth='600px'
    >
      <ScrollBarContainer>
        <Form form={form} onSubmit={onSubmit}>
          <Box
            display="flex"
            flexDirection="column"
          >
            <Box
              display="flex"
              flexDirection="column"
            >
              <Box
                color={theme.palette.text1.main}
                fontSize={18}
                fontWeight={400}
              >Name</Box>
              <Controller
                control={formControl}
                name="name"
                render={({ field }) => (
                  <TextField {...field} />
                )}
              />
              <Typography sx={{
                color: theme.palette.error.main,
                marginTop: '1px',
                fontSize: '12px'
              }}>{error.name}</Typography>
              <Box
                color={theme.palette.text1.main}
                fontSize={18}
                fontWeight={400}
                paddingTop='16px'
              >Description</Box>
              <Controller
                control={formControl}
                name="description"
                render={({ field }) => (
                  <TextField {...field} multiline rows={4} inputHeight='165px' />
                )}
              />
              <Typography sx={{
                color: theme.palette.error.main,
                marginTop: '1px',
                fontSize: '12px'
              }}>{error.description}</Typography>
              <Box
                sx={{
                  fontSize: '24px',
                  color: theme.palette.text1.main,
                  fontWeight: '700',
                  paddingTop: '24px'
                }}
              >
                Filters
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Box
                  display='flex'
                  flexDirection='column'>
                  <Box
                    color={theme.palette.text1.main}
                    fontSize={18}
                    fontWeight={400}
                    paddingTop='16px'
                  >Countries</Box>
                  <Controller
                    control={formControl}
                    name="countries"
                    render={({ field }) => (
                      <AccountsAutocomplete
                        options={countryOptions}
                        onChange={field.onChange}
                        placeholder="Select Countries"
                        sx={{ width: '254px' }}
                      />
                    )}
                  />
                  <Typography sx={{
                    color: theme.palette.error.main,
                    marginTop: '1px',
                    fontSize: '12px'
                  }}>{error.countries}</Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  fontSize: '24px',
                  color: theme.palette.text1.main,
                  fontWeight: '700',
                  paddingTop: '24px'
                }}
              >
                Time Range
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '24px',
                  paddingTop: '24px'
                }}
              >
                <Box
                  display='flex'
                  flexDirection='column'>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'end',
                      gap: theme.spacing(2)
                    }}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'>
                      <Box
                        color={theme.palette.text1.main}
                        fontSize={18}
                        fontWeight={400}
                      >Date from</Box>
                      <Controller
                        control={formControl}
                        name="dateRangeFrom"
                        render={({ field }) => (
                          <TextField type="number"
                            style={{ width: '87px' }}
                            {...field}
                          />
                        )}
                      />
                    </Box>
                    <Controller
                      control={formControl}
                      name="monthRangeFrom"
                      render={({ field }) => (
                        <AccountsAutocomplete
                          options={monthOptions}
                          onChange={field.onChange}
                          placeholder="Enter Time"
                          sx={{ width: '159px' }}
                        />
                      )}
                    />
                  </Box>
                  <Typography sx={{
                    color: theme.palette.error.main,
                    marginTop: '1px',
                    fontSize: '12px'
                  }}>{error.dateRangeFrom || error.monthRangeFrom}</Typography>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'end',
                      gap: theme.spacing(2)
                    }}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'>
                      <Box
                        color={theme.palette.text1.main}
                        fontSize={18}
                        fontWeight={400}
                      >Date To</Box>
                      <Controller
                        control={formControl}
                        name="dateRangeTo"
                        render={({ field }) => (
                          <TextField type="number"
                            style={{ width: '87px' }}
                            {...field}
                          />
                        )}
                      />
                    </Box>
                    <Controller
                      control={formControl}
                      name="monthRangeTo"
                      render={({ field }) => (
                        <AccountsAutocomplete
                          options={monthOptions}
                          onChange={field.onChange}
                          placeholder="Enter Time"
                          sx={{ width: '159px' }}
                        />
                      )}
                    />
                  </Box>
                  <Typography sx={{
                    color: theme.palette.error.main,
                    marginTop: '1px',
                    fontSize: '12px'
                  }}>{error.dateRangeTo || error.monthRangeTo}</Typography>
                </Box>
              </Box>

              <Box
                display={'flex'}
                gap='24px'
              >
                <Box
                  display='flex'
                  flexDirection='column'>
                  <Box
                    color={theme.palette.text1.main}
                    fontSize={18}
                    fontWeight={400}
                    paddingTop='24px'
                  >Hours From</Box>
                  <Controller
                    control={formControl}
                    name="hourRangeFrom"
                    render={({ field }) => (
                      <AccountsAutocomplete
                        options={hoursOptions}
                        onChange={field.onChange}
                        placeholder="Enter Time"
                        sx={{ width: '254px' }}
                      />
                    )}
                  />
                  <Typography sx={{
                    color: theme.palette.error.main,
                    marginTop: '1px',
                    fontSize: '12px'
                  }}>{error.hourRangeFrom}</Typography>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'>
                  <Box
                    color={theme.palette.text1.main}
                    fontSize={18}
                    fontWeight={400}
                    paddingTop='24px'
                  >Hours To</Box>
                  <Controller
                    control={formControl}
                    name="hourRangeTo"
                    render={({ field }) => (
                      <AccountsAutocomplete
                        options={hoursOptions}
                        onChange={field.onChange}
                        placeholder="Enter Time"
                        sx={{ width: '254px' }}
                      />
                    )}
                  />
                  <Typography sx={{
                    color: theme.palette.error.main,
                    marginTop: '1px',
                    fontSize: '12px'
                  }}>{error.hourRangeTo}</Typography>
                </Box>
              </Box>

              <Box
                color={theme.palette.text1.main}
                fontSize={18}
                fontWeight={400}
                paddingTop='16px'
              >Day of Week</Box>
              <Controller
                control={formControl}
                name="daysOfWeek"
                render={({ field }) => (
                  <AccountsAutocomplete
                    options={weekOptions}
                    onChange={field.onChange}
                    placeholder="Enter day of week"
                    sx={{ width: '537px' }}
                  />
                )}
              />
              <Typography sx={{
                color: theme.palette.error.main,
                marginTop: '1px',
                fontSize: '12px'
              }}>{error.daysOfWeek}</Typography>

              <Box
                color={theme.palette.text1.main}
                fontSize={18}
                fontWeight={400}
                paddingTop='24px'
              >Interval</Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(2)
                }}
              >
                <Box
                  display='flex'
                  flexDirection='column'>
                  <Controller
                    control={formControl}
                    name="intervalMin"
                    render={({ field }) => (
                      <TextField type="number"
                        style={{ width: '187px' }}
                        {...field}
                      />
                    )}
                  />
                  <Typography sx={{
                    color: theme.palette.error.main,
                    marginTop: '1px',
                    fontSize: '12px'
                  }}>{error.intervalMin}</Typography>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'>
                  <Controller
                    control={formControl}
                    name="intervalMinValue"
                    render={({ field }) => (
                      <AccountsAutocomplete
                        options={[{ title: 'Seconds', value: 'seconds' }, { title: 'Minutes', value: 'minutes' }]}
                        onChange={field.onChange}
                        placeholder="Enter Time"
                        sx={{ width: '159px' }}
                      />
                    )}
                  />
                  <Typography sx={{
                    color: theme.palette.error.main,
                    marginTop: '1px',
                    fontSize: '12px'
                  }}>{error.intervalMin && 'Requreq field'}</Typography>
                </Box>
              </Box>

              <Box
                color={theme.palette.text1.main}
                fontSize={18}
                fontWeight={400}
                paddingTop='24px'
              >Interval Between Run</Box>
              <Box
                display='flex'
                flexDirection='column'>
                <Controller
                  control={formControl}
                  name="runInterval"
                  render={({ field }) => (
                    <TextField type="number"
                      style={{ width: '187px' }}
                      {...field}
                    />
                  )}
                />
                <Typography sx={{
                  color: theme.palette.error.main,
                  marginTop: '1px',
                  fontSize: '12px'
                }}>{error.runInterval}</Typography>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={2}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      fontSize: '24px',
                      color: theme.palette.text1.main,
                      fontWeight: '700',
                      paddingTop: '24px'
                    }}
                  >
                    Override and Custom Fields
                  </Box>
                  <RoundIcon style={{ marginBottom: '-25px', cursor: 'pointer' }}
                    onClick={handleAddOverrideField} />
                </Box>
                {fields}
              </Box>
            </Box>
            <ActionButtons
              sx={{ paddingTop: '24px', paddingBottom: '24px' }}
              onCancel={handleClose}
              submitText="Submit"
              cancelText='Cancel'
            />
          </Box>
        </Form >
      </ScrollBarContainer>
    </Popup >
  )
}
