import React, { useState } from 'react'
import { useGetAdvertisersQuery, useGetAdvertiserQuery, type Advertiser } from '@/store/advertisers'
import { useDebounce } from 'use-debounce'
import { type Option } from '@/designSystem/Autocomplete/types'
import * as AutocompleteDS from '@/designSystem/Autocomplete'
import { type Theme, type SxProps } from '@mui/material'

interface Props {
  advertiserId?: string | null
  onChange: (option: Option<string> | null) => void
  sx?: SxProps<Theme>
}

const AdvertiserSelection = ({ advertiserId, onChange, sx }: Props) => {
  const [search, setSearch] = useState('')

  const [debouncedSearch] = useDebounce(search, 500)

  const advertisersQueryOptions = useGetAdvertisersQuery({ filters: { name: debouncedSearch }, page: 1, limit: 10 }).data?.advertisers.map((item) => ({
    title: item.name,
    value: item.id
  })) ?? []

  const selectedAdvertiser: Advertiser = useGetAdvertiserQuery(advertiserId ?? '').data

  if (!advertiserId) {
    return <AutocompleteDS.default required sx={sx} title="Advertiser" onInputChange={(e) => setSearch(e.target.value || '')} options={advertisersQueryOptions} onChange={(option) => onChange(option)} defaultValue={{ title: '', value: '' }}/>
  }

  if (!selectedAdvertiser) return null

  return <AutocompleteDS.default required sx={sx} title="Advertiser" onInputChange={(e) => setSearch(e.target.value || '')} options={advertisersQueryOptions} onChange={(option) => onChange(option)} defaultValue={{ title: selectedAdvertiser.name, value: selectedAdvertiser.id }}/>
}

export default AdvertiserSelection
