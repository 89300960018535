import React from 'react'

export default function CheckedCheckbox () {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="6" fill="#4141D5"/>
      <path d="M10.0007 14.8008L7.66736 12.4675C7.60634 12.4057 7.53367 12.3567 7.45357 12.3232C7.37346 12.2898 7.28751 12.2725 7.20069 12.2725C7.11387 12.2725 7.02792 12.2898 6.94781 12.3232C6.8677 12.3567 6.79504 12.4057 6.73402 12.4675C6.67226 12.5285 6.62323 12.6012 6.58976 12.6813C6.5563 12.7614 6.53906 12.8473 6.53906 12.9342C6.53906 13.021 6.5563 13.1069 6.58976 13.187C6.62323 13.2671 6.67226 13.3398 6.73402 13.4008L9.52736 16.1942C9.78736 16.4542 10.2074 16.4542 10.4674 16.1942L17.534 9.13415C17.5958 9.07314 17.6448 9.00047 17.6783 8.92036C17.7117 8.84026 17.729 8.7543 17.729 8.66749C17.729 8.58067 17.7117 8.49472 17.6783 8.41461C17.6448 8.3345 17.5958 8.26183 17.534 8.20082C17.473 8.13906 17.4003 8.09002 17.3202 8.05656C17.2401 8.02309 17.1542 8.00586 17.0674 8.00586C16.9805 8.00586 16.8946 8.02309 16.8145 8.05656C16.7344 8.09002 16.6617 8.13906 16.6007 8.20082L10.0007 14.8008Z" fill="white"/>
    </svg>
  )
}
