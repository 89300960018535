import Box from '@mui/material/Box'
import React, { useState } from 'react'

import {
  selectCurrentRange,
  setCurrentRange
} from '@/store/dashboard'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as Reload } from '@/assets/icons/tabler_reload.svg'
export default function GeneralChartLeftButtons () {
  const dispatch = useDispatch()
  const currentRange = useSelector(selectCurrentRange)
  const [rotating, setRotating] = useState(false)
  const handlerReload = () => {
    setRotating(true)
    dispatch(setCurrentRange({ from: currentRange.from, to: currentRange.to }))
    console.log('reload')

    // Stop the rotation after 1 second
    setTimeout(() => setRotating(false), 1000)
  }

  return (
    <Box
      onClick={handlerReload}
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
      }}
    >
      <Reload className={rotating ? 'rotating' : ''}/>
      <style>{`
        .rotating {
          animation: rotate 1s linear;
        }

        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </Box>
  )
}
