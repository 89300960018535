import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '@/components/Layout'

import { getRoutes } from '@/routes/routesList'
import AccountsPage from '@/pages/Accounts'
import { useRole } from '@/hooks/role'
import SettingsPage from '@/pages/Settings'
import TermsPage from '@/pages/Settings/Terms'
import ApiTokensPage from '@/pages/Settings/ApiTokens'
import AdvertisersLogsPage from '@/pages/Settings/AdvertisersLogs'
import PresetsPage from '@/pages/Settings/Presets'

const PrivateRoutes = () => {
  const routes = getRoutes()
  const role = useRole()

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="*" element={<Navigate to="/" replace />} />
        {routes.map(({ ref, component }) => (
          <Route key={ref} path={ref} element={component} />
        ))}
        {role && <>
          <Route path="accounts" element={<AccountsPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="settings/api-tokens" element={<ApiTokensPage />} />
          <Route path="settings/presets-list" element={<PresetsPage />} />
          <Route path="settings/crm-terms" element={<TermsPage />} />
          <Route path="settings/advertisers-logs" element={<AdvertisersLogsPage />} />
        </>}
      </Route>
    </Routes>
  )
}

export default PrivateRoutes
