import { type FiltersAdvertiser } from '@/store/advertisers'

interface FilterItemType {
  title: string
  name: keyof FiltersAdvertiser
  isMultiple: boolean
}

export type IFilterCategories = Record<string, FilterItemType[]>

const filterCategories: IFilterCategories = {
  Main: [
    {
      title: 'Name',
      name: 'name',
      isMultiple: false
    },
    {
      title: 'Pull Lead Options',
      name: 'pullLeadStatuses',
      isMultiple: false
    },
    {
      title: 'Active',
      name: 'status',
      isMultiple: false
    }
  ],
  Dates: [
    {
      title: 'Created At',
      name: 'createdAt',
      isMultiple: false
    }
  ]
}

export { filterCategories, type FilterItemType }
