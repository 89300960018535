import React, { useEffect, useState } from 'react'

import EditRoundedIcon from '@mui/icons-material/EditRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Box, IconButton, Typography, useTheme } from '@mui/material'

import FlexBox from '@/designSystem/FlexBox'
import Table from '@/designSystem/Table'
import AddIcon from '@/designSystem/Icons/Add'
import Popup from '@/designSystem/Popup'
import {
  Header,
  CTAField,
  TextField
} from '@/designSystem/Table/Fields'

import ActionButtons from '@/designSystem/ActionButtons'
import TextFieldModal from '@/designSystem/TextField'
import { type IApiSettings } from '@/components/Advertisers/IntegrationSettingsPage/ApiConnectionSettings/EditPushSettingsForm'
import {
  selectPullLeadState,
  selectPullLeadStateSettings,
  selectPullLeadStatusesSettings,
  useEditAdvertiserMutation
} from '@/store/advertisers'
import { useSelector } from 'react-redux'
import IOSSwitch from '@/designSystem/IOSSwitch'
import AccountsAutocomplete from '@/pages/Accounts/AccountsAutocomplete'

const headers = ['Key', 'Value', 'Group', 'Action']

interface AddIntegrationFieldModalProps {
  open: boolean
  handleClose: () => void
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  onSubmit: (obj: IApiSettings[]) => boolean | void
  allSettings?: IApiSettings[]
  editFieldId?: string
  popupError?: string
}

interface RowApiSettings {
  key?: string
  group: string
  value?: string
  label?: string
}
interface PullSettingsHeaderTableProps {
  rows: RowApiSettings[]
  advertiserId?: string
  settingsType: 'pullLeadStatusesSettings' | 'pullLeadStateSettings'
}

const customValueOptions = [{
  value: '{"type":"from_time","format":"YYYY-MM-DD HH:mm","amount":0}',
  title: 'From time'
}, {
  value: '{"type":"to_time","format":"YYYY-MM-DD HH:mm","amount":0}',
  title: 'To time'
}]

export default function PullSettingsHeaderTable ({
  rows,
  advertiserId,
  settingsType
}: PullSettingsHeaderTableProps) {
  const [editAdvertiser] = useEditAdvertiserMutation()
  const allPullSettings = settingsType === 'pullLeadStatusesSettings' ? useSelector(selectPullLeadStatusesSettings) : useSelector(selectPullLeadStateSettings)
  const pullLeadState = useSelector(selectPullLeadState)
  const [isOpenAddPopup, setIsOpenAddPopup] = useState(false)
  const [editPopupId, setEditPopupId] = useState<string | null>(null)
  const [settings, setSettings] = useState<IApiSettings[] | undefined>([])
  const [popupError, setPopupError] = useState('')
  let title = 'Header & Params'
  if (settingsType === 'pullLeadStateSettings') {
    title = 'Header & Params'
  }
  useEffect(() => {
    const newSettings: IApiSettings[] = reducePullingSettings(rows)
    setSettings(newSettings)
  }, [rows])

  function reducePullingSettings (settings: IApiSettings[]) {
    return settings.reduce((acc: IApiSettings[], setting: IApiSettings) => {
      const isHeadersGroup = setting?.group === 'headers'
      const isParametersGroup = setting?.group === 'parameters'
      if (isHeadersGroup || isParametersGroup) {
        acc.push({
          key: setting?.key ?? '',
          group: setting?.group ?? '',
          value: setting?.value ?? ''
        })
      }
      return acc
    }, [])
  }

  const onSubmit = async (settingsRes: IApiSettings[]) => {
    setPopupError('')
    try {
      const dataAdvertiser = await editAdvertiser({
        id: advertiserId ?? '',
        [settingsType]: settingsRes,
        pullLeadState: settingsType === 'pullLeadStatusesSettings' ? pullLeadState : undefined
      }).unwrap()
      if (dataAdvertiser?.[settingsType]) {
        const pullSettings = dataAdvertiser?.[settingsType] as IApiSettings[]
        const newSettings: IApiSettings[] = reducePullingSettings(pullSettings)
        setSettings?.(newSettings)
        setIsOpenAddPopup(false)
        setEditPopupId(null)
      }
    } catch (e: any) {
      setPopupError(e?.data?.message)
      return false
    }
  }

  const handleAddField = (settingsAll: IApiSettings[]) => {
    void onSubmit(settingsAll)
  }

  const handleRemoveField = (key: string) => {
    const newSettings = allPullSettings?.filter((setting: IApiSettings) => setting?.key !== key)
    if (newSettings) {
      void onSubmit(newSettings)
    }
  }

  const headerNodes = headers.map((header) => <Header header={header} key={header} />)
  const fieldToElementMap: any = useFieldToElementMap((key) => setEditPopupId(key), (key) => handleRemoveField(key))

  const rowNodes = settings?.map((row) => (
    headers.map((header) => fieldToElementMap(header, row))
  ))

  return (
    <>
      <FlexBox column gap={3}>
        <FlexBox gap={4} alignCenter>
          <Typography variant='h2'>
            {title}
          </Typography>
          <IconButton onClick={() => setIsOpenAddPopup(true)}>
            <AddIcon />
          </IconButton>
        </FlexBox>
        <Table headers={headerNodes} rows={rowNodes ?? []} />
      </FlexBox>
      {isOpenAddPopup && (
        <AddIntegrationFieldModal open={isOpenAddPopup}
          handleClose={() => setIsOpenAddPopup(false)} onSubmit={handleAddField}
          allSettings={allPullSettings} popupError={popupError} />
      )}
      {editPopupId && (
        <AddIntegrationFieldModal open={Boolean(editPopupId)}
          handleClose={() => setEditPopupId(null)} onSubmit={handleAddField}
          allSettings={allPullSettings} editFieldId={editPopupId} popupError={popupError} />
      )}
    </>
  )
}

const useFieldToElementMap = (
  edit: (key: string) => void,
  remove: (key: string) => void
) => (header: string, row: RowApiSettings) => {
  const { palette } = useTheme()
  const {
    key,
    group,
    value
  } = row
  switch (header) {
    case headers[0]:
      return <TextField text={key} />
    case headers[1]:
      return <TextField text={value} limitation={50} />
    case headers[2]:
      return <TextField text={group} />
    case headers[3]:
      return (
        <FlexBox sx={{ width: '130px', justifyContent: 'space-between' }}>
          <CTAField
            onClick={() => edit(key as string)}
            icon={<EditRoundedIcon sx={{ fill: palette.primary.main }} />}
          />
          <CTAField
            onClick={() => remove(key as string)}
            icon={<CloseRoundedIcon sx={{ fill: palette.red.main }} />}
          />
        </FlexBox>
      )
  }
}

function AddIntegrationFieldModal ({
  open, handleClose, onSubmit,
  allSettings, editFieldId, popupError
}: AddIntegrationFieldModalProps) {
  const setting = allSettings?.find((setting: IApiSettings) => setting?.key === editFieldId)
  const [group, setGroup] = React.useState('headers')
  const [value, setValue] = React.useState(editFieldId ? setting?.value : '')
  const [key, setKey] = React.useState(editFieldId ? setting?.key : '')
  const [error, setError] = React.useState({ key: '', value: '' })
  const [isCustomField, setIsCustomField] = useState(false)
  const [customFieldValue, setCustomFieldValue] = useState('')

  useEffect(() => {
    setError({ key: '', value: '' })
  }, [open])

  useEffect(() => {
    const group = setting?.group ?? 'headers'
    if (editFieldId) {
      setGroup(group)
    }
  }, [editFieldId, setting?.group])

  const handleValueChange = (e: any) => {
    setValue(e.target.value)
  }

  const handleKeyChange = (e: any) => {
    setKey(e.target.value)
  }

  const handleChangeIsCustomField = (e: any) => {
    setIsCustomField(e?.target?.checked)
    setCustomFieldValue('')
  }

  const handleChangeGroup = (e: any) => {
    setGroup(e?.target?.checked ? 'headers' : 'parameters')
    setCustomFieldValue('')
  }

  const handleCustomFieldValueChange = (value: string) => {
    setCustomFieldValue(value)
  }

  const handleAddCustomField = (e: { value: string, title: string } | null) => {
    if ((e?.title === 'From time') ||
      (e?.title === 'To time')) {
      handleCustomFieldValueChange(e?.value)
    }
  }

  const handleSubmit = () => {
    const uniqueKey = Boolean(allSettings?.find((setting: IApiSettings) =>
      (setting?.key === key) && (setting?.key !== editFieldId)))
    const isValue = isCustomField ? customFieldValue : value
    if (value && key && !uniqueKey) {
      setError({ key: '', value: '' })
    } else if (uniqueKey || !isValue || !key) {
      setError({ key: uniqueKey || !key ? 'Required field and must be unique' : '', value: !isValue ? 'Required field' : '' })
      return
    }
    if (editFieldId) {
      const newSettings = allSettings?.map((setting: IApiSettings) => {
        if (setting?.key === editFieldId) {
          return {
            group,
            value: isValue,
            key
          }
        }
        return setting
      })
      if (newSettings) {
        const response = onSubmit(newSettings)
        if (response) {
          setKey('')
          setValue('')
          setCustomFieldValue('')
          setIsCustomField(false)
        }
      }
    } else {
      const settingsFields = allSettings ? [...allSettings] : []
      if (key && isValue) {
        settingsFields?.push({
          key: key ?? '',
          group,
          value: isValue
        })
        setError({ key: '', value: '' })
      }
      const response = onSubmit(settingsFields)
      if (response) {
        setKey('')
        setValue('')
        setCustomFieldValue('')
        setIsCustomField(false)
      }
    }
  }

  return (
    <Popup
      open={open}
      handleClose={handleClose}
      title={editFieldId ? `Edit ${group} field` : `Add ${group} field`}
    >
      <FlexBox column gap={4}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <IOSSwitch
            checked={isCustomField}
            disabled={group === 'headers'}
            onChange={(e) => handleChangeIsCustomField(e)}
            formControlLabel={{
              label: 'Custom Field',
              labelPlacement: 'end',
              sx: {
                margin: 0,
                '& .MuiTypography-root': {
                  marginRight: '10px',
                  marginLeft: '10px'
                }
              }
            }} />
          <IOSSwitch
            formControlLabel={{
              label: 'Parameters/Headers',
              labelPlacement: 'start',
              onChange: handleChangeGroup,
              checked: group === 'headers',
              sx: {
                margin: 0,
                '& .MuiTypography-root': {
                  marginRight: '12px',
                  marginLeft: '10px'
                }
              }
            }}
          />
        </Box>
        {(group === 'parameters') && isCustomField && <Box>
          <Typography
            color={'#393E51'}
            fontSize={18}
            fontWeight={400}
            marginBottom={'4.5px'}
          >Amount of hours from current time
          </Typography>
          <AccountsAutocomplete
            options={!customFieldValue ? customValueOptions : []}
            onChange={(e: { value: string, title: string } | null) => { handleAddCustomField(e) }}
            placeholder="Change Type"
            sx={{ width: '100%' }}
          />
        </Box>}
        <Box>
          <TextFieldModal title="Key" onChange={handleKeyChange} value={key} />
          <Typography variant='body2' color='error'>
            {error.key}
          </Typography>
        </Box>
        {group === 'headers' && <Box>
          <TextFieldModal title="Value" onChange={handleValueChange} value={value} />
          <Typography variant='body2' color='error'>
            {error.value}
          </Typography>
        </Box>}
        {(group === 'parameters') && !isCustomField && <Box>
          <TextFieldModal title="Value" onChange={handleValueChange} value={value} />
          <Typography variant='body2' color='error'>
            {error.value}
          </Typography>
        </Box>}
        {customFieldValue && <Box>
          <TextFieldModal multiline rows={2} inputHeight='70px' title="Value"
            onChange={(e) => handleCustomFieldValueChange(e?.target?.value)} value={customFieldValue} />
          <Typography variant='body2' color='error'>
            {error.value}
          </Typography>
        </Box>}
        {popupError && <Typography variant='body1' color='error'>
          {popupError}
        </Typography>}
        <ActionButtons submitText='Submit changes' onCancel={handleClose} onSubmit={handleSubmit} />
      </FlexBox>
    </Popup >
  )
}
