import { type LeadsFilters } from '@/store/leads'

interface FilterItemType {
  title: string
  name: keyof LeadsFilters
  isMultiple: boolean
}

export type IFilterCategories = Record<string, FilterItemType[]>

const filterCategories: IFilterCategories = {
  Main: [
    {
      title: 'UUIDS',
      name: 'id',
      isMultiple: true
    },
    {
      title: 'Country',
      name: 'country',
      isMultiple: true
    },
    {
      title: 'State',
      name: 'state',
      isMultiple: true
    },
    {
      title: 'Is test',
      name: 'isTest',
      isMultiple: false
    },
    {
      title: 'IP',
      name: 'ip',
      isMultiple: false
    },
    {
      title: 'Lead Source',
      name: 'leadSource',
      isMultiple: false
    },
    {
      title: 'External Id',
      name: 'externalId',
      isMultiple: true
    },
    {
      title: 'Revenue',
      name: 'revenue',
      isMultiple: false
    },
    {
      title: 'With Conversion',
      name: 'withConversion',
      isMultiple: false
    },
    {
      title: 'Conversion Ids',
      name: 'conversionId',
      isMultiple: true
    },
    {
      title: 'Crm Sale Status',
      name: 'crmSaleStatus',
      isMultiple: true
    }
  ],
  Advertiser: [
    {
      title: 'Advertiser',
      name: 'advertiserId',
      isMultiple: true
    },
    {
      title: 'Sale Status',
      name: 'saleStatus',
      isMultiple: true
    },
    {
      title: 'Advertiser Sale Status',
      name: 'advertiserSaleStatus',
      isMultiple: true
    }
  ],
  'Aff Sub (All 15)': [
    {
      title: 'Aff Sub',
      name: 'affSub1',
      isMultiple: false
    },
    {
      title: 'Aff Sub2',
      name: 'affSub2',
      isMultiple: false
    },
    {
      title: 'Aff Sub3',
      name: 'affSub3',
      isMultiple: false
    },
    {
      title: 'Aff Sub4',
      name: 'affSub4',
      isMultiple: false
    },
    {
      title: 'Aff Sub5',
      name: 'affSub5',
      isMultiple: false
    },
    {
      title: 'Aff Sub6',
      name: 'affSub6',
      isMultiple: false
    },
    {
      title: 'Aff Sub7',
      name: 'affSub7',
      isMultiple: false
    },
    {
      title: 'Aff Sub2',
      name: 'affSub2',
      isMultiple: false
    },
    {
      title: 'Aff Sub8',
      name: 'affSub8',
      isMultiple: false
    },
    {
      title: 'Aff Sub9',
      name: 'affSub9',
      isMultiple: false
    },
    {
      title: 'Aff Sub10',
      name: 'affSub10',
      isMultiple: false
    },
    {
      title: 'Aff Sub11',
      name: 'affSub11',
      isMultiple: false
    },
    {
      title: 'Aff Sub12',
      name: 'affSub12',
      isMultiple: false
    },
    {
      title: 'Aff Sub13',
      name: 'affSub13',
      isMultiple: false
    },
    {
      title: 'Aff Sub14',
      name: 'affSub14',
      isMultiple: false
    },
    {
      title: 'Aff Sub15',
      name: 'affSub15',
      isMultiple: false
    }
  ],
  Dates: [
    {
      title: 'Created At',
      name: 'createdAt',
      isMultiple: false
    },
    {
      title: 'State Updated At',
      name: 'stateUpdatedAt',
      isMultiple: false
    }
  ],
  Profile: [
    {
      title: 'Email',
      name: 'email',
      isMultiple: false
    },
    {
      title: 'First name',
      name: 'firstName',
      isMultiple: false
    },
    {
      title: 'Last name',
      name: 'lastName',
      isMultiple: false
    },
    // {
    //   title: 'Password',
    //   name: 'password',
    //   isMultiple: false
    // },
    {
      title: 'Phone',
      name: 'phone',
      isMultiple: false
    }
  ]
}

export { filterCategories, type FilterItemType }
