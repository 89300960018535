import { EType } from '@/components/Pages/TrafficDistribution/RotationControl/RotationControlTypes'
import RotationFolderIcon from '@/designSystem/Pages/TrafficDistribution/RotationControl/icons/RotationFolder'
import AdvertiserIcon from '@/designSystem/Pages/TrafficDistribution/RotationControl/icons/Advertiser'

import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'

interface Props {
  type: EType
  onClick?: (value: EType) => void
}

type IInfo = Record<EType, { name: string, description: string, icon: JSX.Element }>

const info: IInfo = {
  [EType.Advertiser]: {
    name: 'Advertiser',
    description: 'Create an advertiser and manage the settings',
    icon: AdvertiserIcon()
  },
  [EType.RotationFolder]: {
    name: 'Rotation Folder',
    description: 'Create a group and manage the settings',
    icon: RotationFolderIcon()
  }
}

const Type = ({ type, onClick }: Props) => {
  const typeInfo = info[type]
  const { spacing } = useTheme()

  return <Box onClick={() => onClick && onClick(type)}
    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: spacing(3), background: 'white', borderRadius: '16px', padding: spacing(3), ...onClick ? { cursor: 'pointer' } : { cursor: 'default' }, flex: '0 0 50%' }}>
        {typeInfo?.icon}
        <Typography sx={{ fontWeight: 'bold' }}>{typeInfo?.name || 'error'}</Typography>
        <Typography variant='subtitle2' sx={{ textAlign: 'center' }}>{typeInfo?.description}</Typography>
    </Box>
}

export default Type
