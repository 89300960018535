import React, { useEffect, useState } from 'react'

import FlexBox from '@/designSystem/FlexBox'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectPullLeadState,
  selectPullLeadStateSettings,
  selectPullLeadStatusesSettings,
  setPullLeadState,
  useEditAdvertiserMutation
} from '@/store/advertisers'
import PullSettingsConnectionTable from './PullSettingsConnectionTable'
import PullSettingsHeaderTable from './PullSettingsHeaderTable'
import { Box, type SelectChangeEvent, Typography } from '@mui/material'
import IOSSwitch from '@/designSystem/IOSSwitch'
import ActionsSelect from '@/designSystem/ActionsSelect'
import {
  TestPullModal
} from '@/designSystem/Pages/Advertisers/IntegrationSettings/PullSettingsTabContent/TestPullModal'
import { PullLeadActionType } from '@/enums/advertiser.enum'

export interface IApiSettingsIntegration {
  id: string
  name: string
  description: string
  status: string
  pullLeadStatuses: boolean
  createdAt: string
  updatedAt: string
  advertiserSettings?: Array<{
    key?: string
    group: string
    value?: string
    label: string
  }>
  pullLeadStatusesSettings?: null | Array<{
    key: string
    group: string
    value: string
    label?: string
  }>
}
interface PullApiSettings {
  key: string
  value: string
  group: string
}
interface PullSettingsTabContentProps {
  apiConnectionSettings?: IApiSettingsIntegration
  advertiserId: string
}

export default function PullSettingsTabContent ({
  advertiserId
}: PullSettingsTabContentProps) {
  const dispatch = useDispatch()
  const pullLeadStatusesSettings = useSelector(selectPullLeadStatusesSettings)
  const pullLeadState = useSelector(selectPullLeadState)
  const [editAdvertiser] = useEditAdvertiserMutation()
  const [openTestModal, setOpenTestModal] = useState(false)
  const [pullLeadActionType, setPullLeadActionType] = useState<PullLeadActionType>(PullLeadActionType.TEST_PULL)
  const [usePullLeadState, setUsePullLeadState] = React.useState(pullLeadState)
  const pullLeadStateSettings = useSelector(selectPullLeadStateSettings)
  const handleUsePullLeadStateSettings = async (e: any) => {
    const checked = e?.target?.checked === true
    setUsePullLeadState(e?.target?.checked)
    const dataAdvertiser = await editAdvertiser({ id: advertiserId, pullLeadState: checked }).unwrap()
    dispatch(setPullLeadState(dataAdvertiser?.pullLeadState ?? false))
  }
  useEffect(() => {
    setUsePullLeadState(pullLeadState)
  }, [pullLeadState])
  const onChangeActions = (e: SelectChangeEvent<unknown>) => {
    if (e.target.value === 0) {
      setPullLeadActionType(PullLeadActionType.TEST_PULL)
    } else if (e.target.value === 1) {
      setPullLeadActionType(PullLeadActionType.MANUAL_PULL)
    }
    setOpenTestModal(true)
  }
  const handleCloseTestModal = () => {
    setOpenTestModal(false)
  }
  return (
    <FlexBox gap={5} column>
      <FlexBox justifyContent={'space-between'} alignItems={'center'}>
      <Box>
        <IOSSwitch
          formControlLabel={{
            label: 'Use Pull lead state settings',
            labelPlacement: 'end',
            onChange: handleUsePullLeadStateSettings,
            checked: usePullLeadState,
            sx: {
              margin: 0,
              '& .MuiTypography-root': {
                marginRight: '12px',
                marginLeft: '10px'
              }
            }
          }}
        />
      </Box>
      <FlexBox gap={3}>
        <ActionsSelect
          items={[{ value: 0, title: 'Test Pulling' }, { value: 1, title: 'Manual Pulling' }]}
          onChange={onChangeActions}
        />
      </FlexBox>
      </FlexBox>
      <Typography variant='h2' sx={{ marginTop: '30px', marginBottom: '20px' }}>
        Pull Lead Statuses
      </Typography>

      <FlexBox gap={10} sx={{ justifyContent: 'space-between' }} >
        <Box flex="0 0 40%">
          <PullSettingsConnectionTable rows={pullLeadStatusesSettings ?? []} settingsType="pullLeadStatusesSettings"
                                       advertiserId={advertiserId} />
          <Box/>
        </Box>
        <Box flex="0 0 58%">
          <PullSettingsHeaderTable rows={pullLeadStatusesSettings ?? []} settingsType="pullLeadStatusesSettings"
                                   advertiserId={advertiserId} />
          <Box/>
        </Box>
      </FlexBox>
      {pullLeadState &&
       <Box>
         <Typography variant='h2' sx={{ marginTop: '30px', marginBottom: '20px' }}>
           Pull Lead States
         </Typography>
         <FlexBox gap={10} sx={{ justifyContent: 'space-between' }}>
           <Box flex="0 0 40%">
             <PullSettingsConnectionTable rows={pullLeadStateSettings ?? []} settingsType="pullLeadStateSettings"
                                          advertiserId={advertiserId} />
             <Box/>
           </Box>
           <Box flex="0 0 58%">
             <PullSettingsHeaderTable rows={pullLeadStateSettings ?? []} settingsType="pullLeadStateSettings"
                                      advertiserId={advertiserId} />
             <Box/>
           </Box>
         </FlexBox>
       </Box>
      }
      {openTestModal && (
        <TestPullModal open={openTestModal}
                          handleClose={handleCloseTestModal}
                           pullLeadActionType={pullLeadActionType}
                          advertiserId={advertiserId} />
      )}
    </FlexBox>
  )
}
