import React from 'react'

export default function RealFDT () {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="45" height="45" rx="12" fill="white" />
      <g clipPath="url(#clip0_485_67620)">
        <path d="M35.3508 19.3888C35.1645 19.2263 34.934 19.1229 34.6888 19.0916C34.4435 19.0604 34.1945 19.1027 33.9733 19.2133L28.7886 21.7948L24.4598 14.6047C24.3485 14.4202 24.1915 14.2676 24.0039 14.1616C23.8163 14.0557 23.6045 14 23.389 14C23.1735 14 22.9617 14.0557 22.7741 14.1616C22.5865 14.2676 22.4294 14.4202 22.3182 14.6047L17.9894 21.7979L12.8067 19.2164C12.5861 19.1068 12.338 19.0648 12.0936 19.0957C11.8492 19.1266 11.6194 19.229 11.433 19.3901C11.2466 19.5511 11.1119 19.7636 11.0458 20.0009C10.9797 20.2382 10.9852 20.4898 11.0616 20.724L14.8823 32.4297C14.9207 32.5473 14.985 32.6548 15.0705 32.7442C15.1561 32.8336 15.2606 32.9027 15.3763 32.9463C15.4921 32.9899 15.6162 33.007 15.7394 32.9962C15.8627 32.9854 15.9819 32.9471 16.0884 32.8841C16.1142 32.8686 18.7546 31.3476 23.389 31.3476C28.0234 31.3476 30.6638 32.8686 30.6875 32.8831C30.794 32.9467 30.9135 32.9855 31.0371 32.9967C31.1606 33.0078 31.2851 32.991 31.4012 32.9474C31.5174 32.9039 31.6223 32.8347 31.708 32.7451C31.7938 32.6555 31.8583 32.5477 31.8967 32.4297L35.7174 20.7271C35.796 20.4928 35.8031 20.2405 35.7378 20.0022C35.6725 19.7638 35.5378 19.5503 35.3508 19.3888ZM28.329 27.7127C28.2953 27.9038 28.1954 28.077 28.0468 28.2018C27.8982 28.3266 27.7104 28.3951 27.5164 28.3953C27.4679 28.3952 27.4196 28.3911 27.3718 28.3829C24.7331 27.9299 22.0366 27.9299 19.3979 28.3829C19.2911 28.4018 19.1815 28.3994 19.0756 28.3759C18.9697 28.3524 18.8694 28.3083 18.7805 28.2461C18.601 28.1204 18.4788 27.9285 18.4407 27.7127C18.4026 27.4969 18.4518 27.2748 18.5775 27.0953C18.7032 26.9158 18.8951 26.7936 19.1109 26.7555C21.9395 26.2695 24.8303 26.2695 27.6589 26.7555C27.7661 26.7738 27.8687 26.8132 27.9607 26.8713C28.0526 26.9294 28.1322 27.0051 28.1949 27.094C28.2576 27.183 28.302 27.2834 28.3258 27.3896C28.3495 27.4958 28.352 27.6056 28.3332 27.7127H28.329Z" fill="#4141D5" />
      </g>
      <defs>
        <clipPath id="clip0_485_67620">
          <rect width="24" height="24" fill="white" transform="translate(11 11)" />
        </clipPath>
      </defs>
    </svg>
  )
}
