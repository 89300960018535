import React, { useEffect, useState } from 'react'
import MuiTextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'

import type { TextFieldProps as MuiTextFiledProps } from '@mui/material/TextField'
import type { SxProps, Theme } from '@mui/material'
import FlexBox from '../FlexBox'

// TODO: Understand how to spread SxProps 2x inside Sx prop
type TextFieldMock = Record<string, string | Record<string, string | Record<string, string>>>

export type TextFiledProps = Omit<MuiTextFiledProps, 'sx'> & {
  title?: React.ReactNode
  placeholders?: string[]
  sx?: {
    root?: SxProps<Theme>
    placeholder?: SxProps<Theme>
    title?: SxProps<Theme>
    textField?: TextFieldMock
  }
  inputHeight?: string
  notInput?: boolean
  plusPlaceholder?: boolean
  plusParameters?: boolean
}

export default function TextField ({
  title,
  placeholder,
  placeholders,
  value,
  onChange,
  sx,
  inputHeight,
  notInput = false,
  plusPlaceholder = false,
  plusParameters = false,
  ...rest
}: TextFiledProps) {
  const theme = useTheme()
  const [openPlaceholerBar, setOpenPlaceholerBar] = useState(false)
  const [fieldValue, setFieldValue] = useState<string>(() => value as string)

  useEffect(() => {
    onChange?.({ target: { value: fieldValue } } as any)
    setOpenPlaceholerBar(false)
  }, [fieldValue])

  return (
    <FlexBox sx={sx?.root} column>
      <FlexBox justifyContent="space-between" alignCenter>
        {!!title && (
          <Typography
            variant='body2'
            sx={{
              marginBottom: theme.spacing(1.125),
              ...sx?.title
            }}
            color={theme.palette.text2.main}
          >
            {title}
          </Typography>
        )}
        {!!placeholders && (
          <span onClick={() => setOpenPlaceholerBar((val) => !val)}>
            <Typography variant='body2' color={theme.palette.primary.main} sx={{ cursor: 'pointer' }}>
              {openPlaceholerBar ? 'Hide placeholders' : 'Show placeholders'}
            </Typography>
          </span>
        )}
      </FlexBox>
      <MuiTextField
        placeholder={placeholder ?? 'Enter Text…'}
        value={fieldValue}
        onChange={(e) => {
          !notInput && setFieldValue(e.target.value)
          return onChange?.(e)
        }}
        sx={{
          '& .MuiInputBase-root': {
            height: inputHeight ?? '40px',
            borderRadius: '16px',
            fontSize: '14px',
            ...sx?.textField
          },
          '& .MuiOutlinedInput-root:hover': {
            '& > fieldset': {
              borderColor: theme.palette.primary.main
            }
          },
          '& .Mui-disabled:hover': {
            '& > fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.38)'
            }
          },
          '& fieldset': { borderColor: theme.palette.primary.main }
        }}
        {...rest}
      />
      {openPlaceholerBar && (
        <Placeholders
          placeholders={placeholders!}
          onClick={(addiction) => {
            if (plusPlaceholder) {
              setFieldValue(`${fieldValue}${fieldValue ? (fieldValue === '') ? '' : '+' : ''}{${addiction}}`)
            } else if (plusParameters) {
              setFieldValue(`${fieldValue}${fieldValue
                ? (fieldValue?.includes('?')) ? '&' : '?'
                : ''}${addiction}={{${addiction}}}`)
            } else if (!plusPlaceholder && !plusParameters) {
              setFieldValue(addiction)
            }
          }}
        />
      )}
    </FlexBox>
  )
}

interface PlaceholdersProps {
  placeholders: string[]
  onClick: (placeholder: string) => void
}
function Placeholders ({ placeholders, onClick }: PlaceholdersProps) {
  const { spacing, palette } = useTheme()
  return (
    <FlexBox
      column
      gap={4}
      padding={spacing(4)}
      marginTop={spacing(2)}
      sx={{
        backgroundColor: palette.leftBar.main,
        border: `1px solid ${palette.primary.main}`,
        borderRadius: '16px'
      }}
    >
      <FlexBox justifyContent="space-between" alignCenter>
        <Typography variant='headline' color={palette.text1.main}>
          Placeholders
        </Typography>
        <Typography variant="subtitle2" color={palette.gray.main}>
          Click to paste
        </Typography>
      </FlexBox>
      <FlexBox
        gap={3}
        columnGap={spacing(3)}
        flexWrap="wrap"
      >
        {placeholders.map((placeholder) => (
          <span key={placeholder} onClick={() => onClick(placeholder)}>
            <Box
              padding={spacing(1.5, 4)}
              sx={{
                backgroundColor: palette.whiteText.main,
                borderRadius: '8px',
                cursor: 'pointer'
              }}
            >
              <Typography variant='subtitle2' color={palette.text2.main}>
                {placeholder}
              </Typography>
            </Box>
          </span>
        ))}
      </FlexBox>
    </FlexBox>
  )
}
