/* eslint-disable no-lone-blocks */
import React, { type ReactNode, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { TreeView as MUTreeView } from '@mui/x-tree-view/TreeView'
import { TreeItem, type TreeItemProps } from '@mui/x-tree-view/TreeItem'
import { ERenderer, EType, type IItem } from './types'
import styled from '@emotion/styled'
import { Box, Menu, MenuItem, Typography, useTheme } from '@mui/material'
import AdvertiserIcon from '@/designSystem/Pages/TrafficDistribution/RotationControl/icons/Advertiser'
import RotationFolderIcon from '@/designSystem/Pages/TrafficDistribution/RotationControl/icons/RotationFolder'
import FolderIcon from '@/designSystem/Pages/LeadConvertions/AdvertiserRevenue/icons/FolderIcon'
import RevenueIcon from '@/designSystem/Pages/LeadConvertions/AdvertiserRevenue/icons/RevenueIcon'
import { ReactComponent as ArrowBottomIcon } from '@/assets/icons/arrow-bottom-round.svg'
import { ReactComponent as ArrowUpIcon } from '@/assets/icons/arrow-up-round.svg'
import FlexBox from '@/designSystem/FlexBox'
import IconButton from '@/designSystem/IconButton'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import Close from '@mui/icons-material/Close'
import Edit from '@mui/icons-material/Edit'
import AddIcon from '@/designSystem/Icons/Add'
import { CountryField } from '@/designSystem/Table/Fields'
import Popup from '@/designSystem/Popup'
import LogsWrapper from './LogsWrapper'
import { useGetRotationsQuery } from '@/store/rotationControl'
import { selectRotationControlQueryParams } from '@/store/queryParams'
import { useSelector } from 'react-redux'
import LinearProgress from '@mui/material/LinearProgress'
import RejectedLeadsWrapper from '@/designSystem/TreeView/RejectedLeadsWrapper'
import { RotationLogType } from '@/enums/rotations.enum'
import { ToastContainer } from 'react-toastify'

const PQL = (props: { children: string }) => {
  const theme = useTheme()

  const getTime = (query: string) => {
    const regex = /([+]\d*)/
    const timeRegex = regex.exec(query)
    const time = timeRegex ? timeRegex[0] : ''
    const formattedTime = `${time.slice(0, 3)}:${time.slice(3)}`
    return formattedTime
  }

  const getDays = (query: string) => {
    const regex = /\[.*\]/
    const daysRegex = regex.exec(query)
    const days = daysRegex ? daysRegex[0] : ''
    const result = days.replace('[', '').replace(']', '')
      .split('\'').join('')
      .split(',').join(', ')
    return result
  }

  const getHours = (query: string) => {
    const regex = /'\S*'/g
    const daysRegex = query.match(regex)
    if (daysRegex) {
      const times = daysRegex.map((item) => item.replaceAll('\'', ''))
      return times.join(' - ')
    }
    return ''
  }

  const query = props.children
  if (query.includes('dayOfWeek')) {
    const time = getTime(query)
    const days = getDays(query)
    return <FlexBox sx={{ gap: theme.spacing(2) }}>Day | <FlexBox sx={{ color: theme.palette.green.main, display: 'inline' }}>{time}</FlexBox> {days}</FlexBox>
  }
  if (query.includes('hourOfDay')) {
    const time = getTime(query)
    const hours = getHours(query)
    return <FlexBox sx={{ gap: theme.spacing(2) }}>Hour | <FlexBox sx={{ color: theme.palette.green.main, display: 'inline' }}>{time}</FlexBox> {hours}</FlexBox>
  }
  return <Typography>PQL | {query}</Typography>
}

interface IBasicMenuProps {
  id: string
  cloneElement?: (id: string) => void
  getLogs?: () => boolean
  item?: IItem
  onAdd?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>, element: IItem) => void
}
export const BasicMenu = (props: IBasicMenuProps) => {
  const [isLogsVisible, setLogsVisible] = useState<boolean>(false)
  const [logsType, setLogsType] = useState<RotationLogType | null>(null)
  const [rotationItem, setRotationItem] = useState<IItem | null>(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onClone = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation()
    if (props?.item?.type === 'advertiser') {
      const itemWithOutAdvrtiserId = { ...props.item, advertiserId: null }
      if (props.onAdd) {
        props.onAdd(e, itemWithOutAdvrtiserId)
      }
    } else if (props.cloneElement) {
      props.cloneElement(props.id)
    }
    handleClose()
  }

  const onLogs = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, logType: RotationLogType, rotationItem?: IItem) => {
    e.stopPropagation()
    setLogsType(logType)
    setLogsVisible(true)
    rotationItem && setRotationItem(rotationItem)
    handleClose()
  }
  const renderLogsPopup = () => {
    if (!isLogsVisible || !logsType) return null

    const WrapperComponent = logsType === 'logs' ? LogsWrapper : RejectedLeadsWrapper
    const title = logsType === 'logs' ? 'Logs' : 'Reject Logs'

    return (
      <Popup open handleClose={() => setLogsVisible(false)} title={title}>
        <WrapperComponent id={props.id} rejectedCapCount={rotationItem?.rejectedCapCount ?? 0} />
      </Popup>
    )
  }
  return (
    <>
      <div>
        <IconButton id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreHoriz sx={{ fill: 'white' }} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          {props.cloneElement && <MenuItem onClick={onClone}>Clone</MenuItem>}
          <MenuItem onClick={(ev) => onLogs(ev, RotationLogType.LOGS)}>Logs</MenuItem>
          {(props?.item?.rejectedCapCount ?? 0) > 0 && <MenuItem onClick={(ev) => onLogs(ev, RotationLogType.REJECTED_LEADS, props?.item)}>Reject Logs</MenuItem>}
        </Menu>
      </div>
      {renderLogsPopup()}
    </>
  )
}

interface IDeleteWithConfirmationProps {
  onDelete: (e: React.MouseEvent<HTMLLIElement | SVGSVGElement, MouseEvent>) => void
}
export const DeleteWithConfirmation = (props: IDeleteWithConfirmationProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onDelete = (e: React.MouseEvent<HTMLLIElement | SVGSVGElement, MouseEvent>) => {
    e.stopPropagation()
    props.onDelete(e)
  }

  return (
    <>
      <div>
        <IconButton id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ background: 'transparent', '&:hover': { background: 'none' } }}
        >
          <Close sx={{ fill: '#ff0000' }} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem onClick={onDelete}>Confirm deletion</MenuItem>
          <MenuItem onClick={handleClose}>Cancel</MenuItem>
        </Menu>
      </div>
    </>
  )
}

const Headers = () => {
  const { palette, spacing } = useTheme()

  const headers = ['Name', 'Passing Rule', 'Settings', '']

  return <FlexBox sx={{ paddingLeft: spacing(4) }}>
    {headers.map((item) => <Typography key={item} variant='h1' color={palette.text2.main} sx={{ flex: `0 0 ${100 / headers.length}%` }}>
      {item}
    </Typography>)}
  </FlexBox>
}

const Type = (props: { node: IItem }) => {
  switch (props.node.type) {
    case EType.RotationFolder: return <RotationFolderIcon />
    case EType.Advertiser: return <AdvertiserIcon />
    case EType.Folder: return <FolderIcon />
    case EType.Revenue: return <RevenueIcon />
    default: return null
  }
}

interface IRow {
  item: IItem
  actions: IActions
}
interface FillRateProps {
  title: string
  value: number
  total: number
  color: string
}

const FillRate: React.FC<FillRateProps> = ({ value, total, color, title }) => {
  const percentage = (value / total) * 100
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 200, marginTop: 0 }}>
      <Box sx={{ flex: '0 0 100px', backgroundColor: '#F2F2F2', height: 12, borderRadius: 4, position: 'relative', overflow: 'hidden' }}>
        <LinearProgress
          variant="determinate"
          value={percentage}
          sx={{
            height: '100%',
            borderRadius: 4,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: 'transparent',
            '& .MuiLinearProgress-bar': {
              backgroundColor: color,
              borderRadius: 4
            }
          }}
        />
      </Box>

      <Typography sx={{
        fontSize: '15px',
        lineHeight: '15px',
        height: '19px',
        marginLeft: 2,
        color,
        fontWeight: 'bold',
        minWidth: '50px'
      }}>
        {value}/{total}
      </Typography>
      <Typography sx={{
        fontSize: '15px',
        lineHeight: '15px',
        height: '19px',
        marginLeft: 4,
        color: '#999a9e'
      }}>
        {title}
      </Typography>
    </Box>

  )
}

const Row = ({ item, actions }: IRow) => {
  const { spacing, palette } = useTheme()

  const query = useSelector(selectRotationControlQueryParams)
  const rotations = useGetRotationsQuery({ filters: query.filters }).data ?? []

  const isPriorityVisible = item.type === EType.RotationFolder || rotations.find((i) => i.id === item.id)

  const onDelete = async (e: React.MouseEvent<HTMLLIElement | SVGSVGElement, MouseEvent>, id: string) => {
    e.stopPropagation()
    if (actions.deleteElement) {
      await actions.deleteElement(id)
    }
  }
  const onAdd = async (e: React.MouseEvent<SVGElement | HTMLLIElement, MouseEvent>, element: IItem) => {
    e.stopPropagation()
    actions.selectElement(element)
    if (actions.selectMode) {
      actions.selectMode('create')
    }
  }
  const onEdit = async (e: React.MouseEvent<SVGElement>, element: IItem) => {
    e.stopPropagation()
    actions.selectElement(element)
    if (actions.selectMode) {
      actions.selectMode('edit')
    }
  }

  const onMoveUp = (e: React.MouseEvent<SVGElement>, id: string) => {
    e.stopPropagation()
    if (actions?.moveUpElement && id) {
      actions.moveUpElement(id)
    }
  }
  const onMoveBottom = (e: React.MouseEvent<SVGElement>, id: string) => {
    e.stopPropagation()
    if (actions?.moveDownElement && id) {
      actions.moveDownElement(id)
    }
  }

  const getCountries = (query: string) => {
    const regex = /\[.*\]/
    const countryRegex = regex.exec(query)
    const country = countryRegex ? countryRegex[0] : ''
    const result = country.replace('[', '').replace(']', '')
      .split('\'').join('').toLocaleLowerCase()
      .split(',')
    return result
  }

  return <FlexBox>
    <Box sx={{ display: 'flex', gap: spacing(3), flex: '0 0 25%', alignItems: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
      {!!item.priority && isPriorityVisible && <Box sx={{
        width: 32,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        background: '#F1F3FF',
        flexShrink: 0
      }}>
        {item.priority}
      </Box>}
      <Type node={item} />
      <Box sx={{
        display: 'flex',
        alignItems: 'center'
      }}>{item.name}</Box>
    </Box>
    <Box sx={{ display: 'flex', flex: '0 0 25%', alignItems: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
      {item.renderer === ERenderer.Country && getCountries(item.pql).map((item) => <CountryField key={item} countryCode={item} />)}
      {item.renderer === ERenderer.PassAll && <Typography>PQL | Pass All</Typography>}
      {item.renderer === ERenderer.Custom && <PQL>{item.pql}</PQL>}
    </Box>
    <Box sx={{ display: 'flex', flex: '0 0 25%', flexDirection: 'column', alignItems: 'flex-start', whiteSpace: 'nowrap', justifyContent: 'center', textOverflow: 'ellipsis', overflow: 'hidden' }}>

      {item.capAmount && <FillRate value={item.capCount ?? 0} title="Push Cap" total={item.capAmount} color={palette.orange1.main}></FillRate>}
      {item.rejectedCap && <FillRate value={item.rejectedCapCount ?? 0} title="Reject Cap" total={item.rejectedCap} color={palette.cyen1.main}></FillRate>}
      {item.rejectedTimeoutCap && <FillRate value={item.rejectedTimeoutCapCount ?? 0} title="Timeout Cap" total={item.rejectedTimeoutCap} color={palette.red2.main}></FillRate>}
    </Box>
    <Box sx={{ display: 'flex', flex: '0 0 25%', alignItems: 'center', justifyContent: 'flex-end', gap: spacing(2) }}>
      <ArrowBottomIcon onClick={(e) => onMoveBottom(e, item.id)} />
      <ArrowUpIcon onClick={(e) => onMoveUp(e, item.id)} />
      <DeleteWithConfirmation onDelete={(e) => onDelete(e, item.id)} />
      <Edit onClick={(e) => onEdit(e, item)} />
      <BasicMenu id={item.id} cloneElement={actions.cloneElement} getLogs={actions.getLogs}
        item={item} onAdd={onAdd}/>
      {item?.level !== 4 && item?.type !== 'advertiser' && <AddIcon onClick={(e) => onAdd(e, item)} />}
    </Box>
  </FlexBox>
}

const StyledTreeItem = ({ className, ...props }: TreeItemProps) => (
  <TreeItem className={className} {...props} />
)

const CustomTreeItem = styled(StyledTreeItem)(({ theme }) => ({
  padding: '12px 0 0 0',
  '& .MuiTreeItem-iconContainer': {
    width: 24,
    height: 24
  },
  '& .MuiTreeItem-iconContainer > svg': {
    width: 24,
    height: 24,
    display: 'flex'
  },
  '& .MuiTreeItem-content': {
    height: 64,
    boxSizing: 'border-box',
    border: '1px solid transparent',
    backgroundColor: 'white',
    borderRadius: '16px'
  },
  '& .MuiTreeItem-content:hover': {
    border: '1px solid black',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
  },
  '& .Mui-selected': {
    backgroundColor: 'unset'
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24
  }
}))

const renderTree = (nodes: IItem[], actions: IActions, CustomNode?: (el: any) => ReactNode | null) =>
  nodes.map((el, index) => {
    return (
      <CustomTreeItem key={el.id} nodeId={el.id?.toString()} label={CustomNode ? CustomNode(el) : <Row actions={actions} item={el} />}>
        {Array.isArray(el.children) && el.children.length > 0
          ? renderTree(el.children, actions, CustomNode)
          : null}
      </CustomTreeItem>
    )
  })

export interface IActions {
  moveUpElement?: (id: string) => any
  moveDownElement?: (id: string) => any
  deleteElement?: (id: string) => any
  selectElement: (value: any) => void
  cloneElement?: (id: string) => void
  selectMode?: (mode: 'create' | 'edit') => void
  getLogs?: () => boolean
  setErrorMessage?: (value: string) => void
}
interface Props {
  expanded: string[]
  items: any[]
  handleToggle: (e: React.SyntheticEvent<Element, Event>, nodeIds: string[]) => void
  actions: IActions
  CustomLabel?: (item: any) => ReactNode
}
export default function TreeView (props: Props) {
  const data = props.items

  return <>
    <Headers />
    <ToastContainer/>
    <MUTreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon height={24} width={24} />}
      expanded={props.expanded}
      onNodeToggle={props.handleToggle}
      selected={null}
    >
      {renderTree(data ?? [], props.actions, props.CustomLabel ? props.CustomLabel : undefined)}
    </MUTreeView>
  </>
}
