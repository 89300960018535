import React from 'react'

import Box from '@mui/material/Box'
import Button from '@/designSystem/Button'
import useTheme from '@mui/material/styles/useTheme'
import { useNavigate } from 'react-router-dom'
import { IconButton } from '@mui/material'
import AddIcon from '@/designSystem/Icons/Add'
import QuestionMarkIcon from '@/designSystem/PageContentLayout/Icons/QuestionMark'
import PresetsInfoModal from './PresetsInfoModal'
import { AddEditPresetModal } from './AddEditPresetsModal'

export default function LeftActionButtons (): JSX.Element {
  const theme = useTheme()
  const navigate = useNavigate()

  const [openInfo, setOpenInfo] = React.useState(false)
  const [openAdd, setOpenAdd] = React.useState(false)

  const onClickReload = () => navigate(0)

  const openInfoModal = () => {
    setOpenInfo(true)
  }

  const closeInfoModal = () => {
    setOpenInfo(false)
  }

  const openAddModal = () => {
    setOpenAdd(true)
  }

  const closeAddModal = () => {
    setOpenAdd(false)
  }

  return (
    <>
      <Box
        display="flex"
        gap={theme.spacing(3)}
        alignItems="center"
      >
        <IconButton onClick={openInfoModal} >
          <QuestionMarkIcon />
        </IconButton>
        <Button
          sx={{
            borderRadius: '16px',
            height: '30px',
            padding: `${theme.spacing(1.5)} ${theme.spacing(6)}`
          }}
          variant='contained'
          onClick={onClickReload}
          text="Reload"
          textVariant='subtitle1'
        />
        <IconButton onClick={openAddModal}>
          <AddIcon />
        </IconButton>
      </Box>
      {openInfo && <PresetsInfoModal handleClose={closeInfoModal} />}
      {openAdd && <AddEditPresetModal handleClose={closeAddModal} open={openAdd} />}
    </>
  )
}
