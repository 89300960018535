import React, { useState } from 'react'
import { TextField as TextFieldMUI } from '@mui/material'
import { autocompleteStyle } from './JsxStyles'

const InputText = (props: { value: string, onChange: (value: string) => void }) => {
  const [value, setValue] = useState(props.value)
  return <TextFieldMUI
          sx={{ ...autocompleteStyle, flex: '1 1 100%', width: 'fill-available', boxSizing: 'border-box !important', '>div': { borderRadius: '16px' }, input: { padding: '8px 12px !important', borderRadius: '12px' } }}
          onChange={(e) => setValue(e.target.value)}
          onBlur={(e) => e.target.value && props.onChange(e.target.value)}
          value={value}
          placeholder={'Value'}
        />
}

export default InputText
