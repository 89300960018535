import React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import LockIcon from '@mui/icons-material/Lock'

import { useTheme } from '@mui/material'

import type { Tab as ITab } from '@/designSystem/NavigationPanel/types'
import type { Option } from '@/designSystem/Autocomplete/types'

function ChosenTabFlag ({ isNested }: { isNested?: boolean }) {
  const theme = useTheme()
  return (
    <Box
      sx={{
        position: 'absolute',
        width: isNested ? '5px' : '4px',
        height: isNested ? '26px' : '24px',
        background: theme.palette.primary.main,
        top: '12px',
        left: 0,
        borderRadius: '0px 12px 12px 0px'
      }}
    />
  )
}

interface TabProps {
  tab: ITab
  onRoute: (value: Option<string>) => void
  onClickExpand?: (text: string) => void
  isNested?: boolean
}

export default function Tab ({ tab, onClickExpand, onRoute, isNested }: TabProps) {
  const { isOpen, icon, text, nestedTabs, ref, isChosen, noAccessTab } = tab
  const theme = useTheme()

  const onClick = () => {
    if (nestedTabs?.length) {
      onClickExpand?.(text)
    } else {
      onRoute({ value: ref ?? '#', title: text ?? '' })
    }
  }

  return (
    <>
      <ListItemButton
        onClick={onClick}
        sx={{
          pointerEvents: noAccessTab ? 'none' : undefined,
          paddingLeft: 0,
          paddingRight: 0,
          borderRadius: '16px',
          '&:hover': {
            borderRadius: '16px'
          }
        }}
      >
        {isChosen && <ChosenTabFlag isNested={isNested} />}
        {icon && (
          <ListItemIcon sx={{ marginLeft: isChosen ? theme.spacing(3) : undefined }}>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText primary={text} sx={{ marginLeft: isNested ? theme.spacing(10.5) : undefined }} />
        {noAccessTab && <LockIcon />}
        {nestedTabs && !noAccessTab && (isOpen
          ? <ExpandLess />
          : <ExpandMore />
        )}
      </ListItemButton>
      {nestedTabs && (
        <Collapse
          in={isOpen}
          timeout="auto"
          unmountOnExit
          sx={{ margin: `${theme.spacing(2)} 0` }}
        >
          <List component="div" disablePadding>
            <Box
              sx={{
                borderRadius: '16px',
                backgroundColor: theme.palette.whiteText.main,
                padding: `${theme.spacing(2)} 0`
              }}
            >
              {nestedTabs.map((tab) => !tab.isHidden && <Tab tab={tab} key={tab.text} onRoute={onRoute} isNested />)}
            </Box>
          </List>
        </Collapse>
      )}
    </>
  )
}
