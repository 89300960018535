import React from 'react'
import MuiButton from '@mui/material/Button'

import useTheme from '@mui/material/styles/useTheme'
import Typography from '@mui/material/Typography'

import type { ButtonProps as MuiButtonProps } from '@mui/material/Button'

import { type Theme } from '@mui/material'

type ButtonProps = Omit<MuiButtonProps, 'children'> & {
  text?: React.ReactNode
  textVariant?: keyof Theme['typography']
  icon?: React.ReactNode
  textColor?: string
}

export default function Button ({
  text,
  variant = 'outlined',
  fullWidth = true,
  disabled,
  icon,
  sx,
  textVariant = 'body2',
  textColor: propsTextColor,
  ...rest
}: ButtonProps) {
  const theme = useTheme()

  const getColor = () => {
    if (propsTextColor) {
      return propsTextColor
    } else {
      return variant === 'outlined'
        ? theme.palette.text1.main
        : theme.palette.whiteText.main
    }
  }
  const textColor = getColor()

  return (
    <MuiButton
      fullWidth={fullWidth}
      variant={variant}
      disabled={disabled}
      sx={{
        borderRadius: '12px',
        textTransform: 'capitalize',
        '&:disabled': {
          backgroundColor: theme.palette.inputText.main
        },
        ...sx
      }}
      {...rest}
    >
      {!!icon && icon}
      {text && (
        <Typography
          color={disabled ? theme.palette.disabledText.main : textColor}
          variant={textVariant as any}
        >
          {text}
        </Typography>
      )}
    </MuiButton>
  )
}
