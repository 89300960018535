import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'

export interface IPresetFilters {
  label?: string
  createdTo?: {
    from: string
    to: string
  }
}
export interface PresetsState {
  totalCount?: number
  presetsState?: IPreset[]
}

const initialState: PresetsState = {
  totalCount: 0,
  presetsState: []
}

export interface IPreset {
  id?: string
  label: string
  groups: string[]
  termsIds: string[]
  pql: string
  createdAt?: string
  updatedAt?: string
}

export const presetsSlice = createSlice({
  name: 'presets',
  initialState,
  reducers: {
    setPresetsTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setPresetsState: (state, action: PayloadAction<any>) => {
      state.presetsState = action.payload
    }
  }
})

export const { setPresetsTotalCount, setPresetsState } = presetsSlice.actions

export const selectPresetsTotalCount = (state: RootState) => state.presets.totalCount
export const selectPresetsState = (state: RootState) => state.presets.presetsState

export default presetsSlice.reducer

interface GetPresetsArgs {
  limit?: number
  page?: number
  filters?: IPresetFilters
}

interface GetPresetsReturn {
  totalCount?: number
  presets: IPreset[]
}

export const presetsApi = createApi({
  reducerPath: 'presetsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/presets`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['Presets'],
  endpoints: (builder) => ({
    getPresets: builder.query<GetPresetsReturn, GetPresetsArgs>({
      query: ({ limit = 10, page = 1, filters = {} }) => ({
        url: '/search',
        method: 'POST',
        body: {
          limit,
          page,
          sortField: 'createdAt',
          sortOrder: 'desc',
          createdAt: filters?.createdTo !== undefined ? filters?.createdTo : undefined,
          filter: Object.keys(filters).length
            ? {
                label: filters?.label ? filters?.label : undefined
              }
            : undefined
        }
      }),
      providesTags: [{ type: 'Presets', id: 'PresetsLIST' }]
    }),
    createPresets: builder.mutation<IPreset, IPreset>({
      query: ({
        ...rest
      }) => ({
        url: '',
        method: 'POST',
        body: {
          ...rest
        }
      }),
      invalidatesTags: [{ type: 'Presets', id: 'PresetsLIST' }]
    }),
    updatePresets: builder.mutation<IPreset, IPreset>({
      query: ({
        id, ...rest
      }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: {
          ...rest
        }
      }),
      invalidatesTags: [{ type: 'Presets', id: 'PresetsLIST' }]
    }),
    deletePresets: builder.mutation({
      query: (id: string) => ({
        url: `/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Presets', id: 'PresetsLIST' }]
    }),
    getPresetById: builder.query({
      query: (id: string) => ({
        url: `/${id}`,
        method: 'GET'
      })
    }),
    getPresetsGroupFields: builder.query({
      query: () => ({
        url: '/group-filds',
        method: 'GET'
      })
    }),
    getPresetsConditionsFields: builder.query({
      query: () => ({
        url: '/conditions-filds',
        method: 'GET'
      })
    })
  })
})

export const {
  useGetPresetsQuery,
  useCreatePresetsMutation,
  useUpdatePresetsMutation,
  useDeletePresetsMutation,
  useGetPresetByIdQuery,
  useGetPresetsGroupFieldsQuery,
  useGetPresetsConditionsFieldsQuery
} = presetsApi
