import React from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import {
  initialQueryParamsState,
  selectDealsQueryParams
  , setDealsQueryParams

} from '@/store/queryParams'
import { getDefaultColumns } from '@/utils/tables'
import ColumnSettingsModal, {
  type ColumnSettingsInputs,
  type ColumnSettingsModalProps
} from '@/components/Common/Tables/ColumnSettingsModal'
import { dealsHistoryHeaders } from '@/store/deals'

export default function DealsSettingsPopup ({ handleClose }: Pick<ColumnSettingsModalProps, 'handleClose'>) {
  const { limit, headers, filters } = useSelector(selectDealsQueryParams)
  const dispatch = useDispatch()
  const form = useForm<ColumnSettingsInputs>({
    defaultValues: {
      limit: { value: limit, title: `${limit}` },
      columns: getDefaultColumns(headers, dealsHistoryHeaders)
    }
  })
  const onSubmit: SubmitHandler<ColumnSettingsInputs> = (data) => {
    dispatch(setDealsQueryParams({
      limit: data.limit.value,
      headers: data.columns.map(({ title, value }) => value ? title : '').filter((value) => !!value),
      filters
    }))
    handleClose()
  }
  const handleReset = () => {
    form.reset({
      limit: { value: initialQueryParamsState.deals.limit, title: `${initialQueryParamsState.deals.limit}` },
      columns: getDefaultColumns(Object.values(dealsHistoryHeaders), dealsHistoryHeaders)
    })
    void form.handleSubmit(onSubmit)()
  }

  return (
    <ColumnSettingsModal
      form={form}
      onSubmit={onSubmit}
      handleClose={handleClose}
      handleReset={handleReset}

    />
  )
}
