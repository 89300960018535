interface TableColumn {
  name: string
  dbName: string
  title: string
  value: boolean
}

export function getDefaultColumns (headers: string[], allHeaders: Record<string, string>): TableColumn[] {
  const defaultHeaders = headers.map((header: string, index: number) => {
    const dbName = Object.keys(allHeaders).find(key => allHeaders[key] === header) ?? ''
    return {
      name: `columns.${index}`,
      dbName: dbName ?? '',
      title: header,
      value: true
    }
  }).concat(
    Object.entries(allHeaders)
      .filter(([, value]) => !headers.includes(value))
      .map(([key, value], index) => ({
        name: `columns.${index + headers.length}`,
        dbName: key,
        title: value,
        value: false
      }))
  )
  return defaultHeaders.filter(item => item.dbName)
}
