import React from 'react'
import ReactDOM from 'react-dom/client'
import App from '@/App'
import reportWebVitals from '@/reportWebVitals'
import { appVersion } from '@/config'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
console.log('app version', appVersion)
root.render(
  // <React.StrictMode>
    <App />
  // {/* </React.StrictMode> */}
)

reportWebVitals()
