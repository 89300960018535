import React, { useEffect, useState } from 'react'
// import { useGetRotationQuery } from '@/store/rotationControl'
import LogsLayout from './LogsLayout'
import { type PostbackLogsArgs, useGetPostbackLogsQuery } from '@/store/postBacks'

const LogsWrapper = ({ id }: { id: string }) => {
  const [postBacksLogs, setPostBackLogs] = useState([])
  const { data: postbacksLogs }: any = useGetPostbackLogsQuery(id)

  useEffect(() => {
    if (postbacksLogs) {
      console.log('🚀 ~ LogsWrapper ~ postbacksLogs:', postbacksLogs)
      postbacksLogs && setPostBackLogs(postbacksLogs?.logs ?? [])
    }
  }, [postbacksLogs])

  const logs: any = []
  return <LogsLayout logs={postBacksLogs}/>
//   return null
}

export default LogsWrapper
