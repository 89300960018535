import React from 'react'
import PageContentLayout from '@/designSystem/PageContentLayout'

import LeftActionButtons from '@/components/Pages/TrafficData/Hold/LeftActionButtons'
import RightActionButtons from '@/components/Pages/TrafficData/Hold/RightActionButtons'
import Table from '@/components/Pages/TrafficData/Hold/Table'

export default function HoldPage () {
  return (
    <PageContentLayout
      title="Hold"
      // support={{ content: <HelpInformationModal />, subtitle: 'Leads' }}
      leftActionButtons={<LeftActionButtons />}
      rightActionButtons={<RightActionButtons />}
    >
      <Table />
    </PageContentLayout>
  )
}
