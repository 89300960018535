import React from 'react'
import { Typography, useTheme } from '@mui/material'
import DateTimePicker from '@/designSystem/DateTimePicker'
import { type FilterItemProps } from '.'
import { sx } from '../constants'
function parseISOString (s: string | undefined) {
  if (!s) return undefined
  const b = s.split(/\D+/) as any
  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
}

const FromToDateFilterItem = (props: FilterItemProps) => {
  const { spacing, palette } = useTheme()
  const { onChange, isMultiple, title, name, selectedValues } = props

  if (typeof selectedValues === 'string' || typeof selectedValues === 'boolean' || Array.isArray(selectedValues)) return null

  const onSave = (e: any, isFrom: boolean) => {
    if (e?.getDate()) {
      const value = new Date(e).toISOString()
      onChange(name as any, { ...typeof selectedValues === 'object' ? selectedValues : {}, ...isFrom ? { from: value } : { to: value } }, isMultiple)
    }
  }

  const from = parseISOString(selectedValues?.from)
  const to = parseISOString(selectedValues?.to)

  return <>
    <Typography variant="body1" sx={{ marginTop: spacing(4) }} color={palette.card.main}>{title}</Typography>
    <Typography variant="subtitle1" color={palette.card.main} sx={{ marginTop: spacing(1) }}>From: </Typography><DateTimePicker value={from} sx={sx} onChange={(e) => onSave(e, true)}/>
    <Typography variant="subtitle1" color={palette.card.main} sx={{ marginTop: spacing(1) }}>To: </Typography><DateTimePicker value={to} sx={sx} onChange={(e) => onSave(e, false)} />
  </>
}

export default FromToDateFilterItem
