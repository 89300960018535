/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react'

import { format } from 'date-fns'

import { Box, Typography, useTheme } from '@mui/material'
import AutoInjectTableWrapper from './AutoInjectTableWrapper'
import { type IAutoInjection } from '../AutoInjectionTypes'
import { ModalEventInfo } from '@/designSystem/ModalMessagesLogs'
import { BooleanField, CountryField, TextField } from '@/designSystem/Table/Fields'
import { ReactComponent as ThreeDots } from '@/assets/icons/three-dots.svg'
import { useSelector, useDispatch } from 'react-redux'
import { selectAutoInject } from '@/store/autoInjections'
import { Months } from '../AutoInjectionsOptions'
import { StateField } from '@/components/Pages/TrafficData/Leads/ElementLeadsMap'
import { useGetLogsQuery } from '@/store/logs'
import { selectLogsQueryParams, setLogsQueryParams } from '@/store/queryParams'
import { type LeadState } from '@/enums/leads.enum'

interface ITableAutoInject {
  idAutoInjection: string
}

export interface IAutoInjectionTask {
  leadId: string
  leadState: LeadState
  newSentLeadIds: string[]
  processingStatus: string
  startedAt: string
  finishedAt: string
  actionId: string
}

const BoxWrapper = ({ children }: any) => {
  return (
    <Box
      sx={{ height: '67px', display: 'table-cell', verticalAlign: 'middle' }}
    >
      {children}
    </Box>
  )
}

const LitleSell = ({ children, width }: any) => {
  return (
    <BoxWrapper>
      <Typography
        display="flex"
        variant="body1"
        color={'#393E51'}
        width={width}
        textOverflow={'ellipsis'}
        overflow={'hidden'}
        whiteSpace={'nowrap'}
      >
        {children}
      </Typography>
    </BoxWrapper>
  )
}

const MenuAutoInjectTable = ({ idAutoInjection }: ITableAutoInject) => {
  const dispath = useDispatch()
  const { limit = 20, page } = useSelector(selectLogsQueryParams)
  const { data } = useGetLogsQuery({ limit, page, format: 'processedLeads', filter: { autoInjectionId: idAutoInjection } })
  const autoInjections = useSelector(selectAutoInject)
  const autoInjection: any = autoInjections?.find((autoInjection: IAutoInjection) => autoInjection.id === idAutoInjection)
  const { palette } = useTheme()

  const [modalLogs, setModalLogs] = React.useState<any>(null)

  useEffect(() => {
    if (data) {
      dispath(setLogsQueryParams({ limit, page, totalCount: data?.totalCount ?? 0 }))
    }
  }, [data])

  const validDate = (date: Date) => { return date && !isNaN(date.getTime()) }

  const rowsAutoInjectMain = [
    {
      id:
        <TextField text={autoInjection?.id} limitation={1} />,
      name:
        <LitleSell width="max-content">
          {autoInjection?.name}
        </LitleSell>,
      description:
        <LitleSell width="max-content">
          {autoInjection?.description}
        </LitleSell>,
      status:
        <LitleSell width="max-content">
          {autoInjection?.status}
        </LitleSell>
    }]

  const rowsAutoInjectSettings = [
    {
      country:
        <CountryField countryCode={autoInjection?.country ?? ''} />,
      interval:
        <LitleSell width="max-content">
          {autoInjection?.interval} Millisec
        </LitleSell>,
      finalState:
        <TextField text={autoInjection?.state} />,
      autoLogin:
        <BooleanField isSomething={autoInjection?.autoLogin ?? false} />
    }]

  const rowsAutoInjectFilters = [
    {
      fromDate:
        <LitleSell width="max-content">
          {autoInjection?.dateRangeFrom} {Months[autoInjection?.monthRangeFrom - 1]}
        </LitleSell>,
      toDate:
        <LitleSell width="max-content">
          {autoInjection?.dateRangeTo} {Months[autoInjection?.monthRangeTo - 1]}
        </LitleSell>,
      fromHours:
        <LitleSell width="max-content">
          {autoInjection?.hourRangeFrom} - {autoInjection?.hourRangeTo}
        </LitleSell>,
      states:
        <TextField text={autoInjection?.state} />,
      overrideFields:
        autoInjection?.overrideFields
          ? <TextField text={JSON.stringify(autoInjection?.overrideFields)} limitation={100} />
          : 'No override fields'
    }]

  const stampsDateAt = validDate(new Date(autoInjection?.createdAt)) ? format(new Date(autoInjection?.createdAt), 'P HH:mm:ss') : 'Empty'
  const stampsDateUp = validDate(new Date(autoInjection?.updatedAt)) ? format(new Date(autoInjection?.updatedAt), 'P HH:mm:ss') : 'Empty'
  const timeStampsAutoInjectSettings = [
    {
      createdArt:
        <BoxWrapper>
          <Typography
            display="flex"
            variant="body1"
            color={palette.text1.main}
            width="190px">
            {stampsDateAt}
          </Typography>
        </BoxWrapper>,
      stateUpdatedAt:
        <BoxWrapper>
          <Typography
            display="flex"
            variant="body1"
            color={palette.text1.main}
            width="190px">
            {stampsDateUp}
          </Typography>
        </BoxWrapper>
    }]

  const autoInjectionTasksLog = useMemo(() => {
    return data?.logs && data?.logs?.length > 0 && data?.logs?.map((task: IAutoInjectionTask) => {
      return {
        leadUUID:
        <TextField text={task?.leadId} limitation={1} />,
        leadState:
        <StateField state={task?.leadState} />,
        newSentLeadIds:
        task?.newSentLeadIds?.length > 0
          ? <ThreeDots
            onClick={() => setModalLogs(task?.newSentLeadIds)}
            style={{ cursor: 'pointer' }}
          />
          : <TextField text={task?.newSentLeadIds?.[0]} limitation={80} />,
        processingStatus:
        <TextField text={task?.processingStatus} />,
        startedAt:
        <TextField text={validDate(new Date(task?.startedAt)) ? format(new Date(task?.startedAt), 'P HH:mm:ss') : 'Empty'} />,
        finishedAt:
        <TextField text={validDate(new Date(task?.finishedAt)) ? format(new Date(task?.finishedAt), 'P HH:mm:ss') : 'Empty'} />,
        actionId:
        <TextField text={task?.actionId} limitation={1} />
      }
    })
  }, [data])

  return (
    <>
      <AutoInjectTableWrapper
        rowsAutoInjectMain={rowsAutoInjectMain}
        rowsAutoInjectSettings={rowsAutoInjectSettings}
        timeStampsAutoInjectSettings={timeStampsAutoInjectSettings}
        rowsAutoInjectFilters={rowsAutoInjectFilters}
        tasksLength={data?.logs?.length}
        rowsAutoInjectTasks={autoInjectionTasksLog}
      />
      {modalLogs && <ModalEventInfo
        open={!!modalLogs}
        handleClose={() => setModalLogs(null)}
        logs={modalLogs}
      />}
    </>
  )
}

export default MenuAutoInjectTable
