import React from 'react'

import useTheme from '@mui/material/styles/useTheme'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const helpInformation = {
  p1: 'This tab aims at assigning your lead sale statuses set in the CRM Sale Statuses settings to sale statuses of all your advertisers.',
  p2: 'To assign an advertiser status, drag it from the Unassigned Advertiser Statuses area to the required CRM Sale Status line, and place it in the Advertiser Sale Status column.',
  p3: 'You can later use your sale statuses to filter data in the Traffic Data and Conversions tables as well as to generate reports via Data Exporter, Drilldown Reports and Scheduled Reports.'
}

export default function HelpInformationModal () {
  const theme = useTheme()
  return (
  <>
    <Box
      display="flex"
      flexDirection="column"
      gap={theme.spacing(3)}
      color={theme.palette.text2.main}
    >
      <Typography variant="body2">
        {helpInformation.p1}
      </Typography>
      <Typography variant='body2'>
        {helpInformation.p2}
      </Typography>
      <Typography variant='body2'>
        {helpInformation.p3}
      </Typography>
    </Box>
  </>
  )
}
