import React, { useMemo } from 'react'

import Chip from '@/components/Advertisers/Filters/Chip'
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { type FiltersAdvertiser } from '@/store/advertisers'
import { Box, useTheme } from '@mui/material'
import cloneDeep from 'lodash.clonedeep'
import unset from 'lodash.unset'
import { selectQueryParamsAdvertisers, setQueryParamsAdvertisers } from '@/store/queryParams'

const Bar = ({ children }: { children: React.ReactNode }) => {
  const { spacing, palette } = useTheme()
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={spacing(3)}
      justifyContent="flex-start"
      padding={spacing(3, 4)}
      width="100%"
      borderRadius="12px"
      sx={{
        backgroundColor: palette.card.main
      }}
    >
      {children}
    </Box>
  )
}

const areFiltersExist = (filters?: FiltersAdvertiser) => {
  if (!filters) {
    return false
  }
  return Object.entries(filters).some(([key, value]) => {
    if (Array.isArray(value)) return !!value.length
    return !!value
  })
}

export default function FilterBar () {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectQueryParamsAdvertisers)

  const isParamExist = useMemo(() => areFiltersExist(queryParams.filters), [queryParams.filters])

  if (!isParamExist) {
    return null
  }

  const onDeleteParam = (label: string) => () => {
    const params = cloneDeep(queryParams)
    unset(params, `filters.${label}`)
    dispatch(setQueryParamsAdvertisers(params))
  }

  const filters = queryParams.filters!

  return (
    <Bar>
      {filters.id && (
        <Chip
          label={`Id: "${filters.id.join(',')}"`}
          onDelete={onDeleteParam('id')}
        />
      )}
      {filters.name && (
        <Chip
          label={`Name: "${filters.name}"`}
          onDelete={onDeleteParam('name')}
        />
      )}
      {filters.status !== undefined && (
        <Chip
          label={`Activity: ${filters.status ? 'Yes' : 'No'}`}
          onDelete={onDeleteParam('status')}
        />
      )}
      {filters.pullLeadStatuses !== undefined && (
        <Chip
          label={`Pulling: ${filters.pullLeadStatuses ? 'Yes' : 'No'}`}
          onDelete={onDeleteParam('pullLeadStatuses')}
        />
      )}
      {filters.createdAt?.from && (
        <Chip
          label={`Show from: ${format(new Date(filters.createdAt.from), 'P HH:mm')}`}
          onDelete={onDeleteParam('createdAt.from')}
        />
      )}
      {filters.createdAt?.to && (
        <Chip
          label={`Show before: ${format(new Date(filters.createdAt.to), 'P HH:mm')}`}
          onDelete={onDeleteParam('createdAt.to')}
        />
      )}
    </Bar>
  )
}
