import React from 'react'
import { Box } from '@mui/material'
import PageContentLayout from '@/designSystem/PageContentLayout'
import { ReactComponent as SettingsIcon } from '@/assets/icons/settings-icon.svg'
import { ReactComponent as ApiTokensIcon } from '@/assets/icons/api-tokens.svg'
import { ReactComponent as PresetsIcon } from '@/assets/icons/presets.svg'
import { ReactComponent as CrmSettingsIcon } from '@/assets/icons/crm-settings.svg'
import { ReactComponent as CrmTermsIcon } from '@/assets/icons/crm-terms.svg'
import { ReactComponent as ArrowRightRoundIcon } from '@/assets/icons/arrow-right-round.svg'
import { ReactComponent as AdvertisersLogsIcon } from '@/assets/icons/advertiser-logs.svg'
import { ReactComponent as DeduplicationIcon } from '@/assets/icons/deduplication.svg'
import { useNavigate } from 'react-router-dom'
import { LateMinimumIntervalPopup } from '@/components/Pages/Settings/LateMinimumIntervalPopup'
import { LeadsDeduplicationPopup } from '@/components/Pages/Settings/LeadsDeduplicationPopup'
import { AutoLoginDomainPopup } from '@/components/Pages/Settings/AutoLoginDomain'

interface ISettingsItem {
  icon: React.ReactNode
  title: string
  to?: string
  text: string
  onClick?: () => void
}

const SettingsItem = ({ icon, title, to, text, onClick }: ISettingsItem) => {
  const navigate = useNavigate()

  const onClickSettings = () => {
    to && navigate(to)
    onClick && onClick()
  }

  return (
    <Box
      width={'100%'}
      borderRadius={'16px'}
      border={'1px solid #4141D5'}
      padding={'20px 16px 20px 16px'}
      onClick={onClickSettings}
      sx={{
        cursor: 'pointer',
        backgroundColor: '#FFFFFF'
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'20px'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
          >
            {icon}
            <Box
              sx={{
                fontSize: '20px',
                fontWeight: 500,
                color: '#000931',
                marginLeft: '15px'
              }}
            >
              {title}
            </Box >
          </Box>
          <ArrowRightRoundIcon />
        </Box>
        <Box
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            color: '#393E51'
          }}
        >
          {text}
        </Box>
      </Box>
    </Box>
  )
}

const SettingsPage = () => {
  const [openLateMinimumInterval, setOpenLateMinimumInterval] = React.useState(false)
  const [openLeadsDeduplication, setOpenLeadsDeduplication] = React.useState(false)
  const [openAutoLoginDomain, setOpenAutoLoginDomain] = React.useState(false)

  const handleOpenLateMinimumInterval = () => {
    setOpenLateMinimumInterval(true)
  }

  const handleCloseLateMinimumInterval = () => {
    setOpenLateMinimumInterval(false)
  }

  const handleOpenLeadsDeduplication = () => {
    setOpenLeadsDeduplication(true)
  }

  const handleCloseLeadsDeduplication = () => {
    setOpenLeadsDeduplication(false)
  }

  const handleOpenAutoLoginDomain = () => {
    setOpenAutoLoginDomain(true)
  }

  const handleCloseAutoLoginDomain = () => {
    setOpenAutoLoginDomain(false)
  }

  return (
    <>
      <PageContentLayout title={<Box
        display={'flex'}
        alignItems={'center'}
      >
        <SettingsIcon />
        <Box
          sx={{
            fontSize: '28px',
            fontWeight: 500,
            color: '#000931',
            marginLeft: '15px'
          }}
        >
          Settings
        </Box>
      </Box>}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'16px'}
        >
          <SettingsItem icon={<ApiTokensIcon />} title={'API tokens'} to={'/settings/api-tokens'}
            text="Here you can create an API Token - the personal unique key according to your needs." />
          <SettingsItem icon={<PresetsIcon />} title={'Presets list'} to={'/settings/presets-list'}
            text="Presets are preconfigured conditions and fields for Data Exporter, and conditions for..." />
          <SettingsItem icon={<CrmTermsIcon />} title={'CRM terms'} to={'/settings/crm-terms'}
            text="CRM Terms are basically the formulas consisting of the variables and mathematical signs..." />
          <SettingsItem icon={<AdvertisersLogsIcon />} title={'Advertisers logs'} to={'/settings/advertisers-logs'}
            text="Here you can see the information Advertisers integrations." />
          <SettingsItem icon={<CrmSettingsIcon />} title={'Late Minimum Interval'} onClick={handleOpenLateMinimumInterval}
            text="Late Minimum Interval is a scheduling term commonly used in project management, particularly in the context of scheduling tasks or activities." />
          <SettingsItem icon={<DeduplicationIcon />} title={'Leads Deduplication'} onClick={handleOpenLeadsDeduplication}
            text="Leads Deduplication is a process commonly employed in sales and marketing operations to ensure the accuracy and efficiency of lead management." />
             <SettingsItem icon={<ApiTokensIcon />} title={'Auto Login Domain'} onClick={handleOpenAutoLoginDomain}
            text="You can change Auto Login Domain" />
        </Box>
      </PageContentLayout>
      <AutoLoginDomainPopup open={openAutoLoginDomain} handleClose={handleCloseAutoLoginDomain} />
      <LateMinimumIntervalPopup open={openLateMinimumInterval} handleClose={handleCloseLateMinimumInterval} />
      <LeadsDeduplicationPopup open={openLeadsDeduplication} handleClose={handleCloseLeadsDeduplication} />
    </>
  )
}

export default SettingsPage
