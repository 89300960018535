import React from 'react'

import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'
import ReloadButton from '@/designSystem/ReloadButton'

interface LeftActionButtonsProps {
  onClickReload: () => void
}

export default function LeftActionButtons ({
  onClickReload
}: LeftActionButtonsProps) {
  const theme = useTheme()

  return (
    <>
      <Box
        display="flex"
        gap={theme.spacing(3)}
        alignItems="center"
      >
        <ReloadButton onClick={onClickReload} />
      </Box>
    </>
  )
}
