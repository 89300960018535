import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import GoalType from '@/designSystem/Pages/TrafficDistribution/RotationControl/icons/GoalType'
import { Typography, useTheme } from '@mui/material'
import Form from '@/components/Form'
import IOSSwitch from '@/components/Form/Switch'
import TextField from '@/components/Form/TextField'
import Popup from '@/designSystem/Popup'
import TextFieldLayout, { type TextFiledProps as TextFiledLayoutProps } from '@/designSystem/TextField'
import { Controller, type SubmitHandler, type UseFormReturn } from 'react-hook-form'
import ActionButtons from '@/designSystem/ActionButtons'
import Autocomplete from '@/components/Form/Autocomplete'
import FlexBox from '@/designSystem/FlexBox'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Renderer from './Renderer'
import { countryList, placeholderValues } from '@/constants'
import { type Option } from '@/designSystem/Autocomplete/types'
import PQL from '@/designSystem/TreeView/PQL'
import { type Inputs } from './AddPostBackPopup'
import CountryIcon from '@/designSystem/Pages/TrafficDistribution/RotationControl/icons/CountryIcon'
import PQLIcon from '@/designSystem/Pages/TrafficDistribution/RotationControl/icons/PQLIcon'
import Postback from '@/designSystem/Pages/TrafficDistribution/RotationControl/icons/Postback'
import { LeadState } from '@/enums/leads.enum'

// import { Inputs } from './AddPostBackPopup'
export enum ERenderer {
  // GOALTYPE = 'goalType',
  COUNTRY = 'country',
  FREE_PQL = 'free_pql',
}
export interface AddPopupProps {
  form: UseFormReturn<Inputs>
  onSubmit: SubmitHandler<Inputs>
  handleClose: () => void
  title: string
  subtitle: string
  id?: number
}

export const goalTypeOptions = [{
  title: 'FTD', value: 'FTD'
}, {
  title: 'Lead', value: 'PUSHED_LEAD'
}]
export const requestMethodOptions = [{
  title: 'GET', value: 'GET'
}, {
  title: 'POST', value: 'POST'
}]

export default function AddOrEditPopup ({
  form,
  onSubmit,
  handleClose,
  title,
  subtitle,
  id
}: AddPopupProps) {
  const theme = useTheme()
  const { control: formControl, formState: { errors } } = form
  const [renderer, setRenderer] = useState<ERenderer | null>(form.getValues().renderer)
  const countryOptions: Array<Option<string>> = Object.entries(countryList)
    .map(([key, value]) => ({
      title: value,
      value: key
    }))
  const onSetRenderer = (value: ERenderer | null) => {
    setRenderer(value)
  }

  useEffect(() => {
    if (renderer) { form.setValue('renderer', renderer) }
  }, [renderer])

  if (!renderer) {
    return <Popup
      open
      title={title}
      subTitle={subtitle}
      handleClose={handleClose}
      rootBoxSx={{ backgroundColor: '#F1F3FF', paddingX: theme.spacing(4) }}
    >
      <Box sx={{ background: 'white', padding: theme.spacing(4), borderRadius: '16px' }}>
        <FlexBox sx={{ justifyContent: 'center', marginBottom: theme.spacing(3) }}>
          <Box sx={{ width: 184 }}></Box>
        </FlexBox>
        <Typography variant='h2' sx={{ fontWeight: 'bold', marginBottom: theme.spacing(1) }}>Recommended</Typography>
        <Typography sx={{ marginBottom: theme.spacing(2) }}>Choose passing rule</Typography>
        <FlexBox sx={{ gap: theme.spacing(2) }}>
          <Renderer renderer={ERenderer.COUNTRY} onClick={onSetRenderer} />
          <Renderer renderer={ERenderer.FREE_PQL} onClick={onSetRenderer} />
        </FlexBox>
      </Box>
    </Popup>
  }

  return (
    <Popup
      open
      title={title}
      subTitle={subtitle}
      handleClose={handleClose}
      rootBoxSx={{ backgroundColor: '#F1F3FF' }}
    >
      <Box sx={{ background: 'white', padding: theme.spacing(4), borderRadius: '16px' }}>
        <FlexBox sx={{ justifyContent: 'space-between' }}>
          <FlexBox sx={{ width: '50%' }}>
            <ArrowBackIosIcon onClick={() => setRenderer(null)} />
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: theme.spacing(3), paddingX: theme.spacing(3), width: '100%' }}>
              <Box width={'32px'} height={'32px'}>
                <Postback />
              </Box>
              <FlexBox flexDirection={'column'} gap={2}>
                <Typography sx={{ fontWeight: 'bold' }}>Postback</Typography>
                <Typography variant='subtitle2' sx={{ textAlign: 'start' }}>Create Postback, add the content, placeholders and manage the settings</Typography>
              </FlexBox>
            </Box>
          </FlexBox>
          {/* <Box sx={{ width: 184 }}>
            <Type type={type} />
          </Box> */}
          {renderer === ERenderer.COUNTRY && <Box sx={{ display: 'flex', flexDirection: 'row', gap: theme.spacing(3), paddingX: theme.spacing(3), width: '50%' }}>
            <Box width={'32px'} height={'32px'}>
              <CountryIcon />
            </Box>
            <FlexBox flexDirection={'column'} gap={2}>
              <Typography sx={{ fontWeight: 'bold' }}>Country</Typography>
              <Typography variant='subtitle2' sx={{ textAlign: 'start' }}>You can specify the countries that will pass</Typography>
            </FlexBox>

          </Box>
          }
          {renderer === ERenderer.FREE_PQL && <Box sx={{ display: 'flex', flexDirection: 'row', gap: theme.spacing(3), paddingX: theme.spacing(3), width: '50%' }}>
            <Box width={'32px'} height={'32px'}>
              <PQLIcon />
            </Box>
            <FlexBox flexDirection={'column'} gap={2}>
              <Typography sx={{ fontWeight: 'bold' }}>Free PQL</Typography>
              <Typography variant='subtitle2' sx={{ textAlign: 'start' }}>Create your own rule with our Query Language</Typography>
            </FlexBox>
          </Box>
          }
        </FlexBox>
        <Form form={form} onSubmit={onSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            gap={theme.spacing(6)}
          >
            <Box display="flex" flexDirection="column" gap={theme.spacing(3)}>
              <Box>
                <Controller
                  rules={{ required: true }}
                  name="name"
                  render={({ field }) => (
                    <TextField title="Name" autoComplete='off' />
                  )}
                />
                {errors.name && <Typography sx={{
                  color: theme.palette.error.main,
                  marginTop: '1px',
                  fontSize: '12px'
                }}>Name is required</Typography>}
              </Box>
              <Box>
                <Controller
                  rules={{ required: true }}
                  name="description"
                  render={({ field }) => (
                    <TextField title="Description" autoComplete='off' />

                  )}
                />
                {errors.description && <Typography sx={{
                  color: theme.palette.error.main,
                  marginTop: '1px',
                  fontSize: '12px'
                }}>Description is required</Typography>}
              </Box>
              <Box>
              <Controller
                rules={{ required: true }}
                name="goalType"
                render={({ field }) => (
                  <Autocomplete
                    title="Goal Type"
                    options={goalTypeOptions}
                  />
                )}
              />
                {errors.goalType && <Typography sx={{
                  color: theme.palette.error.main,
                  marginTop: '1px',
                  fontSize: '12px'
                }}>Goal Type is required</Typography>}
              </Box>
              <Box>
              <Controller
                rules={{ required: true }}
                name="requestMethod"
                render={({ field }) => (
                  <Autocomplete
                    title="Request Method"
                    options={requestMethodOptions}
                  />
                )}
                />
                {errors.requestMethod && <Typography sx={{
                  color: theme.palette.error.main,
                  marginTop: '1px',
                  fontSize: '12px'
                }}>Request Method is required</Typography>}
              </Box>

              {renderer === ERenderer.COUNTRY &&
              <Box>
                <Controller
                rules={{ required: true }}
                name="country"
                render={({ field }) => (
                  <Autocomplete
                    title="Country"
                    options={countryOptions}
                  />
                )}
              />
                {errors.country && <Typography sx={{
                  color: theme.palette.error.main,
                  marginTop: '1px',
                  fontSize: '12px'
                }}>Country is required</Typography>}
              </Box>}
              {renderer === ERenderer.FREE_PQL &&
              <Box>
                <Controller
                  control={formControl}
                  rules={{ required: true }}
                  name="pql"
                  render={({ field }) => (
                    <PQL form={form} />
                  )}
                />
                {errors.pql && <Typography sx={{
                  color: theme.palette.error.main,
                  marginTop: '1px',
                  fontSize: '12px'
                }}>PQL is required</Typography>}
              </Box>}
            </Box>
            <Box>
                 <Controller
                  rules={{ required: true, pattern: /^[^?]+(?:\?(?:[^&=]+=(?:\{\{[^}]+\}\}|[^&=]+))(?:&[^&=]+=(?:\{\{[^}]+\}\}|[^&=]+))*)?$/ }}
                  name='postbackLink'
                  render={({ field: { value, onChange } }) => (
                    <TextFieldLayout
                      value={value}
                      onChange={onChange}
                      multiline
                      rows={2}
                      inputHeight='70px'
                      plusParameters
                      name='postbackLink'
                      title='URL'
                      placeholder='Enter value...'
                      placeholders={placeholderValues}
                    />
                  )}
                />
                {errors.postbackLink && <Typography sx={{
                  color: theme.palette.error.main,
                  marginTop: '1px',
                  fontSize: '12px'
                }}>URL not valid</Typography>}
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <IOSSwitch
                defaultValue
                formControlLabel={{
                  label: 'Active',
                  labelPlacement: 'start',
                  sx: {
                    margin: 0,
                    '& .MuiTypography-root': {
                      marginRight: theme.spacing(3)
                    }
                  }
                }}
                name="active"
              />
            </Box>
            <ActionButtons
              onCancel={handleClose}
            />
          </Box>
        </Form>

      </Box>
    </Popup>
  )
}
