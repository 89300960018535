import React, { useEffect, useState } from 'react'
// import DateTimePicker from '@/components/Form/DateTimePicker'
import DateTimePickerLayout from '@/designSystem/DateTimePicker'
import type { Option } from '@/designSystem/Autocomplete/types'
import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'
import { ReactComponent as Info } from '@/assets/icons/info.svg'
import { type SxProps, Typography, styled } from '@mui/material'
import { type SubmitHandler, useForm, type UseFormReturn } from 'react-hook-form'
import Autocomplete from '@/designSystem/Pages/Advertisers/Autocomplete'
import { useSelector, useDispatch } from 'react-redux'
import { selectCurrentRange, selectGlobalTimeRange, setCurrentRange, setGlobalTimeRange } from '@/store/dashboard'
import { getCurrentDay, getCurrentMonth, getCurrentWeek, getCurrentYear, getLastMonth, getLastWeek, getYesterday } from './utils'
import dayjs, { type Dayjs } from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker, type DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker'
import { selectTimezone } from '@/store/settings'

function DateTimePickerX<T> ({ value, onChange, sx, ...rest }: DateTimePickerProps<T>) {
  const { palette } = useTheme()
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          format="DD/MM/YYYY"
          value={value}
          onChange={onChange} // Cast newValue to Dayjs | null
          sx={{
            width: '100%',
            background: '#FFFFFF',
            borderRadius: '16px',
            padding: '8px 16px',
            '& .MuiInputBase-root': {
              '& fieldset': {
                // border: `1px solid ${palette.primary.main}`,
                borderRadius: '16px'
              },
              '&:hover fieldset': {
                // borderColor: palette.primary.main
              },
              '&.Mui-focused fieldset': {
                // borderColor: palette.primary.main
              }
            },
            ...sx
          }}
          {...rest}
        />
    </LocalizationProvider>
  )
}

export default function RightActionButtons (): JSX.Element {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { palette, spacing } = theme
  const datePickerSx: SxProps = {
    backgroundColor: '#FFFFFF',
    borderRadius: '16px',
    padding: '10px 16px'

  }
  const currentRange = useSelector(selectCurrentRange)

  const [valueRangeFrom, setValueRangeFrom] = React.useState<Dayjs | null>(dayjs(currentRange.from))
  const [valueRangeTo, setValueRangeTo] = React.useState<Dayjs | null>(dayjs(currentRange.to))
  useEffect(() => {
    setValueRangeFrom(dayjs(currentRange.from))
    setValueRangeTo(dayjs(currentRange.to))
  }, [currentRange])
  const globalTimeRange = useSelector(selectGlobalTimeRange)
  const dateValues = [
    { title: 'Today', value: 'today' },
    { title: 'Yesterday', value: 'yesterday' },
    { title: 'Last week', value: 'lastWeek' },
    { title: 'This week', value: 'currentWeek' },
    { title: 'This month', value: 'currentMonth' },
    { title: 'Last month', value: 'lastMonth' },
    { title: 'Year', value: 'currentYear' }
  ]
  const onChangeDate = (value: Option<string> | null) => {
    dispatch(
      setGlobalTimeRange({
        ...globalTimeRange,
        globalTimeSelect: value?.value
      })
    )
    switch (value?.value) {
      case 'today':
        dispatch(setCurrentRange(getCurrentDay()))
        break
      case 'currentYear':
        dispatch(setCurrentRange(getCurrentYear()))
        break
      case 'lastMonth':
        dispatch(setCurrentRange(getLastMonth()))
        break
      case 'currentMonth':
        dispatch(setCurrentRange(getCurrentMonth()))
        break
      case 'currentWeek':
        dispatch(setCurrentRange(getCurrentWeek()))
        break
      case 'lastWeek':
        dispatch(setCurrentRange(getLastWeek()))
        break
      case 'yesterday':
        dispatch(setCurrentRange(getYesterday()))
        break
    }
  }

  return (
    <>
      <Box
        display='flex'
        gap={theme.spacing(3)}
        alignItems='center'
      >
        <Box
         display='flex'
         gap={theme.spacing(3)}
         alignItems='center'
        >
          <CustomizedDatePickers
            sx={datePickerSx}
            value={valueRangeFrom}
            onChange={(newValue: any) => {
              setValueRangeFrom(newValue)
              dispatch(setCurrentRange({
                from: dayjs(newValue)?.toISOString(),
                to: currentRange.to
              }))
            }} />
          <CustomizedDatePickers
            sx={datePickerSx}
            value={valueRangeTo}
            onChange={(newValue: any) => {
              setValueRangeTo(newValue)
              dispatch(setCurrentRange({
                from: currentRange.from,
                to: dayjs(newValue)?.toISOString()
              }))
            }} />
         <Autocomplete
            onChange={onChangeDate}
            options={dateValues}
            clearIcon=''
            defaultValue={dateValues[4]}
            placeholder='Date'
            sx={{
              width: '450px'
            }}
            inputSx={{
              backgroundColor: 'blue',
              color: 'white'
            }}
          />
        </Box>
      </Box>
    </>
  )
}

const CustomizedDatePickers = styled(DateTimePickerX)(`  
.MuiInputBase-root{
  background: #FFFFFF
  border-radius: 16px
  padding: 8px 16px
}
input{
  padding: 0
  font-size: 16px
  line-height: 24px
  letter-spacing: 0.15px
  color: #393E51
}
label{
  color: #6B6B6B
  font-size: 18px
  font-style: normal
  font-weight: 500
  line-height: normal
  margin-top: -9px
  &.Mui-focused{
    margin-top: -4px
  }
}
`)
