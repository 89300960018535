import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@/designSystem/Button'
import useTheme from '@mui/material/styles/useTheme'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Info } from '@/assets/icons/info.svg'
import AdvertisersLogsInfoPopup from './AdvertisersLogsInfoPopup'

export default function LeftActionButtons (): JSX.Element {
  const [openInformationPopup, setOpenInformationPopup] = useState(false)
  const theme = useTheme()
  const navigate = useNavigate()
  const handleOpenInformationModal = () => {
    setOpenInformationPopup(true)
  }
  const onClickReload = () => navigate(0)
  return (
    <>
      <Box
        display="flex"
        gap={theme.spacing(3)}
        alignItems="center"
      >
        <Button
          sx={{
            borderRadius: '16px',
            height: '30px',
            minWidth: '30px',
            width: '30px',
            padding: `${theme.spacing(0)}`
          }}
          // variant='unstyled'
          onClick={handleOpenInformationModal}
          icon = {<Info />}
          textVariant='subtitle1'
        />
        <Button
          sx={{
            borderRadius: '16px',
            height: '30px',
            padding: `${theme.spacing(1.5)} ${theme.spacing(6)}`
          }}
          variant='contained'
          onClick={onClickReload}
          text="Reload"
          textVariant='subtitle1'
        />
      </Box>
      {openInformationPopup && (
        <AdvertisersLogsInfoPopup handleClose={() => setOpenInformationPopup(false)} />
      )}
    </>
  )
}
