import React from 'react'

export default function PointIcon () {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="30" width="30" height="30" rx="15" transform="rotate(90 30 0)" fill="#393E51"/>
      <rect x="23" y="7" width="16" height="16" rx="8" transform="rotate(90 23 7)" fill="#F1F3FF"/>
    </svg>
  )
}
