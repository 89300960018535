import React from 'react'

export default function Leave () {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="12" fill="#F1F3FF"/>
      <path opacity="0.5" d="M23.0867 15.5C23 16.4633 23 17.6733 23 19.2033V36.7967C23 38.3267 23 39.5367 23.0867 40.5H21.3333C17.405 40.5 15.44 40.5 14.22 39.28C13 38.0583 13 36.095 13 32.1667V23.8333C13 19.905 13 17.94 14.22 16.72C15.44 15.5 17.405 15.5 21.3333 15.5H23.0867Z" fill="#FF070D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24.1783 12.0159C23 13.0609 23 14.9725 23 18.7942V37.2075C23 41.0292 23 42.9409 24.1783 43.9859C25.3567 45.0309 27.1583 44.7175 30.7617 44.0892L34.645 43.4125C38.635 42.7159 40.63 42.3675 41.815 40.9042C43 39.4392 43 37.3225 43 33.0875V22.9142C43 18.6809 43 16.5642 41.8167 15.0992C40.63 13.6359 38.6333 13.2875 34.6433 12.5925L30.7633 11.9142C27.16 11.2859 25.3583 10.9725 24.18 12.0175L24.1783 12.0159ZM29.25 26.2575C29.25 25.5342 28.69 24.9492 28 24.9492C27.31 24.9492 26.75 25.5342 26.75 26.2559V29.7459C26.75 30.4675 27.31 31.0525 28 31.0525C28.69 31.0525 29.25 30.4675 29.25 29.7459V26.2559V26.2575Z" fill="#FF070D"/>
    </svg>
  )
}
