import React from 'react'

import {
  BooleanField, CountryField, DateTimeField, TextField
} from '@/designSystem/Table/Fields'
import { Box, Typography } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { type IConversions } from './ConversionsTypes'
import { conversionsHeaders } from '@/store/conversions'

export const allHeaders: Record<string, string> = {
  ...conversionsHeaders,
  sentLeadId: 'Sent Lead UUID',
  revenue: 'Revenue',
  conversionId: 'Conversion UUID',
  conversionChangeLog: 'Conversion Change Log'
}

export function FieldMap ({ children }: any) {
  const { palette } = useTheme()
  return (
    <Typography
      sx={{ display: 'flex' }}
      variant='table1'
      width="max-content"
      color={palette.text2.main}
    >
      {children}
    </Typography>
  )
}

const ConversionsLogToField = (conversionLog: string) => {
  const log = JSON.parse(conversionLog)
  return log.map((item: any, index: number) => {
    return (
      <FieldMap key={index}>
        <Typography
          variant='body1'
          width="max-content"
          color='text2'
        >
          {item.field} from {item.from}$ to {item.to}$
        </Typography>
      </FieldMap>
    )
  })
}

interface IIndicatorConversions {
  active: boolean
  isCheck?: boolean
  setCheck?: () => void
}
export const IndicatorConversions = ({ active, isCheck, setCheck }: IIndicatorConversions) => {
  const { palette } = useTheme()
  return (
    <Box
      position='relative'
      display='flex'
      alignItems='center'
      width={0}
    >
      <Box
        sx={{
          borderRadius: '0px 6px 6px 0px',
          width: '6px',
          height: '36px',
          backgroundColor: active ? '#6B6B6B' : palette.grey[300],
          position: 'absolute'
        }}
      />
      <input
        type='checkbox'
        style={{
          flexShrink: 0,
          marginLeft: '12px',
          width: '24px',
          height: '24px'
        }}
        checked={isCheck}
        onClick={setCheck}
      />
    </Box>
  )
}

const elementConversionsMap = (header: string, row: IConversions &
{ isCheck: boolean, sentLeadId: string, updatedAt: string }, isChecked?: boolean, onCheckboxClick?: (id: string) => void) => {
  const {
    id, leadId, country, isTest, advertiserId,
    goalType, revenue, affSub, affSub1, affSub2, affSub3, affSub4, affSub5, affSub6,
    affSub7, affSub8, affSub9, affSub10, affSub11, affSub12, affSub13, affSub14, affSub15,
    createdAt, sentLeadId, updatedAt, conversionId, conversionChangeLog
  } = row

  switch (header) {
    case '::indicatorConversions':
      return <IndicatorConversions active isCheck={isChecked} setCheck={() => onCheckboxClick && onCheckboxClick(id)} />
    case allHeaders.id:
      return <TextField text={id} limitation={1} />
    case allHeaders.leadId:
      return <TextField text={leadId} limitation={1} />
    case allHeaders.country:
      return <CountryField countryCode={country ?? ''} />
    case allHeaders.goalType:
      return <TextField text={goalType} />
    case allHeaders.affSub:
      return <TextField text={affSub} />
    case allHeaders.affSub1:
      return <TextField text={affSub1} />
    case allHeaders.affSub2:
      return <TextField text={affSub2} />
    case allHeaders.affSub3:
      return <TextField text={affSub3} />
    case allHeaders.affSub4:
      return <TextField text={affSub4} />
    case allHeaders.affSub5:
      return <TextField text={affSub5} />
    case allHeaders.affSub6:
      return <TextField text={affSub6} />
    case allHeaders.affSub7:
      return <TextField text={affSub7} />
    case allHeaders.affSub8:
      return <TextField text={affSub8} />
    case allHeaders.affSub9:
      return <TextField text={affSub9} />
    case allHeaders.affSub10:
      return <TextField text={affSub10} />
    case allHeaders.affSub11:
      return <TextField text={affSub11} />
    case allHeaders.affSub12:
      return <TextField text={affSub12} />
    case allHeaders.affSub13:
      return <TextField text={affSub13} />
    case allHeaders.affSub14:
      return <TextField text={affSub14} />
    case allHeaders.affSub15:
      return <TextField text={affSub15} />
    case allHeaders.advertiserId:
      return <TextField text={advertiserId} limitation={1} />
    case allHeaders.isTest:
      return <BooleanField isSomething={isTest} />
    case allHeaders.createdAt:
      return <DateTimeField date={new Date(createdAt)} />
    case allHeaders.updatedAt:
      return <DateTimeField date={new Date(updatedAt)} />
    case allHeaders.sentLeadId:
      return <TextField text={sentLeadId} limitation={1} />
    case allHeaders.revenue:
      return <TextField text={`${revenue}$`} />
    case allHeaders.conversionId:
      return <TextField text={conversionId} limitation={100} />
    case allHeaders.conversionChangeLog:
      return <Box>{ConversionsLogToField(conversionChangeLog ?? '')}</Box>
  }
}

export default elementConversionsMap
