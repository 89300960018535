import React from 'react'

import LeadsLogsLayout from './LeadsLogsLayout'

export enum LeadsTypeColorsLogs {
  'Valitaion error' = 'rgba(255, 7, 13, 0.30)',
  'FSM state switched' = 'rgba(65, 65, 213, 0.30)',
  'Registration' = 'rgba(31, 180, 38, 0.30)',
  'Pushing to advertisers' = 'rgba(31, 180, 38, 0.30)',
  'Lead flow started' = 'rgba(65, 65, 213, 0.30)'
}

export interface ILeadsLogs {
  id: string
  message: string
  type: 'Valitaion error' | 'FSM state switched' | 'Registration' | 'Pushing to advertisers' | 'Lead flow started'
  createdAt: Date
  action?: string
  logData: any
}

export default function LeadsLogs ({ leadLogs }: any) {
  const newLogs = leadLogs?.map((log: any) => {
    const logDataFrom = log?.logData?.data?.state?.from ?? ''
    const logDataTo = log?.logData?.data?.state?.to ?? ''
    console.log('log', log)
    return {
      id: log?.id,
      message: logDataFrom ? `${logDataFrom} -> ${logDataTo}` : '',
      type: '',
      logData: log?.logData,
      createdAt: new Date(log?.createdAt),
      action: log?.action
    }
  })
  return <LeadsLogsLayout logs={newLogs} />
}
