import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { Box, Typography } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'
import ActionButtons from '@/designSystem/ActionButtons'
import SettingsTextField from './SettingsTextField'
import {
  selectPullLeadState,
  setPullLeadStateSettings,
  setPullLeadStatusesSettings,
  useEditAdvertiserMutation
} from '@/store/advertisers'
import { EApiSettingsFields, type IApiSettings } from './EditPushSettingsForm'
import { useDispatch, useSelector } from 'react-redux'
import AccountsAutocomplete from '@/pages/Accounts/AccountsAutocomplete'
import { checkRequestTimeout } from '@/utils/advertisers'
import { StateCheckTypeEnum } from '@/enums/advertiser.enum'

interface EditPullSettingsModalProps {
  advertiserId?: string
  handleClose: () => void
  settings?: IApiSettings[]
  setSettings?: (settings: IApiSettings[]) => void
  settingsType: 'pullLeadStatusesSettings' | 'pullLeadStateSettings'
}
const stateCheckTypeOptions = [{ value: StateCheckTypeEnum.STATE_FIELD_VALUE, title: 'State field' },
  { value: StateCheckTypeEnum.FIELD_AVAILABILITY, title: 'Field availability' },
  { value: StateCheckTypeEnum.LEAD_ID_AVAILABILITY, title: 'Lead id availability' }]

const regexValidUrl = /^(http|https):\/\/[^ "]+$/

const emptyError: Partial<Record<EApiSettingsFields, string>> = {}

export default function EditPullSettingsFormModal ({
  advertiserId,
  handleClose,
  settings,
  setSettings,
  settingsType
}: EditPullSettingsModalProps) {
  const dispatch = useDispatch()
  const [editAdvertiser] = useEditAdvertiserMutation()
  const [error, setError] = React.useState<Partial<Record<EApiSettingsFields, string>>>(emptyError)
  const [remoteError, setRemoteError] = React.useState('')
  const pullLeadState = useSelector(selectPullLeadState)
  const url = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.URL)?.value ?? ''
  const statusField = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.STATUS_FIELD)?.value
  const leadIdField = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.LEAD_ID_FIELD)?.value
  const leadListField = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.LEAD_LIST_FIELD)?.value
  const stateCheckType = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.STATE_CHECK_TYPE)?.value as StateCheckTypeEnum

  const pageField = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.PAGE_FIELD)?.value
  const startingPageNumber = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.STARTING_PAGE_NUMBER)?.value
  const requestTimeout = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.REQUEST_TIMEOUT)?.value
  const requestType = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.REQUEST_TYPE)?.value
  const stateField = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.STATE_FIELD)?.value
  const stateFieldFtd = settings?.find((setting: IApiSettings) => setting?.key === EApiSettingsFields.STATE_FIELD_FTD_VALUE)?.value
  const startingPageNumberNotZero = startingPageNumber && parseInt(startingPageNumber) >= 0
  const stateCheckIsUsed = (settingsType === 'pullLeadStatusesSettings' && !pullLeadState) || settingsType === 'pullLeadStateSettings'
  const [settingsUrl, setSettingsUrl] = React.useState(url)

  useEffect(() => {
    if (url && statusField &&
    leadIdField && startingPageNumberNotZero) {
      setSettingsUrl(url)
    }
  }, [url, statusField, leadIdField,
    startingPageNumberNotZero, stateFieldFtd, stateCheckType])

  useEffect(() => {
    if (stateCheckType === StateCheckTypeEnum.LEAD_ID_AVAILABILITY) {
      settings && setSettings?.(settings?.map((setting: IApiSettings) => {
        if (setting?.key === EApiSettingsFields.STATE_FIELD ||
          setting?.key === EApiSettingsFields.STATE_FIELD_FTD_VALUE) {
          return {
            ...setting,
            value: ''
          }
        }
        return setting
      }))
    } else if (stateCheckType === StateCheckTypeEnum.FIELD_AVAILABILITY) {
      settings && setSettings?.(settings?.map((setting: IApiSettings) => {
        if (setting?.key === EApiSettingsFields.STATE_FIELD_FTD_VALUE) {
          return {
            ...setting,
            value: ''
          }
        }
        return setting
      }))
    }
  }, [stateCheckType])
  const onSubmit = async () => {
    const newSettings = settings && [...settings]
    const validUrl = regexValidUrl.test(settingsUrl)
    const error = checkRequestTimeout(requestTimeout)
    if (error) {
      return toast.error(error)
    }
    let requiredFields = [url, statusField, leadIdField, validUrl, startingPageNumberNotZero, stateField, stateFieldFtd, stateCheckType]
    if (settingsType === 'pullLeadStatusesSettings') {
      if (pullLeadState) {
        requiredFields = [url, statusField, leadIdField, validUrl, startingPageNumberNotZero]
      } else {
        if (stateCheckType === StateCheckTypeEnum.FIELD_AVAILABILITY) {
          requiredFields = [url, statusField, leadIdField, validUrl, startingPageNumberNotZero, stateField, stateCheckType]
        }
      }
    } else if (settingsType === 'pullLeadStateSettings') {
      requiredFields = [url, leadIdField, validUrl, startingPageNumberNotZero, stateField, stateFieldFtd, stateCheckType]
      if (stateCheckType === StateCheckTypeEnum.FIELD_AVAILABILITY) {
        requiredFields = [url, leadIdField, validUrl, startingPageNumberNotZero, stateField, stateCheckType]
      } else if (stateCheckType === StateCheckTypeEnum.LEAD_ID_AVAILABILITY) {
        requiredFields = [url, leadIdField, validUrl, startingPageNumberNotZero, stateCheckType]
      }
    }
    if (requiredFields.some(field => !field)) {
      !remoteError && setError({
        [EApiSettingsFields.URL]: !url || !validUrl ? 'Not a valid URL' : '',
        [EApiSettingsFields.STATUS_FIELD]: !statusField ? 'Status field is required' : '',
        [EApiSettingsFields.LEAD_ID_FIELD]: !leadIdField ? 'Lead Id field is required' : '',
        [EApiSettingsFields.STATE_FIELD]: !stateField ? 'State field is required' : '',
        [EApiSettingsFields.STATE_FIELD_FTD_VALUE]: !stateFieldFtd ? 'State FTD field is required' : '',
        [EApiSettingsFields.STARTING_PAGE_NUMBER]: !startingPageNumberNotZero ? 'Value must be not less zero' : '',
        [EApiSettingsFields.STATE_CHECK_TYPE]: !stateCheckType ? 'State check type can not be empty' : ''
      })
    } else {
      try {
        const dataAdvertiser = await editAdvertiser({
          id: advertiserId ?? '',
          [settingsType]: newSettings,
          pullLeadState: settingsType === 'pullLeadStatusesSettings' ? pullLeadState : undefined
        }).unwrap()
        if (dataAdvertiser?.[settingsType]) {
          const newSettings = dataAdvertiser?.[settingsType] as IApiSettings[]
          setSettings && setSettings(newSettings)
          if (settingsType === 'pullLeadStatusesSettings') {
            dispatch(setPullLeadStatusesSettings(newSettings))
          } else {
            dispatch(setPullLeadStateSettings(newSettings))
          }
          handleClose()
        }
        setRemoteError('')
        handleClose()
      } catch (e: any) {
        setRemoteError(e?.data?.message)
        return false
      }
    }
  }

  const updateSetting = (key: EApiSettingsFields, value: string | undefined) => {
    if (!settings) return

    setSettings?.(
      settings.map((setting: IApiSettings) =>
        setting?.key === key ? { ...setting, value } : setting
      )
    )
    setError?.({
      ...error,
      [key]: ''
    })
  }
  const handleInputChange = (key: EApiSettingsFields) =>
    (e: React.ChangeEvent<HTMLInputElement>) => updateSetting(key, e.target.value)

  const handleSelectChange = (key: EApiSettingsFields) =>
    (e: { value: string, title: string } | null) => updateSetting(key, e?.value)

  return (
    <>
      <FlexBox column gap={3}>
        <Box>
          <SettingsTextField title={'Url'} value={settingsUrl} onChange={handleInputChange(EApiSettingsFields.URL)} />
          <Typography variant='body2' color='error'>
            {error[EApiSettingsFields.URL]}
          </Typography>
        </Box>
        {settingsType === 'pullLeadStatusesSettings' &&
         <Box>
           <SettingsTextField title={'Status Field'} value={statusField} onChange={handleInputChange(EApiSettingsFields.STATUS_FIELD)}/>
           <Typography variant='body2' color='error'>
             {error[EApiSettingsFields.STATUS_FIELD]}
           </Typography>
         </Box>
        }
        <Box>
          <SettingsTextField title={'Lead Id Field'} value={leadIdField} onChange={handleInputChange(EApiSettingsFields.LEAD_ID_FIELD)} />
          <Typography variant='body2' color='error'>
            {error[EApiSettingsFields.LEAD_ID_FIELD]}
          </Typography>
        </Box>
        {stateCheckIsUsed &&
         <Box>
           <Typography
            color={'#393E51'}
            fontSize={18}
            fontWeight={400}
            marginBottom={'4.5px'}
           >State Check Type
           </Typography>
           <AccountsAutocomplete
            defaultValue={stateCheckType
              ? {
                  value: stateCheckType,
                  title: stateCheckTypeOptions.find(item => item.value === stateCheckType)?.title ?? 'Not found'
                }
              : { value: StateCheckTypeEnum.STATE_FIELD_VALUE, title: 'State field' }}
            options={settingsType === 'pullLeadStatusesSettings' ? stateCheckTypeOptions.filter(item => item.value !== StateCheckTypeEnum.LEAD_ID_AVAILABILITY) : stateCheckTypeOptions}
            onChange={handleSelectChange(EApiSettingsFields.STATE_CHECK_TYPE)}
            placeholder="State Check Type"
            sx={{ width: '100%' }}
           />
           <Typography variant='body2' color='error'>
             {error[EApiSettingsFields.STATE_CHECK_TYPE]}
           </Typography>
         </Box>
        }
        {stateCheckIsUsed &&
          ![StateCheckTypeEnum.LEAD_ID_AVAILABILITY].includes(stateCheckType) &&
        <Box>
          <SettingsTextField title={'State Field'} value={stateField} onChange={handleInputChange(EApiSettingsFields.STATE_FIELD)} />
          <Typography variant='body2' color='error'>
            {error[EApiSettingsFields.STATE_FIELD]}
          </Typography>
        </Box>
        }
        {stateCheckIsUsed &&
          ![StateCheckTypeEnum.FIELD_AVAILABILITY, StateCheckTypeEnum.LEAD_ID_AVAILABILITY].includes(stateCheckType) &&
         <Box>
           <SettingsTextField title={'State Field FTD Value'} value={stateFieldFtd}
                              onChange={handleInputChange(EApiSettingsFields.STATE_FIELD_FTD_VALUE)}/>
           <Typography variant='body2' color='error'>
             {error[EApiSettingsFields.STATE_FIELD_FTD_VALUE]}
           </Typography>
         </Box>
        }
        <Box>
          <SettingsTextField title={'Starting page number (leave blank for page 1)'} defaultValue={'0'}
            value={startingPageNumber} onChange={handleInputChange(EApiSettingsFields.STARTING_PAGE_NUMBER)} />
          <Typography variant='body2' color='error'>
            {error[EApiSettingsFields.STARTING_PAGE_NUMBER]}
          </Typography>
        </Box>

          <SettingsTextField title={'Request timeout in ms (leave blank to use default)'} defaultValue={''}
                             value={requestTimeout} onChange={handleInputChange(EApiSettingsFields.REQUEST_TIMEOUT)} />

        <Box>
          <SettingsTextField title={'Lead List Field'} value={leadListField} onChange={handleInputChange(EApiSettingsFields.LEAD_LIST_FIELD)} />
        </Box>
        <Box>
          <SettingsTextField title={'Page Field'} value={pageField} onChange={handleInputChange(EApiSettingsFields.PAGE_FIELD)} />
        </Box>
        <Box>
          <Typography
            color={'#393E51'}
            fontSize={18}
            fontWeight={400}
            marginBottom={'4.5px'}
          >Request type (POST is used by default)
          </Typography>
          <AccountsAutocomplete
            defaultValue={requestType
              ? { value: requestType, title: requestType.toUpperCase() }
              : { value: 'get', title: 'GET' }}
            options={[{ value: 'get', title: 'GET' }, { value: 'post', title: 'POST' }]}
            onChange={handleSelectChange(EApiSettingsFields.REQUEST_TYPE)}
            placeholder="Change Request Type"
            sx={{ width: '100%' }}
          />
        </Box>

        {remoteError && <Typography variant='body1' color='error'>
          {remoteError}
        </Typography>}
        <ActionButtons submitType='submit' submitText='Submit' onCancel={handleClose}
          onSubmit={onSubmit} sx={{ marginBottom: '15px', marginTop: '10px' }} />
      </FlexBox>
    </>
  )
}
