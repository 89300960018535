import React, { useEffect } from 'react'

import { Box } from '@mui/material'

import { ReactComponent as SettingsIcon } from '@/assets/icons/settings-icon.svg'
import PageContentLayout from '@/designSystem/PageContentLayout'
import LeftActionButtons from '../../../components/Pages/Settings/PresetsList/LeftActionButtons'
import RightActionButtons from '../../../components/Pages/Settings/PresetsList/RightActionButtons'
import PresetsPagination from '../../../components/Pages/Settings/PresetsList/PresetsPagination'
import PresetsFilterBar from '../../../components/Pages/Settings/PresetsList/Filters/PresetsFilterBar'
import {
  useGetPresetsQuery, useDeletePresetsMutation,
  setPresetsState,
  selectPresetsState,
  type IPreset
} from '@/store/presets'
import { useSelector, useDispatch } from 'react-redux'
import { PresetsItem } from '@/components/Pages/Settings/PresetsList/PresetsItem'
import { AddEditPresetModal } from '@/components/Pages/Settings/PresetsList/AddEditPresetsModal'
import { EmptyTablePresets } from '@/components/Pages/Settings/PresetsList/EmptyTablePresets'
import { selectPresetsQueryParams } from '@/store/queryParams'

export default function PresetsPage () {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectPresetsQueryParams)
  const presetsData = useGetPresetsQuery(queryParams)?.data?.presets
  const presetsState = useSelector(selectPresetsState)
  const [deletePresets] = useDeletePresetsMutation()

  const [editPresetId, setEditPresetId] = React.useState<string>('')

  useEffect(() => {
    dispatch(setPresetsState(presetsData))
  }, [presetsData])

  const deletePreset = async (id: string) => {
    await deletePresets(id)
  }

  const handleOpenEditModal = (presetId: string) => {
    setEditPresetId(presetId)
  }

  const handleCloseEditModal = () => {
    setEditPresetId('')
  }

  return (
    <>
      <PageContentLayout
        title={<Box
          display={'flex'}
          alignItems={'center'}
        >
          <SettingsIcon />
          <Box
            display={'flex'}
            alignItems={'center'}
            sx={{
              fontSize: '28px',
              fontWeight: 500,
              color: '#000931',
              marginLeft: '15px'
            }}
          >
            Settings / <Box sx={{ color: '#7A7F8F', marginLeft: '8px', marginTop: '1px' }}>Presets List</Box>
          </Box>
        </Box>}
        leftActionButtons={<LeftActionButtons />}
        rightActionButtons={<RightActionButtons />}
      >
        <PresetsFilterBar />
        <PresetsPagination />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '24px'
          }}
        >
          {presetsState?.map((preset: IPreset) => (
            <PresetsItem key={preset.id} preset={preset} deletePreset={deletePreset} editPreset={handleOpenEditModal} />
          ))}
        </Box>
        {!presetsData?.length && <EmptyTablePresets />}
      </PageContentLayout>
      <AddEditPresetModal open={!!editPresetId} handleClose={handleCloseEditModal}
        presetId={editPresetId} />
    </>
  )
}
