/* eslint-disable react/prop-types */
import React from 'react'
import { Box, Typography } from '@mui/material'

import { CountryField, DateTimeField, TextField } from '@/designSystem/Table/Fields'
import { useGetConversionsQuery } from '@/store/conversions'
import PullingTableWrapper from './PullingTableWrapper'
import { IndicatorConversions } from '@/components/Conversions/ElementConversionsMap'
import { ModalEventInfo } from '@/designSystem/ModalMessagesLogs'

interface ILead {
  conversionId: string | null
  onClickReload: () => void
  saleStatusData: any
}

const BoxWrapper = ({ children }: any) => {
  return (
    <Box
      sx={{ height: '67px', display: 'table-cell', verticalAlign: 'middle' }}
    >
      {children}
    </Box>
  )
}

const LitleSell = ({ children, width }: any) => {
  return (
    <BoxWrapper>
      <Typography
        display="flex"
        variant="body1"
        color={'#393E51'}
        width={width}
        textOverflow={'ellipsis'}
        overflow={'hidden'}
        whiteSpace={'nowrap'}
      >
        {children}
      </Typography>
    </BoxWrapper>
  )
}

const PullingLeadTable = ({ conversionId, onClickReload, saleStatusData }: ILead) => {
  const [modalLogs, setModalLogs] = React.useState<any>(null)
  const conversionsArr: any = conversionId &&
    useGetConversionsQuery({ limit: 1, include: { createdByUser: true }, filters: { id: [conversionId] } })?.data?.conversions

  const advertiserSaleStatusMain = saleStatusData?.map((item: any) => {
    return {
      id: <TextField text={item?.id} limitation={1} />,
      crmSaleStatus: <TextField text={item?.crmSaleStatus} limitation={100} />,
      externalStatus: <TextField text={item?.externalStatus} limitation={100} />,
      createdAt: <DateTimeField date={new Date(item?.createdAt)} />
    }
  })
  const conversionsData = (conversionsArr?.length > 0) && conversionsArr?.map((item: any, index: number) => {
    return {
      id:
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={'8px'}
        >
          <TextField text={item?.id} limitation={1} />
        </Box>,
      leadId: <TextField text={item?.leadId} limitation={1} />,
      advertiserId: <TextField text={item?.advertiserId} limitation={1} />,
      sentLeadId: <TextField text={item?.sentLeadId} limitation={1} />,
      revenue: <LitleSell width="max-content">${item?.revenue ?? ''}</LitleSell>,
      countryCode: <CountryField countryCode={item?.country ?? ''} />,
      goalType: <LitleSell width="max-content">{item?.goalType ?? ''}</LitleSell>,
      createdBy: <TextField text={item?.createdByUser?.email} limitation={80} />,
      createdAt: <DateTimeField date={new Date(item?.createdAt)} />,
      updatedAt: <DateTimeField date={new Date(item?.updatedAt)} />
    }
  })

  return (
    <>
      <PullingTableWrapper
        advertiserSaleStatusMain={advertiserSaleStatusMain}
        conversionsData={conversionsData}
        conversions={conversionsArr}
      />
      {modalLogs && <ModalEventInfo
        open={!!modalLogs}
        handleClose={() => setModalLogs(null)}
        logs={modalLogs}
      />}
    </>
  )
}

export default PullingLeadTable
