import React, { useEffect, useMemo } from 'react'
import PageContentLayout from '@/designSystem/PageContentLayout'
import Box from '@mui/material/Box'
import LeftActionButtons from '@/pages/Accounts/LeftActionButtons'
import BasicTable from '@/designSystem/BasikTable'
import { IconButton, useTheme } from '@mui/material'
import AccountsAutocomplete from './AccountsAutocomplete'
import { ReactComponent as EditIcon } from '@/assets/icons/edit.svg'
import { ReactComponent as SettingsIcon } from '@/assets/icons/settings.svg'
import { ReactComponent as DeleteIcon } from '@/assets/icons/delete.svg'
import { ReactComponent as LogoutIcon } from '@/assets/icons/logout.svg'
import { type Option } from '@/designSystem/Autocomplete/types'
import { AccessSettingsModal, AddEditAccountModal, DeleteAccountModal } from './AccountsModals'
import {
  type IUsers, selectUsers, setUsers,
  useSearchUsersQuery, useDeleteUserMutation,
  useCreateUserMutation, useUpdateUserMutation
} from '@/store/users'
import { useDispatch, useSelector } from 'react-redux'
import { useLogOutUserMutation } from '@/store/auth'
import { format } from 'date-fns'
import Button from '@/designSystem/Button'
import AccountsPagination from './AccountsPagination'

const accountTableHeaders = ['Email', 'Created At',
  'Role', 'Edit', 'Settings', 'Delete User', 'Log out']

export default function AccountsPage () {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { data } = useSearchUsersQuery({ page: 1, limit: 20, sortField: 'createdAt', sortOrder: 'desc' })
  const accounts: IUsers[] = useSelector(selectUsers)
  const [createUser] = useCreateUserMutation()
  const [updateUser] = useUpdateUserMutation()
  const [deleteUser] = useDeleteUserMutation()
  const [logOutUser] = useLogOutUserMutation()

  const [users, setAccUsers] = React.useState<IUsers[] | null>(accounts ?? null)
  const [deleteId, setDeleteId] = React.useState<string>('')
  const [editId, setEditId] = React.useState<string>('')
  const [settingsId, setSettingsId] = React.useState<string>('')
  const [addAccount, setAddAccount] = React.useState<boolean>(false)
  const [filter, setFilter] = React.useState<{ email: string }>({ email: '' })

  useEffect(() => {
    data && dispatch(setUsers(data.users))
  }, [data])

  useEffect(() => {
    setAccUsers(accounts)
  }, [accounts])

  const heandleOpenEdit = (id: string) => () => {
    setEditId(id)
  }

  const handleOpenAdd = () => {
    setAddAccount(true)
  }

  const heandleOpenSettings = (id: string) => () => {
    setSettingsId(id)
  }

  const heandleOpenDelete = (id: string) => () => {
    setDeleteId(id)
  }

  const heandleDeleteAccount = async () => {
    await deleteUser(deleteId)
    setDeleteId('')
  }

  const resetFilters = () => {
    setFilter({ email: '' })
    setAccUsers(accounts)
  }

  const heandleAddFilterName = (value: string) => {
    setFilter({ email: value })
  }

  const heandleAddEditAccount = async (submitObj: any, id: string) => {
    if (addAccount) {
      await createUser(submitObj)
    } else {
      await updateUser({ user: submitObj, userId: id })
    }
    setEditId('')
    setAddAccount(false)
  }

  const handleSubmitSettings = async (submitObj: any, id: string) => {
    await updateUser({ user: submitObj, userId: id })
    setSettingsId('')
  }

  const heandleLogOutAccount = (id: string) => () => {
    logOutUser(id)
  }

  const handleCloseModal = () => {
    setDeleteId('')
    setEditId('')
    setSettingsId('')
    setAddAccount(false)
  }

  const handleSortForName = (value: Option<string> | null) => {
    const filteredAccounts: IUsers[] | any = users?.filter((account) => account?.email === value?.value)
    if (value) {
      setAccUsers(filteredAccounts)
      setFilter({ ...filter, email: value?.value })
    } else { setAccUsers(accounts); setFilter({ email: '' }) }
  }

  const accountTables = useMemo(() =>
    users?.map((account) => {
      return {
        email: account?.email,
        createdAt: format(new Date(account?.createdAt), 'dd/MM/yyyy HH:mm:ss'),
        role: account?.role,
        edit: <IconButton onClick={heandleOpenEdit(account.id)} ><EditIcon width={24} /></IconButton>,
        settings: <IconButton onClick={heandleOpenSettings(account.id)} ><SettingsIcon width={24} /></IconButton>,
        deleteUser: <IconButton onClick={heandleOpenDelete(account.id)} ><DeleteIcon width={24} /></IconButton>,
        logout: <IconButton onClick={heandleLogOutAccount(account.id)}><LogoutIcon width={24} /></IconButton>
      }
    })
  , [users])

  const emailOptions = users?.map((account) => {
    return {
      title: account.email,
      value: account.email
    }
  })

  return (
    <>
      <PageContentLayout
        title="Account managment"
        leftActionButtons={<LeftActionButtons openAdd={handleOpenAdd} />}
        rightActionButtons={
          <Box
            display="flex"
            gap={theme.spacing(3)}
          >
            {!!(filter?.email) && <Button
              onClick={resetFilters}
              text='Reset filter'
              variant='contained'
              sx={{
                backgroundColor: theme.palette.red2.main,
                padding: `${theme.spacing(2)} ${theme.spacing(6)}`
              }}
            />}
            <AccountsAutocomplete
              inputValue={filter?.email ?? ''}
              options={emailOptions ?? []}
              onChange={handleSortForName}
              onInputChange={(e: any) => { heandleAddFilterName(e.target.value) }}
              placeholder="Email"
              sx={{ minWidth: theme.spacing(70) }}
            />
          </Box>}
      >
        <Box
          sx={{
            paddingTop: theme.spacing(11)
          }}
        >
          <AccountsPagination accounts={accounts ?? []} setUsers={setAccUsers} users={users ?? []}/>
          <BasicTable
            headers={accountTableHeaders}
            rows={accountTables}
          />
        </Box>
      </PageContentLayout>
      <DeleteAccountModal open={!!deleteId} onCancel={handleCloseModal} onSubmit={heandleDeleteAccount} />
      <AddEditAccountModal open={!!editId || addAccount} onCancel={handleCloseModal} addAccount={addAccount}
        accounts={users ?? []} editAccountId={editId} onSubmit={heandleAddEditAccount} />
      <AccessSettingsModal open={!!settingsId} settingsId={settingsId} onCancel={handleCloseModal} onSubmit={handleSubmitSettings}
        accounts={users ?? []} />
    </>
  )
}
