import React from 'react'
import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'

import Form from '@/components/Form'
import TextField from '@/components/Form/TextField'
import DateTimePicker from '@/components/Form/DateTimePicker'

import Popup from '@/designSystem/Popup'
import Button from '@/designSystem/Button'
import Accordion from '@/designSystem/Accordion'
import composeSx from '@/designSystem/TextField/v2'

import { type SxProps, Typography, styled } from '@mui/material'

import { useSelector, useDispatch } from 'react-redux'
import { type SubmitHandler, useForm, type UseFormReturn } from 'react-hook-form'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import { countryOptions } from '@/constants'
import Autocomplete from '@/components/Form/Autocomplete'
import { type Advertiser, useGetAdvertisersQuery } from '@/store/advertisers'
import { selectDealsQueryParams, setDealsQueryParams } from '@/store/queryParams'

interface FiltersPopupProps {
  handleClose: () => void
}

interface IDealsInputs {
  id: string
  userName: string
  advertiserId: { title: string, value: string } | undefined
  reason: string
  newRevenue: string
  countries?: { title: string, value: string } | undefined
  createdTo: { from: string, to: string } | undefined
}

export interface FilterPopupFormProps {
  form: UseFormReturn<IDealsInputs>
  onSubmit: SubmitHandler<IDealsInputs>
  handleClose: () => void
  resetFilters: () => void
}

export default function DealsFiltersPopup ({
  handleClose
}: FiltersPopupProps) {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectDealsQueryParams)
  const { data } = useGetAdvertisersQuery({ limit: 500, page: 1 })
  const theme = useTheme()
  const { palette, spacing } = theme

  const defaultValues: IDealsInputs = {
    id: queryParams?.filters?.userId ?? '',
    userName: queryParams?.filters?.userName ?? '',
    advertiserId: queryParams?.filters?.advertiserId
      ? { title: '', value: queryParams?.filters?.advertiserId }
      : undefined,
    reason: queryParams?.filters?.reason ?? '',
    newRevenue: queryParams?.filters?.newRevenue ?? '',
    countries: queryParams?.filters?.countries
      ? { title: '', value: queryParams?.filters?.countries[0] }
      : undefined,
    createdTo: (queryParams?.filters?.createdTo?.from && queryParams?.filters?.createdTo?.to)
      ? {
          from: queryParams?.filters?.createdTo?.from,
          to: queryParams?.filters?.createdTo?.to
        }
      : undefined
  }

  const form = useForm<IDealsInputs>({ defaultValues })

  const onSubmit: SubmitHandler<IDealsInputs> = (data) => {
    const {
      id,
      advertiserId,
      reason,
      newRevenue,
      countries,
      userName,
      createdTo
    } = data

    dispatch(setDealsQueryParams({
      ...queryParams,
      filters: {
        userId: id || undefined,
        advertiserId: advertiserId ? advertiserId?.value : undefined,
        reason: reason || undefined,
        countries: countries?.value ? [countries?.value] : undefined,
        newRevenue: newRevenue || undefined,
        userName: userName || undefined,
        createdTo: (createdTo?.from && createdTo?.to)
          ? {
              from: createdTo?.from,
              to: createdTo?.to
            }
          : undefined
      }
    }))
    handleClose()
  }

  const resetFilters = () => {
    dispatch(setDealsQueryParams({ ...queryParams, filters: undefined }))
    handleClose()
  }

  const optionsAdvertisers = data?.advertisers?.map((advertiser: Advertiser) => {
    return {
      title: advertiser?.name,
      value: advertiser?.id
    }
  })

  const accordionSx: SxProps = {
    backgroundColor: palette.inputText.main,
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '& .MuiAccordionSummary-root': {
      color: palette.card.main
    },
    '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
      fill: palette.card.main,
      marginRight: '1.3px'
    }
  }

  const datePickerSx: SxProps = {
    backgroundColor: palette.inputText.main,
    borderRadius: '16px'
  }

  const textFieldsSx = composeSx(theme)

  return (
    <Popup
      open
      title={<Title /> as any}
      handleClose={handleClose}
      titleSx={{ color: palette.card.main }}
      maxWidth='404px'
      rootBoxSx={{
        padding: spacing(4, 6),
        backgroundColor: palette.inputText.main
      }}
      closeButtonSx={{
        '& .MuiSvgIcon-root': { fill: palette.card.main }
      }}
    >
      <ScrollBarContainer>
        <Form form={form} onSubmit={onSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            gap={spacing(3)}
          >
            <Accordion defaultExpanded title="Main" sx={accordionSx}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                width="100%"
                gap={spacing(3)}
              >
                <TextField title="User UIUD" sx={textFieldsSx} placeholder='Enter UIUD' name='id' />
                <TextField title="User Name" sx={textFieldsSx}
                  placeholder='Enter User Name' name='userName' />
                <TextField title="Reason" sx={textFieldsSx} placeholder='Enter Reason' name='reason' />
                <TextField title="New Revenue" sx={textFieldsSx} placeholder='Enter New Revenue'
                  type='number' name='newRevenue' />
                <Typography variant="body1" color={palette.card.main}>Country</Typography>
                <Autocomplete
                  textFieldProps={{
                    root: {
                      title: 'Country',
                      placeholder: 'Choose Country',
                      sx: textFieldsSx
                    }
                  }}
                  name="countries"
                  options={countryOptions}
                />
              </Box>
              <Typography variant="body1" color={palette.card.main}>Advertiser</Typography>
              <Autocomplete
                textFieldProps={{
                  root: {
                    title: 'Advertiser',
                    placeholder: 'Choose Advertiser',
                    sx: textFieldsSx
                  }
                }}
                name="advertiserId"
                options={optionsAdvertisers ?? [{ title: 'Select advertiser', value: null }]}
              />
            </Accordion>
            <Accordion title="Dates" sx={accordionSx}>
              <Box
                display="flex"
                flexDirection="column"
                gap={spacing(3)}
                justifyContent="flex-start"
              >
                <Typography variant="body1" color={palette.card.main}>Created From</Typography>
                <CustomizedDatePickers sx={datePickerSx} label='Select date' name="createdTo.from" />
                <Typography variant="body1" color={palette.card.main}>Created To</Typography>
                <CustomizedDatePickers sx={datePickerSx} label='Select date' name="createdTo.to" />
              </Box>
            </Accordion>
            <Box
              display="flex"
              flexDirection="column"
              gap={theme.spacing(2)}
            >
              <Button
                text="Apply Filters"
                variant="contained"
                type="submit"
              />
              <Button
                text="Reset Filters"
                variant="text"
                textColor={theme.palette.red2.main}
                onClick={resetFilters}
              />
            </Box>
          </Box>
        </Form>
      </ScrollBarContainer>
    </Popup>
  )
}

function Title () {
  const theme = useTheme()
  return (
    <Typography
      variant='headline'
      color={theme.palette.whiteText.main}
    >
      Filters
    </Typography>
  )
}

const CustomizedDatePickers = styled(DateTimePicker)(`  
.MuiInputBase-root{
  background: #FFFFFF;
  border-radius: 16px;
  padding: 8px 16px;
}
input{
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #393E51;
}
label{
  color: #6B6B6B;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: -9px;
  &.Mui-focused{
    margin-top: -4px;
  }
}
`)
