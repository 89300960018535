import React from 'react'

import { Box, Typography } from '@mui/material'
import { CountryField } from '@/designSystem/Table/Fields'
import { ReactComponent as ArrBottomIcon } from '@/assets/icons/arr-bottom.svg'
import { ReactComponent as ArrUpIcon } from '@/assets/icons/arr-up.svg'

interface IRenderRow {
  row: any
  header: string
  handleExpand: (id: string) => void
  handleBackExpand: (id: string) => void
  handleMouseEnter: (id: string) => void
  handleMouseLeave: (id: string) => void
}

interface IExpandTextField {
  text: string
  isWithChildren: boolean
  isOpenExpand?: boolean
  isShow?: boolean
  handleBackExpand: (id: string) => void
  handleExpand: (id: string) => void
  id: string
  level: number
  margin?: number
  handleMouseEnter: (id: string) => void
  handleMouseLeave: (id: string) => void
  isHovered: boolean
}

interface ITableItem {
  title: string
  data: Record<string, string>
  level: number
  children?: ITableItem[]
  id: string
}

interface ICustomizedTextField {
  text: string
  id: string
  isOpenExpand?: boolean
  isWithChildren?: boolean
  handleMouseEnter: (id: string) => void
  handleMouseLeave: (id: string) => void
  isHovered: boolean
}

const ExpandTextField = ({
  text, isWithChildren, isOpenExpand,
  handleBackExpand, handleExpand,
  handleMouseEnter, handleMouseLeave, isHovered,
  id, level, isShow, margin = 0
}: IExpandTextField) => {
  const textIsCountry = (text?.length === 2) && (text === text?.toUpperCase())
  return (
    <Box sx={{
      backgroundColor: (id === 'total' || isHovered) ? '#F1F3FF' : 'white',
      cursor: isWithChildren ? 'pointer' : 'default',
      borderRadius: '8px'
    }} key={id}
      onMouseEnter={() => handleMouseEnter(id)}
      onMouseLeave={() => handleMouseLeave(id)}
      onClick={() => isOpenExpand ? handleBackExpand(id) : isWithChildren && handleExpand(id)}>
      {((level === 1) || isShow) &&
        <Box sx={{ marginLeft: `${margin}px` }}>
          <Box
            display={'flex'}
            alignItems={'center'}
            height={'35px'}
            width={'100%'}
          >
            {isWithChildren && <Box
              sx={{
                marginRight: '8px',
                width: '23px',
                height: '23px',
                cursor: 'pointer'
              }}
            >
              {!isOpenExpand && <ArrBottomIcon />}
              {isOpenExpand && <ArrUpIcon />}
            </Box>}
            {!textIsCountry && <Typography sx={{
              color: '#393E51',
              fontSize: id === 'total' ? '20px' : '16px',
              fontWeight: id === 'total' ? 700 : 500,
              textAlign: 'left',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%'
            }}>{!text?.toString() ? 'Empty' : text?.toString()}</Typography>}
            {textIsCountry && <CountryField countryCode={text?.toString()} colorText={'#393E51'} />}
          </Box>
        </Box>}
    </Box>
  )
}

const CustomizedTextField = ({ text, id, handleMouseEnter, handleMouseLeave, isHovered }: ICustomizedTextField) => {
  return (
    <Box
      key={id}
      display='flex'
      alignItems='center'
      justifyContent='center'
      height='35px'
      sx={{
        backgroundColor: (id === 'total' || isHovered) ? '#F1F3FF' : 'white',
        borderRadius: '8px'
      }}
      onMouseEnter={() => handleMouseEnter(id)}
      onMouseLeave={() => handleMouseLeave(id)}
    >
      <Typography sx={{
        color: '#393E51',
        fontSize: id === 'total' ? '20px' : '16px',
        fontWeight: id === 'total' ? 700 : 500
      }}>{!text?.toString() ? 'Empty' : text?.toString()}</Typography>
    </Box>
  )
}

export const ReportRenderRow = ({
  row, header,
  handleExpand, handleBackExpand,
  handleMouseEnter, handleMouseLeave
}: IRenderRow) => {
  const { level, title, data, children, id, isOpenExpand, isShow, isHovered } = row
  const isWithChildren = children && children?.length > 0

  let currentData
  if (header === 'Title') {
    switch (level) {
      case 1:
        currentData = <ExpandTextField text={title} isWithChildren={isWithChildren} id={id}
          handleExpand={handleExpand} handleBackExpand={handleBackExpand}
          isOpenExpand={isOpenExpand} level={1} isShow={isShow} margin={20}
          handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} isHovered={isHovered} />
        break
      case 2:
        currentData = <ExpandTextField text={title} isWithChildren={isWithChildren} id={id}
          handleExpand={handleExpand} handleBackExpand={handleBackExpand}
          isOpenExpand={isOpenExpand} level={2} isShow={isShow} margin={60}
          handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} isHovered={isHovered} />
        break
      case 3:
        currentData = <ExpandTextField text={title} isWithChildren={isWithChildren} id={id}
          handleExpand={handleExpand} handleBackExpand={handleBackExpand}
          isOpenExpand={isOpenExpand} level={3} isShow={isShow} margin={100}
          handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} isHovered={isHovered} />
        break
      case 4:
        currentData = <ExpandTextField text={title} isWithChildren={isWithChildren} id={id}
          handleExpand={handleExpand} handleBackExpand={handleBackExpand}
          isOpenExpand={isOpenExpand} level={4} isShow={isShow} margin={140}
          handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} isHovered={isHovered} />
        break
      case 5:
        currentData = <ExpandTextField text={title} isWithChildren={isWithChildren} id={id}
          handleExpand={handleExpand} handleBackExpand={handleBackExpand}
          isOpenExpand={isOpenExpand} level={5} isShow={isShow} margin={180}
          handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} isHovered={isHovered} />
        break
    }
  } else if (data && data[header] !== undefined && (level === 1 || (isOpenExpand === true))) {
    currentData = <CustomizedTextField text={data[header]} id={id}
      isOpenExpand={isOpenExpand} isWithChildren={isWithChildren}
      handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} isHovered={isHovered} />
  } else {
    currentData = <CustomizedTextField text={data[header]} id={id}
      isOpenExpand={isOpenExpand} isWithChildren={isWithChildren}
      handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} isHovered={isHovered} />
  }
  if (children && children?.length > 0 && (isOpenExpand === true)) {
    const childrenData = children?.map((child: ITableItem) =>
      ReportRenderRow({ row: child, header, handleExpand, handleBackExpand, handleMouseEnter, handleMouseLeave }))
    return [currentData, ...childrenData]
  } else {
    return currentData
  }
}
