import React from 'react'

import AutocompleteLayout from '@/designSystem/Autocomplete'
import Button from '@/designSystem/Button'
import { type Option } from '@/designSystem/Autocomplete/types'
import { Box } from '@mui/material'
import Close from '@mui/icons-material/Close'

interface IFormAutocomplete {
  name: string
  selectedOptions: any[]
  options: Array<Option<string>>
  deleteField: (value: string) => () => void
  sx?: any
  textFieldProps?: any
  onChange: (value: Option<string> | null) => void
  placeholder?: string
  onInputChange?: (value: string) => void
  rowSelectedOptions?: boolean
}

export default function FormAutocomplete (props: IFormAutocomplete) {
  const {
    selectedOptions, options, deleteField, sx,
    textFieldProps, onChange, onInputChange, rowSelectedOptions = false
  } = props

  return (
    <>
      <AutocompleteLayout
        options={options?.filter((option) => !selectedOptions.includes(option.value))}
        onChange={onChange}
        onInputChange={(e) => { onInputChange && onInputChange(e.target.value) }}
        sx={sx}
        textFieldProps={textFieldProps}
      />
      {!rowSelectedOptions && <Box
        display={selectedOptions?.length > 0 ? 'block' : 'none'}>
        <Button
          sx={{
            borderRadius: '16px',
            height: '40px',
            width: 'fit-content',
            padding: '8px 16px',
            marginTop: '5px'
          }}
          variant='contained'
          text={<Box
            display={'flex'}
            alignItems={'center'}
          >{selectedOptions[0]}
            <Close
              sx={{
                marginLeft: '5px',
                width: '20px',
                height: '20px'
              }} />
          </Box>}
          textVariant='subtitle1'
          onClick={deleteField(selectedOptions[0])} />
        {selectedOptions?.length > 1 && <Box>... </Box>}
        {selectedOptions?.length > 2 && <Button
          sx={{
            borderRadius: '16px',
            height: '40px',
            width: 'fit-content',
            padding: '8px 16px',
            marginTop: '5px'
          }}
          variant='contained'
          text={<Box
            display={'flex'}
            alignItems={'center'}
          >{selectedOptions[selectedOptions?.length - 1]}
            <Close
              sx={{
                marginLeft: '5px',
                width: '20px',
                height: '20px'
              }} />
          </Box>}
          textVariant='subtitle1'
          onClick={deleteField(selectedOptions[selectedOptions?.length - 1])} />}
      </Box>}
      {rowSelectedOptions && <Box
        display={'flex'}
        flexDirection={'row'}
        flexWrap={'wrap'}
        gap={'5px'}
      >
        {selectedOptions.map((option, index) => (
          <Button
            key={index}
            sx={{
              borderRadius: '16px',
              height: '40px',
              width: 'fit-content',
              padding: '8px 16px',
              marginTop: '5px'
            }}
            variant='contained'
            text={<Box
              display={'flex'}
              alignItems={'center'}
            >{option}
              <Close
                sx={{
                  marginLeft: '5px',
                  width: '20px',
                  height: '20px'
                }} />
            </Box>}
            textVariant='subtitle1'
            onClick={deleteField(option)} />
        ))}
      </Box>}
    </>
  )
}
