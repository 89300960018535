import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { LeadState } from '@/enums/leads.enum'

export interface SentLead {
  id: string
  leadId: string
  externalId: string
  externalStatus: string
  advertiserId: string
  status: string
  sentAt: Date
  sentLeadErrorType: string
  excluded: boolean
  requestData: string
  responseData: string
  createdAt: Date
  updatedAt: Date
}

export const leadsHeaders: Record<string, string> = {
  id: 'UUID',
  country: 'Country',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  password: 'Password',
  phone: 'Phone',
  state: 'State',
  crmSaleStatus: 'Crm Sale Status',
  isTest: 'Is Test',
  advertiserId: 'Advertiser Id',
  advertiserName: 'Advertiser Name',
  additionalInfo: 'Duplication Reason',
  affSub1: 'Aff Sub 1',
  affSub2: 'Aff Sub 2',
  affSub3: 'Aff Sub 3',
  affSub4: 'Aff Sub 4',
  affSub5: 'Aff Sub 5',
  affSub6: 'Aff Sub 6',
  affSub7: 'Aff Sub 7',
  affSub8: 'Aff Sub 8',
  affSub9: 'Aff Sub 9',
  affSub10: 'Aff Sub 10',
  affSub11: 'Aff Sub 11',
  affSub12: 'Aff Sub 12',
  affSub13: 'Aff Sub 13',
  affSub14: 'Aff Sub 14',
  affSub15: 'Aff Sub 15',
  ip: 'Ip',
  advertiserAutoLoginUrl: 'Auto Login Url',
  advertiserAutoLoginDomain: 'Auto Login Domain',
  crmAutoLoginUrl: 'Crm Auto Login Url',
  isAutoLoginSuccessful: 'Auto Login Successful',
  stateUpdatedAt: 'State Updated At',
  leadSource: 'Lead Source',
  externalId: 'External Id',
  apiTokenId: 'Api Tiken Id',
  comment: 'Comment',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
  externalStatus: 'External Status',
  statusCheckedAt: 'Status Checked At',
  conversionId: 'Conversion Id',
  withConversion: 'With Conversions'
}

export type TypeLeadsState = 'DUPLICATED' | 'PENDING' | 'PUSHED_LEAD' | 'FTD' | 'FTD_LATE' | 'HOLD'
export interface Lead {
  id: string
  country: string
  firstName: string
  lastName: string
  email: string
  phone: string
  advSub: string
  affiliate: number
  isAutologinSuccess: boolean
  state: LeadState
  isTest: boolean
  additionalInfo: object | null
  advertiserId: string
  advertiser: { name: string }
  affSub1: string
  affSub2: string
  affSub3: string
  affSub4: string
  affSub5: string
  affSub6: string
  affSub7: string
  affSub8: string
  affSub9: string
  affSub10: string
  affSub11: string
  affSub12: string
  affSub13: string
  affSub14: string
  affSub15: string
  manual: boolean
  ip: string
  createdAt: Date
  rejectedReason: string
  advertiserAutoLoginUrl: string
  advertiserAutoLoginDomain: string
  crmAutoLoginUrl: string
  isAutoLoginSuccessful: boolean
  stateUpdatedAt: Date
  leadSource: string
  externalId: string
  apiTokenId: string
  apiToken: string
  comment: string
  withLeads: boolean
  sentLeads: SentLead[]
  isCheck?: boolean
  conversionId?: string
  isExpand?: string
  isOpenExpand?: boolean
  saleStatusHistory?: string
  updatedAt?: Date
  leadId?: string
  externalStatus?: string
  status?: string
  sentAt?: Date
  statusCheckedAt?: Date
  excluded?: boolean
  withConversion?: boolean
  crmSaleStatus?: string
}

export interface ExtraSettings {
  key: string
  value: string
  label?: string
  group?: string
}

export interface LeadsFilters {
  id?: string[]
  country?: string[]
  state?: Array<Lead['state']>
  isTest?: boolean
  ip?: string
  isAutologinSuccess?: boolean
  leadSource?: string
  externalId?: string[]
  revenue?: {
    from?: string
    to?: string
  }
  withConversion?: boolean
  conversionId?: string[]
  advertiserId?: string[] // ids
  saleStatus?: string[]
  advertiserSaleStatus?: string[]
  crmSaleStatus?: string[]
  affSub1?: string
  affSub2?: string
  affSub3?: string
  affSub4?: string
  affSub5?: string
  affSub6?: string
  affSub7?: string
  affSub8?: string
  affSub9?: string
  affSub10?: string
  affSub11?: string
  affSub12?: string
  affSub13?: string
  affSub14?: string
  affSub15?: string

  createdAt?: {
    from?: string
    to?: string
  }
  stateUpdatedAt?: {
    from?: string
    to?: string
  }

  firstName?: string
  lastName?: string
  email?: string
  password?: string
  phone?: string
}

export interface LeadsState {
  totalCount?: number
  leads: Lead[]
}

const initialState: LeadsState = {
  totalCount: 0,
  leads: []
}

export const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setLeadsTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setLeads: (state, action: PayloadAction<Lead[]>) => {
      state.leads = action.payload
    }
  }
})

export const { setLeadsTotalCount, setLeads } = leadsSlice.actions

export const selectLeadsTotalCount = (state: RootState) => state.leads.totalCount
export const selectLeads = (state: RootState) => state.leads.leads

export default leadsSlice.reducer

interface GetLeadsArgs {
  limit?: number
  page?: number
  filters?: LeadsFilters
  include?: { logs: boolean }
}

interface GetLeadsReturn {
  leads: Lead[]
  totalCount: number
}

const transformValues = (name: string, value: string | string[] | boolean | { from?: string, to?: string }) => {
  if (typeof value === 'string' || typeof value === 'boolean') return value
  if (Array.isArray(value)) {
    return {
      in: value
    }
  }
  if (name === 'revenue') {
    return {
      ...value.from ? { gte: value.from } : {},
      ...value.to ? { lte: value.to } : {}
    }
  }
  return {
    ...value.from ? { gt: value.from } : {},
    ...value.to ? { lt: value.to } : {}
  }
}

const transformFilters = (filters: LeadsFilters | undefined) => {
  if (!filters) return {}
  const newFilters = Object.entries(filters).reduce((acc: LeadsFilters, [key, value]) => {
    return {
      ...acc,
      [key]: transformValues(key, value)
    }
  }, {})
  return newFilters
}

export const leadsApi = createApi({
  reducerPath: 'leadsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/leads`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['Leads'],
  endpoints: (builder) => ({
    getLeads: builder.query<GetLeadsReturn, GetLeadsArgs>({
      query: ({ limit = 10, page = 1, filters, include }) => {
        if (!filters) {
          return {
            url: '/search',
            method: 'POST',
            body: {
              limit,
              page,
              include: {
                sentLeads: true,
                advertiser: true,
                ...include?.logs ? { logs: include?.logs } : {}
              },
              sortField: 'createdAt',
              sortOrder: 'desc',
              filter: {
                state: {
                  in: [LeadState.DUPLICATED, LeadState.PENDING, LeadState.PUSHED_LEAD, LeadState.FTD, LeadState.FTD_LATE]
                }
              }
            }
          }
        }
        const { createdAt, ...restFilters } = filters
        return {
          url: '/search',
          method: 'POST',
          body: {
            limit,
            page,
            include: {
              sentLeads: true,
              advertiser: true,
              ...include?.logs ? { logs: include?.logs } : {}
            },
            sortField: 'createdAt',
            sortOrder: 'desc',
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            ...(createdAt?.from || createdAt?.to)
              ? {
                  createdAt: {
                    ...createdAt.from ? { from: createdAt.from } : {},
                    ...createdAt.to ? { to: createdAt.to } : {}
                  }
                }
              : {},
            filter: {
              state: {
                in: [LeadState.DUPLICATED, LeadState.PENDING, LeadState.PUSHED_LEAD, LeadState.FTD, LeadState.FTD_LATE]
              },
              ...transformFilters(restFilters)
            }
          }
        }
      },
      providesTags: [{ type: 'Leads', id: 'LeadsLIST' }]
    }),
    getLead: builder.query<Lead, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'GET'
      }),
      providesTags: [{ type: 'Leads', id: 'LeadsLIST' }]
    }),
    getAllLeads: builder.query<GetLeadsReturn, GetLeadsArgs>({
      query: () => ({
        url: '/getAllLeads',
        method: 'GET'
      }),
      providesTags: [{ type: 'Leads', id: 'LeadsLIST' }]
    }),
    updateLeadState: builder.mutation<Lead, { id: string, conversionCreatedAt?: Date }>({
      query: ({ id, conversionCreatedAt }) => ({
        url: `/changeState/${id}`,
        method: 'PATCH',
        body: { conversionCreatedAt, state: LeadState.FTD }
      }),
      invalidatesTags: [{ type: 'Leads', id: 'LeadsLIST' }]
    }),
    patchLead: builder.mutation<Lead, { id: string, data: Partial<Lead> }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: [{ type: 'Leads', id: 'LeadsLIST' }]
    })
  })
})

export const {
  useGetLeadsQuery, useGetAllLeadsQuery,
  useGetLeadQuery, useUpdateLeadStateMutation,
  usePatchLeadMutation
} = leadsApi
