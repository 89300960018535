import React from 'react'

import { useTheme } from '@mui/material'
import Button from '@/designSystem/Button'

export default function ReloadButton ({ onClick }: { onClick: () => void }) {
  const { spacing } = useTheme()
  return (
    <Button
      sx={{
        borderRadius: '16px',
        height: '30px',
        padding: `${spacing(1.5)} ${spacing(6)}`,
        width: 'auto'
      }}
      variant='contained'
      onClick={onClick}
      text="Reload"
      textVariant='subtitle1'
    />
  )
}
