import React from 'react'
import { type HoldsFilters } from '@/store/hold'
import { type FiltersAdvertiser } from '@/store/advertisers'
import { booleanOptions } from '../constants'
import AutocompleteFilterItem from './AutocompleteFilterItem'
import { countryOptions } from '@/constants'
import AdvertiserFilterItem from './AdvertiserFilterItem'
import { type LeadsFilters } from '@/store/leads'
import { type ConversionsFilters } from '@/store/conversions'
import { type FiltersRotationControl } from '@/store/rotationControl'

export interface FilterItemProps {
  onChange: (field: any, value: string | string[] | boolean | { from?: string, to?: string }, isMultiple: boolean) => void
  onDelete: (field: any, value: string | string[], isMultiple: boolean) => void
  isMultiple: boolean
  title: string
  name: keyof HoldsFilters | keyof LeadsFilters | keyof ConversionsFilters | keyof FiltersAdvertiser | keyof FiltersRotationControl
  selectedValues: any
}

const FilterItem = (props: FilterItemProps) => {
  const { name } = props

  switch (name as any) {
    case 'country': return <AutocompleteFilterItem {...props} options={countryOptions} />
    case 'advertiserId': return <AdvertiserFilterItem {...props} />
    case 'status': return <AutocompleteFilterItem {...props} options={booleanOptions} />
  }
  return null
}

export default FilterItem
