import React from 'react'
import IconButton from '@/designSystem/IconButton'
import { ReactComponent as ExpandIcon } from '@/assets/icons/expand.svg'
import { ReactComponent as CollapseIcon } from '@/assets/icons/collapse.svg'
import { TextField, useTheme } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'

interface Props {
  onHide: () => void
  onExpand: () => void
  search: string
  onChange: (value: string) => void
}

const ExtraActions = ({ onHide, onExpand, search, onChange }: Props) => {
  const { spacing } = useTheme()

  return <FlexBox sx={{ gap: spacing(4), marginBottom: spacing(10), alignItems: 'center' }}>
    <TextField sx={{ input: { paddingTop: '10px !important', paddingBottom: '10px !important' } }} size='small' label="Start your tree search" value={search} onChange={(e) => onChange(e.target.value || '')}/>
    <IconButton style={{ height: 40, width: 40 }} onClick={onExpand}>
      <ExpandIcon />
    </IconButton>
    <IconButton style={{ height: 40, width: 40 }} onClick={onHide}>
      <CollapseIcon />
    </IconButton>
  </FlexBox>
}

export default ExtraActions
