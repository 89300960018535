import React, { useMemo } from 'react'
import Chip from '@/components/Advertisers/Filters/Chip'
import { useDispatch, useSelector } from 'react-redux'
import { Box, useTheme } from '@mui/material'
import cloneDeep from 'lodash.clonedeep'
import unset from 'lodash.unset'
import { type ITermFilters } from '@/store/terms'
import { format } from 'date-fns'
import { selectTermsQueryParams, setTermsQueryParams } from '@/store/queryParams'

const Bar = ({ children }: { children: React.ReactNode }) => {
  const { spacing, palette } = useTheme()
  return (
    <Box
      display={'flex'}
      flexWrap={'wrap'}
      rowGap={spacing(3)}
      gap={spacing(3)}
      justifyContent="flex-start"
      padding={spacing(3, 4)}
      maxWidth="1750px"
      borderRadius="12px"
      sx={{
        backgroundColor: palette.card.main
      }}
    >
      {children}
    </Box>
  )
}

const areFiltersExist = (filters?: ITermFilters) => {
  if (!filters) {
    return false
  }
  return (
    !!filters?.label ||
    !!filters?.createdTo?.from ||
    !!filters?.createdTo?.to
  )
}

export default function TermsFilterBar () {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectTermsQueryParams)

  const isParamExist = useMemo(() => areFiltersExist(queryParams?.filters), [queryParams?.filters])

  if (!isParamExist) {
    return null
  }

  const onDeleteParam = (label: string) => () => {
    const params = cloneDeep(queryParams)
    unset(params, `filters.${label}`)
    dispatch(setTermsQueryParams(params))
  }

  const filters = queryParams.filters

  return (
    <Bar>
      {filters?.label && (
        <Chip
          label={`Label: "${filters.label}"`}
          onDelete={onDeleteParam('label')}
        />
      )}
      {filters?.createdTo?.from && (
        <Chip
          label={`Show from: ${format(new Date(filters.createdTo.from), 'P HH:mm')}`}
          onDelete={onDeleteParam('createdTo.from')}
        />
      )}
      {filters?.createdTo?.to && (
        <Chip
          label={`Show before: ${format(new Date(filters.createdTo.to), 'P HH:mm')}`}
          onDelete={onDeleteParam('createdTo.to')}
        />
      )}
    </Bar>
  )
}
