import React from 'react'

import { Box, Typography } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { CountryField, TextField } from '@/designSystem/Table/Fields'

export const allHeaders = {
  id: 'UUID',
  advertiserID: 'Advertiser UUID',
  revenue: 'Revenue',
  goalType: 'Goal Type',
  country: 'Country'
}

const BoxWrapper = ({ children }: any) => {
  return (
    <Box
      sx={{ height: '67px', display: 'table-cell', verticalAlign: 'middle' }}
    >
      {children}
    </Box>
  )
}

function FieldMap ({ children }: any) {
  const { palette } = useTheme()
  return (
    <Typography
      sx={{ display: 'flex' }}
      variant='table1'
      width="max-content"
      color={palette.text2.main}
    >
      {children}
    </Typography>
  )
}

const elementDealsMap = (header: string, row: any) => {
  const {
    id,
    advertiserId,
    goalType,
    revenue,
    country
  } = row

  switch (header) {
    case allHeaders.id:
      return <BoxWrapper>
        <TextField text={id} limitation={1} />
      </BoxWrapper>
    case allHeaders.advertiserID:
      return <BoxWrapper>
        <TextField text={advertiserId || 'No Advertiser'} limitation={1} />
      </BoxWrapper>
    case allHeaders.goalType:
      return <FieldMap>
        <Box>
          <Typography sx={{
            color: '#6B6B6B',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            textTransform: 'capitalize'
          }}>
            {goalType}
          </Typography>
        </Box>
      </FieldMap>
    case allHeaders.revenue:
      return <FieldMap>
        <Box>
          <Typography sx={{
            color: '#6B6B6B',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            textTransform: 'capitalize'
          }}>
            {revenue}$
          </Typography>
        </Box>
      </FieldMap>
    case allHeaders.country:
      return <FieldMap>
        <CountryField countryCode={country ?? ''} />
      </FieldMap>
  }
}

export default elementDealsMap
