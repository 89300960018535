import React from 'react'

import { FormProvider, type UseFormReturn, type FieldValues, type SubmitHandler } from 'react-hook-form'

interface FormProps<T extends FieldValues> {
  form: UseFormReturn<T>
  onSubmit: SubmitHandler<T>
  children: React.ReactNode
}

const Form = <T extends FieldValues>({
  form,
  children,
  onSubmit
}: FormProps<T>) => {
  return (
    <FormProvider {...form}>
      <form onSubmit={(event) => {
        form.handleSubmit(onSubmit)(event).catch(console.error)
      }}>
        {children}
      </form>
    </FormProvider>
  )
}

export default Form
