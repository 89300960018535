import { Button, Box, Typography } from '@mui/material'
import type { Option } from '@/designSystem/Autocomplete/types'
import React, { useEffect, useState } from 'react'
import { ReactComponent as Quetion } from '@/assets/icons/witgetQuetion.svg'
import { ReactComponent as Logo } from '@/assets/icons/witgetLogo.svg'
import Tooltip from '@mui/material/Tooltip'
import { getCurrentDay, getCurrentMonth, getCurrentWeek, getCurrentYear, getLastMonth, getLastWeek, getLastYear, getYesterday } from '../utils'

import Autocomplete from '@/designSystem/Pages/Advertisers/Autocomplete'
import { type IGetWitgetAnswer, type IGetWitgetRequest, selectCurrentRange, useSearchLeadChartQuery, useSearchWidgetLabelQuery } from '@/store/dashboard'
import { useSelector } from 'react-redux'
import { type ITerm } from '@/store/terms'
import { iconData } from '@/designSystem/SelectIconTagField'
// import { da } from 'date-fns/locale'
interface IWitgetItem {
  key: string | any
  term: ITerm
  description?: string
  label: string
  icon: string

}
function hasDecimalPart (number: any) {
  return number % 1 !== 0
}
export default function WitgetIte ({ key, term, description, label, icon }: IWitgetItem) {
  console.log('🚀 ~ WitgetIte ~ term:', term)
  const [currentMonth, setCurrentMonth] = useState(0)
  const [currentDay, setCurrentDay] = useState(0)
  const [week, setWeek] = useState(0)
  const [today, setToday] = useState(0)
  const [currentFormat, setFormat] = useState('')
  const currentRange = useSelector(selectCurrentRange)
  const getWitgetToday: any = useSearchWidgetLabelQuery({ label, createdAt: getCurrentDay() })
  const getWitgetCurrentMonth: any = useSearchWidgetLabelQuery({ label, createdAt: currentRange })
  const getWitgetWeek: any = useSearchWidgetLabelQuery({ label, createdAt: getCurrentWeek() })
  const getWitgetCurrentRange: any = useSearchWidgetLabelQuery({ label, createdAt: currentRange })
  console.log('🚀 ~ WitgetIte ~ getWitgetCurrentMonthgetWitgetCurrentMonthgetWitgetCurrentMonth:', getWitgetCurrentRange)
  useEffect(() => {
    setCurrentMonth(getWitgetCurrentMonth?.data?.value && getWitgetCurrentMonth?.data?.value)
    setCurrentDay(getWitgetCurrentRange?.data?.value && getWitgetCurrentRange?.data?.value)
    setWeek(getWitgetWeek?.data?.value && getWitgetWeek?.data?.value)
    setToday(getWitgetToday?.data?.value && getWitgetToday?.data?.value)
  }, [getWitgetToday?.data, getWitgetCurrentMonth?.data, getWitgetWeek?.data, getWitgetCurrentRange?.data])
  useEffect(() => {
    term && term.format === 'NUMBER' && setFormat('')
    term && term.format === 'MONEY' && setFormat('$')
    term && term.format === 'PERCENTAGE' && setFormat('%')
  }, [term])
  const matchedIcon = icon ? iconData.find((item) => item.title === icon)?.icon : ''
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={'column'}
      flexWrap={'nowrap'}
      justifyContent={'start'}
      minWidth={'300px'}
      // width={'303px'}
      height={'295px'}
      padding={'20px'}
      borderRadius={'16px'}
      gap={'24px'}
      // position={'relative'}
      bgcolor={'#FFFFFF'}
    >

      <Box
        display="flex"
        flexDirection={'row'}
        alignItems='flex-end'
        justifyContent={'start'}
        gap={'15px'}
        width={'100%'}
      >
        {matchedIcon &&
          <Box border={'1px solid #4141D5'} display={'inline-block'} height={'47px'} borderRadius={'10px'}>
            {matchedIcon}
          </Box>
        }
        <Box
          display="flex"
          flexDirection={'row'}
          alignItems="center"
          justifyContent={'start'}
          fontSize='24px'
          lineHeight='24px'
          fontWeight={'700'}
          color='#000931'
          textTransform={'capitalize'}
          position={'relative'}
          paddingBottom={'5px'}
        >
          {label}
          <Box position={'absolute'} top={'-15px'} right={'-20px'} >
            <Tooltip title={description ?? 'no info'} arrow>
              <Quetion width={'20px'} height={'20px'} />
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={'row'}
        alignItems="start"
        justifyContent={'space-between'}
        width={'100%'}
      >

        <Box
          display="flex"
          flexDirection={'column'}
          // alignItems="center"
          justifyContent={'space-between'}
          width={'100%'}
          gap='12px'
        >

          <Box
            display="flex"
            flexDirection={'row'}
            alignItems="center"
            justifyContent={'start'}
            width={'100%'}
            fontSize='26px'
            fontWeight={700}
            lineHeight='33px'
            color='#000931'
          >
            {hasDecimalPart(currentDay) ? `${currentDay?.toFixed(2)}${currentFormat}` : `${currentDay ?? 0}${currentFormat}`}
          </Box>
        </Box>

      </Box>
      <Box
        display="flex"
        flexDirection={'column'}
        alignItems="center"
        justifyContent={'start'}
        width={'100%'}
        gap={'8px'}
      >
        <Box
          display="flex"
          flexDirection={'row'}
          alignItems="center"
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Typography>Today</Typography>
          <Typography>{hasDecimalPart(today) ? `${today?.toFixed(2)}${currentFormat}` : `${today ?? 0}${currentFormat}`}</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={'row'}
          alignItems="center"
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Typography>Week</Typography>
          <Typography>{hasDecimalPart(week) ? `${week?.toFixed(2)}${currentFormat}` : `${week ?? 0}${currentFormat}`}</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={'row'}
          alignItems="center"
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Typography>Month</Typography>
          <Typography>{hasDecimalPart(currentMonth) ? `${currentMonth?.toFixed(2)}${currentFormat}` : (`${currentMonth ?? 0}${currentFormat}` ?? 0)}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
