import type { Tab } from '@/designSystem/NavigationPanel/types'

export const openTab = (tabs: Tab[], text: string) => {
  return tabs.map((tab) => {
    if (tab.text === text) {
      return {
        ...tab,
        isOpen: !tab.isOpen
      }
    }
    return tab
  })
}
