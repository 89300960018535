import React from 'react'

import { BooleanField, DateTimeField, TextField } from '@/designSystem/Table/Fields'
import { Box, Typography } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { ReactComponent as ThreeDots } from '@/assets/icons/three-dots.svg'
import { ReactComponent as EditIcon } from '@/assets/icons/edit.svg'
import { ReactComponent as TrashIcon } from '@/assets/icons/trash-red.svg'
import { apiTokensHeaders, type IApiTokens } from '@/store/apiTokens'

export function FieldMap ({ children }: any) {
  const { palette } = useTheme()
  return (
    <Typography
      sx={{ display: 'flex' }}
      variant='table1'
      width="max-content"
      color={palette.text2.main}
    >
      {children}
    </Typography>
  )
}

const IndicatorApiTokens = ({ active }: { active: boolean }) => {
  const { palette } = useTheme()
  return (
    <Box
      position='relative'
      display='flex'
      alignItems='center'
      width={0}
    >
      <Box
        sx={{
          borderRadius: '0px 6px 6px 0px',
          width: '6px',
          height: '36px',
          backgroundColor: active ? '#6B6B6B' : palette.grey[300],
          position: 'absolute'
        }}
      />
    </Box>
  )
}

const elementMap = (header: string, row: any,
  setModal: (description: string) => void,
  handleOpenEdit: (row: IApiTokens) => void,
  handleDelete: (id: string) => void) => {
  const {
    name,
    id,
    createdAt,
    token,
    active,
    leadProfile,
    ips
  } = row

  switch (header) {
    case '::indicatorApiTokens':
      return <IndicatorApiTokens active />
    case apiTokensHeaders.id:
      return <TextField text={id} limitation={1} />
    case apiTokensHeaders.name:
      return <FieldMap>
        <Box sx={{
          color: '#4141D5',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          textTransform: 'capitalize'
        }}>
          {name}
        </Box>
      </FieldMap>
    case apiTokensHeaders.token:
      return <FieldMap>
        <Box sx={{ cursor: 'pointer' }} onClick={() => setModal(token)} >
          <ThreeDots />
        </Box>
      </FieldMap>
    case apiTokensHeaders.active:
      return <BooleanField isSomething={active || false} />
    case apiTokensHeaders.ips:
      return <FieldMap>
        {ips?.length > 0 ? <TextField text={ips} limitation={80} /> : 'No IPs'}
      </FieldMap>
    case apiTokensHeaders.createdAt:
      return <DateTimeField date={new Date(createdAt)} />
    case apiTokensHeaders.leadProfile:
      return <FieldMap>
        {Object.keys(leadProfile)?.length ? <TextField text={JSON.stringify(leadProfile)} limitation={80} /> : '{ }'}
      </FieldMap>
    case apiTokensHeaders.settings:
      return <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px'
        }}>
        <EditIcon
          style={{
            cursor: 'pointer'
          }}
          onClick={() => handleOpenEdit(row)} />
        <TrashIcon
          style={{
            cursor: 'pointer'
          }}
          onClick={() => handleDelete(id)} />
      </Box>
  }
}

export default elementMap
