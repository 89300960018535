import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@/designSystem/Button'
import useTheme from '@mui/material/styles/useTheme'
import { IconButton } from '@mui/material'
import AddIcon from '@/designSystem/Icons/Add'
import { AddAutoInjectionModal } from './AddAutoInjectionModal'
import { useNavigate } from 'react-router-dom'
import QuestionMarkIcon from '@/designSystem/PageContentLayout/Icons/QuestionMark'
import AutoInjectionInfoModal from './AutoInjectionInfoModal'

export default function LeftActionButtons ({ isMenu }:
{ isMenu: boolean }): JSX.Element {
  const [openAddPopup, setOpenAddPopup] = useState(false)
  const [openSupportInjectModal, setOpenSupportInjectModal] = useState(false)
  const theme = useTheme()
  const navigate = useNavigate()
  const onClickOpenAddButton = () => {
    setOpenAddPopup(true)
  }
  const onClickReload = () => navigate(0)
  const handleCloseModal = () => {
    setOpenAddPopup(false)
  }
  const handleOpenInfoModal = () => {
    setOpenSupportInjectModal(true)
  }

  return (
    <>
      <Box
        display="flex"
        gap={theme.spacing(3)}
        alignItems="center"
      >
        <IconButton onClick={handleOpenInfoModal}>
          <QuestionMarkIcon />
        </IconButton>
        <Button
          sx={{
            borderRadius: '16px',
            height: '30px',
            padding: `${theme.spacing(1.5)} ${theme.spacing(6)}`
          }}
          variant='contained'
          onClick={onClickReload}
          text="Reload"
          textVariant='subtitle1'
        />
        {!isMenu && <IconButton onClick={onClickOpenAddButton}>
          <AddIcon />
        </IconButton>}
      </Box>
      {openAddPopup && (
        <AddAutoInjectionModal handleClose={handleCloseModal} open={openAddPopup} />
      )}
      {openSupportInjectModal && (
        <AutoInjectionInfoModal handleClose={() => setOpenSupportInjectModal(false)} />
      )}
    </>
  )
}
