import React, { type ReactNode, useState } from 'react'
import { Box, Menu, MenuItem, Typography, useTheme } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'
import IconButton from '@/designSystem/IconButton'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import Close from '@mui/icons-material/Close'
import Popup from '@/designSystem/Popup'
import LogsWrapper from './LogsWrapper'
import { type SxProps, styled } from '@mui/material'
import Paper from '@mui/material/Paper'
import Edit from '@mui/icons-material/Edit'
import Grid from '@mui/material/Grid'
import CountryIcon from '@/designSystem/Pages/TrafficDistribution/RotationControl/icons/CountryIcon'
import GoalTypePassingRules from '@/designSystem/Pages/TrafficDistribution/RotationControl/icons/GoalTypePassingRules'
import PQLIcon from '@/designSystem/Pages/TrafficDistribution/RotationControl/icons/PQLIcon'
import UpdatePostBackPopup from './Popups/PostBackPopup/UpdatePostBackPopup'
import { type PassingRules, type SearchPostBacksAnswer, useDeletePostbackMutation, selectPostBackData, useUpdatePriorityPostbacksMutation } from '@/store/postBacks'
import { ReactComponent as ThreeDots } from '@/assets/icons/three-dots.svg'
import { useSelector } from 'react-redux'
import { findPriorityItemPostbacks } from './PostBacksList'
export enum ERenderer {
  // GOALTYPE = 'goalType',
  COUNTRY = 'country',
  FREE_PQL = 'free_pql',
}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  border: 'none',
  boxShadow: 'none',
  padding: '16px 0',
  textAlign: 'start',
  color: theme.palette.text.secondary
}))
interface IBasicMenuProps {
  id: string
  cloneElement?: (id: string) => void
  getLogs?: () => boolean
}

interface IDeleteWithConfirmationProps {
  // onDelete: (e: React.MouseEvent<HTMLLIElement | SVGSVGElement, MouseEvent>) => void
  id: string
}
export const BasicMenu = (props: IBasicMenuProps) => {
  const [isLogsVisible, setLogsVisible] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const onLogsChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setLogsVisible(true)
  }

  return (
    <>
      <div>
        <IconButton id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={onLogsChange}
          // sx={{
          //   width: '24px!important',
          //   height: '24px!important'
          // }}
        >
          <ThreeDots/>
        </IconButton>
      </div>
      {isLogsVisible && <Popup
        open
        handleClose={() => setLogsVisible(false)}
        title="Logs"
      >
        <LogsWrapper id={props.id}/>
      </Popup>}
    </>
  )
}
export const DeleteWithConfirmation = (props: IDeleteWithConfirmationProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [deletePostback] = useDeletePostbackMutation()
  const poskDataState: PassingRules[] = useSelector(selectPostBackData)
  const [updatePriorityPostbacks] = useUpdatePriorityPostbacksMutation()

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onDelete = async (e: React.MouseEvent<HTMLLIElement | SVGSVGElement, MouseEvent>) => {
    e.stopPropagation()
    //   items: findPriorityItemPostbacks(poskDataState?.filter(item => item.id === props.id))
    // console.log('sddsf', findPriorityItemPostbacks(poskDataState?.filter(item => item.id !== props.id)))
    await deletePostback(props.id)
    await updatePriorityPostbacks({
      items: findPriorityItemPostbacks(poskDataState?.filter(item => item.id !== props.id))
    })
    handleClose()
  }

  return (
    <>
        <div>
        <IconButton id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ background: 'transparent', '&:hover': { background: 'none' } }}
        >
          <Close sx={{ fill: '#ff0000' }} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem onClick={onDelete}>Confirm deletion</MenuItem>
          <MenuItem onClick={handleClose}>Cancel</MenuItem>
        </Menu>
      </div>
    </>
  )
}
export default function PostBacksItem ({ postback }: { postback: PassingRules }) {
  const { spacing } = useTheme()
  const [isUpdatePopup, setIsUpdatePopup] = useState(false)

  const onEdit = async (e: React.MouseEvent<SVGElement>, postback: PassingRules) => {
    e.stopPropagation()
    setIsUpdatePopup(true)
    // actions.selectElement(element)
    // if (actions.selectMode) {
    //   actions.selectMode('edit')
    // }
  }

  return (
          <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
              key={postback.id}
              wrap='nowrap'
          >
              <Grid item xs={2} sx={{ padding: '0px!important', marginTop: '20px!important', height: '64px!important' }}>
                <Item>
                  <FlexBox sx={{ paddingLeft: '40px', gap: '8px', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        justifyContent: 'center',
                        width: '32px',
                        height: '32px',
                        borderRadius: '8px',
                        padding: '8px',
                        backgroundColor: '#F1F3FF'
                      }}
                    >
                      {postback.priority}
                    </Box>
                    {postback.pql && postback.pql.length > 10 && <PQLIcon/>}
                    {postback.country && postback.country.length > 0 && <CountryIcon/>}
                    {postback.name}
                  </FlexBox>
                </Item>
              </Grid>
              <Grid item xs={4} sx={{ padding: '0px!important', marginTop: '20px!important', height: '64px!important' }}>
              <Item sx={{ paddingRight: '16px' }}>
                <FlexBox sx={{ gap: '8px', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap' }}>
                  <GoalTypePassingRules/>
                  {postback.goalType }
                </FlexBox>
              </Item>
              </Grid>
              <Grid item xs={4} sx={{ padding: '0px!important', marginTop: '20px!important', height: '64px!important' }}>
                <Item sx={{ width: '100%', paddingRight: '16px' }}>
                  <FlexBox sx={{ gap: '8px', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap', height: '32px', whiteSpace: 'nowrap', overflowX: 'hidden' }}>
                    {postback.postbackLink}
                  </FlexBox>
                </Item>
              </Grid>
              <Grid item xs={2} sx={{ padding: '0px!important', marginTop: '20px!important', height: '32px!important' }}>
                <Item sx={{ paddingRight: '16px', paddingY: '12px' }}>
                  <Box sx={{ display: 'flex', flex: '0 0 25%', alignItems: 'center', justifyContent: 'flex-end', gap: spacing(3) }}>
                    <DeleteWithConfirmation id={postback.id}/>
                    <BasicMenu id={postback.id} />
                    <Edit onClick={(e) => onEdit(e, postback)} />
                  </Box>
                </Item>
              </Grid>
              {isUpdatePopup && (
               <UpdatePostBackPopup handleClose={() => setIsUpdatePopup(false)} postback={postback}/>
              )}
          </Grid>

  )
}
