import React from 'react'

import Dashboard from '@/designSystem/NavigationPanel/Icons/Dashboard'
import TraficDistribution from '@/designSystem/NavigationPanel/Icons/TraficDistribution'
import TraficData from '@/designSystem/NavigationPanel/Icons/TraficData'
import LeadConvertions from '@/designSystem/NavigationPanel/Icons/LeadConvertions'
import Postback from '@/designSystem/NavigationPanel/Icons/Postback'
import Reporting from '@/designSystem/NavigationPanel/Icons/Reporting'
import Injections from '@/designSystem/NavigationPanel/Icons/Injections'
import FinanceAndTools from '@/designSystem/NavigationPanel/Icons/FinanceAndTools'

import AdvertisersPage from '@/pages/TrafficDistribution/Advertisers'
import RotationControlPage from '@/pages/TrafficDistribution/RotationControl'
import LeadsPage from '@/pages/TrafficData/Leads'
import HoldPage from '@/pages/TrafficData/Hold'
import SaleStatusesPage from '@/pages/TrafficData/SaleStatuses'

import type { Tab } from '@/designSystem/NavigationPanel/types'
import DashboardMain from '@/pages/Dashboard'
import InjectionsPage from '@/pages/Injections/Injections'
import IntegrationSettingsPage from '@/components/Advertisers/IntegrationSettingsPage'
import AutoInjectionsPage from '@/pages/Injections/AutoInjections'
import AdvertiserRevenuePage from '@/pages/LeadConvertions/AdveriserRevenue'
import DealsUpdaterPage from '@/pages/Deals/DealsUpdater'
import ConversionsPage from '@/pages/TrafficData/Conversions'
import LeadInformationPage from '@/components/Pages/TrafficData/Leads/LeadInformationPage'
import DealsPage from '@/pages/Deals/DealsHistory'
import { useSelector } from 'react-redux'
import { selectAccessRights } from '@/store/auth'
import { fullAccess } from '@/pages/Accounts/access'
import DrillDownReportsPage from '@/pages/DrillDownReports'
import PostBackPage from '@/pages/PostBack/PostBackPage'

export const routeRefs = Object.freeze({
  home: '/',
  traficDistribution: {
    advertisets: {
      index: '/trafic-distribution/advertisers/',
      advertiser: (id: string | number) => routeRefs.traficDistribution.advertisets.index + String(id)
    },
    rotationControl: '/trafic-distribution/rotation-control/',
    saleStatuses: '/trafic-distribution/sale-statuses'
  },
  traficData: {
    hold: '/trafic-data/hold',
    conversions: '/trafic-data/conversions',
    leads: '/trafic-data/leads/',
    lead: (id: string | number) => routeRefs.traficData.leads + String(id)
  },
  leadConvertions: {
    advertisersPayout: '/lead-convertions/advertiser-payout',
    advertiserRevenue: '/lead-convertions/advertiser-revenue',
    conversions: '/lead-convertions/conversions'
  },
  postback: '/postback/',
  reporting: {
    drilldownReports: '/reporting/drilldown-reports'
  },
  injections: {
    injections: '/injections/injections',
    autoInjections: '/injections/auto-injection'
  },
  financeAndTools: {
    dealsUpdater: '/finance-and-tools/deals-updater',
    dealsHistory: '/finance-and-tools/deals-history'
  }
}
)

const tabs: Tab[] = [
  {
    access: fullAccess.DASHBOARD,
    icon: <Dashboard />,
    text: 'Dashboard',
    component: <DashboardMain />,
    ref: routeRefs.home
  },
  {
    icon: <TraficDistribution />,
    text: 'Trafic Distribution',
    nestedTabs: [
      {
        access: fullAccess.ADVERTISERS,
        text: 'Advertisers',
        ref: routeRefs.traficDistribution.advertisets.index,
        component: <AdvertisersPage />
      },
      {
        access: fullAccess.ROTATIONS,
        text: 'Rotation Control',
        ref: routeRefs.traficDistribution.rotationControl,
        component: <RotationControlPage />
      },
      {
        access: fullAccess.SALE_STATUSES,
        text: 'Sale Statuses',
        ref: routeRefs.traficDistribution.saleStatuses,
        component: <SaleStatusesPage />
      },
      {
        access: fullAccess.ADVERTISERS,
        text: '',
        ref: routeRefs.traficDistribution.advertisets.index + ':advertiserId/',
        isHidden: true,
        component: <IntegrationSettingsPage />
      }
    ]
  },
  {
    icon: <TraficData />,
    text: 'Trafic Data',
    nestedTabs: [
      {
        access: fullAccess.LEADS,
        text: 'Leads',
        ref: routeRefs.traficData.leads,
        component: <LeadsPage />
      },
      {
        access: fullAccess.HOLDS,
        text: 'Hold',
        ref: routeRefs.traficData.hold,
        component: <HoldPage />
      },
      {
        access: fullAccess.CONVERSIONS,
        text: 'Conversions',
        ref: routeRefs.traficData.conversions,
        component: <ConversionsPage />
      },
      {
        access: fullAccess.LEADS,
        text: '',
        ref: routeRefs.traficData.leads + ':leadId/',
        isHidden: true,
        component: <LeadInformationPage />
      }
    ]
  },
  {
    icon: <LeadConvertions />,
    text: 'Lead Convertions',
    nestedTabs: [
      {
        access: fullAccess.ADVERTISER_REVENUES,
        text: 'Advertiser Revenue',
        ref: routeRefs.leadConvertions.advertiserRevenue,
        component: <AdvertiserRevenuePage />
      }
    ]
  },
  {
    access: fullAccess.POSTBACKS,
    icon: <Postback />,
    text: 'Postback',
    ref: routeRefs.postback,
    component: <PostBackPage />
  },
  {
    icon: <Reporting />,
    text: 'Reporting',
    nestedTabs: [
      {
        access: fullAccess.DRILLDOWN_REPORTS,
        text: 'Drilldown Reports',
        ref: routeRefs.reporting.drilldownReports,
        component: <DrillDownReportsPage />
      }
    ]
  },
  {
    icon: <Injections />,
    text: 'Injections',
    nestedTabs: [
      {
        access: fullAccess.INJECTIONS,
        text: 'Injections',
        ref: routeRefs.injections.injections,
        component: <InjectionsPage />
      },
      {
        access: fullAccess.AUTO_INJECTIONS,
        text: 'Auto Injection',
        ref: routeRefs.injections.autoInjections,
        component: <AutoInjectionsPage />
      }
    ]
  },
  {
    icon: <FinanceAndTools />,
    text: 'Finance & Tools',
    nestedTabs: [
      {
        access: fullAccess.DEALS_UPDATERS,
        text: 'Deals Updater',
        ref: routeRefs.financeAndTools.dealsUpdater,
        component: <DealsUpdaterPage />
      },
      {
        access: fullAccess.DEALS_HISTORIES,
        text: 'Deals History',
        ref: routeRefs.financeAndTools.dealsHistory,
        component: <DealsPage />
      }
    ]
  }
]

const getAccessTabs = (tabs: Tab[]): Tab[] => {
  const accessState = useSelector(selectAccessRights)
  return tabs?.map((tab) => {
    if (tab?.access && !accessState?.includes(tab.access)) {
      return { ...tab, noAccessTab: true }
    }
    if (tab?.nestedTabs) {
      return { ...tab, nestedTabs: getAccessTabs(tab?.nestedTabs) }
    }
    return tab
  })
}

const getTabs = () => getAccessTabs(tabs)

const getRoute = (tab: Tab): Tab[] => {
  const accessState = useSelector(selectAccessRights)
  if (tab?.access && !accessState?.includes(tab?.access)) {
    return []
  }
  if (tab?.nestedTabs) {
    return tab?.nestedTabs?.map(getRoute).flat()
  }
  return [tab]
}

export const getRoutes = () => tabs.map(getRoute).flat()

export default getTabs
