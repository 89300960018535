import { type LeadState } from '@/enums/leads.enum'

export interface IRotationControl {
  type: EType

  id: string
  description: string
  name: string
  priority: number
  capAmount: number | null
  capCount: number
  nodeParentId: string | null
  status: string
  pql: string
  renderer: ERenderer
  children: IRotationControl[]
  rejectedCap: number
  rejectedTimeoutCap: number
  resetCounterHours: number[]
  logs?: any[]
  sentLeads?: any[]
  revenue: number
  goalType: LeadState.FTD | LeadState.PUSHED_LEAD
  fillFirst: boolean
  weight: number
  advertiserId: string | null
}

export enum EType {
  Advertiser = 'advertiser',
  RotationFolder = 'rotation folder'
}

export enum ERenderer {
  PassAll = 'passAll',
  Country = 'country',
  Custom = 'custom'
}
