import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { useTheme, Typography, Box, IconButton, type SxProps } from '@mui/material'
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import { selectLogsTotalCount } from '@/store/advertisersLogs'
import { selectAdvertiserLogsQueryParams, setAdvertiserLogsQueryParams } from '@/store/queryParams'

const PaginationNavigation = () => {
  const dispatch = useDispatch()
  const { spacing, palette } = useTheme()

  const queryParams = useSelector(selectAdvertiserLogsQueryParams)
  const totalCount = useSelector(selectLogsTotalCount)

  const { page = 1, limit } = queryParams
  const totalPages = totalCount && Math.ceil(totalCount / limit)

  const iconSx: SxProps = {
    width: '20px',
    height: '20px'
  }

  return (
    <Box
      padding={spacing(1.5, 3)}
      border={`1px solid ${palette.primary.main}`}
      borderRadius="12px"
      display="flex"
      alignItems="center"
      gap={spacing(3)}
      sx={{ backgroundColor: palette.card.main }}
    >
      <IconButton
        sx={iconSx}
        disabled={page === 1}
        onClick={() => dispatch(setAdvertiserLogsQueryParams({
          ...queryParams,
          page: page - 1
        }))}
      >
        <KeyboardArrowLeftRoundedIcon />
      </IconButton>
      <Typography variant='headline'>
        {page}
      </Typography>
      <IconButton
        sx={iconSx}
        disabled={page === totalPages}
        onClick={() => dispatch(setAdvertiserLogsQueryParams({
          ...queryParams,
          page: page + 1
        }))}
      >
        <KeyboardArrowRightRoundedIcon />
      </IconButton>
    </Box>
  )
}

export default function AdvertisersLogsPagination () {
  const { spacing, palette } = useTheme()
  const { page = 1, limit = 10 } = useSelector(selectAdvertiserLogsQueryParams)
  const totalCount = useSelector(selectLogsTotalCount)

  if (!totalCount) {
    return null
  }

  const firstElement = (page - 1) * limit + 1
  const lastElement = Math.min(page * limit, totalCount)
  const range = `${firstElement} to ${lastElement} Elements / ${totalCount} Total`
  const totalPages = Math.ceil(totalCount / limit)
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="row"
      gap={spacing(12)}
    >
      <Typography
        variant='headline'
        color={palette.text1.main}
      >
        {range}
      </Typography>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="row"
        gap={spacing(3)}
      >
        <Typography variant='headline'>
          Page:
        </Typography>
        <PaginationNavigation />
        <Typography variant='headline'>
          of {totalPages}
        </Typography>
      </Box>
    </Box>
  )
}
