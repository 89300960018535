import React, { useEffect } from 'react'

import AccountsAutocomplete from '@/pages/Accounts/AccountsAutocomplete'
import { Box, Typography, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { type IPreset, selectPresetsState } from '@/store/presets'
import FormAutocomplete from '@/components/Form/FormAutocomplete'
import { type Option } from '@/designSystem/Autocomplete/types'
import { type UseFormReturn } from 'react-hook-form'
import { type GetDrillReportsArgs } from '@/store/drillReports'

interface IMetricPresetsGroups {
  data: { presetsGroups: string[], conditionFieldsOptions: Record<string, any> }
  form: UseFormReturn<GetDrillReportsArgs>
}

const MetricPresetsGroups = ({ data, form }: IMetricPresetsGroups) => {
  const theme = useTheme()
  const presetsState: IPreset[] | undefined = useSelector(selectPresetsState)

  const [error, setError] = React.useState<boolean>(false)
  const [preset, setPreset] = React.useState<IPreset | null>(null)

  const presetsOptions = presetsState?.map((preset: IPreset) => ({
    value: preset?.id ?? '',
    title: preset?.label
  }))

  const groupsOptions = data?.presetsGroups?.map((group: string) => ({
    value: group,
    title: group
  }))

  useEffect(() => {
    if (preset) {
      form.setValue('groups', preset?.groups)
      form.setValue('pql', preset?.pql)
      form.setValue('termsIds', preset?.termsIds)
    }
  }, [preset])

  const handlePreseteChange = (e: Option<string> | null) => {
    setPreset(presetsState?.find((preset: IPreset) => preset?.id === e?.value) ?? null)
  }

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '120px',
        display: 'flex',
        gap: '120px',
        padding: '20px',
        justifyContent: 'start',
        alignItems: 'start',
        backgroundColor: '#FFFFFF',
        borderRadius: '10px'
      }}
    >
      <Box>
        <Box
          color={theme.palette.text1.main}
          fontSize={18}
          fontWeight={400}
        >Change Preset</Box>
        <AccountsAutocomplete
          defaultValue={{ value: '', title: '' }}
          options={presetsOptions ?? []}
          onChange={(e: Option<string> | null) => handlePreseteChange(e)}
          placeholder="Change Option"
          sx={{ width: '416px' }} />
      </Box>
      <Box>
        <Box
          color={theme.palette.text1.main}
          fontSize={18}
          fontWeight={400}
        >Add Groups</Box>
        <FormAutocomplete
          name="groups"
          rowSelectedOptions
          options={groupsOptions ?? [{ title: 'Select groups', value: null }] as unknown as Array<Option<string>>}
          selectedOptions={form?.watch('groups')?.length > 0 ? form?.watch('groups') : []}
          onChange={(option: Option<string> | null) => {
            const isMaxGroups = form?.watch('groups')?.length <= 4
            option && isMaxGroups && form?.setValue('groups', [...form?.watch('groups'), option.value])
            option && setError(false)
          }}
          deleteField={(value: string) => () => {
            form.setValue('groups', form?.watch('groups')?.filter((item: string) => item !== value))
          }}
          sx={{ width: '416px' }}
          textFieldProps={{
            input: {
              sx: {
                input: {
                  padding: '0px 0px 0px 10px !important'
                }
              }
            }
          }}
        />
        {error && <Typography sx={{
          color: theme.palette.error.main,
          marginTop: '1px',
          fontSize: '12px'
        }}>Required field</Typography>}
      </Box>
    </Box>
  )
}

export default MetricPresetsGroups
