import React, { useEffect } from 'react'

import EditPushSettingsFormModal from '@/components/Advertisers/IntegrationSettingsPage/ApiConnectionSettings/EditPushSettingsFormModal'
import { useParams } from 'react-router-dom'
import { selectAdvertiserSettings } from '@/store/advertisers'
import { useSelector } from 'react-redux'

export enum EApiSettingsFields {
  CRM_SALE_STATUS_TO_USE = 'crm_sale_status_to_use',
  STATUS_FIELD = 'status_field',
  STATUS_FIELD_VALUE = 'status_field_value',
  STATUS_CHECK_TYPE = 'status_check_type',
  RESPONSE_CODE_VALUE = 'response_code_value',
  // USE_PULL_LEAD_STATUS_SETTINGS = 'use_pull_lead_state_settings',
  STATE_FIELD = 'state_field',
  STATE_FIELD_FTD_VALUE = 'state_field_ftd_value',
  LEAD_ID_FIELD = 'lead_id_field',
  URL = 'url',
  PAGE_FIELD = 'page_field',
  REQUEST_TYPE = 'request_type',
  LEAD_LIST_FIELD = 'lead_list_field',
  STARTING_PAGE_NUMBER = 'starting_page_number',
  AUTO_LOGIN = 'auto_login_url_field',
  PRE_REQUEST_CODE = 'pre_request_code',
  REQUEST_TIMEOUT = 'request_timeout'
}

interface EditPushSettingsFormProps {
  handleClose: () => void
}

export interface IApiSettings {
  key?: string | EApiSettingsFields
  group?: string
  value?: string
  label?: string
  data?: { countries: string[] }
}

const defaultSettings = [
  {
    key: EApiSettingsFields.URL,
    group: 'connection',
    value: ''
  },
  {
    key: EApiSettingsFields.LEAD_ID_FIELD,
    group: 'connection',
    value: ''
  },
  {
    key: EApiSettingsFields.CRM_SALE_STATUS_TO_USE,
    group: 'connection',
    value: 'NEW'
  },
  {
    key: EApiSettingsFields.STATUS_CHECK_TYPE,
    group: 'connection',
    value: EApiSettingsFields.STATUS_FIELD_VALUE
  },
  {
    key: EApiSettingsFields.STATUS_FIELD,
    group: 'connection',
    value: ''
  },
  {
    key: EApiSettingsFields.STATUS_FIELD_VALUE,
    group: 'connection',
    value: ''
  },
  {
    key: EApiSettingsFields.RESPONSE_CODE_VALUE,
    group: 'connection',
    value: ''
  },
  {
    key: EApiSettingsFields.REQUEST_TYPE,
    group: 'connection',
    value: 'get'
  },
  {
    key: EApiSettingsFields.AUTO_LOGIN,
    group: 'connection',
    value: ''
  },
  {
    key: EApiSettingsFields.PRE_REQUEST_CODE,
    group: 'connection',
    value: ''
  },
  {
    key: EApiSettingsFields.REQUEST_TIMEOUT,
    group: 'connection',
    value: ''
  }
]

export default function EditPushSettingsForm ({
  handleClose
}: EditPushSettingsFormProps) {
  const { advertiserId } = useParams()
  const allApiSettings = useSelector(selectAdvertiserSettings)
  const mergedSettings = React.useMemo(() => {
    const existingKeys = new Set(allApiSettings.map((setting: IApiSettings) => setting.key))

    const missingSettings = defaultSettings.filter(
      (defaultSetting: IApiSettings) => !existingKeys.has(defaultSetting.key)
    )

    return [...allApiSettings, ...missingSettings]
  }, [allApiSettings, defaultSettings])
  const [settings, setSettings] = React.useState<IApiSettings[]>(mergedSettings)

  return (
    <>
      <EditPushSettingsFormModal
        advertiserId={advertiserId}
        handleClose={handleClose}
        settings={settings}
        setSettings={setSettings}
      />
    </>
  )
}
