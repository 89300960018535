import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import {
  useGetAutoInjectionsQuery,
  setAutoInjectTotalCount, selectAutoInjectMenu, setAutoInject, selectAutoInject, setAutoInjectMenu
} from '@/store/autoInjections'
import { selectAutoInjectQueryParams } from '@/store/queryParams'
import { Typography, useTheme } from '@mui/material'
import InjectPagination from './AutoInjectPagination'
import InjectFilterBar from './Filters/AutoInjectFilterBar'
import TableLayout from '@/designSystem/Table'
import elementAutoInjectionsMap from './ElementAutoMap'
import { EmptyTableAutoInject } from './EmptyTableAutoInject'
import { type IAutoInjection } from './AutoInjectionTypes'
import { ModalEventInfo } from '@/designSystem/ModalMessagesLogs'

const Header = ({ header }: { header: string }) => {
  if (header.startsWith('::')) {
    return ''
  }
  return (
    <Typography
      variant='body1'
      color="text1"
      width="max-content"
      sx={{
        display: 'flex'
      }}
    >
      {header}
    </Typography>
  )
}

export default function AutoInjectionsTable () {
  const dispatch = useDispatch()
  const { limit = 10, headers, filters, page } = useSelector(selectAutoInjectQueryParams)
  const menu = useSelector(selectAutoInjectMenu)
  const { data } = useGetAutoInjectionsQuery({ limit, filters, page })

  const { spacing } = useTheme()
  const injectionsState: IAutoInjection[] = useSelector(selectAutoInject)
  const [injections, setInjections] = React.useState<any>(injectionsState ?? [])
  const [modalLogs, setModalLogs] = React.useState<any>(null)
  const headersWithExtraFields = ['::indicatorAutoInjections']?.concat(headers)
  const headerNodes: React.ReactNode[] =
    headersWithExtraFields?.map((header) => <Header key={header} header={header} />)

  const handleOpenInject = ({ name, id, state }: { name: string, id: string, state: string }) => {
    dispatch(setAutoInjectMenu({ name, id, state }))
  }

  const rowNodes: any = injections?.map((row: any) => (
    headersWithExtraFields?.map((header) =>
      elementAutoInjectionsMap(header, row, setModalLogs, handleOpenInject))
  ))

  useEffect(() => {
    if (data) {
      data && dispatch(setAutoInject(data?.data ?? []))
      setInjections(data?.data ?? [])
      dispatch(setAutoInjectTotalCount(data?.totalCount ?? 0))
    }
  }, [data])

  useEffect(() => {
    if (menu?.name === 'Active') {
      const activeInjections = injectionsState?.filter((inject: any) => inject?.status === 'ACTIVE')
      setInjections(activeInjections)
    } else if (menu?.name === 'Finished') {
      const finishedInjections = injectionsState?.filter((inject: any) => inject?.status === 'FINISHED')
      setInjections(finishedInjections)
    } else {
      setInjections(injectionsState)
    }
  }, [menu])

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={spacing(6)}
    >
      <InjectPagination />
      <InjectFilterBar />
      <TableLayout
        headers={headerNodes}
        rows={rowNodes}
        sx={{
          '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-of-type': {
            width: 0
          }
        }}
      />
      {(rowNodes?.length === 0) && <EmptyTableAutoInject />}
      {modalLogs && <ModalEventInfo
        open={!!modalLogs}
        handleClose={() => setModalLogs(null)}
        logs={modalLogs}
      />}
    </Box>
  )
}
