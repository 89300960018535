import React, { useState } from 'react'

import List from '@mui/material/List'
import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'

import SearchAutocomplete from '@/designSystem/Autocomplete/Search'
import Tab from '@/designSystem/NavigationPanel/Tab'
import Time from '@/designSystem/NavigationPanel/Time'
import Button from '@/designSystem/Button'
import HiddenPanel from '@/designSystem/NavigationPanel/HiddenPanel'
import { windowHeight } from '@/constants'
import { openTab } from '@/designSystem/NavigationPanel/utils'

import type { Option } from '@/designSystem/Autocomplete/types'
import type { Tab as ITab } from '@/designSystem/NavigationPanel/types'

interface NavigationPanelProps {
  tabs: ITab[]
  onRoute: (value: Option<string>) => void
  setTabs: React.Dispatch<React.SetStateAction<ITab[]>>
  autocompleteOptions: Array<Option<string>>
}

export default function NavigationPanel ({
  tabs, onRoute, setTabs, autocompleteOptions
}: NavigationPanelProps) {
  const [isHidden, setIsHidden] = useState(false)
  const theme = useTheme()

  const onClickExpand = (text: string) => setTabs((prevTabs) => openTab(prevTabs, text))

  if (isHidden) {
    return (
      <HiddenPanel
        tabs={tabs}
        onRoute={onRoute}
        unHide={() => setIsHidden(false)}
      />
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={theme.spacing(4)}
      sx={{ minWidth: '312px', backgroundColor: theme.palette.leftBar.main }}
      height={windowHeight}
      overflow="auto"
    >
      <Box marginTop={theme.spacing(4)} sx={{ width: '100%' }}>
        <Time />
      </Box>
      <Box marginBottom={theme.spacing(11)} marginTop={theme.spacing(6)} sx={{ width: '100%' }}>
        <SearchAutocomplete options={autocompleteOptions} onChange={(val) => onRoute(val as Option<string>)} />
      </Box>
      <List sx={{ paddingBottom: theme.spacing(14), width: '100%' }}>
        {tabs.map((tab) => !tab.isHidden && (
          <Tab
            tab={tab}
            key={tab.text}
            onClickExpand={onClickExpand}
            onRoute={onRoute}
          />
        ))}
      </List>
      <Button
        text="Hide"
        variant='contained'
        onClick={() => setIsHidden(!isHidden)}
      />
    </Box>
  )
}
