import React from 'react'

const PassAllIcon = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="32" height="32" rx="8" fill="#000931"/>
        <path d="M15.2884 8.55116C16.0683 8.25118 16.9317 8.25118 17.7116 8.55116L23.2933 10.6982C23.6117 10.8207 23.8854 11.0367 24.0785 11.3179C24.2716 11.599 24.375 11.9321 24.375 12.2732V19.728C24.375 20.0691 24.2716 20.4022 24.0785 20.6834C23.8854 20.9645 23.6117 21.1806 23.2933 21.303L17.7116 23.4495C16.9317 23.7495 16.0683 23.7495 15.2884 23.4495L9.70669 21.303C9.38834 21.1806 9.11457 20.9645 8.92147 20.6834C8.72837 20.4022 8.625 20.0691 8.625 19.728V12.2732C8.625 11.9321 8.72837 11.599 8.92147 11.3179C9.11457 11.0367 9.38834 10.8207 9.70669 10.6982L15.2884 8.55116ZM12.7841 12.6709C12.6475 12.6146 12.4941 12.6144 12.3573 12.6702C12.2205 12.7261 12.1111 12.8335 12.0529 12.9694C11.9946 13.1052 11.9922 13.2585 12.0461 13.3961C12.1 13.5337 12.2059 13.6446 12.3409 13.7048L15.9375 15.2466V18.8128C15.9375 18.962 15.9968 19.1051 16.1023 19.2106C16.2077 19.3161 16.3508 19.3753 16.5 19.3753C16.6492 19.3753 16.7923 19.3161 16.8977 19.2106C17.0032 19.1051 17.0625 18.962 17.0625 18.8128V15.2466L20.6591 13.7053C20.727 13.6762 20.7886 13.634 20.8402 13.5812C20.8918 13.5283 20.9325 13.4658 20.9599 13.3971C20.9874 13.3285 21.0011 13.2552 21.0002 13.1813C20.9993 13.1074 20.9838 13.0344 20.9547 12.9665C20.9256 12.8986 20.8834 12.8371 20.8305 12.7854C20.7777 12.7338 20.7151 12.6931 20.6465 12.6657C20.5779 12.6382 20.5046 12.6246 20.4307 12.6255C20.3568 12.6264 20.2838 12.6418 20.2159 12.6709L16.5 14.2633L12.7841 12.6709Z" fill="white"/>
    </svg>

  )
}

export default PassAllIcon
