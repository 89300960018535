import React from 'react'

import FlexBox from '@/designSystem/FlexBox'

import { Typography, useTheme } from '@mui/material'
import Popup from '@/designSystem/Popup'

interface SupportInfoModalProps {
  handleClose: () => void
}
export default function SupportInfoModal ({
  handleClose
}: SupportInfoModalProps) {
  return (
    <Popup
      open
      handleClose={handleClose}
      title="Help Information"
      subTitle="API Integration Settings"
    >

      <FlexBox gap={3} column>
        <Typography
          color={'#393E51'}
          fontSize={18}
          fontWeight={400}
          marginBottom={'15px'}
        >Push Lead Connection
        </Typography>
        <Paragraph title="Connection" body="Here you can specify settings for sending a request to Advertiser API to push a lead and settings for parsing a response returned from it.
        You can use dot as a separator to specify a field that is nested inside a response object. For example, a path  'response.data.leadId' will check a 'leadId' field inside 'data' object that is nested inside top-level 'response' object" />
        <Paragraph title="URL" body="URL -  endpoint url in Advertiser API where a request to push a lead is sent" />
        <Paragraph title="Connection" body="Lead Id Field - a path indicating where Lead ID value can be found in the response object" />
        <Paragraph title="Connection" body="CRM Sale Status To Use - CRM Sale Status that will be set to the lead in case a check specified in Status Check Type select box passes" />
        <Paragraph title="Connection" body="Status Check Type - one of three ways to check a status that an advertiser set for this lead.
        1) Status field  - in this case a value found in the response object using Status Field input is compared with the value specified in the Status Field Value input.
        2) Response code  - in this case a value specified in the Response Code Value input is compared with the status code returned with the response from Advertiser API. For example, 201.
        3) Lead id availability  - in this case a check passes if a lead id value is found in the response using a path specified in Lead Id field input.
        " />
        <Paragraph title="Connection" body="Request type - the HTTP method used to send the lead data, such as POST or GET." />
        <Paragraph title="Connection" body="Request timeout – the duration (in milliseconds) to wait for a response. If response is not received within this time, a lead is considered as rejected."/>

        <Paragraph title="Connection" body="Auto Login Url Field – a path indicating where Auto Login Url value can be found in the response object"/>
        <Paragraph title="Connection" body="Add pre-request code - if set, the code specified in Pre-request code input is processed before sending a request to push a lead."/>

        <Paragraph title="Connection Settings" body="Allows you to edit values for each API Key to integrate advertisers into your system." />
      </FlexBox>
      <FlexBox gap={3} column>
        <Paragraph title="Groups" body="There are different groups of settings for integration advertisers to the system such as Auth Settings, Pulling Settings and ect." />
        <Paragraph title="API Key" body="An application programming interface key is a unique identifier used for integration of advertisers to the system." />
        <Paragraph title="Value" body="This is all necessary information for filling APi Keys fields." />
         <Paragraph title="Connection Settings" body="Allows you to edit values for each API Key to integrate advertisers into your system." />
      </FlexBox>
    </Popup>
  )
}

interface ParagraphProps {
  title: string
  body: string
}
function Paragraph ({ title, body }: ParagraphProps) {
  const { palette } = useTheme()
  return (
    <FlexBox gap={2} column>
      <Typography variant="headline" color={palette.text1.main}>{title}</Typography>
      <Typography variant="body2" color={palette.text2.main}>{body}</Typography>
    </FlexBox>
  )
}
