import { type LeadState } from '@/enums/leads.enum'

interface IItem {
  type: EType

  id: string
  description: string
  name: string
  priority: number
  capAmount: number | null
  capCount: number
  rejectedCapCount: number | null
  rejectedCap: number | null
  rejectedTimeoutCap: number | null
  rejectedTimeoutCapCount: number | null
  nodeParentId: string | null
  status: string
  pql: string
  renderer: ERenderer
  children: IItem[]
  level: number

  revenue: number
  goalType: LeadState.FTD | LeadState.PUSHED_LEAD
  fillFirst: boolean
  weight: number
}

export enum EType {
  Folder = 'folder',
  Revenue = 'revenue',
  Advertiser = 'advertiser',
  RotationFolder = 'rotation folder'
}

export enum ERenderer {
  PassAll = 'passAll',
  Country = 'country',
  Custom = 'custom',

}

export type { IItem }
