import React from 'react'

import FlexBox from '@/designSystem/FlexBox'

import { Typography, useTheme } from '@mui/material'
import Popup from '@/designSystem/Popup'

interface PresetsInfoModalProps {
  handleClose: () => void
}
export default function PresetsInfoModal ({
  handleClose
}: PresetsInfoModalProps) {
  return (
    <Popup
      open
      handleClose={handleClose}
      title="Help Information"
      subTitle="Data Exporter Presets List"
    >
      <FlexBox gap={3} column>
        <Paragraph body="Here you can create and edit necessary presets which will be used for Data Exporter" />
      </FlexBox>
    </Popup>
  )
}

interface ParagraphProps {
  body: string
}
function Paragraph ({ body }: ParagraphProps) {
  const { palette } = useTheme()
  return (
    <FlexBox gap={2} column>
      <Typography variant="body2" color={palette.text2.main}>{body}</Typography>
    </FlexBox>
  )
}
