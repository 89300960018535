import React, { useEffect, useMemo, useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'

import Popup from '@/designSystem/Popup'
import { Box, type SxProps, Typography, styled, useTheme } from '@mui/material'
import ActionButtons from '@/designSystem/ActionButtons'
import {
  type IPreset, useCreatePresetsMutation,
  selectPresetsState, useUpdatePresetsMutation,
  useGetPresetsGroupFieldsQuery,
  useGetPresetsConditionsFieldsQuery
} from '@/store/presets'
import Form from '@/components/Form'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import TextField from '@/components/Form/TextField'
import BaseTextField from '@/designSystem/TextField'
import { useSelector, useDispatch } from 'react-redux'
import { setTermsState, useGetTermsQuery } from '@/store/terms'
import FormAutocomplete from '@/components/Form/FormAutocomplete'
import { type Option } from '@/designSystem/Autocomplete/types'
import { ReactComponent as PlusIcon } from '@/assets/icons/add-plus-round.svg'
import { ReactComponent as TrushIcon } from '@/assets/icons/trash-red.svg'
import IOSSwitch from '@/designSystem/IOSSwitch'
import AccountsAutocomplete from '@/pages/Accounts/AccountsAutocomplete'
import { countryOptions } from '@/constants'
import DateTimePicker from '@/components/Form/DateTimePicker'

interface AddPreset {
  label: string
  groups: string[]
  termsIds: string[]
  pql: string
}

interface IPropsAddInjModal {
  handleClose: () => void
  open: boolean
  presetId?: string
}

export interface IConditionalItem {
  option: string
  sign: string
  value: string
}

const emptyError = {
  label: '',
  groups: '',
  termsIds: '',
  pql: ''
}

export const AddEditPresetModal = ({ handleClose, open, presetId }: IPropsAddInjModal) => {
  const dispatch = useDispatch()
  const [addPreset] = useCreatePresetsMutation()
  const [editPresets] = useUpdatePresetsMutation()
  const termsData = useGetTermsQuery({ limit: 500, page: 1 })?.data?.terms
  const presetsGroups = useGetPresetsGroupFieldsQuery({})?.data
  const conditionFieldsOptions = useGetPresetsConditionsFieldsQuery({})?.data
  const preset = useSelector(selectPresetsState)?.find((preset: IPreset) => preset.id === presetId)

  const theme = useTheme()

  const [checkAndOr, setCheckAndOr] = useState<boolean>(false)
  const [error, setError] = useState(emptyError)
  const [conditional, setConditional] = useState<IConditionalItem[]>([])
  const [pqlState, setPqlState] = useState<string>('')

  const termsOptions = termsData?.map((term) => ({
    title: term.label,
    value: term.id
  }))

  const groupsOptions = presetsGroups?.map((group: string) => ({
    title: group,
    value: group
  }))

  const conditionalOptions = conditionFieldsOptions && Object.keys(conditionFieldsOptions)?.map((key) => ({
    title: key,
    value: key
  }))

  const defaultValues = {
    label: '',
    groups: [],
    termsIds: [],
    pql: ''
  }

  const datePickerSx: SxProps = {
    backgroundColor: theme.palette.inputText.main,
    borderRadius: '16px'
  }

  function objectsToString (objects: IConditionalItem[]) {
    const parts = objects.map(obj => `${obj.option} ${obj.sign} ${obj.value}`)
    const orAnd = checkAndOr ? 'AND' : 'OR'
    return parts.join(` ${orAnd} `)
  }

  function formatDateTime (inputTime: Date) {
    const date = new Date(inputTime)
    const formattedDate = date.toISOString()
    return formattedDate
  }

  function areAllFieldsNotEmpty (objects: IConditionalItem[]) {
    return objects.every(obj =>
      Object.values(obj).every(value => typeof value === 'string' && value.trim() !== '')
    )
  }

  useEffect(() => {
    const pql = objectsToString(conditional)
    form.setValue('pql', pql)
    setPqlState(pql)
  }, [conditional])

  useEffect(() => {
    termsData && dispatch(setTermsState(termsData))
  }, [termsData])

  useEffect(() => {
    if (presetId) {
      preset?.label && form.setValue('label', preset?.label)
      preset?.groups && form.setValue('groups', preset?.groups)
      preset?.termsIds && form.setValue('termsIds', preset?.termsIds)
      preset?.pql && form.setValue('pql', preset?.pql)
      preset?.pql && setPqlState(preset?.pql)
    }
  }, [presetId])

  const form = useForm<AddPreset>({ defaultValues })

  const handleCloseReset = () => {
    form.reset()
    setPqlState('')
    setConditional([])
    handleClose()
  }

  const deleteConditions = () => {
    setConditional([])
  }

  const onSubmit: SubmitHandler<AddPreset> = async (data) => {
    const isNotEmpty = areAllFieldsNotEmpty(conditional)

    if (!(data?.groups?.length &&
      data?.label && data?.termsIds?.length && data?.pql) || !isNotEmpty) {
      setError({
        label: !data?.label ? 'Required field' : '',
        groups: !data?.groups?.length ? 'Required field' : '',
        termsIds: !data?.termsIds?.length ? 'Required field' : '',
        pql: !data?.pql ? 'Required field' : !isNotEmpty ? 'Required field and must change all fields' : ''
      })
      return
    } else {
      setError(emptyError)
    }
    if (presetId) {
      await editPresets({
        id: presetId,
        label: data.label,
        groups: data.groups,
        termsIds: data.termsIds,
        pql: data.pql
      })
      handleCloseReset()
      return
    }
    await addPreset({
      label: data.label,
      groups: data.groups,
      termsIds: data.termsIds,
      pql: data.pql
    }).unwrap()
    handleCloseReset()
  }

  const handleChangeCheckAndOr = (e: any) => {
    setCheckAndOr(e?.target?.checked)
  }

  const handleAddConditional = () => {
    setConditional([...conditional, {
      option: '',
      sign: '',
      value: ''
    }]
    )
  }

  const handleChangeValueContitional = (index: number, item: string) => (e: any) => {
    const newConditional = [...conditional]
    if (item === 'option') {
      newConditional[index] = {
        [item]: e,
        sign: '',
        value: ''
      }
    } else {
      newConditional[index] = {
        ...newConditional[index],
        [item]: e
      }
    }
    setConditional(newConditional)
  }

  const ConditionalItem = (option: string, sign: string, value: string, index: number) => {
    const optionSign = conditionFieldsOptions?.[option as keyof typeof conditionFieldsOptions]?.sign
    const isOptions = conditionFieldsOptions?.[option as keyof typeof conditionFieldsOptions]?.value === 'options' ||
      conditionFieldsOptions?.[option as keyof typeof conditionFieldsOptions]?.value === 'option'
    const isText = conditionFieldsOptions?.[option as keyof typeof conditionFieldsOptions]?.value === 'text'
    const isCountry = conditionFieldsOptions?.[option as keyof typeof conditionFieldsOptions]?.value === 'country'
    const isDate = conditionFieldsOptions?.[option as keyof typeof conditionFieldsOptions]?.value === 'date'

    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'7px'}
        width={'100%'}
      >
        <AccountsAutocomplete
          defaultValue={{ value: option, title: option }}
          options={conditionalOptions ?? [{ title: 'Change option', value: null }] as unknown as Array<Option<string>>}
          onChange={(e: any) => handleChangeValueContitional(index, 'option')(e?.value)}
          placeholder="Enter Option"
          sx={{ width: '100%' }}
        />
        <AccountsAutocomplete
          defaultValue={{ value: sign, title: sign }}
          options={optionSign ?? [{ title: 'Change sign', value: null }] as unknown as Array<Option<string>>}
          onChange={(e: any) => handleChangeValueContitional(index, 'sign')(e?.value)}
          placeholder="Change Option"
          sx={{ width: '100%' }}
        />
        {isText && <BaseTextField
          value={value}
          onChange={(e: any) => handleChangeValueContitional(index, 'value')(e?.target?.value)}
          placeholder="Enter Value"
          fullWidth />}
        {isOptions && <AccountsAutocomplete
          defaultValue={{ value, title: value }}
          options={conditionFieldsOptions?.[option as keyof typeof conditionFieldsOptions]?.options ??
            [{ title: 'Change option', value: null }] as unknown as Array<Option<string>>}
          onChange={(e: any) => handleChangeValueContitional(index, 'value')(e?.value)}
          placeholder="Change Option"
          sx={{ width: '100%' }} />}
        {isCountry && <AccountsAutocomplete
          defaultValue={{ value, title: value }}
          options={countryOptions ?? [{ title: 'Change country', value: null }] as unknown as Array<Option<string>>}
          onChange={(e: any) => handleChangeValueContitional(index, 'value')(e?.value)}
          placeholder="Change Country"
          sx={{ width: '100%' }} />}
        {isDate && <CustomizedDatePickers sx={datePickerSx}
          value={value}
          onAccept={(e: any) => {
            const date = formatDateTime(e)
            handleChangeValueContitional(index, 'value')(date)
          }}
        />}
        {index < conditional?.length - 1 && <Typography
          sx={{
            color: theme.palette.text1.main,
            fontSize: 15,
            fontWeight: 700
          }}
        >{checkAndOr ? 'AND' : 'OR'}
        </Typography>}
      </Box>
    )
  }

  const conditionalMemo = useMemo(() => {
    return conditional?.map((item, index) => {
      return (
        <Box
          width={'100%'}
          key={index}
        >
          {ConditionalItem(item.option, item?.sign ?? '', item.value ?? '', index)}
        </Box>
      )
    }) ?? []
  }, [conditional])

  return (
    <Popup
      open={open}
      title={presetId ? 'Edit Preset' : ' Create Preset'}
      subTitle={presetId ? undefined : 'New Item'}
      handleClose={handleCloseReset}
      overflow='auto'
      maxWidth='600px'
    >
      <ScrollBarContainer>
        <Form form={form} onSubmit={onSubmit}>
          <Box
            display="flex"
            flexDirection="column"
          >
            <Box display="flex" flexDirection="column" gap={'15px'}>

              <Box>
                <TextField title='Label' name='label' />
                <Typography sx={{
                  color: theme.palette.error.main,
                  marginTop: '1px',
                  fontSize: '12px'
                }}>{error.label}</Typography>
              </Box>

              <Box>
                <Box
                  color={theme.palette.text1.main}
                  fontSize={18}
                  fontWeight={400}
                >Terms Ids</Box>
                <FormAutocomplete
                  name="termsIds"
                  rowSelectedOptions
                  options={termsOptions ?? [{ title: 'Select terms', value: null }] as unknown as Array<Option<string>>}
                  selectedOptions={form.watch('termsIds')?.length > 0 ? form.watch('termsIds') : []}
                  onChange={(option: Option<string> | null) => {
                    option && form.setValue('termsIds', [...form.watch('termsIds'), option?.value])
                  }}
                  deleteField={(value: string) => () => {
                    form.setValue('termsIds', form.watch('termsIds')?.filter((item: string) => item !== value))
                  }}
                  placeholder="Enter terms ids"
                  sx={{ width: '100%' }}
                  textFieldProps={{
                    input: {
                      sx: {
                        input: {
                          padding: '0px 0px 0px 10px !important'
                        }
                      }
                    }
                  }}
                />
                <Typography sx={{
                  color: theme.palette.error.main,
                  marginTop: '1px',
                  fontSize: '12px'
                }}>{error.termsIds}</Typography>
              </Box>

              <Box>
                <Box
                  color={theme.palette.text1.main}
                  fontSize={18}
                  fontWeight={400}
                >Groups</Box>
                <FormAutocomplete
                  name="groups"
                  options={groupsOptions ?? [{ title: 'Select groups', value: null }] as unknown as Array<Option<string>>}
                  selectedOptions={form.watch('groups')?.length > 0 ? form.watch('groups') : []}
                  onChange={(option: Option<string> | null) => {
                    const isMaxGroups = form.watch('groups')?.length <= 4
                    option && isMaxGroups && form.setValue('groups', [...form.watch('groups'), option?.value])
                  }}
                  deleteField={(value: string) => () => {
                    form.setValue('groups', form.watch('groups')?.filter((item: string) => item !== value))
                  }}
                  placeholder="Enter groups"
                  sx={{ width: '100%' }}
                  textFieldProps={{
                    input: {
                      sx: {
                        input: {
                          padding: '0px 0px 0px 10px !important'
                        }
                      }
                    }
                  }}
                />
                <Typography sx={{
                  color: theme.palette.error.main,
                  marginTop: '1px',
                  fontSize: '12px'
                }}>{error.groups}</Typography>
              </Box>

              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                paddingTop={'24px'}
              >
                <Typography
                  sx={{
                    color: theme.palette.text1.main,
                    fontSize: 18,
                    fontWeight: 700
                  }}
                >Conditions
                </Typography>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={'14px'}
                >
                  {form.watch('pql') && <Box
                    sx={{
                      cursor: 'pointer',
                      marginTop: '8px'
                    }}
                    onClick={deleteConditions}
                  >
                    <TrushIcon width={'28px'} height={'28px'} />
                  </Box>}
                  {form.watch('pql') && <IOSSwitch
                    checked={checkAndOr}
                    onChange={(e) => handleChangeCheckAndOr(e)}
                    formControlLabel={{
                      label: 'OR / AND',
                      labelPlacement: 'end',
                      sx: {
                        margin: 0,
                        '& .MuiTypography-root': {
                          marginRight: theme.spacing(3),
                          marginLeft: theme.spacing(1.5)
                        }
                      }
                    }} />}
                  <Box
                    sx={{
                      cursor: 'pointer',
                      marginTop: '7px'
                    }}
                    onClick={handleAddConditional}
                  >
                    <PlusIcon width={'29px'} height={'29px'} />
                  </Box>
                </Box>
              </Box>
              <BaseTextField multiline rows={2} inputHeight='70px' disabled
                defaultValue={pqlState} placeholder='Change conditional options' />
              {conditionalMemo}
              <Typography sx={{
                color: theme.palette.error.main,
                marginTop: '1px',
                fontSize: '12px'
              }}>{error.pql}</Typography>
            </Box>
            <ActionButtons
              sx={{ paddingTop: '24px', paddingBottom: '24px' }}
              onCancel={handleCloseReset}
              submitText="Submit"
              cancelText='Cancel'
            />
          </Box>
        </Form>
      </ScrollBarContainer>
    </Popup>
  )
}

const CustomizedDatePickers = styled(DateTimePicker)(`  
.MuiInputBase-root{
  background: #FFFFFF;
  border-radius: 16px;
  padding: 8px 16px;
}
input{
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #393E51;
}
label{
  color: #6B6B6B;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: -9px;
  &.Mui-focused{
    margin-top: -4px;
  }
}
`)
