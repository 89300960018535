import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import type { RootState } from '@/store'
import { type IRotationControl } from '@/components/Pages/TrafficDistribution/RotationControl/RotationControlTypes'

export interface ExtraSettings {
  key: string
  value: string
}

export interface TimeReset {
  id: string
  hour: number
}

export type RotationControlWithExtraSettings = IRotationControl & {
  other?: ExtraSettings[]
}

export const selectQueryParams = (state: RootState) =>
  state.rotationControl.queryParams

type CreateTimeResetArgs = Pick<
TimeReset, 'hour'>

export const resetTimeApi = createApi({
  reducerPath: 'timeResetApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/resetCounterTime`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['TimeReset'],
  endpoints: (builder) => ({
    getTimeResets: builder.query<TimeReset[], any>({
      query: () => ({
        url: '/search',
        method: 'POST'
      }),
      providesTags: [{ type: 'TimeReset', id: 'LIST' }]
    }),
    createTimeReset: builder.mutation<
    IRotationControl,
    CreateTimeResetArgs
    >({
      query: (query) => ({
        url: '/',
        method: 'POST',
        body: query
      }),
      invalidatesTags: [{ type: 'TimeReset', id: 'LIST' }]
    }),
    deleteTimeReset: builder.mutation<IRotationControl, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'TimeReset', id: 'LIST' }]
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetTimeResetsQuery,
  useCreateTimeResetMutation,
  useDeleteTimeResetMutation
} = resetTimeApi
