import React from 'react'
import { useTheme } from '@mui/material'
import composeSx from '@/designSystem/TextField/v2'
import Autocomplete from '@/components/Form/Autocomplete'

const limitOptions = [
  {
    title: '5',
    value: 5
  },
  {
    title: '10',
    value: 10
  },
  {
    title: '20',
    value: 20
  },
  {
    title: '50',
    value: 50
  },
  {
    title: '100',
    value: 100
  }
]

export default function ItemsPerPageAutocomplete () {
  const theme = useTheme()
  return (
    <Autocomplete
      name = "limit"
      options = {limitOptions}
      textFieldProps = {{
        root: {
          sx: composeSx(theme),
          title: 'Items per page',
          placeholder: 'Choose limiting'
        }
      }}
    />
  )
}
