import React, { useCallback, useLayoutEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import PublicRoutes from './PublicRoutes'
import PrivateRoutes from './PrivateRoutes'
import { useAuthWatchDog } from '@/hooks/auth'
import { useDispatch } from 'react-redux'
import { setAccessRights, setRole } from '@/store/auth'

const Routes = () => {
  const dispatch = useDispatch()
  const [auth, setAuth] = useState('')
  const [refresh, setRefresh] = useState(0)

  useLayoutEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      setAuth(token)
    }
    const storageAccess = localStorage.getItem('accessRights')?.split(',')
    if (storageAccess) {
      dispatch(setAccessRights(storageAccess))
    }
    const roleStorage = localStorage.getItem('role')
    if (roleStorage) {
      dispatch(setRole(roleStorage))
    }
  }, [])

  const afterLogin = useCallback(() => {
    setRefresh((old) => old + 1)
  }, [])

  const afterLogout = useCallback(() => {
    setRefresh((old) => old + 1)
  }, [])

  useAuthWatchDog(afterLogin, afterLogout)

  return (
    <BrowserRouter>{auth ? <PrivateRoutes key={refresh}/> : <PublicRoutes key={refresh}/>}</BrowserRouter>
  )
}
export default Routes
