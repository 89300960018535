import React, { useEffect, useMemo } from 'react'
import Button from '@/designSystem/Button'
import Popup from '@/designSystem/Popup'
import { Box, Checkbox, Typography, styled, useTheme } from '@mui/material'
import TextField from '@/designSystem/TextField'
import AccountsAutocomplete from './AccountsAutocomplete'
import BasicTable from '@/designSystem/BasikTable'
import theme from '@/theme'
import { type IUsers } from '@/store/users'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import { useLogOutUserMutation } from '@/store/auth'
import { fullAccessArray } from './access'

interface IAccountModal {
  open: boolean
  onCancel: () => void
  onSubmit?: any
  addAccount?: boolean
  accounts?: IUsers[]
  editAccountId?: string
  settingsId?: string
}

const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,30}$/
const regexAccessCode = /^[a-zA-Z0-9]{8}$/

export const AddEditAccountModal = ({
  open, onCancel, onSubmit, addAccount = false,
  accounts, editAccountId
}: IAccountModal) => {
  const [logOutUser] = useLogOutUserMutation()
  const theme = useTheme()
  const [email, setEmail] = React.useState('')
  const [changedEmail, setChangedEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [role, setRole] = React.useState('')
  const [changedRole, setChangedRole] = React.useState('')
  const [errorEmail, setErrorEmail] = React.useState('')
  const [errorPassword, setErrorPassword] = React.useState('')

  useEffect(() => {
    const changeEmail = accounts?.find((account) => account.id === editAccountId)?.email
    const changeRole = accounts?.find((account) => account.id === editAccountId)?.role
    setChangedRole(changeRole ?? '')
    setRole(changeRole ?? '')
    setChangedEmail(changeEmail ?? '')
    setEmail(changeEmail ?? '')
  }, [editAccountId])

  useEffect(() => {
    if (addAccount) {
      setEmail('')
      setPassword('')
      setRole('')
    }
    setErrorEmail('')
    setErrorPassword('')
  }, [open])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handleRole = (event: { title: string, value: string }) => {
    setRole(event.value)
  }

  const handleSubmit = () => {
    if (addAccount) {
      if (!regexEmail.test(email)) {
        setErrorEmail('Invalid email')
      } else if (regexEmail.test(email)) {
        setErrorEmail('')
      }
      if (!regexPassword.test(password)) {
        setErrorPassword('Your password must contain at least 1 number, 1 letter, and 1 capital,must be at least 8 characters and must not be longer than 30 characters')
      } else if (regexPassword.test(password)) {
        setErrorPassword('')
      }
      if (!regexEmail.test(email) || !regexPassword.test(password)) {
        return
      }
      const submitObj = {
        email,
        password,
        role,
        name: email
      }
      void onSubmit?.(submitObj).then(() => {
        setEmail('')
        setPassword('')
        setRole('')
        onCancel()
      })
    } else {
      if (!regexEmail.test(email)) {
        setErrorEmail('Invalid email')
        return
      } else if (regexEmail.test(email)) {
        setErrorEmail('')
      }
      const submitObj = {
        email,
        role
      }
      void onSubmit?.(submitObj, editAccountId).then(() => {
        setEmail('')
        setRole('')
        logOutUser(editAccountId)
        onCancel()
      })
    }
  }

  return (
    <Popup open={open} title={'Account management'}
      subTitle={addAccount ? 'Create Users' : 'Edit Users'} maxWidth='569px' handleClose={onCancel}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flexStart'
        }}>
          <Typography variant='body1' sx={{
            color: theme.palette.text1.main,
            marginBottom: '4.5px'
          }}>Email</Typography>
          <CustomizedInput
            value={accounts?.find((account) => account.id === editAccountId)?.email ?? email}
            placeholder='Enter Email'
            onChange={handleChange}
          />
          {errorEmail && <Typography sx={{
            color: theme.palette.error.main,
            marginTop: '4.5px',
            fontSize: '14px'
          }}>{errorEmail}</Typography>}
        </Box>

        {addAccount && <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flexStart'
        }}>
          <Typography variant='body1' sx={{
            color: theme.palette.text1.main,
            marginBottom: '4.5px'
          }}>Password</Typography>
          <CustomizedInput
            placeholder='Enter Password'
            onChange={handlePassword}
          />
          {errorPassword && <Typography sx={{
            color: theme.palette.error.main,
            marginTop: '4.5px',
            fontSize: '14px'
          }}>{errorPassword}</Typography>}
        </Box>}

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flexStart'
        }}>
          <Typography variant='body1' sx={{
            color: theme.palette.text1.main,
            marginBottom: '4.5px'
          }}>Role</Typography>
          <AccountsAutocomplete
            sx={{
              width: '521px'
            }}
            options={[{ title: 'ADMIN', value: 'ADMIN' }, { title: 'USER', value: 'USER' }]}
            placeholder='Enter Role'
            defaultValue={accounts?.find((account) => account.id === editAccountId)?.role === 'ADMIN'
              ? { title: 'ADMIN', value: 'ADMIN' }
              : accounts?.find((account) => account.id === editAccountId)?.role ? { title: 'USER', value: 'USER' } : undefined}
            onChange={(e) => e ? handleRole(e) : setRole('')}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button sx={{
          width: '252px',
          height: '43px'
        }} variant='outlined' text='Cancel' onClick={onCancel} />
        <Button sx={{
          width: '252px',
          height: '43px'
        }} variant='contained' text={addAccount ? 'Create' : 'Edit'}
          disabled={addAccount
            ? !(email && password && role)
            : ((changedEmail === email) && (changedRole === role))}
          onClick={handleSubmit} />
      </Box>
    </Popup>
  )
}

export const DeleteAccountModal = ({ open, onCancel, onSubmit }: any) => {
  return (
    <Popup open={open} title={'Are you sure you want to delete the user?'} maxWidth='569px' closeIcon>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button sx={{
          width: '252px',
          height: '43px'
        }} variant='outlined' text='Yes' onClick={onSubmit} />
        <Button sx={{
          width: '252px',
          height: '43px'
        }} variant='contained' text='No' onClick={onCancel} />
      </Box>
    </Popup>
  )
}

export const AccessSettingsModal = ({ open, onCancel, onSubmit, accounts, settingsId }: IAccountModal) => {
  const [logOutUser] = useLogOutUserMutation()
  const userAccessRights = accounts?.find((account) => account.id === settingsId)?.accessRights
  const userRoleUser = accounts?.find((account) => account.id === settingsId)?.role === 'USER'

  const [access, setAccess] = React.useState<string[] | undefined>([])
  const [password, setPassword] = React.useState('')
  const [resetQRCodeSecret, setResetQRCodeSecret] = React.useState(false)
  const [brandNames, setBrandNames] = React.useState(false)
  const [errorPassword, setErrorPassword] = React.useState('')
  const [accessCode, setAccessCode] = React.useState('')
  const [errorAccessCode, setErrorAccessCode] = React.useState('')

  useEffect(() => {
    userAccessRights && setAccess(userAccessRights)
    const user: IUsers | undefined = accounts?.find((account) => account.id === settingsId)
    if (user?.accessRights?.includes('CANNOT_VIEW_BRAND_NAMES')) {
      setBrandNames(true)
    } else {
      setBrandNames(false)
    }
  }, [settingsId])

  useEffect(() => {
    setPassword('')
    setErrorPassword('')
    setResetQRCodeSecret(false)
    setErrorAccessCode('')
    setAccessCode('')
  }, [open])

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setPassword(event.target.value)
      setResetQRCodeSecret(true)
    } else {
      setPassword(event.target.value)
      setResetQRCodeSecret(false)
    }
  }

  const handleShowBrandNames = () => {
    setBrandNames(!brandNames)
  }

  const handleAccessCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccessCode(event.target.value)
  }
  const handleSubmit = () => {
    if (!regexPassword.test(password) && password) {
      setErrorPassword('Your password must contain at least 1 number, 1 letter, and 1 capital,must be at least 8 characters and must not be longer than 30 characters')
      return
    } else if (regexPassword.test(password)) {
      setErrorPassword('')
    }

    if (!regexAccessCode.test(accessCode) && accessCode) {
      setErrorAccessCode('Invalid access code, must be 8 characters long and contain only letters and numbers')
      return
    } else if (regexAccessCode.test(accessCode)) {
      setErrorAccessCode('')
    }

    let uniqueAccessRights = Array.from(new Set(access))

    if (brandNames && !uniqueAccessRights?.includes('CANNOT_VIEW_BRAND_NAMES')) {
      uniqueAccessRights.push('CANNOT_VIEW_BRAND_NAMES')
    } else if (!brandNames && uniqueAccessRights) {
      uniqueAccessRights = uniqueAccessRights.filter((item) => item !== 'CANNOT_VIEW_BRAND_NAMES')
    }
    let submitObj = {}
    if (password && resetQRCodeSecret) {
      submitObj = {
        password,
        accessRights: uniqueAccessRights,
        resetQRCodeSecret,
        accessCodes: accessCode ? [accessCode] : undefined
      }
    } else {
      submitObj = {
        accessRights: uniqueAccessRights,
        accessCodes: accessCode ? [accessCode] : undefined
      }
    }
    void onSubmit?.(submitObj, settingsId).then(() => {
      logOutUser(settingsId)
      setPassword('')
      setErrorPassword('')
      setAccessCode('')
      setErrorAccessCode('')
      setResetQRCodeSecret(false)
      onCancel()
    })
  }

  const handleChangeAccess = (value: string) => {
    const newAccess = access?.includes(value)
      ? access.filter((item) => item !== value)
      : [...(access ?? []), value]
    setAccess(newAccess)
  }

  const accessRights = useMemo(() => fullAccessArray?.map((value: string) => {
    return {
      section: value,
      manage: <Checkbox checked={access && access?.includes(value)} onClick={() => handleChangeAccess(value)} />
    }
  }), [access])

  return (
    <Popup open={open} title={'Access settings'} maxWidth='569px' handleClose={onCancel}>
      <ScrollBarContainer>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '521px',
          minHeight: '250px',
          maxHeight: '285px',
          background: '#FFFFFF',
          borderRadius: '16px'
        }}>
          <ScrollBarContainer>
            <BasicTable headers={['Section', 'Manage']} rows={accessRights} sx={{
              minWidth: '250px'
            }} />
          </ScrollBarContainer>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '18px',
          padding: '16px 24px'
        }}>
          {!userRoleUser &&
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flexStart'
            }}>
              <Typography variant='body1' sx={{
                color: theme.palette.text2.main,
                marginBottom: '4.5px'
              }}>Access Code</Typography>
              <CustomizedPassInput
                placeholder='Enter Access Code'
                onChange={handleAccessCode}
              />
              {errorAccessCode && <Typography sx={{
                color: theme.palette.error.main,
                marginTop: '4.5px',
                fontSize: '14px'
              }}>{errorAccessCode}</Typography>}
            </Box>
          }
          <Typography variant='body1' sx={{
            color: theme.palette.text1.main,
            ...theme.typography.headline
          }}>Reset password and QR Code</Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flexStart'
          }}>
            <Typography variant='body1' sx={{
              color: theme.palette.text2.main,
              marginBottom: '4.5px'
            }}>New password</Typography>
            <CustomizedPassInput
              placeholder='Enter Password'
              onChange={handlePassword}
            />
            {errorPassword && <Typography sx={{
              color: theme.palette.error.main,
              marginTop: '4.5px',
              fontSize: '14px'
            }}>{errorPassword}</Typography>}
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }} >
            <Typography variant='body1' sx={{
              color: theme.palette.text2.main
            }}>Reset QR Code</Typography>
            <Checkbox checked={resetQRCodeSecret} />
          </Box>
          {userRoleUser && <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }} onClick={handleShowBrandNames} >
            <Typography variant='body1' sx={{
              color: theme.palette.text2.main
            }}>Do not display brand names</Typography>
            <Checkbox checked={brandNames} />
          </Box>}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
          <Button sx={{
            width: '252px',
            height: '43px'
          }} variant='outlined' text='Cansel' onClick={onCancel} />
          <Button sx={{
            width: '252px',
            height: '43px'
          }} variant='contained' text='Done' onClick={handleSubmit} />
        </Box>
      </ScrollBarContainer>
    </Popup>
  )
}

const CustomizedInput = styled(TextField)(`
width: 521px;
height: 40px;
border-radius: 16px;
`)
const CustomizedPassInput = styled(TextField)(`
width: 473px;
height: 40px;
border-radius: 16px;
`)
