import { serverURL } from './config'
import { leadsHeaders } from '@/store/leads'
const getCSV = async (table: 'hold' | 'leads' | 'conversions', ids: string[], headers: string[], csvFileName: string) => {
  const url = `${serverURL}/v1/csv/exportCsvData`
  const bodyRaw = {
    table,
    ids
  }
  const body = JSON.stringify(bodyRaw)
  try {
    const res = await fetch(url, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const data = await res.json()

    if (data?.length === 0) return

    const filteredHeaders = Object.entries(leadsHeaders).filter(([key, val]) => {
      return headers.includes(val) || headers.includes(key)
    })

    const headerString = filteredHeaders.map((i: any[]) => i[1]).join(',').concat('\n')

    const csv = data.map((d: any) => {
      const values = filteredHeaders.map(([key]) => {
        const value = d[key]
        if (!value) return ''
        if (key === 'advertiser') {
          return d?.Advertiser?.name ?? '" "'
        }
        if (typeof value === 'boolean') { return value ? 'Yes' : 'No' }
        if (typeof value === 'object') JSON.stringify(value).replaceAll(',', '')
        return value
      })
      return values.join(',')
    })
      .join('\n')

    const resultCSV = headerString.concat(csv)

    const blob = new Blob([resultCSV], { type: 'text/csv;charset=utf-8;' })

    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', `${csvFileName}.csv`)
    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
  } catch (e) {
    console.error(e)
  }
}

const api = {
  getCSV
}

export default api
