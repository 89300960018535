import React from 'react'
import axios from 'axios'
import authImage from '@/assets/images/auth.png'
import { ReactComponent as AuthIcon } from '@/assets/icons/auth-icon.svg'
import {
  Box, styled, Modal as MuiModal, useTheme, Typography,
  TextField, InputAdornment, IconButton
} from '@mui/material'
import Button from '@/designSystem/Button'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { QrCodeModal } from './QrCodeModal'
import { serverURL } from '@/config'
import FlexBox from '@/designSystem/FlexBox'

const SignInPage = () => {
  const theme = useTheme()
  const img = <img src={authImage} alt="img" />

  const [login, setLogin] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [newQr, setNewQr] = React.useState(false)
  const [code, setCode] = React.useState('')
  const [openQr, setOpenQr] = React.useState(false)
  const [qrCode, setQrCode] = React.useState('')
  const [error, setError] = React.useState('')
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleNewQr = () => setNewQr(!newQr)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLogin(event.target.value)
    setError('')
  }

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
    setError('')
  }

  const handleCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value)
    setError('')
  }

  const handleCanseled = () => {
    setOpenQr(false)
    setQrCode('')
    setError('')
  }

  const handleAuthLogin: any = async () => {
    // localStorage.setItem('token', '123')
    // dispatch(setRole('ADMIN'))
    // localStorage.setItem('role', 'ADMIN')
    // window.location.reload()
    let authObject = {}
    if (newQr) {
      authObject = {
        email: login,
        password,
        verificationCode: 'regenerate_QR_code'
      }
    } else if (code) {
      authObject = {
        email: login,
        password,
        verificationCode: code
      }
    } else {
      authObject = {
        email: login,
        password
      }
    }
    await axios.post(`${serverURL}/v1/auth/login`, authObject)
      .then((res) => {
        if (res?.data?.tokens?.access?.token) {
          localStorage.setItem('token', res?.data?.tokens?.access?.token)
          localStorage.setItem('role', res?.data?.user?.role)
          localStorage.setItem('accessRights', res?.data?.user?.accessRights)
          window.location.reload()
        } else if (res?.data?.imageUrl) {
          setOpenQr(true)
          setQrCode(res?.data?.imageUrl)
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'Something went wrong')
      })
    setNewQr(false)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          overflow: 'hidden'
        }}
      >
        {img}
      </Box>
      <CustomizedModal open>
        <>
          <AuthIcon />
          <Box
            sx={{
              fontSize: theme.typography.h1.fontSize,
              fontStyle: 'normal',
              fontWeight: theme.typography.h1.fontWeight,
              lineHeight: theme.typography.h1.lineHeight,
              color: theme.palette.text2.main,
              marginBottom: '24px',
              marginTop: '8px'
            }}
          >Sign In</Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flexStart'
          }}>
            <Typography variant='body1' sx={{
              color: theme.palette.text1.main,
              marginBottom: '4.5px'
            }}>Login</Typography>
            <CustomizedInput
              type='text'
              placeholder='Enter Login'
              onChange={handleChange}
            />
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flexStart',
            marginTop: '16px'
          }}>
            <Typography variant='body1' sx={{
              color: theme.palette.text1.main,
              marginBottom: '4.5px'
            }}>Password</Typography>
            <CustomizedInput
              placeholder='Enter Password'
              onChange={handlePassword}
              id="filled-adornment-password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment:
                  < InputAdornment position="end" >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              }}
            />
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flexStart',
            marginTop: '16px'
          }}>
            <Typography variant='body1' sx={{
              color: theme.palette.text1.main,
              marginBottom: '4.5px'
            }}>Verification Code</Typography>
            <CustomizedInput
              type='text'
              placeholder='Enter Code'
              onChange={handleCode}
            />
            <FlexBox alignCenter sx={{ marginTop: 2 }} gap={2}>
              <input type="checkbox" style={{ width: 20, height: 20 }} checked={newQr} onClick={handleNewQr}/>
              <Typography variant='body1' sx={{
                color: theme.palette.text1.main
              }}>New QR</Typography>
            </FlexBox>
          </Box>

          <Typography variant='body1' sx={{
            color: theme.palette.error.main,
            marginTop: '16px',
            maxWidth: '381px',
            textAlign: 'center',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            hyphens: 'auto',
            height: '40px'
          }}>{error}</Typography>

          <Button
            onClick={handleAuthLogin}
            text={<Typography variant='body1'
              sx={{
                textTransform: 'capitalize',
                color: theme.palette.whiteText.main
              }}
            >Sign In</Typography>}
            sx={{
              width: '381px',
              height: '43px',
              marginTop: '75px',
              borderRadius: '12px',
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark
              }
            }} />
        </>
      </CustomizedModal >
      <QrCodeModal open={openQr} qrCode={qrCode} onCancel={handleCanseled}
        onSubmit={handleCanseled} />
    </>
  )
}

export default SignInPage

const CustomizedModal = styled(MuiModal)(`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
.MuiBackdrop-root {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 24px;
  transform: translate(-50%, -50%);
  width: 429px;
  min-height: 627px;
  border-radius: 24px;
  border: 3px solid rgba(65, 65, 213, 0.30);
  background: rgba(255, 255, 255, 0.60);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
}
`)

const CustomizedInput = styled(TextField)(`
.MuiInputBase-input{
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  outline: none;
  padding: 2px 4px;
  &::placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.MuiOutlinedInput-root {
  width: 381px;
  height: 40px;
  border-radius: 16px;
  border-color: rgba(65, 65, 213, 0.30);
  input {
    padding-left: 16px;
  }
}
.MuiOutlinedInput-notchedOutline {
  border-radius: 16px;
  border-color: rgba(65, 65, 213, 0.30);
}
`)
