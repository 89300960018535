import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'

export const advertiserLogsHeaders: Record<string, string> = {
  name: 'Name',
  createdAt: 'Created At',
  advertiser: 'Advertiser',
  error: 'Error'
}
export interface LogsFilter {
  createdAt?: {
    from?: string
    to?: string
  }
  uuid?: string | undefined
  advertiserId?: string | undefined | null
}
export interface LogsStates {
  logs: any[]
  totalCount?: number | undefined
}
const initialState: LogsStates = {
  logs: [],
  totalCount: 0
}

export const advertisersLogsSlice = createSlice({
  name: 'advertisersLogs',
  initialState,
  reducers: {
    setLogsTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setLogs: (state, action: PayloadAction<any[]>) => {
      state.logs = action.payload
    }

  }
})
export const { setLogs, setLogsTotalCount } = advertisersLogsSlice.actions
export const selectLogsTotalCount = (state: RootState) => state.advertisersLogs?.totalCount
export const selectLogs = (state: RootState) => state.advertisersLogs.logs
export const selectLogsPage = (state: RootState) => state.queryParams.advertisersLogs.page

export default advertisersLogsSlice.reducer
interface SearchLogsArgs {
  page?: number
  limit?: number
  sortField: string
  filter?: {
    advertiserId?: string | null | undefined
    id?: string | null | undefined
  }
  createdAt?: {
    from?: string
    to?: string
  }
}
export interface AdvertisersLogs {
  advertiser: {
    name: string
    id: string
  }
  id: string
  logData: any
  createdAt: string
}
export interface GetLogsReturn {
  logs: AdvertisersLogs[]
  totalCount: number
}

export const advertisersLogsApi = createApi({
  reducerPath: 'advertisersLogsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/logs`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['Logs'],
  endpoints: (builder) => ({
    searchLogs: builder.query<GetLogsReturn, SearchLogsArgs>({
      query: ({ limit = 10, page = 1, sortField, filter, createdAt }) => ({
        url: '/advertiserLogs',
        method: 'POST',
        body: {
          limit,
          page,
          sortField,
          filter,
          createdAt
        }
      }),
      providesTags: [{ type: 'Logs', id: 'LogsLIST' }]
    })
  })
})

export const { useSearchLogsQuery } = advertisersLogsApi
