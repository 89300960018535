import React from 'react'
import ActionButtons from '@/designSystem/ActionButtons'
import Popup from '@/designSystem/Popup'
import { ScrollBarContainer } from '@/designSystem/ScrollBarContainer'
import { type UseFormReturn } from 'react-hook-form'
import { setDrillReportsDataState, setDrillReportsHeadersState, setDrillReportsState, type GetDrillReportsArgs } from '@/store/drillReports'
import { useSelector, useDispatch } from 'react-redux'
import { type ITerm, selectTermsState, setTermsState, useUpdateTermOrderMutation } from '@/store/terms'
import { Box, useTheme, IconButton } from '@mui/material'
import { ReactComponent as ArrowUpIcon } from '@/assets/icons/arr-up.svg'
import { ReactComponent as ArrowBottomIcon } from '@/assets/icons/arr-bottom.svg'
import Checkbox from '@/designSystem/Checkbox'
import Button from '@/designSystem/Button'
import { useNavigate } from 'react-router-dom'
import { iconData } from '@/designSystem/SelectIconTagField'

interface IWidgetModal {
  open: boolean
  onClose: () => void
  form?: UseFormReturn<GetDrillReportsArgs>
  isSettings?: boolean
}

interface ITermModalItem {
  term: ITerm
  checked: boolean
  checkItem: (term: ITerm) => void
  moveUp: (id: string) => void
  moveDown: (id: string) => void
  isSettings?: boolean
}

const TermsModalItem = ({ term, checked, checkItem, moveUp, moveDown, isSettings }: ITermModalItem): JSX.Element => {
  const termIcon = term?.icon && iconData?.find((item) => item?.value === term.icon)?.icon

  const handleCheck = (term: ITerm) => {
    checkItem(term)
  }

  const handleMoveUp = () => {
    moveUp(term.id)
  }

  const handleMoveDown = () => {
    moveDown(term.id)
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 16px 10px 16px',
        borderRadius: '16px',
        border: '1px solid #4141D5',
        backgroundColor: '#FFFFFF'
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        padding={'0 18px 0 10px'}
      >
        <IconButton sx={{ padding: '0 10px' }} onClick={handleMoveUp}>
          <ArrowUpIcon width={'22px'} height={'22px'} style={{ cursor: 'pointer' }} />
        </IconButton>
        <IconButton sx={{ padding: '0 10px' }} onClick={handleMoveDown}>
          <ArrowBottomIcon width={'22px'} height={'22px'} style={{ cursor: 'pointer' }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'start'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px'
          }}
        >
          {termIcon &&
            <Box border={'1px solid #4141D5'} display={'inline-block'}
              width={'47px'} height={'47px'} borderRadius={'10px'}>
              {termIcon}
            </Box>
          }
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px'
              }}
            >
              <Box sx={{ color: '#000931', fontSize: '18px', fontWeight: 500 }}>{term?.label}</Box>
              *
              <Box sx={{ color: '#7A7F8F', fontSize: '18px', fontWeight: 400 }}>{term?.format}</Box>
            </Box>
            <Box sx={{ color: '#393E51', fontSize: '14px', fontWeight: 400 }}>{term?.description}</Box>
          </Box>
        </Box>
      </Box>
      {!isSettings && <Checkbox
        checked={checked}
        onChange={() => { }}
        sx={{ padding: '0', cursor: 'pointer' }}
        onClick={() => handleCheck(term)}
      />}
    </Box>
  )
}

export const WidgetSettingsModal = ({ open, onClose, form, isSettings }: IWidgetModal) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const allTerms = useSelector(selectTermsState)
  const [editTermsOrder] = useUpdateTermOrderMutation()
  const [changeTerms, setChangeTerms] = React.useState<string[]>(form?.getValues()?.termsIds ?? [])
  const [allTermsState, setAllTermsState] = React.useState<ITerm[]>(allTerms ?? [])

  const handleChangeTerms = (term: ITerm) => {
    const isTermExist = changeTerms?.find((t: string) => t === term.id)
    if (isTermExist) {
      setChangeTerms(changeTerms?.filter((t: string) => t !== term.id))
    } else {
      setChangeTerms([...changeTerms, term.id])
    }
  }

  const handleTermMoveUp = (id: string) => {
    const termIndex = allTermsState?.findIndex((t: ITerm) => t?.id === id)
    if (termIndex > 0) {
      const newTerms = [...allTermsState]
      const temp = newTerms[termIndex - 1]
      newTerms[termIndex - 1] = newTerms[termIndex]
      newTerms[termIndex] = temp
      setAllTermsState(newTerms)
    }
  }

  const handleTermMoveDown = (id: string) => {
    const termIndex = allTermsState?.findIndex((t: ITerm) => t?.id === id)
    if (termIndex < allTermsState?.length - 1) {
      const newTerms = [...allTermsState]
      const temp = newTerms[termIndex + 1]
      newTerms[termIndex + 1] = newTerms[termIndex]
      newTerms[termIndex] = temp
      setAllTermsState(newTerms)
    }
  }

  const editTermsOrderHandler = async () => {
    const terms = allTermsState?.map((term: ITerm) => term.id)
    await editTermsOrder(terms).then((response: any) => {
      if (response?.data) {
        dispatch(setTermsState(response?.data?.data))
        onClose()
      }
    })
  }

  const handleSave = () => {
    const terms = allTermsState?.map((term: ITerm) => term.id)?.filter((t: string) => changeTerms?.includes(t))
    dispatch(setTermsState(allTermsState))
    dispatch(setDrillReportsState({ data: [], total: {} }))
    dispatch(setDrillReportsHeadersState([]))
    dispatch(setDrillReportsDataState(null))
    form?.setValue('termsIds', terms)
    onClose()
  }

  const handleOpenTermsPage = () => {
    navigate('/settings/crm-terms')
    onClose()
  }

  return (
    <Popup
      open={open}
      title="Widget Settings"
      subTitle={isSettings ? 'Sort Terms' : 'Sort and Select Terms'}
      handleClose={onClose}
      overflow='auto'
      maxWidth='600px'
    >
      {!isSettings && <Button
        sx={{
          borderRadius: '16px',
          height: '40px',
          width: '100%',
          padding: `${theme.spacing(1.5)} ${theme.spacing(6)}`
        }}
        variant='contained'
        text="Create term"
        textVariant='body2'
        onClick={handleOpenTermsPage}
      />}
      <ScrollBarContainer>
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxHeight={'400px'}
          gap={'12px'}>
          {allTermsState?.map((term: ITerm) => {
            const isChecked = !!changeTerms?.find((t: string) => t === term.id)
            return <TermsModalItem key={term.id} term={term} checked={isChecked} isSettings={isSettings}
              checkItem={handleChangeTerms} moveUp={handleTermMoveUp} moveDown={handleTermMoveDown} />
          })}
        </Box>
      </ScrollBarContainer>
      <ActionButtons
        sx={{ paddingTop: '24px' }}
        onCancel={onClose}
        onSubmit={isSettings ? editTermsOrderHandler : handleSave}
        submitText="Save"
        cancelText='Cancel'
      />
    </Popup>
  )
}
