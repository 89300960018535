import React from 'react'

export default function ExpandIcon () {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="22" rx="8" fill="#4141D5"/>
      <path d="M7.33333 9.66797C6.6 9.66797 6 10.268 6 11.0013C6 11.7346 6.6 12.3346 7.33333 12.3346C8.06667 12.3346 8.66667 11.7346 8.66667 11.0013C8.66667 10.268 8.06667 9.66797 7.33333 9.66797ZM16.6667 9.66797C15.9333 9.66797 15.3333 10.268 15.3333 11.0013C15.3333 11.7346 15.9333 12.3346 16.6667 12.3346C17.4 12.3346 18 11.7346 18 11.0013C18 10.268 17.4 9.66797 16.6667 9.66797ZM12 9.66797C11.2667 9.66797 10.6667 10.268 10.6667 11.0013C10.6667 11.7346 11.2667 12.3346 12 12.3346C12.7333 12.3346 13.3333 11.7346 13.3333 11.0013C13.3333 10.268 12.7333 9.66797 12 9.66797Z" fill="#F1F3FF"/>
    </svg>
  )
}
