/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { ReactComponent as EmptyTableIcon } from '@/assets/icons/empty-table.svg'
import { ReactComponent as PlusIcon } from '@/assets/icons/add-plus-white.svg'
import Button from '@/designSystem/Button'
import { AddEditPresetModal } from './AddEditPresetsModal'

export const EmptyTablePresets = () => {
  const [openAddPopup, setOpenAddPopup] = useState(false)

  const handleCloseModal = () => {
    setOpenAddPopup(false)
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding='40px 0'
        width="100%"
        height="300px"
        borderRadius="12px"
        marginTop="24px"
        gap="24px"
        sx={{
          backgroundColor: '#FFFFFF'
        }}
      >
        <EmptyTableIcon />
        <Typography
          variant='body1'
          color="text1"
          textAlign='center'
          sx={{
            fontWeight: '500',
            width: '445px',
            color: '#000931'
          }}
        >
          Data wasn't found for the current filters, but you can add a new element.
        </Typography>
        <Button
          text={<Box
            display='flex'
            alignItems='center'
            fontSize={18}
            fontWeight={500}
            whiteSpace={'nowrap'}
          >
            <PlusIcon style={{ marginRight: '8px' }} />
            Add New Data Exporter Preset</Box>}
          variant='contained'
          sx={{
            width: '320px',
            height: '40px',
            backgroundColor: '#4141D5'
          }}
          onClick={() => setOpenAddPopup(true)}
        />
      </Box>
      {openAddPopup && (
        <AddEditPresetModal handleClose={handleCloseModal} open={openAddPopup} />
      )}
    </>
  )
}
