import { Button, Box } from '@mui/material'
import type { Option } from '@/designSystem/Autocomplete/types'
import React from 'react'
import Autocomplete from '@/designSystem/Pages/Advertisers/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { selectTopAdvertisersCharts, setTopAdvertisersCharts } from '@/store/dashboard'
import { getCurrentDay, getCurrentMonth, getCurrentWeek, getCurrentYear, getLastMonth, getLastWeek, getLastYear, getYesterday } from '../utils'
import IOSSwitch from '@/designSystem/IOSSwitch'
import { type TopChartRightButtonsProps } from '@/components/Dashboard/types/dashboard'

export default function TopAdvertisersChartRightButtons ({ showFtd, onShowFtdChange }: TopChartRightButtonsProps) {
  const dispatch = useDispatch()
  const topAdvertisersCharts = useSelector(selectTopAdvertisersCharts)
  const getDateValues = [
    { title: 'No time period selected', value: 'none' },
    { title: 'Last Year vs Current Year', value: 'lastYear_currentYear' },
    { title: 'Last Month vs Current Month', value: 'lastMonth_currentMonth' },
    { title: 'Last Week vs Current Week', value: 'lastWeek_currentWeek' },
    { title: 'Yesterday vs Today', value: 'yesterday_today' }
  ]

  const onChangeTimePeriod = (value: Option<string> | null) => {
    let currentRangeX
    let lastRange
    switch (value?.value) {
      case 'yesterday_today':
        currentRangeX = getCurrentDay()
        lastRange = getYesterday()
        break
      case 'lastWeek_currentWeek':
        currentRangeX = getCurrentWeek()
        lastRange = getLastWeek()
        break
      case 'lastMonth_currentMonth':
        currentRangeX = getCurrentMonth()
        lastRange = getLastMonth()
        break
      case 'lastYear_currentYear':
        currentRangeX = getCurrentYear()
        lastRange = getLastYear()
        break
      default:
        currentRangeX = topAdvertisersCharts.localCurrentRange
        lastRange = topAdvertisersCharts.localLastRange
    }

    dispatch(
      setTopAdvertisersCharts({
        ...topAdvertisersCharts,
        localCurrentRange: currentRangeX,
        localLastRange: lastRange,
        timePeriod: value?.value
      })
    )
  }
  return (
    <Box
      display="flex"
      gap={6}
      alignItems="center"
    >
      <IOSSwitch
        formControlLabel={{
          label: 'Display FTD',
          labelPlacement: 'end',
          onChange: (e: any) => onShowFtdChange(e.target.checked),
          checked: showFtd,
          sx: {
            margin: 0,
            '& .MuiTypography-root': {
              marginRight: '12px',
              marginLeft: '10px'
            }
          }
        }}
      />
      <Autocomplete
            onChange={onChangeTimePeriod}
            options={getDateValues}
            value={getDateValues.find((item) => item.value === topAdvertisersCharts.timePeriod)}
            clearIcon=''
            placeholder={'No time period selected'}
            defaultValue={getDateValues[0]}
            leftShift
            sx={{ width: 300 }}
          />
    </Box>
  )
}
