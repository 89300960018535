import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
import { type IAutoInjection } from '@/components/AutoInjections/AutoInjectionTypes'

export const autoInjectionsHeaders: Record<string, string> = {
  name: 'Name',
  description: 'Description',
  interval: 'Interval',
  status: 'Status',
  state: 'State',
  createdAt: 'Created At',
  updatedAt: 'Updated At'
}

export interface AutoInjectionsFilters {
  createdAt?: {
    from?: string
    to?: string
  } | undefined
  status?: { in: string[] } | undefined
  id?: { in: string[] } | undefined
  name?: string | undefined
  state?: { in: string[] } | undefined
  country?: { in: string[] } | undefined
}

export interface AutoInjectionsState {
  data: IAutoInjection[]
  totalCount?: number
  menu?: {
    name: string
    id: string
    state?: string
  }
}

const initialState: AutoInjectionsState = {
  data: [],
  totalCount: 0,
  menu: { name: '', id: '' }
}

export const autoInjectionsSlice = createSlice({
  name: 'autoInjections',
  initialState,
  reducers: {
    setAutoInjectTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setAutoInjectMenu: (state, action: PayloadAction<{ name: string, id: string, state: string }>) => {
      state.menu = action.payload
    },
    setAutoInject: (state, action: PayloadAction<IAutoInjection[]>) => {
      state.data = action.payload
    }
  }
})

export const { setAutoInjectTotalCount, setAutoInjectMenu, setAutoInject } = autoInjectionsSlice.actions

export const selectAutoInjectTotalCount = (state: RootState) => state.autoInjections.totalCount
export const selectAutoInjectMenu = (state: RootState) => state.autoInjections.menu
export const selectAutoInject = (state: RootState) => state.autoInjections.data

export default autoInjectionsSlice.reducer

interface GetAutoInjectionsArgs {
  limit?: number
  page?: number
  filters?: AutoInjectionsFilters
}

type CreateEditAutoInjectionsArgs = Pick<IAutoInjection, 'name' | 'description' | 'country'
| 'dateRangeFrom' | 'monthRangeFrom' | 'dateRangeTo' | 'monthRangeTo' |
'hourRangeFrom' | 'hourRangeTo' | 'overrideFields' | 'daysOfWeek' | 'interval' | 'runInterval'>

interface GetAutoInjectionsReturn {
  data: IAutoInjection[]
  totalCount: number
}

export const autoInjectionsApi = createApi({
  reducerPath: 'autoInjectionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/autoinjections`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  tagTypes: ['AutoInjections'],
  endpoints: (builder) => ({
    getAutoInjections: builder.query<GetAutoInjectionsReturn, GetAutoInjectionsArgs>({
      query: ({ limit = 10, page = 1, filters }) => ({
        url: '/search',
        method: 'POST',
        body: {
          limit,
          page,
          sortField: 'createdAt',
          sortOrder: 'desc',
          createdAt: filters?.createdAt ?? undefined,
          filter: {
            status: filters?.status,
            id: filters?.id,
            name: filters?.name,
            state: filters?.state,
            country: filters?.country
          }
        }
      }),
      providesTags: [{ type: 'AutoInjections', id: 'AutoInjectionsLIST' }]
    }),
    createAutoInjections: builder.mutation<IAutoInjection, CreateEditAutoInjectionsArgs>({
      query: ({
        name, description, country, ...rest
      }) => ({
        url: '',
        method: 'POST',
        body: {
          name,
          description,
          country,
          status: 'ACTIVE',
          ...rest
        }
      }),
      invalidatesTags: [{ type: 'AutoInjections', id: 'AutoInjectionsLIST' }]
    }),
    editAutoInjections: builder.mutation<IAutoInjection, Partial<CreateEditAutoInjectionsArgs> & { id: string }>({
      query: ({ name, description, id }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: {
          name,
          description
        }
      }),
      invalidatesTags: [{ type: 'AutoInjections', id: 'AutoInjectionsLIST' }]
    }),
    stopAutoInjection: builder.mutation<IAutoInjection, { id: string }>({
      query: ({ id }) => ({
        url: '/stopAutoInjection',
        method: 'POST',
        body: {
          id
        },
        invalidatesTags: [{ type: 'AutoInjections', id: 'AutoInjectionsLIST' }]
      })
    })
  })
})

export const {
  useGetAutoInjectionsQuery, useCreateAutoInjectionsMutation,
  useEditAutoInjectionsMutation, useStopAutoInjectionMutation
} = autoInjectionsApi
