import React from 'react'

import MuiSelect, { type SelectProps as MuiSelectProps } from '@mui/material/Select'
import expandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

import {
  FormControl,
  InputLabel,
  MenuItem,
  styled,
  useTheme,
  Typography,
  type MenuItemProps,
  type InputLabelProps
} from '@mui/material'

export const InputLabelStyled = styled((props: InputLabelProps) => (
  <InputLabel {...props} />
))(({ theme }) => ({
  top: '-9px',
  color: theme.palette.text1.main
}))

export const MuiSelectStyled = styled(({ MenuProps, ...rest }: MuiSelectProps) => {
  const { spacing, palette } = useTheme()
  return (
    <MuiSelect
      IconComponent={expandMoreRoundedIcon}
      MenuProps={{
        sx: {
          '& .MuiMenu-paper': {
            marginTop: spacing(1),
            borderRadius: '16px',
            border: `1px solid ${palette.primary.main}`
          }
        },
        ...MenuProps
      }}
      {...rest}
    />
  )
})(({ theme }) => ({
  minWidth: '133px',
  borderRadius: '16px',
  height: '40px',
  border: `1px solid ${theme.palette.text1.main}`,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
    '&:hover': {
      borderColor: 'transparent'
    }
  },
  '&:focus-within': {
    borderColor: 'transparent',
    '&:hover': {
      borderColor: 'transparent'
    }
  }
}))

export interface Item {
  value: number
  title: React.ReactNode
}
type SelectProps = MuiSelectProps & {
  items: Item[]
  menuItemProps?: MenuItemProps
  inputLabelProps?: InputLabelProps
}

const magicalValue = Number.MIN_VALUE

export default function Select ({
  items,
  menuItemProps,
  inputLabelProps,
  title,
  ...rest
}: SelectProps) {
  const extendedItems = items.concat({ title: 'Actions', value: magicalValue })
  return (
    <FormControl>
      <MuiSelectStyled value={magicalValue} {...rest}>
        {extendedItems.map(({ title, value }) => (
          <MenuItem sx={{ display: value === magicalValue ? 'none' : undefined }} {...menuItemProps} key={value} value={value}>
            <Title title={title} />
          </MenuItem>
        ))}
      </MuiSelectStyled>
    </FormControl>
  )
}

function Title ({ title }: { title: React.ReactNode }) {
  const { palette } = useTheme()
  if (typeof title === 'string' || typeof title === 'number') {
    <Typography variant="body1" color={palette.text2.main}>
      {title}
    </Typography>
  }
  return title
}
