import React from 'react'

export default function EplCPL () {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="45" height="45" rx="12" fill="white" />
      <path d="M12.125 15H33.875C34.1734 15 34.4595 15.1414 34.6705 15.3931C34.8815 15.6448 35 15.9862 35 16.3421C35 16.6981 34.8815 17.0394 34.6705 17.2911C34.4595 17.5428 34.1734 17.6842 33.875 17.6842H12.125C11.8266 17.6842 11.5405 17.5428 11.3295 17.2911C11.1185 17.0394 11 16.6981 11 16.3421C11 15.9862 11.1185 15.6448 11.3295 15.3931C11.5405 15.1414 11.8266 15 12.125 15ZM15.5 23.5C15.5 23.1441 15.6185 22.8027 15.8295 22.551C16.0405 22.2993 16.3266 22.1579 16.625 22.1579H29.375C29.6734 22.1579 29.9595 22.2993 30.1705 22.551C30.3815 22.8027 30.5 23.1441 30.5 23.5C30.5 23.8559 30.3815 24.1973 30.1705 24.449C29.9595 24.7007 29.6734 24.8421 29.375 24.8421H16.625C16.3266 24.8421 16.0405 24.7007 15.8295 24.449C15.6185 24.1973 15.5 23.8559 15.5 23.5ZM20 30.6579C20 30.3019 20.1185 29.9606 20.3295 29.7089C20.5405 29.4572 20.8266 29.3158 21.125 29.3158H24.875C25.1734 29.3158 25.4595 29.4572 25.6705 29.7089C25.8815 29.9606 26 30.3019 26 30.6579C26 31.0138 25.8815 31.3552 25.6705 31.6069C25.4595 31.8586 25.1734 32 24.875 32H21.125C20.8266 32 20.5405 31.8586 20.3295 31.6069C20.1185 31.3552 20 31.0138 20 30.6579Z" fill="#4141D5" />
    </svg>
  )
}
