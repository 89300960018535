import React from 'react'

export default function DashboardIcon () {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.2614 4.74855C12.1916 4.68179 12.0988 4.64453 12.0022 4.64453C11.9056 4.64453 11.8128 4.68179 11.743 4.74855L3.11328 12.9925C3.07663 13.0275 3.04748 13.0696 3.02757 13.1163C3.00767 13.1629 2.99744 13.2131 2.9975 13.2639L2.99609 21.0011C2.99609 21.3989 3.15413 21.7804 3.43543 22.0617C3.71674 22.343 4.09827 22.5011 4.49609 22.5011H9.00078C9.19969 22.5011 9.39046 22.422 9.53111 22.2814C9.67176 22.1407 9.75078 21.95 9.75078 21.7511V15.3761C9.75078 15.2766 9.79029 15.1812 9.86062 15.1109C9.93094 15.0406 10.0263 15.0011 10.1258 15.0011H13.8758C13.9752 15.0011 14.0706 15.0406 14.1409 15.1109C14.2113 15.1812 14.2508 15.2766 14.2508 15.3761V21.7511C14.2508 21.95 14.3298 22.1407 14.4705 22.2814C14.6111 22.422 14.8019 22.5011 15.0008 22.5011H19.5036C19.9014 22.5011 20.2829 22.343 20.5643 22.0617C20.8456 21.7804 21.0036 21.3989 21.0036 21.0011V13.2639C21.0037 13.2131 20.9934 13.1629 20.9735 13.1163C20.9536 13.0696 20.9245 13.0275 20.8878 12.9925L12.2614 4.74855Z" fill="#000931"/>
      <path d="M23.0114 11.4445L19.5052 8.09016V3C19.5052 2.80109 19.4262 2.61032 19.2855 2.46967C19.1449 2.32902 18.9541 2.25 18.7552 2.25H16.5052C16.3063 2.25 16.1155 2.32902 15.9748 2.46967C15.8342 2.61032 15.7552 2.80109 15.7552 3V4.5L13.0402 1.90406C12.7861 1.64719 12.4083 1.5 12 1.5C11.5931 1.5 11.2163 1.64719 10.9622 1.90453L0.991893 11.4436C0.70033 11.7248 0.663768 12.1875 0.92908 12.4922C0.995704 12.5691 1.07728 12.6316 1.16885 12.676C1.26041 12.7204 1.36005 12.7457 1.46169 12.7503C1.56334 12.755 1.66487 12.7389 1.7601 12.7031C1.85533 12.6672 1.94228 12.6124 2.01564 12.5419L11.7422 3.2475C11.812 3.18074 11.9048 3.14348 12.0014 3.14348C12.098 3.14348 12.1909 3.18074 12.2606 3.2475L21.9881 12.5419C22.1314 12.6793 22.3234 12.7543 22.5219 12.7504C22.7204 12.7465 22.9092 12.6641 23.047 12.5212C23.3349 12.2231 23.311 11.7309 23.0114 11.4445Z" fill="#000931"/>
    </svg>
  )
}
