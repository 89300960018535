/* eslint-disable react/prop-types */
import React from 'react'

import { Box, Typography, useTheme } from '@mui/material'
import BasicTable from '@/designSystem/BasikTable'
import { EmptyTableInject } from '@/components/Injections/EmptyTableInject'
import ReloadButton from '@/designSystem/ReloadButton'
import { DateTimeField } from '@/designSystem/Table/Fields'
import PostBackSentPagination from './PostBackSentPagination'
import { type Lead } from '@/store/leads'
import { LeadState } from '@/enums/leads.enum'
import Button from '@/designSystem/Button'

const CardComponent = ({ title, value, lead, colorBack, color, handleSetModalLogs }: any) => {
  const { additionalInfo } = lead ?? {}
  if (title === LeadState.DUPLICATED && additionalInfo) {
    colorBack = '#FFFFFF'
    color = '#4141D5'
  }
  return (
    <Box
      minWidth={'230px'}
      height={'185px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={'24px'}
      sx={{
        padding: '16px 24px',
        flexDirection: 'column',
        borderRadius: '16px',
        background: colorBack || '#4141D5'
      }}
    >
      <Typography
        width="max-content"
        sx={{
          display: 'flex',
          fontWeight: '500',
          color: color || '#F1F3FF',
          fontSize: '24px',
          fontStyle: 'normal',
          lineHeight: 'normal',
          textTransform: 'capitalize'
        }}
      >
        {title}
      </Typography>
      <Typography
        width="max-content"
        sx={{
          display: 'flex',
          fontWeight: '400',
          color: color || '#F1F3FF',
          fontSize: '24px',
          fontStyle: 'normal',
          lineHeight: 'normal',
          textTransform: 'capitalize'
        }}
      >
        <DateTimeField date={new Date(value)} colorText={color || '#F1F3FF'} />
      </Typography>
      {(title === LeadState.DUPLICATED && additionalInfo)
        ? <Button sx={{
          // backgroundColor: palette.cyen1.main,
          // '&:hover': {
          //   backgroundColor: palette.cyen1.dark
          // }
        }} variant="contained" text='Duplicate Reason' onClick={() => handleSetModalLogs(additionalInfo)} />
        : <Typography
          width="max-content"
          sx={{
            display: 'flex',
            fontWeight: '400',
            color: color || '#F1F3FF',
            fontSize: '14px',
            fontStyle: 'normal',
            lineHeight: 'normal',
            textTransform: 'capitalize'
          }}
        >
          FSM state switched
        </Typography>}

    </Box>
  )
}

const LeadTableWrapper = ({
  rowsLeadsMain, rowsLeadsAdvertiserData,
  rowsLeadsProfile, rowsLeadsAdditional,
  executedPostbacks, onClickReload, dataLead,
  filteredAndSortedLogs, handleSetModalLogs
}: any) => {
  return (
    <>
      <Typography
        width="max-content"
        sx={{
          color: '#000931',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          textTransform: 'capitalize',
          paddingTop: '40px',
          paddingBottom: '12px'
        }}
      >
        Basic Information
      </Typography>
      <BasicTable
        headers={['UUID', 'Country', 'IP', 'State', 'Is Test']}
        rows={rowsLeadsMain}
      />
      <Typography
        width="max-content"
        sx={{
          color: '#000931',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          textTransform: 'capitalize',
          paddingTop: '24px',
          paddingBottom: '12px'
        }}
      >
        Advertiser Data
      </Typography>
      <BasicTable
        headers={['Advertiser', 'Sale Status', 'Is AutoLogin Successful', 'External Status', 'External ID']}
        rows={rowsLeadsAdvertiserData}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          paddingTop: '24px',
          gap: '32px'
        }}
      >
        <Box>
          <Typography
            width="max-content"
            sx={{
              color: '#000931',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              textTransform: 'capitalize',
              paddingBottom: '12px'
            }}
          >
            Additional
          </Typography>
          <BasicTable
            headers={['Created At', 'State Updated At']}
            rows={rowsLeadsAdditional}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
          >
            <Typography
              width="max-content"
              sx={{
                color: '#000931',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                textTransform: 'capitalize',
                paddingBottom: '12px'
              }}
            >
              Last Statuses
            </Typography>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'18px'}
          >
            {filteredAndSortedLogs?.length === 0 && <CardComponent title={dataLead?.state ?? 'Lead'} value={dataLead?.stateUpdatedAt} handleSetModalLogs={handleSetModalLogs}
             lead={dataLead} />}

            {filteredAndSortedLogs?.length > 0 && filteredAndSortedLogs.map((log: any, index: number) => {
              return <CardComponent key={index} title={log?.state} value={log?.updatedAt} lead={dataLead} handleSetModalLogs={handleSetModalLogs}
                colorBack={(filteredAndSortedLogs?.length - 1 !== index) && '#FFFFFF'}
                color={(filteredAndSortedLogs?.length - 1 !== index) && '#4141D5'} />
            })}
          </Box>
        </Box>
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        paddingTop={'40px'}
      >
        <Typography
          width="max-content"
          sx={{
            display: 'flex',
            fontWeight: '500',
            paddingBottom: '12px',
            color: '#000931',
            fontSize: '24px',
            fontStyle: 'normal',
            lineHeight: 'normal',
            textTransform: 'capitalize'
          }}
        >
          Lead profile
        </Typography>
      </Box>
      <BasicTable
        headers={['Email', 'First name', 'Last Name', 'Phone', 'Aff Sub1',
          'Aff Sub2', 'Aff Sub3', 'Aff Sub4', 'Aff Sub5', 'Aff Sub6', 'Aff Sub7', 'Aff Sub8', 'Aff Sub9', 'Aff Sub10',
          'Aff Sub11', 'Aff Sub12', 'Aff Sub13', 'Aff Sub14', 'Aff Sub15']}
        rows={rowsLeadsProfile}
      />

      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '12px',
            paddingTop: '47px',
            paddingBottom: '12px'
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'16px'}
          >
            <Typography
              width="max-content"
              sx={{
                color: '#000931',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                textTransform: 'capitalize'
              }}
            >
              Executed Postbacks
            </Typography>
            <Box><ReloadButton onClick={onClickReload} /></Box>
          </Box>
          <PostBackSentPagination />
        </Box>
        <BasicTable
          headers={['Postback uUID', 'Status', 'Content', 'Method', 'Created At']}
          rows={executedPostbacks}
        />
        {!executedPostbacks?.length && <EmptyTableInject />}
      </Box>
    </>
  )
}

export default LeadTableWrapper
