import React, { useState } from 'react'

import useTheme from '@mui/material/styles/useTheme'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import QuestionMarkIcon from '@/designSystem/PageContentLayout/Icons/QuestionMark'
import { IconButton } from '@mui/material'
import Popup from '@/designSystem/Popup'

interface PageContentLayoutProps {
  children: React.ReactNode
  title?: React.ReactNode
  support?: {
    content?: React.ReactNode
    title?: React.ReactNode
    subtitle: React.ReactNode
    popupMaxWidth?: string
  }
  leftActionButtons?: React.ReactNode
  rightActionButtons?: React.ReactNode
  activeMenu?: React.ReactNode
  contentMargin?: number
}

export default function PageContentLayout ({
  children,
  title,
  leftActionButtons,
  rightActionButtons,
  support,
  contentMargin = 6,
  activeMenu
}: PageContentLayoutProps) {
  const [showHint, setShowHint] = useState(false)

  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.whiteText.main,
          overflow: 'auto'
        }}
        width="100%"
        display="flex"
        flexDirection="column"
        padding={`${theme.spacing(8)} ${theme.spacing(8)}`}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" >
          <Box display="flex" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              gap={theme.spacing(2)}
              marginRight={theme.spacing(6)}
            >
              <Typography variant='h1' color={theme.palette.text1.main}>
                {title}
              </Typography>
              {support && (
                <IconButton onClick={() => setShowHint(true)}>
                  <QuestionMarkIcon />
                </IconButton>
              )}
              {activeMenu}
            </Box>
            {leftActionButtons}
          </Box>
          {rightActionButtons}
        </Box>
        <Box marginTop={theme.spacing(contentMargin)}>
          {children}
        </Box>
      </Box>
      {support && (
        <Popup
          maxWidth={support?.popupMaxWidth ?? '716px'}
          title={support?.title as any ?? 'Help Information'}
          subTitle={support.subtitle}
          open={showHint}
          handleClose={() => setShowHint(false)}
        >
          {support.content}
        </Popup>
      )}
    </>
  )
}
