import React, { forwardRef } from 'react'

import { Box, useTheme, type BoxProps } from '@mui/material'

type FlexBoxProps = Omit<BoxProps, 'gap'> & {
  column?: boolean
  gap?: number
  justifyCenter?: boolean
  alignCenter?: boolean
}
export default forwardRef(function FlexBox ({
  children,
  column,
  justifyCenter,
  alignCenter,
  gap,
  ...rest
}: FlexBoxProps, ref) {
  const { spacing } = useTheme()
  return (
    <Box
      ref={ref}
      display="flex"
      flexDirection={column ? 'column' : 'row'}
      gap={gap && spacing(gap)}
      justifyContent={justifyCenter ? 'center' : undefined}
      alignItems={alignCenter ? 'center' : undefined}
      {...rest}
    >
      {children}
    </Box>
  )
})
