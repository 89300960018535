import React from 'react'

import { type IPreset } from '@/store/presets'
import { Box } from '@mui/material'
import { ReactComponent as PercentIcon } from '@/assets/icons/percent.svg'
import { ReactComponent as EditPresetIcon } from '@/assets/icons/edit-term.svg'
import { ReactComponent as DeleteIcon } from '@/assets/icons/delete.svg'
import { format } from 'date-fns'
import IconButton from '@/designSystem/IconButton'
import { useSelector } from 'react-redux'
import { type ITerm, selectTermsState } from '@/store/terms'

export const PresetsItem = ({ preset, deletePreset, editPreset }:
{
  preset: IPreset
  deletePreset: (id: string) => void
  editPreset: (id: string) => void
}): JSX.Element => {
  const termsState = useSelector(selectTermsState)
  const validDate = (date: Date) => { return date && !isNaN(date.getTime()) }
  const createdAt = preset?.createdAt && validDate(new Date(preset?.createdAt))
    ? format(new Date(preset?.createdAt), 'dd/MM/yyyy HH:mm:ss')
    : preset?.createdAt

  const termsLabels = preset?.termsIds?.map((termId) => {
    const term = termsState?.find((term: ITerm) => term.id === termId)
    return term?.label
  }).join(',')

  const handleDelete = () => {
    preset?.id && deletePreset(preset.id)
  }

  const handleEdit = () => {
    preset?.id && editPreset(preset.id)
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '210px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 16px 20px 16px',
        borderRadius: '16px',
        border: '1px solid #4141D5',
        backgroundColor: '#FFFFFF'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'start'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '15px'
          }}
        >
          <PercentIcon width={'60px'} height={'60px'} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px'
            }}
          >
            <Box sx={{ color: '#000931', fontSize: '18px', fontWeight: 500 }}>{preset?.label}</Box>
            <Box sx={{
              color: '#7A7F8F',
              fontSize: '18px',
              fontWeight: 400,
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>{preset?.groups?.join(',')}</Box>
            <Box sx={{ color: '#7A7F8F', fontSize: '18px', fontWeight: 400 }}>{termsLabels}</Box>
            {createdAt && <Box sx={{ color: '#393E51', fontSize: '14px', fontWeight: 400 }}>{String(createdAt)}</Box>}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '28px'
          }}>
          <IconButton sx={{ background: 'white' }} onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
          <IconButton sx={{ background: 'white' }} onClick={handleEdit}>
            <EditPresetIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          minHeight: '55px',
          maxHeight: '75px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '7px 14px',
          margin: '7px 0',
          borderRadius: '16px',
          border: '1px solid #4141D5',
          backgroundColor: '#FFFFFF',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >{preset?.pql}</Box>
    </Box>
  )
}
