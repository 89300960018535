import * as React from 'react'
import MuiTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { type SxProps, styled } from '@mui/material'

export interface TableProps {
  headers: React.ReactNode[]
  rows: React.ReactNode[][]
  sx?: SxProps
  onRowClick?: (row: React.ReactNode[], index: number) => void
}

export default function Table ({ headers, rows, sx, onRowClick }: TableProps) {
  return (
        <TableContainer
            component={Paper}
            sx={{
              borderRadius: '16px',
              boxShadow: 'none',
              overflowY: 'hidden',
              overflowX: 'auto',
              width: '100%',
              '::-webkit-scrollbar': {
                height: '8px'
              },
              '::-webkit-scrollbar-track': {
                background: '#E0E2F0'
              },
              '::-webkit-scrollbar-thumb': {
                background: '#4141D5',
                borderRadius: 16
              }
            }}
        >
            <CustomTable sx={{ minWidth: 650, ...sx }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {headers.map((header, index) => (
                            <TableCell align="center" sx={{ textAlign: 'center' }} key={index}>{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow
                            key={index}
                            onClick={() => onRowClick?.(Object.values(row), index)}
                        >
                            {Object.values(row).map((value, index) => (
                                <TableCell align="center" key={index}>{value}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </CustomTable>
        </TableContainer>
  )
}

const CustomTable = styled(MuiTable)(`
.MuiTableCell-root {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    color: #393E51;
    border-bottom: none;
}
`)
