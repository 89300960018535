import React from 'react'

import AdvertiserLogsLayout from './AdvertiserLogsLayout'

export enum AdvertiserTypeColorsLogs {
  'Valitaion error' = 'rgba(255, 7, 13, 0.30)',
  'FSM state switched' = 'rgba(65, 65, 213, 0.30)',
  'Registration' = 'rgba(31, 180, 38, 0.30)',
  'Pushing to advertisers' = 'rgba(31, 180, 38, 0.30)',
  'Lead flow started' = 'rgba(65, 65, 213, 0.30)'
}

export interface IAdvertiserLogs {
  id: string
  createdAt: Date
  updatedAt?: Date
  logData: any
  action?: string
  advertiser?: {
    id: string
    name: string
  }
}

export default function AdvertiserLogs ({ advertiserLogs }: { advertiserLogs: IAdvertiserLogs[] }) {
  const newLogs = advertiserLogs?.map((log: IAdvertiserLogs) => {
    return {
      id: log?.id,
      logData: log?.logData,
      createdAt: new Date(log?.createdAt),
      advertiser: log?.advertiser
    }
  })
  return <AdvertiserLogsLayout logs={newLogs} />
}
