import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button, Menu, MenuItem } from '@mui/material'

import { ReactComponent as SettingsIcon } from '@/assets/icons/settings-icon.svg'
import { ReactComponent as Arrows } from '@/assets/icons/arrows.svg'
import PageContentLayout from '@/designSystem/PageContentLayout'
import LeftActionButtons from '@/components/Pages/Settings/ApiTokens/LeftActionButtons'
import RightActionButtons from '@/components/Pages/Settings/ApiTokens/RightActionButtons'
import { selectApiTokensMenu, setApiTokensMenu } from '@/store/apiTokens'
import ApiTokensTable from '@/components/Pages/Settings/ApiTokens/ApiTokensTable'

export default function ApiTokensPage () {
  const dispatch = useDispatch()
  const menu = useSelector(selectApiTokensMenu)

  const isMenu: boolean = !!menu?.id

  const handleSetActive = (name: string) => {
    dispatch(setApiTokensMenu({ name, id: '' }))
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onMenuItemClicked = (name: string) => {
    handleSetActive(name)
    handleClose()
  }

  return (
    <PageContentLayout
    title={<Box
      display={'flex'}
      alignItems={'center'}
    >
      <SettingsIcon />
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          fontSize: '28px',
          fontWeight: 500,
          color: '#000931',
          marginLeft: '15px'
        }}
      >
        Settings / <Box sx={{ color: '#7A7F8F', marginLeft: '8px', marginTop: '1px' }}>API Tokens</Box>
      </Box>
    </Box>}
       activeMenu={<Box sx={{
         display: 'flex',
         alignItems: 'center',
         marginLeft: '16px',
         marginTop: '2px',
         color: '#000931',
         fontSize: '24px',
         fontStyle: 'normal',
         fontWeight: 700,
         lineHeight: 'normal'
       }}>{menu?.name}
        {!isMenu &&
          <>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            >
            <Arrows />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={() => onMenuItemClicked('')}>All</MenuItem>
            <MenuItem onClick={() => onMenuItemClicked('Active')}>Active</MenuItem>
            <MenuItem onClick={() => onMenuItemClicked('Not Active')}>Not Active</MenuItem>
          </Menu>
        </>
        }
      </Box>}
      leftActionButtons={<LeftActionButtons isMenu={isMenu}/>}
      rightActionButtons={!isMenu && <RightActionButtons />}
    >
      <ApiTokensTable />
    </PageContentLayout>
  )
}
