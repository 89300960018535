import React from 'react'

import backgroundSVG from '@/designSystem/LeftBar/Images/background.svg'
import Logo from '@/designSystem/LeftBar/Images/Logo'
import Box from '@mui/material/Box'
import SettingsIcon from '@/designSystem/LeftBar/Images/Settings'
import UserIcon from '@/designSystem/LeftBar/Images/User'
import UserChangedIcon from '@/designSystem/LeftBar/Images/UserChanged'
import LeaveIcon from '@/designSystem/LeftBar/Images/Leave'
import IconButton from '@mui/material/IconButton'

import useTheme from '@mui/material/styles/useTheme'

import { windowHeight } from '@/constants'
import { useLocation } from 'react-router-dom'
import { useRole } from '@/hooks/role'
import { useSelector } from 'react-redux'
import { selectAccessRights } from '@/store/auth'
import { fullAccess } from '@/pages/Accounts/access'
import SettingsChanged from './Images/SettingsChanged'

interface LeftBarProps {
  onClickSettings: () => void
  onClickLeave: () => void
  onClickLogo: () => void
  onClickUser: () => void
}

export default function LeftBar ({
  onClickSettings,
  onClickLeave,
  onClickLogo,
  onClickUser
}: LeftBarProps) {
  const theme = useTheme()
  const location = useLocation()
  const role = useRole()
  const accessRights = useSelector(selectAccessRights)
  const isSettings = accessRights?.includes(fullAccess.SETTINGS)

  return (
    <Box
      minWidth="96px"
      display="flex"
      flexDirection="column"
      height={windowHeight}
      sx={{ backgroundImage: `url(${backgroundSVG})` }}
      alignItems="center"
      justifyContent="space-between"
      paddingTop={theme.spacing(8)}
      paddingBottom={theme.spacing(4)}
    >
      <Logo />
      <Box display="flex" flexDirection="column" gap={theme.spacing(14)}>
        <Box display="flex" flexDirection="column" gap={theme.spacing(6)}>
          {isSettings && <IconButton onClick={onClickSettings}>
            {(location.pathname === '/settings') ? <SettingsChanged /> : <SettingsIcon />}
          </IconButton>}
          {role && <IconButton onClick={onClickUser}>
            {(location.pathname === '/accounts') ? <UserChangedIcon /> : <UserIcon />}
          </IconButton>}
        </Box>
        <IconButton onClick={onClickLeave}>
          <LeaveIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
