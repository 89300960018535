import CountryIcon from '@/designSystem/Pages/TrafficDistribution/RotationControl/icons/CountryIcon'
import PQLIcon from '@/designSystem/Pages/TrafficDistribution/RotationControl/icons/PQLIcon'
import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
export enum ERenderer {
  COUNTRY = 'country',
  FREE_PQL = 'free_pql',
}
interface Props {
  renderer: ERenderer
  onClick: (value: ERenderer) => void
}

type IInfo = Record<ERenderer, { name: string, description: string, icon: JSX.Element }>

const info: IInfo = {
  [ERenderer.COUNTRY]: {
    name: 'Country',
    description: 'You can specify the countries that will pass',
    icon: CountryIcon()
  },
  [ERenderer.FREE_PQL]: {
    name: 'Free PQL',
    description: 'Create your own rule with our Query Language',
    icon: PQLIcon()
  }
}

const Renderer = ({ renderer, onClick }: Props) => {
  const rendererInfo = info[renderer]
  const { spacing } = useTheme()

  return <Box onClick={() => onClick(renderer)}
    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: spacing(3), background: '#F1F3FF', borderRadius: '16px', padding: spacing(3), cursor: 'pointer', flex: '0 0 33%' }}>
        {rendererInfo.icon}
        <Typography sx={{ fontWeight: 'bold' }}>{rendererInfo.name}</Typography>
        <Typography variant='subtitle2' sx={{ textAlign: 'center ' }}>{rendererInfo.description}</Typography>
    </Box>
}

export default Renderer
