import React, { useEffect, useState } from 'react'

import NavigationPanelLayout from '@/designSystem/NavigationPanel'

import type { Option } from '@/designSystem/Autocomplete/types'
import type { Tab } from '@/designSystem/NavigationPanel/types'
import { useLocation, useNavigate } from 'react-router-dom'
import { openTab } from '@/designSystem/NavigationPanel/utils'
import getTabs from '@/routes/routesList'
import { selectAccessRights } from '@/store/auth'
import { useSelector } from 'react-redux'

// Looks like we need only first 2 levels of nesting
const compareRefs = (comparable: string, target?: string) => {
  if (!target) {
    return false
  }
  const splited = comparable.split('/')
  const twoLevelRef = `/${splited[1]}/${splited?.[2] ?? ''}/`
  return twoLevelRef === target || comparable === target
}

const composeOption = (tab: Tab): Array<Option<string>> => {
  const { nestedTabs, ref, text } = tab
  if (nestedTabs) {
    return [
      {
        value: ref ?? '#',
        title: text
      },
      ...nestedTabs.flatMap(composeOption)
    ]
  }
  return [
    {
      value: ref ?? '#',
      title: text
    }
  ]
}

const isChosenInside = (tab: Tab, ref: string): boolean => {
  if (tab.nestedTabs) {
    return tab.nestedTabs.map((nestedTab) => isChosenInside(nestedTab, ref)).includes(true)
  }
  return compareRefs(ref, tab.ref)
}

const markChosenTab = (tab: Tab, ref: string, accessState: string[]): Tab => {
  if (tab.nestedTabs) {
    const isAccess = tab.nestedTabs.some((nestedTab) => accessState?.includes(nestedTab.access ?? ''))
    return {
      ...tab,
      isOpen: isChosenInside(tab, ref),
      nestedTabs: tab.nestedTabs.map((nestedTab) => markChosenTab(nestedTab, ref, accessState)),
      noAccessTab: !isAccess
    }
  }
  return {
    ...tab,
    isChosen: compareRefs(ref, tab.ref),
    noAccessTab: !accessState?.includes(tab.access ?? '')
  }
}

export default function NavigationPanel () {
  const location = useLocation()
  const navigate = useNavigate()
  const accessState = useSelector(selectAccessRights)
  const tabsList = getTabs()

  const [tabs, setTabs] = useState(() => tabsList.map((tab) => markChosenTab(tab, location.pathname, accessState)))

  const onRoute = ({ value, title }: Option<string>) => {
    setTabs((prevTabs) => openTab(prevTabs, title))
    navigate(value)
  }

  useEffect(() => {
    setTabs((prevTabs) => prevTabs.map((tab) => markChosenTab(tab, location.pathname, accessState)))
  }, [location.pathname])

  const autocompleteOptions = tabs.map(composeOption).flat().filter((i) => i.title && i.value !== '#')

  return (
    <NavigationPanelLayout
      setTabs={setTabs}
      onRoute={onRoute}
      tabs={tabs}
      autocompleteOptions={autocompleteOptions}
    />
  )
}
