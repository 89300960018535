/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useEffect, useState } from 'react'

import EditRoundedIcon from '@mui/icons-material/EditRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Box, IconButton, Typography, useTheme } from '@mui/material'

import FlexBox from '@/designSystem/FlexBox'
import Table from '@/designSystem/Table'
import AddIcon from '@/designSystem/Icons/Add'
import Popup from '@/designSystem/Popup'
import {
  Header,
  CTAField,
  TextField
} from '@/designSystem/Table/Fields'

import ActionButtons from '@/designSystem/ActionButtons'
import TextFieldModal from '@/designSystem/TextField'
import { type IApiSettings } from '@/components/Advertisers/IntegrationSettingsPage/ApiConnectionSettings/EditPushSettingsForm'
import { selectAdvertiserSettings, setAdvertiserSettings, useEditAdvertiserMutation } from '@/store/advertisers'
import { useSelector, useDispatch } from 'react-redux'
import IOSSwitch from '@/designSystem/IOSSwitch'
import { placeholderValues } from '@/constants'

const headers = ['Key', 'Value', 'Group', 'Action']

interface AddIntegrationFieldModalProps {
  open: boolean
  handleClose: () => void
  onSubmit: (obj: IApiSettings[]) => void
  allSettings?: IApiSettings[]
  editFieldId?: string
  popupError: string
}

interface RowPushSettings {
  key?: string
  group: string
  value?: string
}
interface PushSettingOverwriteProps {
  rows: RowPushSettings[]
  advertiserId?: string
}

interface IOverwriteSettings {
  group: string
  value: string
  key: string
}

export default function PushSettingsHeaderTable ({
  rows,
  advertiserId
}: PushSettingOverwriteProps) {
  const dispatch = useDispatch()
  const [editAdvertiser] = useEditAdvertiserMutation()
  const allSettingsState = useSelector(selectAdvertiserSettings)
  const [isOpenAddPopup, setIsOpenAddPopup] = useState(false)
  const [editPopupId, setEditPopupId] = useState<string | null>(null)
  const [settings, setSettings] = useState<IOverwriteSettings[] | undefined>([])
  const [popupError, setPopupError] = useState('')

  useEffect(() => {
    const newSettings: IOverwriteSettings[] = reduceOverwriteSettings(rows)
    setSettings(newSettings)
  }, [rows])

  function reduceOverwriteSettings (settings: IApiSettings[]) {
    return settings.reduce((acc: IOverwriteSettings[], setting: IApiSettings) => {
      const isUrlTokenHeader = setting?.key === 'url' ||
        setting?.key === 'header_authorization_field' || setting?.key === 'status_field' ||
        setting?.key === 'lead_id_field'
      const isHeadersGroup = setting?.group === 'headers'
      const isParametersGroup = setting?.group === 'parameters'
      if (!isUrlTokenHeader && (isHeadersGroup || isParametersGroup)) {
        acc.push({
          group: setting?.group ?? '',
          value: setting?.value ?? '',
          key: setting?.key ?? ''
        })
      }
      return acc
    }, [])
  }

  const onSubmit = async (settingsRes: IApiSettings[]) => {
    setPopupError('')
    try {
      const dataAdvertiser = await editAdvertiser({
        id: advertiserId ?? '',
        advertiserSettings: settingsRes
      }).unwrap()
      if (dataAdvertiser?.advertiserSettings) {
        const newSettings: IOverwriteSettings[] = reduceOverwriteSettings(dataAdvertiser?.advertiserSettings)
        setSettings?.(newSettings)
        dispatch(setAdvertiserSettings(dataAdvertiser?.advertiserSettings))
        setIsOpenAddPopup(false)
        setEditPopupId(null)
      }
    } catch (e: any) {
      setPopupError(e?.data?.message)
      return false
    }
  }

  const handleAddField = (settingsAll: IApiSettings[]) => {
    void onSubmit(settingsAll)
  }

  const handleRemoveField = (key: string) => {
    const newSettings = allSettingsState?.filter((setting: IApiSettings) => setting?.key !== key)
    if (newSettings) {
      void onSubmit(newSettings)
    }
  }

  const headerNodes = headers.map((header) => <Header header={header} key={header} />)
  const fieldToElementMap: any = useFieldToElementMap((key) => setEditPopupId(key), (key) => handleRemoveField(key))

  const rowNodes = settings?.map((row) => (
    headers.map((header) => fieldToElementMap(header, row))
  ))
  return (
    <>
      <FlexBox column gap={3}>
        <FlexBox gap={4} alignCenter>
          <Typography variant='h2'>
          Header & Params
          </Typography>
          <IconButton onClick={() => setIsOpenAddPopup(true)}>
            <AddIcon />
          </IconButton>
        </FlexBox>
        <Table headers={headerNodes} rows={rowNodes ?? []} />
      </FlexBox>
      {isOpenAddPopup && (
        <AddIntegrationFieldModal open={isOpenAddPopup}
          handleClose={() => setIsOpenAddPopup(false)} onSubmit={handleAddField}
          allSettings={allSettingsState} popupError={popupError} />
      )}
      {editPopupId && (
        <AddIntegrationFieldModal open={Boolean(editPopupId)}
          handleClose={() => setEditPopupId(null)} onSubmit={handleAddField}
          allSettings={allSettingsState} editFieldId={editPopupId} popupError={popupError} />
      )}
    </>
  )
}

const useFieldToElementMap = (
  edit: (key: string) => void,
  remove: (key: string) => void
) => (header: string, row: RowPushSettings) => {
  const { palette } = useTheme()
  const {
    key,
    group,
    value
  } = row
  switch (header) {
    case headers[0]:
      return <TextField text={key} />
    case headers[1]:
      return <TextField text={value} limitation={80} />
    case headers[2]:
      return <TextField text={group} />
    case headers[3]:
      return (
        <FlexBox sx={{ width: '130px', justifyContent: 'space-between' }}>
        <CTAField
          onClick={() => edit(key as string)}
          icon={<EditRoundedIcon sx={{ fill: palette.primary.main }} />}
        />
      <CTAField
        onClick={() => remove(key as string)}
        icon={<CloseRoundedIcon sx={{ fill: palette.red.main }} />}
      />
        </FlexBox>
      )
  }
}

function AddIntegrationFieldModal ({ open, handleClose, onSubmit, allSettings, editFieldId, popupError }: AddIntegrationFieldModalProps) {
  const setting = allSettings?.find((setting: IApiSettings) => setting?.key === editFieldId)

  const [group, setGroup] = React.useState('headers')
  const [value, setValue] = React.useState(editFieldId ? setting?.value : '')
  const [key, setKey] = React.useState(editFieldId ? setting?.key : '')
  const [error, setError] = React.useState({ key: '', value: '' })

  useEffect(() => {
    setError({ key: '', value: '' })
  }, [open])

  useEffect(() => {
    const group = setting?.group ?? 'headers'
    if (editFieldId) {
      setGroup(group)
    }
  }, [editFieldId, setting?.group])

  const handleChangeGroup = () => {
    setGroup((prev) => prev === 'headers' ? 'parameters' : 'headers')
  }

  const handleValueChange = (e: any) => {
    const isPlaceholder = placeholderValues.includes(e.target.value)
    if (isPlaceholder) {
      setValue(`{{${e.target.value}}}`)
    } else {
      setValue(e.target.value)
    }
  }

  const handleKeyChange = (e: any) => {
    setKey(e.target.value)
  }

  const handleSubmitParameters = () => {
    const uniqueKey = Boolean(allSettings?.find((setting: IApiSettings) =>
      (setting?.key === key) && (setting?.key !== '') && (setting?.key !== editFieldId)))

    if (key && value && !uniqueKey) {
      setError({ key: '', value: '' })
    } else if (uniqueKey || !key || !value) {
      !popupError && setError({
        value: !value ? 'Required field' : '',
        key: (!key || uniqueKey) ? 'Required field and must be unique' : ''
      })
      return
    }
    if (editFieldId) {
      const newSettings = allSettings?.map((setting: IApiSettings) => {
        if (setting?.key === editFieldId) {
          return {
            value,
            group,
            key
          }
        }
        return setting
      })
      if (newSettings) {
        onSubmit(newSettings)
      }
    } else {
      const settingsFields = allSettings ? [...allSettings] : []
      if (key && value && !uniqueKey) {
        settingsFields?.push({
          key,
          group,
          value
        })
        setError({ key: '', value: '' })
      }
      onSubmit(settingsFields)
    }
    setKey('')
    setValue('')
  }

  return (
    <Popup
      open={open}
      handleClose={handleClose}
      title={editFieldId ? `Edit ${group} field` : `Add ${group} field`}
    >
      <FlexBox column gap={4}>
        <IOSSwitch
          formControlLabel={{
            label: 'Parameters/Headers',
            labelPlacement: 'start',
            onChange: handleChangeGroup,
            checked: group === 'headers',
            sx: {
              margin: 0,
              '& .MuiTypography-root': {
                marginRight: '12px',
                marginLeft: '10px'
              }
            }
          }}
        />
        {<Box>
          <TextFieldModal title="Key" onChange={handleKeyChange} value={key} />
          <Typography variant='body2' color='error'>
            {error.key}
          </Typography>
        </Box>}
        {<Box>
          <TextFieldModal title="Value" onChange={handleValueChange} value={value} placeholders={placeholderValues} />
          <Typography variant='body2' color='error'>
            {error.value}
          </Typography>
        </Box>}
        {popupError && <Typography variant='body1' color='error'>
          {popupError}
        </Typography>}
        <ActionButtons submitText='Submit changes' onCancel={handleClose}
          onSubmit={handleSubmitParameters}
          sx={{
            marginTop: '20px'
          }}
        />
      </FlexBox>
    </Popup>
  )
}
