import React from 'react'
import Box from '@mui/material/Box'
import Button from '@/designSystem/Button'

import useTheme from '@mui/material/styles/useTheme'

import type { SxProps } from '@mui/material/styles'
import type { ButtonProps } from '@mui/material'

interface ActionButtonsProps extends Omit<ButtonProps, 'onClick'> {
  onCancel: () => void
  onSubmit?: () => void
  cancelText?: string
  submitText?: string
  submitType?: ButtonProps['type']
  sx?: SxProps
}

export default function ActionButtons ({
  onCancel,
  onSubmit,
  cancelText,
  submitType = 'submit',
  submitText,
  sx,
  ...rest
}: ActionButtonsProps) {
  const theme = useTheme()
  return (
    <Box sx={sx} display="flex" gap={theme.spacing(4)}>
      <Button onClick={onCancel} text={cancelText ?? 'Cancel'} />
      <Button onClick={onSubmit} text={submitText ?? 'Apply'} {...rest} variant='contained' type={submitType} />
    </Box>
  )
}
