import React, { useState } from 'react'
import PageContentLayout from '@/designSystem/PageContentLayout'
import HelpInformationModal from '@/designSystem/Pages/Advertisers/HelpInformationModal'

import LeftActionButtons from '@/components/Pages/TrafficDistribution/SaleStatuses/LeftActionButtons'
import SaleStatuses from '@/components/Pages/TrafficDistribution/SaleStatuses/'
import CreateSaleStatus from './CreateSaleStatus'
import CreateCRMSaleStatus from './CreateCRMSaleStatus'
import Button from '@/designSystem/Button'

export default function SaleStatusesPage () {
  const [isModalVisible, setModalVisible] = useState(false)
  const [selectedCRMStatus, setSelectedCRMStatus] = useState<any | null>(null)
  const handleClose = () => {
    setModalVisible(false)
    setSelectedCRMStatus(null)
  }
  const handleOpen = () => setModalVisible(true)

  const editCRMSaleStatus = (val: any) => {
    handleOpen()
    setSelectedCRMStatus(val)
  }

  return (
    <PageContentLayout
      title="Sale statuses"
      support={{ content: <HelpInformationModal />, subtitle: 'Sale Statuses' }}
      leftActionButtons={<LeftActionButtons />}
      rightActionButtons={<Button sx={{ width: 220 }} text={'Add CRM Sale Status'} onClick={handleOpen}></Button>}
    >
      <SaleStatuses editCRMSaleStatus={editCRMSaleStatus} />
      <CreateSaleStatus />
      {isModalVisible && <CreateCRMSaleStatus handleClose={handleClose} selectedCRMStatus={selectedCRMStatus} />}
    </PageContentLayout>
  )
}
