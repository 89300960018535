import React, { type SVGProps } from 'react'

export default function AddIcon (props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="30" height="30" rx="15" fill="#4141D5"/>
      <path d="M21 15.9961H16V20.9961C16 21.2613 15.8946 21.5157 15.7071 21.7032C15.5196 21.8907 15.2652 21.9961 15 21.9961C14.7348 21.9961 14.4804 21.8907 14.2929 21.7032C14.1054 21.5157 14 21.2613 14 20.9961V15.9961H9C8.73478 15.9961 8.48043 15.8907 8.29289 15.7032C8.10536 15.5157 8 15.2613 8 14.9961C8 14.7309 8.10536 14.4765 8.29289 14.289C8.48043 14.1015 8.73478 13.9961 9 13.9961H14V8.99609C14 8.73088 14.1054 8.47652 14.2929 8.28899C14.4804 8.10145 14.7348 7.99609 15 7.99609C15.2652 7.99609 15.5196 8.10145 15.7071 8.28899C15.8946 8.47652 16 8.73088 16 8.99609V13.9961H21C21.2652 13.9961 21.5196 14.1015 21.7071 14.289C21.8946 14.4765 22 14.7309 22 14.9961C22 15.2613 21.8946 15.5157 21.7071 15.7032C21.5196 15.8907 21.2652 15.9961 21 15.9961Z" fill="#F1F3FF"/>
    </svg>
  )
}
