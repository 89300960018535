export const monthOptions = [
  { title: 'January', value: '1' },
  { title: 'February', value: '2' }, { title: 'March', value: '3' },
  { title: 'April', value: '4' }, { title: 'May', value: '5' },
  { title: 'June', value: '6' }, { title: 'July', value: '7' },
  { title: 'August', value: '8' }, { title: 'September', value: '9' },
  { title: 'October', value: '10' }, { title: 'November', value: '11' },
  { title: 'December', value: '12' }
]

export const hoursOptions = [
  { title: '00:00', value: '0' },
  { title: '01:00', value: '1' }, { title: '02:00', value: '2' },
  { title: '03:00', value: '3' }, { title: '04:00', value: '4' },
  { title: '05:00', value: '5' }, { title: '06:00', value: '6' },
  { title: '07:00', value: '7' }, { title: '08:00', value: '8' },
  { title: '09:00', value: '9' }, { title: '10:00', value: '10' },
  { title: '11:00', value: '11' }, { title: '12:00', value: '12' },
  { title: '13:00', value: '13' }, { title: '14:00', value: '14' },
  { title: '15:00', value: '15' }, { title: '16:00', value: '16' },
  { title: '17:00', value: '17' }, { title: '18:00', value: '18' },
  { title: '19:00', value: '19' }, { title: '20:00', value: '20' },
  { title: '21:00', value: '21' }, { title: '22:00', value: '22' },
  { title: '23:00', value: '23' }
]

export const weekOptions = [
  { title: 'Monday', value: 'Monday' },
  { title: 'Tuesday', value: 'Tuesday' }, { title: 'Wednesday', value: 'Wednesday' },
  { title: 'Thursday', value: 'Thursday' }, { title: 'Friday', value: 'Friday' },
  { title: 'Saturday', value: 'Saturday' }, { title: 'Sunday', value: 'Sunday' }
]

export enum Months {
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
}
