import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { setLeadsQueryParams, selectLeadsQueryParams } from '@/store/queryParams'

import FilterIcon from '@/designSystem/Pages/Advertisers/Icons/Filter'
import SettingsIcon from '@/assets/icons/Settings'
import { Box, useTheme } from '@mui/material'
import Button from '@/designSystem/Button'
import IconButton from '@/designSystem/IconButton'
import LeadsFiltersPopup from './Filters/LeadsFiltersPopup'
import LeadsSettingsPopup from './Filters/LeadsSettingsPopup'
import isObjectNotEmpty from '@/utils/isObjectNotEmpty'
import { type Option } from '@/designSystem/Autocomplete/types'
import Autocomplete from '@/designSystem/Pages/Advertisers/Autocomplete'

const subtractDays = (startingDate: Date, daysCount: number) =>
  new Date(new Date().setDate(startingDate.getDate() - daysCount)).toISOString()
const subtractMonth = (startingDate: Date, monthsCount: number) =>
  new Date(new Date().setMonth(startingDate.getMonth() - monthsCount)).toISOString()
const subtractYears = (startingDate: Date, yearsCount: number) =>
  new Date(new Date().setFullYear(startingDate.getFullYear() - yearsCount)).toISOString()

const getDateValues = (someDate: Date): Array<Option<string>> => [
  { title: 'Day', value: subtractDays(someDate, 1) },
  { title: 'Week', value: subtractDays(someDate, 7) },
  { title: '1 Month', value: subtractMonth(someDate, 1) },
  { title: '6 Months', value: subtractMonth(someDate, 6) },
  { title: 'Year', value: subtractYears(someDate, 1) }
]

export default function RightActionButtons () {
  const theme = useTheme()
  const [isOpenFilterPopup, setIsOpenFilterPopup] = useState(false)
  const [isSettingsOpen, setIsSettingOpen] = useState(false)

  const dispatch = useDispatch()
  const queryParams = useSelector(selectLeadsQueryParams)

  const isFilter = queryParams?.filters && isObjectNotEmpty(queryParams?.filters)

  const changeDate = (value: Option<string> | null) => {
    return value?.value && dispatch(setLeadsQueryParams({
      ...queryParams,
      page: 1,
      filters: {
        ...queryParams.filters,
        createdAt: {
          from: new Date(value?.value).toISOString(),
          to: (new Date()).toISOString()
        }
      }
    }))
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={theme.spacing(4)}
      >
        {isFilter && <Button
          onClick={() => dispatch(setLeadsQueryParams({ ...queryParams, filters: undefined }))}
          text='Reset filter'
          variant='contained'
          sx={{
            backgroundColor: theme.palette.red2.main,
            padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
            width: 'max-content'
          }}
        />}
        <Autocomplete
          sx={{ width: '200px', cursor: 'pointer' }}
          onChange={(e: any) => {
            changeDate(e)
          }}
          options={getDateValues(new Date())}
          value={null}
          placeholder='Date'
          leftShift
        />
        <IconButton onClick={() => setIsOpenFilterPopup(true)}>
          <FilterIcon />
        </IconButton>
        <Box
          display="flex"
          alignItems="center"
          gap={theme.spacing(3)}
        >
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => setIsSettingOpen(true)}
          >
            <SettingsIcon />
          </IconButton>
        </Box>
      </Box>
      {isSettingsOpen && (<LeadsSettingsPopup handleClose={() => setIsSettingOpen(false)} />)}
      {isOpenFilterPopup && (
        <LeadsFiltersPopup handleClose={() => setIsOpenFilterPopup(false)} />
      )}
    </>
  )
}
