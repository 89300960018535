import React from 'react'
import PageContentLayout from '@/designSystem/PageContentLayout'
import HelpInformationModal from '@/designSystem/Pages/Advertisers/HelpInformationModal'

import LeftActionButtons from '@/components/Advertisers/LeftActionButtons'
import RightActionButtons from '@/components/Advertisers/RightActionButtons'
import Table from '@/components/Advertisers/Table'

export default function AdvertisersPage () {
  return (
    <PageContentLayout
      title="Advertisers"
      support={{ content: <HelpInformationModal />, subtitle: 'Advertisers' }}
      leftActionButtons={<LeftActionButtons />}
      rightActionButtons={<RightActionButtons />}
    >
      <Table />
    </PageContentLayout>
  )
}
