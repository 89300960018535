import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { serverURL } from '@/config'
import { createSlice } from '@reduxjs/toolkit'

export const authApi: any = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}/v1/auth`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      const timezone = localStorage.getItem('timezone')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        timezone && headers.set('timezone', timezone)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    logOutUser: builder.mutation({
      query: (userId: string) => ({
        url: '/logout',
        method: 'POST',
        body: { userId }
      })
    })
  })
})

export const { useLogOutUserMutation } = authApi

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    role: '',
    userId: '',
    accessRights: []
  },
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload
    },
    setUserId: (state, action) => {
      state.userId = action.payload
    },
    setAccessRights: (state, action) => {
      state.accessRights = action.payload
    }
  }
})

export const { setRole, setUserId, setAccessRights } = authSlice.actions

export default authSlice.reducer

export const selectRole = (state: any) => state.auth.role

export const selectUserId = (state: any) => state.auth.userId

export const selectAccessRights = (state: any) => state.auth.accessRights
