import React from 'react'

import { useFormContext, Controller } from 'react-hook-form'
import TextFieldLayout, { type TextFiledProps as TextFiledLayoutProps } from '@/designSystem/TextField'
import camelCase from 'lodash.camelcase'

type TextFieldProps = TextFiledLayoutProps & {
  name?: string
}

export default function TextField ({ name, title, ...rest }: TextFieldProps) {
  const { control } = useFormContext()
  const fieldName = name ?? camelCase(title)

  if (!fieldName) {
    return null
  }

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { value, onChange } }) => (
        <TextFieldLayout
          value={value}
          onChange={onChange}
          title={title}
          {...rest}
        />
      )}
    />
  )
}
