import React, { useEffect } from 'react'

import {
  setAdvertiserSettings, setPullLeadState,
  setPullLeadStateSettings,
  setPullLeadStatusesSettings,
  useGetAdvertisersQuery
} from '@/store/advertisers'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PullSettingsTabContent
  from '@/designSystem/Pages/Advertisers/IntegrationSettings/PullSettingsTabContent/PullSettingsTabContent'

export default function PullSettingsTab () {
  const { advertiserId } = useParams()
  const dispatch = useDispatch()

  const [advertiser] = useGetAdvertisersQuery({
    limit: 1,
    filters:
      { id: advertiserId ? [advertiserId] : undefined }
  })?.data?.advertisers ?? []

  const getAdvertiser = () => {
    if (!advertiserId) return
    return advertiser
  }

  useEffect(() => {
    const advertiser = getAdvertiser()
    if (!advertiser) return
    dispatch(setAdvertiserSettings(advertiser?.advertiserSettings ?? []))
    dispatch(setPullLeadStatusesSettings(advertiser?.pullLeadStatusesSettings ?? []))
    dispatch(setPullLeadStateSettings(advertiser?.pullLeadStateSettings ?? []))
    dispatch(setPullLeadState(advertiser?.pullLeadState ?? false))
  }, [advertiserId, dispatch, getAdvertiser])

  return (
    <PullSettingsTabContent
      advertiserId={advertiserId ?? ''}
    />
  )
}
