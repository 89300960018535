import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import { Typography, useTheme } from '@mui/material'
import DealsPagination from './DealsPagination'
import DealsFilterBar from './Filters/DealsFilterBar'
import TableLayout from '@/designSystem/Table'
import elementMap from './ElementMap'
import { EmptyTableDeals } from './EmptyTableDeals'
import {
  selectDealsState,
  setDealsState,
  setDealsTotalCount,
  useGetDealsQuery
} from '@/store/deals'
import { DealsInfoModal } from './DealsInfoModal'
import { ModalEventInfo } from '@/designSystem/ModalMessagesLogs'
import { selectDealsQueryParams } from '@/store/queryParams'

const Header = ({ header }: { header: string }) => {
  if (header.startsWith('::')) {
    return ''
  }
  return (
    <Typography
      variant='body1'
      color="text1"
      width="max-content"
      sx={{
        display: 'flex'
      }}
    >
      {header}
    </Typography>
  )
}

export default function DealsTable () {
  const dispatch = useDispatch()
  const { limit = 10, headers, filters, page } = useSelector(selectDealsQueryParams)

  const { data }: any = useGetDealsQuery({ limit, filters, page })

  const dealsState: any = useSelector(selectDealsState)
  const { spacing } = useTheme()
  const [openModal, setOpenModal] = React.useState(false)
  const [deal, setDeal] = React.useState<any>({})
  const [deals, setDeals] = React.useState<any>(dealsState ?? [])
  const [modalLogs, setModalLogs] = React.useState<any>(null)
  const headersWithExtraFields = ['::indicatorDeals']?.concat(headers)
  const headerNodes: React.ReactNode[] =
    headersWithExtraFields?.map((header) => <Header key={header} header={header} />)

  const handleOpenDeals = ({ name, id, deal }: { name: string, id: string, deal: any }) => {
    setOpenModal(true)
    setDeal({ name, id, deal })
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const rowNodes: any = deals?.map((row: any) => (
    headersWithExtraFields?.map((header) =>
      elementMap(header, row, handleOpenDeals, setModalLogs))
  ))
  useEffect(() => {
    if (data) {
      data && dispatch(setDealsState(data?.data ?? []))
      setDeals(data?.data ?? [])
      dispatch(setDealsTotalCount(data?.totalCount ?? 0))
    }
  }, [data])

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap={spacing(6)}
      >
        <DealsPagination />
        <DealsFilterBar />
        <TableLayout
          headers={headerNodes}
          rows={rowNodes}
          sx={{
            '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:first-of-type': {
              width: 0
            }
          }}
        />
        {(rowNodes?.length === 0) && <EmptyTableDeals />}
      </Box>
      {openModal && <DealsInfoModal open={openModal} handleClose={handleClose} deal={deal} />}
      {modalLogs && <ModalEventInfo
        open={!!modalLogs}
        handleClose={() => setModalLogs(null)}
        logs={modalLogs}
      />}
    </>
  )
}
