import React, { useState } from 'react'

import { Box, Card, IconButton, Typography, useTheme } from '@mui/material'
import FlexBox from '@/designSystem/FlexBox'

import { format } from 'date-fns'

import ClipboardIcon from '@/designSystem/Icons/Clipboard'
import ExpandIcon from '@/designSystem/Icons/Expand'
import { CalendarIcon, ClockIcon } from '@mui/x-date-pickers'
import { type PostbackLogsArgs, type PostbackLogs, type LinkParameter } from '@/store/postBacks'

export default function Logs ({ logs }: PostbackLogsArgs) {
  const [areCollapsedIds, setAreCollapsedIds] = useState<boolean[]>(() => new Array(logs?.length).fill(true))
  const { palette, spacing } = useTheme()

  if (!logs || logs.length === 0) {
    return (
      <Typography variant="h1">
        No logs yet
      </Typography>
    )
  }

  const onCopy = (text: string) => navigator.clipboard.writeText(text)
  const onExpand = (index: number) => setAreCollapsedIds((areCollapsed) => {
    const collapsed = [...areCollapsed]
    collapsed[index] = !collapsed[index]
    return collapsed
  })

  return (
    <FlexBox
      position='relative'
      column
      borderLeft={`3px dashed ${palette.text2.main}`}
      margin={spacing(3)}
      padding={spacing(0, 3)}
      gap={6}
    >
      {logs.map(({ id, createdAt, ...rest }, index) => (
        <Box key={id}>
          <Box
            position="absolute"
            left={spacing(-4)}
          >
            {/* <PointIcon /> */}
          </Box>
          <Card
            variant="outlined"
            sx={{
              padding: spacing(4, 6),
              borderRadius: '16px',
              maxWidth: '730px',
              marginLeft: spacing(6)
            }}
          >
            <FlexBox column gap={4}>
              <FlexBox gap={8}>
                <FlexBox gap={2}>
                  <CalendarIcon />
                  <Typography variant='body2' color={palette.text2.main}>
                    {format(new Date(createdAt), 'yyyy-MM-dd')}
                  </Typography>
                </FlexBox>
                <FlexBox gap={2}>
                  <ClockIcon />
                  <Typography variant='body2' color={palette.text2.main}>
                    {format(new Date(createdAt), 'kk:mm:ss')}
                  </Typography>
                </FlexBox>
              </FlexBox>
              <FlexBox gap={2} alignCenter>
                <Row title="ID" text={id} isLimited={areCollapsedIds[index]} />
                <FlexBox gap={1} alignCenter>
                  <IconButton onClick={() => onExpand(index)}>
                    <ExpandIcon />
                  </IconButton>
                  <IconButton onClick={() => onCopy(id)}>
                    <ClipboardIcon opacity="0.7" />
                  </IconButton>
                </FlexBox>
              </FlexBox>
              {Object.entries(rest?.logData?.data).map(([key, value]) => {
                if (value !== null) {
                  if (key === 'linkParameters' && typeof value === 'object') {
                    const toKeys = Array.isArray(value.to) ? (value.to as LinkParameter[]).map((param) => param.key).join(', ') : ''
                    const fromKeys = Array.isArray(value.from) ? (value.from as LinkParameter[]).map((param) => param.key).join(', ') : ''
                    if (fromKeys !== '' && toKeys !== '') {
                      return <Row key={key} title={key} text={`${fromKeys}  ->  ${toKeys}`} />
                    }
                    return null
                  } else if (typeof value === 'object') {
                    return <Row key={key} title={key} text={`${(value as any)?.from} -> ${(value as any)?.to}`} />
                  }
                }
                return null
              })}
              {/* {Object.entries(rest?.logData?.data).map(([key, value]) => { return value !== null ? <Row key={key} title={key} text={JSON.stringify(`${(value as any)?.from} -> ${(value as any)?.to}`).replaceAll('"', '')} /> : null })} */}
            </FlexBox>
          </Card>
        </Box>
      ))}
    </FlexBox>
  )
}

interface RowProps {
  title: string
  text: string
  isLimited?: boolean
}
function Row ({ text, title, isLimited }: RowProps) {
  const { palette } = useTheme()

  return (
    <Box>
      <Box
        color={palette.text1.main}
        fontWeight={500}
      >
        {`${title}: `}
      </Box>
      <Typography
      color={palette.text2.main}
      sx={isLimited
        ? {
            maxWidth: '80px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }
        : undefined}
    >
      <Box
      display="inline"
      marginLeft={'20px'}>
      {text}
      </Box>
    </Typography>
    </Box>

  )
}
