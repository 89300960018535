import React, { useRef } from 'react'

import { useDrag } from 'react-dnd'

import Box from '@mui/material/Box'
import { Typography, useTheme } from '@mui/material'
import HighlightOff from '@mui/icons-material/HighlightOff'

import type { StatusEntry, SaleStatusesWithUnused } from './index'
import FlexBox from '@/designSystem/FlexBox'

interface StatusBoxProps {
  statuses: StatusEntry[]
  onStatusMove: (id: string, oldRowName: SaleStatusesWithUnused, newRowName: SaleStatusesWithUnused) => void
  isTable?: boolean
  value: string
  id: string
  rowName: SaleStatusesWithUnused
  onDelete?: () => void
}

const StatusBox = ({ statuses, onStatusMove, isTable, value, id, rowName, onDelete }: StatusBoxProps) => {
  const ref = useRef(null)

  const { spacing, palette } = useTheme()

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'STATUS',
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),

    item: () => {
      return { id, rowName }
    }
  }))

  drag(ref)

  return (
    <FlexBox
      padding={spacing(1.5, 4)}
      style={{
        backgroundColor: isTable ? '#e3e3e3' : '#fff',
        opacity: isDragging ? 0.5 : 1
      }}
      borderRadius="8px"
      ref={ref}
      alignCenter
      gap={1}
    >
      <Typography variant="table1" color={palette.text2.main}>
        {value}
      </Typography>
      {onDelete && <HighlightOff onClick={onDelete} />}
    </FlexBox>
  )
}

export default StatusBox
