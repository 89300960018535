import React from 'react'

export default function LeadRevenue () {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="45" height="45" rx="12" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21 15H25C28.771 15 30.657 15 31.828 16.172C32.672 17.015 32.908 18.229 32.974 20.25H13.026C13.092 18.229 13.328 17.015 14.172 16.172C15.343 15 17.229 15 21 15ZM25 31H21C17.229 31 15.343 31 14.172 29.828C13 28.657 13 26.771 13 23C13 22.558 13 22.142 13.002 21.75H32.998C33 22.142 33 22.558 33 23C33 26.771 33 28.657 31.828 29.828C30.657 31 28.771 31 25 31ZM17 26.25C16.8011 26.25 16.6103 26.329 16.4697 26.4697C16.329 26.6103 16.25 26.8011 16.25 27C16.25 27.1989 16.329 27.3897 16.4697 27.5303C16.6103 27.671 16.8011 27.75 17 27.75H21C21.1989 27.75 21.3897 27.671 21.5303 27.5303C21.671 27.3897 21.75 27.1989 21.75 27C21.75 26.8011 21.671 26.6103 21.5303 26.4697C21.3897 26.329 21.1989 26.25 21 26.25H17ZM23.5 26.25C23.3011 26.25 23.1103 26.329 22.9697 26.4697C22.829 26.6103 22.75 26.8011 22.75 27C22.75 27.1989 22.829 27.3897 22.9697 27.5303C23.1103 27.671 23.3011 27.75 23.5 27.75H25C25.1989 27.75 25.3897 27.671 25.5303 27.5303C25.671 27.3897 25.75 27.1989 25.75 27C25.75 26.8011 25.671 26.6103 25.5303 26.4697C25.3897 26.329 25.1989 26.25 25 26.25H23.5Z" fill="#4141D5" />
    </svg>
  )
}
