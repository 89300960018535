import React from 'react'

import { useNavigate, useParams } from 'react-router'
import { useGetLeadsQuery } from '@/store/leads'
import LeadInformationPageLayout from './LeadInformationPageLayout'
import { useGetHoldsQuery } from '@/store/hold'

export default function LeadInformationPage () {
  const { leadId } = useParams()
  const navigate = useNavigate()
  const dataLeads = (leadId &&
     useGetLeadsQuery({ limit: 1, filters: { id: [leadId] }, include: { logs: true } }).data?.leads?.[0]) ?? null

  const dataHolds = (leadId &&
    useGetHoldsQuery({ limit: 1, filters: { id: [leadId] }, include: { logs: true } }).data?.leads?.[0]) ?? null
  if (!leadId) {
    navigate(-1)
  }

  const onClickReload = () => navigate(0)

  return (
    <LeadInformationPageLayout
      onClickReload={onClickReload}
      dataLead={dataLeads ?? dataHolds}
    />
  )
}
