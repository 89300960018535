import React from 'react'
import { type Option } from '@/designSystem/Autocomplete/types'
import * as AutocompleteDS from '@/designSystem/Autocomplete'
import { type Theme, type SxProps } from '@mui/material'
import { goalTypeOptions } from '.'

interface Props {
  currentGoalType?: Option<string>
  onChange: (option: Option<string> | null) => void
  sx?: SxProps<Theme>
}

const GoalTypeSelection = ({ currentGoalType, onChange, sx }: Props) => {
  return <AutocompleteDS.default required sx={sx} title="Goal Type" options={goalTypeOptions} onChange={(option) => onChange(option)} defaultValue={goalTypeOptions?.find((i) => i.value === currentGoalType?.value)}/>
}

export default GoalTypeSelection
