/* eslint-disable react/prop-types */
import React from 'react'

import { Box, Typography } from '@mui/material'
import BasicTable from '@/designSystem/BasikTable'
import { EmptyTableInject } from '../EmptyTableInject'
import InjectTasksPagination from '../InjectTasksPagination'

const InjectTableWrapper = ({
  rowsInjectMain, rowsInjectSettings, tasksInjectSettings,
  timeStampsInjectSettings, tasksMainInjectSettings, tasksLength
}: any) => {
  return (
    <Box
      width={'1650px'}
      sx={{
        overflowY: 'auto'
      }}
    >
      <Typography
        width="max-content"
        sx={{
          color: '#000931',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          textTransform: 'capitalize',
          paddingTop: '40px',
          paddingBottom: '12px'
        }}
      >
        Basic Information
      </Typography>
      <BasicTable
        headers={['UUID', 'Name', 'Description', 'Status']}
        rows={rowsInjectMain}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '12px',
          paddingTop: '24px'
        }}
      >
        <Box>
          <Typography
            width="max-content"
            sx={{
              color: '#000931',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              textTransform: 'capitalize',
              paddingTop: '24px',
              paddingBottom: '12px'
            }}
          >
            Settings
          </Typography>
          <BasicTable
            headers={['Interval', 'Advertiser Id']}
            rows={rowsInjectSettings}
          />
        </Box>
        <Box
        >
          <Typography
            width="max-content"
            sx={{
              color: '#000931',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              textTransform: 'capitalize',
              paddingTop: '24px',
              paddingBottom: '12px'
            }}
          >
            {!tasksLength && 'Tasks'}
            {tasksLength > 0 && `Tasks ( ${tasksLength} )`}
          </Typography>
          <Box
            width={'625px'}
            height={'185px'}
          >
            <BasicTable
              headers={['Pending', 'In Progress', 'Success', 'Rejected', 'Error']}
              rows={tasksInjectSettings}
            />
          </Box>
        </Box>
        <Box>
          <Typography
            width="max-content"
            sx={{
              color: '#000931',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              textTransform: 'capitalize',
              paddingTop: '24px',
              paddingBottom: '12px'
            }}
          >
            Timestamps
          </Typography>
          <BasicTable
            headers={['Created At', 'State Updated At']}
            rows={timeStampsInjectSettings}
          />
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '12px',
            paddingTop: '47px',
            paddingBottom: '12px'
          }}
        >
          <Typography
            width="max-content"
            sx={{
              color: '#000931',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              textTransform: 'capitalize'
            }}
          >
            Tasks
          </Typography>
          <InjectTasksPagination count={tasksMainInjectSettings?.length} />
        </Box>
        <BasicTable
          headers={['Injection UUID', 'Lead UUID', 'Processing Status', 'Extra Data', 'Created At', 'Update At']}
          rows={tasksMainInjectSettings}
        />
        {(tasksMainInjectSettings?.length === 0) && <EmptyTableInject />}
      </Box>
    </Box>
  )
}

export default InjectTableWrapper
